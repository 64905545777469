import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { Select } from 'components/form/base/Select';
import { roleNamesHash } from 'constants/roles';
import { mapSelect } from 'utils/mappings';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  fullNameQuery: string;
  emailQuery: string;
  role: string | TFormSelectValue;
}

export const Filters: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        fullNameQuery: values.fullNameQuery && values.fullNameQuery.trim(),
        emailQuery: values.emailQuery && values.emailQuery.trim(),
        role: values.role ? mapSelect(values.role) : null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      fullNameQuery: '',
      emailQuery: '',
      role: '',
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='fullNameQuery'
            label='Фамилия и имя'
            placeholder='Введите фамилию, имя'
            maxLength={75}
          />
          <InputField
            name='emailQuery'
            label='Почта'
            placeholder='Введите почту'
            maxLength={255}
          />
          <Select
            name='role'
            label='Роль'
            options={[
              { value: '', label: 'Все' },
              ...Object.keys(roleNamesHash).map(item => ({
                value: item,
                label: roleNamesHash[item],
              })),
            ]}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
