import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'antd';

import { GetThereContainer } from './components/GetThereContainer';
import { GetThereItem } from './components/GetThereItem/GetThereItem';

interface IProps {
  name: string;
  entityType: string;
  fieldType: string;
}

export const GetThere = ({ name, fieldType, entityType }: IProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  });

  const handleSelectorChange = () =>
    append({ selectedWay: null, description: '' });

  return (
    <Row gutter={[24, 0]}>
      {fields.length ? (
        <Col span={24}>
          {fields.map((item: any, index) => (
            <Controller
              key={item.id}
              name={`${name}.${index}`}
              control={control}
              defaultValue={item.value}
              render={({ field, fieldState }) => (
                <GetThereContainer
                  key={field.name}
                  number={index + 1}
                  handleClear={() => remove(index)}
                  error={fieldState.error}
                  {...field}
                >
                  <GetThereItem
                    name={`${field.name}`}
                    entityType={entityType}
                    fieldType={fieldType}
                  />
                </GetThereContainer>
              )}
            />
          ))}
        </Col>
      ) : null}
      <Col span={24}>
        <Button onClick={handleSelectorChange} style={{ padding: '0 15px' }}>
          + Добавить способ
        </Button>
      </Col>
    </Row>
  );
};
