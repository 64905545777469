import * as Yup from 'yup';

import { seoValidationSchema } from 'utils/yup';
import { stripTagsAndTrim } from 'utils/helpers';

export const validationSchema = Yup.object()
  .shape({
    hero: Yup.string()
      .min(1, 'Введите от 1 до 80 символов')
      .max(80, 'Введите от 1 до 80 символов')
      .required(),
    title: Yup.string()
      .min(1, 'Введите от 1 до 80 символов')
      .max(80, 'Введите от 1 до 80 символов')
      .required(),
    shortDesc: Yup.string()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    description: Yup.array().of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length <= 4000;
          },
          message: 'Максимальное количество символов - 4000',
        }),
      })
    ),
    direction: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
            .min(1, 'Введите от 1 до 60 символов')
            .max(60, 'Введите от 1 до 60 символов')
            .required(),
          icon: Yup.mixed().required('Необходимо загрузить иконку'),
          shortDescription: Yup.string().max(
            255,
            'Введите от 1 до 255 символов'
          ),
        })
      )
      .min(1, 'Необходимо добавить отрасль'),
  })
  .concat(seoValidationSchema);
