// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PurchasesForm-module__dateNumberContainer--GUuSv {\n  gap: 24px;\n}\n@media (max-width: 1200px) {\n  .PurchasesForm-module__dateNumberContainer--GUuSv {\n    gap: 0;\n  }\n}\n.PurchasesForm-module__materialSection--HiYIZ {\n  margin-top: 25px;\n}\n.PurchasesForm-module__materialItem--OeiBA {\n  margin-top: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Purchases/components/PurchasesForm/PurchasesForm.module.less"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AACE;EAAA;IACE,MAAA;EAEF;AACF;AACA;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".dateNumberContainer {\n  gap: 24px;\n\n  @media (max-width: 1200px) {\n    gap: 0;\n  }\n}\n\n.materialSection {\n  margin-top: 25px;\n}\n\n.materialItem {\n  margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateNumberContainer": "PurchasesForm-module__dateNumberContainer--GUuSv",
	"materialSection": "PurchasesForm-module__materialSection--HiYIZ",
	"materialItem": "PurchasesForm-module__materialItem--OeiBA"
};
export default ___CSS_LOADER_EXPORT___;
