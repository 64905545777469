import React, { useState } from 'react';
import { Button, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadMaterialsList,
  removeMaterial,
  setPublishMaterial,
  setUnPublishMaterial,
} from 'store/slices/materials/actions';
import { IMaterialResponse } from 'store/slices/materials/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { useDeepEffect } from 'utils/useDeepEffect';
import { canBePublished, canBeUnPublished } from 'utils/rights';
import { PageTitles } from 'constants/pageTitles';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { b2bUrl } = useAppSelector(state => state.configSlice);

  const copyTextToClipboard = async text => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      notification.success({
        message: 'Ссылка успешно скопирована',
      });
    }
  };

  const copyBtbUrl = (item: IMaterialResponse) => {
    const url = `${b2bUrl}/materials/${item.id}/${item.sysName}`;
    copyTextToClipboard(url);
  };

  const { list, total, limit } = useAppSelector(state => state.materials);

  const loadMaterials = () => {
    dispatch(
      loadMaterialsList({
        ...query,
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  useDeepEffect(() => {
    loadMaterials();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(null);
    setCurrentItem(null);
  };

  const handleRemove = async () => {
    await dispatch(removeMaterial(currentItem.id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Материал успешно перенесен в Корзину',
        });
        loadMaterials();
      })
      .finally(() => {
        modalCloseHandler();
      });
  };

  const handlePublish = async id => {
    await dispatch(setPublishMaterial(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно опубликована',
        });
        loadMaterials();
        modalCloseHandler();
      });
  };

  const handleUnPublish = async id => {
    await dispatch(setUnPublishMaterial(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadMaterials();
        modalCloseHandler();
      });
  };

  const getActions = (item: IMaterialResponse) => [
    {
      key: 'copyBtbUrl',
      label: <Typography.Text>Скопировать ссылку</Typography.Text>,
      onClick: () => copyBtbUrl(item),
    },
    {
      key: 'edit',
      label: <Link to={`/materials/${item.id}/edit`}>Редактировать</Link>,
    },
    canBePublished(item) && {
      key: 'publish',
      label: <Typography.Text>Опубликовать</Typography.Text>,
      onClick: () => handlePublish(item.id),
    },
    canBeUnPublished(item) && {
      key: 'unPublish',
      label: <Typography.Text>Снять с публикации</Typography.Text>,
      onClick: () => handleUnPublish(item.id),
    },
    {
      key: 'delete',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        setModalIsOpen('remove');
        setCurrentItem(item);
      },
    },
  ];

  return (
    <MainLayout
      pageTitle={PageTitles.materials}
      extraButtonsList={[
        <Link key={1} to='/materials/add'>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
      aside={<Filters />}
    >
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen === 'remove' && (
            <StickyConfirmModal
              isOpen={modalIsOpen === 'remove'}
              onRequestClose={modalCloseHandler}
              title='Удаление материала'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину материал{' '}
                  <Typography.Text style={{ wordBreak: 'break-word' }} strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={handleRemove}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
