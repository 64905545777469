import React from 'react';
import { Button, Typography } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { CustomIcon, SvgIconNames } from 'components/icons/icons';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import { materialTypeHash } from '../MaterialItem/constants';
import {
  MaterialEnum,
  MaterialFileType,
  MaterialType,
  MaterialUrlType,
} from '../MaterialItem/types';
import styles from './Header.module.less';

export type HeaderProps = Partial<MaterialUrlType> &
  Partial<MaterialFileType> & {
    type: MaterialType;
    className?: string;
    move: (from: number, to: number) => void;
    index: number;
    fieldsLength: number;
    setIsOpen: () => void;
    isOpen: boolean;
    remove: () => void;
  };

export const Header = ({
  displayName = '',
  type,
  realName = '',
  url = '',
  path = '',
  move,
  index,
  fieldsLength,
  setIsOpen,
  isOpen,
  remove,
}: HeaderProps) => {
  const isFile = type === MaterialEnum.FILE;
  const downloadParam = isFile ? realName || displayName : undefined;
  const resourceUrl = isFile ? `${UPLOAD_SERVICE_BASE_URL}/${path}` : url;
  const isShowLink = (isFile && path) || url;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Typography.Title level={5} className={styles.title}>
          {displayName || 'Без названия'}
        </Typography.Title>
        <div className={styles.actions}>
          <Button
            onClick={() => move(index, index - 1)}
            icon={<ArrowUpOutlined />}
            disabled={index === 0}
          />
          <Button
            onClick={() => move(index, index + 1)}
            icon={<ArrowDownOutlined />}
            disabled={index + 1 >= fieldsLength}
          />
          <Button
            onClick={setIsOpen}
            icon={isOpen ? <UpOutlined /> : <DownOutlined />}
            className={styles.toggleVisibleButton}
          />
          <Button danger icon={<DeleteOutlined />} onClick={remove} />
        </div>
      </div>
      <div className={styles.file}>
        <Typography.Text>
          {materialTypeHash[type]}
          {isShowLink && (
            <Typography.Link
              href={`${resourceUrl}`}
              download={downloadParam}
              target='_blank'
              rel='noreferrer nofollow'
            >
              : {realName || resourceUrl}{' '}
              <CustomIcon name={SvgIconNames.ToThePage} />
            </Typography.Link>
          )}
        </Typography.Text>
      </div>
    </div>
  );
};
