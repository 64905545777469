import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router';

import { Login } from 'pages/auth/Login/Login';
import { PasswordResetRequest } from 'pages/auth/PasswordResetRequest/PasswordResetRequest';
import { PasswordResetConfirm } from 'pages/auth/PasswordResetConfirm/PasswordResetConfirm';
import { Registration } from 'pages/auth/Registration/Registration';
import { WaitingForRole } from 'pages/auth/WaitingForRole/WaitingForRole';
import { EmailConfirm } from 'pages/auth/EmailConfirm/EmailConfirm';
import { EmailIsSend } from 'pages/auth/EmailIsSend/EmailIsSend';
import { Role, Roles } from 'constants/roles';
import { BasicLayout } from 'components/Layout/BasicLayout/BasicLayout';
import { APP_ROUTES_BY_ROLES, ROUTES_PATHS_BY_ROLES } from 'constants/routes';

import { GuestRoute } from './customRoutes/guest';
import { ProtectedRoute } from './customRoutes/protected';

export const appRoutes: (role: Role) => RouteObject[] = role => [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/password-reset-request',
    element: <PasswordResetRequest />,
  },
  {
    path: '/password-reset-confirm',
    element: <PasswordResetConfirm />,
  },
  {
    path: '/registration',
    element: <Registration />,
  },
  {
    path: '/email-confirm',
    element: <EmailConfirm />,
  },
  {
    path: '/email-is-send',
    element: (
      <GuestRoute>
        <EmailIsSend />
      </GuestRoute>
    ),
  },
  {
    path: '/waiting-for-role',
    element: (
      <GuestRoute>
        <WaitingForRole />
      </GuestRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <BasicLayout>
          <Outlet />
        </BasicLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            replace
            to={ROUTES_PATHS_BY_ROLES[role][0] || '/attractions'}
          />
        ),
      },
      ...APP_ROUTES_BY_ROLES[role || Roles.Unauthorized],
    ],
  },
];
