export const ERROR_CODE_KEY = 'UPLOADS_UNSUPPORTED_FILE_TYPE';

export const DEFAULT_ACCEPT = '.png';

export const VALID_FORMATS_LIST = ['image/png'];

export enum SERVER_ERROR_DICTIONARY {
  UPLOADS_UNSUPPORTED_FILE_TYPE = 'У файла недопустимый формат. Загрузите файл с расширением .png',
}

export const MAX_FILE_SIZE = 1 * 1024 * 100;

export const SCREEN_WIDTH = 120;

export const SCREEN_HEIGTH = 120;
