import { FieldValues, UseFieldArrayReturn } from 'react-hook-form';

export interface MaterialItemProps {
  index: number;
  name: string;
  methods: UseFieldArrayReturn<FieldValues, string, 'id'>;
  className?: string;
  validFormatsList?: string[];
  accept?: string;
  uploadsUnsupportedFileType?: string;
}

export enum MaterialEnum {
  FILE = 'file',
  URL = 'url',
}
export type MaterialType = `${MaterialEnum}`;

export type MaterialValueRequestData = {
  url: string;
  displayName: string;
};
export interface MaterialValueRequest
  extends Partial<Record<MaterialType, MaterialValueRequestData>> {
  type: MaterialType;
}

export interface MaterialUrlType {
  url: string;
  displayName?: string;
}

export interface MaterialFileType {
  baseUrl: string;
  path: string;
  realName: string;
  displayName?: string;
}
export interface MaterialValue {
  type: MaterialType;
  url?: MaterialUrlType;
  file?: MaterialFileType;
}
