// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageInfoPopover-module__popoverText--MOzUn {\n  display: block;\n  margin-right: 9px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.ImageInfoPopover-module__popoverWrapper--vUVJs {\n  max-width: 400px;\n}\n@media (max-width: 480px) {\n  .ImageInfoPopover-module__popoverWrapper--vUVJs {\n    max-width: 200px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/view/components/ImageInfoPopover.module.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACA;EACI,gBAAA;AACJ;AAAI;EAAA;IACI,gBAAA;EAGN;AACF","sourcesContent":[".popoverText {\n    display: block;\n    margin-right: 9px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n.popoverWrapper {\n    max-width: 400px;\n    @media (max-width: 480px) {\n        max-width: 200px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverText": "ImageInfoPopover-module__popoverText--MOzUn",
	"popoverWrapper": "ImageInfoPopover-module__popoverWrapper--vUVJs"
};
export default ___CSS_LOADER_EXPORT___;
