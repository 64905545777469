import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { StatusesEnum } from 'constants/status';

import {
  IVacanciesItem,
  IVacanciesItemResponse,
  IVacanciesListParams,
  TVacanciesDraftItem,
} from './interfaces';

type TListResponse = {
  data: IVacanciesItemResponse[];
  total: number;
};

export const loadVacanciesList = createAsyncThunk<
  TListResponse,
  IVacanciesListParams,
  { state: RootState }
>('vacancies/loadList', async (params, { getState }) => {
  const { limit } = getState().vacancies;
  const { data } = await apiClient.get<TListResponse>(urls.api.vacancies.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadVacanciesItem = createAsyncThunk<
  IVacanciesItemResponse,
  string
>('vacancies/loadItem', async id => {
  const { data } = await apiClient.get<IVacanciesItemResponse>(
    getUrlWithParams(urls.api.vacancies.getOne, {
      id,
    })
  );
  return data;
});

export const saveVacanciesItem = createAsyncThunk<
  IVacanciesItemResponse,
  IVacanciesItem
>('vacancies/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.put<IVacanciesItemResponse>(
      getUrlWithParams(urls.api.vacancies.put, { id }),
      {
        ...params,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IVacanciesItemResponse>(
      urls.api.vacancies.post,
      {
        ...params,
      }
    );
    return data;
  }
});

export const saveVacanciesItemAsDraft = createAsyncThunk<
  IVacanciesItemResponse,
  TVacanciesDraftItem
>('vacancies/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.put<IVacanciesItemResponse>(
      getUrlWithParams(urls.api.vacancies.draft.put, { id }),
      {
        ...params,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IVacanciesItemResponse>(
      urls.api.vacancies.draft.post,
      {
        ...params,
      }
    );
    return data;
  }
});

export const deleteVacanciesItem = createAsyncThunk<
  unknown,
  {
    id: string;
  }
>('vacancies/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.vacancies.delete, { id: params.id }));
  return data;
});

export const setUnPublishVacancy = createAsyncThunk<void, string>(
  'vacancies/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.vacancies.status.patch, { id }),
      {
        status: StatusesEnum.NOT_PUBLISHED,
      }
    );
  }
);
