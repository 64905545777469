export const activitiesOptions = [
  {
    label: '-',
    value: '',
    id: 0,
    key: 0,
  },
  {
    label: 'Создание',
    value: 'POST',
    id: 1,
    key: 1,
  },
  {
    label: 'Удаление',
    value: 'DELETE',
    id: 2,
    key: 2,
  },
  {
    label: 'Изменение',
    value: 'PATCH,PUT',
    id: 3,
    key: 3,
  },
  {
    label: 'Изменение выборки главной',
    value: 'CHANGE_SORT_MAIN',
    id: 4,
    key: 4,
  },
   {
    label: 'Изменение выборки каталога',
    value: 'CHANGE_SORT',
    id: 5,
    key: 5,
  },
];
