export enum sourcePreview {
  articles = 'articles',
  news = 'news',
  attractions = 'attractions',
  resorts = 'resorts',
}

export interface IPreviewDataParams {
  source: sourcePreview;
  sourceId?: number;
  data: Record<string, any>;
}

export interface IPreviewDataResponse extends IPreviewDataParams {
  id: number;
}

export interface IPreviewSliceProps {
  id: number | undefined;
  loading: boolean;
}
