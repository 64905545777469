import React, { useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { MainLayout } from '../../../../components/Layout/MainLayout';
import { AboutCardForm } from './AboutCardForm';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ICardItem } from '../../../../store/slices/b2bCard/interfaces';
import {
  getCardItem,
  setCardItem,
} from '../../../../store/slices/b2bCard/actions';
import { mapCardItemValues } from '../../utils';
import CardSpin from '../../../../components/Card/CardSpin';

export const EditAbout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(true);
  const { cardItemId, cardId } = useParams();
  const state = useAppSelector(state => state.b2bCard);
  const onSubmit = (values: ICardItem) => {
    setIsLoading(true);
    dispatch(
      setCardItem({
        cardId,
        payload: { ...mapCardItemValues(values), id: Number(cardItemId) },
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/mainBusiness/about');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    dispatch(getCardItem({ cardItemId, cardId }))
      .unwrap()
      .finally(() => {
        setQueryLoading(false);
      });
  }, []);

  if (queryLoading) {
    return <CardSpin />;
  }
  return (
    <MainLayout pageTitle='Редактирование'>
      <Content>
        <AboutCardForm
          isLoading={isLoading}
          initialValues={state.cardItem}
          onSubmit={onSubmit}
        />
      </Content>
    </MainLayout>
  );
};
