export enum DefaultAuthTypesEnum {
  Email = 'email',
}
export enum SocialAuthTypesEnum {
  Vk = 'vk',
  Yandex = 'yandex',
  Esia = 'esia',
}

export type TAuthTypes = `${DefaultAuthTypesEnum}` | `${SocialAuthTypesEnum}`;

export const authTypesValueTextHash = {
  [DefaultAuthTypesEnum.Email]: 'Регистрация через почту',
  [SocialAuthTypesEnum.Vk]: 'Регистрация через Вконтакте',
  [SocialAuthTypesEnum.Yandex]: 'Регистрация через Яндекс.ID',
  [SocialAuthTypesEnum.Esia]: 'Регистрация через ЕСИА',
};
