import { omit } from 'utils/helpers';

import { StatusesEnum, statusesNamesHash } from './status';

export const b2bFilterStatuses = [
  { value: '', label: 'Все' },
  ...Object.keys(
    omit(statusesNamesHash, [StatusesEnum.ON_MODERATION, StatusesEnum.DECLINED])
  ).map(item => ({
    value: item,
    label: statusesNamesHash[item],
  })),
];
