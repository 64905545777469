import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Collapse } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormActions } from 'components/Layout/FormActions/FormActions';
import { SEO } from 'components/form/SEO/SEO';

import { IFormValues } from '../types';
import { validationSchema } from '../validation';
import { InfoForm } from './components/InfoForm/InfoForm';
import { DirectionForm } from './components/DirectionForm/DirectionForm';

interface IProps {
  onFinish: (values: IFormValues) => void;
  initialValues: IFormValues;
  isLoading: boolean;
}

export const PriorityIndustryForm = ({
  onFinish,
  initialValues,
  isLoading,
}: IProps) => {
  const [activeCollapseTabs, setActiveCollapseTabs] = useState([
    'directions',
    'seo',
  ]);

  const resolver = yupResolver(validationSchema);

  const methods = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver,
  });

  const handleCancel = () => {
    methods.reset(initialValues);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onFinish)}
        className='ant-form ant-form-vertical indent-top'
      >
        <InfoForm />
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollapseTabs(value)}
          activeKey={activeCollapseTabs}
        >
          <Collapse.Panel header='Список отраслей' key='directions'>
            <DirectionForm />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={handleCancel} disabled={isLoading}>
            Отмена
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            type='primary'
            htmlType='submit'
          >
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
