export enum entityTypeNames {
  attractions = 'Достопримечательности',
  resorts = 'Курорты',
  hotels = 'Отели',
  restaurants = 'Кафе и рестораны',
  catalog = 'Данные полей',
  articles = 'Статьи',
  events = 'События',
  routes = 'Маршруты',
  news = 'Новости',
  investProjects = 'Инвестиционные проекты',
  regulations = 'Нормативные документы',
  regions = 'Регионы',
  supportMeasures = 'Меры поддержки',
  purchases = 'Закупки',
  sales = 'Продажи',
  rent = 'Аренда',
  investInfrastructures = 'Инвестиционная инфраструктура',
  vacancies = 'Вакансии',
  contractExchanges = 'Биржа контрактов',
  materials = 'Материалы',
  projects = 'Проекты',
  applications = 'Заявки',
  contractConclusionsInfo = 'Сведения о заключенных договорах',
  investPassports = 'Паспорта',
  infrastructures = 'Инфраструктура',
  purchasePlans = 'План закупок',
  oezTerritory = 'Действующие резиденты',
}

export enum fieldTypeNames {
  tags = 'Теги',
  categories = 'Категории',
  hotelComforts = 'Главные удобства в отеле',
  hotelComfortGroups = 'Группы удобств',
  hotelFeatures = 'Особенности размещения',
  restaurantTypes = 'Тип заведения',
  foods = 'Кухни',
  features = 'Особенности ',
  routeTypes = 'Тип маршрута',
  routeLevels = 'Сложность',
  industries = 'Отрасль',
  getThere = 'Способ как добраться',
  supportLevels = 'Территориальный уровень',
  supportPeriods = 'Регулярность оказания',
  supportFormats = 'Формат',
  supportTypes = 'Тип поддержки',
  initiators = 'Инициатор',
  supportProblems = 'Типы проблем',
  purchaseMethodCatalogId = 'Способ закупки',
  saleMethodCatalogId = 'Способ процедуры продажи',
  rentMethodCatalogId = 'Способ процедуры аренды',
  territoryTypes = 'Тип территории',
  specialization = 'Специализация',
  productTypes = 'Направление/вид продукции',
  oezname = 'Территория ОЭЗ',
}

export type entityTypeNamesKeys = keyof typeof entityTypeNames;
export type fieldTypeNamesKeys = keyof typeof fieldTypeNames;
