export enum ApplicationsStatusesFormEnum {
  investInfrastructureResponds = 'investInfrastructureResponds',
  supportResponds = 'supportResponds',
  contactsResponds = 'contactsResponds',
  taxBenefitsResponds = 'taxBenefitsResponds',
  taxBenefitsCommonResponds = 'taxBenefitsCommonResponds',
  mobileResponds = 'mobileResponds',
}
export const applicationsStatusesFormNamesHash = {
  [ApplicationsStatusesFormEnum.investInfrastructureResponds]:
    'Получить консультацию',
  [ApplicationsStatusesFormEnum.supportResponds]: 'Поддержка',
  [ApplicationsStatusesFormEnum.contactsResponds]: 'Обратная связь',
  [ApplicationsStatusesFormEnum.taxBenefitsResponds]: 'Стать резидентом ЛК',
  [ApplicationsStatusesFormEnum.taxBenefitsCommonResponds]: 'Стать резидентом',
  [ApplicationsStatusesFormEnum.mobileResponds]: 'Обратная связь МП',
};

export const applicationsStatusesFormColorHash = {
  [ApplicationsStatusesFormEnum.investInfrastructureResponds]: 'blue',
  [ApplicationsStatusesFormEnum.supportResponds]: 'cyan',
  [ApplicationsStatusesFormEnum.contactsResponds]: 'geekblue',
  [ApplicationsStatusesFormEnum.taxBenefitsResponds]: 'gold',
  [ApplicationsStatusesFormEnum.taxBenefitsCommonResponds]: 'green',
  [ApplicationsStatusesFormEnum.mobileResponds]: 'purple',
};
