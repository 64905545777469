export enum NewsTopicEnum {
  Tourist = 'tourist',
  Business = 'business',
}

export type TNewsTopic = `${NewsTopicEnum}`;

export const newsTopicNamesHash = {
  [NewsTopicEnum.Tourist]: 'Туристам',
  [NewsTopicEnum.Business]: 'Бизнесу',
};
