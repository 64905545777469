import React from 'react';
import { Collapse } from 'antd';

import { useAppSelector } from 'store';
import {
  canViewOrganizationApplications,
  canViewOrganizationProjects,
} from 'utils/rights';

import { Requisites } from './components/Requisites/Requisites';
import { Users } from './components/Users/Users';
import { Applications } from './components/Applications/Applications';
import { Projects } from './components/Projects/Projects';

export const ItemInfo = () => {
  const { role } = useAppSelector(state => state.account);

  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([
    'requisites',
    'users',
    'applications',
    'projects',
  ]);

  return (
    <Collapse
      bordered={false}
      expandIconPosition='end'
      onChange={(value: string[]) => setActiveCollapseTabs(value)}
      activeKey={activeCollapseTabs}
    >
      <Collapse.Panel header='Реквизиты' key='requisites'>
        <Requisites />
      </Collapse.Panel>
      <Collapse.Panel header='Пользователи' key='users'>
        <Users />
      </Collapse.Panel>
      {canViewOrganizationApplications(role) && (
        <Collapse.Panel header='Заявки' key='applications'>
          <Applications />
        </Collapse.Panel>
      )}
      {canViewOrganizationProjects(role) && (
        <Collapse.Panel header='Проекты' key='projects'>
          <Projects />
        </Collapse.Panel>
      )}
    </Collapse>
  );
};
