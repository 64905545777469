import React from 'react';
import { Button, Row, Col, notification, Typography } from 'antd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store';
import {
  loadOrganization,
  setResident,
} from 'store/slices/organizations/actions';
import { Select } from 'components/form/base/Select';
import {
  DEFAULT_ACCEPT_ORGANIZATION,
  VALID_FORMATS_LIST_ORGANIZATION,
  ERROR_MESSAGE_ORGANIZATION,
  Roles,
  TRoles,
} from 'constants/organizations';
import { FormDocumentUpload } from 'components/form/FormDocumentUpload/FormDocumentUpload';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { IDocument } from 'types/document';
import { useDeepEffect } from 'utils/useDeepEffect';
import { downloadDocument } from 'utils/downloadFile';
import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import { validationSchema } from './formUtils';
import styles from './Role.module.less';

interface IFormValues {
  role: TRoles;
  documents: IDocument[];
  document: IDocument;
}

export const Role = () => {
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.organizations);

  const [isLoading, setIsLoading] = React.useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;

  const resolver = yupResolver(validationSchema);

  const methods = useForm<IFormValues>({
    defaultValues: {
      role: item.isResident ? 'resident' : 'noResident',
      documents: item.documents || [],
      document: null,
    },
    resolver,
  });

  const fieldMethods = useFieldArray({
    control: methods.control,
    name: 'documents',
  });

  const comment = methods.watch();

  const handleAddDocumentToArray = () => {
    if (comment.document) {
      const newValue = [...comment.documents, comment.document];
      methods.setValue('documents', newValue);
      methods.setValue('document', null);
      methods.clearErrors();
      notification.success({
        message: 'Файл успешно загружен',
      });
      setIsLoading(false);
    }
  };

  useDeepEffect(() => {
    handleAddDocumentToArray();
  }, [comment.document]);

  const cancelButtonHandler = () => {
    navigate(`/organizations/${id}`);
  };

  const handleDownload = (item: IDocument) => {
    const { path, realName } = item;
    downloadDocument(`${UPLOAD_SERVICE_BASE_URL}/${path}`, realName);
  };

  const submit = (values: IFormValues) => {
    let role = false;
    if (typeof values.role === 'string') {
      role = values.role === 'resident';
    } else {
      role = (values.role as Record<string, string>).value === 'resident';
    }
    dispatch(
      setResident({
        id: Number(id),
        isResident: role,
        documents: values.documents.map(({ realName, path }) => ({
          realName,
          baseUrl: UPLOAD_SERVICE_BASE_URL,
          path,
        })),
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Роль успешно изменена',
        });
        dispatch(loadOrganization(id));
      })
      .finally(() => setIsLoading(false));
  };

  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: '',
      render: (_: unknown, { realName }: IDocument) => (
        <Typography.Text>{realName.split('.')[0]}</Typography.Text>
      ),
    },
    {
      key: 2,
      title: '',
      width: 60,
      dataIndex: 'actions',
      render: (_: unknown, item: IDocument) => (
        <Button
          onClick={() => handleDownload(item)}
          icon={<DownloadOutlined />}
        />
      ),
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Row>
          <Select
            style={{ width: isMobile ? '100%' : '320px' }}
            name='role'
            label='Роль'
            options={Object.entries(Roles).map(([value, label]) => ({
              label,
              value,
            }))}
          />
        </Row>
        {fieldMethods.fields.length > 0 ? (
          <Col>
            <CommonTable
              className={styles.table}
              bordered={false}
              columns={columns}
              dataSource={fieldMethods.fields}
              total={fieldMethods.fields.length}
              limit={5}
            />
          </Col>
        ) : null}
        <Col
          style={{
            marginTop: fieldMethods.fields.length > 0 ? 24 : 0,
            marginBottom: 64,
          }}
        >
          <FormDocumentUpload
            name='document'
            title='Загрузить файл'
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            accept={DEFAULT_ACCEPT_ORGANIZATION}
            validFormatsList={VALID_FORMATS_LIST_ORGANIZATION}
            errorMessage={ERROR_MESSAGE_ORGANIZATION}
          />
        </Col>
        {methods.formState.errors.documents ? (
          <Col style={{ color: '#ff4d4f' }}>
            {methods.formState.errors.documents.message}
          </Col>
        ) : null}
        <Row gutter={[18, 0]} justify='end' align='middle'>
          <Col>
            <Button onClick={cancelButtonHandler} disabled={isLoading}>
              Отмена
            </Button>
          </Col>
          <Col>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Сохранить
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};
