// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Users-module__row--nni9P .ant-form-item {\n  margin-bottom: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Organizations/components/ItemControl/components/Users/Users.module.less"],"names":[],"mappings":"AAAA;EAGG,2BAAA;AADH","sourcesContent":[".row {\n\t:global {\n\t\t.ant-form-item {\n\t\t\tmargin-bottom: 0 !important;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Users-module__row--nni9P"
};
export default ___CSS_LOADER_EXPORT___;
