import { createSlice } from '@reduxjs/toolkit';

import { IPriorityState } from './interfaces';
import { getPriority } from './actions';

export const blockPrioritySlice = createSlice({
  name: 'mainBusiness',
  initialState: {
    components: [],
  } as IPriorityState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPriority.fulfilled, (state, { payload }) => {
      state.components = payload.data;
    });
  },
});
