import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveInvestmentPassportsItem,
  saveInvestmentPassportsItemAsDraft,
} from 'store/slices/investmentPassports/actions';
import {
  IInvestmentPassportsItem,
  TInvestmentPassportsDraftItem,
} from 'store/slices/investmentPassports/interfaces';

import { InvestmentPassportsForm } from './components/InvestmentPassportsForm';
import { DEFAULT_STATE } from './components/constants';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IInvestmentPassportsItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentPassportsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investmentPassports');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TInvestmentPassportsDraftItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentPassportsItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investmentPassports');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/investmentPassports');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <InvestmentPassportsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={DEFAULT_STATE}
      />
    </MainLayout>
  );
};
