import React from 'react';

import { List } from './List';
export const activitiesRoutes = {
  path: '/activities',
  children: [
    {
      path: '',
      element: <List />,
    },
  ],
};
