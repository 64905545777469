// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TouristForm-module__row--qQSaA .ant-descriptions-item-container .ant-descriptions-item-label::after {\n  content: '';\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Tourists/components/TouristForm.module.less"],"names":[],"mappings":"AAMQ;EACE,WAAA;AALV","sourcesContent":[".row {\n  :global {\n    \n    .ant-descriptions-item-container {\n\n      .ant-descriptions-item-label {\n        &::after {\n          content: '';\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "TouristForm-module__row--qQSaA"
};
export default ___CSS_LOADER_EXPORT___;
