import React from 'react';
import { Button, Form, Row, Typography } from 'antd';
import { DeleteOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Content as AntContent } from 'antd/lib/layout/layout';
import classNames from 'classnames';

import styles from './GetThereContainer.module.less';

interface IPlaceItemProps {
  number: number;
  handleClear: () => void;
  children: React.ReactNode;
  error: any;
}

export const GetThereContainer: React.FC<IPlaceItemProps> = React.forwardRef(
  (
    { number, handleClear, children, error },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const [isOpenContent, setIsOpenContent] = React.useState(true);

    return (
      <Form.Item
        help={error ? 'Проверьте правильность заполнения полей' : ''}
        validateStatus={error ? 'error' : 'success'}
        style={{ marginBottom: 24 }}
      >
        <Row
          className={classNames(styles.placeContainer, {
            [styles.error]: error,
          })}
        >
          <Row
            className={classNames(
              styles.placeHeaderContainer,
              !isOpenContent && {
                [styles.isOpenContent]: !isOpenContent,
              },
              isOpenContent && {
                [styles.error]: error,
              }
            )}
            justify='space-between'
            wrap={false}
          >
            <div
              className={styles.placeHeaderTextArea}
              onClick={() => setIsOpenContent(!isOpenContent)}
            >
              <Typography.Text className={styles.placeHeaderText}>
                {number}. Способ добраться
              </Typography.Text>
            </div>
            <div className={styles.actionContainer}>
              <Button
                danger
                type='text'
                onClick={handleClear}
                icon={<DeleteOutlined />}
              />
              <Button
                type='text'
                style={{ marginRight: 8 }}
                onClick={() => setIsOpenContent(!isOpenContent)}
                icon={isOpenContent ? <DownOutlined /> : <RightOutlined />}
              />
            </div>
          </Row>

          <div ref={ref} tabIndex={0} style={{ width: '100%' }}>
            {isOpenContent && (
              <AntContent style={{ minWidth: 0 }}>{children}</AntContent>
            )}
          </div>
        </Row>
      </Form.Item>
    );
  }
);

GetThereContainer.displayName = 'GetThereContainer';
