import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import { IContractExchange, TContractExchangeResponse } from './interfaces';

type TListResponse = {
  data: IContractExchange[];
  total: number;
};

export const loadContractExchangeList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    statuses: string[];
  },
  { state: RootState }
>('contractExchange/loadList', async (params, { getState }) => {
  const { limit } = getState().contractExchange;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.contractExchange.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadContractExchangeItem = createAsyncThunk<
  IContractExchange,
  string
>('contractExchange/loadItem', async id => {
  const { data } = await apiClient.get<IContractExchange>(
    getUrlWithParams(urls.api.contractExchange.getOne, {
      id,
    })
  );
  return data;
});

export const saveContractExchangeItem = createAsyncThunk<
  TContractExchangeResponse,
  TContractExchangeResponse
>('contractExchange/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.put<IContractExchange>(
      getUrlWithParams(urls.api.contractExchange.put, { id }),
      {
        ...params,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IContractExchange>(
      urls.api.contractExchange.post,
      {
        ...params,
      }
    );
    return data;
  }
});

export const setPublishContractExchange = createAsyncThunk<void, string>(
  'contractExchange/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.contractExchange.publish.patch, { id })
    );
  }
);

export const setUnPublishContractExchange = createAsyncThunk<void, string>(
  'contractExchange/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.contractExchange.unPublish.patch, { id })
    );
  }
);

export const removeContractExchangeItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: number;
  }
>('contractExchange/removeItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.contractExchange.delete, { id: id }));
  return data;
});
