import React from 'react';
import { Form, InputNumber, Tooltip } from 'antd';
import { Controller } from 'react-hook-form';

interface IInputRatingProps {
  name: string;
  label?: string;
  placeholder?: string;
  step: number;
  min: number;
  max: number;
  required?: boolean;
  allowClear?: boolean;
}

export const InputRating: React.FC<IInputRatingProps> = ({
  name,
  label,
  required,
  ...props
}) => {
  const handleChange = (onChange, value) => {
    if (Number(value) > props.min && !Number.isInteger(value)) {
      onChange(Number(value).toFixed(1));
    } else {
      onChange(value);
    }
  };

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          required={required}
        >
          <Tooltip
            placement='top'
            title={`Введите число от ${props.min} до ${props.max}`}
          >
            <InputNumber
              {...props}
              {...field}
              onChange={value => handleChange(field.onChange, value)}
              stringMode
              style={{ width: '100%', minWidth: 100 }}
            />
          </Tooltip>
        </Form.Item>
      )}
    />
  );
};
