import { useEffect, useState } from 'react';

export enum BreakpointsEnum {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export type Breakpoints = `${BreakpointsEnum}`;

export type TUseBreakPointsResponse = {
  breakpoint: Breakpoints;
  width: number;
};

export const mediaQueryBreakpoints = {
  [BreakpointsEnum.mobile]: 800,
  [BreakpointsEnum.tablet]: 1200,
};

export const useBreakpoints = (): TUseBreakPointsResponse => {
  const [breakpoint, setBreakpoint] = useState(BreakpointsEnum.desktop);
  const [width, setWidth] = useState(0);

  const onResize = () => {
    const windowWidth = window.innerWidth;
    setWidth(windowWidth);

    if (windowWidth <= mediaQueryBreakpoints.mobile) {
      setBreakpoint(BreakpointsEnum.mobile);
    }
    if (
      windowWidth > mediaQueryBreakpoints.mobile &&
      windowWidth < mediaQueryBreakpoints.tablet
    ) {
      setBreakpoint(BreakpointsEnum.tablet);
    }
    if (windowWidth >= mediaQueryBreakpoints.tablet) {
      setBreakpoint(BreakpointsEnum.desktop);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    if (typeof window !== 'undefined') {
      onResize();
    }
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return { breakpoint, width };
};
