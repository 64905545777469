import { DEFAULT_ORGANIZATION_NAME } from 'constants/organizations';
import { OrganizationsItem, OrganizationTypeEnum } from 'types/organizations';

export const getOrganizationName = (
  organizationData?: OrganizationsItem
): string => {
  if (!organizationData || organizationData.isDraft) {
    return DEFAULT_ORGANIZATION_NAME;
  }
  if (organizationData.type === OrganizationTypeEnum.ip) {
    const { lastName, firstName, secondName } = organizationData;
    return `ИП ${lastName} ${firstName?.charAt(0)}. ${secondName?.charAt(0)}.`;
  } else {
    return organizationData.shortName;
  }
};
