import React from 'react';
import { Button } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useFormContext, useWatch } from 'react-hook-form';

import styles from './MenuItemActions.module.less';

interface IProps {
  name: string;
  parentName?: string;
  onMove: (index: number, step: number) => void;
  onRemove: (index: number) => void;
  index: number;
  fieldsLength: number;
}

export const MenuItemActions = ({
  name,
  parentName,
  onMove,
  onRemove,
  index,
  fieldsLength,
}: IProps) => {
  const { setValue, control } = useFormContext();

  const isActive = useWatch({
    name: `${name}.isActive`,
    control,
  });

  const parentIsActive = useWatch({
    name: `${parentName}.isActive`,
    control,
  });

  const handleChangeActive = () => {
    setValue(`${name}.isActive`, !isActive);
  };

  return (
    <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
      <Button
        size='small'
        onClick={handleChangeActive}
        disabled={parentIsActive !== undefined && !parentIsActive}
      >
        {isActive ? 'Деактивировать' : 'Активировать'}
      </Button>
      <div>
        <Button
          size='small'
          onClick={() => onMove(index, index - 1)}
          icon={<ArrowUpOutlined />}
          disabled={index === 0}
        />
        <Button
          size='small'
          onClick={() => onMove(index, index + 1)}
          icon={<ArrowDownOutlined />}
          disabled={index + 1 >= fieldsLength}
          style={{ marginLeft: 4 }}
        />
        <Button
          size='small'
          onClick={() => onRemove(index)}
          className={styles.deleteBtn}
          type='text'
          danger
          icon={<DeleteOutlined />}
        />
      </div>
    </div>
  );
};
