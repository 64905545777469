import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IInvestmentPassportsItem,
  TInvestmentPassportsDraftItem,
} from 'store/slices/investmentPassports/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadInvestmentPassportsItem,
  saveInvestmentPassportsItem,
  saveInvestmentPassportsItemAsDraft,
} from 'store/slices/investmentPassports/actions';
import { StatusesEnum } from 'constants/status';

import { InvestmentPassportsForm } from './components/InvestmentPassportsForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.investmentPassports);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadInvestmentPassportsItem(id));
  }, []);

  const onFinish = (values: IInvestmentPassportsItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentPassportsItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/investmentPassports');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TInvestmentPassportsDraftItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentPassportsItemAsDraft({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/investmentPassports');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/investmentPassports');
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item?.id && String(item?.id) === id ? (
        <InvestmentPassportsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft={item.status === StatusesEnum.DRAFT}
          isLoading={isLoading}
          initialValues={mapValuesToForm(item)}
        />
      ) : null}
    </MainLayout>
  );
};
