// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvestmentForm-module__formItem--Dt2Ia {\n  margin-top: 8px;\n}\n.InvestmentForm-module__gallerySection--HgvSe {\n  margin-top: 22px;\n}\n.InvestmentForm-module__materialSection--ze3lS {\n  margin-top: 49px;\n}\n.InvestmentForm-module__materialItem--bPGOZ {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/InvestmentProjects/components/InvestmentForm/InvestmentForm.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,gBAAA;AACF","sourcesContent":[".formItem {\n  margin-top: 8px;\n}\n.gallerySection{\n  margin-top: 22px;\n}\n.materialSection {\n  margin-top: 49px;\n}\n.materialItem{\n  margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "InvestmentForm-module__formItem--Dt2Ia",
	"gallerySection": "InvestmentForm-module__gallerySection--HgvSe",
	"materialSection": "InvestmentForm-module__materialSection--ze3lS",
	"materialItem": "InvestmentForm-module__materialItem--bPGOZ"
};
export default ___CSS_LOADER_EXPORT___;
