import React from 'react';

import { PageTitles } from 'constants/pageTitles';

import { Alert } from './Alert';

export const alertB2BRoutes = {
  path: '/alertB2B',
  children: [
    {
      path: '',
      element: <Alert pageTitle={PageTitles.alertB2B} type='B2B' />,
    },
  ],
};
export const alertB2CRoutes = {
  path: '/alertB2C',
  children: [
    {
      path: '',
      element: <Alert pageTitle={PageTitles.alertB2C} type='B2C' />,
    },
  ],
};
