import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Col, notification, Row, Space, Tag, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { EnvironmentOutlined, WalletOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import { contactIconsHash } from 'constants/contacts';
import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import {
  loadContractExchangeItem,
  removeContractExchangeItem,
  saveContractExchangeItem,
  setPublishContractExchange,
  setUnPublishContractExchange,
} from 'store/slices/contractExchange/actions';
import { statusesColorsHash } from 'constants/status';
import Yup from 'utils/yup';
import {
  ContractExchangeStatusesEnum,
  contractExchangeStatusesNames,
} from 'constants/contractsStatus';
import { adsTypes } from 'constants/contracts';
import { makeAddressString } from 'utils/entities';
import { formatPhoneNumber } from 'utils/format';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { Text as TextContent } from 'components/view/ViewContent/Text';
import { getName } from 'pages/Organizations/utils';
import { clearContractExchangeItemData } from 'store/slices/contractExchange/contractExchangeSlice';
import { contactsFormat } from 'utils/contacts';

import { mapValues } from '../components/formUtils';
import { getOrganizationName } from '../utils';
import styles from './View.module.less';

const validationSchema = Yup.object().shape({
  declineReason: Yup.string().max(800).required(),
});

export const View = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalCancel, setIsModalCancel] = React.useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { item } = useAppSelector(state => state.contractExchange);
  const { b2bUrl } = useAppSelector(state => state.configSlice);
  const loadContractExchange = () => {
    dispatch(loadContractExchangeItem(id));
  };

  const creatorData = getName({
    firstName: item.creatorData?.firstName,
    secondName: item.creatorData?.secondName,
    lastName: item.creatorData?.lastName,
  });
  const editorData = getName({
    firstName: item.editorData?.firstName,
    secondName: item.editorData?.secondName,
    lastName: item.editorData?.lastName,
  });

  const resolver = yupResolver(validationSchema);

  const isDeclined = item.status === ContractExchangeStatusesEnum.DECLINED;
  const isPublished = item.status === ContractExchangeStatusesEnum.PUBLISHED;
  const isOnModeration =
    item.status === ContractExchangeStatusesEnum.ON_MODERATION;
  const isNotPublished =
    item.status === ContractExchangeStatusesEnum.NOT_PUBLISHED;

  const methods = useForm({
    defaultValues: { declineReason: '' },
    resolver,
  });
  const declineReason = methods.watch('declineReason');

  React.useEffect(() => {
    loadContractExchange();
  }, []);

  const handlePublish = async () => {
    await dispatch(setPublishContractExchange(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно опубликован',
        });
        loadContractExchange();
      });
  };

  const handleDelete = async id => {
    await dispatch(removeContractExchangeItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно удален',
        });
        dispatch(clearContractExchangeItemData());
        navigate('/contractExchange');
      });
  };

  const handleUnPublish = async () => {
    await dispatch(setUnPublishContractExchange(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно снят с публикации',
        });
        loadContractExchange();
      });
  };

  const handleDecline = async () => {
    await dispatch(
      saveContractExchangeItem({
        id,
        ...{
          ...mapValues(item),
          description: item.description,
          status: ContractExchangeStatusesEnum.DECLINED,
          declineReason,
        },
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно отклонен',
        });
        setIsModalCancel(false);
        loadContractExchange();
      });
  };

  return (
    <MainLayout
      pageTitle='Просмотр'
      extraButtonsList={[
        <div key={1} className={styles.extraButtons}>
          {isPublished && (
            <React.Fragment>
              <Button onClick={handleUnPublish}>Снять с публикации</Button>
              <Typography.Link
                href={`${b2bUrl}/contract-exchange/${item.id}`}
                target='_blank'
                rel='noreferrer nofollow'
              >
                <Button>Показать на сайте</Button>
              </Typography.Link>
            </React.Fragment>
          )}
          <Link to={`/contractExchange/${id}/edit`}>
            <Button>Редактировать</Button>
          </Link>
          <Button
            danger
            onClick={() => {
              if (isPublished) {
                notification.error({
                  message: 'Необходимо снять сущность с публикации',
                });
              } else {
                setIsModalOpen(true);
              }
            }}
          >
            Удалить
          </Button>
        </div>,
      ]}
      aside={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div>
            <Content>
              <Row style={{ flexFlow: 'column nowrap' }}>
                <Typography style={{ marginBottom: '17px', color: '#8C8C8C' }}>
                  Статус
                </Typography>
                <Space direction='vertical' size='small'>
                  <Tag color={statusesColorsHash[item.status]}>
                    {contractExchangeStatusesNames[item.status]}
                  </Tag>
                </Space>
              </Row>
            </Content>
          </div>
          {item.id && item.id === id ? (
            <div>
              <Content>
                <Row style={{ flexFlow: 'column nowrap' }}>
                  <Typography.Title level={5} style={{ marginBottom: '17px' }}>
                    Контакты
                  </Typography.Title>
                  <Space direction='vertical' size='small'>
                    <Typography>
                      <div>{getOrganizationName(item.organization)}</div>
                    </Typography>
                    {item.contactPhone && (
                      <Typography.Link
                        className={styles.contactItem}
                        href={contactsFormat({
                          value: item.contactPhone,
                          type: DefaultContactOptionsTypes.Phone,
                        })}
                      >
                        <span className={styles.contactIconPhone}>
                          {contactIconsHash[DefaultContactOptionsTypes.Phone]}
                        </span>
                        <span className={styles.contact}>
                          {formatPhoneNumber(item.contactPhone)}
                        </span>
                      </Typography.Link>
                    )}
                    {item.contactEmail && (
                      <Typography.Link
                        className={styles.contactItem}
                        href={`mailto:${item.contactEmail}`}
                        target='_blank'
                      >
                        <span className={styles.contactIcon}>
                          {contactIconsHash[DefaultContactOptionsTypes.Email]}
                        </span>
                        <span className={styles.contact}>
                          {item.contactEmail}
                        </span>
                      </Typography.Link>
                    )}
                    {item.contactWebsite && (
                      <Typography.Link
                        className={styles.contactItem}
                        href={item.contactWebsite}
                        target='_blank'
                      >
                        <span className={styles.contactIcon}>
                          {contactIconsHash[DefaultContactOptionsTypes.Site]}
                        </span>
                        <span className={styles.contact}>
                          {item.contactWebsite}
                        </span>
                      </Typography.Link>
                    )}
                  </Space>
                </Row>
              </Content>
            </div>
          ) : null}
        </div>
      }
    >
      {(isOnModeration || isDeclined || isNotPublished) && (
        <Content
          style={{
            marginBottom: '24px',
          }}
        >
          <Row>
            <Col>
              <Typography style={{ color: '#8C8C8C' }}>
                Модерация контента
              </Typography>
              <div
                style={{
                  marginTop: '8px',
                  display: 'flex',
                  gap: '8px',
                }}
              >
                {isOnModeration && (
                  <Button danger onClick={() => setIsModalCancel(true)}>
                    Отклонить
                  </Button>
                )}
                <Button type='primary' onClick={handlePublish}>
                  Опубликовать
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
      )}
      <Content>
        <Row>
          <Typography>
            <Tag color='blue'>{adsTypes[item.type]}</Tag>
          </Typography>
        </Row>
        <Row>
          <Typography
            style={{
              fontSize: '20px',
              lineHeight: '28ps',
              fontWeight: 400,
              marginTop: '24px',
            }}
          >
            {item.name}
          </Typography>
        </Row>
        <Row>
          <Typography
            style={{
              marginTop: '16px',
              wordBreak: 'break-word',
            }}
          >
            {item.productTypeData?.name}
          </Typography>
        </Row>
        {makeAddressString(item.address, { exclude: [] }) && (
          <Row
            style={{
              marginTop: '18px',
            }}
          >
            <Typography>
              <EnvironmentOutlined
                style={{ fontSize: '22px', marginRight: '12px' }}
              />
              {makeAddressString(item.address, { exclude: [] })}
            </Typography>
          </Row>
        )}
        <Row
          style={{
            marginTop: '20px',
          }}
        >
          <WalletOutlined style={{ fontSize: '22px', marginRight: '12px' }} />
          <Typography>
            {item.price ? `${item.price / 100} ₽` : 'Стоимость не указана'}
          </Typography>
        </Row>
        {item.description && (
          <Row>
            <Typography
              style={{
                marginTop: '16px',
              }}
            >
              <TextContent
                text={item.description
                  .replace(/\n/g, '<br>')
                  .replace(/\u00A0/g, ' ')}
              />
            </Typography>
          </Row>
        )}
      </Content>
      <div className={styles.activityData}>
        <Content>
          <Row>
            <Typography style={{ color: '#8C8C8C' }}>Автор</Typography>
          </Row>
          <Row>
            <Typography style={{ marginTop: '8px' }}>{creatorData}</Typography>
          </Row>
          <Row>
            <Typography style={{ color: '#8C8C8C', marginTop: '8px' }}>
              Дата создания
            </Typography>
          </Row>
          <Row>
            <Typography style={{ marginTop: '8px' }}>
              {dayjs(item.createdAt).format('DD.MM.YY')}
            </Typography>
          </Row>
        </Content>
        {item.updatedAt && (
          <Content>
            {editorData && (
              <React.Fragment>
                <Row>
                  <Typography style={{ color: '#8C8C8C' }}>
                    Последние изменения
                  </Typography>
                </Row>

                <Row>
                  <Typography style={{ marginTop: '8px' }}>
                    {editorData}
                  </Typography>
                </Row>
              </React.Fragment>
            )}
            <Row>
              <Typography style={{ color: '#8C8C8C', marginTop: '8px' }}>
                Дата последних изменений
              </Typography>
            </Row>
            <Row>
              <Typography style={{ marginTop: '8px' }}>
                {dayjs(item.updatedAt).format('DD.MM.YY')}
              </Typography>
            </Row>
          </Content>
        )}
        {item.publishedAt && (
          <Content>
            <Row>
              <Typography style={{ color: '#8C8C8C' }}>
                Дата публикации
              </Typography>
            </Row>
            <Row>
              <Typography style={{ marginTop: '8px' }}>
                {dayjs(item.publishedAt).format('DD.MM.YY')}
              </Typography>
            </Row>
          </Content>
        )}
        {isModalOpen && (
          <StickyConfirmModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            title='Удаление'
            okText='Удалить'
            text={
              <div>
                Вы хотите убрать с портала{' '}
                <Typography.Text strong>{item.name || ''}</Typography.Text>?
              </div>
            }
            onSubmit={() => handleDelete(item.id)}
            okButtonProps={{ danger: true }}
          />
        )}
        {isModalCancel && (
          <StickyConfirmModal
            isOpen={isModalCancel}
            onRequestClose={() => setIsModalCancel(false)}
            title='Отклонить'
            okText='Отправить'
            text={
              <FormProvider {...methods}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className='ant-form ant-form-vertical'
                  style={{ width: '100%' }}
                >
                  <div className={styles.declineFormArea}>
                    <TextAreaField
                      name='declineReason'
                      label='Причина отклонения'
                      placeholder='Введите причину'
                      maxLength={800}
                      rows={2}
                    />
                  </div>
                </form>
              </FormProvider>
            }
            onSubmit={() => methods.handleSubmit(handleDecline)()}
          />
        )}
      </div>
    </MainLayout>
  );
};
