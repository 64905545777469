export enum MainTourismKind {
  Slider = 'slider',
  Resorts = 'resorts',
  Attractions = 'attractions',
  Events = 'events',
  TouristRoutes = 'touristRoutes',
  Places = 'places',
  News = 'news',
  Map = 'map',
  Articles = 'articles',
}

export const mainTourismHeaders = {
  [MainTourismKind.Slider]: 'Слайдер',
  [MainTourismKind.Resorts]: 'Выборка - Курорты',
  [MainTourismKind.Attractions]: 'Выборка - Достопримечательности',
  [MainTourismKind.Events]: 'Выборка - События',
  [MainTourismKind.TouristRoutes]: 'Выборка - Маршруты',
  [MainTourismKind.Places]: 'Что посетить?',
  [MainTourismKind.News]: 'Новости',
  [MainTourismKind.Map]: 'Карта',
  [MainTourismKind.Articles]: 'Статьи',
};
