import React from 'react';
import { Content } from 'antd/lib/layout/layout';

import { getActivities } from 'store/slices/activities/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';
import { useAppDispatch, useAppSelector } from '../../store';
import { useQuery } from '../../hooks/useQuery';
import { useDeepEffect } from '../../utils/useDeepEffect';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => {
    return state.activities;
  });

  const loadNews = () => {
    dispatch(
      getActivities({
        limit,
        ...query,
        sort,
      })
    );
  };

  useDeepEffect(() => {
    loadNews();
  }, [query, sort]);

  return (
    <MainLayout
      pageTitle={PageTitles['activities']}
      aside={<Filters setSort={setSort} />}
    >
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={null}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
