export enum ContractExchangeStatusesEnum {
  PUBLISHED = 'published',
  ON_MODERATION = 'onModeration',
  DECLINED = 'declined',
  NOT_PUBLISHED = 'notPublished',
  // TO_BE_PUBLISHED = 'toBePublished',
}

export const contractExchangeStatusesNames = {
  [ContractExchangeStatusesEnum.PUBLISHED]: 'Опубликовано',
  [ContractExchangeStatusesEnum.ON_MODERATION]: 'На модерации',
  [ContractExchangeStatusesEnum.DECLINED]: 'Отклонено',
  [ContractExchangeStatusesEnum.NOT_PUBLISHED]: 'Снято с публикации',
};
