import {
  ICardItem,
  ICardRequest,
  ICardResponse,
} from '../../store/slices/b2bCard/interfaces';
import { IHistoryItem } from '../../store/slices/b2bHistory/interfaces';
import { IComponent } from '../../store/slices/blockPriority/interfaces';

export const mapCardItemValues = (values: ICardItem): ICardItem => {
  return {
    id: values.id,
    description: values.description || '',
    sortPriority: values.sortPriority || null,
    subtitle: values.subtitle || '',
    link: values.link || '',
    title: values.title || '',
  };
};

export const mapPriorityValues = (values: IComponent[]): IComponent[] => {
  return values.map((component, index) => ({
    id: component.id,
    sortPriority: (index + 1) * 100,
    name: component.name,
  }));
};

export const mapCardValues = (values: ICardResponse): ICardRequest => {
  return {
    id: values.id,
    title: values.title,
    description: values.description,
    language: values.language,
    cardType: values.cardType,
  };
};

export const mapHistoryValues = (values: IHistoryItem): IHistoryItem => {
  return {
    id: values.id,
    title: values.title,
    sortPriority: values.sortPriority,
    company: values.company,
    investVolume: String(values.investVolume),
    kavkazShare: String(values.kavkazShare),
    workPlaces: String(values.workPlaces),
    link: values.link,
    region: values.region,
  };
};
