import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import { ITouristItem, ITouristItemResponse } from './interfaces';
import { IReviewsItemResponse } from '../reviews/interfaces';

type TListResponse = {
  data: ITouristItemResponse[];
  total: number;
};

type TReviewsListResponse = {
  data: IReviewsItemResponse[];
  total: number;
};

export const loadTouristList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    sort?: string;
  },
  { state: RootState }
>('tourist/loadList', async (params, { getState }) => {
  const { limit } = getState().admins;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.users.tourist.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadTouristItem = createAsyncThunk<ITouristItemResponse, string>(
  'tourist/loadItem',
  async id => {
    const { data } = await apiClient.get<ITouristItemResponse>(
      getUrlWithParams(urls.api.users.getOne.tourist, { id })
    );
    return data;
  }
);

export const saveTouristItem = createAsyncThunk<
  ITouristItem,
  ITouristItem & { id: number }
>('tourist/saveItem', async ({ id, ...params }) => {
  const { data } = await apiClient.patch<ITouristItemResponse>(
    getUrlWithParams(urls.api.users.patch.tourist, { id }),
    {
      ...params,
    }
  );
  return data;
});

export const loadTouristReviews = createAsyncThunk<
  TReviewsListResponse,
  {
    id?: string;
    limit?: number;
    offset?: number;
  },
  { state: RootState }
>('tourist/loadReviews', async params => {
  const { data } = await apiClient.get(urls.api.reviews.admin.get, {
    params: {
      limit: params.limit,
      user: params.id,
      offset: params.offset,
    },
  });
  return data;
});
