import React from 'react';
import { Button, Card, Col, Descriptions, notification, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { PaperClipOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from 'store';
import { updateReportItem } from 'store/slices/reports/actions';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { Select } from 'components/form/base/Select';
import { getName } from 'pages/Reports/components/utils';
import {
  reportName,
  reportsOptions,
  reportStatusName,
  TReportStatus,
} from 'constants/reports';
import { IDocument } from 'types/document';
import { downloadDocument } from 'utils/downloadFile';
import { OrganizationTypeEnum } from 'types/organizations';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import styles from './CommonInfo.module.less';

type TOption = { value: TReportStatus; label: string };

interface IFormValues {
  status: TOption;
}

export const CommonInfo = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    item: { status, type, createdAt, organizationData, report },
  } = useAppSelector(state => state.reports);
  const dispatch = useAppDispatch();

  const defaultValues = React.useMemo(
    () => ({ status: { value: status, label: reportStatusName[status] } }),
    [status]
  );

  const methods = useForm<IFormValues>({
    defaultValues,
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const handleReset = () => reset(defaultValues);

  const handleCancel = () => isDirty && handleReset();

  const handleChange = (values: IFormValues) => {
    setIsLoading(true);
    dispatch(updateReportItem({ status: values.status.value }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Статус отчета обновлен',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Статус не обновлен',
        });
        handleReset();
      })
      .finally(() => setIsLoading(false));
  };

  const handleDownload = (file: IDocument) =>
    downloadDocument(`${UPLOAD_SERVICE_BASE_URL}/${file.path}`, file.realName);

  return (
    <FormProvider {...methods}>
      <form className='ant-form ant-form-vertical'>
        <Card
          className={styles.title}
          title='Общая информация'
          bordered={false}
        >
          <Row>
            <Col xs={24} md={12} xl={6}>
              <Select
                name='status'
                label='Статус отчета'
                options={reportsOptions.slice(1)}
              />
            </Col>
          </Row>
          <Descriptions
            labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
            title=''
            size='small'
            column={4}
            layout='vertical'
          >
            <Descriptions.Item
              className={styles.row}
              label='Тип отчетности'
              span={4}
            >
              {reportName[type]}
            </Descriptions.Item>
            <Descriptions.Item
              className={styles.row}
              label='Дата и время загрузки'
              span={4}
            >
              {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item
              className={styles.row}
              label='Название организации'
              span={4}
            >
              {(organizationData.type === OrganizationTypeEnum.organization
                ? organizationData.shortName
                : getName({
                    firstName: organizationData.firstName,
                    secondName: organizationData.secondName,
                    lastName: organizationData.lastName,
                  })) || 'Не заполнено'}
            </Descriptions.Item>
          </Descriptions>
          {report && (
            <Row>
              <Col>
                <Button
                  type='link'
                  onClick={() => handleDownload(report)}
                  className={styles.link}
                >
                  <PaperClipOutlined className={styles.icon} />
                  {report.realName}
                </Button>
              </Col>
            </Row>
          )}
        </Card>
        <FormActions>
          <Button onClick={handleCancel} disabled={isLoading || !isDirty}>
            Отмена
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            disabled={isLoading || !isDirty}
            onClick={() => methods.handleSubmit(handleChange)()}
          >
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
