import React from 'react';

import { List } from './List';
import { Edit } from './Edit';

export const routesSampleRoutes = {
  path: '/routesSample',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: ':id/edit',
      element: <Edit slug='/routesSample' />,
    },
    {
      path: 'add',
      element: <Edit slug='/routesSample' />,
    },
  ],
};
