import React, { useCallback } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import { TAsyncPaginate } from './interfaces/index';

/**
 * Селект с пагинаций
 */

const PaginateSelect: TAsyncPaginate = props => {
  const { loadOptions, required, disabled, ...restProps } = props;

  const getNoOptionsMessage = useCallback(() => 'Нет опций', []);

  const getLoadingMessage = useCallback(() => 'Загрузка...', []);

  return (
    <AsyncPaginate
      isSearchable={false}
      debounceTimeout={500}
      {...restProps}
      isDisabled={disabled}
      loadOptions={loadOptions}
      noOptionsMessage={getNoOptionsMessage}
      loadingMessage={getLoadingMessage}
    />
  );
};

export default PaginateSelect;
