import * as Yup from 'yup';

import { IMaterial, TMaterialDraft } from 'store/slices/materials/interfaces';
import { prepareContent } from 'components/form/Content/utils';
import { contentSchema } from 'utils/yup';
import { mapContent, mapContentToForm, mapSelect } from 'utils/mappings';
import { Statuses, StatusesEnum } from 'constants/status';

export const mapValuesToForm = values => {
  return {
    ...values,
    name: values.name || '',
    content: prepareContent(mapContentToForm(values.content || [])),
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Введите от 1 до 180 символов')
    .max(180, 'Введите от 1 до 180 символов')
    .required(),
  content: contentSchema,
  status: Yup.mixed().required(),
});

export const mapValues = (values): IMaterial => {
  return {
    name: values.name.trim() || '',
    content: mapContent(values.content),
    status: mapSelect(values.status) as Statuses,
  };
};

export const mapValuesToDraft = (values): TMaterialDraft => {
  return {
    name: values.name ? values.name : null,
    content: values.content?.length ? mapContent(values.content) : [],
  };
};
