// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo-module__logo--Inn8S {\n  display: flex;\n  align-items: center;\n  color: #fff;\n}\n.Logo-module__text--SDoOk {\n  font-family: 'Montserrat';\n  font-size: 16px;\n  font-weight: 700;\n  letter-spacing: 0.02em;\n  margin-left: 20px;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".logo {\n  display: flex;\n  align-items: center;\n  color: #fff;\n}\n.text {\n  font-family: 'Montserrat';\n  font-size: 16px;\n  font-weight: 700;\n  letter-spacing: 0.02em;\n  margin-left: 20px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo-module__logo--Inn8S",
	"text": "Logo-module__text--SDoOk"
};
export default ___CSS_LOADER_EXPORT___;
