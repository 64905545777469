import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import { GetProjectsParams, InvestmentProjectsItem } from './interfaces';

export const loadInvestmentProjectsList = createAsyncThunk<
  ListResponse<InvestmentProjectsItem>,
  GetProjectsParams,
  { state: RootState }
>('investmentProjects/loadList', async (params, { getState }) => {
  const { limit } = getState().investmentProjects;
  const { data } = await apiClient.get(urls.api.investmentProjects.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const saveInvestmentProjectsItem = createAsyncThunk<
  InvestmentProjectsItem,
  InvestmentProjectsItem
>(
  'investmentProjects/saveItem',
  async ({ id, image, gallery, content, ...params }) => {
    const uploadedImage = await uploadImage(image);
    const uploadedContent = await uploadContent(content);
    const uploadedGallery = await uploadImageArray(gallery);

    if (id) {
      const { data } = await apiClient.patch(
        getUrlWithParams(urls.api.investmentProjects.patch, { id }),
        {
          ...params,
          image: uploadedImage,
          content: uploadedContent,
          gallery: uploadedGallery,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post(urls.api.investmentProjects.post, {
        ...params,
        image: uploadedImage,
        content: uploadedContent,
        gallery: uploadedGallery,
      });
      return data;
    }
  }
);
export const saveInvestmentProjectsItemDraft = createAsyncThunk<
  DeepPartial<InvestmentProjectsItem>,
  DeepPartial<InvestmentProjectsItem>
>(
  'investmentProjects/saveItemAsDraft',
  async ({ id, image, gallery, content, ...params }) => {
    const uploadedImage = await uploadImage(image);
    const uploadedContent = await uploadContent(content);
    const uploadedGallery = await uploadImageArray(gallery);

    if (id) {
      const { data } = await apiClient.patch(
        getUrlWithParams(urls.api.investmentProjects.draft.patch, { id }),
        {
          ...params,
          image: uploadedImage,
          content: uploadedContent,
          gallery: uploadedGallery,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post(
        urls.api.investmentProjects.draft.post,
        {
          ...params,
          image: uploadedImage,
          content: uploadedContent,
          gallery: uploadedGallery,
        }
      );
      return data;
    }
  }
);

export const loadInvestmentProjectsItem = createAsyncThunk<
  InvestmentProjectsItem,
  string
>('investmentProjects/loadItem', async id => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.investmentProjects.getOne, { id })
  );
  return data;
});

export const setPublishProject = createAsyncThunk<void, number>(
  'invest-projects/publish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.investmentProjects.publish.patch, { id })
    )
);

export const setUnPublishProject = createAsyncThunk<void, number>(
  'invest-projects/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.investmentProjects.unPublish.patch, { id })
    )
);

export const deleteProject = createAsyncThunk<void, { id: number }>(
  'invest-projects/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.investmentProjects.delete, { id })
    )
);
