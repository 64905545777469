import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import { TContact } from 'types/contact';

import { formatPhoneNumber } from './format';

export const sortContacts = (contacts: TContact[]) => {
  if (!Array.isArray(contacts)) return [];
  return [...contacts].sort((a, b) => {
    const typeOrder = {
      [DefaultContactOptionsTypes.Phone]: 1,
      [DefaultContactOptionsTypes.Email]: 2,
      [DefaultContactOptionsTypes.Site]: 3,
    };
    const typeA = a.type in typeOrder ? a.type : 'website';
    const typeB = b.type in typeOrder ? b.type : 'website';
    return typeOrder[typeA] - typeOrder[typeB];
  });
};

export const contactsFormat = item => {
  if (item.type === DefaultContactOptionsTypes.Phone) {
    return `tel: ${formatPhoneNumber(item.value)}`;
  } else if (item.type === DefaultContactOptionsTypes.Email) {
    return `mailto: ${item.value}`;
  } else {
    return item.value;
  }
};
