import React from 'react';
import { Col, Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { tutuOptions } from 'constants/tutu';

interface IProps {
  name: string;
}

export const TutuSelector = ({ name }: IProps) => {
  const { control } = useFormContext();

  const onSelectWay = (option, onChange) => {
    onChange(option);
  };

  return (
    <Col xs={{ span: 24 }} md={{ span: 12 }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Form.Item
            label='Виджет Tutu.ru'
            help={fieldState.error?.message}
            validateStatus={fieldState.error ? 'error' : 'success'}
          >
            <Select
              defaultValue='Не указан'
              value={field.value ? field.value : 'Не указан'}
              onSelect={(_, option) => onSelectWay(option, field.onChange)}
            >
              {tutuOptions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      />
    </Col>
  );
};
