import React from 'react';
import { Button, Dropdown, Image, Menu, Typography } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import { TImage } from 'types/image';
import { IAddress } from 'types/address';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { getImageUrl } from 'utils/image';
import { makeAddressString } from 'utils/entities';

interface ICol {
  id: number;
  name: string;
  sortPriority: number;
  image: TImage;
  address?: IAddress;
}

interface IProps {
  data: ICol[];
  limit?: number;
  getActions: (item: ICol) => Array<
    | {
        element: JSX.Element;
        handler?: undefined;
      }
    | {
        element: React.ReactNode;
        handler: () => void;
      }
  >;
  handleSort: (item: ICol, direction: 'up' | 'down') => void;
}

export const HotelTable: React.FC<IProps> = ({
  data,
  getActions,
  limit = 20,
  handleSort,
}) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'name',
      render: (name, item) => (
        <Typography>
          <div
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          >
            <div style={{ flexShrink: 0 }}>
              <Image
                width={50}
                height={50}
                preview={false}
                src={
                  item?.image
                    ? getImageUrl(item.image, 50, 50) ||
                      '/public/images/no-image.svg'
                    : '/public/images/no-image.svg'
                }
                fallback='/public/images/no-image.svg'
                style={{ flexShrink: 0 }}
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <div>{name || 'Без названия'}</div>
              {item.address && (
                <div
                  style={{
                    marginTop: '4px',
                    fontSize: '12px',
                    color: '#8C8C8C',
                  }}
                >
                  {item.address?.region?.name} -{' '}
                  {makeAddressString({ city: item.address?.city }) ||
                    makeAddressString({
                      settlement: item.address?.settlement,
                    })}
                </div>
              )}
            </div>
          </div>
        </Typography>
      ),
    },
    {
      key: 2,
      width: 150,
      title: 'Сортировка',
      dataIndex: 'sortPriority',
      render: (sortPriority, item, index) => (
        <div>
          {index > 0 && (
            <Button
              type='text'
              icon={<ArrowUpOutlined />}
              onClick={() => handleSort(item, 'up')}
            />
          )}
          {index < data.length - 1 && (
            <Button
              type='text'
              icon={<ArrowDownOutlined />}
              onClick={() => handleSort(item, 'down')}
            />
          )}
        </div>
      ),
    },
    {
      key: 3,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (actions, item) => {
        const dropMenu = (
          <Menu>
            {getActions &&
              getActions(item).map((action, index) =>
                action ? (
                  <Menu.Item key={index} onClick={action.handler}>
                    {action.element}
                  </Menu.Item>
                ) : null
              )}
          </Menu>
        );
        return (
          <>
            <Dropdown
              placement='bottomRight'
              overlay={dropMenu}
              arrow
              trigger={['click']}
            >
              <Button type='text' icon={<SettingOutlined />} />
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 600 }}
      dataSource={data}
      limit={limit}
      total={data.length}
    />
  );
};
