// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonInfo-module__title--I1E2u .ant-card-head {\n  border-bottom: none !important;\n}\n.CommonInfo-module__row--ra7r6 {\n  padding-bottom: 0 !important;\n}\n.CommonInfo-module__row--ra7r6 .ant-descriptions-item-container .ant-descriptions-item-content {\n  margin-bottom: 24px !important;\n}\n.CommonInfo-module__row--ra7r6 .ant-descriptions-item-container .ant-descriptions-item-label {\n  margin-bottom: 4px !important;\n}\n.CommonInfo-module__row--ra7r6 .ant-descriptions-item-container .ant-descriptions-item-label::after {\n  content: '';\n}\n.CommonInfo-module__link--OOxG5 {\n  display: flex;\n  align-items: flex-start;\n  padding: 0;\n  white-space: normal;\n  height: auto;\n  text-align: start;\n  word-break: break-word;\n}\n.CommonInfo-module__icon--hCYNO {\n  color: #8C8C8C;\n  font-size: 22px;\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reports/components/ViewInfo/components/CommonInfo/CommonInfo.module.less"],"names":[],"mappings":"AAAA;EAGG,8BAAA;AADH;AAMA;EAEE,4BAAA;AALF;AAGA;EAMI,8BAAA;AANJ;AAAA;EAUI,6BAAA;AAPJ;AASI;EACC,WAAA;AAPL;AAcA;EACC,aAAA;EACA,uBAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;AAZD;AAeA;EACC,cAAA;EACA,eAAA;EACA,iBAAA;AAbD","sourcesContent":[".title {\n\t:global {\n\t\t.ant-card-head {\n\t\t\tborder-bottom: none !important;\n\t\t}\n\t}\n}\n\n.row {\n\t:global {\n\t\tpadding-bottom: 0 !important;\n\t\t\n\t\t.ant-descriptions-item-container {\n\t\t\t.ant-descriptions-item-content {\n\t\t\t\tmargin-bottom: 24px !important;\n\t\t\t}\n\n\t\t\t.ant-descriptions-item-label {\n\t\t\t\tmargin-bottom: 4px !important;\n\n\t\t\t\t&::after {\n\t\t\t\t\tcontent: '';\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n\n.link {\n\tdisplay: flex;\n\talign-items: flex-start;\n\tpadding: 0;\n\twhite-space: normal;\n\theight: auto;\n\ttext-align: start;\n\tword-break: break-word;\n}\n\n.icon {\n\tcolor: #8C8C8C;\n\tfont-size: 22px;\n\tmargin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "CommonInfo-module__title--I1E2u",
	"row": "CommonInfo-module__row--ra7r6",
	"link": "CommonInfo-module__link--OOxG5",
	"icon": "CommonInfo-module__icon--hCYNO"
};
export default ___CSS_LOADER_EXPORT___;
