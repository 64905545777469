import React from 'react';
import { Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  DownSquareOutlined,
  LoadingOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';

import { downloadDocument } from 'utils/downloadFile';
import { IVacanciesRespondsItemResponse } from 'store/slices/vacanciesResponds/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { formatPhoneNumber } from 'utils/format';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

interface IProps {
  data: IVacanciesRespondsItemResponse[] | [];
  total: number;
  limit?: number;
  setSort: (sort?: string) => void;
}

export const VacanciesRespondsList: React.FC<IProps> = ({
  data,
  total,
  limit,
  setSort,
}) => {
  const [fetchingDocumentPath, setFetchingDocumentPath] = React.useState('');

  const downloadCV = ({ path, realName }: MaterialValue['file']) => {
    if (UPLOAD_SERVICE_BASE_URL && path && realName) {
      setFetchingDocumentPath(path);
      const url = `${UPLOAD_SERVICE_BASE_URL}/${path}`;
      const docName = `${realName}`;

      downloadDocument(url, docName).finally(() => setFetchingDocumentPath(''));
    }
  };

  const columns = [
    {
      key: 1,
      width: 145,
      title: 'Дата и время отклика',
      dataIndex: 'createdAt',
      sorter: true,
      render: createdAt => (
        <Typography.Text style={{ whiteSpace: 'pre-line' }}>
          {dayjs(createdAt).format('DD.MM.YYYY, \n HH:mm')}
        </Typography.Text>
      ),
    },
    {
      key: 2,
      title: 'ФИО',
      dataIndex: 'firstName',
      render: (
        _: unknown,
        { firstName, lastName }: IVacanciesRespondsItemResponse
      ) => (
        <Typography.Text>
          {lastName} {firstName}
        </Typography.Text>
      ),
    },
    {
      key: 3,
      title: 'Email',
      dataIndex: 'email',
      render: email => (
        <Typography.Text>{email || 'Не указано'}</Typography.Text>
      ),
    },
    {
      key: 4,
      title: 'Телефон',
      dataIndex: 'phone',
      render: phone => (
        <Typography.Text>{formatPhoneNumber(phone)}</Typography.Text>
      ),
    },
    {
      key: 5,
      title: 'Резюме',
      dataIndex: 'materials',
      render: materials => {
        if (!materials) {
          return <Typography.Text>Не прикреплено</Typography.Text>;
        }

        if (fetchingDocumentPath === materials?.file?.path) {
          return (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          );
        } else {
          return (
            <Typography>
              <a onClick={() => downloadCV(materials.file)}>Скачать</a>
            </Typography>
          );
        }
      },
    },
  ];

  const expandableItem = {
    expandedRowRender: (item: IVacanciesRespondsItemResponse) => (
      <Typography.Text style={{ margin: 0 }}>{item.message}</Typography.Text>
    ),
    expandIcon: ({ expanded, onExpand, record }) => {
      return expanded ? (
        <UpSquareOutlined onClick={e => onExpand(record, e)} />
      ) : (
        <DownSquareOutlined onClick={e => onExpand(record, e)} />
      );
    },
  };

  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
      expandableItem={expandableItem}
    />
  );
};
