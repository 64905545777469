import { IAlert, TAlertRequest } from '../../store/slices/alert/interfaces';

export const mapValuesToForm = (values: IAlert): TAlertRequest => {
  return {
    id: values.id,
    name: values.name,
    type: values.type,
    is_active: values.is_active,
    link: values.link,
  };
};
