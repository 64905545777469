import { mapDefaultPosition, yandexKey } from 'constants/yandex';

export const mapDefaultState = {
  controls: ['zoomControl'],
  center: mapDefaultPosition,
  zoom: 6,
};

export const mapOptions = {
  editorDrawingCursor: 'crosshair',
  editorMaxPoints: 5,
  fillColor: '#00FF00',
  strokeColor: '#0000FF',
  strokeWidth: 5,
};

export const mapQuery = {
  apikey: yandexKey,
  load: 'package.full',
};
