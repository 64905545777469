import React from 'react';
import { Collapse } from 'antd';

import { CommonInfo } from './components/CommonInfo/CommonInfo';
import { Documents } from './components/Documents/Documents';
import { Comments } from './components/Comments/Comments';

export const ViewInfo = () => {
  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([
    'documents',
    'comments',
  ]);

  return (
    <React.Fragment>
      <CommonInfo />
      <Collapse
        bordered={false}
        expandIconPosition='end'
        onChange={(value: string[]) => setActiveCollapseTabs(value)}
        activeKey={activeCollapseTabs}
      >
        <Collapse.Panel header='Документы' key='documents'>
          <Documents />
        </Collapse.Panel>
        <Collapse.Panel header='Комментарии' key='comments'>
          <Comments />
        </Collapse.Panel>
      </Collapse>
    </React.Fragment>
  );
};
