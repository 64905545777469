import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Content } from 'antd/lib/layout/layout';
import { Button, Col, Collapse, Form, Row, Skeleton } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

import { LangEnum } from 'constants/lang';
import { Select } from 'components/form/base/Select';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty } from 'utils/helpers';
import {
  IMenuRequestBody,
  IMenuRequestParams,
  TMenuType,
} from 'store/slices/menu/interfaces';

import { CollapseKeys, TCollapseKeys } from '../types';
import { MenuItems } from '../MenuItems/components/MenuItems/MenuItems';
import { DEFAULT_NESTING_LEVEL, MENU_OPTIONS } from '../constants';
import { mapValues } from './formUtils';
import { validationSchema } from './validation';
import styles from './MenuForm.module.less';

export interface IFormValues extends IMenuRequestBody {
  type: TMenuType;
  menuOptions: { value: string; label: string };
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IMenuRequestBody) => void;
  isLoading: boolean;
  isDataLoading: boolean;
};

export const MenuForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  isLoading,
  isDataLoading,
}) => {
  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState<
    TCollapseKeys[]
  >([CollapseKeys.collapse]);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { control } = methods;

  const [lang, menuOptions] = useWatch({
    name: ['lang', 'menuOptions'],
    control,
  });

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollapseTabs([CollapseKeys.collapse]);
    }
  }, [methods.formState.errors]);

  const updateQuery = (values: IMenuRequestParams) => {
    const queryString = new URLSearchParams(
      values as unknown as Record<string, string>
    ).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  useDeepEffect(() => {
    updateQuery({
      lang,
      menuType: menuOptions?.value as TMenuType,
    });
  }, [menuOptions?.value, lang]);

  const onSubmit = (values: IFormValues) => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  const onCancel = () => {
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form
        className='ant-form ant-form-vertical indent-top'
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Content className={styles.contentBlock}>
          <Row>
            <Form.Item
              label={`Перейти к ${
                lang === LangEnum.ru ? 'английской' : 'русской'
              } версии`}
            >
              <Button
                onClick={() =>
                  methods.setValue(
                    'lang',
                    lang === LangEnum.ru ? LangEnum.en : LangEnum.ru
                  )
                }
              >
                {lang === LangEnum.ru ? 'Английская версия' : 'Русская версия'}
              </Button>
            </Form.Item>
          </Row>
        </Content>
        <Content className={styles.contentBlock}>
          <Row>
            <Col xs={24} md={6}>
              <Select
                name='menuOptions'
                label='Выбор меню'
                options={MENU_OPTIONS}
              />
            </Col>
          </Row>
        </Content>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: TCollapseKeys[]) => setActiveCollapseTabs(value)}
          activeKey={activeCollapseTabs}
        >
          <Collapse.Panel header={menuOptions.label} key='collapse'>
            {!isDataLoading ? (
              <MenuItems name='items' depthLevel={DEFAULT_NESTING_LEVEL} />
            ) : (
              <Skeleton
                paragraph={{ rows: 4 }}
                active
                title
                loading={isDataLoading}
              />
            )}
            <div className={styles.bottomActions}>
              <Button onClick={onCancel} disabled={isLoading}>
                Отмена
              </Button>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Сохранить
              </Button>
            </div>
          </Collapse.Panel>
        </Collapse>
      </form>
    </FormProvider>
  );
};
