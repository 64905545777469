import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const router = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.pathname + router.search]);

  return null;
};
