import React, { useState, useEffect, useCallback } from 'react';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  loadPriorityIndustry,
  savePriorityIndustry,
} from 'store/slices/priorityIndustry/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';

import { PriorityIndustryForm } from './components/PriorityIndustryForm/PriorityIndustryForm';
import { IFormValues } from './components/types';
import { mapValues, mapValuesToForm } from './components/utils';

export const PriorityIndustry = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.priorityIndustry);

  // На данный момент используется только русский язык;
  useEffect(() => {
    dispatch(loadPriorityIndustry('ru'));
  }, []);

  const handleFinish = useCallback((values: IFormValues) => {
    setIsLoading(true);

    // На данный момент используется только русский язык;
    dispatch(savePriorityIndustry({ ...mapValues(values), lang: 'ru' }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.priorityIndustry}>
      {item && (
        <PriorityIndustryForm
          onFinish={handleFinish}
          initialValues={mapValuesToForm(item)}
          isLoading={isLoading}
        />
      )}
    </MainLayout>
  );
};
