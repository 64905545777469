import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, Form, Modal, notification, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { AxiosError } from 'axios';

import { MAX_MATERIAL_FILE_SIZE } from 'constants/upload';
import { uploadMaterial } from 'utils/upload';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import {
  DEFAULT_ACCEPT,
  ERROR_CODE_KEY,
  ERROR_MESSAGE,
  VALID_FORMATS_LIST,
} from './contants';
import styles from './UploadIcon.module.less';

type TProps = {
  name: string;
  title: string;
  label?: string;
  required?: boolean;
};

export const UploadIcon: React.FC<TProps> = ({
  name,
  title,
  label,
  required,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewTitle, setPreviewTitle] = React.useState('');

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const file = useWatch({ name: `${name}.file`, control });

  const uploadedIcon = useWatch({ name: `${name}.icon`, control });

  const fileListItem = React.useMemo(
    () => (file?.path ? [{ name: file.realName, uid: file.pathName }] : []),
    [file]
  );

  const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess?.('ok');
    }, 0);
  };

  const handleFileChange = async (info: UploadChangeParam) => {
    const isLt2M = (info.file.size as number) < MAX_MATERIAL_FILE_SIZE;
    const isValidType = VALID_FORMATS_LIST.includes(info.file.type);

    if (!isLt2M) {
      return notification.error({
        message: `Пожалуйста, выберите файл с размером не более ${
          MAX_MATERIAL_FILE_SIZE / (1024 * 1024)
        } МБ`,
      });
    }
    if (!isValidType) {
      return notification.error({
        message: ERROR_MESSAGE,
      });
    }

    setIsLoading(true);
    try {
      const { path, fileList } = await uploadMaterial(info);
      const file = fileList[0];

      // Заполнение информации о загруженном файле в форму
      setValue(`${name}.icon`, {
        baseUrl: UPLOAD_SERVICE_BASE_URL,
        path,
        realName: file.name,
      });

      // Обнуление поля для того чтобы не отображать файл под загрузчиком
      setValue(`${name}.file`, null);
      setIsLoading(false);
    } catch (error) {
      if (error.code && ERROR_CODE_KEY === error.code) {
        notification.error({
          message: ERROR_MESSAGE,
        });
      } else {
        notification.error({
          message: (error as AxiosError)?.message,
        });
      }
      setIsLoading(false);
    }
  };

  const uploadedIconFile = React.useMemo(() => {
    return (
      uploadedIcon
        ? [
            {
              uid: uploadedIcon.path,
              name: uploadedIcon.realName,
              status: 'done',
              url: `${UPLOAD_SERVICE_BASE_URL}/${uploadedIcon.path}`,
            },
          ]
        : []
    ) as UploadFile[];
  }, [uploadedIcon]);

  const iconFieldError = getValueByStringKeyWithArr(errors, `${name}.icon`);

  return (
    <Controller
      name={name}
      defaultValue={null}
      render={({ field }) => {
        return (
          <Form.Item
            label={label}
            required={required}
            tooltip={
              Array.isArray(uploadedIconFile) &&
              !uploadedIconFile.length &&
              iconFieldError?.message
                ? iconFieldError?.message
                : undefined
            }
            className={styles.tooltip}
          >
            <div ref={field.ref} tabIndex={0}>
              {uploadedIcon ? (
                <>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => setPreviewOpen(false)}
                  >
                    <img
                      alt='attached_img'
                      style={{ width: '100%' }}
                      src={previewImage}
                    />
                  </Modal>
                  <Upload
                    listType='picture-card'
                    fileList={uploadedIconFile}
                    onPreview={handlePreview}
                    showUploadList={{
                      showRemoveIcon: false,
                    }}
                  />
                </>
              ) : (
                <Upload
                  accept={DEFAULT_ACCEPT}
                  name='file'
                  customRequest={dummyRequest}
                  onChange={handleFileChange}
                  maxCount={1}
                  fileList={fileListItem}
                >
                  <Button
                    icon={<UploadOutlined />}
                    danger={
                      Array.isArray(uploadedIconFile) &&
                      !uploadedIconFile.length &&
                      iconFieldError?.message
                    }
                    loading={isLoading}
                  >
                    {title}
                  </Button>
                </Upload>
              )}
            </div>
          </Form.Item>
        );
      }}
      control={control}
    />
  );
};
