import { notification } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadSignedAgreementsItem,
  saveSignedAgreementsItem,
  saveSignedAgreementsItemDraft,
} from 'store/slices/signedAgreements/actions';
import {
  SignedAgreementsItem,
  SignedAgreementsItemRequest,
} from 'store/slices/signedAgreements/interfaces';
import { DeepPartial } from 'types/utils';

import { SignedAgreementsForm } from './components/SignedAgreementsForm';
import { mapValuesToForm } from './components/SignedAgreementsForm/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.signedAgreements);
  const isCorrectItem = item?.id === Number(id);

  React.useEffect(() => {
    dispatch(loadSignedAgreementsItem(id));
  }, [id]);

  const onSubmit = (values: SignedAgreementsItemRequest) => {
    const newItem = { ...values, id: item?.id };

    setIsLoading(true);

    dispatch(saveSignedAgreementsItem(newItem))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно сохранено',
        });
        navigate('/signedAgreements');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<SignedAgreementsItem>) => {
    setIsLoading(true);

    dispatch(saveSignedAgreementsItemDraft({ ...values, id: item?.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно сохранено',
        });
        navigate('/signedAgreements');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {isCorrectItem && (
        <SignedAgreementsForm
          onSubmit={onSubmit}
          isDraft={item.status === 'draft'}
          defaultValues={mapValuesToForm(item)}
          onSaveAsDraft={onSaveAsDraft}
          isLoading={isLoading}
        />
      )}
    </MainLayout>
  );
};
