import { createSlice } from '@reduxjs/toolkit';

import {
  loadOrganization,
  loadOrganizationList,
  loadOrganizationUserList,
  loadOrganizationApplicationList,
  loadOrganizationProjectList,
} from './actions';
import { IOrganizationsState } from './interfaces';

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    list: [],
    item: {},
    users: [],
    applications: [],
    projects: [],
    limit: 20,
    total: 0,
    offset: 0,
  } as IOrganizationsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadOrganizationList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
    builder.addCase(loadOrganization.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
    builder.addCase(
      loadOrganizationUserList.fulfilled,
      (state, { payload }) => {
        state.users = payload;
      }
    );
    builder.addCase(
      loadOrganizationApplicationList.fulfilled,
      (state, { payload: { data } }) => {
        state.applications = data;
      }
    );
    builder.addCase(
      loadOrganizationProjectList.fulfilled,
      (state, { payload: { data } }) => {
        state.projects = data;
      }
    );
  },
});
