export enum ExtensionContactsTypes {
  email = 'email',
  phone = 'phone',
}

export type TExtensionContactsTypes = keyof typeof ExtensionContactsTypes;

export type TExtensionContactsFieldsType = {
  id?: string;
  type: TExtensionContactsTypes;
  value: string;
  additional?: string;
};
