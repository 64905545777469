import React from 'react';
import { Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FormProvider, useForm, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';

import { InputField } from 'components/form/base/InputField';
import { RangeDatePicker } from 'components/form/base/RangePicker';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { useQuery } from 'hooks/useQuery';
import { resetFilters } from 'store/slices/formDocuments/formDocumentsSlice';
import { useAppSelector } from 'store';

import styles from './Filters.module.less';

type IFormValues = {
  displayNameQuery: string;
  date: Dayjs[] | null;
};

export const DEFAULT_FORM_DOCUMENTS_STATE = {
  displayNameQuery: '',
  date: null,
};

export const Filters = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const shouldResetFilters = useAppSelector(
    state => state.formDocuments.shouldResetFilters
  );

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        displayNameQuery: values.displayNameQuery || '',
        ...(values.date
          ? {
              createdAtFrom: dayjs(values.date[0]).startOf('day').toISOString(),
              createdAtTo: dayjs(values.date[1]).endOf('day').toISOString(),
            }
          : null),
      };

      updateFilters(removeEmptyValues(preparedValues));
    }, 400),
    []
  );

  const { displayNameQuery, createdAtFrom, createdAtTo } = omit(
    query,
    'limit',
    'offset'
  );

  const queryValuesToForm = {
    displayNameQuery: displayNameQuery || '',
    date:
      createdAtFrom && createdAtTo
        ? [dayjs(createdAtFrom), dayjs(createdAtTo)]
        : null,
  };

  const methods = useForm({
    defaultValues: {
      ...DEFAULT_FORM_DOCUMENTS_STATE,
      ...queryValuesToForm,
    },
  });

  React.useEffect(() => {
    /* Сброс фильтров, когда пользователь отправляет комментарий */
    if (shouldResetFilters) {
      methods.reset(DEFAULT_FORM_DOCUMENTS_STATE);
      dispatch(resetFilters(false));
    }
  }, [shouldResetFilters]);

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <FormProvider {...methods}>
      <Col xs={{ span: 24 }} md={{ span: 10 }} className={styles.searchInput}>
        <InputField
          name='displayNameQuery'
          type='search'
          placeholder='Поиск по названию'
          maxLength={255}
          addonAfter={<SearchOutlined />}
        />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 10 }}>
        <RangeDatePicker
          name='date'
          label=''
          placeholder={['Дата начала', 'Дата окончания']}
        />
      </Col>
    </FormProvider>
  );
};
