import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { InputField } from 'components/form/base/InputField';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { useQuery } from 'hooks/useQuery';

interface IFormValues {
  fullNameQuery: string;
  emailQuery: string;
  phoneQuery: string;
}

export const Filters: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        fullNameQuery: values.fullNameQuery && values.fullNameQuery.trim(),
        emailQuery: values.emailQuery && values.emailQuery.trim(),
        phoneQuery: values.phoneQuery && values.phoneQuery.trim(),
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const queryValues = omit(query, 'limit', 'offset');

  const queryValuesToForm = React.useMemo(
    () =>
      Object.keys(queryValues).reduce((acc, item) => {
        acc[item] = queryValues[item];
        return acc;
      }, {}) as IFormValues,
    [queryValues]
  );

  const methods = useForm({
    defaultValues: {
      fullNameQuery: '',
      emailQuery: '',
      phoneQuery: '',
      ...queryValuesToForm,
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='fullNameQuery'
            label='Фамилия и имя'
            placeholder='Введите фамилию, имя'
            maxLength={75}
          />
          <InputField
            name='emailQuery'
            label='Почта'
            placeholder='Введите почту'
            maxLength={255}
          />
          <InputField
            name='phoneQuery'
            label='Телефон'
            placeholder='Введите телефон'
            maxLength={255}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
