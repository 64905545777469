import { IPriorityIndustryResponse } from 'store/slices/priorityIndustry/interfaces';
import { prepareContent } from 'components/form/Content/utils';
import { mapContent } from 'utils/mappings';

import { IFormValues } from './types';

export const mapValuesToForm = ({
  lang,
  hero,
  shortDesc,
  description,
  direction,
  title,
  metaTitle,
  metaDescription,
  ogTitle,
  ogDescription,
  metaKeywords,
}: IPriorityIndustryResponse) => ({
  lang,
  hero: hero || '',
  shortDesc: shortDesc || '',
  description: prepareContent(
    description
      ? [
          {
            type: 'text',
            text: description,
          },
        ]
      : []
  ),
  direction:
    direction?.map(({ name, shortDescription, ...rest }) => ({
      title: name,
      shortDescription: shortDescription || '',
      ...rest,
    })) || [],
  title: title || '',
  metaTitle: metaTitle || '',
  metaDescription: metaDescription || '',
  ogTitle: ogTitle || '',
  ogDescription: ogDescription || '',
  metaKeywords: metaKeywords || '',
});

export const mapValues = (values: IFormValues) => ({
  ...values,
  direction: values.direction.map(({ title, shortDescription, ...rest }) => ({
    name: title,
    shortDescription: shortDescription || null,
    ...rest,
  })),
  description: mapContent(values.description)[0]?.text || null,
  metaTitle: values.metaTitle || null,
  metaDescription: values.metaDescription || null,
  ogTitle: values.ogTitle || null,
  ogDescription: values.ogDescription || null,
  metaKeywords: values.metaKeywords || null,
});
