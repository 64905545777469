import React from 'react';

import { PriorityIndustry } from './PriorityIndustry';

export const priorityIndustryRoutes = {
  path: '/priorityIndustry',
  children: [
    {
      path: '',
      element: <PriorityIndustry />,
    },
  ],
};
