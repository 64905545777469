import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiClient } from '../../../utils/http';
import { urls } from '../../api';
import { IActivityResponse } from './interfaces';

export const getActivities = createAsyncThunk<IActivityResponse, any>(
  'activities/list',
  async (params) => {  
    const { data } = await apiClient.get<IActivityResponse>(
      urls.api.activities.get,
      {params}
    );
    return data;
  }
);
