import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  ITouristRoutesDraftItem,
  ITouristRoutesItem,
} from 'store/slices/touristRoutes/interfaces';
import {
  saveTouristRoutesItem,
  saveTouristRoutesItemAsDraft,
} from 'store/slices/touristRoutes/actions';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { DEFAULT_SEO_STATE } from 'constants/seo';

import { TouristRoutesForm } from './components/TouristRoutesForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: ITouristRoutesItem) => {
    setIsLoading(true);
    dispatch(saveTouristRoutesItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/touristRoutes');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: ITouristRoutesDraftItem) => {
    setIsLoading(true);
    dispatch(saveTouristRoutesItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/touristRoutes');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/touristRoutes');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <TouristRoutesForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          lang: LangEnum.ru,
          name: '',
          image: null,
          heroImage: null,
          tags: [],
          shortDescription: '',
          routeType: null,
          routeLevel: null,
          content: prepareContent([]),
          status: StatusesEnum.PUBLISHED,
          audio: null,
          points: [],
          polyline: [],
          days: 0,
          duration: '',
          externalUrl: '',
          ...DEFAULT_SEO_STATE,
          isSelection: false,
        }}
      />
    </MainLayout>
  );
};
