import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import { loadBusinessList } from 'store/slices/business/actions';
import { IBusinessItemResponse } from 'store/slices/business/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { useDeepEffect } from 'utils/useDeepEffect';
import { PageTitles } from 'constants/pageTitles';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.business);

  const loadBusiness = () => {
    dispatch(
      loadBusinessList({
        ...query,
        sort,
      })
    );
  };

  useDeepEffect(() => {
    loadBusiness();
  }, [query, sort]);

  const getActions = (item: IBusinessItemResponse) => [
    {
      key: 'preview',
      label: <Link to={`/business/${item.id}`}>Превью</Link>,
    },
    {
      key: 'edit',
      label: <Link to={`/business/${item.id}/edit`}>Редактировать</Link>,
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.business} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
