import React from 'react';
import { Layout, Typography } from 'antd';
import {
  useForm,
  FormProvider,
  WatchObserver,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { Select } from 'components/form/base/Select';
import { DatePicker } from 'components/form/base/DatePicker';
import { applicationsStatusesNamesHash } from 'constants/applicationsStatus';
import { defaultDateFormat } from 'components/form/base/RangePicker';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  organizationName: string;
  status: string | TFormSelectValue;
  createdAtFrom: string | null;
  createdAtTo: string | null;
}

export const Filters: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        organizationName:
          values.organizationName && values.organizationName.trim(),
        status: mapSelect(values.status),
        createdAtFrom: values.createdAtFrom
          ? dayjs(values.createdAtFrom, defaultDateFormat)
              .startOf('day')
              .toISOString()
          : null,
        createdAtTo: values.createdAtTo
          ? dayjs(values.createdAtTo, defaultDateFormat)
              .endOf('day')
              .toISOString()
          : null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const queryValues = omit(query, 'limit', 'offset');

  const queryValuesToForm = React.useMemo(
    () =>
      Object.keys(queryValues).reduce((acc, item) => {
        if (item === 'createdAtFrom' || item === 'createdAtTo') {
          acc[item] = dayjs(queryValues[item]).format(defaultDateFormat);
        } else {
          acc[item] = queryValues[item];
        }

        return acc;
      }, {}) as IFormValues,
    [queryValues]
  );

  const methods = useForm({
    defaultValues: {
      organizationName: '',
      status: '',
      createdAtFrom: null,
      createdAtTo: null,
      ...queryValuesToForm,
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const [maxDate, minDate] = useWatch<IFormValues>({
    name: ['createdAtFrom', 'createdAtTo'],
    control: methods.control,
  });

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='organizationName'
            label='Название организации'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select
            name='status'
            label='Статус заявки'
            options={[
              { value: '', label: 'Все' },
              ...Object.keys(applicationsStatusesNamesHash).map(item => ({
                value: item,
                label: applicationsStatusesNamesHash[item],
              })),
            ]}
          />
          <DatePicker
            name='createdAtFrom'
            label='Дата начала'
            placeholder='Выберите дату начала'
            maxDateRestriction={
              minDate && dayjs(minDate as string, 'DD.MM.YYYY')
            }
          />
          <DatePicker
            name='createdAtTo'
            label='Дата окончания'
            placeholder='Выберите дату окончания'
            minDateRestriction={
              maxDate && dayjs(maxDate as string, 'DD.MM.YYYY')
            }
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
