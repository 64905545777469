import React from 'react';
import { Button, Col, Dropdown, Menu, Row, Spin, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { IRegionsItemResponse } from 'store/slices/regions/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { useDeepEffect } from '../../../../utils/useDeepEffect';
import { useQuery } from '../../../../hooks/useQuery';
import { loadRegionsList } from '../../../../store/slices/regions/actions';
import { MainLayout } from '../../../../components/Layout/MainLayout';
import { mainBusinessHeaders } from '../../../../constants/mainBusiness';
import { IHistoryResponse } from '../../../../store/slices/b2bHistory/interfaces';
import { LangEnumKeys } from '../../../../constants/lang';
import { LangSelectSection } from '../../../../components/form/LangSelectSection/LangSelectSection';

/**
 * Комопонет со списком регионов B2B "Карты регионов"
 * @conponent
 */

interface IFormValues extends IHistoryResponse {
  lang: { value: LangEnumKeys; label: string };
}
export const Regions = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();

  const data = useAppSelector(state => state.regions);

  const methods = useForm<IFormValues>({
    defaultValues: { lang: { value: 'ru', label: 'Русский' } },
  });

  const loadRegions = () => {
    dispatch(
      loadRegionsList({
        ...query,
        lang: lang.value,
      })
    );
  };

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    loadRegions();
  }, [lang]);

  const getActions = (item: IRegionsItemResponse) => [
    {
      key: 'edit',
      label: <Link to={`${item.id}/regionEdit`}>Редактировать</Link>,
    },
  ];
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'name',
      render: (text, item) => (
        <Typography>
          <Link to={`${item.id}/regionEdit`} style={{ color: 'inherit' }}>
            <div>
              <div>{text || 'Без названия'}</div>
            </div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 4,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: never, item: IRegionsItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <MainLayout pageTitle={mainBusinessHeaders.regions}>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <LangSelectSection />
        </form>
      </FormProvider>
      <Content>
        {data.isLoading ? (
          <Row justify='center'>
            <Col>
              <Spin size='large' />
            </Col>
          </Row>
        ) : (
          <CommonTable
            columns={columns}
            scroll={{ x: 500 }}
            dataSource={data.list}
            total={data.list.length}
            limit={20}
          />
        )}
      </Content>
    </MainLayout>
  );
};
