import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button, Card, Col, notification, Row, Typography } from 'antd';

import { useAppDispatch } from '../../../store';
import { CollapsePriority } from './components/CollapsePriority';
import { setPriority } from '../../../store/slices/blockPriority/actions';
import {
  IComponent,
  TVariant,
} from '../../../store/slices/blockPriority/interfaces';
import { mapPriorityValues } from '../../../pages/MainBusiness/utils';
import { ExtraArrowMenu } from '../../ExtraArrowMenu';
import { FormActions } from '../../Layout/FormActions/FormActions';
import {
  B2BBlockPriorityKeys,
  B2CBlockPriorityKeys,
} from '../../../constants/blockPriority';
import styles from './styles/index.module.less';

interface IFormValues {
  components: IComponent[];
}

interface TDataChild {
  name: string;
  link: string;
}

type TData = Partial<
  Record<
    B2CBlockPriorityKeys | B2BBlockPriorityKeys,
    { link?: string; children?: TDataChild[]; name: string }
  >
>;

interface IProps {
  initialValues: IComponent[];
  data: TData;
  title: string;
  type: TVariant;
}

export const PriorityForm: React.FC<IProps> = ({
  initialValues,
  data,
  title,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const methods = useForm<IFormValues>({
    defaultValues: {
      components: initialValues,
    },
  });

  const { move, fields } = useFieldArray({
    control: methods.control,
    name: 'components',
  });

  const onSubmit = useCallback((values: IFormValues) => {
    setIsLoading(true);
    dispatch(
      setPriority({
        type: type,
        updateBlocks: mapPriorityValues(values.components),
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleMove = (index: number, step: number) => move(index, step);

  const handleCancel = () => {
    methods.reset({ components: initialValues });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <FormProvider {...methods}>
      <Card title={title} bordered={false}>
        {fields.map((field, index) => {
          if (!data[field.name]) {
            return null;
          }
          const link = data[field.name].link;
          return data[field.name]?.children ? (
            <CollapsePriority
              name={data[field.name].name}
              handleMove={handleMove}
              count={fields.length}
              index={index}
              list={data[field.name].children}
            />
          ) : (
            <Row key={field.id} className={styles.row}>
              <Col flex={1}>
                {link ? (
                  <Link to={link}>
                    <Typography className={styles.text}>
                      {data[field.name].name}
                    </Typography>
                  </Link>
                ) : (
                  <span>
                    <Typography className={styles.text}>
                      {data[field.name].name}
                    </Typography>
                  </span>
                )}
              </Col>
              <Col>
                <ExtraArrowMenu
                  onMove={handleMove}
                  index={index}
                  count={fields.length}
                />
              </Col>
            </Row>
          );
        })}
        <FormActions>
          <Button onClick={handleCancel} disabled={isLoading}>
            Отмена
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            type='primary'
            onClick={methods.handleSubmit(onSubmit)}
          >
            Сохранить
          </Button>
        </FormActions>
      </Card>
    </FormProvider>
  );
};
