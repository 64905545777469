import React from 'react';
import { Button, Col, Dropdown, Menu, Row, Tag, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SettingOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { CommonTable } from 'components/CommonTable/CommonTable';
import {
  Statuses,
  statusesColorsHash,
  statusesNamesHash,
} from 'constants/status';
import { PurchasePlansItem } from 'store/slices/purchasePlans/interfaces';

import styles from './TablePlans.module.less';
import { FileIcon } from '../../../../components/FileIcon';

interface TablePlansProps {
  total: number;
  getActions: (item: PurchasePlansItem) => ItemType[];
  setSort: (sort?: string) => void;
  limit: number;
  items?: PurchasePlansItem[];
}

export const TablePlans = ({
  getActions,
  total,
  limit,
  items = [],
  setSort,
}: TablePlansProps) => {
  const columns: ColumnsType<TODO_ANY> = [
    {
      key: 'name',
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (text: string, { id }: PurchasePlansItem) => (
        <Link to={`/purchasePlans/${id}/edit`}>
          <Row gutter={[12, 0]}>
            <Col>
              <FileIcon />
            </Col>
            <Col style={{ overflow: 'hidden', flex: '1 0' }}>
              <Typography.Text>{text || 'Без названия'}</Typography.Text>
            </Col>
          </Row>
        </Link>
      ),
    },
    {
      key: 'year',
      title: 'Год',
      sorter: true,
      dataIndex: 'year',
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      width: '20%',
      render: (status: Statuses) => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 'actions',
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right',
      render: (_: unknown, item: PurchasePlansItem) => (
        <Dropdown
          placement='bottomRight'
          overlay={
            <Menu className={styles.actionDropdown} items={getActions(item)} />
          }
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Content>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        total={total}
        limit={limit}
        dataSource={items}
        setSort={setSort}
      />
    </Content>
  );
};
