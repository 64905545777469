import React from 'react';
import { Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { IReportDocument } from 'store/slices/reportDocuments/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { downloadDocument } from 'utils/downloadFile';
import { IDocument } from 'types/document';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

interface IProps {
  data: Array<IReportDocument>;
  total: number;
  limit?: number;
}

export const ListTable = ({ data, total, limit = 5 }: IProps) => {
  const [fetchingDocumentPath, setFetchingDocumentPath] = React.useState('');

  const downloadDoc = ({ path, realName }: IDocument) => {
    setFetchingDocumentPath(path);
    const url = `${UPLOAD_SERVICE_BASE_URL}/${path}`;
    const docName = `${realName}`;

    downloadDocument(url, docName).finally(() => setFetchingDocumentPath(''));
  };

  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'displayName',
      render: displayName => <Typography>{displayName}</Typography>,
    },
    {
      key: 2,
      title: 'Автор',
      dataIndex: 'creatorData',
      width: '140px',
      render: creatorData => (
        <Typography>
          {creatorData?.lastName} {creatorData?.firstName}
        </Typography>
      ),
    },
    {
      key: 3,
      title: 'Дата и время загрузки',
      width: '140px',
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography>{dayjs(createdAt).format('DD.MM.YYYY HH:mm')}</Typography>
      ),
    },
    {
      key: 4,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, { document }: IReportDocument) => (
        <Button
          type='text'
          icon={<DownloadOutlined />}
          onClick={() => {
            if (UPLOAD_SERVICE_BASE_URL && document.path && document.realName) {
              downloadDoc(document);
            }
          }}
          loading={fetchingDocumentPath === document.path}
        />
      ),
    },
  ];

  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
    />
  );
};
