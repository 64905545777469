import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, notification, Row, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';

import {
  IHistoryItem,
  IHistoryResponse,
} from '../../../../store/slices/b2bHistory/interfaces';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  deleteHistoryItem,
  getHistory,
} from '../../../../store/slices/b2bHistory/actions';
import { mainBusinessHeaders } from '../../../../constants/mainBusiness';
import { useDeepEffect } from '../../../../utils/useDeepEffect';
import { LangEnumKeys } from '../../../../constants/lang';
import { CardTable } from '../../../../components/Card/CardTable';
import { LangSelectSection } from '../../../../components/form/LangSelectSection/LangSelectSection';
import { StickyConfirmModal } from '../../../../components/modals/StickyConfirmModal';
import { MainLayout } from '../../../../components/Layout/MainLayout';

interface IFormValues extends IHistoryResponse {
  lang: { value: LangEnumKeys; label: string };
}

export const History = () => {
  const dispatch = useAppDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState<IHistoryItem | null>(null);

  const data = useAppSelector(state => state.b2bHistory);

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };
  const handleRemove = (id: number) =>
    dispatch(deleteHistoryItem({ historyItemId: id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        modalCloseHandler();
        loadData();
      });

  const methods = useForm<IFormValues>({
    defaultValues: { ...data.history, lang: { value: 'ru', label: 'Русский' } },
  });

  const loadData = () => {
    dispatch(getHistory({ lang: lang.value }));
  };

  const getActions = item => [
    {
      element: <Typography.Text type='danger'>Удалить</Typography.Text>,
      handler: () => {
        setModalIsOpen(true);
        setCurrentItem(item);
      },
    },
    {
      element: (
        <Link to={`${data.history.id}/${item.id}/edit`}>
          <Typography.Text>Редактировать</Typography.Text>
        </Link>
      ),
    },
  ];

  const getLink = (id: number) => `${data.history.id}/${id}/edit`;

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    loadData();
  }, [lang]);

  return (
    <MainLayout
      pageTitle={mainBusinessHeaders.history}
      extraButtonsList={[
        <Link key={1} to={`${data.history.id}/add`}>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
    >
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical indent-top'>
          <LangSelectSection />
        </form>
      </FormProvider>
      {data.isLoading ? (
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        <Card style={{ marginTop: 10 }}>
          <CardTable
            data={data.history.historyItems.data}
            getActions={getActions}
            getLink={getLink}
          />
        </Card>
      )}
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление слайда'
              okText='Удалить'
              text={<div>Вы хотите удалить слайд?</div>}
              onSubmit={() => handleRemove(currentItem.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
