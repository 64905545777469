import React, { useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { IHistoryItem } from '../../../../store/slices/b2bHistory/interfaces';
import {
  getHistoryItem,
  setHistoryItem,
} from '../../../../store/slices/b2bHistory/actions';
import { MainLayout } from '../../../../components/Layout/MainLayout';
import { HistoryItemForm } from './HistoryItemForm';
import CardSpin from '../../../../components/Card/CardSpin';
import { mapHistoryValues } from '../../utils';

export const EditHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(true);
  const { historyItemId, historyId } = useParams();
  const state = useAppSelector(state => state.b2bHistory);
  const onSubmit = (values: IHistoryItem) => {
    setIsLoading(true);
    dispatch(
      setHistoryItem({
        historyId,
        payload: { ...mapHistoryValues(values), id: Number(historyItemId) },
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/mainBusiness/history');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    dispatch(getHistoryItem({ historyItemId, historyId }))
      .unwrap()
      .finally(() => {
        setQueryLoading(false);
      });
  }, []);

  if (queryLoading) {
    return <CardSpin />;
  }
  return (
    <MainLayout pageTitle='Редактирование'>
      <Content>
        <HistoryItemForm
          isLoading={isLoading}
          initialValues={state.historyItem}
          onSubmit={onSubmit}
        />
      </Content>
    </MainLayout>
  );
};
