import React from 'react';

import { Edit } from './Edit';

type TProps = {
  onChange: (arg) => void;
  name: string;
  value: { path: string; baseUrl: string }[];
};

export const ReviewGallery = ({ value, ...props }: TProps) => {
  const handleDelete = (index: number) => {
    const newFileList = [...value];
    newFileList.splice(index, 1);
    props.onChange(newFileList);
  };

  return (
    <div
      className={'ant-upload-list-picture-card'}
      style={{ display: 'flex', flexFlow: 'row wrap' }}
      tabIndex={0}
    >
      {value.map((item, index) => (
        <Edit
          key={index}
          file={item}
          {...props}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </div>
  );
};
