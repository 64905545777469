import { LandPlotApplicationsTypes } from 'types/applications';
import { urls } from 'store/api';

export const APPLICATION_URLS_BY_TYPE = {
  [LandPlotApplicationsTypes.landLeaseApplications]:
    urls.api.forms.landLeaseApplications.download.get,
  [LandPlotApplicationsTypes.landPlotUrbanPlanningApplications]:
    urls.api.forms.urbanPlanning.download.get,
  [LandPlotApplicationsTypes.connectionNetworksItoApplications]:
    urls.api.forms.connectITO.download.get,
  [LandPlotApplicationsTypes.leaseRealEstateNonResidentApplications]:
    urls.api.forms.realEstateNonResidents.download.get,
  [LandPlotApplicationsTypes.cadastralWorkApplications]:
    urls.api.forms.cadastralWork.download.get,
};
