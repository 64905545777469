import React from 'react';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { loadReportItem, removeReportItem } from 'store/slices/reports/actions';
import { resetReportItem } from 'store/slices/reports/reportsSlice';
import { IReport } from 'store/slices/reports/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { reportName } from 'constants/reports';

import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';
import { ViewInfo } from './components/ViewInfo/ViewInfo';

export const View = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<IReport>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const { item } = useAppSelector(state => state.reports);
  const dispatch = useAppDispatch();

  const loadReport = () => dispatch(loadReportItem(id));

  React.useEffect(() => {
    loadReport();
    return () => {
      dispatch(resetReportItem());
    };
  }, []);

  const handleCloseModalAction = React.useCallback(() => {
    setModalIsOpen(null);
    setCurrentItem(null);
  }, []);

  const handleRemove = () =>
    dispatch(removeReportItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Отчет успешно удален',
        });
        handleCloseModalAction();
        navigate('/reports');
      });

  return (
    <MainLayout
      extraButtonsList={[
        <Button
          onClick={() => {
            setModalIsOpen(true);
            setCurrentItem(item);
          }}
          key={0}
          danger
        >
          Удалить
        </Button>,
      ]}
      pageTitle={item && item.id === id ? reportName[item.type] : ''}
      aside={<div />}
    >
      {item && item.id === id && <ViewInfo />}
      {currentItem?.id && modalIsOpen && (
        <ConfirmModal
          onRemove={handleRemove}
          onCloseModalAction={handleCloseModalAction}
          modalIsOpen={modalIsOpen}
          currentItem={currentItem}
        />
      )}
    </MainLayout>
  );
};
