import { WidgetTypeEnum } from 'components/form/Content/contentTypes';

export enum ReviewCategoriesEnum {
  resorts = 'resorts',
  attractions = 'attractions',
  hotels = 'hotels',
  restaurants = 'restaurants',
  routes = 'routes',
  events = 'events',
}

export const reviewCategoriesNamesHash = {
  [ReviewCategoriesEnum.resorts]: 'Курорты',
  [ReviewCategoriesEnum.attractions]: 'Достопримечательности',
  [ReviewCategoriesEnum.hotels]: 'Отели',
  [ReviewCategoriesEnum.restaurants]: 'Кафе и рестораны',
  [ReviewCategoriesEnum.routes]: 'Маршруты',
  [ReviewCategoriesEnum.events]: 'События',
};

export const entityTypeForUrlHash = {
  [WidgetTypeEnum.resorts]: 'resorts',
  [WidgetTypeEnum.attractions]: 'attractions',
  [WidgetTypeEnum.hotels]: 'hotels',
  [WidgetTypeEnum.restaurants]: 'bars-and-restaurants',
  [WidgetTypeEnum.routes]: 'touristRoutes',
  [WidgetTypeEnum.events]: 'events',
};
