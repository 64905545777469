// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvestInfrastructuresForm-module__videoGalleryContainer--WfMsf {\n  margin-top: 24px;\n}\n.InvestInfrastructuresForm-module__videoGalleryTitle--FgW9e {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/InvestmentInfrastructures/components/InvestInfrastructuresForm/InvestInfrastructuresForm.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,kBAAA;AAAF","sourcesContent":[".videoGalleryContainer {\n  margin-top: 24px;\n}\n\n.videoGalleryTitle {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoGalleryContainer": "InvestInfrastructuresForm-module__videoGalleryContainer--WfMsf",
	"videoGalleryTitle": "InvestInfrastructuresForm-module__videoGalleryTitle--FgW9e"
};
export default ___CSS_LOADER_EXPORT___;
