import React from 'react';
import { Button, Dropdown, Image, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { getImageUrl } from 'utils/image';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { IInvestInfrastructuresItemResponse } from 'store/slices/investInfrastructures/interfaces';

interface IProps {
  data: IInvestInfrastructuresItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IInvestInfrastructuresItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (text, item: IInvestInfrastructuresItemResponse) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/investInfrastructures/${item.id}/edit`}
          >
            <div style={{ flexShrink: 0 }}>
              <Image
                width={50}
                height={50}
                preview={false}
                src={
                  item?.image
                    ? getImageUrl(item.image, 50, 50) ||
                      '/public/images/no-image.svg'
                    : '/public/images/no-image.svg'
                }
                fallback='/public/images/no-image.svg'
                style={{ flexShrink: 0 }}
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <div>{(text && text.trim()) || 'Без названия'}</div>
            </div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: 150,
      title: 'Тип площадки',
      dataIndex: 'territoryData',
      render: territoryData => (
        <Typography>{territoryData?.name || 'Не указано'}</Typography>
      ),
    },
    {
      key: 3,
      width: 162,
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 4,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IInvestInfrastructuresItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
