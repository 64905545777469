import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Col, Radio, Row, Typography } from 'antd';
import classNames from 'classnames';

import { getValueByStringKeyWithArr } from 'utils/objects';

import styles from './MaterialItem.module.less';
import { MaterialFile } from '../MaterialFile';
import { MaterialLink } from '../MaterialLink';
import { MaterialEnum, MaterialItemProps } from './types';
import { Header } from '../Header';

const typesHash = {
  file: MaterialFile,
  url: MaterialLink,
};

export const MaterialItem = ({
  index,
  name,
  methods,
  validFormatsList,
  accept,
  uploadsUnsupportedFileType,
  ...props
}: MaterialItemProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const ref = React.useRef(null);
  const fieldName = `${name}.${index}`;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { type: materialType, ...fieldValue } = useWatch({
    name: fieldName,
    control,
  });

  const currentMaterialItemError = getValueByStringKeyWithArr(
    errors,
    fieldName
  );

  React.useEffect(() => {
    /* Автоскролл к блоку, если в одном из полей материалов есть
       ошибка и нету ошибок в других полях формы */
    if (
      currentMaterialItemError &&
      ref?.current &&
      Object.keys(errors).length <= 1
    ) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsOpen(true);
    }
  }, [ref, currentMaterialItemError]);

  const { move, fields, remove } = methods;
  const FieldComponent = typesHash[materialType];
  const materialField = fieldValue[materialType] || {};

  return (
    <Col {...props}>
      <div ref={ref}>
        <Row
          className={classNames(styles.top, {
            [styles.error]: !!currentMaterialItemError,
          })}
        >
          <Header
            className={styles.title}
            type={materialType}
            index={index}
            fieldsLength={fields.length}
            move={move}
            setIsOpen={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            remove={() => remove(index)}
            {...materialField}
          />
        </Row>
        {isOpen && (
          <Col className={styles.bottom}>
            <Controller
              name={`${fieldName}.type`}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Radio.Button value={MaterialEnum.FILE}>
                    Загрузить
                  </Radio.Button>
                  <Radio.Button value={MaterialEnum.URL}>
                    Указать ссылку
                  </Radio.Button>
                </Radio.Group>
              )}
            />
            <div className={styles.uploadButtonContainer}>
              <FieldComponent
                name={fieldName}
                validFormatsList={validFormatsList}
                accept={accept}
                uploadsUnsupportedFileType={uploadsUnsupportedFileType}
              />
              {currentMaterialItemError &&
                materialType === MaterialEnum.FILE && (
                  <Typography.Text className={styles.errorUploadMessage}>
                    {
                      getValueByStringKeyWithArr(
                        errors,
                        `${fieldName}.file.path`
                      )?.message
                    }
                  </Typography.Text>
                )}
            </div>
          </Col>
        )}
        {currentMaterialItemError && (
          <Typography.Text className={styles.errorMessage}>
            Проверьте правильность заполнения полей
          </Typography.Text>
        )}
      </div>
    </Col>
  );
};
