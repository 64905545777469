import * as Yup from 'yup';

export const cardValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Введите от 1 до 60 символов')
    .max(60, 'Введите от 1 до 60 символов')
    .required(),
  subtitle: Yup.string()
    .min(1, 'Введите от 1 до 128 символов')
    .max(128, 'Введите от 1 до 128 символов')
    .required(),
  sortPriority: Yup.number().min(1).required(),
});
