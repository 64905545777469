import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { configResponsive, useResponsive, useClickAway } from 'ahooks';

import { MainHeader } from '../MainHeader/MainHeader';
import { Sidebar } from '../Sidebar/Sidebar';

configResponsive({
  small: 0,
  middle: 800,
  large: 1400,
  extraLarge: 1800,
});

export const BasicLayout = ({ children }: PropsWithChildren) => {
  const responsive = useResponsive();
  const [sidebarCollapsedStatus, setSidebarCollapsedStatus] =
    React.useState(false);
  const onCollapse = (status = null) => {
    setSidebarCollapsedStatus(prev =>
      typeof status === 'boolean' ? status : !prev
    );
  };
  useClickAway(event => {
    const target = event.target as HTMLElement;
    const sidebar = target.closest('.ant-layout-sider');
    const header = target.closest('.ant-layout-header');
    const headerBtn = target.closest('.ant-header-btn');
    if (!responsive.middle && !sidebar && !header && !headerBtn) {
      return onCollapse(true);
    }
    if (!responsive.middle && headerBtn && sidebarCollapsedStatus) {
      return onCollapse(true);
    }
  }, []);
  const sidebarWidth = responsive.middle
    ? sidebarCollapsedStatus
      ? '80'
      : '255'
    : 0;
  return (
    <Layout
      style={{
        // fixed header
        paddingTop: '64px',
        // fixed sidebar
        paddingLeft: `${sidebarWidth}px`,
        maxWidth: '100vw',
        minHeight: '100vh',
      }}
    >
      <MainHeader
        showSideBar={sidebarCollapsedStatus}
        baseURL={'/'}
        onCollapse={onCollapse}
      />
      <Sidebar
        sidebarCollapsedStatus={sidebarCollapsedStatus}
        onCollapse={onCollapse}
      />
      <Layout style={{ width: '100%' }}>{children}</Layout>
    </Layout>
  );
};
