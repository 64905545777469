import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';

interface IFormValues {
  exactFullNameQuery: string;
  emailQuery: string;
  phoneQuery: string;
  innQuery: string;
}

export const Filters: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        exactFullNameQuery:
          values.exactFullNameQuery && values.exactFullNameQuery.trim(),
        emailQuery: values.emailQuery && values.emailQuery.trim(),
        phoneQuery: values.phoneQuery && values.phoneQuery.trim(),
        innQuery: values.innQuery && values.innQuery.trim(),
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      exactFullNameQuery: '',
      emailQuery: '',
      phoneQuery: '',
      innQuery: '',
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='exactFullNameQuery'
            label='ФИО'
            placeholder='Введите ФИО'
            maxLength={75}
          />
          <InputField
            name='emailQuery'
            label='Почта'
            placeholder='Введите почту'
            maxLength={255}
          />
          <InputField
            name='phoneQuery'
            label='Телефон'
            placeholder='Введите телефон'
            maxLength={255}
          />
          <InputField
            name='innQuery'
            label='Организация'
            placeholder='Введите ИНН'
            maxLength={255}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
