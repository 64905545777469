import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Collapse, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import dayjs from 'dayjs';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import { contactIconsHash } from 'constants/contacts';
import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import {
  loadTouristItem,
  loadTouristReviews,
} from 'store/slices/tourist/actions';
import { formatPhoneNumber } from 'utils/format';
import { contactsFormat } from 'utils/contacts';
import { useDeepDidMountEffect } from 'utils/useDeepDidMountEffect';
import { useDeepEffect } from 'utils/useDeepEffect';

import { TouristReviews } from './TouristReviews';
import styles from './View.module.less';

export const View = () => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'reviews',
  ]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { item, reviews, totalReviews } = useAppSelector(
    state => state.tourist
  );
  const [offset, setOffset] = React.useState(0);
  const initialLoad = async () => {
    await dispatch(loadTouristReviews({ id, offset, limit: 5 }));
    await dispatch(loadTouristItem(id));
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  useDeepDidMountEffect(() => {
    dispatch(loadTouristReviews({ id, offset, limit: 5 }));
  }, [offset]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [offset]);

  return (
    <MainLayout
      pageTitle='Просмотр пользователя туриста'
      extraButtonsList={[
        <Link key={1} to={`/tourist/${id}/edit`}>
          <Button>Редактировать</Button>
        </Link>,
      ]}
      hasBackButton
      backButtonPath='/tourist'
      aside={
        item.id && item.id === Number(id) ? (
          <div>
            <Content>
              <Row style={{ flexFlow: 'column nowrap' }}>
                <Typography.Title level={5} style={{ marginBottom: '17px' }}>
                  Контакты
                </Typography.Title>
                <Space direction='vertical' size='small'>
                  {item.phone && (
                    <Typography.Link
                      href={contactsFormat({
                        value: item.phone,
                        type: DefaultContactOptionsTypes.Phone,
                      })}
                    >
                      <span className={styles.contactIconPhone}>
                        {contactIconsHash[DefaultContactOptionsTypes.Phone]}
                      </span>
                      <span className={styles.contact}>
                        {formatPhoneNumber(item.phone)}
                      </span>
                    </Typography.Link>
                  )}
                  <Typography.Link
                    href={`mailto:${item.email}`}
                    target='_blank'
                  >
                    <span className={styles.contactIcon}>
                      {contactIconsHash[DefaultContactOptionsTypes.Email]}
                    </span>
                    <span className={styles.contact}>{item.email}</span>
                  </Typography.Link>
                </Space>
              </Row>
            </Content>
          </div>
        ) : null
      }
    >
      {item.id && item.id === Number(id) ? (
        <>
          <Content>
            <Row>
              <Typography.Text
                style={{
                  fontSize: '20px',
                  lineHeight: '28ps',
                  fontWeight: 400,
                }}
              >
                {`${item.lastName} ${item.firstName} ${
                  item.secondName ? item.secondName : ''
                }`}
              </Typography.Text>
            </Row>
            {item.position && (
              <>
                <Row style={{ marginTop: '16px' }}>
                  <Typography.Text type='secondary'>Должность</Typography.Text>
                </Row>
                <Row style={{ marginTop: '4px' }}>
                  <Typography.Text>{item.position || '-'}</Typography.Text>
                </Row>
              </>
            )}
            <Row style={{ marginTop: '16px' }}>
              <Typography.Text type='secondary'>
                Дата регистрации
              </Typography.Text>
            </Row>
            <Row style={{ marginTop: '4px' }}>
              <Typography.Text>
                {dayjs(item.createdAt).format('DD.MM.YY HH:mm')}
              </Typography.Text>
            </Row>
          </Content>
          {reviews?.length > 0 && (
            <Collapse
              bordered={false}
              expandIconPosition='end'
              onChange={(value: string[]) => setActiveCollpaseTabs(value)}
              activeKey={activeCollpaseTabs}
            >
              <Collapse.Panel header='Отзывы пользователя' key='reviews'>
                <Row>
                  <TouristReviews
                    reviews={reviews}
                    total={totalReviews}
                    offset={offset}
                    onLoadMore={value => setOffset(value)}
                  />
                </Row>
              </Collapse.Panel>
            </Collapse>
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
