import React from 'react';
import { Modal, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';

import { PolylinesMap } from './components/PolylinesMap/PolylinesMap';
import styles from './PolylineModal.module.less';

type LocationSelectorProps = {
  polygonPosition: number[][][];
  isOpenModal: boolean;
  mapPositionName?: string;
  onMapPolygonChange: (v: number[][][]) => void;
  setPolygonPosition: (v: number[][][]) => void;
  setIsOpenModal: (v: boolean) => void;
};

export const PolylineModal: React.FC<LocationSelectorProps> = ({
  polygonPosition,
  isOpenModal,
  onMapPolygonChange,
  setIsOpenModal,
  setPolygonPosition,
}) => {
  const [modalPolygonPosition, setModalPolygonPosition] = React.useState([]);
  const [isOpenCancelModal, setIsOpenCancelModal] = React.useState(false);
  const { breakpoint } = useBreakpoints();
  const isMobile = breakpoint === BreakpointsEnum.mobile;

  const polygonValuesLength = polygonPosition?.[0]?.length;
  const polygonModalValuesLength = modalPolygonPosition?.[0]?.length;

  const onCancelHandler = () => {
    if (polygonModalValuesLength > 1 && polygonModalValuesLength < 5) {
      setIsOpenCancelModal(true);
      return;
    }
    if (!polygonModalValuesLength) {
      setIsOpenModal(false);
    }
    if (polygonModalValuesLength === 5) {
      setIsOpenCancelModal(true);
      return;
    }
    setIsOpenModal(false);
  };

  const onOkHandler = () => {
    if (polygonModalValuesLength > 1 && polygonModalValuesLength < 5) {
      setIsOpenCancelModal(true);
      return;
    }
    if (!polygonModalValuesLength) {
      setIsOpenModal(false);
      return;
    }
    if (polygonModalValuesLength < 5) {
      setIsOpenCancelModal(true);
      return;
    }
    onMapPolygonChange(modalPolygonPosition);
    setPolygonPosition(modalPolygonPosition);
    setIsOpenModal(false);
  };

  const resetPolyline = () => {
    if (polygonValuesLength === 5) {
      setIsOpenCancelModal(false);
      setIsOpenModal(false);
    } else {
      setIsOpenCancelModal(false);
      setIsOpenModal(false);
      setPolygonPosition([]);
    }
  };

  return (
    <Modal
      open
      title='Редактирование контура'
      onCancel={onCancelHandler}
      maskClosable={false}
      okText='Завершить'
      cancelText='Отмена'
      width='1344px'
      onOk={onOkHandler}
    >
      <Row className={styles.editModal}>
        <PolylinesMap
          height={isMobile ? 354 : 600}
          polygonPosition={polygonPosition}
          isOpenModal={isOpenModal}
          setPolygonPosition={setModalPolygonPosition}
          setIsOpenModal={setIsOpenModal}
        />
      </Row>
      <Modal
        open={isOpenCancelModal}
        onCancel={() => setIsOpenCancelModal(false)}
        okText='Выйти'
        cancelText='Не отменять'
        width='400px'
        onOk={resetPolyline}
        className={styles.cancelModal}
      >
        <div className={styles.warnModal}>
          <ExclamationCircleOutlined className={styles.warnIcon} />
          <div className={styles.warnModalInfo}>
            <div className={styles.warnModalTitle}>Отмена</div>
            <div>
              Вы уверены, что хотите отменить и выйти? Контур не будет сохранен.
            </div>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};
