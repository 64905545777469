import React, { memo } from 'react';
import { Button, notification, Upload } from 'antd';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { AxiosError } from 'axios';

import { uploadMaterial } from 'utils/upload';
import { IDocument } from 'types/document';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import styles from './CustomUpload.module.less';

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const VALID_FORMATS_LIST = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats',
  'application/pdf',
  'application/rtf',
];

enum SERVER_ERROR_DICTIONARY {
  UPLOADS_UNSUPPORTED_FILE_TYPE = 'У файла недопустимый формат. Загрузите файл с расширением .pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .jpeg, .jpg, .png',
}

interface IProps {
  onAdd: (file: IDocument) => void;
  buttonTitle?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  isAdditional?: boolean;
}

export const CustomUpload = memo(
  ({
    onAdd,
    buttonTitle = 'Загрузить документ',
    icon = <PlusOutlined />,
    disabled = false,
    isAdditional = false,
  }: IProps) => {
    const handleBeforeUpload = async (file: RcFile) => {
      const isValidSize = (file.size as number) < MAX_FILE_SIZE;
      const isValidType = VALID_FORMATS_LIST.includes(file.type);

      if (!isValidSize) {
        notification.error({
          message: 'Пожалуйста, выберите файл с размером не более 10 МБ',
        });
      }
      if (!isValidType) {
        notification.error({
          message: SERVER_ERROR_DICTIONARY.UPLOADS_UNSUPPORTED_FILE_TYPE,
        });
      }

      return (isValidType && isValidSize) || Upload.LIST_IGNORE;
    };

    const handleAddFile = async (info: UploadChangeParam) => {
      try {
        const { path, fileList } = await uploadMaterial(info);
        const file = fileList[0];

        onAdd({
          baseUrl: UPLOAD_SERVICE_BASE_URL,
          path,
          realName: file.name,
        });
      } catch (error) {
        if (error.code && SERVER_ERROR_DICTIONARY[error.code]) {
          notification.error({
            message: SERVER_ERROR_DICTIONARY[error.code],
          });
        } else {
          notification.error({
            message: (error as AxiosError)?.message,
          });
        }
      }
    };

    const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) =>
      setTimeout(() => {
        onSuccess?.('ok');
      }, 0);

    return (
      <Upload
        accept='.pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .jpeg, .jpg, .png'
        maxCount={1}
        customRequest={dummyRequest}
        onChange={handleAddFile}
        beforeUpload={handleBeforeUpload}
        fileList={[]}
        disabled={disabled}
      >
        {isAdditional ? (
          <Button
            className={styles.button}
            type='link'
            icon={<PlusCircleOutlined />}
          >
            Добавить приложение
          </Button>
        ) : (
          <Button icon={icon} disabled={disabled}>
            {buttonTitle}
          </Button>
        )}
      </Upload>
    );
  }
);

CustomUpload.displayName = 'CustomUpload';
