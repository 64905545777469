import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';

import { useDeepEffect } from 'utils/useDeepEffect';

import { InputField } from '../base/InputField';
import styles from './PriceRange.module.less';

export const PriceRange: React.FC<{ name: string }> = ({ name }) => {
  const { setValue, control } = useFormContext();
  const { price, freePrice } = useWatch({ name, control });

  useDeepEffect(() => {
    if (!price && !freePrice) {
      setValue(`${name}.maxPrice`, null);
    }
  }, [price]);

  return (
    <Form.Item
      extra='Пожалуйста, укажите цену или диапазон цен. Для бесплатных событий поставьте "Бесплатно".'
      style={{ marginBottom: 0 }}
    >
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <InputField
            name={`${name}.price`}
            label='Цена'
            type='price'
            suffix='₽'
            disabled={freePrice}
            formItemStyle={{ marginBottom: 8 }}
            style={{ fontWeight: 'normal' }}
            maxLength={7}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <InputField
            name={`${name}.maxPrice`}
            label='Макс. цена'
            type='price'
            suffix='₽'
            disabled={!price || freePrice}
            formItemStyle={{ marginBottom: 8 }}
            style={{ fontWeight: 'normal' }}
            maxLength={7}
          />
        </Col>
        <Row style={{ marginLeft: 8 }}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <Row align='bottom' className={styles.freePriceContainer}>
              <Checkbox
                checked={freePrice}
                onChange={e =>
                  setValue(name, {
                    price: null,
                    maxPrice: null,
                    freePrice: e.target.checked,
                  })
                }
              >
                Бесплатно
              </Checkbox>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form.Item>
  );
};
