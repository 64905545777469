import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { mapRegionForFilters, mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { InputField } from 'components/form/base/InputField';
import { Select } from 'components/form/base/Select';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import {
  ADS_TYPES_OPTIONS_FILTER,
  CONTRACTS_STATUSES_OPTIONS_FILTER,
} from 'constants/contracts';
import { AuthorAsyncSelect } from 'components/form/selects/AuthorAsyncSelect';
import { urls } from 'store/api';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  name: string;
  organizationName: string;
  creators: string[] | TFormSelectValue[];
  status: string | TFormSelectValue;
  type: string | TFormSelectValue;
  regionFiasIds: string[] | TFormSelectValue[];
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        name: values.name && values.name.trim(),
        organizationName:
          values.organizationName && values.organizationName.trim(),
        creators: values.creators.length ? mapSelect(values.creators) : null,
        status: values.status ? mapSelect(values.status) : null,
        type: values.type ? mapSelect(values.type) : null,
        regionFiasIds: values.regionFiasIds.length
          ? mapRegionForFilters(values.regionFiasIds)
          : null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      name: '',
      organizationName: '',
      creators: [],
      status: '',
      type: '',
      regionFiasIds: [],
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='name'
            label='Название'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select
            name='status'
            label='Статус публикации'
            options={CONTRACTS_STATUSES_OPTIONS_FILTER}
          />
          <Select
            name='type'
            label='Тип объявления'
            options={ADS_TYPES_OPTIONS_FILTER}
          />
          <InputField
            name='organizationName'
            label='Название компании'
            placeholder='Введите название компании'
            maxLength={255}
          />
          <CatalogRegionSelect
            name='regionFiasIds'
            label='Регион'
            mode='multiple'
            placeholder='Выберите регион'
          />
          <AuthorAsyncSelect
            name='creators'
            label='Автор'
            mode='multiple'
            placeholder='Выберите автора'
            url={urls.api.users.business.get}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
