import React from 'react';
import { Button, Col, Collapse, Row, Card, Typography } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDeepEffect } from 'utils/useDeepEffect';
import { Status } from 'components/form/Status/Status';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import {
  IRentalsItem,
  TRentalsDraftItem,
} from 'store/slices/rentals/interfaces';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { DatePicker } from 'components/form/base/DatePicker';
import { AddMaterial } from 'components/form/AddMaterial';
import { ExtensionContacts } from 'components/form/ExtensionContacts/ExtensionContacts';
import { ProcedureStatusSelect } from 'components/form/selects/ProcedureStatusSelect';
import { InputAutoCompleteField } from 'components/form/base/InputAutoCompleteField';
import { TextAreaAutoCompleteField } from 'components/form/base/TextAreaAutoCompleteField';
import { Statuses } from 'constants/status';
import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import {
  TProcedureStatuses,
  TProcedureStatusesNames,
} from 'constants/procedureStatus';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';
import {
  DEFAULT_ACCEPT,
  UPLOADS_UNSUPPORTED_FILE_TYPES,
  VALID_FORMATS_LIST,
} from './constants';
import styles from './RentalsForm.module.less';

interface IFormValues {
  name: string;
  rentMethodCatalogId: number | { value: number; label: string };
  statusProcedure:
    | TProcedureStatuses
    | { value: TProcedureStatuses; label: TProcedureStatusesNames };
  contactPerson: string;
  timePlaceProcedureDocumentation: string;
  paymentProcurementDocuments: string;
  securingProcurementApplication: string;
  securingContract: string;
  placeOpeningEnvelopes: string;
  dateTimeOpeningEnvelopes: string;
  placeConsiderationApplication: string;
  dateTimeConsiderationApplication: string;
  placeDebriefing: string;
  dateTimeDebriefing: string;
  datePlacementNotice: null;
  noticeNumber: string;
  materials: MaterialValue[];
  initialPrice: string;
  status: Statuses;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IRentalsItem) => void;
  onSaveDraft: (values: TRentalsDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const RentalsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([
    'contacts',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollapseTabs([
        ...Array.from(
          new Set([
            ...activeCollapseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Card title='Общая информация' bordered={false}>
          <Row>
            <Col span={24}>
              <TextAreaField
                name='name'
                label='Название предмета процедуры аренды имущества'
                required
                placeholder='Введите название'
                maxLength={500}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 10 }}>
              <CatalogAsyncSelect
                name='rentMethodCatalogId'
                label='Способ процедуры аренды'
                placeholder='Выберите способ'
                required
                requestParams={{
                  entityType: 'rent',
                  fieldType: 'rentMethodCatalogId',
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
              <ProcedureStatusSelect
                name='statusProcedure'
                label='Статус процедуры аренды'
                placeholder='Выберите статус'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputAutoCompleteField
                name='timePlaceProcedureDocumentation'
                params={{
                  entityName: 'rent',
                  field: 'rent.timePlaceProcedureDocumentation',
                }}
                label='Срок, место и порядок предоставления документации об аренде'
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputAutoCompleteField
                name='paymentProcurementDocuments'
                params={{
                  entityName: 'rent',
                  field: 'rent.paymentProcurementDocuments',
                }}
                label='Внесение платы за документацию об аренде'
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaAutoCompleteField
                name='securingProcurementApplication'
                label='Обеспечение заявки на участие в процедуре аренды'
                placeholder='Начните печатать текст'
                params={{
                  entityName: 'rent',
                  field: 'rent.securingProcurementApplication',
                }}
                maxLength={500}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaAutoCompleteField
                name='securingContract'
                label='Обеспечение исполнения договора'
                placeholder='Начните печатать текст'
                params={{
                  entityName: 'rent',
                  field: 'rent.securingContract',
                }}
                maxLength={500}
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='dateTimeOpeningEnvelopes'
                params={{
                  entityName: 'rent',
                  field: 'rent.dateTimeOpeningEnvelopes',
                }}
                label='Дата и время открытия доступа к заявкам на участие в процедуре аренды'
                maxLength={255}
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='placeOpeningEnvelopes'
                params={{
                  entityName: 'rent',
                  field: 'rent.placeOpeningEnvelopes',
                }}
                label='Место открытия доступа к заявкам на участие в процедуре аренды'
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='dateTimeConsiderationApplication'
                params={{
                  entityName: 'rent',
                  field: 'rent.dateTimeConsiderationApplication',
                }}
                label='Дата и время рассмотрения заявок на участие в процедуре аренды'
                maxLength={255}
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='placeConsiderationApplication'
                params={{
                  entityName: 'rent',
                  field: 'rent.placeConsiderationApplication',
                }}
                label='Место рассмотрения заявок на участие в процедуре аренды'
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='dateTimeDebriefing'
                params={{
                  entityName: 'rent',
                  field: 'rent.dateTimeDebriefing',
                }}
                label='Дата и время проведения аукциона'
                maxLength={255}
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='placeDebriefing'
                params={{
                  entityName: 'rent',
                  field: 'rent.placeDebriefing',
                }}
                label='Место проведения аукциона'
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <DatePicker
                name='datePlacementNotice'
                label='Дата размещения извещения'
                placeholder='Выберите дату начала'
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <InputAutoCompleteField
                name='noticeNumber'
                params={{
                  entityName: 'rent',
                  field: 'rent.noticeNumber',
                }}
                label='Номер извещения процедуры аренды'
                maxLength={60}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaField
                name='initialPrice'
                label='Начальная (минимальная) цена договора'
                maxLength={500}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className={styles.materialSection}>
              <Typography.Text>Документы</Typography.Text>
              <AddMaterial
                name='materials'
                title='Загрузить документ'
                className={styles.materialItem}
                validFormatsList={VALID_FORMATS_LIST}
                accept={DEFAULT_ACCEPT}
                uploadsUnsupportedFileType={UPLOADS_UNSUPPORTED_FILE_TYPES}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollapseTabs(value)}
          activeKey={activeCollapseTabs}
        >
          <Collapse.Panel header='Контакты' key='contacts'>
            <ExtensionContacts name='contacts' type='rent' />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
