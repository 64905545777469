import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { TContentBlock } from 'components/form/Content/contentTypes';
import { prepareContent } from 'components/form/Content/utils';
import { LangEnum } from 'constants/lang';
import {
  ProjectStatuses,
  projectStatusesNames,
  StatusesEnum,
} from 'constants/status';
import yup, { contentSchema, imageSchema } from 'utils/yup';

export const PROJECT_STATUS_OPTIONS = Object.keys(projectStatusesNames).map(
  item => ({
    value: item,
    label: projectStatusesNames[item as ProjectStatuses],
  })
);

export const PROJECT_STATUS_OPTIONS_FILTER = [
  { value: '', label: 'Все' },
  ...PROJECT_STATUS_OPTIONS,
];

export const INITIAL_VALUES = {
  image: null,
  lang: LangEnum.ru,
  name: '',
  status: StatusesEnum.PUBLISHED,
  region: null,
  projectStatus: null,
  shortDescription: '',
  industry: null,
  budget: '',
  workPlaces: '',
  investments: '',
  irr: '',
  npv: '',
  initiator: '',
  metaTitle: null,
  metaDescription: null,
  ogTitle: null,
  ogDescription: null,
  metaKeywords: null,
  content: prepareContent([]) as TContentBlock[],
  gallery: [],
  videoUrls: [{ value: '' }, { value: '' }],
  materials: [] as MaterialValue[],
};

export const VALIDATION_SCHEMA = yup.object().shape({
  image: imageSchema,
  name: yup.string().required(),
  region: yup.mixed().required(),
  projectStatus: yup.mixed().required(),
  status: yup.mixed().required(),
  industry: yup.mixed().required(),
  budget: yup.string().required(),
  content: contentSchema,
  gallery: yup.array().of(imageSchema),
  materials: yup.array().of(
    yup.object().shape({
      url: yup.object().shape({
        url: yup.string().url(),
      }),
    })
  ),
  videoUrls: yup.array().of(
    yup.object().shape({
      value: yup.string().url(),
    })
  ),
});

export const COLLAPSE_FIELDS_ERRORS = {
  industry: 'about',
  budget: 'about',
  content: 'about',
};
