import React from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Image } from 'antd';

import { getImageUrl } from 'utils/image';

export const Edit = ({ file, onDelete }) => {
  const [isPreviewVisible, setPreviewVisible] = React.useState(false);

  return (
    <div>
      <div className={`ant-upload-list-picture-card-container `}>
        <div
          className={
            'ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card'
          }
        >
          <div className={'ant-upload-list-item-info'}>
            <span className={'ant-upload-span'}>
              <Image
                src={getImageUrl(file, 190, 190)}
                preview={{
                  src: getImageUrl(file),
                  visible: isPreviewVisible,
                  onVisibleChange: visible => setPreviewVisible(visible),
                }}
              />
            </span>
          </div>
          <div className={'ant-upload-list-item-actions'}>
            <EyeOutlined
              className='anticon-delete'
              onClick={() => setPreviewVisible(!isPreviewVisible)}
            />
            <DeleteOutlined onClick={onDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
