// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ApplicationsForm-module__applicationNumber--HFP6U {\n  width: 170px;\n}\n@media (max-width: 576px) {\n  .ApplicationsForm-module__applicationNumber--HFP6U {\n    width: 100%;\n  }\n}\n.ApplicationsForm-module__applicationStatus--yuQei {\n  width: 220px;\n}\n@media (max-width: 576px) {\n  .ApplicationsForm-module__applicationStatus--yuQei {\n    width: 100%;\n  }\n}\n.ApplicationsForm-module__document--_ZUZl {\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  gap: 8px;\n  word-break: break-word;\n}\n.ApplicationsForm-module__document--_ZUZl:hover {\n  opacity: 0.6;\n}\n.ApplicationsForm-module__document--_ZUZl svg {\n  margin-right: 8px;\n  width: 16px;\n  height: 16px;\n  color: #8C8C8C;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Applications/components/ApplicationsForm/ApplicationsForm.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EAAA;IACE,WAAA;EAEF;AACF;AACA;EACE,YAAA;AACF;AACE;EAAA;IACE,WAAA;EAEF;AACF;AACA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,sBAAA;AACF;AACE;EACE,YAAA;AACJ;AATA;EAYI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AAAJ","sourcesContent":[".applicationNumber {\n  width: 170px;\n\n  @media (max-width: 576px) {\n    width: 100%;\n  }\n}\n\n.applicationStatus {\n  width: 220px;\n\n  @media (max-width: 576px) {\n    width: 100%;\n  }\n}\n\n.document {\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  gap: 8px;\n  word-break: break-word;\n\n  &:hover {\n    opacity: 0.6;\n  }\n\n  svg {\n    margin-right: 8px;\n    width: 16px;\n    height: 16px;\n    color: #8C8C8C;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"applicationNumber": "ApplicationsForm-module__applicationNumber--HFP6U",
	"applicationStatus": "ApplicationsForm-module__applicationStatus--yuQei",
	"document": "ApplicationsForm-module__document--_ZUZl"
};
export default ___CSS_LOADER_EXPORT___;
