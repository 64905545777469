import React, { memo } from 'react';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

interface IProps {
  onDelete: () => void;
  onMove: (step?: number) => void;
  onChangeStatus: () => void;
  isOpen: boolean;
  sort: number;
  total: number;
}

export const Extra = memo(
  ({ onDelete, onMove, onChangeStatus, isOpen, sort, total }: IProps) => (
    <div onClick={e => e.stopPropagation()}>
      <Row gutter={16}>
        <Col>
          <Row gutter={4}>
            <Col>
              <Button
                size='small'
                onClick={() => onMove(-1)}
                icon={<ArrowUpOutlined />}
                disabled={sort === 1}
              />
            </Col>
            <Col>
              <Button
                size='small'
                onClick={() => onMove()}
                icon={<ArrowDownOutlined />}
                disabled={sort === total}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={4}>
            <Col>
              <Button
                size='small'
                onClick={onDelete}
                icon={<DeleteOutlined />}
                danger
              />
            </Col>
            <Col>
              <Button
                size='small'
                onClick={onChangeStatus}
                icon={isOpen ? <DownOutlined /> : <UpOutlined />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
);

Extra.displayName = 'Extra';
