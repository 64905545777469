import React from 'react';
import { Button, Card, Col, Row } from 'antd';

import { formatterFieldCount } from '../../../../utils/formatterFieldCount';
import { InputField } from '../../../../components/form/base/InputField';
import { TextAreaField } from '../../../../components/form/base/TextAreaField';
import { FormActions } from '../../../../components/Layout/FormActions/FormActions';

interface IProps {
  isLoading: boolean;
  handleCancel: () => void;
}

export const AboutForm: React.FC<IProps> = ({ isLoading, handleCancel }) => {
  return (
    <Card title='Общая информация'>
      <Row>
        <Col span={24}>
          <InputField
            name='title'
            label='Заголовок'
            placeholder='Введите заголовок'
            minLength={1}
            maxLength={60}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaField
            name='description'
            label='Описание'
            placeholder='Введите описание'
            minLength={1}
            maxLength={255}
            rows={2}
            showCount={{
              formatter: formatterFieldCount,
            }}
            required
          />
        </Col>
      </Row>
      <FormActions>
        <Button onClick={handleCancel} disabled={isLoading}>
          Отмена
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          type='primary'
          htmlType='submit'
        >
          Сохранить
        </Button>
      </FormActions>
    </Card>
  );
};
