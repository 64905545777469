import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IAttractionItem } from 'store/slices/attractions/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadAttractionItem,
  saveAttractionItem,
  saveAttractionItemAsDraft,
} from 'store/slices/attractions/actions';
import { LangEnum } from 'constants/lang';

import { AttractionsForm } from './components/AttractionsForm';
import { mapValuesToForm } from './components/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.attractions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadAttractionItem(id));
  }, []);

  const onFinish = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/attractions');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    category: null,
    categoryData: null,
    tags: [],
    tagsData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item.id && item.id === Number(id) ? (
        <AttractionsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
