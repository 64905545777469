import {
  VacanciesWorkExperienceTypeEnum,
  VacanciesWorkScheduleTypeEnum,
  VacanciesWorkTypeEnum,
} from 'types/vacancies';

export const VACANCIES_WORK_TYPE_OPTIONS = Object.entries(
  VacanciesWorkTypeEnum
).map(([value, label]) => ({
  value,
  label,
}));

export const VACANCIES_WORK_SCHEDULE_OPTIONS = Object.entries(
  VacanciesWorkScheduleTypeEnum
).map(([value, label]) => ({
  value,
  label,
}));

export const VACANCIES_WORK_EXPERIENCE_OPTIONS = Object.entries(
  VacanciesWorkExperienceTypeEnum
).map(([value, label]) => ({
  value,
  label,
}));
