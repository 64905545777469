import React from 'react';
import { FormProvider, useForm, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Layout, Typography } from 'antd';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { Statuses } from 'constants/status';
import { Select } from 'components/form/base/Select';
import { AuthorAsyncSelect } from 'components/form/selects/AuthorAsyncSelect';
import { debounce, removeEmptyValues } from 'utils/helpers';
import { mapRegionForFilters, mapSelect } from 'utils/mappings';
import { LangEnumKeys } from 'constants/lang';
import { LocaleSelect } from 'components/form/selects/LocaleSelect';

import { FILTER_STATUS_OPTIONS } from './constants';
import { CatalogRegionSelect } from '../../../../components/form/selects/CatalogRegionSelect';
import { TFormSelectValue } from '../../../Vacancies/components/VacanciesForm/types';

export interface FilterValues {
  name: string;
  status: Statuses | '';
  authors: string[] | null;
  regionFiasIds: string[] | TFormSelectValue[];
  lang: LangEnumKeys | { value: LangEnumKeys; label: string } | '';
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const methods = useForm<FilterValues>({
    defaultValues: {
      name: '',
      authors: [],
      regionFiasIds: [],
      status: '',
      lang: '',
      ...query,
    },
  });

  const updateFilters = (filterValues: Record<string, string>) => {
    const queryString = new URLSearchParams(filterValues).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce(({ authors, status, regionFiasIds, ...values }: FilterValues) => {
      const preparedValues = {
        ...values,
        regionFiasIds: regionFiasIds.length
          ? mapRegionForFilters(regionFiasIds)
          : null,
        status: mapSelect(status),
        authors: authors?.length ? mapSelect(authors) : null,
        lang: mapSelect(values.lang),
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(
        debounceSubmit
      ) as unknown as WatchObserver<FilterValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: 16, paddingBottom: 1 }}
    >
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form-vertical'>
          <InputField
            name='name'
            placeholder='Введите название'
            label='Название'
            maxLength={255}
          />
          <Select
            name='status'
            options={FILTER_STATUS_OPTIONS}
            label='Статус'
          />
          <CatalogRegionSelect
            name='regionFiasIds'
            label='Регион'
            mode='multiple'
            placeholder='Выберите регион'
            isNewsPage
          />
          <AuthorAsyncSelect
            name='authors'
            label='Автор'
            placeholder='Выберите автора'
            mode='multiple'
          />
          <LocaleSelect
            name='lang'
            label='Язык'
            placeholder='Язык'
            withEmptyValue
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
