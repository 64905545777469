import React, { useCallback, useState } from 'react';
import { Col, notification, Pagination, PaginationProps, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteStrategy,
  loadStrategies,
  saveStrategy,
  updateStrategy,
} from 'store/slices/openingInfo/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { IDocument } from 'types/document';
import { TStrategy } from 'store/slices/openingInfo/interfaces';

import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import { CustomUpload } from '../CustomUpload/CustomUpload';
import { SimpleForm } from '../EntityForms/SimpleForm';
import styles from './Strategies.module.less';

const PAGE_SIZE = 3;

export const Strategies = () => {
  const [offset, setOffset] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);

  const {
    strategies: { list, total },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const loadListStrategies = async (offset: number) =>
    await dispatch(
      loadStrategies({
        limit: PAGE_SIZE,
        offset: (offset - 1) * PAGE_SIZE,
      })
    ).unwrap();

  useDeepEffect(() => {
    loadListStrategies(offset);
  }, [offset]);

  const handleChangePage: PaginationProps['onChange'] = page => setOffset(page);

  const handleAdd = useCallback(
    async (file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      await dispatch(saveStrategy({ file, title, sortIndex: total + 1 }));
      loadListStrategies(1);
    },
    [total]
  );

  const handleUpdate = useCallback(
    async (file: IDocument, title: string, { id, sortIndex }: TStrategy) =>
      await dispatch(updateStrategy({ file, title, id, sortIndex })),
    []
  );

  const handleMove = useCallback(
    async (strategy: TStrategy, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === strategy.id);
      const swapEl = list[currentIndex + step];

      await dispatch(
        updateStrategy({
          id: swapEl.id,
          file: swapEl.file,
          title: swapEl.title,
          sortIndex: strategy.sortIndex,
        })
      );

      await dispatch(
        updateStrategy({
          id: strategy.id,
          file: strategy.file,
          title: strategy.title,
          sortIndex: swapEl.sortIndex,
        })
      );

      await loadListStrategies(offset);
    },
    [offset, list]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteStrategy(id));
      handleCloseModal();
      await loadListStrategies(1);
      notification.success({
        message: 'Документ удалён',
      });
    },
    [offset]
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <CustomUpload
            onAdd={handleAdd}
            disabled={list.length >= 1 || loading}
          />
        </Col>
      </Row>
      {total > 0 && (
        <Row className={styles.list}>
          <Col span={24}>
            {list.map(item => (
              <SimpleForm
                key={item.id}
                onMove={handleMove}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                total={total}
                entity={item}
              />
            ))}
          </Col>
        </Row>
      )}
      <Row justify='end'>
        <Col>
          <Pagination
            size='small'
            current={offset}
            onChange={handleChangePage}
            total={total}
            pageSize={PAGE_SIZE}
            hideOnSinglePage
          />
        </Col>
      </Row>
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          text='Вы уверены, что хотите удалить документ?'
        />
      )}
    </div>
  );
};
