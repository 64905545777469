import React, { memo } from 'react';
import { Col, Row, Typography } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import { IDocument } from 'types/document';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import styles from './Header.module.less';

interface IProps {
  file: IDocument;
  title: string;
}

export const Header = memo(({ file, title }: IProps) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col>
          <Typography.Text className={styles.title} ellipsis>
            {title}
          </Typography.Text>
        </Col>
      </Row>
      <Row wrap={false} align='middle'>
        <Col>
          <Typography.Text className={styles.subTitle} ellipsis>
            Файл:{' '}
            <Typography.Link
              target='_blank'
              href={`${UPLOAD_SERVICE_BASE_URL}/${file.path}`}
              onClick={e => e.stopPropagation()}
            >
              {file.realName}
              <SelectOutlined className={styles.icon} />
            </Typography.Link>
          </Typography.Text>
        </Col>
      </Row>
    </div>
  );
});

Header.displayName = 'Header';
