export enum B2BBlockPriority {
  HeroBlock = 'HeroBlock',
  WhyBlock = 'WhyBlock',
  SuccessStoriesBlock = 'SuccessStoriesBlock',
  HelpBlock = 'HelpBlock',
  SupportMeasuresBlock = 'SupportMeasuresBlock',
  NewsBlock = 'NewsBlock',
  InfoBlock = 'InfoBlock',
}

export enum B2CBlockPriority {
  slider = 'slider',
  tourists_routes = 'tourists-routes',
  what_visit = 'what-visit',
  places = 'places',
  events = 'events',
  news = 'news',
  map = 'map',
  articles = 'articles',
}

export enum AboutRegionBlockPriority {
  hero = 'hero',
  info = 'info',
  description = 'description',
  regions = 'regions',
  map = 'map',
}

export type B2BBlockPriorityKeys = `${B2BBlockPriority}`;

export type B2CBlockPriorityKeys = `${B2CBlockPriority}`;

export type AboutRegionPriorityKeys = `${AboutRegionBlockPriority}`;
