import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'antd';

import { PreferencesItem } from './components/PreferencesItem/PreferencesItem';
import styles from './Preferences.module.less';

export const Preferences: React.FC<{ name: string }> = ({ name }) => {
  const { control } = useFormContext();

  const { fields, append, move, remove } = useFieldArray({
    name: name,
    control,
  });

  const addItem = () => {
    append({
      icon: null,
      file: null,
      title: '',
      description: '',
      alt: '',
    });
  };

  const changePosition = (prevIndex, nextIndex) => {
    move(prevIndex, nextIndex);
  };

  const removeItem = index => {
    remove(index);
  };

  return (
    <div>
      {fields.length > 0 && (
        <div className={styles.list}>
          {fields.map((item: any, index) => (
            <PreferencesItem
              key={item.id}
              name={`${name}.${index}`}
              index={index}
              total={fields.length}
              changePosition={changePosition}
              removeItem={removeItem}
            />
          ))}
        </div>
      )}
      <Row style={{ marginTop: fields.length ? 24 : 0 }}>
        <Col span={24}>
          <Button onClick={addItem}>+ Добавить</Button>
        </Col>
      </Row>
    </div>
  );
};
