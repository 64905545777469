export enum OrganizationTypeEnum {
  ip = 'ip',
  organization = 'organization',
}

export type OrganizationType = keyof typeof OrganizationTypeEnum;
export enum TaxSystem {
  Osno = 'osno',
  Usn = 'usn',
  UsnEnvd = 'usnEnvd',
  UsnPsn = 'usnPsn',
  Envd = 'envd',
  Psn = 'psn',
}

export type TTaxSystem = `${TaxSystem}`;

export type TTaxSystemIp = Exclude<TTaxSystem, 'usn'>;

export type TTaxSystemOrganization = Exclude<TTaxSystem, 'psn' | 'usnPsn'>;

export type OrganizationsItemDadata = {
  hid: string;
  name: string;
};

export interface OrganizationsItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: OrganizationType;
  fullName: string;
  shortName: string;
  kpp: string;
  inn: string;
  bik: string;
  account: string;
  author: number;
  authorData: {
    firstName: string;
    id: number;
    lastName: string;
    secondName: string;
  };
  creator: number;
  editor: number;
  ownerData: {
    firstName: string;
    lastName: string;
    secondName: string;
  };
  firstName?: string;
  lastName?: string;
  secondName?: string;
  registerDate?: Date;
  taxSystemIp?: TTaxSystemIp;
  taxSystemOrganization?: TTaxSystemOrganization;
  ogrn?: string;
  okpo?: string;
  okved?: string;
  okato?: string;
  oktmo?: string;
  bankName?: string;
  corrAccount?: string;
  registerAddress?: string;
  actualAddress?: string;
  phone?: string;
  email?: string;
  dadataInfo: OrganizationsItemDadata;
  isDraft?: boolean;
  siteUrl?: string;
}

export enum Roles {
  Owner = 'owner',
  Guest = 'guest',
}

export type TRoles = `${Roles}`;
