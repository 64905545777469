import React from 'react';
import { Button, notification, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';
import { useAppDispatch, useAppSelector } from 'store';
import { useQuery } from 'hooks/useQuery';
import {
  deletePurchasePlan,
  loadPurchasePlansList,
  setUnPublishPurchasePlan,
} from 'store/slices/purchasePlans/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { canBeUnPublished } from 'utils/rights';
import {
  PurchasePlansItem,
  QueryFormValues,
} from 'store/slices/purchasePlans/interfaces';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { StatusesEnum } from 'constants/status';

import { TablePlans } from './components/TablePlans';
import { Filters } from './components/Filters';

export const List = () => {
  const query = useQuery();
  const [sort, setSort] = React.useState<string | undefined>('');
  const [isRemoveModalIsOpen, setRemoveModalIsOpen] =
    React.useState<boolean>(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { list, total, limit } = useAppSelector(state => state.purchasePlans);

  const onCreateClick = () => navigate('add');

  const loadItems = () =>
    dispatch(loadPurchasePlansList({ ...(query as QueryFormValues), sort }));

  const handleUnPublish = async (id: number) => {
    await dispatch(setUnPublishPurchasePlan(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadItems();
      });
  };

  useDeepEffect(() => {
    loadItems();
  }, [query, sort]);

  const buttonList = [
    <Button key={1} onClick={onCreateClick} type='primary'>
      Создать
    </Button>,
  ];

  const onRemoveModalClose = () => setRemoveModalIsOpen(false);

  const onRemoveClick = async () => {
    await dispatch(deletePurchasePlan({ id: currentItem.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        loadItems();
        onRemoveModalClose();
      });
  };

  const getActions = (item: PurchasePlansItem): ItemType[] => [
    {
      key: 'edit',
      label: <Link to={`/purchasePlans/${item.id}/edit`}>Редактировать</Link>,
    },
    {
      key: 'unPublish',
      label: <Typography.Text>Снять с публикации</Typography.Text>,
      onClick: () => handleUnPublish(item?.id || 0),
      disabled: !canBeUnPublished(item),
    },
    {
      key: 'remove',
      label: 'Удалить',
      danger: true,
      onClick: () => {
        if (item.status === StatusesEnum.PUBLISHED) {
          notification.error({
            message: 'Необходимо снять сущность с публикации',
          });
        } else {
          setRemoveModalIsOpen(true);
          setCurrentItem(item);
        }
      },
    },
  ];

  return (
    <MainLayout
      pageTitle={PageTitles['purchasePlans']}
      extraButtonsList={buttonList}
      aside={<Filters />}
    >
      <TablePlans
        items={list}
        setSort={setSort}
        total={total}
        limit={limit}
        getActions={getActions}
      />
      <StickyConfirmModal
        isOpen={isRemoveModalIsOpen}
        onRequestClose={onRemoveModalClose}
        title='Удаление сущности'
        okText='Удалить'
        text='Вы действительно хотите удалить эту cущность?'
        onSubmit={onRemoveClick}
        okButtonProps={{ danger: true }}
      />
    </MainLayout>
  );
};
