import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IRentalsItemResponse } from 'store/slices/rentals/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { StateStatus, stateStatusesNamesHash } from 'types/stateStatus';
import { procedureStatusesNamesHash } from 'constants/procedureStatus';

type TProps = {
  data: IRentalsItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IRentalsItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
};

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: TProps) => {
  const columns = [
    {
      key: 1,
      title: '№ извещения',
      width: 138,
      dataIndex: 'noticeNumber',
      render: (noticeNumber, { id }: IRentalsItemResponse) => (
        <Link
          style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          to={`/rentals/${id}/edit`}
        >
          <Typography style={{ color: '#1890FF' }}>
            {noticeNumber || 'Не указано'}
          </Typography>
        </Link>
      ),
    },
    {
      key: 2,
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (text, { id }: IRentalsItemResponse) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/rentals/${id}/edit`}
          >
            <div>{(text && text.trim()) || 'Без названия'}</div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 3,
      width: 170,
      title: 'Статус процедуры аренды',
      dataIndex: 'state',
      render: state => (
        <>
          {stateStatusesNamesHash[state] ? (
            <Tag color={state === StateStatus.Current ? 'blue' : 'green'}>
              {stateStatusesNamesHash[state]}
            </Tag>
          ) : (
            <div>Не указано</div>
          )}
        </>
      ),
    },
    {
      key: 4,
      width: 170,
      title: 'Стадии процедуры аренды',
      dataIndex: 'statusProcedure',
      render: statusProcedure => (
        <>
          {procedureStatusesNamesHash[statusProcedure] ? (
            <div>{procedureStatusesNamesHash[statusProcedure]}</div>
          ) : (
            <div>Не указано</div>
          )}
        </>
      ),
    },
    {
      key: 5,
      width: 170,
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 6,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IRentalsItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
