import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { IVacanciesItemResponse } from 'store/slices/vacancies/interfaces';

interface IProps {
  data: IVacanciesItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IVacanciesItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'name',
      sorter: true,
      render: (text, item) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/vacancies/${item.id}/edit`}
          >
            <div>{(text && text.trim()) || 'Без названия'}</div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '20%',
      title: 'Организация',
      sorter: true,
      dataIndex: 'organization',
      render: organization => (
        <Typography>{organization || 'Не указано'}</Typography>
      ),
    },
    {
      key: 3,
      width: '18%',
      title: 'Регион',
      sorter: true,
      dataIndex: 'address',
      render: address => (
        <Typography>{address?.region?.name || 'Не указано'}</Typography>
      ),
    },
    {
      key: 4,
      width: '15%',
      title: 'Отклики',
      dataIndex: 'responds',
      render: responds => <Typography>{responds}</Typography>,
    },
    {
      key: 5,
      width: 162,
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 6,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_, item: IVacanciesItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
    />
  );
};
