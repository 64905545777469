import { createSlice } from '@reduxjs/toolkit';

import { IActivityState } from './interfaces';
import { getActivities } from './actions';

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState: {
    list: [],
    total: 0,
    limit: 20,
    isLoading: true,
  } as IActivityState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getActivities.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.list = payload.data;
      state.total = payload.total;
      state.limit = payload.limit;
      state.isLoading = false;
    });
  },
});
