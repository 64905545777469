import React from 'react';
import { AutoComplete, Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { debounce, isString } from 'utils/helpers';

import { PhoneInput } from '../base/PhoneInput';

type TProps = {
  name: string;
  params: Record<string, string>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  nameSearchField?: string;
  url?: string;
};

export const PhoneInputAutoComplete = ({
  name,
  required,
  params,
  label = 'Телефон',
  nameSearchField = 'pattern',
  url = urls.api.suggest.get,
}: TProps) => {
  const [options, setOptions] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const isFirstFocus = React.useRef<boolean>(false);

  const { control } = useFormContext();

  const getPanelValue = (pattern: string) =>
    apiClient
      .get(url, {
        params: {
          [nameSearchField]: pattern.replace(/[^\d]+/g, ''),
          ...params,
        },
      })
      .then(res => {
        const arrStrValue = (
          res.data.values as (string | Record<string, string>)[]
        ).map(item => (isString(item) ? item : Object.values(item)[0]));

        const newOptions = Array.from(new Set(arrStrValue)).map(item => ({
          value: item,
          label: item,
        }));

        setOptions(newOptions);
      })
      .catch(() => []);

  const handleSearch = React.useCallback(
    debounce((value: string) => getPanelValue(value), 400),
    []
  );

  const handleFocus = (value: string) =>
    !isFirstFocus.current &&
    getPanelValue(value).finally(() => (isFirstFocus.current = true));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Form.Item
          help={fieldState?.error?.message}
          validateStatus={fieldState?.error ? 'error' : 'success'}
          label={label}
          required={required}
        >
          <AutoComplete
            onSelect={(value: string) => field.onChange(value)}
            onSearch={handleSearch}
            options={options}
            onFocus={() => handleFocus(field.value)}
            filterOption={() => true}
            value={field.value}
          >
            {/* wrapper to fix onChange function on PhoneInput */}
            <div>
              <PhoneInput {...field} error={fieldState.error} />
            </div>
          </AutoComplete>
        </Form.Item>
      )}
    />
  );
};
