import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { useParams } from 'react-router';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { loadVacanciesRespondsList } from 'store/slices/vacanciesResponds/actions';

import { VacanciesRespondsList } from './components/VacanciesResponds/VacanciesRespondsList';

export const VacanciesResponds = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { id } = useParams();

  const { list, total, limit } = useAppSelector(
    state => state.vacanciesResponds
  );

  useDeepEffect(() => {
    dispatch(
      loadVacanciesRespondsList({
        vacancyIds: id,
        ...query,
        sort,
      })
    );
  }, [query, sort]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [query.offset]);

  return (
    <MainLayout pageTitle='Отклики' hasBackButton backButtonPath='/vacancies'>
      <Content style={{ padding: 24 }}>
        <VacanciesRespondsList
          data={list}
          total={total}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
