import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { loadRegionsList } from 'store/slices/regions/actions';
import { PageTitles } from 'constants/pageTitles';
import { IRegionsItemResponse } from 'store/slices/regions/interfaces';

import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.regions);
  const { b2cUrl } = useAppSelector(state => state.configSlice);

  const loadRegions = () => {
    dispatch(
      loadRegionsList({
        ...query,
        sort,
      })
    );
  };

  useDeepEffect(() => {
    loadRegions();
  }, [query, sort]);

  const getActions = (item: IRegionsItemResponse) => [
    {
      key: 'website',
      label: (
        <a
          target='_blank'
          rel='noreferrer nofollow'
          href={`${b2cUrl}/about/${item.id}`}
        >
          На сайте
        </a>
      ),
    },
    {
      key: 'edit',
      label: <Link to={`/regions/${item.id}/edit`}>Редактировать</Link>,
    },
    {
      key: 'mapView',
      label: (
        <Link
          to={`/regions/${item.id}/regionRelations`}
          state={{ regionFiasId: item.fiasId }}
        >
          Отображение мест на карте
        </Link>
      ),
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles['regions']}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
