import React from 'react';
import dayjs from 'dayjs';
import { Tag, Typography } from 'antd';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { IActivity } from 'store/slices/activities/interfaces';

interface IProps {
  data: IActivity[];
  total: number;
  limit?: number;
  getActions: (item: IActivity) => Array<
    | {
        element: JSX.Element;
        handler?: undefined;
      }
    | {
        element: React.ReactNode;
        handler: () => void;
      }
  >;
  setSort: (sort?: string) => void;
}

const modelsMap = {
  users: 'Пользователи',
  catalog: 'Каталог',
  attractions: 'Достопримечательности',
  resorts: 'Курорты',
  news: 'Новости',
  articles: 'Статьи',
  routes: 'Маршруты',
  events: 'События',
  eventsSeances: 'Мероприятия Сеансы',
  questionAnswer: 'Вопрос ответ',
  infrastructures: 'Инфраструктура',
  investProjects: 'инвестпроекты',
  purchasePlans: 'Планы покупки',
  hotels: 'Отели',
  restaurants: 'Рестораны',
  regions: 'Регионы',
  regulations: 'Нормативно-правовые акты',
  organizations: 'Организации',
  supportMeasures: 'Меры поддержки',
  vacancies: 'Вакансии',
  projects: 'Проекты',
  purchases: 'Покупки',
  sales: 'Продажи',
  support: 'Помощь',
  materials: 'Материалы',
  contacts: 'Контакты',
  menu: 'Меню',
  reviews: 'Обзоры',
};

const methodMap = {
  POST: 'Создание',
  DELETE: 'Удаление',
  PATCH: 'Изменение',
  PUT: 'Изменение',
  CHANGE_SORT_MAIN: 'Изменение выборки главной',
  CHANGE_SORT: 'Изменение выборки каталога',
};

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'name',
      sorter: true,
      render: name => <Typography>{name}</Typography>,
    },
    {
      key: 2,
      title: 'Автор',
      sorter: true,
      dataIndex: 'userId',
      render: (_, data) => <Typography>{data.username}</Typography>,
    },
    {
      key: 3,
      title: 'Активность',
      dataIndex: 'method',
      render: method => <Typography>{methodMap?.[method] ?? '-'}</Typography>,
    },
    {
      key: 4,
      title: 'Сущность',
      dataIndex: 'model',
      render: model => <Typography>{modelsMap?.[model] ?? model}</Typography>,
    },
    {
      key: 5,
      title: 'Дата',
      dataIndex: 'createdAt',
      sorter: true,
      render: createdAt => (
        <Typography.Text>
          {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
        </Typography.Text>
      ),
    },
    {
      key: 6,
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
    />
  );
};
