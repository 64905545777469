// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImagePreview-module__wrapper--i8b7y {\n  position: relative;\n}\n.ImagePreview-module__image--ZHmwi {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/ImagePreview/ImagePreview.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EAEA,WAAA;EACA,YAAA;EAEA,iBAAA;AAFF","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.image {\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ImagePreview-module__wrapper--i8b7y",
	"image": "ImagePreview-module__image--ZHmwi"
};
export default ___CSS_LOADER_EXPORT___;
