import { createSlice } from '@reduxjs/toolkit';

import { IApplicationsItem, IApplicationsState } from './interfaces';
import { loadApplicationsItem, loadApplicationsList } from './actions';

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
  } as IApplicationsState,
  reducers: {
    clearApplicationsItemData: state => {
      state.item = {} as IApplicationsItem;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadApplicationsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
    builder.addCase(loadApplicationsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});

export const { clearApplicationsItemData } = applicationsSlice.actions;
