import React from 'react';
import { Button } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

interface IProps {
  onMove: (index: number, step: number) => void;
  onRemove: (index: number) => void;
  index: number;
  count: number;
  isDisabled?: boolean;
}

export const ExtraControlMenu = ({
  onMove,
  onRemove,
  index,
  count,
  isDisabled,
}: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '16px',
      }}
      onClick={e => e.stopPropagation()}
    >
      <div>
        <Button
          size='small'
          onClick={() => onMove(index, index - 1)}
          icon={<ArrowUpOutlined />}
          disabled={index === 0}
        />
        <Button
          size='small'
          onClick={() => onMove(index, index + 1)}
          icon={<ArrowDownOutlined />}
          disabled={index + 1 >= count}
          style={{ marginLeft: 4 }}
        />
      </div>
      <Button
        disabled={isDisabled}
        size='small'
        onClick={() => onRemove(index)}
        style={{ marginRight: 16 }}
        type='text'
        icon={<DeleteOutlined />}
        danger
      />
    </div>
  );
};
