import React from 'react';
import { Layout, Typography } from 'antd';
import { FormProvider, useForm, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import { useQuery } from 'hooks/useQuery';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { InputField } from 'components/form/base/InputField';
import { AuthorAsyncSelect } from 'components/form/selects/AuthorAsyncSelect';
import { Select } from 'components/form/base/Select';
import { RangeDatePicker } from 'components/form/base/RangePicker';

import { activitiesOptions } from '../utils';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  name: string;
  period: string;
  activities: string[] | TFormSelectValue[];
  authors: string[] | TFormSelectValue[];
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        name: values.name && values.name.trim() + ' like',
        createdAtFrom: values.period
          ? dayjs(values.period[0]).utc().startOf('day').toISOString()
          : null,
        createdAtTo: values.period
          ? dayjs(values.period[1]).utc().endOf('day').toISOString()
          : null,
        method: Object.values(values.activities).length
          ? mapSelect(values.activities)
          : null,
        userId: values.authors.length ? mapSelect(values.authors) : null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      name: '',
      period: '',
      activities: [],
      authors: [],
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='name'
            label='Название'
            placeholder='Введите название'
            maxLength={255}
          />
          <RangeDatePicker
            name='period'
            label='Период'
            placeholder={['Дата начала', 'Дата окончания']}
          />
          <Select
            name='activities'
            label='Активность'
            options={activitiesOptions}
          />
          <AuthorAsyncSelect
            name='authors'
            label='Автор'
            mode='multiple'
            placeholder='Выберите автора'
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
