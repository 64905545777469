import React from 'react';
import { Button, Card, Col, Row } from 'antd';

import { InputField } from '../form/base/InputField';
import { FormActions } from '../Layout/FormActions/FormActions';

interface IProps {
  isLoading: boolean;
  handleCancel: () => void;
}

export const CardForm: React.FC<IProps> = ({ isLoading, handleCancel }) => {
  return (
    <Card title='Общая информация'>
      <Row>
        <Col span={24}>
          <InputField
            name='title'
            label='Заголовок'
            placeholder='Введите заголовок'
            minLength={1}
            maxLength={60}
            required
          />
        </Col>
      </Row>
      <FormActions>
        <Button onClick={handleCancel} disabled={isLoading}>
          Отмена
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          type='primary'
          htmlType='submit'
        >
          Сохранить
        </Button>
      </FormActions>
    </Card>
  );
};
