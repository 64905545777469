import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Col, Image, List, Row, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { getImageUrl } from 'utils/image';
import { IArticlesItem } from 'store/slices/articles/interfaces';

import { RegionsNestedArticlesSelector } from './RegionsNestedArticlesSelector';

export const RegionsNestedArticles: React.FC<{ name: string }> = ({ name }) => {
  const { setValue, control } = useFormContext();
  const articles = useWatch({ name, control }) as unknown as IArticlesItem[];

  const onRemoveArticle = (id: number) => {
    const filteredArticles =
      articles.length && articles.filter(article => article.id !== id);
    setValue(name, filteredArticles || []);
  };

  return (
    <Row>
      <Col xs={{ span: 24 }} md={{ span: 10 }}>
        <RegionsNestedArticlesSelector name={name} />
      </Col>
      {articles.length ? (
        <List
          pagination={{
            pageSize: 5,
          }}
          dataSource={articles}
          style={{ width: '100%' }}
          renderItem={article => (
            <List.Item key={article.id} style={{ border: 'none' }}>
              <Row
                key={article.id}
                style={{
                  width: '100%',
                  padding: 8,
                  border: '1px solid #d9d9d9',
                }}
                wrap={false}
              >
                <div style={{ flexShrink: 0 }}>
                  <Image
                    width={70}
                    height={70}
                    preview={false}
                    src={
                      article.image
                        ? getImageUrl(article.image, 70, 70) ||
                          '/public/images/no-image.svg'
                        : '/public/images/no-image.svg'
                    }
                    fallback='/public/images/no-image.svg'
                  />
                </div>
                <div
                  style={{
                    marginLeft: '16px',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flex: 1,
                  }}
                >
                  <Typography.Text>Статья</Typography.Text>
                  <Typography.Link
                    onClick={() => console.log('link to article')}
                    ellipsis
                    style={{ marginRight: 30 }}
                  >
                    {article.name}
                  </Typography.Link>
                </div>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => onRemoveArticle(article.id)}
                  style={{ marginRight: 16 }}
                />
              </Row>
            </List.Item>
          )}
        />
      ) : null}
    </Row>
  );
};
