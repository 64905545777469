import { createSlice } from '@reduxjs/toolkit';

import { IDocumentsState } from './interfaces';
import { loadDocumentsList } from './actions';

export const formDocumentsSlice = createSlice({
  name: 'formDocuments',
  initialState: {
    list: [],
    limit: 5,
    total: 0,
    offset: 0,
    shouldResetFilters: false,
  } as IDocumentsState,
  reducers: {
    resetFilters: (state, { payload }) => {
      state.shouldResetFilters = payload;
    },
    clearFormDocumentsData: state => {
      state.list = [] as IDocumentsState['list'];
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadDocumentsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
  },
});

export const { resetFilters, clearFormDocumentsData } =
  formDocumentsSlice.actions;
