import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { Select } from 'components/form/base/Select';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { b2bFilterStatuses } from 'constants/b2bFilterStatuses';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  name: string;
  status: string | TFormSelectValue;
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        name: values.name && values.name.trim(),
        status: mapSelect(values.status),
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      name: '',
      status: '',
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='name'
            label='Название'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select
            name='status'
            label='Статус'
            options={[
              b2bFilterStatuses[0],
              ...b2bFilterStatuses
                .slice(1)
                .sort((a, b) =>
                  (b.label as string).localeCompare(a.label as string)
                ),
            ]}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
