import { Button, Card, Col, Collapse, Row, Typography } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { GlobalOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainImage } from 'components/form/MainImage/MainImage';
import { InputField } from 'components/form/base/InputField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import { Gallery } from 'components/form/Gallery/Gallery';
import { FieldsList } from 'components/form/FieldsList';
import { Select } from 'components/form/base/Select';
import { AddMaterial } from 'components/form/AddMaterial';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { useDeepEffect } from 'utils/useDeepEffect';
import { Status } from 'components/form/Status/Status';
import { isEmpty, isString } from 'utils/helpers';
import { getCollapseTabsWithError } from 'utils/yup';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { ContentTypeEnum } from 'components/form/Content/contentTypes';

import styles from './InvestmentForm.module.less';
import {
  COLLAPSE_FIELDS_ERRORS,
  INITIAL_VALUES,
  PROJECT_STATUS_OPTIONS,
  VALIDATION_SCHEMA,
} from './constants';
import { FormValues, InvestmentFormProps } from './types';
import { mapValues, mapValuesAsDraft } from './formUtils';

export const InvestmentForm = ({
  onSubmit,
  onSaveAsDraft,
  defaultValues = {},
  isDraft = false,
  isLoading,
}: InvestmentFormProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | string[]>([
    'about',
    'seo',
    'publication',
  ]);
  const formInitialValues = { ...INITIAL_VALUES, ...defaultValues };
  const methods = useForm<FormValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmitHandler = (values: FormValues) => onSubmit(mapValues(values));

  useDeepEffect(() => {
    methods.reset({ ...INITIAL_VALUES, ...defaultValues });
  }, [defaultValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      const errorsKeys = getCollapseTabsWithError(
        methods.formState.errors,
        COLLAPSE_FIELDS_ERRORS
      );
      setActiveTab(prev => Array.from(new Set([...prev, ...errorsKeys])));
    }
  }, [methods.formState.errors]);

  const cancelButtonHandler = () => navigate('/investmentProjects');

  const handleSaveAsDraft = () => {
    const formValues = mapValuesAsDraft(methods.getValues());

    onSaveAsDraft(formValues);
  };

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), industry: null });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        className='ant-form ant-form-vertical'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Глав. фото' required />
            </Col>
            <Col flex={1} style={{ minWidth: 200 }}>
              <InputField
                name='name'
                label='Название инвестиционного проекта'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <CatalogRegionSelect
                name='region'
                required
                label='Регион'
                placeholder='Выберите регион'
              />
            </Col>
            <Col lg={{ span: 7 }} sm={{ span: 12 }} xs={{ span: 24 }}>
              <Select
                name='projectStatus'
                options={PROJECT_STATUS_OPTIONS}
                required
                label='Статус проекта'
                placeholder='Выберите статус'
              />
            </Col>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                placeholder='Описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          activeKey={activeTab}
          onChange={(value: string | string[]) => setActiveTab(value)}
        >
          <Collapse.Panel header='О проекте' key='about'>
            <Row gutter={[24, 0]}>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <CatalogAsyncSelect
                  name='industry'
                  required
                  label='Отрасль проекта'
                  placeholder='Выберите отрасль'
                  requestParams={{
                    entityType: 'investProjects',
                    fieldType: 'industries',
                    lang: langValue,
                  }}
                  showSearch={false}
                  onSearch={undefined}
                />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField
                  label='Бюджет проекта'
                  name='budget'
                  maxLength={80}
                  required
                />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField
                  label='Рабочие места'
                  name='workPlaces'
                  maxLength={80}
                />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField
                  label='Сумма инвестиций'
                  name='investments'
                  maxLength={80}
                />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField
                  label='Внутренняя норма доходности (IRR %)'
                  name='irr'
                />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField label='Срок окупаемости (PBP)' name='pbp' />
              </Col>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <InputField
                  label='Чистая приведённая стоимость (NPV)'
                  name='npv'
                />
              </Col>
              <Col span={24}>
                <InputField label='Инициатор проекта' name='initiator' />
              </Col>
              <Col span={24}>
                <ContentField
                  name='content'
                  required
                  label='Описание'
                  placeholder='Начните печатать текст'
                  enabledTypes={[
                    ContentTypeEnum.image,
                    ContentTypeEnum.gallery,
                    ContentTypeEnum.video,
                  ]}
                />
              </Col>
            </Row>
            <Col className={styles.gallerySection}>
              <Typography.Text>Галерея</Typography.Text>
              <Row className={styles.formItem}>
                <Gallery name='gallery' />
              </Row>
            </Col>
            <Col className={styles.materialSection}>
              <Typography.Text>Галерея видео</Typography.Text>
              <FieldsList
                buttonLabel='Добавить видео'
                name='videoUrls'
                className={styles.formItem}
                field={
                  <InputField
                    placeholder='Ссылка на видео'
                    addonBefore={<GlobalOutlined />}
                  />
                }
              />
            </Col>
            <Col className={styles.materialSection}>
              <Typography.Text>Дополнительные материалы</Typography.Text>
              <AddMaterial name='materials' className={styles.materialItem} />
            </Col>
          </Collapse.Panel>
          {/* <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel> */}
          <Collapse.Panel header='Публикация' key='publication'>
            <Status status={formInitialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
