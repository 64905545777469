// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WideImageUpload-module__dropzone--X8FHH {\n  position: relative;\n  height: inherit;\n  overflow: hidden;\n  line-height: 0;\n}\n.WideImageUpload-module__uploadArea--LBaw3 {\n  position: absolute;\n  bottom: 36px;\n  left: 50%;\n  z-index: 1;\n  transform: translateX(-50%);\n}\n@media (max-width: 499px) {\n  .WideImageUpload-module__uploadArea--LBaw3 {\n    bottom: 10px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Content/Image/WideImageUpload.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,eAAA;EACA,gBAAA;EAEA,cAAA;AADF;AAaA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EAEA,2BAAA;AAZF;AAaE;EAAA;IACE,YAAA;EAVF;AACF","sourcesContent":[".dropzone {\n  position: relative;\n\n  height: inherit;\n  overflow: hidden;\n\n  line-height: 0;\n}\n\n.image {\n  // position: absolute;\n  // top: 50%;\n  // z-index: 1;\n\n  // width: 100%;\n  // transform: translateY(-50%);\n}\n\n.uploadArea {\n  position: absolute;\n  bottom: 36px;\n  left: 50%;\n  z-index: 1;\n\n  transform: translateX(-50%);\n  @media (max-width: 499px) {\n    bottom: 10px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "WideImageUpload-module__dropzone--X8FHH",
	"uploadArea": "WideImageUpload-module__uploadArea--LBaw3"
};
export default ___CSS_LOADER_EXPORT___;
