import React from 'react';
import { Button, Col, Form, Row, Select, Tooltip } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DeleteOutlined,
  MailOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { BaseOptionType } from 'rc-select/lib/Select';

import { InputField } from 'components/form/base/InputField';
import { InputAutoCompleteField } from 'components/form/base/InputAutoCompleteField';
import { PhoneInputAutoComplete } from 'components/form/PhoneInputAutoComplete/PhoneInputAutoComplete';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { useBreakpoints } from 'hooks/useBreakpoints';

import { ExtensionContactsTypes, TExtensionContactsFieldsType } from './types';
import styles from './ExtensionContacts.module.less';

const options = [
  { label: 'Телефон', value: ExtensionContactsTypes.phone },
  {
    label: 'Email',
    value: ExtensionContactsTypes.email,
  },
];

const BREAKPOINT = 499;

export const ExtensionContacts: React.FC<{
  name: string;
  type: 'purchases' | 'sales' | 'rent';
}> = ({ name, type }) => {
  const { width } = useBreakpoints();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  });

  const onAddContact = (option: BaseOptionType) => {
    append({
      type: option.value,
      value: '',
      ...(option.value === ExtensionContactsTypes.phone && { additional: '' }),
    });
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <InputAutoCompleteField
            name='contactPerson'
            params={{
              entityName: type,
              field: `${type}.contactPerson`,
            }}
            label='Контактное лицо'
            maxLength={100}
            required
          />
        </Col>
      </Row>

      <div>
        {(fields as TExtensionContactsFieldsType[]).map((item, index) => {
          const fieldName = `${name}.${index}`;
          return (
            <div key={index} className={styles.fieldContainer}>
              {item.type === ExtensionContactsTypes.phone ? (
                <>
                  <div className={styles.phoneInputContainer}>
                    <PhoneInputAutoComplete
                      name={`${fieldName}.value`}
                      params={{
                        entityName: type,
                        field: `${type}.contacts.phone`,
                      }}
                      label='Телефон'
                      required={index < 2}
                    />
                  </div>
                  <div className={styles.additionalPhoneInput}>
                    <InputField
                      key='inputKEY'
                      name={`${fieldName}.additional`}
                      type='number'
                      label='Добавочный'
                      maxLength={4}
                      withErrorMessage={width <= BREAKPOINT}
                      suffix={
                        width >= BREAKPOINT ? (
                          getValueByStringKeyWithArr(
                            errors,
                            `${fieldName}.additional`
                          )?.message ? (
                            <Tooltip
                              placement='top'
                              title={
                                getValueByStringKeyWithArr(
                                  errors,
                                  `${fieldName}.additional`
                                )?.message
                              }
                            >
                              <WarningOutlined style={{ color: '#ff4d4f' }} />
                            </Tooltip>
                          ) : (
                            <span />
                          )
                        ) : (
                          <span />
                        )
                      }
                    />
                  </div>
                </>
              ) : (
                <div className={styles.emailInputContainer}>
                  <InputAutoCompleteField
                    name={`${fieldName}.value`}
                    params={{
                      entityName: type,
                      field: `${type}.contacts.email`,
                    }}
                    label='Email'
                    prefix={<MailOutlined />}
                    placeholder='Введите еmail'
                    required={index < 2}
                  />
                </div>
              )}
              {index >= 2 && (
                <>
                  <Button
                    className={styles.desktopDeleteBtn}
                    icon={<DeleteOutlined />}
                    onClick={() => remove(index)}
                  />
                  <Button
                    className={styles.mobileDeleteBtn}
                    danger
                    onClick={() => remove(index)}
                  >
                    Удалить контакт
                  </Button>
                </>
              )}
            </div>
          );
        })}
      </div>

      <Row>
        <Col>
          <Form.Item>
            <Select
              value='Добавить контакт'
              onChange={(_, option) => onAddContact(option)}
            >
              {options.map(item => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
