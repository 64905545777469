import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';

import { useAppSelector } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { OrganizationsItem } from 'types/organizations';
import { ListResponse } from 'types/pagination';
import { getNameOrganization } from 'pages/Business/utils';
import { RolesNames } from 'constants/organizations';
import { apiClient } from 'utils/http';
import { pick } from 'utils/helpers';

import styles from './UserOrganizations.module.less';

export const UserOrganizations = () => {
  const [organizations, setOrganizations] = useState<
    ListResponse<OrganizationsItem>
  >({ data: [], total: 0 });

  const {
    item: { id },
  } = useAppSelector(state => state.business);

  const getUserOrganizations = async () => {
    const { data } = await apiClient.get<ListResponse<OrganizationsItem>>(
      getUrlWithParams(urls.api.organizations.getByUser, { id })
    );

    setOrganizations(data);
  };

  useEffect(() => {
    id && getUserOrganizations();
  }, [id]);

  const columns = [
    {
      key: 1,
      title: 'Название',
      render: (_: unknown, organization: OrganizationsItem) => (
        <Typography.Link href={`/organizations/${organization.id}`}>
          {getNameOrganization(
            pick(
              organization,
              'lastName',
              'firstName',
              'secondName',
              'shortName',
              'fullName',
              'type'
            ),
            'Не заполнено'
          )}
        </Typography.Link>
      ),
    },
    {
      key: 2,
      title: 'ИНН / КПП',
      render: (_: unknown, { kpp, inn }: OrganizationsItem) => (
        <>
          {inn ? <Typography.Text>{inn}</Typography.Text> : null}
          <br />
          {kpp ? <Typography.Text>{kpp}</Typography.Text> : null}
        </>
      ),
    },
    {
      key: 3,
      title: 'ОГРН / ОГРНИП',
      dataIndex: 'ogrn',
      render: ogrn =>
        ogrn ? (
          <Typography.Text>{ogrn}</Typography.Text>
        ) : (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        ),
    },
    {
      key: 4,
      title: 'Роль',
      dataIndex: 'role',
      render: role => <Typography.Text>{RolesNames[role]}</Typography.Text>,
    },
  ];

  return (
    <div>
      <Typography.Text className={styles.tableTitle} strong>
        Организации пользователя
      </Typography.Text>
      <CommonTable
        className={styles.table}
        bordered={false}
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={organizations.data}
        total={organizations.total}
        limit={5}
      />
    </div>
  );
};
