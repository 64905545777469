import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadInvestmentSolutionsItem,
  saveInvestmentSolutionsItem,
  saveInvestmentSolutionsItemDraft,
} from 'store/slices/investmentSolutions/actions';
import { InvestmentSolutionsItem } from 'store/slices/investmentSolutions/interfaces';
import { StatusesEnum } from 'constants/status';
import { DeepPartial } from 'types/utils';

import { Form } from './components/Form';
import { mapValuesToForm } from './components/Form/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.investmentSolutions);
  const isCorrectItem = item?.id === Number(id);

  React.useEffect(() => {
    dispatch(loadInvestmentSolutionsItem(id));
  }, [id]);

  const onSubmit = (values: InvestmentSolutionsItem) => {
    setIsLoading(true);
    const newItem = { ...values, id: item?.id };
    dispatch(saveInvestmentSolutionsItem(newItem))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно обновлена',
        });
        navigate('/investmentSolutions');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<InvestmentSolutionsItem>) => {
    setIsLoading(true);
    dispatch(saveInvestmentSolutionsItemDraft({ ...values, id: item?.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно создана',
        });
        navigate('/investmentSolutions');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {isCorrectItem && (
        <Form
          onSubmit={onSubmit}
          isDraft={item.status === StatusesEnum.DRAFT}
          isLoading={isLoading}
          defaultValues={mapValuesToForm(item)}
          onSaveAsDraft={onSaveAsDraft}
        />
      )}
    </MainLayout>
  );
};
