import { createSlice } from '@reduxjs/toolkit';

import { loadReportItem, loadReportsList, updateReportItem } from './actions';
import { IReportsState } from './interfaces';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    list: [],
    item: null,
    limit: 20,
    total: 0,
    offset: 0,
  } as IReportsState,
  reducers: {
    resetReportItem: state => {
      state.item = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadReportsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );

    builder.addCase(loadReportItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
    builder.addCase(updateReportItem.fulfilled, (state, { meta }) => {
      const { arg } = meta;
      state.item.status = arg.status;
    });
  },
});

export const { resetReportItem } = reportsSlice.actions;
