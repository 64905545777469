import React from 'react';

import { makeAddressString } from 'utils/entities';
import { getImageUrl } from 'utils/image';
import { getUrlWithParams } from 'store/api';
import { IRegionRelationsItem } from 'store/slices/regions/interfaces';

import {
  CATEGORY_TYPES,
  ENTITY_POINTS_URLS,
  ENTITY_REGION_RELATIONS_NAMES,
} from '../../constants';
import { EntityRegionRelationsTypes } from '../../types';
import styles from './BalloonLayout.module.less';

type TProps = {
  point: IRegionRelationsItem;
};

export const BalloonLayout: React.FC<TProps> = ({ point }) => {
  if (!point) {
    return null;
  }
  const pointType = point.data?.category || point.type;

  return (
    <div className={styles.wrapper}>
      {!CATEGORY_TYPES.includes(pointType as EntityRegionRelationsTypes) && (
        <div className={styles.imageWrapper}>
          <img
            src={
              point.data?.image
                ? getImageUrl(point.data.image) || '/public/images/no-image.svg'
                : '/public/images/no-image.svg'
            }
            alt=''
            className={styles.image}
          />
        </div>
      )}
      <div className={styles.balloonContent}>
        <div className={styles.iconContainer}>
          <img
            src={`/public/images/place/${pointType}.svg`}
            className={styles.icon}
            alt=''
          />
          <div className={styles.balloonType}>
            {ENTITY_REGION_RELATIONS_NAMES[point.data?.category || point.type]}
          </div>
        </div>
        <a
          href={getUrlWithParams(ENTITY_POINTS_URLS[pointType], {
            id: point.entityId,
          })}
          target='_blank'
          rel='noreferrer'
          className={styles.balloonPointName}
        >
          {point.name}
        </a>
        <div className={styles.address}>
          {`Адрес: ${makeAddressString(point.address)}`}
        </div>
      </div>
    </div>
  );
};
