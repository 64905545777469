import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';

import {
  GetRegulationsParams,
  RegulationsItem,
  RegulationsItemRequest,
} from './interfaces';

export const loadRegulationsList = createAsyncThunk<
  ListResponse<RegulationsItem>,
  GetRegulationsParams,
  { state: RootState }
>('regulations/loadList', async (params, { getState }) => {
  const { limit } = getState().regulations;
  const { data } = await apiClient.get(urls.api.regulations.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const saveRegulationsItem = createAsyncThunk<
  RegulationsItem,
  RegulationsItemRequest
>('regulations/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.regulations.patch, { id }),
      params
    );

    return data;
  } else {
    const { data } = await apiClient.post(urls.api.regulations.post, params);

    return data;
  }
});

export const saveRegulationsItemDraft = createAsyncThunk<
  DeepPartial<RegulationsItem>,
  DeepPartial<RegulationsItemRequest>
>('regulations/saveItemDraft', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.regulations.draft.patch, { id }),
      params
    );
    return data;
  } else {
    const { data } = await apiClient.post(
      urls.api.regulations.draft.post,
      params
    );

    return data;
  }
});

export const loadRegulationsItem = createAsyncThunk<RegulationsItem, string>(
  'regulations/loadItem',
  async id => {
    const { data } = await apiClient.get(
      getUrlWithParams(urls.api.regulations.getOne, { id })
    );

    return data;
  }
);

export const setUnPublishRegulations = createAsyncThunk<void, number>(
  'regulations/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.regulations.unPublish.patch, { id })
    )
);

export const deleteRegulation = createAsyncThunk<void, { id: number }>(
  'regulations/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.regulations.delete, { id })
    )
);
