import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch } from 'store';
import {
  saveInvestmentProjectsItem,
  saveInvestmentProjectsItemDraft,
} from 'store/slices/investmentProjects/actions';
import { InvestmentProjectsItem } from 'store/slices/investmentProjects/interfaces';
import { DeepPartial } from 'types/utils';

import { InvestmentForm } from './components/InvestmentForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (values: InvestmentProjectsItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentProjectsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Инвестиционный проект успешно добавлен',
        });
        navigate('/investmentProjects');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<InvestmentProjectsItem>) => {
    setIsLoading(true);
    dispatch(saveInvestmentProjectsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Инвестиционный проект успешно создан',
        });
        navigate('/investmentProjects');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Создание'>
      <InvestmentForm
        onSubmit={onSubmit}
        onSaveAsDraft={onSaveAsDraft}
        isDraft
        isLoading={isLoading}
      />
    </MainLayout>
  );
};
