import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import {
  IHotelsDraftItem,
  IHotelsItem,
  IHotelsItemResponse,
} from './interfaces';

type TListResponse = { data: IHotelsItemResponse[]; total: number };

export const loadHotelsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
    isSortPriority?: boolean;
  },
  { state: RootState }
>('hotels/loadList', async (params, { getState }) => {
  const { limit } = getState().hotels;
  const { data } = await apiClient.get<TListResponse>(urls.api.hotels.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadHotelsItem = createAsyncThunk<IHotelsItemResponse, string>(
  'hotels/loadItem',
  async id => {
    const { data } = await apiClient.get<IHotelsItemResponse>(
      getUrlWithParams(urls.api.hotels.getOne, {
        id,
      })
    );
    return data;
  }
);

export const bulkUpdateHotelsItem = createAsyncThunk<
  IHotelsItem[],
  Partial<IHotelsItem>[]
>('hotels/bulkUpdateItems', async params => {
  const { data } = await apiClient.patch<IHotelsItem[]>(
    urls.api.hotels.bulkPatch,
    params
  );
  return data;
});

export const saveHotelsItem = createAsyncThunk<
  IHotelsItem,
  Partial<IHotelsItem>
>('hotels/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = params.gallery && (await uploadImageArray(params.gallery));
  const content = params.content && (await uploadContent(params.content));

  if (id) {
    const { data } = await apiClient.patch<IHotelsItem>(
      getUrlWithParams(urls.api.hotels.patch, { id }),
      {
        ...params,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IHotelsItem>(urls.api.hotels.post, {
      ...params,
      image,
      heroImage,
      gallery,
      content,
    });
    return data;
  }
});

export const saveHotelsItemAsDraft = createAsyncThunk<
  IHotelsDraftItem,
  IHotelsDraftItem
>('hotels/saveItem', async params => {
  const { id, ...requestParams } = params;
  const image = await uploadImage(requestParams.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = await uploadImageArray(requestParams.gallery);
  const content = await uploadContent(requestParams.content);

  if (id) {
    const { data } = await apiClient.patch<IHotelsDraftItem>(
      getUrlWithParams(urls.api.hotels.draft.patch, { id }),
      {
        ...requestParams,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IHotelsDraftItem>(
      urls.api.hotels.draft.post,
      {
        ...requestParams,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  }
});

export const setPublishHotel = createAsyncThunk<void, string>(
  'hotels/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.hotels.publish.patch, { id })
    );
  }
);

export const setUnPublishHotel = createAsyncThunk<void, string>(
  'hotels/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.hotels.unPublish.patch, { id })
    );
  }
);

export const removeHotelsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: number;
  }
>('hotels/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.hotels.delete, { id: params.id }));
  return data;
});
