export enum ApiErrors {
  USER_NOT_FOUND = 'Пользователь с указанным адресом электронной почты не найден',
  DUPLICATED_EMAIL = 'Этот адрес почты уже зарегистрирован',
  PASSWORD_INCORRECT = 'Неверный email или пароль',
  PASSWORD_EMAIL_INCORRECT = 'Неверный email или пароль',
  CATALOG_ITEM_DUPLICATED = 'Сущность с таким именем уже существует',
  FORBIDDEN_WIDGET_RELATED_ITEM_DELETE = 'Нельзя удалить связанный с другими сущностями объект',
  FORBIDDEN_PUBLISHED_ITEM_DELETE = 'Запрещено удалять опубликованную сущность',
  ORGANIZATION_MUST_HAVE_OWNER = 'Организация должна иметь владельца',
  DUPLICATED_ORGANIZATION_OWNERS = 'Организация должна иметь только одного владельца',
  NOT_PUBLISHED_RELATED_ITEM_ERROR = 'Присутствует не опубликованная сущность, исправьте текущие данные',
  RELATED_INVEST_PASSPORT_NOT_FOUND = 'Не найден инвестиционный паспорт',
  RELATED_INVEST_PROJECT_NOT_FOUND = 'Не найден инвестиционный проект',
  TOKEN_ERROR = 'Запрос на изменение пароля уже был использован',
  ITEM_NOT_PUBLISHED = 'Одна из сущностей в списке была снята с публикации. Скорректируйте выборку сущностей',
  CATALOG_ITEM_HAS_RELATIONS = 'Невозможно удалить данный элемент. Элемент используется в других сущностях',
}

export const BACKEND_VALIDATOR_ERRORS = {
  'Forbidden resource': 'Доступ запрещен',
  'Not Found': 'Сущность не найдена или была удалена',
  'Email has been already confirmed': 'Адрес электронной почты уже подтвержден',
};
