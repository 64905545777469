import { createSlice } from '@reduxjs/toolkit';

import { IEventsState } from './interfaces';
import {
  loadEventsItem,
  loadEventsList,
  loadEventsListMainTourism,
} from './actions';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    mainTourismList: [],
    isMainTourismListLoading: false,
    isMainTourismListTotal: 0,

    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
    isLoading: false,
  } as unknown as IEventsState,
  reducers: {},
  extraReducers: builder => {
    // slice for view some events for b2c portal main page
    builder.addCase(
      loadEventsListMainTourism.fulfilled,
      (state, { payload: { data, total } }) => {
        state.mainTourismList = data;
        state.isMainTourismListTotal = total;
        state.isMainTourismListLoading = false;
      }
    );
    builder.addCase(loadEventsListMainTourism.pending, state => {
      state.isMainTourismListLoading = true;
    });
    builder.addCase(loadEventsListMainTourism.rejected, state => {
      state.isLoading = false;
    });

    // slice for event list
    builder.addCase(
      loadEventsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
        state.isLoading = false;
      }
    );
    builder.addCase(loadEventsList.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(loadEventsList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loadEventsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});
