// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-module__menu--Jqfd5 .ant-menu-item {\n  min-height: 40px !important;\n  height: 100% !important;\n  line-height: 22px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Layout/Sidebar/Sidebar.module.less"],"names":[],"mappings":"AAAA;EAGG,2BAAA;EACA,uBAAA;EACA,4BAAA;AADH","sourcesContent":[".menu {\n\t:global {\n\t\t.ant-menu-item {\n\t\t\tmin-height: 40px !important;\n\t\t\theight: 100% !important;\n\t\t\tline-height: 22px !important;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "Sidebar-module__menu--Jqfd5"
};
export default ___CSS_LOADER_EXPORT___;
