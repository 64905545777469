import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, notification, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IRentalsItem,
  TRentalsDraftItem,
} from 'store/slices/rentals/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  deleteRentalsItem,
  loadRentalsItem,
  saveRentalsItem,
  saveRentalsItemAsDraft,
} from 'store/slices/rentals/actions';
import { StatusesEnum } from 'constants/status';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';

import { RentalsForm } from './components/RentalsForm/RentalsForm';
import { mapValuesToForm } from './components/RentalsForm/formUtils';

export const Edit = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.rentals);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadRentalsItem(id));
  }, []);

  const onFinish = (values: IRentalsItem) => {
    setIsLoading(true);
    dispatch(saveRentalsItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/rentals');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TRentalsDraftItem) => {
    setIsLoading(true);
    dispatch(saveRentalsItemAsDraft({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Карточка объекта аренды успешно изменена',
        });
        navigate('/rentals');
      })
      .catch(() => setIsLoading(false));
  };

  const handleDelete = async id => {
    setIsLoading(true);
    await dispatch(deleteRentalsItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Карточка объекта аренды успешно перемещена в Корзину',
        });
        navigate('/rentals');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/rentals');
  };

  return (
    <MainLayout
      pageTitle='Редактирование'
      extraButtonsList={[
        <Button
          key='delete'
          danger
          onClick={() => String(item.id) == id && setIsModalOpen(true)}
        >
          Удалить
        </Button>,
      ]}
    >
      {item.id && String(item.id) == id ? (
        <>
          <RentalsForm
            onFinish={onFinish}
            onSaveDraft={onSaveDraft}
            cancelButtonHandler={cancelButtonHandler}
            isDraft={item.status === StatusesEnum.DRAFT}
            isLoading={isLoading}
            initialValues={mapValuesToForm(item)}
          />
          {isModalOpen && (
            <StickyConfirmModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              title='Удаление карточки объекта аренды'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину объект
                  аренды{' '}
                  <Typography.Text strong>{item.name || ''}</Typography.Text>?
                </div>
              }
              onSubmit={() => handleDelete(item.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
