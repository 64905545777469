import React from 'react';
import { FormProvider, useForm, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Layout, Typography } from 'antd';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { ProjectStatuses, Statuses } from 'constants/status';
import { Select } from 'components/form/base/Select';
import { AuthorAsyncSelect } from 'components/form/selects/AuthorAsyncSelect';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import { debounce, removeEmptyValues } from 'utils/helpers';
import { mapRegionForFilters, mapSelect } from 'utils/mappings';
import { LangEnumKeys } from 'constants/lang';
import { LocaleSelect } from 'components/form/selects/LocaleSelect';

import { PROJECT_STATUS_OPTIONS_FILTER } from '../InvestmentForm/constants';
import { FILTER_STATUS_OPTIONS } from './constants';

export interface FilterValues {
  nameQuery: string;
  status: Statuses | '';
  projectStatus: ProjectStatuses | '';
  authors: string[] | null;
  regionFiasIds: string[] | null;
  lang: LangEnumKeys | { value: LangEnumKeys; label: string } | '';
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const methods = useForm<FilterValues>({
    defaultValues: {
      nameQuery: '',
      authors: [],
      regionFiasIds: [],
      status: '',
      projectStatus: '',
      lang: '',
      ...query,
    },
  });

  const updateFilters = (filterValues: Record<string, string>) => {
    const queryString = new URLSearchParams(filterValues).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce(
      ({
        authors,
        status,
        projectStatus,
        regionFiasIds,
        ...values
      }: FilterValues) => {
        const preparedValues = {
          ...values,
          status: mapSelect(status),
          projectStatus: mapSelect(projectStatus),
          authors: authors?.length ? mapSelect(authors) : null,
          regionFiasIds: regionFiasIds?.length
            ? mapRegionForFilters(regionFiasIds)
            : null,
          lang: mapSelect(values.lang),
        };
        updateFilters(removeEmptyValues(preparedValues));
      },
      500
    ),
    []
  );

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(
        debounceSubmit
      ) as unknown as WatchObserver<FilterValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: 16, paddingBottom: 1 }}
    >
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form-vertical'>
          <InputField
            name='nameQuery'
            placeholder='Введите название'
            label='Название'
            maxLength={255}
          />
          <Select
            name='projectStatus'
            options={PROJECT_STATUS_OPTIONS_FILTER}
            label='Статус проекта'
          />
          <Select
            name='status'
            options={FILTER_STATUS_OPTIONS}
            label='Статус'
          />
          <AuthorAsyncSelect
            name='authors'
            label='Автор'
            placeholder='Выберите автора'
            mode='multiple'
          />
          <CatalogRegionSelect
            name='regionFiasIds'
            label='Регион'
            placeholder='Выберите регион'
            mode='multiple'
          />
          <LocaleSelect
            name='lang'
            label='Язык'
            placeholder='Язык'
            withEmptyValue
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
