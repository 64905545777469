import React from 'react';

import { Control } from './Control';
import { List } from './List';
import { View } from './View';

export const organizationsRoutes = {
  path: '/organizations',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: ':id',
      element: <View />,
    },
    {
      path: ':id/control',
      element: <Control />,
    },
  ],
};
