import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiClient } from '../../../utils/http';
import { IPriorityResponse, TPriorityRequest, TVariant } from './interfaces';

export const getPriority = createAsyncThunk<IPriorityResponse, TVariant>(
  'blockPriority/getPriority',
  async type => {
    const { data } = await apiClient.get<IPriorityResponse>(
      `/block-priority/${type}`
    );
    return data;
  }
);
export const setPriority = createAsyncThunk<
  IPriorityResponse,
  TPriorityRequest
>('blockPriority/setPriority', async ({ type, updateBlocks }) => {
  const { data } = await apiClient.patch<IPriorityResponse>(
    `/block-priority/${type}/bulk_update`,
    {
      updateBlocks,
    }
  );
  return data;
});
