import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { loadAdminsList } from 'store/slices/admin/actions';
import { PageTitles } from 'constants/pageTitles';
import { IAdminsItemResponse } from 'store/slices/admin/interfaces';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.admins);
  const loadAdmins = () => {
    dispatch(
      loadAdminsList({
        ...query,
        sort,
      })
    );
  };

  useDeepEffect(() => {
    loadAdmins();
  }, [query, sort]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [query.offset]);

  const getActions = (item: IAdminsItemResponse) => [
    {
      key: 'preview',
      label: <Link to={`/admin/${item.id}`}>Превью</Link>,
      onClick: () => console.log('link to view'),
    },
    {
      key: 'edit',
      label: <Link to={`/admin/${item.id}/edit`}>Редактировать</Link>,
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.admin} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
