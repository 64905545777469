import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  notification,
  Typography,
  Card,
  Row,
  Col,
  Skeleton,
} from 'antd';
import {
  BankOutlined,
  MailOutlined,
  PaperClipOutlined,
  UserOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { downloadDocument } from 'utils/downloadFile';
import { applicationsStatusesFormNamesHash } from 'constants/applicationsFormRespondsStatus';
import { useQuery } from 'hooks/useQuery';
import {
  loadRespondsItem,
  deleteRespondsItem,
} from 'store/slices/responds/actions';
import { MaterialFileType } from 'components/form/AddMaterial/components/MaterialItem/types';
import {
  RespondsInvestInfrastructureTypesOfPagesEnum,
  RespondsTypesEnum,
  TRespondsTypes,
} from 'store/slices/responds/interfaces';
import {
  mappedNameFromObject,
  selectPageFromObject,
  selectPhoneFromObject,
} from 'components/utils';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { OrganizationsItem } from 'types/organizations';
import {
  MobileRespondsResortEnum,
  MobileRespondsResortNameHash,
  MobileRespondsThemeEnum,
  MobileRespondsThemeNameHash,
} from 'constants/mobileResponds';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import { getOrganizationInfo } from './utils';
import styles from './ApplicationsFromFormsView.module.less';

export const View: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [organizationItem, setOrganizationItem] =
    React.useState<OrganizationsItem>(null);
  const { item, loading } = useAppSelector(state => state.responds);
  const { b2bUrl } = useAppSelector(state => state.configSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const type = query.type as TRespondsTypes;
  const [isLoading, setIsLoading] = React.useState(false);

  const isInvestInfrastructureResponds =
    type === RespondsTypesEnum.investInfrastructureResponds;

  const isOrganizationData =
    type === RespondsTypesEnum.taxBenefitsResponds ||
    type === RespondsTypesEnum.supportResponds;

  const isMobileRespondsFields = type === RespondsTypesEnum.mobileResponds;

  const fetchOrganizationItem = async ({ id }) => {
    const { data } = await apiClient.get<OrganizationsItem>(
      getUrlWithParams(urls.api.organizations.getOne, {
        id: id,
      })
    );
    setOrganizationItem(data);
  };

  React.useEffect(() => {
    dispatch(loadRespondsItem({ id, type })).then((res: any) => {
      if (
        type === RespondsTypesEnum.supportResponds ||
        type === RespondsTypesEnum.taxBenefitsResponds
      ) {
        fetchOrganizationItem({ id: res?.payload?.organizationId });
      }
      return;
    });
  }, [type]);

  const handleDelete = async id => {
    setIsLoading(true);
    await dispatch(deleteRespondsItem({ id, type }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        setIsLoading(false);
        navigate(`/applicationsForm?type=${type}`);
      });
  };

  const downloadDoc = ({ path, realName }: MaterialFileType) => {
    const url = `${UPLOAD_SERVICE_BASE_URL}/${path}`;
    const docName = `${realName}`;

    downloadDocument(url, docName);
  };

  const InvestInfrastructureResponds: React.FC = () => {
    return (
      <Row gutter={[24, 4]} className={styles.viewRow}>
        <Col span={24}>
          <div className={styles.document}>
            {'fromPage' in item &&
            item.fromPage ===
              RespondsInvestInfrastructureTypesOfPagesEnum.investPage ? (
              <>
                <Typography.Link
                  href={`${b2bUrl}/invest-infrastructure`}
                  className={styles.infrastructureLink}
                  target='_blank'
                  rel='noreferrer'
                >
                  Инвестиционная инфраструктура
                </Typography.Link>
                <Typography.Text style={{ color: '#8C8C8C' }}>
                  /
                </Typography.Text>
              </>
            ) : null}

            <Typography.Link
              href={
                'fromPage' in item &&
                item.fromPage ===
                  RespondsInvestInfrastructureTypesOfPagesEnum.investPage
                  ? `${b2bUrl}/invest-infrastructure/${item.investInfrastructureId}`
                  : b2bUrl
              }
              className={styles.infrastructureLink}
              target='_blank'
              rel='noreferrer'
            >
              {'fromPage' in item ? selectPageFromObject(item) : '-'}
            </Typography.Link>
          </div>
        </Col>
      </Row>
    );
  };

  const OrganizationData: React.FC = () => {
    return (
      <Col span={24}>
        <div className={styles.document}>
          <BankOutlined />
          {getOrganizationInfo(item, organizationItem)}
        </div>
      </Col>
    );
  };

  const AttachedFiles: React.FC = () => {
    const isSupportResponds =
      type === RespondsTypesEnum.supportResponds && 'materials' in item;
    const isMobileRespondsFiles =
      type === RespondsTypesEnum.mobileResponds && 'files' in item;
    return (
      <>
        {isSupportResponds
          ? item?.materials?.map((material, index) => {
              return (
                <Col span={24} key={index}>
                  <div className={styles.document}>
                    <PaperClipOutlined />
                    <Typography.Link
                      onClick={() => {
                        if (
                          UPLOAD_SERVICE_BASE_URL &&
                          material.file.path &&
                          material.file.realName
                        ) {
                          downloadDoc(material.file);
                        }
                      }}
                      target='_blank'
                      rel='noreferrer nofollow'
                    >
                      {material.file.realName}
                    </Typography.Link>
                  </div>
                </Col>
              );
            })
          : isMobileRespondsFiles
          ? item.files?.map((file, index) => {
              return (
                <Col span={24} key={index}>
                  <div className={styles.document}>
                    <PaperClipOutlined />
                    <Typography.Link
                      onClick={() => {
                        if (
                          UPLOAD_SERVICE_BASE_URL &&
                          file.path &&
                          file.realName
                        ) {
                          downloadDoc(file);
                        }
                      }}
                      target='_blank'
                      rel='noreferrer nofollow'
                    >
                      {file.realName}
                    </Typography.Link>
                  </div>
                </Col>
              );
            })
          : null}
      </>
    );
  };

  const MobileRespondsFields: React.FC = () => {
    const isTheme =
      'theme' in item &&
      Object.values(MobileRespondsThemeEnum).includes(
        item.theme as MobileRespondsThemeEnum
      );
    const isResort =
      'resort' in item &&
      Object.values(MobileRespondsResortEnum).includes(
        item.resort as MobileRespondsResortEnum
      );
    return (
      <>
        <Row gutter={[24, 8]} className={styles.viewRow}>
          <Col span={24} className={styles.viewCol}>
            Курорт
          </Col>
          <Col span={24}>
            <p className={styles.userMessage}>
              {isResort ? MobileRespondsResortNameHash[item.resort] : '-'}
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 8]} className={styles.viewRow}>
          <Col span={24} className={styles.viewCol}>
            Тема
          </Col>
          <Col span={24}>
            <p className={styles.userMessage}>
              {isTheme ? MobileRespondsThemeNameHash[item.theme] : '-'}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const UserPhoneNumber = () => {
    if (type === RespondsTypesEnum.mobileResponds) {
      return 'userData' in item && item.userData !== null ? (
        <Col span={24}>
          <div className={styles.document}>
            <PhoneOutlined rotate={90} />
            <Typography.Text>{selectPhoneFromObject(item)}</Typography.Text>
          </div>
        </Col>
      ) : null;
    }
    if (type !== RespondsTypesEnum.supportResponds) {
      return (
        <Col span={24}>
          <div className={styles.document}>
            <PhoneOutlined rotate={90} />
            <Typography.Text>{selectPhoneFromObject(item)}</Typography.Text>
          </div>
        </Col>
      );
    }
    return null;
  };

  return (
    <MainLayout
      hasBackButton={true}
      pageTitle={applicationsStatusesFormNamesHash[type] || ''}
      backButtonPath={`/applicationsForm?type=${type}`}
      extraButtonsList={[
        <Button
          key='delete'
          danger
          onClick={() => item?.id && setIsModalOpen(true)}
        >
          Удалить
        </Button>,
      ]}
    >
      {item?.id ? (
        <>
          <Card bordered={false}>
            {!loading ? (
              <>
                {isInvestInfrastructureResponds ? (
                  <InvestInfrastructureResponds />
                ) : null}
                <Row gutter={[24, 4]} className={styles.viewRow}>
                  <Col className={styles.viewCol} span={24}>
                    Дата и время
                  </Col>
                  <Col span={24} className={styles.viewCol}>
                    <Typography>
                      {dayjs(item?.createdAt).format('DD.MM.YYYY HH:mm') || ''}
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[24, 20]} className={styles.viewRow}>
                  {isOrganizationData ? <OrganizationData /> : null}
                  <Col span={24}>
                    <div className={styles.document}>
                      <UserOutlined width={24} />
                      <Typography.Text>
                        {mappedNameFromObject(item)}
                      </Typography.Text>
                    </div>
                  </Col>
                  <UserPhoneNumber />
                  {'email' in item ? (
                    <Col span={24}>
                      <div className={styles.document}>
                        <MailOutlined />
                        {item?.email || ''}
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {isMobileRespondsFields ? <MobileRespondsFields /> : null}
                <Row gutter={[24, 8]} className={styles.viewRow}>
                  <Col span={24} className={styles.viewCol}>
                    Сообщение
                  </Col>
                  <Col span={24}>
                    <p className={styles.userMessage}>{item?.message || ''}</p>
                  </Col>
                </Row>
                <Row gutter={[24, 16]} className={styles.viewRow}>
                  <AttachedFiles />
                </Row>
              </>
            ) : (
              <Skeleton
                paragraph={{ rows: 4 }}
                active
                title
                loading={loading}
              />
            )}
          </Card>
          {isModalOpen && (
            <StickyConfirmModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              title='Удаление заявки'
              okText='Удалить'
              text={
                <div>
                  Вы хотите удалить заявку{' '}
                  <Typography.Text strong>
                    {type === RespondsTypesEnum.mobileResponds &&
                    'email' in item
                      ? item?.email
                      : mappedNameFromObject(item)}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={() => handleDelete(item.id)}
              okButtonProps={{ danger: true, loading: isLoading }}
            />
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
