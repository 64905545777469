import React from 'react';
import { PageHeader, Breadcrumb, Typography, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { breadCrumbsHash } from './breadCrumbsHash';

type TProps = {
  pageTitle?: string;
  extraButtonsList?: React.ReactNode[];
  pageTitleFooter?: string;
  subTitle?: string;
  hasBackButton?: boolean;
  backButtonPath?: string;
  isClickableId?: boolean;
};

export const Breadcrumbs: React.FC<TProps> = ({
  pageTitle = 'Редактирование кафе и ресторана',
  extraButtonsList = [],
  pageTitleFooter = '',
  subTitle = '',
  hasBackButton = false,
  backButtonPath = '',
  isClickableId = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbs = React.useMemo(() => {
    //TODO: first item should be link to dashboard
    const breadCrumbsArr: (string | React.ReactElement)[] = [
      'Администрирование',
    ];
    const slited = location.pathname.split('/').slice(1);

    slited.forEach((element, index) => {
      let content = '';
      if (breadCrumbsHash[element]) {
        content = breadCrumbsHash[element];
      } else {
        content = `#${element}`;
      }
      //no link for last item and id
      if (
        index + 1 !== slited.length &&
        (!content.startsWith('#') || isClickableId)
      ) {
        breadCrumbsArr.push(
          <Link to={`/${[...slited].slice(0, index + 1).join('/')}`}>
            {content}
          </Link>
        );
      } else {
        breadCrumbsArr.push(content);
      }
    });
    return breadCrumbsArr;
  }, [location.pathname]);

  return (
    <div>
      <PageHeader
        footer={pageTitleFooter}
        ghost={false}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {hasBackButton ? (
              <Button
                type='text'
                onClick={() => {
                  if (backButtonPath) {
                    navigate(backButtonPath);
                  } else {
                    window.history.back();
                  }
                }}
                style={{ marginRight: 12, padding: 0 }}
                icon={<ArrowLeftOutlined style={{ fontSize: 24 }} />}
              />
            ) : null}
            <Typography.Text
              style={{ fontSize: '20px', lineHeight: '28px', fontWeight: 400 }}
            >
              {pageTitle}
            </Typography.Text>
          </div>
        }
        subTitle={subTitle}
        breadcrumbRender={() => (
          <Breadcrumb>
            {breadCrumbs.map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        extra={extraButtonsList}
      ></PageHeader>
    </div>
  );
};
