import React from 'react';
import { Form, Input, Select } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { SearchOutlined } from '@ant-design/icons';

import { apiClient } from 'utils/http';
import { urls } from 'store/api';
import { StatusesEnum } from 'constants/status';
import { IRegionsItemResponse } from 'store/slices/regions/interfaces';
import { debounce } from 'utils/helpers';
import { IArticlesItem } from 'store/slices/articles/interfaces';

type TProps = {
  name: string;
};

type TFormValues = Omit<IRegionsItemResponse, 'articles'> & {
  articles: IArticlesItem[];
};

export const RegionsNestedArticlesSelector: React.FC<TProps> = ({ name }) => {
  const [data, setData] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const { setValue } = useFormContext();
  const values = useWatch() as unknown as TFormValues;

  const getArticles = debounce(async (nameQuery?: string) => {
    try {
      setFetching(true);
      const { data } = await apiClient.get(urls.api.articles.get, {
        params: {
          limit: 5,
          ...(nameQuery ? { nameQuery } : null),
          regionFiasIds: values.fiasId,
          status: StatusesEnum.PUBLISHED,
        },
      });

      if (data?.data.length) {
        const articles = data.data.map(article => ({
          ...article,
          value: article.id,
          label: article.name,
        }));
        setData(articles);
      } else {
        setData([]);
      }
    } catch (e: unknown) {
      setFetching(false);
    }
  }, 800);

  React.useEffect(() => {
    getArticles();
  }, []);

  const handleSearch = (nameQuery?: string) => {
    getArticles(nameQuery);
  };

  const onSelectArticle = option => {
    setValue(name, [
      ...values.articles,
      data.find(item => item.id === option.value),
    ]);
  };

  const checkIsSelectorChosen = id => {
    return !!values.articles?.find(item => item.id === id);
  };

  return (
    <Form.Item>
      <Input.Group compact>
        <Select
          showSearch
          value={null}
          filterOption={false}
          loading={fetching}
          placeholder='Поиск'
          showArrow={false}
          onSearch={handleSearch}
          onSelect={(_, option) => {
            onSelectArticle(option);
            handleSearch();
          }}
          notFoundContent={null}
          style={{ width: 'calc(100% - 37px)' }}
        >
          {data.length &&
            data.map(item => (
              <Select.Option
                key={item.id}
                value={item.value}
                disabled={checkIsSelectorChosen(item.id)}
              >
                {item.name}
              </Select.Option>
            ))}
        </Select>
        <span
          style={{
            height: 32,
            padding: '5px 11px',
            verticalAlign: 'center',
            backgroundColor: '#FAFAFA',
            border: '1px solid #D9D9D9',
            borderRadius: 2,
          }}
        >
          <SearchOutlined />
        </span>
      </Input.Group>
    </Form.Item>
  );
};
