import React from 'react';
import { Button, Dropdown, Image, Menu, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { CommonTable } from '../CommonTable/CommonTable';
import { getImageUrl } from '../../utils/image';

interface ICol {
  id: number;
  title: string;
  sortPriority: number;
}

interface IProps {
  data: ICol[];
  getLink?: (id: number) => string;
  getActions: (item: ICol) => Array<
    | {
        element: JSX.Element;
        handler?: undefined;
      }
    | {
        element: React.ReactNode;
        handler: () => void;
      }
  >;
  showSubtitle?: boolean;
}

export const CardTable: React.FC<IProps> = ({
  data,
  getActions,
  getLink,
  showSubtitle = false,
}) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'title',
      render: (title, item) => {
        const link = getLink ? getLink(item.id) : '';
        return (
          <Typography>
            <Link
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'inherit',
              }}
              to={link}
            >
              <div style={{ flexShrink: 0 }}>
                {item.image && (
                  <Image
                    width={50}
                    height={50}
                    preview={false}
                    src={
                      item?.image
                        ? getImageUrl(item.image, 50, 50) ||
                          '/public/images/no-image.svg'
                        : '/public/images/no-image.svg'
                    }
                    fallback='/public/images/no-image.svg'
                    style={{ flexShrink: 0 }}
                  />
                )}
              </div>
              <div style={{ display: 'flex', marginLeft: '16px' }}>
                <div style={{ marginRight: '5px' }}>
                  {title || 'Без названия'}
                </div>
                <div>{(showSubtitle && item.subtitle) || ''}</div>
              </div>
            </Link>
          </Typography>
        );
      },
    },
    {
      key: 2,
      width: 150,
      title: 'Сортировка',
      dataIndex: 'sortPriority',
      render: sortPriority => <Typography>{sortPriority}</Typography>,
    },
    {
      key: 3,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (actions, item) => {
        const dropMenu = (
          <Menu>
            {getActions &&
              getActions(item).map((action, index) =>
                action ? (
                  <Menu.Item key={index} onClick={action.handler}>
                    {action.element}
                  </Menu.Item>
                ) : null
              )}
          </Menu>
        );
        return (
          <>
            <Dropdown
              placement='bottomRight'
              overlay={dropMenu}
              arrow
              trigger={['click']}
            >
              <Button type='text' icon={<SettingOutlined />} />
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 600 }}
      dataSource={data}
      limit={10}
      total={data.length}
    />
  );
};
