import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { prepareContent } from 'components/form/Content/utils';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { ProjectStatuses, Statuses, StatusesEnum } from 'constants/status';
import { InvestmentProjectsItem } from 'store/slices/investmentProjects/interfaces';
import { TImage } from 'types/image';
import { DeepPartial } from 'types/utils';
import { omit } from 'utils/helpers';
import {
  mapContent,
  mapGallery,
  mapImage,
  mapRegion,
  mapSelect,
  SelectValue,
  mapMaterial,
  mapVideo,
  mapValuesToRegion,
  mapValueStringToSelect,
  mapValuesToSelect,
  mapValueStringToFieldArray,
} from 'utils/mappings';

import { FormValues } from './types';

export const mapValues = ({
  name,
  image,
  region,
  content,
  status,
  industry,
  projectStatus,
  gallery,
  materials,
  videoUrls,
  lang,
  ...values
}: FormValues): InvestmentProjectsItem => ({
  ...values,
  lang: mapSelect(lang) as LangEnumKeys,
  name: name.trim(),
  image: mapImage(image as TImage),
  region: mapRegion(region),
  content: mapContent(content),
  status: mapSelect(status as SelectValue) as Statuses,
  industry: mapSelect(industry as SelectValue) as number,
  projectStatus: mapSelect(projectStatus as SelectValue) as ProjectStatuses,
  gallery: mapGallery(gallery),
  materials: mapMaterial(materials as MaterialValue[]),
  videoUrls: mapVideo(videoUrls) as string[],
});

export const mapValuesAsDraft = ({
  name,
  image,
  region,
  content,
  industry,
  projectStatus,
  gallery,
  materials,
  videoUrls,
  lang,
  ...values
}: FormValues): DeepPartial<InvestmentProjectsItem> => ({
  ...omit(values, 'status'),
  lang: mapSelect(lang) as LangEnumKeys,
  name: name?.trim() || undefined,
  image: image ? mapImage(image as TImage) : undefined,
  region: region ? mapRegion(region) : undefined,
  content: content?.length ? mapContent(content) : prepareContent([]),
  gallery: gallery?.length ? mapGallery(gallery) : [],
  projectStatus: projectStatus
    ? (mapSelect(projectStatus) as ProjectStatuses)
    : undefined,
  industry: industry ? (mapSelect(industry) as number) : undefined,
  materials: materials?.length ? mapMaterial(materials as MaterialValue[]) : [],
  videoUrls: videoUrls?.length ? mapVideo(videoUrls) : [],
});

export const mapValuesToForm = ({
  name,
  content,
  region,
  projectStatus,
  status,
  industryData,
  videoUrls,
  materials,
  gallery,
  lang,
  ...item
}: InvestmentProjectsItem) => ({
  ...omit(item, [
    'author',
    'creator',
    'editor',
    'createdAt',
    'updatedAt',
    'authorData',
    'publishedAt',
  ]),
  lang: lang || LangEnum.ru,
  name: name || '',
  content: prepareContent(content || []),
  region: region ? mapValuesToRegion(region) : null,
  industry: mapValuesToSelect(industryData),
  projectStatus: projectStatus ? mapValueStringToSelect(projectStatus) : null,
  status: status === StatusesEnum.DRAFT ? StatusesEnum.PUBLISHED : status,
  videoUrls: mapValueStringToFieldArray(videoUrls),
  materials: materials || [],
  gallery: gallery || [],
});
