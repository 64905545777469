import React from 'react';
import { FormItemProps, InputProps } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { ReviewGallery } from './ReviewGallery';

type TProps = FormItemProps & InputProps;

export const Gallery: React.FC<TProps> = props => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      render={({ field }) => {
        return (
          <ReviewGallery
            {...props}
            {...field}
            onChange={items => field.onChange(items)}
          />
        );
      }}
      control={control}
    />
  );
};
