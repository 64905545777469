import { Col, List, Row, Tag, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { pluralize } from 'utils/pluralize';
import { entityTypeNames } from 'constants/catalog';
import { useAppSelector } from 'store';
import { IReviewsItemResponse } from 'store/slices/reviews/interfaces';

import { entityTypeForUrlHash } from '../components/constants';

type TProps = {
  reviews: IReviewsItemResponse[];
  total: number;
  offset: number;
  onLoadMore: (offset: number) => void;
};
export const TouristReviews: React.FC<TProps> = ({
  reviews,
  total,
  offset,
  onLoadMore,
}) => {
  const { b2cUrl } = useAppSelector(state => state.configSlice);

  return (
    <Row style={{ width: '100%' }}>
      {reviews?.length ? (
        <List
          pagination={
            total > 5
              ? {
                  onChange: (page, size) => {
                    const offset = (page - 1) * size;
                    onLoadMore(offset);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} из ${total} ${pluralize(
                      'результат',
                      total,
                      true
                    )}`,
                  current: 1 + (Number(offset) || 0) / 5,
                  total,
                  pageSize: 5,
                  showSizeChanger: false,
                }
              : false
          }
          dataSource={reviews}
          style={{ width: '100%' }}
          renderItem={review => (
            <List.Item key={review.id} style={{ border: 'none' }}>
              <Row
                key={review.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  minHeight: '110px',
                  padding: '16px 24px',
                  border: '1px solid #d9d9d9',
                }}
                wrap={false}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flex: 1,
                  }}
                >
                  <Row
                    style={{
                      marginBottom: '4px',
                    }}
                  >
                    <Col
                      style={{
                        marginRight: '24px',
                        maxWidth: '70%',
                      }}
                    >
                      <Typography.Link
                        href={`${b2cUrl}/${
                          entityTypeForUrlHash[review.entityType]
                        }/${review.entityId}`}
                        target='_blank'
                      >
                        <Typography.Title level={5} ellipsis={{ rows: 1 }}>
                          {review.entityInfo.name}
                        </Typography.Title>
                      </Typography.Link>
                    </Col>
                    <Col>
                      <Tag color={statusesColorsHash[review.status]}>
                        {statusesNamesHash[review.status]}
                      </Tag>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: '8px',
                    }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {entityTypeNames[review.entityType]}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        width: '70%',
                      }}
                    >
                      <Typography.Paragraph
                        ellipsis={{ rows: 2 }}
                        style={{
                          marginBottom: '0px',
                        }}
                      >
                        {review.content}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </div>
                <Link to={`/reviews/${review.id}`} target='_blank'>
                  Перейти
                </Link>
              </Row>
            </List.Item>
          )}
        />
      ) : null}
    </Row>
  );
};
