import { createSlice } from '@reduxjs/toolkit';

import { IMenuResponse } from './interfaces';
import { loadMenu } from './actions';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuData: [],
    loading: false,
  } as { menuData: IMenuResponse[]; loading: boolean },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadMenu.fulfilled, (state, { payload }) => {
      state.menuData = payload;
      state.loading = false;
    });
    builder.addCase(loadMenu.rejected, state => {
      state.menuData = [];
      state.loading = false;
    });
    builder.addCase(loadMenu.pending, state => {
      state.loading = true;
    });
  },
});
