export enum WeekDays {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
}

export enum WeekDaysShortNameRu {
  mon = 'пн',
  tue = 'вт',
  wed = 'ср',
  thu = 'чт',
  fri = 'пт',
  sat = 'сб',
  sun = 'вс',
}

export const weekDaysNames = {
  [WeekDays.mon]: 'Понедельник',
  [WeekDays.tue]: 'Вторник',
  [WeekDays.wed]: 'Среда',
  [WeekDays.thu]: 'Четверг',
  [WeekDays.fri]: 'Пятница',
  [WeekDays.sat]: 'Суббота',
  [WeekDays.sun]: 'Воскресенье',
};

export const weekDaysNamesShort = {
  [WeekDays.mon]: 'Пн',
  [WeekDays.tue]: 'Вт',
  [WeekDays.wed]: 'Ср',
  [WeekDays.thu]: 'Чт',
  [WeekDays.fri]: 'Пт',
  [WeekDays.sat]: 'Сб',
  [WeekDays.sun]: 'Вс',
};

export const weekDaysNamesIndexes = {
  [WeekDaysShortNameRu.mon]: 0,
  [WeekDaysShortNameRu.tue]: 1,
  [WeekDaysShortNameRu.wed]: 2,
  [WeekDaysShortNameRu.thu]: 3,
  [WeekDaysShortNameRu.fri]: 4,
  [WeekDaysShortNameRu.sat]: 5,
  [WeekDaysShortNameRu.sun]: 6,
};

export type WeekDaysKeys = keyof typeof WeekDays;
export type WeekDay = `${WeekDays}`;
