import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Row } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { IAlert } from '../../../store/slices/alert/interfaces';
import { validationSchema } from './validation';
import { CheckboxField } from '../../../components/form/base/CheckboxField';
import { InputField } from '../../../components/form/base/InputField';
import { FormActions } from '../../../components/Layout/FormActions/FormActions';

interface IProps {
  initialValues: IAlert;
  onSubmit: (values: IAlert) => void;
  isLoading: boolean;
}

export const AlertForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
}) => {
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const handleCancel = () => {
    methods.reset(initialValues);
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Row>
          <Col span={24}>
            <InputField
              name='name'
              label='Название'
              required
              placeholder='Введите название'
              showCount
              maxLength={255}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InputField
              name='link'
              label='Ссылка'
              placeholder='Введите ссылку'
              addonBefore={<GlobalOutlined />}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckboxField name='is_active'>Показать оповещение</CheckboxField>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={handleCancel} disabled={isLoading}>
            Отмена
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            type='primary'
            htmlType='submit'
          >
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
