import React from 'react';
import classNames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Typography } from 'antd';
import { Path, useFieldArray, useFormContext } from 'react-hook-form';

import { InputField } from 'components/form/base/InputField';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { IFormValues } from 'pages/PriorityIndustry/components/types';
import { UploadSingleFile } from 'components/form/UploadSingleFile/UploadSingleFile';
import { formatterFieldCount } from 'utils/formatterFieldCount';

import { Header } from './components/Header/Header';
import { ExtraControlMenu } from '../../../../../../components/ExtraControlMenu';
import { IconTooltip } from './components/IconTooltip/IconTooltip';
import styles from './DirectionForm.module.less';

export const DirectionForm = () => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<IFormValues>();
  const { remove, move, append, fields } = useFieldArray({
    control,
    name: 'direction',
  });
  const componentRef = React.useRef<Array<HTMLDivElement>>([]);
  const defaultActiveTabs = Array.from(
    { length: fields.length },
    (_, index) => index
  );

  const [activeCollapseTabs, setActiveCollapseTabs] =
    React.useState(defaultActiveTabs);

  const findFirstErrorIndex = array => {
    return array?.findIndex(item => item !== null && item !== undefined);
  };

  React.useEffect(() => {
    const firstErrorIndex: number = findFirstErrorIndex(errors?.direction);

    if (Object.keys(errors)[0] === 'direction') {
      componentRef.current[firstErrorIndex].focus();

      setActiveCollapseTabs([...activeCollapseTabs, firstErrorIndex]);
    }
  }, [errors]);

  const handleAdd = () => {
    setActiveCollapseTabs([...activeCollapseTabs, activeCollapseTabs.length]);
    append({
      title: '',
      icon: null,
      shortDescription: '',
    });

    !fields.length && clearErrors('direction');
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleMove = (index: number, step: number) => move(index, step);

  return (
    <>
      <Row>
        <Col span={24}>
          <InputField
            name='title'
            label='Заголовок на портале'
            placeholder='Введите название'
            minLength={1}
            maxLength={80}
            required
          />
        </Col>
      </Row>
      <Button
        icon={<PlusOutlined />}
        onClick={handleAdd}
        disabled={fields.length >= 26}
      >
        Добавить отрасль
      </Button>
      {errors.direction ? (
        <Typography.Text type='danger' className={styles.errorText}>
          {errors.direction.message}
        </Typography.Text>
      ) : null}

      {fields.map(({ id }, index) => (
        <Collapse
          key={id}
          className={styles.collapse}
          defaultActiveKey={[1]}
          onChange={value => setActiveCollapseTabs(value as any)}
          activeKey={activeCollapseTabs}
          expandIconPosition='end'
        >
          <Collapse.Panel
            key={index}
            header={
              <div
                tabIndex={0}
                ref={ref => (componentRef.current[index] = ref)}
              >
                <Header index={index} />
              </div>
            }
            className={classNames({
              [styles.error]: errors.direction && errors.direction[index],
            })}
            extra={
              <ExtraControlMenu
                index={index}
                count={fields.length}
                onMove={handleMove}
                onRemove={handleRemove}
              />
            }
          >
            <Row>
              <Col span={24}>
                <InputField
                  name={`direction.${index}.title` as Path<IFormValues>}
                  label='Название отрасли'
                  placeholder='Введите название'
                  minLength={1}
                  maxLength={60}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <UploadSingleFile<IFormValues>
                  label='Загрузить иконку'
                  name={`direction.${index}.icon` as Path<IFormValues>}
                  tooltip={<IconTooltip />}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <TextAreaField
                  name={`direction.${index}.shortDescription`}
                  label='Краткое описание'
                  placeholder='Введите описание'
                  minLength={1}
                  maxLength={255}
                  rows={2}
                  showCount={{
                    formatter: formatterFieldCount,
                  }}
                />
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      ))}
    </>
  );
};
