import React from 'react';
import dayjs from 'dayjs';

import { ICommentsItem } from 'store/slices/formComments/interfaces';
import { Avatar } from 'components/Avatar/Avatar';

import { FormCommentsFiles } from '../FormCommentsFiles/FormCommentsFiles';
import styles from './FormCommentsItem.module.less';

export const FormCommentsItem: React.FC<ICommentsItem> = ({
  createdAt,
  text,
  authorData: { firstName, lastName },
  documentsData,
}) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar firstName={firstName} lastName={lastName} />
        </div>

        <div className={styles.messageContainer}>
          <div className={styles.author}>
            {lastName} {firstName}
          </div>
          <div className={styles.message}>{text}</div>
          {!!documentsData?.length && (
            <FormCommentsFiles data={documentsData} />
          )}
          <div className={styles.date}>
            {dayjs(createdAt).format('DD MMMM YYYY, HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};
