import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import { TRespondsItemData, TRespondsTypes } from './interfaces';
type TListResponse = {
  data: TRespondsItemData[];
  total: number;
};

export const loadRespondsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: TRespondsTypes;
    sort?: string;
  },
  { state: RootState }
>('responds/loadList', async ({ type, ...params }, { getState }) => {
  const { limit } = getState().responds;
  const { data } = await apiClient.get<TListResponse>(urls.api[type].get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const loadRespondsItem = createAsyncThunk<
  TRespondsItemData,
  {
    id?: string;
    type?: TRespondsTypes;
  }
>('responds/loadItem', async ({ id, type }) => {
  const { data } = await apiClient.get<TRespondsItemData>(
    getUrlWithParams(urls.api[type].getOne, {
      id,
    })
  );
  return data;
});

export const deleteRespondsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
    type?: TRespondsTypes;
  }
>('responds/deleteItem', async ({ id, type }) => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api[type].delete, { id }));
  return data;
});
