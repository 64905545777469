import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { loadTouristItem, saveTouristItem } from 'store/slices/tourist/actions';

import { mapValuesToForm } from './components/formUtils';
import { TouristForm } from './components/TouristForm';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.tourist);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadTouristItem(id));
  }, []);

  const onFinish = values => {
    setIsLoading(true);
    dispatch(saveTouristItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/tourist');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/tourist');
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && item.id === Number(id) ? (
        <TouristForm
          onFinish={onFinish}
          cancelButtonHandler={cancelButtonHandler}
          initialValues={mapValuesToForm(item)}
          isLoading={isLoading}
          authType={item.authType}
        />
      ) : null}
    </MainLayout>
  );
};
