// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Comments-module__loadMore--KFgGn {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 30px;\n}\n.Comments-module__list--uMGc9 {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reports/components/ViewInfo/components/Comments/Comments.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAAF","sourcesContent":[".loadMore {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 30px;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadMore": "Comments-module__loadMore--KFgGn",
	"list": "Comments-module__list--uMGc9"
};
export default ___CSS_LOADER_EXPORT___;
