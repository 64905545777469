import {
  AppstoreAddOutlined,
  CalendarOutlined,
  CameraOutlined,
  EnvironmentOutlined,
  NodeIndexOutlined,
  PictureOutlined,
  PlusOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ContentType, ContentTypeEnum, WidgetTypeEnum } from './contentTypes';

type TToolbarButton = {
  type: string;
  icon: React.ReactNode;
  title: string;
  initialValue?: any;
};

const buttons: TToolbarButton[] = [
  {
    type: ContentTypeEnum.image,
    icon: <CameraOutlined />,
    title: 'Изображение',
    initialValue: null,
  },
  {
    type: ContentTypeEnum.gallery,
    icon: <AppstoreAddOutlined />,
    title: 'Галерея',
    initialValue: [],
  },
  {
    type: ContentTypeEnum.video,
    icon: <VideoCameraAddOutlined />,
    title: 'Видео',
    initialValue: null,
  },
  {
    type: ContentTypeEnum.widget,
    icon: <EnvironmentOutlined />,
    title: 'Место',
    initialValue: { type: 'place' },
  },
  {
    type: ContentTypeEnum.widget,
    icon: <PictureOutlined />,
    title: 'Событие',
    initialValue: { type: WidgetTypeEnum.events, events: null },
  },
  {
    type: ContentTypeEnum.widget,
    icon: <NodeIndexOutlined />,
    title: 'Маршрут',
    initialValue: { type: WidgetTypeEnum.routes, routes: null },
  },
  {
    type: ContentTypeEnum.widget,
    icon: <CalendarOutlined />,
    title: 'Публикации',
    initialValue: { type: 'article', article: null },
  },
  {
    type: ContentTypeEnum.geoPosition,
    icon: <div>Геометка</div>,
    title: 'Геометка',
    initialValue: { address: {} },
  },
  // {
  //   type: 'htmlCode',
  //   icon: <div>{'<html>'}</div>,
  //   title: 'Код',
  //   initialValue: null,
  // },
];

type TProps = {
  onAddItem: (type: any, initialValue: any) => any;
  onOpenClick: () => void;
  open?: boolean;
  enabledTypes: ContentType[];
};

export const Toolbar: React.FC<TProps> = ({
  onAddItem,
  onOpenClick,
  open = false,
  enabledTypes,
}) => {
  const toolbarItems = buttons
    .filter(({ type }) => enabledTypes.includes(type as ContentType))
    .map((data, index) => {
      if (
        data.type === ContentTypeEnum.widget &&
        data.initialValue?.type === 'place'
      ) {
        return (
          <Dropdown
            key={index}
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item
                  key={1}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.attractions,
                      attractions: null,
                    })
                  }
                >
                  <Typography.Text>Достопримечательности</Typography.Text>
                </Menu.Item>
                <Menu.Item
                  key={2}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.resorts,
                      resorts: null,
                    })
                  }
                >
                  <Typography.Text>Курорты</Typography.Text>
                </Menu.Item>
                <Menu.Item
                  key={3}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.hotels,
                      hotels: null,
                    })
                  }
                >
                  <Typography.Text>Отели</Typography.Text>
                </Menu.Item>
                <Menu.Item
                  key={4}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.restaurants,
                      restaurants: null,
                    })
                  }
                >
                  <Typography.Text>Кафе и рестораны</Typography.Text>
                </Menu.Item>
              </Menu>
            }
            arrow
            trigger={['click']}
          >
            <div key={index} className='toolbar_list-item' title={data.title}>
              {data.icon}
            </div>
          </Dropdown>
        );
      }
      if (
        data.type === ContentTypeEnum.widget &&
        data.initialValue?.type === 'article'
      ) {
        return (
          <Dropdown
            key={index}
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item
                  key={1}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.articles,
                      articles: null,
                    })
                  }
                >
                  <Typography.Text>Статьи</Typography.Text>
                </Menu.Item>
                <Menu.Item
                  key={2}
                  onClick={() =>
                    onAddItem(data.type, {
                      type: WidgetTypeEnum.news,
                      news: null,
                    })
                  }
                >
                  <Typography.Text>Новости</Typography.Text>
                </Menu.Item>
              </Menu>
            }
            arrow
            trigger={['click']}
          >
            <div key={index} className='toolbar_list-item' title={data.title}>
              {data.icon}
            </div>
          </Dropdown>
        );
      }
      return (
        <button
          key={index}
          type='button'
          className='toolbar_list-item'
          title={data.title}
          onClick={() => onAddItem(data.type, data.initialValue)}
        >
          {data.icon}
        </button>
      );
    });

  return (
    <div className='toolbar'>
      <button type='button' className='toolbar_close' onClick={onOpenClick}>
        <div
          className={classNames('toolbar_icon', {
            toolbar_icon__rotated: open,
          })}
        >
          <PlusOutlined style={{ fontSize: '20px' }} />
        </div>
        {/* <Icon
          icon="clear"
          className={classNames(
            'toolbar_icon',
            'toolbar_icon__close',
            { toolbar_icon__rotated: !open }
          )}
        /> */}
      </button>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={open}
        timeout={640}
        classNames={{
          enter: 'toolbar_list__enter',
          enterActive: 'toolbar_list__enter-active',
          exit: 'toolbar_list__exit',
          exitActive: 'toolbar_list__exit-active',
        }}
      >
        <div className='toolbar_list'>{toolbarItems}</div>
      </CSSTransition>
    </div>
  );
};
