// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuForm-module__contentBlock--XTlDv {\n  height: 90px;\n  margin-bottom: 24px;\n  padding-top: 12px;\n  padding-bottom: 16px;\n}\n.MenuForm-module__bottomActions--USVaY {\n  margin-top: 24px;\n  display: flex;\n  justify-content: end;\n  gap: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Menu/components/MenuForm/MenuForm.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AACF;AAEA;EACE,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,SAAA;AAAF","sourcesContent":[".contentBlock {\n  height: 90px;\n  margin-bottom: 24px;\n  padding-top: 12px;\n  padding-bottom: 16px;\n}\n\n.bottomActions {\n  margin-top: 24px;\n  display: flex;\n  justify-content: end;\n  gap: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBlock": "MenuForm-module__contentBlock--XTlDv",
	"bottomActions": "MenuForm-module__bottomActions--USVaY"
};
export default ___CSS_LOADER_EXPORT___;
