import { createSlice } from '@reduxjs/toolkit';

import { ICardState } from './interfaces';
import { getCard, getCardItem } from './actions';

export const b2bCardSlice = createSlice({
  name: 'b2bCard',
  initialState: {
    card: {
      language: 'ru',
      cardItems: {
        data: [],
      },
    },
    cardItem: null,
    isLoading: false,
  } as ICardState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCard.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCard.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getCard.fulfilled, (state, { payload }) => {
      state.card = payload;
      state.isLoading = false;
    });
    builder.addCase(getCardItem.fulfilled, (state, { payload }) => {
      state.cardItem = payload;
    });
  },
});
