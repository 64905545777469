import { createSlice } from '@reduxjs/toolkit';

import { IRestaurantsState } from './interfaces';
import { loadRestaurantsItem, loadRestaurantsList } from './actions';

export const restaurantsSlice = createSlice({
  name: 'restaurants',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
  } as IRestaurantsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadRestaurantsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
        state.isLoading = false;
      }
    );
    builder.addCase(loadRestaurantsList.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(loadRestaurantsList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loadRestaurantsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});
