import React from 'react';

import { RegionsEnum } from 'constants/regionsEnum';

import { ISelectFieldProps, Select } from '../base/Select';

type TProps = {
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
} & ISelectFieldProps;

const options = Object.keys(RegionsEnum).map(region => ({
  label: RegionsEnum[region],
  value: RegionsEnum[region],
}));

export const RegionsEnumSelect: React.FC<TProps> = ({
  label = 'id региона',
  ...props
}) => {
  return <Select options={options} label={label} {...props} />;
};
