import React from 'react';
import dayjs from 'dayjs';
import { Image } from 'antd';

import { IReportComment } from 'store/slices/reportComments/interfaces';
import { Avatar } from 'components/Avatar/Avatar';
import { getImageUrl } from 'utils/image';

import { FormCommentsFiles } from '../FormCommentsFiles/FormCommentsFiles';
import styles from './FormCommentsItem.module.less';

export const FormCommentsItem: React.FC<IReportComment> = ({
  createdAt,
  text,
  authorData: { firstName, lastName, image },
  documentsData,
}) => (
  <div>
    <div className={styles.container}>
      <div className={styles.avatar}>
        {image ? (
          <Image
            className={styles.avatarIcon}
            src={getImageUrl(image)}
            alt={image.realName}
            preview={false}
            width={32}
            height={32}
            placeholder={<Avatar firstName={firstName} lastName={lastName} />}
          />
        ) : (
          <Avatar firstName={firstName} lastName={lastName} />
        )}
      </div>

      <div className={styles.messageContainer}>
        <div className={styles.author}>
          {lastName} {firstName}
        </div>
        <div className={styles.message}>{text}</div>
        {!!documentsData?.length && <FormCommentsFiles data={documentsData} />}
        <div className={styles.date}>
          {dayjs(createdAt).format('DD MMMM YYYY, HH:mm')}
        </div>
      </div>
    </div>
  </div>
);
