import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import {
  saveMaterial,
  saveMaterialAsDraft,
} from 'store/slices/materials/actions';
import { IMaterial, TMaterialDraft } from 'store/slices/materials/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import { StatusesEnum } from 'constants/status';

import { MaterialForm } from './components/MaterialForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IMaterial) => {
    setIsLoading(true);
    dispatch(saveMaterial(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/materials');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TMaterialDraft) => {
    setIsLoading(true);
    dispatch(saveMaterialAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/materials');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/materials');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <MaterialForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          name: '',
          content: prepareContent([]),
          status: StatusesEnum.PUBLISHED,
        }}
      />
    </MainLayout>
  );
};
