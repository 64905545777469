export enum TaxSystemNames {
  'osno' = 'ОСН',
  'psn' = 'ПСН',
  'usn' = 'УСН',
  'usnEnvd' = 'УСН + ЕНВД',
  'usnPsn' = 'УСН + ПСН',
  'envd' = 'ЕНВД',
}

export enum RolesNames {
  'owner' = 'Владелец',
  'guest' = 'Сотрудник',
}

export type TRoles = 'resident' | 'noResident';

export enum Roles {
  'resident' = 'Резидент ОЭЗ',
  'noResident' = 'Нерезидент ОЭЗ',
}

export const DEFAULT_ACCEPT_ORGANIZATION =
  '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx';

export const VALID_FORMATS_LIST_ORGANIZATION = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ERROR_MESSAGE_ORGANIZATION =
  'У файла недопустимый формат. Загрузите файл с расширением ' +
  DEFAULT_ACCEPT_ORGANIZATION;

export const DEFAULT_ORGANIZATION_NAME = 'Организация';
