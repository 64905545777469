import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';

import { IReport } from 'store/slices/reports/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import {
  reportName,
  reportStatusColor,
  reportStatusName,
  TReports,
  TReportStatus,
} from 'constants/reports';
import { OrganizationTypeEnum } from 'types/organizations';

import { getName } from '../utils';
import styles from './ListTable.module.less';

interface IProps {
  data: Array<IReport>;
  total: number;
  limit?: number;
  getActions: (item: IReport) => Array<ItemType>;
  setSort: (sort?: string) => void;
}

export const ListTable = React.memo(
  ({ data, total, limit = 20, getActions, setSort }: IProps) => {
    const columns = [
      {
        key: 1,
        title: '№',
        dataIndex: 'serialNumber',
        width: 79,
        render: (_, { serialNumber, id }: IReport) => (
          <Link to={`/reports/${id}/view`} className={styles.serialNumber}>
            {serialNumber}
          </Link>
        ),
      },
      {
        key: 2,
        title: 'Дата и время загрузки',
        width: 129,
        dataIndex: 'createdAt',
        render: (value: Date) => (
          <Typography.Text>
            {dayjs(value).format('DD.MM.YYYY HH:mm')}
          </Typography.Text>
        ),
      },
      {
        key: 3,
        sorter: true,
        title: 'Тип отчетности',
        dataIndex: 'type',
        render: (value: TReports, item: IReport) => (
          <Link to={`/reports/${item.id}/view`}>
            <Typography.Text>{reportName[value]}</Typography.Text>
          </Link>
        ),
      },
      {
        key: 4,
        title: 'Название организации',
        dataIndex: 'organizationData',
        render: ({
          type,
          shortName,
          firstName,
          secondName,
          lastName,
        }: IReport['organizationData']) => (
          <Typography.Text>
            {(type === OrganizationTypeEnum.organization
              ? shortName
              : getName({ firstName, secondName, lastName })) || 'Не заполнено'}
          </Typography.Text>
        ),
      },
      {
        key: 5,
        title: 'Статус отчета',
        width: 113,
        dataIndex: 'status',
        render: (value: TReportStatus) => (
          <Tag color={reportStatusColor[value]}>{reportStatusName[value]}</Tag>
        ),
      },
      {
        key: 6,
        title: '',
        dataIndex: 'actions',
        width: 65,
        fixed: 'right' as const,
        render: (_: unknown, item: IReport) => (
          <Dropdown
            placement='bottomRight'
            overlay={<Menu items={getActions(item)} />}
            trigger={['click']}
            arrow
          >
            <Button type='text' icon={<SettingOutlined />} />
          </Dropdown>
        ),
      },
    ];
    return (
      <CommonTable
        className={styles.table}
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    );
  }
);

ListTable.displayName = 'ListTable';
