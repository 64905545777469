import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent } from 'utils/upload';

import { IMaterial, IMaterialResponse, TMaterialDraft } from './interfaces';

type TListResponse = { data: IMaterialResponse[]; total: number };

export const loadMaterialsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    status?: string;
    sort?: string;
  },
  { state: RootState }
>('materials/loadList', async (params, { getState }) => {
  const { limit } = getState().materials;
  const { data } = await apiClient.get<TListResponse>(urls.api.materials.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadMaterial = createAsyncThunk<IMaterialResponse, string>(
  'materials/loadItem',
  async id => {
    const { data } = await apiClient.get<IMaterialResponse>(
      getUrlWithParams(urls.api.materials.getOne, {
        id,
      })
    );
    return data;
  }
);

export const saveMaterial = createAsyncThunk<unknown, IMaterial>(
  'materials/saveItem',
  async ({ id, ...params }) => {
    const content = await uploadContent(params.content);

    if (id) {
      const { data } = await apiClient.put<IMaterial>(
        getUrlWithParams(urls.api.materials.put, { id }),
        {
          ...params,
          content,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post<IMaterial>(
        urls.api.materials.post,
        {
          ...params,
          content,
        }
      );
      return data;
    }
  }
);

export const saveMaterialAsDraft = createAsyncThunk<unknown, TMaterialDraft>(
  'materials/saveItem',
  async ({ id, ...requestParams }) => {
    const content = await uploadContent(requestParams.content);

    if (id) {
      const { data } = await apiClient.put<TMaterialDraft>(
        getUrlWithParams(urls.api.materials.draft.put, { id }),
        {
          ...requestParams,
          content,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post<TMaterialDraft>(
        urls.api.materials.draft.post,
        {
          ...requestParams,
          content,
        }
      );
      return data;
    }
  }
);

export const setPublishMaterial = createAsyncThunk<void, string>(
  'materials/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.materials.publish.patch, { id })
    );
  }
);

export const setUnPublishMaterial = createAsyncThunk<void, string>(
  'materials/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.materials.unPublish.patch, { id })
    );
  }
);

export const removeMaterial = createAsyncThunk<void, string>(
  'materials/removeItem',
  async id => {
    const { data } = await apiClient.delete(
      getUrlWithParams(urls.api.materials.delete, { id })
    );
    return data;
  }
);
