import React from 'react';

import { stringToHslColor } from 'utils/strToHslColor';

import styles from './Avatar.module.less';

type TProps = {
  width?: number;
  height?: number;
  firstName: string;
  lastName: string;
};

export const Avatar: React.FC<TProps> = ({
  width = 32,
  height = 32,
  firstName,
  lastName,
}) => {
  return (
    <div
      style={{
        backgroundColor: stringToHslColor(`${lastName} ${firstName}`),
        width,
        height,
      }}
      className={styles.icon}
    >
      {`${lastName?.charAt(0)}${firstName?.charAt(0)}`}
    </div>
  );
};
