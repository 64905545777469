import React from 'react';

import { LangEnum, LangNamesHash } from 'constants/lang';

import { ISelectFieldProps, Select } from '../base/Select';

type TProps = {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  withEmptyValue?: boolean;
} & ISelectFieldProps;

const options = Object.keys(LangEnum).map(lang => ({
  label: LangNamesHash[lang],
  value: lang,
}));

export const LocaleSelect: React.FC<TProps> = ({
  label = 'Тип сущности',
  withEmptyValue,
  ...props
}) => {
  return (
    <>
      <Select
        label={label}
        options={[
          ...(withEmptyValue ? [{ value: '', label: 'Все' }] : []),
          ...options,
        ]}
        {...props}
      />
    </>
  );
};
