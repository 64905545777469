// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditContent-module__card--i4NHk .ant-card-head {\n  padding: 0;\n  margin: 0;\n  border-bottom: none;\n}\n.EditContent-module__card--i4NHk .ant-card-head .ant-card-head-title {\n  padding-top: 0;\n}\n.EditContent-module__card--i4NHk .ant-card-body {\n  padding: 0;\n  padding-top: 24px;\n}\n.EditContent-module__row--YzXgq .ant-descriptions-item-container .ant-descriptions-item-label::after {\n  content: '';\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Business/components/EditContent/EditContent.module.less"],"names":[],"mappings":"AAAA;EAGG,UAAA;EACA,SAAA;EACA,mBAAA;AADH;AAJA;EAOI,cAAA;AAAJ;AAPA;EAYG,UAAA;EACA,iBAAA;AAFH;AAcI;EACC,WAAA;AAZL","sourcesContent":[".card {\n\t:global {\n\t\t.ant-card-head {\n\t\t\tpadding: 0;\n\t\t\tmargin: 0;\n\t\t\tborder-bottom: none;\n\t\t\t.ant-card-head-title {\n\t\t\t\tpadding-top: 0;\n\t\t\t}\n\t\t}\n\n\t\t.ant-card-body {\n\t\t\tpadding: 0;\n\t\t\tpadding-top: 24px;\n\t\t}\n\t}\n}\n\n\n.row {\n\t:global {\n\t\t\n\t\t.ant-descriptions-item-container {\n\n\t\t\t.ant-descriptions-item-label {\n\t\t\t\t&::after {\n\t\t\t\t\tcontent: '';\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "EditContent-module__card--i4NHk",
	"row": "EditContent-module__row--YzXgq"
};
export default ___CSS_LOADER_EXPORT___;
