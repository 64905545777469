import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';

import { Schedule } from '../../Schedule/Schedule';
import { RangeDatePicker } from '../../base/RangePicker';
import { defaultScheduleDump } from './formUtils';

interface IPlaceScheduleProps {
  name: string;
  isClearButtonVisible: boolean;
  error?: any;
}

const BREAKPOINT_WIDTH = 565;

export const PlaceSchedule: React.FC<IPlaceScheduleProps> = ({
  name,
  isClearButtonVisible,
  error,
}) => {
  const { setValue } = useFormContext();
  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <RangeDatePicker
          name={`${name}.date`}
          label='Даты проведения'
          style={{ fontWeight: 'bold' }}
          placeholder={['Дата начала', 'Дата окончания']}
        />
      </Col>
      <Col span={24}>
        <Schedule
          name={`${name}.table`}
          withoutComment
          withAllTimeButton
          breakpoint={BREAKPOINT_WIDTH}
        />
        {error?.schedule?.table && (
          <Row>
            <Typography.Text type='danger'>
              {error?.schedule?.table?.message}
            </Typography.Text>
          </Row>
        )}
        {isClearButtonVisible && (
          <Button
            type='link'
            danger
            icon={<DeleteOutlined />}
            onClick={() => setValue(name, defaultScheduleDump)}
            style={{ marginTop: 24, padding: 0 }}
          >
            Очистить все
          </Button>
        )}
      </Col>
    </Row>
  );
};
