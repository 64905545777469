import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  Col,
  Collapse,
  Image,
  notification,
  Rate,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { getImageUrl } from 'utils/image';
import {
  statusesColorsHash,
  StatusesEnum,
  statusesNamesHash,
} from 'constants/status';
import {
  deleteReviewsItem,
  loadReviewsItem,
  updateStatusItem,
} from 'store/slices/reviews/actions';
import { entityTypeNames } from 'constants/catalog';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { getName } from 'pages/Organizations/utils';
import { entityTypeForUrlHash } from 'constants/reviews';
import { ViewGallery } from 'components/view/ViewGallery/ViewGallery';

import styles from './View.module.less';

export const View = () => {
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const [modalIsOpen, setModalIsOpen] = React.useState(null);
  const { item } = useAppSelector(state => state.reviews);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const loadReviews = () => {
    dispatch(loadReviewsItem({ id }));
  };

  React.useEffect(() => {
    loadReviews();
  }, []);

  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState(['reply']);

  const handlePublish = async () => {
    await dispatch(updateStatusItem({ id, status: StatusesEnum.PUBLISHED }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно опубликована',
        });
        loadReviews();
      });
  };

  const handleUnPublish = async () => {
    await dispatch(updateStatusItem({ id, status: StatusesEnum.NOT_PUBLISHED }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadReviews();
      });
  };

  const modalCloseHandler = () => {
    setModalIsOpen(false);
  };

  const handleRemove = () => {
    if (item.status === StatusesEnum.PUBLISHED) {
      notification.error({
        message: 'Необходимо снять сущность с публикации',
      });
    } else {
      setModalIsOpen(true);
    }
  };

  const handleDelete = async id => {
    await dispatch(deleteReviewsItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        navigate('/reviews');
        modalCloseHandler();
      });
  };

  const getActions = () => [
    <Button
      key='unPublish'
      onClick={handleUnPublish}
      disabled={item.status !== StatusesEnum.PUBLISHED}
    >
      Снять с публикации
    </Button>,
    <Link key='edit' to={`/reviews/${id}/edit`}>
      <Button>Редактировать</Button>
    </Link>,
    <Button danger key='delete' onClick={handleRemove}>
      Удалить
    </Button>,
  ];

  return (
    <MainLayout
      pageTitle='Просмотр отзыва'
      extraButtonsList={getActions()}
      aside={
        item.id ? (
          <div>
            <Content
              style={{
                height: '90px',
                paddingTop: '13px',
                paddingBottom: '18px',
              }}
            >
              <Row>
                <Typography.Text type='secondary'>Статус</Typography.Text>
              </Row>
              <Tag
                color={statusesColorsHash[item.status]}
                style={{ marginTop: '15px' }}
              >
                {statusesNamesHash[item.status]}
              </Tag>
            </Content>
            <Content style={{ marginTop: '24px' }}>
              <Row style={{ flexFlow: 'column nowrap' }}>
                <Typography.Title level={5} style={{ marginBottom: '8px' }}>
                  ФИО пользователя
                </Typography.Title>
                <Space direction='vertical' size='small'>
                  <Col span={24}>
                    <Typography.Text>
                      {getName(
                        {
                          lastName: item.userData.lastName,
                          firstName: item.userData.firstName,
                          secondName: item.userData.secondName,
                        },
                        false,
                        true
                      )}
                    </Typography.Text>
                  </Col>
                </Space>
              </Row>
              <Row style={{ flexFlow: 'column nowrap', marginTop: '32px' }}>
                <Typography.Title level={5} style={{ marginBottom: '8px' }}>
                  Рейтинг
                </Typography.Title>
                <Col span={24}>
                  <Rate disabled defaultValue={item.rating} />
                </Col>
              </Row>
              <Row style={{ flexFlow: 'column nowrap', marginTop: '32px' }}>
                <Typography.Title level={5} style={{ marginBottom: '8px' }}>
                  Дата и время
                </Typography.Title>
                <Col span={24}>
                  <Typography.Text>
                    {dayjs(item.createdAt).format('DD.MM.YY, HH:mm')}
                  </Typography.Text>
                </Col>
              </Row>
            </Content>
          </div>
        ) : null
      }
    >
      {item.id ? (
        <>
          {item.status !== StatusesEnum.PUBLISHED && (
            <Content
              style={{
                height: '90px',
                marginBottom: '24px',
                paddingTop: '13px',
                paddingBottom: '18px',
              }}
            >
              <Row
                justify='space-between'
                style={{ flexFlow: 'column nowrap', height: '100%' }}
              >
                <Row>
                  <Typography.Text type='secondary'>
                    Модерация контента
                  </Typography.Text>
                </Row>
                <Row>
                  <Space>
                    <Button type='primary' onClick={handlePublish}>
                      Опубликовать
                    </Button>
                  </Space>
                </Row>
              </Row>
            </Content>
          )}
          <Content>
            <Space direction='vertical' size='large' style={{ width: '100%' }}>
              <Row wrap={false} className={styles.rowLayout}>
                <Col style={{ flexShrink: 0, marginRight: '32px' }}>
                  <Image
                    width={200}
                    height={200}
                    preview={false}
                    src={
                      item?.entityInfo.heroImage
                        ? getImageUrl(item.entityInfo.heroImage, 200, 200) ||
                          '/public/images/no-image.svg'
                        : '/public/images/no-image.svg'
                    }
                    fallback='/public/images/no-image.svg'
                    style={{ flexShrink: 0 }}
                  />
                </Col>
                <Col style={{ minWidth: 0 }}>
                  <Typography.Title level={4} style={{ marginBottom: '15px' }}>
                    {item.entityInfo.name}
                  </Typography.Title>

                  {item.entityType && (
                    <Row align='middle' style={{ marginTop: '15px' }}>
                      <Typography.Text
                        style={{ fontSize: '16px', lineHeight: '24px' }}
                      >
                        {entityTypeNames[item.entityType]}
                      </Typography.Text>
                    </Row>
                  )}
                  <Row align='middle' style={{ marginTop: '15px' }}>
                    <Button
                      type='primary'
                      ghost
                      style={{ height: '28px', padding: '0px 8px' }}
                    >
                      <Typography.Link
                        href={`${b2cUrl}/${
                          entityTypeForUrlHash[item.entityType]
                        }/${item.entityId}`}
                        target='_blank'
                      >
                        Перейти
                      </Typography.Link>
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Title level={5} style={{ marginBottom: '0px' }}>
                  Текст отзыва
                </Typography.Title>
                <Row align='middle' style={{ marginTop: '16px' }}>
                  <Typography.Text style={{ wordBreak: 'break-word' }}>
                    {item.content}
                  </Typography.Text>
                </Row>
              </Row>
              {item.gallery?.length > 0 && (
                <Row>
                  <Typography.Title level={5}>Фотогалерея</Typography.Title>
                  <ViewGallery gallery={item.gallery} />
                </Row>
              )}
            </Space>
          </Content>
          <Collapse
            bordered={false}
            expandIconPosition='end'
            onChange={(value: string[]) => setActiveCollpaseTabs(value)}
            activeKey={activeCollpaseTabs}
          >
            <Collapse.Panel header='Ответ на отзыв' key='reply'>
              <Row gutter={[24, 0]}>
                <Col flex={1}>
                  <Typography.Text>{item.reply}</Typography.Text>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </>
      ) : null}
      <>
        {modalIsOpen && (
          <StickyConfirmModal
            isOpen={modalIsOpen}
            onRequestClose={modalCloseHandler}
            title='Удаление места'
            okText='Удалить'
            text={
              <div>
                Вы хотите убрать с портала и переместить в Корзину отзыв{' '}
                <Typography.Text strong>
                  {item.entityInfo.name || ''}
                </Typography.Text>
                ?
              </div>
            }
            onSubmit={() => handleDelete(id)}
            okButtonProps={{ danger: true }}
          />
        )}
      </>
    </MainLayout>
  );
};
