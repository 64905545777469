import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, notification, Row, Spin } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  getAboutRegion,
  updateAboutRegion,
} from 'store/slices/aboutRegion/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { LangEnumKeys } from 'constants/lang';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { MainLayout } from 'components/Layout/MainLayout';
import { TContentBlock } from 'components/form/Content/contentTypes';
import { PageTitles } from 'constants/pageTitles';
import { prepareContent } from 'components/form/Content/utils';

import { DescriptionForm } from './DescriptionForm';
import { validationSchema, mapValuesToForm, mapValues } from '../../utils';

interface IFormValues {
  lang: { value: LangEnumKeys; label: string };
  content: TContentBlock[];
}

export const Description = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const data = useAppSelector(state => state.aboutRegion);

  const methods = useForm<IFormValues>({
    defaultValues: {
      content: prepareContent([]),
      lang: { value: 'ru', label: 'Русский' },
    },
    resolver: yupResolver(validationSchema),
  });

  const loadData = () => {
    dispatch(getAboutRegion({ lang: lang.value }))
      .unwrap()
      .then(data => {
        methods.reset({ ...methods.getValues(), ...mapValuesToForm(data) });
      });
  };
  const handleCancel = () => {
    methods.reset();
  };
  const onSubmit = useCallback((values: IFormValues) => {
    setIsLoading(true);
    dispatch(updateAboutRegion(mapValues(values)))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    loadData();
  }, [lang]);

  return (
    <MainLayout pageTitle={PageTitles.description}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className='ant-form ant-form-vertical indent-top'
        >
          <LangSelectSection />
          {data.isLoading ? (
            <Row justify='center'>
              <Col>
                <Spin size='large' />
              </Col>
            </Row>
          ) : (
            <DescriptionForm
              handleCancel={handleCancel}
              isLoading={isLoading}
            />
          )}
        </form>
      </FormProvider>
    </MainLayout>
  );
};
