import { TRespondsItemData } from 'store/slices/responds/interfaces';
import { OrganizationsItem, OrganizationTypeEnum } from 'types/organizations';

export const getShortName = (name: string | null): string => {
  if (!name) {
    return '';
  }
  const [type, lastName, firstName, secondName] = name.split(' ');
  return `${type} ${lastName} ${firstName[0]}.${secondName[0]}.`;
};

export const getOrganizationInfo = (
  item?: TRespondsItemData,
  organizationItem?: OrganizationsItem
): string => {
  const result = [];

  if ('organizationId' in item && item.organizationId) {
    if (
      organizationItem?.type === OrganizationTypeEnum.organization &&
      organizationItem?.fullName
    ) {
      result.push(organizationItem.fullName);
    }

    if (
      organizationItem?.type === OrganizationTypeEnum.ip &&
      organizationItem?.dadataInfo?.name
    ) {
      result.push(getShortName(organizationItem?.dadataInfo?.name));
    }

    /** показываем только ИНН, если пользователь не заполнил организацию и сделал заявку **/
    result.push(organizationItem?.inn);
  } else {
    result.push('-');
  }

  return result.join(', ');
};
