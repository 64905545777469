import { createSlice } from '@reduxjs/toolkit';

import { IAlertState } from './interfaces';
import { getAlerts, setAlert } from './actions';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alerts: [],
    isLoading: true,
  } as IAlertState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAlerts.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getAlerts.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAlerts.fulfilled, (state, { payload }) => {
      state.alerts = payload.data;
      state.isLoading = false;
    });
    builder.addCase(setAlert.fulfilled, (state, { payload }) => {
      state.alerts = state.alerts.map(alert =>
        alert.id === payload.id ? payload : alert
      );
    });
  },
});
