import dayjs, { Dayjs } from 'dayjs';

import { defaultDateFormat } from 'components/form/base/RangePicker';
import {
  PurchasePlansItem,
  PurchasePlansItemRequest,
} from 'store/slices/purchasePlans/interfaces';
import { omit } from 'utils/helpers';
import { MaterialFileType } from 'components/form/AddMaterial/components/MaterialItem/types';
import { DeepPartial } from 'types/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import { mapSelect } from 'utils/mappings';

import { FormValues } from './types';

export const mapValues = ({
  name,
  year,
  material,
  status,
  ...values
}: FormValues): PurchasePlansItemRequest => ({
  ...values,
  name: name.trim(),
  year: dayjs(year, defaultDateFormat).get('y'),
  material: material as Omit<MaterialFileType, 'displayName'>,
  status: mapSelect(status) as Statuses,
});

export const mapValuesToForm = ({
  name,
  year,
  status,
  ...item
}: PurchasePlansItem): FormValues => ({
  ...omit(item, [
    'author',
    'creator',
    'editor',
    'publishedAt',
    'createdAt',
    'updatedAt',
    'lang',
  ]),
  name: name || '',
  year: dayjs().year(Number(year)),
  status: status === StatusesEnum.DRAFT ? StatusesEnum.PUBLISHED : status,
});

export const mapValuesAsDraft = ({
  name,
  year,
  ...values
}: DeepPartial<FormValues>): DeepPartial<PurchasePlansItem> => ({
  ...omit(values, ['status']),
  name: name?.trim() || null,
  year: year ? dayjs(year as Dayjs, defaultDateFormat).get('y') : null,
});
