import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';

import { contactsSchema, imageSchema, seoValidationSchema } from 'utils/yup';
import {
  mapGallery,
  mapImage,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  TInvestmentPassportsDraftItem,
  IInvestmentPassportsItem,
} from 'store/slices/investmentPassports/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { defaultDateFormat } from 'components/form/base/RangePicker';
import { stripTagsAndTrim, isNumber } from 'utils/helpers';

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    region: values.region ? mapValuesToRegion(values.region) : null,
    year: values.year ? dayjs().year(Number(values.year)) : null,
    month: isNumber(values.month) ? dayjs().month(Number(values.month)) : null,
    content: values.content
      ? [{ type: 'text', text: values.content }]
      : prepareContent([]),
    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 80 символов')
      .max(80, 'Введите от 1 до 80 символов')
      .required(),
    image: imageSchema,
    region: Yup.mixed().required(),
    year: Yup.mixed().required(),
    month: Yup.mixed().required(),
    gallery: Yup.array().of(imageSchema),
    content: Yup.array().of(
      Yup.object().shape({
        type: Yup.mixed(),
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);
            if (!filteredText) return true;

            return filteredText.length >= 100 && filteredText.length <= 2500;
          },
          message: 'Введите от 100 до 2500 символов',
        }),
      })
    ),
    status: Yup.mixed().required(),
    contacts: contactsSchema,
  })
  .concat(seoValidationSchema);

export const mapValues = (values): IInvestmentPassportsItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    image: mapImage(values.image),
    region: mapRegion(values.region),
    year: dayjs(values.year, defaultDateFormat).get('y'),
    month: dayjs(values.month, defaultDateFormat).get('M'),
    gallery: mapGallery(values.gallery),
    content: values.content[0].text || null,
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    status: mapSelect(values.status) as Statuses,
  };
};

export const mapValuesToDraft = (values): TInvestmentPassportsDraftItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name?.trim() || null,
    image: values.image ? mapImage(values.image) : null,
    region: values.region ? mapRegion(values.region) : null,
    year: values.year
      ? dayjs(values.year as Dayjs, defaultDateFormat).get('y')
      : null,
    month: values.month
      ? dayjs(values.month as Dayjs, defaultDateFormat).get('M')
      : null,
    gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
    content:
      values.content?.length && values.content[0].text
        ? values.content[0].text
        : null,
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
  };
};
