import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';

import { IPerson } from '../organizations/interfaces';
import { TDocument } from '../reviews/interfaces';

export type TInvestInfrastructureRespondsItem = {
  id?: string;
  name?: string;
  createdAt: string;
  updatedAt: string;
  phone?: string;
  message: string;
  investInfrastructureId: string;
  fromPage: string;
  investInfrastructure: string | null;
};
export type TSupportRespondsItem = {
  id?: string;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  updatedAt: string;
  message: string;
  email?: string;
  status: string;
  materials?: MaterialValue[];
  userId: number;
  organizationId?: number;
  responseId?: string;
  completedAt: string;
};
export type TTaxBenefitsRespondsItem = {
  id?: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  message: string;
  email?: string;
  status: string;
  materials?: MaterialValue[];
  userId: number;
  organizationId?: number;
  responseId: string;
  completedAt: string;
};
export type TContactsRespondsItem = {
  id?: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  message: string;
  email?: string;
};

export type TMobileRespondsItem = {
  id: string;
  createdAt: string;
  updatedAt: string;
  email?: string;
  files?: TDocument[];
  resort: string;
  theme: string;
  userData?: IPerson;
  user: number;
  message: string;
};

export type TRespondsItemData =
  | TInvestInfrastructureRespondsItem
  | TSupportRespondsItem
  | TTaxBenefitsRespondsItem
  | TContactsRespondsItem
  | TMobileRespondsItem;

export type TRespondsState = {
  list: TRespondsItemData[];
  item: TRespondsItemData;
  limit: number;
  total: number;
  offset: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  loading?: boolean;
};

export enum RespondsTypesEnum {
  investInfrastructureResponds = 'investInfrastructureResponds',
  contactsResponds = 'contactsResponds',
  supportResponds = 'supportResponds',
  taxBenefitsResponds = 'taxBenefitsResponds',
  taxBenefitsCommonResponds = 'taxBenefitsCommonResponds',
  mobileResponds = 'mobileResponds',
}
export enum RespondsQueryForTaxBenefitsEnum {
  portal = 'portal',
  cabinet = 'cabinet',
}
export enum RespondsInvestInfrastructureTypesOfPagesEnum {
  investPage = 'investPage',
  mainPage = 'mainPage',
  readySolutions = 'readySolutions',
}
export type TRespondsTypes = `${RespondsTypesEnum}`;
