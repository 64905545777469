// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldsList-module__removeButton--Pa0tj {\n  border: none!important;\n  height: auto!important;\n}\n.FieldsList-module__field--iivYc :last-of-type.ant-input-group-addon {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/FieldsList/FieldsList.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;AACF;AACA;EAEI,UAAA;AAAJ","sourcesContent":[".removeButton {\n  border: none!important;\n  height: auto!important;\n}\n.field{\n  :global(:last-of-type.ant-input-group-addon){\n    padding: 0\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeButton": "FieldsList-module__removeButton--Pa0tj",
	"field": "FieldsList-module__field--iivYc"
};
export default ___CSS_LOADER_EXPORT___;
