import React from 'react';
import { Button, Card, Col, Row } from 'antd';

import { FormActions } from 'components/Layout/FormActions/FormActions';
import { ContentField } from 'components/form/Content/ContentField';

interface IProps {
  isLoading: boolean;
  handleCancel: () => void;
}

export const DescriptionForm: React.FC<IProps> = ({
  handleCancel,
  isLoading,
}) => {
  return (
    <Card title='Общая информация'>
      <Row>
        <Col span={24}>
          <ContentField
            name='content'
            label='Описание'
            placeholder='Начните печатать текст'
            required
          />
        </Col>
      </Row>
      <FormActions>
        <Button onClick={handleCancel} disabled={isLoading}>
          Отмена
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          type='primary'
          htmlType='submit'
        >
          Сохранить
        </Button>
      </FormActions>
    </Card>
  );
};
