import React from 'react';
import { Modal, Upload, UploadFile } from 'antd';

import { IDocument } from 'store/slices/formDocuments/interfaces';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import styles from './FormCommentsFiles.module.less';

export const IMAGES_TYPES = ['jpg', 'png', 'jpeg'];

type TProps = {
  data: IDocument[];
  onRemove?: (file: UploadFile) => void;
};

export const FormCommentsFiles: React.FC<TProps> = ({ data, onRemove }) => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewTitle, setPreviewTitle] = React.useState('');

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const imageList = React.useMemo(() => {
    return data
      .filter(({ document }) => {
        const type = document.realName.split('.').slice(-1)[0];
        return IMAGES_TYPES.includes(type);
      })
      .map(({ document: { path, realName } }) => ({
        uid: path,
        name: realName,
        status: 'done',
        url: `${UPLOAD_SERVICE_BASE_URL}/${path}`,
      })) as UploadFile[];
  }, [data]);

  const fileList = React.useMemo(() => {
    return data
      .filter(({ document }) => {
        const type = document.realName.split('.').slice(-1)[0];
        return !IMAGES_TYPES.includes(type);
      })
      .map(({ document: { path, realName } }) => ({
        uid: path,
        name: realName,
        status: 'done',
        url: `${UPLOAD_SERVICE_BASE_URL}/${path}`,
      })) as UploadFile[];
  }, [data]);

  return (
    <div>
      {!!imageList.length && (
        <div className={styles.imageContainer}>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
            className={styles.modal}
          >
            <img
              alt='attached_img'
              style={{ width: '100%' }}
              src={previewImage}
            />
          </Modal>
          <Upload
            listType='picture-card'
            fileList={imageList}
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: !!onRemove,
            }}
            onRemove={file => {
              onRemove && onRemove(file);
            }}
          />
        </div>
      )}
      <Upload
        listType='text'
        fileList={fileList}
        showUploadList={{
          showRemoveIcon: !!onRemove,
        }}
        className={styles.fileUploader}
        onRemove={file => {
          onRemove && onRemove(file);
        }}
      />
    </div>
  );
};
