export enum ApplicationsTypes {
  application = 'application',
  project = 'project',
}

export enum LandPlotApplicationsTypes {
  landLeaseApplications = 'landLeaseApplications',
  landPlotUrbanPlanningApplications = 'landPlotUrbanPlanningApplications',
  connectionNetworksItoApplications = 'connectionNetworksItoApplications',
  leaseRealEstateNonResidentApplications = 'leaseRealEstateNonResidentApplications',
  cadastralWorkApplications = 'cadastralWorkApplications',
  projects = 'projects',
}

export type TLandPlotApplicationsTypes = `${LandPlotApplicationsTypes}`;

export const applicationsTypesNameHash = {
  [LandPlotApplicationsTypes.landLeaseApplications]:
    'Предоставление земельного участка в аренду',
  [LandPlotApplicationsTypes.landPlotUrbanPlanningApplications]:
    'Предоставление градостроительного плана земельного участка',
  [LandPlotApplicationsTypes.connectionNetworksItoApplications]:
    'Присоединение к сетям инженерно-технического обеспечения',
  [LandPlotApplicationsTypes.leaseRealEstateNonResidentApplications]:
    'Получение в аренду недвижимого имущества нерезидентами',
  [LandPlotApplicationsTypes.cadastralWorkApplications]:
    'Согласованиe кадастровых работ на арендуемом земельном участке',
  [LandPlotApplicationsTypes.projects]: 'Проект',
};
