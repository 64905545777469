import { createSlice } from '@reduxjs/toolkit';

import { IOpeningInfoState } from './interfaces';
import {
  loadOpeningInfo,
  loadChapters,
  updateOpeningInfo,
  updateChapter,
  loadStrategies,
  updateStrategy,
  updateAnnual,
  loadAnnual,
  loadConditions,
  updateCondition,
  loadLandmark,
  updateLandmark,
  loadNestedItems,
  updateNestedItem,
} from './actions';

export const openingInfoSlice = createSlice({
  name: 'openingInfo',
  initialState: {
    description: '',
    chapters: {
      list: [],
      total: 0,
      nestedItems: {
        list: [],
        total: 0,
      },
    },
    strategies: {
      list: [],
      total: 0,
    },
    annual: {
      list: [],
      total: 0,
    },
    conditions: {
      list: [],
      total: 0,
    },
    landmark: {
      list: [],
      total: 0,
    },
  } as IOpeningInfoState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOpeningInfo.fulfilled, (state, { payload }) => {
      state.description = payload.description;
    });
    builder.addCase(updateOpeningInfo.fulfilled, (state, { payload }) => {
      state.description = payload.description;
    });
    builder.addCase(
      loadChapters.fulfilled,
      (state, { payload: { data, total } }) => {
        state.chapters.list = data;
        state.chapters.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadChapters.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadChapters.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateChapter.fulfilled, (state, { payload }) => {
      state.chapters.list = state.chapters.list.map(item =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      loadStrategies.fulfilled,
      (state, { payload: { data, total } }) => {
        state.strategies.list = data;
        state.strategies.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadStrategies.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadStrategies.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStrategy.fulfilled, (state, { payload }) => {
      state.strategies.list = state.strategies.list.map(item =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      loadAnnual.fulfilled,
      (state, { payload: { data, total } }) => {
        state.annual.list = data;
        state.annual.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadAnnual.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadAnnual.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateAnnual.fulfilled, (state, { payload }) => {
      state.annual.list = state.annual.list.map(item =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      loadConditions.fulfilled,
      (state, { payload: { data, total } }) => {
        state.conditions.list = data;
        state.conditions.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadConditions.rejected, state => {
      state.conditions.list = [];
      state.conditions.total = 0;
      state.loading = false;
    });
    builder.addCase(loadConditions.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateCondition.fulfilled, (state, { payload }) => {
      state.conditions.list = state.conditions.list.map(item =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      loadLandmark.fulfilled,
      (state, { payload: { data, total } }) => {
        state.landmark.list = data;
        state.landmark.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadLandmark.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadLandmark.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateLandmark.fulfilled, (state, { payload }) => {
      state.landmark.list = state.landmark.list.map(item =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      loadNestedItems.fulfilled,
      (state, { payload: { data, total } }) => {
        state.chapters.nestedItems.list = data;
        state.chapters.nestedItems.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadNestedItems.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadNestedItems.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateNestedItem.fulfilled, (state, { payload }) => {
      state.chapters.nestedItems.list = state.chapters.nestedItems.list.map(
        item => (item.id === payload.id ? payload : item)
      );
    });
  },
});
