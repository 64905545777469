import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  ISupportMeasuresItem,
  TSupportMeasuresItemDraft,
} from 'store/slices/supportMeasures/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadSupportMeasuresItem,
  saveSupportMeasuresItem,
  saveSupportMeasuresItemAsDraft,
} from 'store/slices/supportMeasures/actions';
import { LangEnum } from 'constants/lang';

import { SupportMeasuresForm } from './components/SupportMeasuresForm';
import { mapValuesToForm } from './components/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.supportMeasures);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadSupportMeasuresItem(id));
  }, []);

  const onFinish = (values: ISupportMeasuresItem) => {
    setIsLoading(true);
    dispatch(saveSupportMeasuresItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/supportMeasures');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TSupportMeasuresItemDraft) => {
    setIsLoading(true);
    dispatch(saveSupportMeasuresItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/supportMeasures');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/supportMeasures');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    initiator: null,
    initiatorData: null,
    supportLevel: null,
    supportLevelData: null,
    supportPeriod: null,
    supportPeriodData: null,
    industry: [],
    industryData: null,
    supportFormat: null,
    supportFormatData: null,
    supportType: null,
    supportTypeData: null,
    supportProblems: null,
    supportProblemsData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item.id && item.id === Number(id) ? (
        <SupportMeasuresForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
