import * as Yup from 'yup';

import { ICardItem } from 'store/slices/b2bCard/interfaces';
import { mapContent, mapContentToForm } from 'utils/mappings';
import { contentSchema } from 'utils/yup';
import { LangEnum } from 'constants/lang';
import { prepareContent } from 'components/form/Content/utils';

import { IAboutRegion } from '../../store/slices/aboutRegion/interfaces';

export const mapCardItemValues = (values: ICardItem): ICardItem => {
  return {
    id: values.id,
    description: values.description || '',
    sortPriority: values.sortPriority || null,
    subtitle: values.subtitle || '',
    link: values.link || '',
    title: values.title || '',
  };
};

export const mapValues = (values): IAboutRegion => {
  return {
    lang: values.lang.value,
    content: mapContent(values.content),
  };
};

export const mapValuesToForm = values => {
  return {
    content: prepareContent(mapContentToForm(values.content || [])),
  };
};

export const validationSchema = Yup.object().shape({
  content: contentSchema,
});
