export const ERROR_CODE_KEY = 'UPLOADS_UNSUPPORTED_FILE_TYPE';

export const DEFAULT_ACCEPT = '.doc, .docx, .pdf, .jpg, .png, .jpeg';

export const VALID_FORMATS_LIST = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
