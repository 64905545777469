// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__wrapper--dOfmJ {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.Header-module__container--v52Qi {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n@media (max-width: 800px) {\n  .Header-module__container--v52Qi {\n    flex-wrap: wrap-reverse;\n  }\n}\n.Header-module__title--sETWV {\n  padding: 24px 24px 0 24px;\n  width: calc(100% - 200px);\n  word-break: break-all;\n}\n@media (max-width: 800px) {\n  .Header-module__title--sETWV {\n    min-width: 100%;\n  }\n}\n.Header-module__actions--aFO91 {\n  display: flex;\n  gap: 4px;\n  flex-wrap: nowrap;\n  padding: 12px 24px;\n}\n.Header-module__toggleVisibleButton--IWMd7 {\n  margin-left: 12px;\n}\n.Header-module__file--zF5Rh {\n  padding: 0 24px 24px 24px;\n  word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/AddMaterial/components/Header/Header.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AAEA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAAF;AAEE;EAAA;IACE,uBAAA;EACF;AACF;AAEA;EACE,yBAAA;EACA,yBAAA;EACA,qBAAA;AAAF;AAEE;EAAA;IACE,eAAA;EACF;AACF;AAEA;EACE,aAAA;EACA,QAAA;EACA,iBAAA;EACA,kBAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,yBAAA;EACA,qBAAA;AAFF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n\n  @media (max-width: 800px) {\n    flex-wrap: wrap-reverse;\n  }\n}\n\n.title {\n  padding: 24px 24px 0 24px;\n  width: calc(100% - 200px);\n  word-break: break-all;\n\n  @media (max-width: 800px) {\n    min-width: 100%;\n  }\n}\n\n.actions {\n  display: flex;\n  gap: 4px;\n  flex-wrap: nowrap;\n  padding: 12px 24px;\n}\n\n.toggleVisibleButton {\n  margin-left: 12px;\n}\n\n.file {\n  padding: 0 24px 24px 24px;\n  word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Header-module__wrapper--dOfmJ",
	"container": "Header-module__container--v52Qi",
	"title": "Header-module__title--sETWV",
	"actions": "Header-module__actions--aFO91",
	"toggleVisibleButton": "Header-module__toggleVisibleButton--IWMd7",
	"file": "Header-module__file--zF5Rh"
};
export default ___CSS_LOADER_EXPORT___;
