import * as Yup from 'yup';

import {
  IReviewsItemResponse,
  TDocument,
} from 'store/slices/reviews/interfaces';
import { Statuses } from 'constants/status';
import { mapSelect } from 'utils/mappings';

export const mapValuesToForm = (values): IReviewsItemResponse => {
  return {
    ...values,
    content: values.content || '',
    gallery: values.gallery ? values.gallery : [],
    reply: values.reply || '',
    status: values.status,
  };
};

export const validationSchema = Yup.object().shape({
  content: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 1000 символов')
    .max(1000, 'Введите от 1 до 1000 символов')
    .required(),
  reply: Yup.string()
    .trim()
    .test({
      test: function (text) {
        if (text) {
          return text.length >= 3 && text.length <= 800;
        }

        return true;
      },
      message: 'Введите от 3-x до 800 символов',
    }),
  status: Yup.mixed().required(),
});

export const mapValues = (
  values
): {
  content: string;
  reply?: string;
  gallery?: TDocument[];
  status: Statuses;
} => {
  return {
    content: values.content,
    gallery: values.gallery ? values.gallery : [],
    reply: values.reply,
    status: mapSelect(values.status) as Statuses,
  };
};
