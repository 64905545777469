// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 480px) {\n  .View-module__rowLayout--qPGJv {\n    flex-direction: column;\n  }\n}\n@media (max-width: 480px) {\n  .View-module__contentHeadRow--fCtKV {\n    margin-bottom: 8px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reviews/View/View.module.less"],"names":[],"mappings":"AACI;EAAA;IACE,sBAAA;EACJ;AACF;AAEI;EAAA;IACE,kBAAA;EACJ;AACF","sourcesContent":[".rowLayout {\n    @media (max-width: 480px) {\n      flex-direction: column;\n    }\n  }\n  .contentHeadRow {\n    @media (max-width: 480px) {\n      margin-bottom: 8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowLayout": "View-module__rowLayout--qPGJv",
	"contentHeadRow": "View-module__contentHeadRow--fCtKV"
};
export default ___CSS_LOADER_EXPORT___;
