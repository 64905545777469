import React from 'react';
import {
  AutoComplete,
  AutoCompleteProps,
  Form,
  FormItemProps,
  Input,
} from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { TextAreaProps } from 'antd/lib/input';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { debounce, isString } from 'utils/helpers';

type IProps = FormItemProps<AutoCompleteProps<TextAreaProps>> &
  Omit<AutoCompleteProps<TextAreaProps>, 'name' | 'placeholder'> & {
    name: string;
    params: Record<string, string>;
    rows?: number;
    nameSearchField?: string;
    url?: string;
    placeholder?: string;
  };

export const TextAreaAutoCompleteField: React.FC<IProps> = ({
  label,
  name,
  params,
  required,
  rows = 4,
  tooltip = undefined,
  extra = undefined,
  nameSearchField = 'pattern',
  url = urls.api.suggest.get,
  placeholder,
  ...props
}) => {
  const [options, setOptions] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const isFirstFocus = React.useRef<boolean>(false);

  const { control } = useFormContext();

  const getPanelValue = (pattern: string) =>
    apiClient
      .get(url, {
        params: {
          [nameSearchField]: pattern,
          ...params,
        },
      })
      .then(res => {
        const arrStrValue = (
          res.data.values as (string | Record<string, string>)[]
        ).map(item => (isString(item) ? item : Object.values(item)[0]));

        const newOptions = Array.from(new Set(arrStrValue)).map(item => ({
          value: item,
          label: item,
        }));

        setOptions(newOptions);
      })
      .catch(() => []);

  const handleSearch = React.useCallback(
    debounce((value: string) => getPanelValue(value), 400),
    []
  );

  const handleFocus = (value: string) =>
    !isFirstFocus.current &&
    getPanelValue(value).finally(() => (isFirstFocus.current = true));

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          required={required}
          tooltip={tooltip}
          extra={extra}
        >
          <AutoComplete
            onSelect={(value: string) => field.onChange(value)}
            onSearch={handleSearch}
            options={options}
            onFocus={() => handleFocus(field.value)}
            filterOption
            {...field}
            {...props}
          >
            <Input.TextArea
              placeholder={placeholder}
              autoComplete='off'
              rows={rows}
            />
          </AutoComplete>
        </Form.Item>
      )}
      control={control}
    />
  );
};
