import { createSlice } from '@reduxjs/toolkit';

import { IHotelsState } from './interfaces';
import { loadHotelsItem, loadHotelsList } from './actions';

export const hotelsSlice = createSlice({
  name: 'hotels',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
    isLoading: false,
  } as IHotelsState,
  reducers: {
    resetHotelItem: state => {
      state.item = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadHotelsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
        state.isLoading = false;
      }
    );
    builder.addCase(loadHotelsList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loadHotelsList.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(loadHotelsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});

export const { resetHotelItem } = hotelsSlice.actions;
