import React from 'react';

type TProps = {
  node?: Element;
  targetWidth: number;
};

/* Данный хук проверяет имеет ли элемент(node) ширину в пределах до targetWidth */

export const useCheckNodeWidth = ({ node, targetWidth }: TProps): boolean => {
  const [width, setWidth] = React.useState<null | number>(null);
  const [isWidthReached, setIsWidthReached] = React.useState(false);

  React.useEffect(() => {
    if (node) {
      const handleResize = () => {
        setWidth(node.clientWidth);
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(node);

      return () => resizeObserver.unobserve(node);
    }
  }, [node]);

  React.useEffect(() => {
    if (width < targetWidth) {
      setIsWidthReached(true);
    } else {
      setIsWidthReached(false);
    }
  }, [width]);

  return isWidthReached;
};
