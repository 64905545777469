import React from 'react';
import { Typography } from 'antd';

export const DefaultTooltip = () => {
  return (
    <div>
      <Typography.Text className='white_text' strong>
        Требования к фото:
      </Typography.Text>
      <ul>
        <li>максимальный размер - 1 МБ</li>
        <li>расширение - .png, .jpg, .jpeg</li>
      </ul>
    </div>
  );
};
