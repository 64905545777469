import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Typography } from 'antd';

import { useAppSelector } from 'store';

import { UserInfo } from './components/UserInfo/UserInfo';
import { UserOrganizations } from '../UserOrganizations/UserOrganizations';

export const ViewContent = () => {
  const {
    item: { firstName, secondName, lastName },
  } = useAppSelector(state => state.business);

  return (
    <Content>
      <Typography.Title
        style={{ fontWeight: 400, marginBottom: '24px' }}
        level={4}
      >{`${lastName} ${firstName} ${secondName}`}</Typography.Title>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px 0' }}>
        <UserOrganizations />
        <UserInfo />
      </div>
    </Content>
  );
};
