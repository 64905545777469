import { createAsyncThunk } from '@reduxjs/toolkit';
import querystring from 'query-string';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IMenuRequestBody,
  IMenuRequestParams,
  IMenuResponse,
} from './interfaces';

export const loadMenu = createAsyncThunk<IMenuResponse[], IMenuRequestParams>(
  'menu/loadItem',
  async params => {
    const { data } = await apiClient.get<IMenuResponse[]>(
      `${urls.api.menu.get}?${querystring.stringify(params)}`
    );
    return data;
  }
);

export const saveMenu = createAsyncThunk<unknown, IMenuRequestBody>(
  'menu/saveItem',
  async body => {
    const { data } = await apiClient.put(urls.api.menu.put, {
      ...body,
    });
    return data;
  }
);
