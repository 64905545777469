import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { IAttractionAddress } from 'types/address';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import { IInfrastructuresItem } from 'store/slices/infrastructures/interfaces';
import { InfrastructureCategoriesSelector } from 'components/form/selects/InfrastructureCategoriesSelector';
import { LangEnumKeys } from 'constants/lang';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';

import { mapValues, validationSchema } from './formUtils';

interface IFormValues {
  name: string;
  category: number;
  address: IAttractionAddress;
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IInfrastructuresItem) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

export const InfrastructuresForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'address',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={1} style={{ minWidth: 200 }}>
              <InputField
                name='name'
                label='Название инфраструктуры'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <InfrastructureCategoriesSelector
                name='category'
                label='Категория'
                placeholder='Выберите категорию'
                required
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Адрес' key='address'>
            <LocationSelector name='address' />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
