// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadDoc-module__uploadField--DumYr {\n  display: inline-block;\n  max-width: 100%;\n  margin-bottom: 25px;\n}\n.UploadDoc-module__uploadField--DumYr .ant-upload-list-item {\n  margin-top: 25px;\n  pointer-events: none;\n}\n.UploadDoc-module__uploadField--DumYr .anticon-paper-clip {\n  font-size: 20px!important;\n}\n.UploadDoc-module__tooltipButton--Lk2tL {\n  cursor: help;\n  margin-left: 5px;\n}\n.UploadDoc-module__tooltipList--GG8RH {\n  list-style: disc inside;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/UploadDoc/UploadDoc.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,eAAA;EACA,mBAAA;AACF;AAJA;EAKI,gBAAA;EACA,oBAAA;AAEJ;AARA;EASI,yBAAA;AAEJ;AACA;EACE,YAAA;EACA,gBAAA;AACF;AACA;EACE,uBAAA;AACF","sourcesContent":[".uploadField {\n  display: inline-block;\n  max-width: 100%;\n  margin-bottom: 25px;\n  :global(.ant-upload-list-item){\n    margin-top: 25px;\n    pointer-events: none;\n  }\n  :global(.anticon-paper-clip) {\n    font-size: 20px!important;\n  }\n}\n.tooltipButton{\n  cursor: help;\n  margin-left:5px;\n}\n.tooltipList{\n  list-style: disc inside;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadField": "UploadDoc-module__uploadField--DumYr",
	"tooltipButton": "UploadDoc-module__tooltipButton--Lk2tL",
	"tooltipList": "UploadDoc-module__tooltipList--GG8RH"
};
export default ___CSS_LOADER_EXPORT___;
