// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PolylineModal-module__cancelModal--C_GwX {\n  margin-top: 20%;\n}\n.PolylineModal-module__warnIcon--AVAHT {\n  color: #faad14;\n  margin-right: 18px;\n  font-size: 21px;\n}\n.PolylineModal-module__warnModalInfo--vs5OF {\n  display: flex;\n  flex-direction: column;\n}\n.PolylineModal-module__warnModal--hhDd0 {\n  display: flex;\n}\n.PolylineModal-module__warnModalTitle--QrJow {\n  font-weight: 500;\n}\n.PolylineModal-module__editModal--RMBr3 {\n  height: 600px;\n}\n@media (max-width: 800px) {\n  .PolylineModal-module__editModal--RMBr3 {\n    height: 354px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/InvestmentInfrastructures/components/PolylineModal/PolylineModal.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAEA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,aAAA;AAJF;AAME;EAAA;IACE,aAAA;EAHF;AACF","sourcesContent":[".cancelModal {\n  margin-top: 20%;\n}\n\n.warnIcon {\n  color: #faad14;\n  margin-right: 18px;\n  font-size: 21px;\n}\n\n.warnModalInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.warnModal {\n  display: flex;\n}\n\n.warnModalTitle {\n  font-weight: 500;\n}\n\n.editModal {\n  height: 600px;\n\n  @media (max-width: 800px) {\n    height: 354px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelModal": "PolylineModal-module__cancelModal--C_GwX",
	"warnIcon": "PolylineModal-module__warnIcon--AVAHT",
	"warnModalInfo": "PolylineModal-module__warnModalInfo--vs5OF",
	"warnModal": "PolylineModal-module__warnModal--hhDd0",
	"warnModalTitle": "PolylineModal-module__warnModalTitle--QrJow",
	"editModal": "PolylineModal-module__editModal--RMBr3"
};
export default ___CSS_LOADER_EXPORT___;
