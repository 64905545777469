import React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';

import { IReport } from 'store/slices/reports/interfaces';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { reportName } from 'constants/reports';

interface IProps {
  onRemove: () => void;
  onCloseModalAction: () => void;
  modalIsOpen: boolean;
  currentItem: IReport;
}

export const ConfirmModal = ({
  onRemove,
  onCloseModalAction,
  modalIsOpen,
  currentItem,
}: IProps) => (
  <StickyConfirmModal
    onRequestClose={onCloseModalAction}
    onSubmit={onRemove}
    isOpen={modalIsOpen}
    text={
      <div>
        Вы хотите убрать с портала и переместить в Корзину отчетность{' '}
        <Typography.Text strong>
          {dayjs(currentItem.createdAt).format('DD.MM.YYYY hh:mm')}
        </Typography.Text>{' '}
        <Typography.Text strong>{reportName[currentItem.type]}</Typography.Text>
        ?
      </div>
    }
    okButtonProps={{ danger: true }}
    title='Удаление отчетности'
    okText='Удалить'
  />
);
