import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Typography } from 'antd';

import { IFormValues } from 'pages/PriorityIndustry/components/types';

import styles from './Header.module.less';

interface IProps {
  index: number;
}

export const Header = ({ index }: IProps) => {
  const { control } = useFormContext<IFormValues>();

  const title = useWatch({
    control,
    name: `direction.${index}.title`,
  });

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title}>
        <Typography.Text className={styles.number}>
          {index + 1}.
        </Typography.Text>
        <Typography.Text className={styles.name} strong>
          {title || 'Название отрасли'}
        </Typography.Text>
      </Typography>
    </div>
  );
};
