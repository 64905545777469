export const getName = ({
  firstName,
  lastName,
  secondName = '',
}: {
  lastName: string;
  firstName: string;
  secondName?: string;
}): string => {
  const result = ['ИП'];
  if (lastName && firstName) {
    result.push(` ${lastName} ${firstName[0]}.`);
    if (secondName) {
      result.push(`${secondName[0]}.`);
    }
  }

  return result.join('');
};
