import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICardItem } from 'store/slices/b2bCard/interfaces';
import { InputField } from 'components/form/base/InputField';
import { FormActions } from 'components/Layout/FormActions/FormActions';

import { cardValidationSchema } from './validation';

interface IProps {
  isLoading: boolean;
  initialValues: ICardItem | null;
  onSubmit: (values: ICardItem) => void;
}

export const InfoCardForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
}) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(cardValidationSchema),
  });
  const handleCancel = () => {
    navigate('/aboutRegion/info');
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Card title='Общая информация'>
          <Row>
            <Col span={24}>
              <InputField
                name='title'
                label='Заголовок'
                placeholder='Введите заголовок'
                minLength={1}
                maxLength={60}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='subtitle'
                label='Подзаголовок'
                placeholder='Введите подзаголовок'
                minLength={1}
                maxLength={60}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                type='number'
                name='sortPriority'
                label='Сортировка'
                placeholder='Введите число'
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Сохранить
            </Button>
          </FormActions>
        </Card>
      </form>
    </FormProvider>
  );
};
