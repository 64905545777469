import { Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';

import { LocaleSelect } from '../selects/LocaleSelect';

type TProps = {
  name?: string;
  label?: string;
  disabled?: boolean;
};

export const LangSelectSection: React.FC<TProps> = ({
  name = 'lang',
  label = 'Язык',
  disabled,
}) => {
  return (
    <Content
      style={{
        height: '90px',
        marginBottom: '24px',
        paddingTop: '13px',
        paddingBottom: '16px',
      }}
    >
      <Row>
        <Col lg={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}>
          <LocaleSelect
            name={name}
            label={label}
            disabled={disabled}
            required
            placeholder=''
          />
        </Col>
      </Row>
    </Content>
  );
};
