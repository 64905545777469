import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IContractExchange } from 'store/slices/contractExchange/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadContractExchangeItem,
  saveContractExchangeItem,
} from 'store/slices/contractExchange/actions';

import { ContractExchangeForm } from './components/ContractExchangeForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const { item } = useAppSelector(state => state.contractExchange);
  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(loadContractExchangeItem(id));
  }, []);

  const onFinish = (values: IContractExchange) => {
    setIsLoading(true);
    dispatch(saveContractExchangeItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно изменен',
        });
        navigate('/contractExchange');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/contractExchange');
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && item.id === id ? (
        <ContractExchangeForm
          onFinish={onFinish}
          cancelButtonHandler={cancelButtonHandler}
          isLoading={isLoading}
          initialValues={mapValuesToForm(item)}
        />
      ) : null}
    </MainLayout>
  );
};
