import React from 'react';
import { configResponsive, useResponsive } from 'ahooks';
import classNames from 'classnames';

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import styles from './MainLayout.module.less';
// import { PageAffix } from 'sport-backend/app/components/affix/affix';
// import { Breadcrumbs } from './components/Breadcrumbs';
// import { ContentEditBlock } from 'sport-backend/app/ant/components/ContentEditBlock';

configResponsive({
  small: 0,
  middle: 800,
  large: 1400,
  extraLarge: 1800,
});

type TProps = {
  pageTitle: string;
  subTitle?: string;
  children: React.ReactNode;
  extraButtonsList?: React.ReactNode[];
  aside?: React.ReactNode;
  hasBackButton?: boolean;
  backButtonPath?: string;
  isClickableId?: boolean;
  isAsideVisible?: boolean;
};

export const MainLayout: React.FC<TProps> = ({
  children,
  pageTitle,
  subTitle,
  extraButtonsList,
  aside = null,
  hasBackButton = false,
  backButtonPath = '',
  isClickableId = false,
  isAsideVisible = true,
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Breadcrumbs
        subTitle={subTitle}
        pageTitle={pageTitle}
        extraButtonsList={extraButtonsList}
        hasBackButton={hasBackButton}
        backButtonPath={backButtonPath}
        isClickableId={isClickableId}
      />

      <div
        style={{
          padding: 24,
          position: 'relative',
        }}
      >
        <div
          className={classNames(styles.wrapper, {
            [styles.isMobile]: !responsive.large,
          })}
        >
          <div className={styles.main}>{children}</div>
          {isAsideVisible ? <div className={styles.aside}>{aside}</div> : null}
        </div>
      </div>
    </React.Fragment>
  );
};
