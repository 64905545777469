import {
  IB2BRegionItem,
  IRegionsItemResponse,
} from '../../../../store/slices/regions/interfaces';

export const mapValues = (values: IRegionsItemResponse): IB2BRegionItem => {
  return {
    id: values.id,
    buttonLabel: values.buttonLabel || '',
    buttonUrl: values.buttonUrl || '',
    capital: values.capital || '',
    description: values.description || '',
    enum: values.enum,
    icon: values.icon,
    lang: values.lang || '',
    name: values.name || '',
    population: values.population,
    square: values.square,
    vrp: values.vrp,
    videoUrl: values.videoUrl || '',
  };
};
