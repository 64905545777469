import React from 'react';
import { Button, Col, Row } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';

import styles from './PreferencesItem.module.less';
import { UploadIcon } from '../UploadImage/UploadIcon';

type TProps = {
  name: string;
  index: number;
  total: number;
  changePosition: (from: number, to: number) => void;
  removeItem: (item: number) => void;
};

export const PreferencesItem: React.FC<TProps> = ({
  name,
  index,
  total,
  changePosition,
  removeItem,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <Button
            onClick={() => {
              changePosition(index, index - 1);
            }}
            style={{ marginRight: '4px' }}
            icon={<ArrowUpOutlined />}
            disabled={index === 0}
          />
          <Button
            onClick={() => {
              changePosition(index, index + 1);
            }}
            style={{ marginRight: '16px' }}
            icon={<ArrowDownOutlined />}
            disabled={index + 1 >= total}
          />
          <Button
            danger
            onClick={() => removeItem(index)}
            icon={<DeleteOutlined />}
          />
        </div>
      </div>

      <Row gutter={[24, 0]}>
        <Col flex='0 0 122px'>
          <UploadIcon name={name} label='Иконка' title='Загрузить' required />
        </Col>
        <Col flex='auto'>
          <InputField
            name={`${name}.alt`}
            label='Alt'
            placeholder='Введите альтернативный текст'
            maxLength={160}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InputField
            name={`${name}.title`}
            label='Заголовок'
            placeholder='Введите заголовок'
            required
            maxLength={40}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InputField
            name={`${name}.description`}
            label='Описание'
            placeholder='Введите описание'
            required
            maxLength={160}
          />
        </Col>
      </Row>
    </div>
  );
};
