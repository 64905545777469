import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadInvestmentProjectsItem,
  saveInvestmentProjectsItem,
  saveInvestmentProjectsItemDraft,
} from 'store/slices/investmentProjects/actions';
import { InvestmentProjectsItem } from 'store/slices/investmentProjects/interfaces';
import { DeepPartial } from 'types/utils';
import { LangEnum } from 'constants/lang';

import { InvestmentForm } from './components/InvestmentForm';
import { mapValuesToForm } from './components/InvestmentForm/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.investmentProjects);
  const isCorrectItem = item?.id === Number(id);

  React.useEffect(() => {
    dispatch(loadInvestmentProjectsItem(id));
  }, [id]);

  const onSubmit = (values: InvestmentProjectsItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentProjectsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Инвестиционный проект успешно добавлен',
        });
        navigate('/investmentProjects');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<InvestmentProjectsItem>) => {
    setIsLoading(true);
    dispatch(saveInvestmentProjectsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Инвестиционный проект успешно добавлен',
        });
        navigate('/investmentProjects');
      })
      .catch(() => setIsLoading(false));
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    industry: null,
    industryData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {isCorrectItem && (
        <InvestmentForm
          onSubmit={onSubmit}
          isDraft
          isLoading={isLoading}
          defaultValues={mapValuesToForm(preparedValues)}
          onSaveAsDraft={onSaveAsDraft}
        />
      )}
    </MainLayout>
  );
};
