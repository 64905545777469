import React, { memo } from 'react';

import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';

interface IProps {
  onClose: () => void;
  onDelete: (itemId: string) => void;
  itemId: string;
  isOpen: boolean;
  text: string;
  entityName?: string;
}

export const ModalConfirmDelete = memo(
  ({
    onClose,
    onDelete,
    itemId,
    isOpen,
    text,
    entityName = 'документа',
  }: IProps) => (
    <StickyConfirmModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={`Удаление ${entityName}`}
      okText='Удалить'
      text={text}
      onSubmit={() => onDelete(itemId)}
      okButtonProps={{ danger: true }}
    />
  )
);

ModalConfirmDelete.displayName = 'ModalConfirmDelete';
