import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { StatusesEnum } from 'constants/status';

import {
  TRentalsDraftItem,
  IRentalsItem,
  IRentalsItemResponse,
} from './interfaces';

type TListResponse = { data: IRentalsItemResponse[]; total: number };

export const loadRentalsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('rentals/loadList', async (params, { getState }) => {
  const { limit } = getState().rentals;
  const { data } = await apiClient.get<TListResponse>(urls.api.rentals.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadRentalsItem = createAsyncThunk<IRentalsItemResponse, string>(
  'rentals/loadItem',
  async id => {
    const { data } = await apiClient.get<IRentalsItemResponse>(
      getUrlWithParams(urls.api.rentals.getOne, {
        id,
      })
    );
    return data;
  }
);

export const saveRentalsItem = createAsyncThunk<IRentalsItem, IRentalsItem>(
  'rentals/saveItem',
  async ({ id, ...params }) => {
    if (id) {
      const { data } = await apiClient.put<IRentalsItem>(
        getUrlWithParams(urls.api.rentals.put, { id }),
        {
          ...params,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post<IRentalsItem>(
        urls.api.rentals.post,
        {
          ...params,
        }
      );
      return data;
    }
  }
);

export const saveRentalsItemAsDraft = createAsyncThunk<
  TRentalsDraftItem,
  TRentalsDraftItem
>('rentals/saveDraftItem', async ({ id, ...requestParams }) => {
  if (id) {
    const { data } = await apiClient.put<TRentalsDraftItem>(
      getUrlWithParams(urls.api.rentals.draft.put, { id }),
      {
        ...requestParams,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<TRentalsDraftItem>(
      urls.api.rentals.draft.post,
      {
        ...requestParams,
      }
    );
    return data;
  }
});

export const setUnPublishRentalsItem = createAsyncThunk<void, string>(
  'rentals/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.rentals.status.put, { id }),
      {
        status: StatusesEnum.NOT_PUBLISHED,
      }
    );
  }
);

export const deleteRentalsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('rentals/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.rentals.delete, { id }));
  return data;
});
