import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const H1Svg = () => (
  <svg
    width='32'
    height='24'
    viewBox='0 0 32 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.01001 4.53973C5.01001 3.89592 5.53147 3.375 6.17474 3.375C6.81801 3.375 7.33948 3.89592 7.33948 4.53973V10.9497H16.6454V4.54199C16.6454 3.89817 17.1681 3.375 17.813 3.375C18.4578 3.375 18.9805 3.89817 18.9805 4.54199V19.4569C18.9805 20.1018 18.4578 20.625 17.813 20.625C17.1681 20.625 16.6454 20.1018 16.6454 19.4569V13.1473H7.33948V19.4603C7.33948 20.103 6.81801 20.625 6.17474 20.625C5.53147 20.625 5.01001 20.103 5.01001 19.4603V4.53973Z'
      fill='currentColor'
    />
    <path
      d='M24.9867 3.375C25.6315 3.375 26.1542 3.89817 26.1542 4.54199V19.4569C26.1542 20.1018 25.6315 20.625 24.9867 20.625C24.3418 20.625 23.8191 20.1018 23.8191 19.4569V13.1473V10.9497V6.49261L22.5685 7.34835C22.4384 7.43296 22.0433 7.59953 21.5647 7.34835C21.3001 7.20953 20.9805 6.86626 20.9805 6.37849C20.9805 5.89073 21.0996 5.60715 21.4002 5.3973L24.2497 3.58067C24.6361 3.37496 24.8163 3.37498 24.9821 3.375H24.9867Z'
      fill='currentColor'
    />
  </svg>
);

export const H1Icon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={H1Svg} {...props} />
);

const H2Svg = () => (
  <svg
    width='38'
    height='24'
    viewBox='0 0 38 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.17432 5.0782C5.17432 4.48222 5.65704 4 6.25252 4C6.848 4 7.33072 4.48222 7.33072 5.0782V11.012H15.9453V5.08029C15.9453 4.4843 16.4292 4 17.0261 4C17.623 4 18.1069 4.4843 18.1069 5.08029V18.8871C18.1069 19.4841 17.623 19.9685 17.0261 19.9685C16.4292 19.9685 15.9453 19.4841 15.9453 18.8871V13.0462H7.33072V18.8902C7.33072 19.4852 6.848 19.9685 6.25252 19.9685C5.65704 19.9685 5.17432 19.4852 5.17432 18.8902V5.0782Z'
      fill='currentColor'
    />
    <path
      d='M22.8953 20C22.2994 20 21.8171 19.5173 21.8171 18.9218C21.8171 18.6567 21.9127 18.414 22.0712 18.2263C23.2365 16.8468 28.1674 11.8225 28.1674 11.8225C28.1674 11.8225 31.7579 8.43376 28.7926 6.53705C27.2559 5.55411 24.8152 6.17027 24.3222 8.30188C24.1421 9.0805 23.7364 9.39647 23.2104 9.39647C22.6144 9.39647 22.1957 8.8988 22.1957 8.30188C22.1957 6.99922 23.276 4.5775 26.1761 4.0986C27.3732 3.90094 28.6811 3.97309 29.868 4.5775C30.7058 5.09566 32.173 6.13198 32.173 8.5344C32.173 10.9368 30.1161 12.8236 30.1161 12.8236L25.2501 17.8436L31.2717 17.8436C31.8667 17.8436 32.3499 18.3263 32.3499 18.9218C32.3499 19.5173 31.8667 20 31.2717 20L22.8953 20Z'
      fill='currentColor'
    />
  </svg>
);

export const H2Icon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={H2Svg} {...props} />
);

const H3Svg = () => (
  <svg
    width='35'
    height='24'
    viewBox='0 0 35 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.36987 5.93742C5.36987 5.41925 5.78957 5 6.30729 5C6.82502 5 7.24472 5.41925 7.24472 5.93742V11.0964H14.7345V5.93924C14.7345 5.42107 15.1552 5 15.6742 5C16.1931 5 16.6139 5.42107 16.6139 5.93924V17.9433C16.6139 18.4624 16.1931 18.8835 15.6742 18.8835C15.1552 18.8835 14.7345 18.4624 14.7345 17.9433V12.8651H7.24472V17.946C7.24472 18.4633 6.82502 18.8835 6.30729 18.8835C5.78957 18.8835 5.36987 18.4633 5.36987 17.946V5.93742Z'
      fill='currentColor'
    />
    <path
      d='M21.928 15.9204C21.5447 15.3409 21.3941 14.9742 20.6963 14.9742C20.1781 14.9742 19.8183 15.4035 19.8643 15.9204C19.9389 16.7587 21.0626 18.5113 23.5841 18.9277C24.6249 19.0995 25.5761 18.9748 26.608 18.4493C27.3365 17.9988 29.1183 16.8267 29.1667 14.2459C29.215 11.6652 27.3244 10.0134 25.4311 10.0134C25.4311 10.0134 28.101 7.41279 28.8555 6.5025C28.9744 6.34538 29.045 6.14964 29.045 5.93742C29.045 5.41969 28.6248 5 28.1076 5L21.3301 5.00099C20.8126 5.00107 20.3932 5.42057 20.3932 5.93803C20.3932 6.45554 20.8127 6.87506 21.3302 6.87506L25.9584 6.87506L22.8949 9.87754C21.9279 10.8348 22.8949 11.5773 23.3051 11.5773L24.7313 11.5773C27.4584 11.9189 28.124 15.1824 25.9968 16.6209C24.683 17.5093 22.6407 16.9983 21.928 15.9204Z'
      fill='currentColor'
    />
  </svg>
);

export const H3Icon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={H3Svg} {...props} />
);

const QuoteSvg = () => (
  <svg
    width='33'
    height='24'
    viewBox='0 0 33 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.30192 3.75C5.70189 3.75 5.19727 4.2359 5.19727 4.83643C5.19727 6.04799 5.19727 22.4055 5.19727 22.4055L9.87024 19.8403H26.0891C26.6906 19.8403 27.1875 19.3523 27.1875 18.7507V4.83854C27.1875 4.238 26.6906 3.75 26.0891 3.75L6.30192 3.75ZM25.6276 5.26576H6.80776V17.8582V19.7432L9.39474 18.3246H25.6367L25.6276 5.26576Z'
      fill='currentColor'
    />
    <path
      d='M13.1073 7.83008C12.5993 7.83008 12.1875 8.24187 12.1875 8.74985V11.0493C12.1875 11.5572 12.5993 11.969 13.1073 11.969H14.3871C14.5141 11.969 14.6176 12.0718 14.6092 12.1985C14.5669 12.8366 14.3389 13.8086 13.5672 13.8086H13.3374C12.9564 13.8086 12.6475 14.1175 12.6476 14.4986C12.6477 14.8795 12.9565 15.1882 13.3374 15.1882H13.5672C13.9291 15.1882 14.29 15.1051 14.617 14.898C14.9422 14.6921 15.1883 14.3927 15.3696 14.0328C15.7197 13.3381 15.8666 12.3419 15.8666 11.0493V8.74985C15.8666 8.24187 15.4548 7.83008 14.9468 7.83008H13.1073Z'
      fill='currentColor'
    />
    <path
      d='M17.7049 7.83008C17.197 7.83008 16.7852 8.24187 16.7852 8.74985V11.0493C16.7852 11.5572 17.197 11.969 17.7049 11.969H18.9848C19.1117 11.969 19.2152 12.0718 19.2068 12.1985C19.1645 12.8366 18.9365 13.8086 18.1648 13.8086H17.9351C17.554 13.8086 17.2451 14.1175 17.2453 14.4986C17.2454 14.8795 17.5542 15.1882 17.9351 15.1882H18.1648C18.5267 15.1882 18.8876 15.1051 19.2147 14.898C19.5399 14.6921 19.7859 14.3927 19.9673 14.0328C20.3173 13.3381 20.4642 12.3419 20.4642 11.0493V8.74985C20.4642 8.24187 20.0524 7.83008 19.5445 7.83008H17.7049Z'
      fill='currentColor'
    />
  </svg>
);

export const QuoteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={QuoteSvg} {...props} />
);

const CodeSvg = () => (
  <svg
    width='36'
    height='24'
    viewBox='0 0 36 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.8257 19.3658C16.5386 20.2471 15.8877 20.4961 15.2808 20.2984C14.674 20.1006 14.341 19.5311 14.6282 18.6498L19.1794 4.68221C19.4661 3.80245 20.1086 3.49887 20.7154 3.6966C21.3222 3.89434 21.6636 4.5185 21.3769 5.39826L16.8257 19.3658Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5761 8.48688C13.8224 8.96491 13.6346 9.55213 13.1565 9.79847L8.25531 12.3242L13.1786 15.0232C13.6502 15.2818 13.8229 15.8736 13.5643 16.3452C13.3058 16.8167 12.714 16.9894 12.2424 16.7309L5.71315 13.1514C5.39742 12.9783 5.20298 12.6451 5.2076 12.2851C5.21222 11.925 5.41514 11.5969 5.73521 11.432L12.2645 8.06736C12.7425 7.82102 13.3297 8.00884 13.5761 8.48688Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.0117 8.48688C22.258 8.00884 22.8453 7.82102 23.3233 8.06736L29.8525 11.432C30.1726 11.5969 30.3755 11.925 30.3801 12.2851C30.3848 12.6451 30.1903 12.9783 29.8746 13.1514L23.3453 16.7309C22.8738 16.9894 22.2819 16.8167 22.0234 16.3452C21.7649 15.8736 21.9376 15.2818 22.4092 15.0232L27.3324 12.3242L22.4312 9.79847C21.9532 9.55213 21.7654 8.96491 22.0117 8.48688Z'
      fill='currentColor'
    />
  </svg>
);

export const CodeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CodeSvg} {...props} />
);

const DashSvg = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.55515 13.1543C5.91671 13.1543 5.40015 12.6372 5.40015 11.9993C5.40015 11.3614 5.91671 10.8443 6.55515 10.8443L18.6735 10.8443C19.3108 10.8443 19.8285 11.3614 19.8285 11.9993C19.8285 12.6372 19.3108 13.1543 18.6735 13.1543L6.55515 13.1543Z'
      fill='currentColor'
    />
  </svg>
);

export const DashIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DashSvg} {...props} />
);

const HyphenSvg = () => (
  <svg
    width='21'
    height='24'
    viewBox='0 0 21 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.78387 13.1562C6.26691 13.1562 5.84863 12.6385 5.84863 11.9998C5.84863 11.361 6.26691 10.8433 6.78387 10.8433L14.977 10.8433C15.493 10.8433 15.9122 11.361 15.9122 11.9998C15.9122 12.6385 15.493 13.1562 14.977 13.1562L6.78387 13.1562Z'
      fill='currentColor'
    />
  </svg>
);

export const HyphenIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HyphenSvg} {...props} />
);

const EmailSvg = () => (
  <svg
    width='30'
    height='24'
    viewBox='0 0 30 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.7621 12.0009C24.7621 12.9975 24.6087 14.0819 24.275 15.0527C23.9801 15.9106 22.9287 17.2477 20.9795 17.0639C19.921 16.9641 19.0165 16.317 18.5091 15.4188C17.6742 16.195 16.5551 16.6698 15.3251 16.6698C12.742 16.6698 10.6479 14.5757 10.6479 11.9925C10.6479 9.40937 12.742 7.31531 15.3251 7.31531C17.9083 7.31531 20.0024 9.40937 20.0024 11.9925C20.0024 12.0388 20.0017 12.0848 20.0004 12.1307V13.8295C20.0004 14.5882 20.5289 15.1605 21.2983 15.1605C22.8371 15.1605 22.8726 13.0971 22.8726 12.0009C22.8726 7.84472 19.5033 4.47545 15.3471 4.47545C11.1909 4.47545 7.82164 7.84472 7.82164 12.0009C7.82164 16.1572 11.1909 19.5264 15.3471 19.5264C16.2212 19.5264 17.0605 19.3774 17.8409 19.1034C17.9596 19.0496 18.0914 19.0196 18.2301 19.0196C18.7512 19.0196 19.1735 19.442 19.1735 19.9631C19.1735 20.3669 18.9199 20.7114 18.5632 20.846C18.5346 20.8603 18.5054 20.8728 18.4758 20.8832C17.4957 21.2282 16.4425 21.4159 15.3471 21.4159C10.1474 21.4159 5.93213 17.2007 5.93213 12.0009C5.93213 6.80118 10.1474 2.58594 15.3471 2.58594C20.5469 2.58594 24.7621 6.80118 24.7621 12.0009ZM17.9411 11.9925C17.9411 13.4373 16.7699 14.6085 15.3251 14.6085C13.8804 14.6085 12.7092 13.4373 12.7092 11.9925C12.7092 10.5478 13.8804 9.37659 15.3251 9.37659C16.7699 9.37659 17.9411 10.5478 17.9411 11.9925Z'
      fill='currentColor'
    />
  </svg>
);

export const EmailIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmailSvg} {...props} />
);

const QuotesSvg = () => (
  <svg
    width='44'
    height='24'
    viewBox='0 0 44 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.1276 15.2249L10.8945 11.9478L14.1276 8.77539'
      stroke='currentColor'
      strokeWidth='1.49038'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.53095 15.2249L6.29785 11.9478L9.53095 8.77539'
      stroke='currentColor'
      strokeWidth='1.49038'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='18.8779' cy='14.4766' r='0.75' fill='currentColor' />
    <circle cx='21.8779' cy='14.4766' r='0.75' fill='currentColor' />
    <circle cx='24.8779' cy='14.4766' r='0.75' fill='currentColor' />
    <path
      d='M34.2249 15.2249L37.458 11.9478L34.2249 8.77539'
      stroke='currentColor'
      strokeWidth='1.49038'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.6272 15.2249L32.8604 11.9478L29.6272 8.77539'
      stroke='currentColor'
      strokeWidth='1.49038'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const QuotesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={QuotesSvg} {...props} />
);
