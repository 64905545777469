export enum ProcedureStatusesEnum {
  AcceptanceApplications = 'acceptanceApplications',
  ConsiderationApplications = 'considerationApplications',
  Completed = 'completed',
  NotTakePlace = 'notTakePlace',
  Canceled = 'canceled',
}

export type TProcedureStatuses = `${ProcedureStatusesEnum}`;

export const currentProcedureStatuses = [
  ProcedureStatusesEnum.AcceptanceApplications,
  ProcedureStatusesEnum.ConsiderationApplications,
];

export const finishedProcedureStatuses = [
  ProcedureStatusesEnum.Completed,
  ProcedureStatusesEnum.NotTakePlace,
  ProcedureStatusesEnum.Canceled,
];

export const procedureStatusesNamesHash = {
  [ProcedureStatusesEnum.AcceptanceApplications]: 'Прием заявок',
  [ProcedureStatusesEnum.ConsiderationApplications]: 'Рассмотрение заявок',
  [ProcedureStatusesEnum.Completed]: 'Завершена',
  [ProcedureStatusesEnum.NotTakePlace]: 'Не состоялась',
  [ProcedureStatusesEnum.Canceled]: 'Отменена',
} as const;

export type TProcedureStatusesNames =
  typeof procedureStatusesNamesHash[keyof typeof procedureStatusesNamesHash];
