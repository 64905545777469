import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, notification, Pagination, PaginationProps, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteChapter,
  loadAllChapters,
  loadChapters,
  loadNestedItems,
  saveChapter,
  updateChapter,
} from 'store/slices/openingInfo/actions';
import { IChapter } from 'store/slices/openingInfo/interfaces';
import { IDocument } from 'types/document';
import { useDeepEffect } from 'utils/useDeepEffect';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { SimpleForm } from '../EntityForms/SimpleForm';
import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import styles from './Chapters.module.less';

const PAGE_SIZE = 3;

export const Chapters = () => {
  const [offset, setOffset] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);
  const swapElRef = useRef<{ chapter: IChapter; step: number }>(null);

  const {
    chapters: { list, total },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const loadListChapters = async (offset: number) =>
    await dispatch(
      loadChapters({
        limit: PAGE_SIZE,
        offset: (offset - 1) * PAGE_SIZE,
      })
    ).unwrap();

  const updateChapterOnAnotherPage = async (list: Array<IChapter>) => {
    const { chapter, step } = swapElRef.current;
    const swapChapter = list[step === 1 ? 0 : list.length - 1];

    await dispatch(
      updateChapter({
        id: swapChapter.id,
        file: swapChapter.file,
        title: swapChapter.title,
        sortIndex: chapter.sortIndex,
      })
    );

    await dispatch(
      updateChapter({
        id: chapter.id,
        file: chapter.file,
        title: chapter.title,
        sortIndex: swapChapter.sortIndex,
      })
    );

    await loadListChapters(offset);
    swapElRef.current = null;
  };

  const loadListNestedItems = async () => await dispatch(loadNestedItems());

  useDeepEffect(() => {
    loadListChapters(offset).then(({ data }) => {
      if (swapElRef.current) {
        updateChapterOnAnotherPage(data);
      }
    });
  }, [offset]);

  useEffect(() => {
    loadListNestedItems();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = page => setOffset(page);

  const handleAdd = useCallback(
    async (file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      await dispatch(saveChapter({ file, title, sortIndex: total + 1 }));

      const lastPage = Math.ceil((total + 1) / PAGE_SIZE);
      if (offset === lastPage) {
        loadListChapters(lastPage);
      } else {
        setOffset(lastPage);
      }
    },
    [total]
  );

  const handleUpdate = useCallback(
    async (file: IDocument, title: string, { id, sortIndex }: IChapter) => {
      await dispatch(updateChapter({ file, title, id, sortIndex }));
    },
    []
  );

  const handleMove = useCallback(
    async (chapter: IChapter, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === chapter.id);
      const swapEl = list[currentIndex + step];

      if (swapEl) {
        await dispatch(
          updateChapter({
            id: swapEl.id,
            file: swapEl.file,
            title: swapEl.title,
            sortIndex: chapter.sortIndex,
          })
        );

        await dispatch(
          updateChapter({
            id: chapter.id,
            file: chapter.file,
            title: chapter.title,
            sortIndex: swapEl.sortIndex,
          })
        );
        await loadListChapters(offset);
      } else {
        swapElRef.current = { chapter, step };
        setOffset(prevState => prevState + step);
      }
    },
    [offset, list]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteChapter(id));
      handleCloseModal();
      notification.success({
        message: 'Устав удалён',
      });

      const list = await dispatch(loadAllChapters()).unwrap();

      await Promise.all(
        list.data.map(({ id, file, title }, index) =>
          dispatch(
            updateChapter({
              id,
              file,
              title,
              sortIndex: index + 1,
            })
          )
        )
      );

      const currentlastPage = Math.ceil(total / PAGE_SIZE);
      const updatelastPage = Math.ceil((total - 1) / PAGE_SIZE);

      if (currentlastPage !== updatelastPage && offset !== 1) {
        setOffset(prevState => prevState - 1);
      } else {
        await loadListChapters(offset);
      }
    },
    [total, offset]
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <CustomUpload
            buttonTitle='Загрузить устав'
            onAdd={handleAdd}
            disabled={loading}
          />
        </Col>
      </Row>
      {total > 0 && (
        <Row className={styles.list}>
          <Col span={24}>
            {list.map(item => (
              <SimpleForm
                key={item.id}
                onMove={handleMove}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                entity={item}
                total={total}
                withAdditional
              />
            ))}
          </Col>
        </Row>
      )}
      <Row justify='end'>
        <Col>
          <Pagination
            size='small'
            current={offset}
            onChange={handleChangePage}
            total={total}
            pageSize={PAGE_SIZE}
            hideOnSinglePage
          />
        </Col>
      </Row>
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          entityName='устава'
          text='Вы уверены, что хотите удалить устав и его внутренние приложения?'
        />
      )}
    </div>
  );
};
