import React, { useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Content } from 'antd/lib/layout/layout';

import { addSliderItem } from '../../store/slices/slider/actions';
import { useAppDispatch } from '../../store';
import { ISliderItem } from '../../store/slices/slider/interfaces';
import { MainLayout } from '../../components/Layout/MainLayout';
import { SliderForm } from './components/SliderForm';
import { mapSliderItemValues } from './utilts';

interface IProps {
  slug: string;
  withImage?: boolean;
  isFormType?: boolean;
}

export const AddSlide: React.FC<IProps> = ({ slug, withImage, isFormType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lang, sliderName } = useParams();
  const onSubmit = (values: ISliderItem) => {
    setIsLoading(true);
    dispatch(
      addSliderItem({
        sliderId: sliderName,
        lang,
        payload: mapSliderItemValues(values),
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate(`/${slug}/${lang}/slider`);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <MainLayout pageTitle='Добавление слайда'>
      <Content>
        <SliderForm
          isFormType={isFormType}
          initialValues={null}
          onSubmit={onSubmit}
          isLoading={isLoading}
          withImage={withImage}
          slug={slug}
        />
      </Content>
    </MainLayout>
  );
};
