import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Row, Space, Tag, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import { loadAdminsItem } from 'store/slices/admin/actions';
import { contactIconsHash } from 'constants/contacts';
import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import { roleNamesHash, Roles } from 'constants/roles';
import { formatPhoneNumber } from 'utils/format';
import { contactsFormat } from 'utils/contacts';

import styles from './View.module.less';

export const View = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { item } = useAppSelector(state => state.admins);
  React.useEffect(() => {
    dispatch(loadAdminsItem(id));
  }, []);
  return (
    <MainLayout
      pageTitle='Просмотр пользователя администратора'
      extraButtonsList={[
        <Link key={1} to={`/admin/${id}/edit`}>
          <Button>Редактировать</Button>
        </Link>,
      ]}
      hasBackButton
      aside={
        item.id && item.id === Number(id) ? (
          <div>
            <Content>
              <Row style={{ flexFlow: 'column nowrap' }}>
                <Typography.Title level={5} style={{ marginBottom: '17px' }}>
                  Контакты
                </Typography.Title>
                <Space direction='vertical' size='small'>
                  {item.phone && (
                    <Typography.Link
                      href={contactsFormat({
                        value: item.phone,
                        type: DefaultContactOptionsTypes.Phone,
                      })}
                    >
                      <span className={styles.contactIconPhone}>
                        {contactIconsHash[DefaultContactOptionsTypes.Phone]}
                      </span>
                      <span className={styles.contact}>
                        {formatPhoneNumber(item.phone)}
                      </span>
                    </Typography.Link>
                  )}
                  <Typography.Link
                    href={`mailto:${item.email}`}
                    target='_blank'
                  >
                    <span className={styles.contactIcon}>
                      {contactIconsHash[DefaultContactOptionsTypes.Email]}
                    </span>
                    <span className={styles.contact}>{item.email}</span>
                  </Typography.Link>
                </Space>
              </Row>
            </Content>
          </div>
        ) : null
      }
    >
      {item.id && item.id === Number(id) ? (
        <Content>
          <Row>
            <Typography.Text
              style={{ fontSize: '20px', lineHeight: '28ps', fontWeight: 400 }}
            >
              {`${item.lastName} ${item.firstName} ${
                item.secondName ? item.secondName : ''
              }`}
            </Typography.Text>
          </Row>
          {item.position && (
            <>
              <Row style={{ marginTop: '16px' }}>
                <Typography.Text type='secondary'>Должность</Typography.Text>
              </Row>
              <Row style={{ marginTop: '4px' }}>
                <Typography.Text>{item.position || '-'}</Typography.Text>
              </Row>
            </>
          )}
          <Row style={{ marginTop: '16px' }}>
            <Typography.Text type='secondary'>Дата регистрации</Typography.Text>
          </Row>
          <Row style={{ marginTop: '4px' }}>
            <Typography.Text>
              {dayjs(item.createdAt).format('DD.MM.YY')}
            </Typography.Text>
          </Row>
          <Row align='middle' style={{ marginTop: '16px' }}>
            {item.role !== Roles.Guest ? (
              <>
                <SmileOutlined
                  style={{ marginRight: '9px', fontSize: '21px' }}
                />
                <Tag>{roleNamesHash[item.role]}</Tag>
              </>
            ) : (
              <>
                <FrownOutlined
                  style={{ marginRight: '9px', fontSize: '21px' }}
                />
                <Typography.Text type='secondary'>
                  Роль не выбрана
                </Typography.Text>
              </>
            )}
          </Row>
        </Content>
      ) : null}
    </MainLayout>
  );
};
