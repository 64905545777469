import { LangEnum } from 'constants/lang';
import { prepareContent } from 'components/form/Content/utils';
import { DEFAULT_SEO_STATE } from 'constants/seo';
import { StatusesEnum } from 'constants/status';

export const DEFAULT_STATE = {
  lang: LangEnum.ru,
  name: '',
  image: null,
  region: null,
  year: null,
  month: null,
  gallery: [],
  content: prepareContent([]),
  ...DEFAULT_SEO_STATE,
  status: StatusesEnum.PUBLISHED,
};

export const MINIMAL_YEAR = 2021;

export const MAXIMAL_YEAR = 2023;
