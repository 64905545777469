export enum ProjectStatus {
  ExpressAnalysis = 'expressAnalysis',
  ComplexAnalysis = 'complexAnalysis',
  Signed = 'signed',
  Funding = 'funding',
}

export type TProjectStatusTypes = `${ProjectStatus}`;

export const projectStatusNames = {
  [ProjectStatus.ExpressAnalysis]: 'Прохождение экспресс-анализа',
  [ProjectStatus.ComplexAnalysis]: 'Комплексный анализ',
  [ProjectStatus.Signed]: 'Договор подписан',
  [ProjectStatus.Funding]: 'Получение финансирования',
};
