// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReviewForm-module__viewRow--jCQGo {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n}\n.ReviewForm-module__viewCol--lMOvm {\n  color: #8c8c8c;\n}\na.ReviewForm-module__titleLink--Anido {\n  color: #262626;\n  margin-bottom: 4px;\n}\n.ReviewForm-module__cardLink--p0Pg0:hover .ReviewForm-module__titleLink--Anido {\n  color: #1890ff;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reviews/components/ReviewForm.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACA;EACE,cAAA;AACF;AAEA;EACE,cAAA;EACA,kBAAA;AAAF;AAIE;EAEI,cAAA;AAHN","sourcesContent":[".viewRow {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n}\n.viewCol {\n  color: #8c8c8c;\n}\n\na.titleLink {\n  color: #262626;\n  margin-bottom: 4px;\n}\n\n.cardLink {\n  &:hover {\n    .titleLink {\n      color: #1890ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewRow": "ReviewForm-module__viewRow--jCQGo",
	"viewCol": "ReviewForm-module__viewCol--lMOvm",
	"titleLink": "ReviewForm-module__titleLink--Anido",
	"cardLink": "ReviewForm-module__cardLink--p0Pg0"
};
export default ___CSS_LOADER_EXPORT___;
