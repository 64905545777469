export enum ApplicationsStatusesEnum {
  // not show in cms
  // Draft = 'draft',
  // Ready = 'ready',
  New = 'new',
  InProgress = 'inProgress',
  ToRework = 'toRework',
  Declined = 'declined',
  Finished = 'finished',
}

export type TApplicationsStatuses = `${ApplicationsStatusesEnum}`;

export const applicationsStatusesNamesHash = {
  [ApplicationsStatusesEnum.New]: 'Заявка получена',
  [ApplicationsStatusesEnum.InProgress]: 'На рассмотрении',
  [ApplicationsStatusesEnum.ToRework]: 'На доработку',
  [ApplicationsStatusesEnum.Declined]: 'Отклонена',
  [ApplicationsStatusesEnum.Finished]: 'Завершена',
};

export const applicationsStatusesColorsHash = {
  [ApplicationsStatusesEnum.InProgress]: 'blue',
  [ApplicationsStatusesEnum.New]: '',
  [ApplicationsStatusesEnum.ToRework]: 'gold',
  [ApplicationsStatusesEnum.Declined]: 'red',
  [ApplicationsStatusesEnum.Finished]: 'green',
} as const;
