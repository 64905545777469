import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Descriptions, Row, Typography } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';

import { IBusinessItemRequest } from 'store/slices/business/interfaces';
import { InputField } from 'components/form/base/InputField';
import { PhoneNumberField } from 'components/form/base/PhoneNumberField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { phonePlaceHoldersHash } from 'constants/phonePlaceholder';
import { TAuthTypes, authTypesValueTextHash } from 'constants/authTypes';

import { mapValues, validationSchema } from './formUtils';
import { UserOrganizations } from '../UserOrganizations/UserOrganizations';
import styles from './EditContent.module.less';

interface IProps {
  readonly onSave: (values: IBusinessItemRequest) => void;
  readonly onCancel: () => void;
  readonly initialValues: IBusinessItemRequest & { email: string };
  readonly isLoading: boolean;
  readonly authType: TAuthTypes;
}

export const EditContent = (props: IProps) => {
  const { onSave, onCancel, initialValues, isLoading, authType } = props;

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const submit = (values: FieldValues) => {
    const preparedValues = mapValues(values);
    onSave(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  return (
    <Content>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(submit)}
          className='ant-form ant-form-vertical'
        >
          <Card
            className={styles.card}
            title='Общая информация'
            bordered={false}
          >
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputField
                  name='firstName'
                  label='Имя'
                  required
                  placeholder='Введите имя'
                  maxLength={75}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputField
                  name='lastName'
                  label='Фамилия'
                  required
                  placeholder='Введите фамилию'
                  maxLength={75}
                />
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputField
                  name='secondName'
                  label='Отчество'
                  required
                  placeholder='Введите отчество'
                  maxLength={75}
                />
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputField name='email' label='Email' required disabled />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <PhoneNumberField
                  name='phone'
                  label='Телефон'
                  required
                  placeholder={phonePlaceHoldersHash.mobilePhone}
                />
              </Col>
            </Row>
          </Card>
          <Row style={{ marginBottom: '24px' }}>
            <Col span={24}>
              <Descriptions title='' size='small' column={1} layout='vertical'>
                <Descriptions.Item
                  className={styles.row}
                  label='Тип регистрации'
                >
                  <Typography.Text type='secondary'>
                    {authTypesValueTextHash[authType]}
                  </Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row style={{ marginBottom: '32px' }}>
            <Col span={24}>
              <UserOrganizations />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={onCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Сохранить
            </Button>
          </FormActions>
        </form>
      </FormProvider>
    </Content>
  );
};
