export enum TutuWidget {
  Bus = 'bus',
  Train = 'train',
  Airplane = 'airplane',
}

export const tutuWidgetName = {
  [TutuWidget.Airplane]: 'Самолет',
  [TutuWidget.Train]: 'Ж/д',
  [TutuWidget.Bus]: 'Автобус',
};

export type TTutuWidget = `${TutuWidget}`;
