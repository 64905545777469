import React from 'react';
import { Button, Dropdown, Menu, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { ITouristItemResponse } from 'store/slices/tourist/interfaces';
import { formatPhoneNumber } from 'utils/format';

interface IProps {
  data: ITouristItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: ITouristItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Фамилия имя',
      sorter: true,
      dataIndex: 'lastName',
      render: (_: unknown, item) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/tourist/${item.id}`}
          >
            <Typography.Text>{`${item.lastName} ${item.firstName}`}</Typography.Text>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '25%',
      title: 'Email',
      sorter: true,
      dataIndex: 'email',
      render: email => (
        <Typography>
          <Typography.Text>{email}</Typography.Text>
        </Typography>
      ),
    },
    {
      key: 3,
      width: '20%',
      title: 'Телефон',
      dataIndex: 'phone',
      render: phone => (
        <Typography.Text>{formatPhoneNumber(phone)}</Typography.Text>
      ),
    },
    {
      key: 4,
      width: '15%',
      title: 'Дата, время регистрации',
      sorter: true,
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography>
          <div>{dayjs(createdAt).format('DD.MM.YYYY HH:mm')}</div>
        </Typography>
      ),
    },
    {
      key: 5,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: ITouristItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
      bordered={false}
    />
  );
};
