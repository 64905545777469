// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__wrapper--o_jum {\n  margin-right: 10px;\n}\n.Header-module__title--_Dpv4 {\n  display: flex;\n  gap: 0 16px;\n  align-items: center;\n  word-break: break-word;\n}\n.Header-module__number--pgql5 {\n  white-space: nowrap;\n}\n.Header-module__name--ype1W {\n  font-size: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PriorityIndustry/components/PriorityIndustryForm/components/DirectionForm/components/Header/Header.module.less"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;AAEA;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;AAAD;AAGA;EACC,mBAAA;AADD;AAIA;EACC,eAAA;AAFD","sourcesContent":[".wrapper {\n\tmargin-right: 10px;\n}\n\n.title {\n\tdisplay: flex;\n\tgap: 0 16px;\n\talign-items: center;\n\tword-break: break-word;\n}\n\n.number {\n\twhite-space: nowrap;\n}\n\n.name {\n\tfont-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Header-module__wrapper--o_jum",
	"title": "Header-module__title--_Dpv4",
	"number": "Header-module__number--pgql5",
	"name": "Header-module__name--ype1W"
};
export default ___CSS_LOADER_EXPORT___;
