import { createSlice } from '@reduxjs/toolkit';

import {
  loadInvestInfrastructuresList,
  loadInvestInfrastructuresItem,
} from './actions';
import { IInvestInfrastructuresState } from './interfaces';

export const investInfrastructuresSlice = createSlice({
  name: 'investInfrastructures',
  initialState: {
    list: [],
    item: null,
    limit: 20,
    total: 0,
    offset: 0,
  } as IInvestInfrastructuresState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadInvestInfrastructuresList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
    builder.addCase(
      loadInvestInfrastructuresItem.fulfilled,
      (state, { payload }) => {
        state.item = payload;
      }
    );
  },
});
