import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { UserTypes } from 'types/userTypes';

import { IAdminsItem, IAdminsItemResponse } from './interfaces';

type TListResponse = {
  data: IAdminsItemResponse[];
  total: number;
};

export const loadAdminsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('admins/loadList', async (params, { getState }) => {
  const { limit } = getState().admins;
  const { data } = await apiClient.get<TListResponse>(urls.api.users.get, {
    params: {
      limit,
      userType: UserTypes.Admin,
      ...params,
    },
  });
  return data;
});

export const loadAdminsItem = createAsyncThunk<IAdminsItemResponse, string>(
  'admins/loadItem',
  async id => {
    const { data } = await apiClient.get<IAdminsItemResponse>(
      getUrlWithParams(urls.api.users.getOne.admin, { id })
    );
    return data;
  }
);

export const saveAdminsItem = createAsyncThunk<
  IAdminsItem,
  IAdminsItem & { id: number }
>('admins/saveItem', async ({ id, ...params }) => {
  const { data } = await apiClient.patch<IAdminsItemResponse>(
    getUrlWithParams(urls.api.users.patch.admin, { id }),
    {
      ...params,
    }
  );
  return data;
});
