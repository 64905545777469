import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import {
  TRespondsTypes,
  TRespondsItemData,
} from 'store/slices/responds/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import {
  applicationsStatusesFormColorHash,
  applicationsStatusesFormNamesHash,
} from 'constants/applicationsFormRespondsStatus';
import { formatPhoneNumber } from 'utils/format';

type TDataItem = TRespondsItemData & { type: TRespondsTypes };

interface IProps {
  data: TDataItem[];
  total: number;
  limit?: number;
  getActions: (item: TDataItem) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const userName = data => {
    if ('firstName' in data) {
      return <Typography.Text>{data.firstName}</Typography.Text>;
    } else if ('userData' in data && data.userData !== null) {
      return <Typography.Text>{data.userData.firstName}</Typography.Text>;
    } else {
      return <Typography.Text>Пользователь не зарегистрирован</Typography.Text>;
    }
  };

  const columns = [
    {
      key: 1,
      title: 'Дата',
      width: 75,
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography>{dayjs(createdAt).format('DD.MM.YYYY')}</Typography>
      ),
    },
    {
      key: 2,
      title: 'Время',
      width: '17%',
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography>{dayjs(createdAt).format('HH:mm')}</Typography>
      ),
    },
    {
      key: 3,
      title: 'Фамилия',
      width: '25%',
      dataIndex: 'lastName',
      render: (_: unknown, { id, type, ...item }: TDataItem) => (
        <Link to={`${id}?type=${type}`}>
          {'lastName' in item && item.lastName ? (
            <Typography.Text>{item.lastName}</Typography.Text>
          ) : (
            'userData' in item &&
            item.userData && (
              <Typography.Text>{item.userData.lastName}</Typography.Text>
            )
          )}
        </Link>
      ),
    },
    {
      key: 4,
      title: 'Имя',
      width: '19%',
      dataIndex: 'firstName',
      render: (_: unknown, item: TDataItem) => (
        <Link to={`${item.id}?type=${item.type}`}>
          <Typography.Text>{userName(item)}</Typography.Text>
        </Link>
      ),
    },
    {
      key: 5,
      width: '20%',
      title: 'Телефон',
      dataIndex: 'phone',
      render: (_: unknown, item: TDataItem) => (
        <Typography>
          {'phone' in item && item.phone ? (
            <Typography.Text>{formatPhoneNumber(item.phone)}</Typography.Text>
          ) : (
            'userData' in item &&
            item.userData && (
              <Typography.Text>
                {formatPhoneNumber(item.userData.phone)}
              </Typography.Text>
            )
          )}
        </Typography>
      ),
    },
    {
      key: 6,
      width: '25%',
      title: 'Email',
      dataIndex: 'email',
      render: email => (
        <Typography>
          <Typography.Text>{email}</Typography.Text>
        </Typography>
      ),
    },
    {
      key: 5,
      width: 160,
      title: 'Тип формы',
      dataIndex: 'type',
      render: type => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tag
            color={applicationsStatusesFormColorHash[type]}
            style={{ marginRight: 0 }}
          >
            {applicationsStatusesFormNamesHash[type]}
          </Tag>
        </div>
      ),
    },
    {
      key: 6,
      title: '',
      width: 60,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: TDataItem) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
      bordered={false}
    />
  );
};
