// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuItemHeader-module__wrapper--VVajG {\n  margin-right: 10px;\n}\n.MenuItemHeader-module__title--wsT9z {\n  word-break: break-word;\n}\n.MenuItemHeader-module__svg--vurHr {\n  margin: 0 16px;\n}\n@media (max-width: 575px) {\n  .MenuItemHeader-module__svg--vurHr {\n    margin: 0 8px;\n  }\n}\n.MenuItemHeader-module__activeEye--IrYeJ {\n  color: #52C41A;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Menu/components/MenuItems/components/MenuItemHeader/MenuItemHeader.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,sBAAA;AAAF;AAGA;EACE,cAAA;AADF;AAGE;EAAA;IACE,aAAA;EAAF;AACF;AAGA;EACE,cAAA;AADF","sourcesContent":[".wrapper {\n  margin-right: 10px;\n}\n\n.title {\n  word-break: break-word;\n}\n\n.svg {\n  margin: 0 16px;\n\n  @media (max-width: 575px) {\n    margin: 0 8px;\n  }\n}\n\n.activeEye {\n  color: #52C41A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MenuItemHeader-module__wrapper--VVajG",
	"title": "MenuItemHeader-module__title--wsT9z",
	"svg": "MenuItemHeader-module__svg--vurHr",
	"activeEye": "MenuItemHeader-module__activeEye--IrYeJ"
};
export default ___CSS_LOADER_EXPORT___;
