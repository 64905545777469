import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Select } from 'antd';

import {
  ProcedureStatusesEnum,
  procedureStatusesNamesHash,
  currentProcedureStatuses,
  finishedProcedureStatuses,
} from 'constants/procedureStatus';
import { StateStatus, stateStatusesNamesHash } from 'types/stateStatus';

type TProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
};

export const ProcedureStatusSelect: React.FC<TProps> = ({
  name,
  label,
  placeholder = 'Выберите статус',
  required,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={fieldState?.error?.message}
          validateStatus={fieldState?.error ? 'error' : 'success'}
          required={required}
        >
          <Select
            {...field}
            onChange={(_, option) => field.onChange(option)}
            placeholder={placeholder}
          >
            <Select.OptGroup
              label={stateStatusesNamesHash[StateStatus.Current]}
            >
              {currentProcedureStatuses.map(status => (
                <Select.Option
                  key={status}
                  value={ProcedureStatusesEnum[status]}
                >
                  {procedureStatusesNamesHash[status]}
                </Select.Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup
              label={stateStatusesNamesHash[StateStatus.Completed]}
            >
              {finishedProcedureStatuses.map(status => (
                <Select.Option
                  key={status}
                  value={ProcedureStatusesEnum[status]}
                >
                  {procedureStatusesNamesHash[status]}
                </Select.Option>
              ))}
            </Select.OptGroup>
          </Select>
        </Form.Item>
      )}
    />
  );
};
