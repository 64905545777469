import React from 'react';

import { InfrastructureCategories } from 'constants/infrastructuresCategories';

import { Select } from '../base/Select';

type TProps = {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  mode?: 'multiple';
};

const options = Object.keys(InfrastructureCategories).map(category => ({
  label: InfrastructureCategories[category],
  value: category,
}));

export const InfrastructureCategoriesSelector: React.FC<TProps> = props => {
  return <Select {...props} allowClear options={options} />;
};
