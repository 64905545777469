import React from 'react';
import { Badge, Button, Dropdown, Menu, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';

import { IOrganization } from 'store/slices/organizations/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { OrganizationTypeEnum } from 'types/organizations';

import { getName } from '../utils';

type TProps = {
  data: IOrganization[];
  total: number;
  limit?: number;
  getActions: (item: IOrganization) => ItemType[];
};

export const ListTable = ({ data, total, limit = 20, getActions }: TProps) => {
  const columns = [
    {
      key: 1,
      width: 118,
      title: 'Дата и время регистрации',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <Typography.Text>
          {createdAt
            ? dayjs(createdAt).format('DD.MM.YYYY HH:mm')
            : 'Не указано'}
        </Typography.Text>
      ),
    },
    {
      key: 2,
      title: 'Название организации / ИП ФИО',
      dataIndex: '',
      render: (
        _: unknown,
        { id, type, firstName, lastName, secondName, shortName }: IOrganization
      ) => (
        <Link to={`/organizations/${id}`}>
          <Typography.Text>
            {type === OrganizationTypeEnum.organization
              ? shortName || 'Не указано'
              : getName({ lastName, firstName, secondName }, true, true) ||
                'Не указано'}
          </Typography.Text>
        </Link>
      ),
    },
    {
      key: 3,
      title: 'ИНН',
      dataIndex: 'inn',
      render: inn => <Typography.Text>{inn || 'Не указано'}</Typography.Text>,
    },
    {
      key: 4,
      title: 'ОГРН/ОГРНИП',
      dataIndex: 'ogrn',
      render: ogrn => <Typography.Text>{ogrn}</Typography.Text>,
    },
    {
      key: 5,
      title: 'ФИО владельца',
      dataIndex: '',
      render: (_: unknown, { ownerData }: IOrganization) => (
        <Typography.Text>
          {ownerData ? getName(ownerData, false, true) : 'Не указано'}
        </Typography.Text>
      ),
    },
    {
      key: 6,
      title: 'Кол-во пользователей',
      width: 87,
      dataIndex: 'usersCount',
      render: usersCount => <Typography.Text>{usersCount}</Typography.Text>,
    },
    {
      key: 7,
      title: 'Статус',
      width: 57,
      dataIndex: 'isDraft',
      align: 'center' as const,
      render: isDraft => <Badge status={isDraft ? 'warning' : 'success'} />,
    },
    {
      key: 8,
      title: '',
      width: 65,
      dataIndex: 'actions',
      align: 'center' as const,
      render: (_: unknown, item: IOrganization) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
    />
  );
};
