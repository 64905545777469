// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialItem-module__top--Fgqfl {\n  border-top: 1px solid #D9D9D9;\n  border-bottom: 1px solid #D9D9D9;\n  background-color: #FAFAFA;\n}\n.MaterialItem-module__top--Fgqfl.MaterialItem-module__error--fe68K {\n  border-top: 1px solid var(--ant-error-color);\n  border-bottom: 1px solid var(--ant-error-color);\n}\n.MaterialItem-module__bottom--A5nXm {\n  display: flex;\n  flex-direction: column;\n  background-color: #F5F5F5;\n  padding: 16px 24px 32px;\n  gap: 24px;\n}\n.MaterialItem-module__uploadButtonContainer--jP79U {\n  position: relative;\n}\n.MaterialItem-module__errorUploadMessage--w6EEJ {\n  position: absolute;\n  color: var(--ant-error-color);\n}\n.MaterialItem-module__errorMessage--YGc6I {\n  color: var(--ant-error-color);\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/AddMaterial/components/MaterialItem/MaterialItem.module.less"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,gCAAA;EACA,yBAAA;AACF;AACE;EACE,4CAAA;EACA,+CAAA;AACJ;AAGA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,uBAAA;EACA,SAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,kBAAA;EACA,6BAAA;AAHF;AAMA;EACE,6BAAA;AAJF","sourcesContent":[".top {\n  border-top: 1px solid #D9D9D9;\n  border-bottom: 1px solid #D9D9D9;\n  background-color: #FAFAFA;\n\n  &.error {\n    border-top: 1px solid var(--ant-error-color);\n    border-bottom: 1px solid var(--ant-error-color);\n  }\n}\n\n.bottom {\n  display: flex;\n  flex-direction: column;\n  background-color: #F5F5F5;\n  padding: 16px 24px 32px;\n  gap: 24px;\n}\n\n.uploadButtonContainer {\n  position: relative;\n}\n\n.errorUploadMessage {\n  position: absolute;\n  color: var(--ant-error-color);\n}\n\n.errorMessage {\n  color: var(--ant-error-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": "MaterialItem-module__top--Fgqfl",
	"error": "MaterialItem-module__error--fe68K",
	"bottom": "MaterialItem-module__bottom--A5nXm",
	"uploadButtonContainer": "MaterialItem-module__uploadButtonContainer--jP79U",
	"errorUploadMessage": "MaterialItem-module__errorUploadMessage--w6EEJ",
	"errorMessage": "MaterialItem-module__errorMessage--YGc6I"
};
export default ___CSS_LOADER_EXPORT___;
