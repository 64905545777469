import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, notification, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  updateApplicationsItemStatus,
  loadApplicationsItem,
  removeApplicationsItem,
} from 'store/slices/applications/actions';
import { TApplicationsStatuses } from 'constants/applicationsStatus';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { applicationsTypesNameHash } from 'types/applications';
import { clearApplicationsItemData } from 'store/slices/applications/applicationsSlice';

import { ApplicationsForm } from './components/ApplicationsForm/ApplicationsForm';
import { mapValuesToForm } from './components/formUtils';

export const View: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.applications);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadApplicationsItem(id));
    return () => {
      dispatch(clearApplicationsItemData());
    };
  }, []);

  const onFinish = (status: TApplicationsStatuses) => {
    setIsLoading(true);
    dispatch(updateApplicationsItemStatus({ status, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Заявка успешно изменена',
        });
        navigate('/applications');
      })
      .catch(() => setIsLoading(false));
  };

  const handleDelete = async id => {
    setIsLoading(true);
    await dispatch(removeApplicationsItem({ id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        navigate('/applications');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/applications');
  };

  return (
    <MainLayout
      pageTitle={applicationsTypesNameHash[item.type] || ''}
      extraButtonsList={[
        <Button
          key='delete'
          danger
          onClick={() =>
            item.id && item.id === Number(id) && setIsModalOpen(true)
          }
        >
          Удалить
        </Button>,
      ]}
    >
      {item.id && item.id === Number(id) ? (
        <>
          <ApplicationsForm
            onFinish={onFinish}
            cancelButtonHandler={cancelButtonHandler}
            isLoading={isLoading}
            initialValues={mapValuesToForm(item)}
          />
          {isModalOpen && (
            <StickyConfirmModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              title='Удаление заявки'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину заявку{' '}
                  <Typography.Text strong>{item.name || ''}</Typography.Text>?
                </div>
              }
              onSubmit={() => handleDelete(item.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
