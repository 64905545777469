import React from 'react';
import { Form, FormItemProps } from 'antd';
import { Controller, Path, useFormContext } from 'react-hook-form';

import { IFile } from 'types/file';

import { BaseUploadSingleFile } from './BaseUploadSingleFile';
import styles from './UploadSingleFile.module.less';

interface IProps<T> extends Omit<FormItemProps, 'name'> {
  name: Path<T>;
  validFormatsList?: string[];
  accept?: string;
  uploadsUnsupportedFileType?: string;
  buttonTitle?: string;
  maxFileSize?: number;
  isIgnoreResolution?: boolean
}

export const UploadSingleFile = <T,>({
  name,
  validFormatsList,
  accept,
  uploadsUnsupportedFileType,
  buttonTitle,
  maxFileSize,
  isIgnoreResolution,
  ...props
}: IProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Form.Item
          {...props}
          className={styles.item}
          help={fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
        >
          <BaseUploadSingleFile
            onChange={imageValues => {
              field.onChange(imageValues);
            }}
            value={field.value as IFile}
            name={name}
            validFormatsList={validFormatsList}
            accept={accept}
            uploadsUnsupportedFileType={uploadsUnsupportedFileType}
            buttonTitle={buttonTitle}
            maxFileSize={maxFileSize}
            isIgnoreResolution={isIgnoreResolution}
          />
        </Form.Item>
      )}
    />
  );
};
