import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { Select } from 'components/form/base/Select';
import { AuthorAsyncSelect } from 'components/form/selects/AuthorAsyncSelect';
import { LocaleSelect } from 'components/form/selects/LocaleSelect';
import { LangEnumKeys } from 'constants/lang';
import { getLimitedStatuses } from 'constants/limitedStatuses';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  nameQuery: string;
  status: string | TFormSelectValue;
  authors: string[] | TFormSelectValue[];
  lang: LangEnumKeys | { value: LangEnumKeys; label: string } | '';
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        nameQuery: values.nameQuery && values.nameQuery.trim(),
        status: mapSelect(values.status),
        authors: values.authors.length ? mapSelect(values.authors) : null,
        lang: mapSelect(values.lang),
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      nameQuery: '',
      status: '',
      authors: [],
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='nameQuery'
            label='Название'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select name='status' label='Статус' options={getLimitedStatuses()} />
          <AuthorAsyncSelect
            name='authors'
            label='Автор'
            mode='multiple'
            placeholder='Выберите автора'
          />
          <LocaleSelect
            name='lang'
            label='Язык'
            placeholder='Язык'
            withEmptyValue
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
