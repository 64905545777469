import { createSlice } from '@reduxjs/toolkit';

import { IHistoryState } from './interfaces';
import { getHistory, getHistoryItem } from './actions';

export const b2bHistorySlice = createSlice({
  name: 'b2bHistory',
  initialState: {
    history: {
      language: 'ru',
      historyItems: {
        data: [],
      },
    },
    historyItem: null,
    isLoading: false,
  } as IHistoryState,
  reducers: {
    setHistoryItem: (state, { payload }) => {
      state.historyItem = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getHistory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getHistory.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getHistory.fulfilled, (state, { payload }) => {
      state.history = payload;
      state.isLoading = false;
    });
    builder.addCase(getHistoryItem.fulfilled, (state, { payload }) => {
      state.historyItem = payload;
    });
  },
});
