import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Row } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISliderItem } from '../../../store/slices/slider/interfaces';
import { formatterFieldCount } from '../../../utils/formatterFieldCount';
import { omit } from '../../../utils/helpers';
import { imageValidationSchema, validationSchema } from '../validation';
import { applicationsStatusesFormNamesHash } from '../../../constants/applicationsFormRespondsStatus';
import { TextAreaField } from '../../../components/form/base/TextAreaField';
import { InputField } from '../../../components/form/base/InputField';
import { FormActions } from '../../../components/Layout/FormActions/FormActions';
import { MainImage } from '../../../components/form/MainImage/MainImage';
import { Select } from '../../../components/form/base/Select';

interface IProps {
  isFormType?: boolean;
  isLoading: boolean;
  initialValues: ISliderItem | null;
  onSubmit: (values: ISliderItem) => void;
  withImage?: boolean;
  slug: string;
}

export const SliderForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
  withImage = false,
  slug,
  isFormType = false,
}) => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const methods = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(withImage ? imageValidationSchema : validationSchema),
  });
  const handleCancel = () => {
    navigate(`/${slug}/${lang}/slider`);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Card title='Общая информация' bordered={false}>
          {withImage && (
            <Row>
              <Col flex={'0 0 100px'}>
                <MainImage name='image' label='Фото' required />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <TextAreaField
                name='title'
                label='Заголовок слайда'
                placeholder='Введите заголовок'
                minLength={1}
                maxLength={128}
                rows={2}
                showCount={{
                  formatter: formatterFieldCount,
                }}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaField
                name='subtitle'
                label='Подзаголовок слайда'
                placeholder='Введите подзаголовок'
                minLength={1}
                maxLength={128}
                rows={2}
                showCount={{
                  formatter: formatterFieldCount,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='buttonLabel'
                label='Название кнопки'
                placeholder='Введите название кнопки'
                minLength={1}
                maxLength={60}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='link'
                label='Ссылка'
                placeholder='Введите ссылку'
                addonBefore={<GlobalOutlined />}
              />
            </Col>
          </Row>
          {isFormType && (
            <Row>
              <Col span={24}>
                <Select
                  name='formType'
                  label='Тип формы'
                  allowClear
                  options={[
                    ...Object.keys(
                      omit(
                        applicationsStatusesFormNamesHash,
                        'mobileResponds',
                        'taxBenefitsResponds'
                      )
                    ).map(item => ({
                      value: item,
                      label: applicationsStatusesFormNamesHash[item],
                    })),
                  ]}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <InputField
                type='number'
                name='sortPriority'
                label='Сортировка'
                placeholder='Введите число'
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Сохранить
            </Button>
          </FormActions>
        </Card>
      </form>
    </FormProvider>
  );
};
