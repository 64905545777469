import React, { memo } from 'react';
import { YMaps, Map as YMap, Placemark, Clusterer } from 'react-yandex-maps';
import ReactDOMServer from 'react-dom/server';
import { CloseOutlined } from '@ant-design/icons';

import { mapDefaultPosition, yandexKey } from 'constants/yandex';
import { IRegionRelationsItem } from 'store/slices/regions/interfaces';

import { TGeoObject, TYMapRef } from './types';
import { CLUSTER_LIST_ID } from './constants';
import { BalloonLayout } from './components/BalloonLayout/BalloonLayout';
import { ClusterInnerLayout } from './components/ClusterInnerLayout/ClusterInnerLayout';
import styles from './RegionRelationsMap.module.less';

type TProps = {
  placeMarkList: IRegionRelationsItem[] | [];
};

export const RegionRelationsMap: React.FC<TProps> = memo(
  ({ placeMarkList }) => {
    const [ymaps, setYmaps] = React.useState(null);
    const [mapRef, setMapRef] = React.useState<TYMapRef | null>(null);
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const [activeCluster, setActiveCluster] = React.useState(null);
    const [activeClusterItem, setActiveClusterItem] = React.useState<
      number | null
    >(null);

    React.useEffect(() => {
      if (ymaps && mapRef) {
        mapRef.events.add('click', () => mapRef.balloon.close());
        if (placeMarkList.length > 1) {
          const points = placeMarkList.map(p => p.address.mapPosition);
          mapRef.setBounds(ymaps.util.bounds.fromPoints(points));
        }
        if (placeMarkList.length === 1) {
          mapRef.setCenter(placeMarkList[0].address?.mapPosition, 18);
        }
      }
    }, [ymaps, placeMarkList]);

    const getLayout = (Component: React.ElementType, props: any) => {
      let verticalOffset = 19;
      if (props.type === 'cluster') {
        verticalOffset = 23;
      }
      if (ymaps) {
        const html = ReactDOMServer.renderToString(<Component {...props} />);
        const Layout = ymaps.templateLayoutFactory.createClass(
          `<div id="balloon" class=${styles.balloon}>
            <div id="inner" class=${styles.inner}>
              ${html}
            </div>
            <button class=${styles.close} id="close">
              ${ReactDOMServer.renderToString(<CloseOutlined />)}
            </button>
          </div>`,
          {
            build: function () {
              Layout.superclass.build.call(this);
              this._$element = document.getElementById('balloon');
              this.applyElementOffset();
              this._$closeEl = document.getElementById('close');
              if (this._$closeEl) {
                this._$closeEl.addEventListener(
                  'click',
                  this.onCloseClick.bind(this)
                );
              }
              this._$listElement = document.getElementById(CLUSTER_LIST_ID);
              if (this._$listElement) {
                this._$listElement.addEventListener(
                  'click',
                  this.onListClick.bind(this)
                );
              }
              if (this._$listElement) {
                this._$listElement.scrollTop = scrollPosition;
              }
            },
            applyElementOffset: function () {
              this._$element.style.left =
                -(this._$element.offsetWidth / 2) + 'px';
              this._$element.style.top =
                -this._$element.offsetHeight - verticalOffset + 'px';
            },
            onCloseClick: function (e: any) {
              e.preventDefault();

              this.events.fire('userclose');
            },
            onListClick: function (e: any) {
              e.preventDefault();
              const target = e.target;
              const index = target.getAttribute('data-placemarkid');
              if (index) {
                setScrollPosition(this._$listElement.scrollTop);
                setActiveClusterItem(index);
              }
            },
          }
        );

        return Layout;
      }
      return null;
    };

    const openCluster = (e: any) => {
      const cluster = e.get('cluster');
      if (cluster) {
        const data = cluster.properties.get('geoObjects');
        setActiveCluster(
          data.sort((a: TGeoObject, b: TGeoObject) => {
            const aName = a.properties._data.point.name.toLocaleLowerCase();
            const bName = b.properties._data.point.name.toLocaleLowerCase();
            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          })
        );
        // data.sort change array, cluster.properties.get('geoObjects') has sorted data
        setActiveClusterItem(
          cluster.properties.get('geoObjects')[0].properties._data.index
        );
        setMapCenter(cluster.geometry._coordinates);
      }
    };

    const closeDescription = () => {
      setActiveClusterItem(null);
      setActiveCluster(null);
      setScrollPosition(0);
    };

    const setMapCenter = (coordinates: number) => {
      if (ymaps && mapRef) {
        const projection = mapRef.options.get('projection');
        const pixelCoords = mapRef.converter.globalToPage(
          projection.toGlobalPixels(coordinates, mapRef.getZoom())
        );
        const card = document.getElementById('balloon');
        if (card) {
          pixelCoords[1] -= 150;
          const newCenter = projection.fromGlobalPixels(
            mapRef.converter.pageToGlobal(pixelCoords),
            mapRef.getZoom()
          );
          mapRef.setCenter(newCenter, mapRef.getZoom(), {
            duration: 500,
            timingFunction: 'ease-in-out',
          });
        }
      }
    };

    return (
      <div className={styles.map}>
        <YMaps
          query={{
            apikey: yandexKey,
            load: 'package.full',
          }}
        >
          <YMap
            onLoad={ymaps => setYmaps(ymaps)}
            instanceRef={ref => setMapRef(ref as unknown as TYMapRef)}
            defaultState={{
              center: mapDefaultPosition,
              zoom: 6,
              controls: ['zoomControl'],
            }}
            width='100%'
            height='100%'
          >
            {placeMarkList.length ? (
              <Clusterer
                options={{
                  groupByCoordinates: false,
                  clusterBalloonLayout: getLayout(ClusterInnerLayout, {
                    type: 'cluster',
                    geoObjects: activeCluster,
                    points: placeMarkList,
                    activeClusterItem,
                    setActiveClusterItem,
                    setScrollPosition,
                  }),
                  gridSize: 200,
                  clusterHideIconOnBalloonOpen: false,
                }}
                onBalloonOpen={openCluster}
                onBalloonClose={closeDescription}
              >
                {placeMarkList.map((point, index) => (
                  <Placemark
                    key={point.id}
                    geometry={point?.address?.mapPosition}
                    properties={{
                      balloonContentHeader: point.name,
                      point,
                      index,
                    }}
                    onBalloonOpen={(e: any) => {
                      e.stopPropagation();
                      const coordinates =
                        e.originalEvent?.currentTarget?.geometry?._coordinates;
                      if (coordinates) {
                        setMapCenter(coordinates);
                      }
                    }}
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref: `/public/images/place/${
                        point.data?.category || point.type
                      }.svg`,
                      iconImageSize: [24, 24],
                      iconImageOffset: [-12, -12],
                      balloonLayout: getLayout(BalloonLayout, {
                        point,
                        index,
                      }),
                      hideIconOnBalloonOpen: false,
                    }}
                  />
                ))}
              </Clusterer>
            ) : null}
          </YMap>
        </YMaps>
      </div>
    );
  }
);

RegionRelationsMap.displayName = 'RegionRelationsMap';
