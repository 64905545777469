import React from 'react';
import {
  Form,
  Input,
  InputProps,
  FormItemProps,
  AutoComplete,
  AutoCompleteProps,
} from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { debounce, isString } from 'utils/helpers';

type TProps = FormItemProps<AutoCompleteProps<InputProps>> &
  Omit<AutoCompleteProps<InputProps>, 'placeholder' | 'name'> & {
    name: string;
    params: Record<string, string>;
    nameSearchField?: string;
    url?: string;
    placeholder?: string;
    formItemStyle?: React.CSSProperties;
    withErrorMessage?: boolean;
    prefix?: React.ReactNode;
  };

export const InputAutoCompleteField = ({
  name,
  params,
  required,
  nameSearchField = 'pattern',
  url = urls.api.suggest.get,
  maxLength = 255,
  withErrorMessage = true,
  label,
  placeholder,
  noStyle,
  tooltip,
  extra,
  formItemStyle,
  prefix,
  ...props
}: TProps) => {
  const [options, setOptions] = React.useState<
    Array<{ value: string; label: string }>
  >([]);
  const isFirstFocus = React.useRef<boolean>(false);

  const { control } = useFormContext();

  const getPanelValue = (pattern: string) =>
    apiClient
      .get(url, {
        params: {
          [nameSearchField]: pattern,
          ...params,
        },
      })
      .then(res => {
        const arrStrValue = (
          res.data.values as (string | Record<string, string>)[]
        ).map(item => (isString(item) ? item : Object.values(item)[0]));

        const newOptions = Array.from(new Set(arrStrValue)).map(item => ({
          value: item,
          label: item,
        }));

        setOptions(newOptions);
      })
      .catch(() => []);

  const handleSearch = React.useCallback(
    debounce((value: string) => getPanelValue(value), 400),
    []
  );

  const handleFocus = (value: string) =>
    !isFirstFocus.current &&
    getPanelValue(value).finally(() => (isFirstFocus.current = true));

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={withErrorMessage && fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          required={required}
          noStyle={noStyle}
          tooltip={tooltip}
          extra={extra}
          style={formItemStyle}
        >
          <AutoComplete
            onSelect={(value: string) => field.onChange(value)}
            onSearch={handleSearch}
            options={options}
            onFocus={() => handleFocus(field.value)}
            maxLength={maxLength}
            filterOption
            {...field}
            {...props}
          >
            <Input
              type='text'
              autoComplete='off'
              placeholder={placeholder}
              prefix={prefix}
              style={{
                ...(noStyle &&
                  fieldState.error && {
                    borderColor: '#ff4d4f',
                    boxShadow: '0 0 0 2px rgb(255 77 79 / 20%)',
                  }),
              }}
            />
          </AutoComplete>
        </Form.Item>
      )}
      control={control}
    />
  );
};
