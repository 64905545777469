// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table-module__actionDropdown--sSrWC .ant-dropdown-menu-item-disabled {\n  display: none!important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/CurrentResidents/components/Table/Table.module.less"],"names":[],"mappings":"AAAA;EAEI,uBAAA;AAAJ","sourcesContent":[".actionDropdown{\n  :global(.ant-dropdown-menu-item-disabled) {\n    display: none!important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionDropdown": "Table-module__actionDropdown--sSrWC"
};
export default ___CSS_LOADER_EXPORT___;
