// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddMaterial-module__addButton--FyYKf {\n  margin-bottom: 24px;\n}\n.AddMaterial-module__list--XlQ3l {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/AddMaterial/AddMaterial.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".addButton {\n  margin-bottom: 24px;\n}\n.list{\n  display: flex;\n  flex-direction: column;\n  gap: 24px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "AddMaterial-module__addButton--FyYKf",
	"list": "AddMaterial-module__list--XlQ3l"
};
export default ___CSS_LOADER_EXPORT___;
