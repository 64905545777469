import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import {
  IVacanciesItem,
  TVacanciesDraftItem,
} from 'store/slices/vacancies/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveVacanciesItem,
  saveVacanciesItemAsDraft,
} from 'store/slices/vacancies/actions';

import { VacanciesForm } from './components/VacanciesForm/VacanciesForm';
import { INITIAL_VALUES } from './components/VacanciesForm/constants';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IVacanciesItem) => {
    setIsLoading(true);
    dispatch(saveVacanciesItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вакансия успешно создана',
        });
        navigate('/vacancies');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TVacanciesDraftItem) => {
    setIsLoading(true);
    dispatch(saveVacanciesItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вакансия успешно создана',
        });
        navigate('/vacancies');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/vacancies');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <VacanciesForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={INITIAL_VALUES}
      />
    </MainLayout>
  );
};
