import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  loadOrganization,
  loadOrganizationUserList,
  loadOrganizationApplicationList,
  loadOrganizationProjectList,
} from 'store/slices/organizations/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { OrganizationTypeEnum } from 'types/organizations';
import {
  canViewOrganizationApplications,
  canViewOrganizationProjects,
} from 'utils/rights';

import { ItemInfo } from './components/ItemInfo/ItemInfo';
import { getName } from './utils';

export const View = () => {
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.organizations);
  const { id } = useParams();
  const { role } = useAppSelector(state => state.account);

  React.useEffect(() => {
    dispatch(loadOrganization(id));
    dispatch(loadOrganizationUserList(id));
    if (canViewOrganizationApplications(role)) {
      dispatch(loadOrganizationApplicationList(id));
    }
    if (canViewOrganizationProjects(role)) {
      dispatch(loadOrganizationProjectList(id));
    }
  }, []);

  const { type, shortName, firstName, lastName, secondName } = item;

  const title =
    type === OrganizationTypeEnum.organization
      ? shortName
      : getName({ firstName, lastName, secondName }, true, true);

  return (
    <MainLayout
      pageTitle={title || 'Не заполнено'}
      extraButtonsList={[
        <Link key={1} to={`/organizations/${id}/control`}>
          <Button>Управление</Button>
        </Link>,
      ]}
    >
      {item.id === Number(id) && <ItemInfo />}
    </MainLayout>
  );
};
