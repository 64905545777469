import dayjs from 'dayjs';

import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { Statuses, StatusesEnum } from 'constants/status';
import { TImage } from 'types/image';
import { DeepPartial } from 'types/utils';
import { omit } from 'utils/helpers';
import {
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage,
  mapMaterial,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
  mapValuesToSelect,
  SelectValue,
} from 'utils/mappings';
import {
  CurrentResidentsItem,
  ICurrentResidentsResponseItem,
} from 'store/slices/currentResidents/interfaces';
import { prepareContent } from 'components/form/Content/utils';

import { FormValues } from './types';
import { defaultDateFormat } from '../../../../components/form/base/RangePicker';

export const mapValues = ({
  image,
  status,
  gallery,
  documents,
  lang,
  category,
  residentStatusDate,
  content,
  ...values
}: FormValues): CurrentResidentsItem => ({
  ...omit(values, 'categoryData'),
  content: mapContent(content),
  lang: mapSelect(lang) as LangEnumKeys,
  category: mapSelect(category) as number,
  image: mapImage(image as TImage),
  status: mapSelect(status as SelectValue) as Statuses,
  gallery: mapGallery(gallery),
  documents: mapMaterial(documents as MaterialValue[]),
  region: values.region ? mapRegion(values.region) : null,
  residentStatusDate: dayjs(
    residentStatusDate,
    defaultDateFormat
  ).toISOString(),
});

export const mapValuesAsDraft = ({
  image,

  gallery,
  documents,
  lang,
  residentStatusDate,
  ...values
}: FormValues): DeepPartial<CurrentResidentsItem> => ({
  ...omit(values, 'status'),
  category: values.category ? (mapSelect(values.category) as number) : null,
  content: values.content?.length ? mapContent(values.content) : [],
  lang: mapSelect(lang) as LangEnumKeys,
  image: image ? mapImage(image as TImage) : undefined,
  gallery: gallery?.length ? mapGallery(gallery) : [],
  region: values.region ? mapRegion(values.region) : null,
  documents: documents?.length ? mapMaterial(documents as MaterialValue[]) : [],
  residentStatusDate: residentStatusDate
    ? dayjs(residentStatusDate, defaultDateFormat).toISOString()
    : null,
});

export const mapValuesToForm = ({
  status,
  documents,
  gallery,
  lang,
  residentStatusDate,
  ...values
}: ICurrentResidentsResponseItem) => ({
  ...omit(values, [
    'author',
    'creator',
    'editor',
    'createdAt',
    'updatedAt',
    'authorData',
    'publishedAt',
  ]),
  region: values.region ? mapValuesToRegion(values.region) : null,
  residentStatusDate: residentStatusDate ? dayjs(residentStatusDate) : null,
  content: prepareContent(mapContentToForm(values.content || [])),
  lang: lang || LangEnum.ru,
  status: status === StatusesEnum.DRAFT ? StatusesEnum.PUBLISHED : status,
  documents: documents || [],
  gallery: gallery || [],
  category: values.categoryData ? mapValuesToSelect(values.categoryData) : null,
});
