// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters-module__searchInput--ZVIkc .ant-input-group-addon {\n  background: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reports/components/ViewInfo/components/Documents/components/Filters/Filters.module.less"],"names":[],"mappings":"AAAA;EAGM,mBAAA;AADN","sourcesContent":[".searchInput {\n  :global {\n    .ant-input-group-addon {\n      background: #ffffff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "Filters-module__searchInput--ZVIkc"
};
export default ___CSS_LOADER_EXPORT___;
