export enum MainBusinessKind {
  Slider = 'slider',
  About = 'about',
  Regions = 'regions',
  History = 'history',
  Help = 'help',
}

export const mainBusinessHeaders = {
  [MainBusinessKind.Slider]: 'Слайдер',
  [MainBusinessKind.About]: 'Почему Кавказ.РФ',
  [MainBusinessKind.Regions]: 'Карта с регионами СКФО',
  [MainBusinessKind.History]: 'Истории успеха',
  [MainBusinessKind.Help]: 'Чем мы можем помочь',
};
