import React from 'react';

import { OpeningInfo } from './OpeningInfo';

export const openingInfoRoutes = {
  path: '/openingInfo',
  children: [
    {
      path: '',
      element: <OpeningInfo />,
    },
  ],
};
