import { MenuTypeEnum } from 'store/slices/menu/interfaces';

export const MENU_TYPE_NAME = {
  [MenuTypeEnum.headerB2b]: 'Шапка бизнеса',
  [MenuTypeEnum.headerB2c]: 'Шапка туриста',
  [MenuTypeEnum.footerB2b]: 'Подвал бизнеса',
  [MenuTypeEnum.footerB2c]: 'Подвал туриста',
};

export const MENU_OPTIONS = Object.entries(MENU_TYPE_NAME).map(
  ([value, label]) => ({
    value,
    label,
  })
);

// Бесконечно вложенные пункты меню. Для изменения вложенности изменить цифру и дополнить headerDefaultNestedNames
export const DEFAULT_NESTING_LEVEL = 1;

export const getPrependValue = (depthLevel: number) => ({
  itemName: '',
  isActive: true,
  openNewPage: false,
  url: '',
  ...(depthLevel > 0 && { subItems: [] }),
});
