export enum VacanciesWorkTypeEnum {
  FullEmployment = 'Полная занятость',
  PartEmployment = 'Частичная занятость',
  ProjectWork = 'Проектная работа / Разовое занятие',
  Volunteering = 'Волонтерство',
  Internship = 'Стажировка',
}

export type TVacanciesWorkType = `${VacanciesWorkTypeEnum}`;

export enum VacanciesWorkScheduleTypeEnum {
  FullDayWork = 'Полный день',
  ShiftWork = 'Сменный график',
  FlexibleWork = 'Гибкий график',
  RemoteWork = 'Удаленная работа',
  RotationalWork = 'Вахтовый метод',
}

export type TVacanciesWorkScheduleType = `${VacanciesWorkScheduleTypeEnum}`;

export enum VacanciesWorkExperienceTypeEnum {
  AnyExperience = 'Не имеет значения',
  WithoutExperience = 'Без опыта',
  FromOneToThreeYears = 'От 1 года до 3 лет',
  FromThreeToSixYears = 'От 3 до 6 лет',
  FromSixYears = 'Более 6 лет',
}

export type TVacanciesWorkExperienceType = `${VacanciesWorkExperienceTypeEnum}`;

export enum VacanciesRevenueTypeEnum {
  BeforeDeduction = 'beforeDeduction',
  AfterDeduction = 'afterDeduction',
}

export type TVacanciesRevenueType = `${VacanciesRevenueTypeEnum}`;

export enum VacanciesTempAddressFlagEnum {
  ExcludeAddress = 'excludeAddress',
  IncludeAddress = 'includeAddress',
}

export type TVacanciesTempAddressFlag = `${VacanciesTempAddressFlagEnum}`;
