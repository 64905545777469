// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Role-module__table--oPn56 .ant-table-cell {\n  border-bottom: none;\n}\n.Role-module__table--oPn56 .ant-table-cell::before {\n  content: none !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Organizations/components/ItemControl/components/Role/Role.module.less"],"names":[],"mappings":"AAAA;EAGG,mBAAA;AADH;AAEG;EACC,wBAAA;AAAJ","sourcesContent":[".table {\n\t:global {\n\t\t.ant-table-cell {\n\t\t\tborder-bottom: none;\n\t\t\t&::before {\n\t\t\t\tcontent: none !important;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "Role-module__table--oPn56"
};
export default ___CSS_LOADER_EXPORT___;
