import * as Yup from 'yup';

import { imageSchema } from '../../utils/yup';

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Введите от 1 до 128 символов')
    .max(128, 'Введите от 1 до 128 символов')
    .required(),
  subtitle: Yup.string().max(128, 'Введите до 128 символов'),
  buttonLabel: Yup.string().max(60, 'Введите до 60 символов'),
  link: Yup.string()
    .url()
    .test('oneOf', 'Введите либо ссылку либо тип формы', (value, ctx) =>
      value ? !ctx.parent.formType : true
    ),
  formType: Yup.mixed().test(
    'oneOf',
    'Введите либо ссылку либо тип формы',
    (value, ctx) => (value ? !ctx.parent.link : true)
  ),
  sortPriority: Yup.number().min(1).required(),
});

export const imageValidationSchema = validationSchema.shape({
  image: imageSchema,
});
