import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, notification, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IPurchasesItem,
  TPurchasesDraftItem,
} from 'store/slices/purchases/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  deletePurchasesItem,
  loadPurchasesItem,
  savePurchasesItem,
  savePurchasesItemAsDraft,
} from 'store/slices/purchases/actions';
import { StatusesEnum } from 'constants/status';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';

import { PurchasesForm } from './components/PurchasesForm/PurchasesForm';
import { mapValuesToForm } from './components/PurchasesForm/formUtils';

export const Edit = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.purchases);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadPurchasesItem(id));
  }, []);

  const onFinish = (values: IPurchasesItem) => {
    setIsLoading(true);
    dispatch(savePurchasesItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/purchases');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TPurchasesDraftItem) => {
    setIsLoading(true);
    dispatch(savePurchasesItemAsDraft({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/purchases');
      })
      .catch(() => setIsLoading(false));
  };

  const handleDelete = async id => {
    setIsLoading(true);
    await dispatch(deletePurchasesItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        navigate('/purchases');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/purchases');
  };

  return (
    <MainLayout
      pageTitle='Редактирование'
      extraButtonsList={[
        <Button
          key='delete'
          danger
          onClick={() => String(item.id) == id && setIsModalOpen(true)}
        >
          Удалить
        </Button>,
      ]}
    >
      {item.id && String(item.id) == id ? (
        <>
          <PurchasesForm
            onFinish={onFinish}
            onSaveDraft={onSaveDraft}
            cancelButtonHandler={cancelButtonHandler}
            isDraft={item.status === StatusesEnum.DRAFT}
            isLoading={isLoading}
            initialValues={mapValuesToForm(item)}
          />
          {isModalOpen && (
            <StickyConfirmModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              title='Удаление закупки'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину закупку{' '}
                  <Typography.Text strong>{item.name || ''}</Typography.Text>?
                </div>
              }
              onSubmit={() => handleDelete(item.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
