import React from 'react';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import {
  DeleteOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import { DatePicker } from 'components/form/base/DatePicker';
import { useCheckNodeWidth } from 'hooks/useCheckNodeWidth';
import { ScheduleTimePickerItem } from 'components/form/Schedule/components/ScheduleTimePickerItem';

import styles from './PlaceSessions.module.less';

type TSessionsErrors = {
  from?: {
    message: string;
  };
  to?: {
    message: string;
  };
};

type TFieldItem = {
  id: string;
  date: null;
  from: null;
  to: null;
  fullDay: boolean;
};

const BREAKPOINT_WIDTH = 685;

export const PlaceSessions: React.FC<{ name: string }> = ({ name }) => {
  const ref = React.useRef(null);
  const { control } = useFormContext();
  const value = useWatch({ name, control });

  const isBreakPointReached = useCheckNodeWidth({
    node: ref?.current,
    targetWidth: BREAKPOINT_WIDTH,
  });

  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  });

  const onAddSession = () => {
    append({ date: null, from: null, to: null, fullDay: false });
  };

  return (
    <div ref={ref}>
      {(fields as TFieldItem[]).map((item, index) => {
        return (
          <Controller
            key={item.id}
            name={`${name}.${index}`}
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error as TSessionsErrors;
              return (
                <Row
                  className={classNames(styles.sessionContainer, {
                    [styles.mobileWidth]: isBreakPointReached,
                  })}
                >
                  {isBreakPointReached && (
                    <Col span={24}>
                      <Form.Item style={{ padding: 0, margin: 0 }}>{`${
                        index + 1
                      }. Сеанс`}</Form.Item>
                    </Col>
                  )}
                  <Col
                    className={classNames(styles.datePicker, {
                      [styles.mobileWidth]: isBreakPointReached,
                    })}
                  >
                    <DatePicker
                      name={`${field.name}.date`}
                      placeholder='Выберите дату'
                    />
                  </Col>

                  <Col
                    className={classNames(styles.timePickerFieldContainer, {
                      [styles.mobileWidth]: isBreakPointReached,
                      [styles.error]:
                        isBreakPointReached && !!error?.from?.message,
                    })}
                  >
                    <ScheduleTimePickerItem
                      name={`${field.name}.from`}
                      prefixTitle='с'
                      isBreakPointReached={isBreakPointReached}
                      suffixIcon={
                        error?.from?.message && !isBreakPointReached ? (
                          <WarningOutlined
                            style={{ color: 'var(--ant-error-color)' }}
                          />
                        ) : null
                      }
                      disabled={field.value.fullDay}
                      errorMessage={error?.from?.message}
                    />
                  </Col>

                  <Col
                    className={classNames(styles.timePickerFieldContainer, {
                      [styles.mobileWidth]: isBreakPointReached,
                      [styles.error]:
                        isBreakPointReached && !!error?.to?.message,
                    })}
                  >
                    <ScheduleTimePickerItem
                      name={`${field.name}.to`}
                      prefixTitle='до'
                      isBreakPointReached={isBreakPointReached}
                      suffixIcon={
                        error?.to?.message && !isBreakPointReached ? (
                          <WarningOutlined
                            style={{ color: 'var(--ant-error-color)' }}
                          />
                        ) : null
                      }
                      disabled={field.value.fullDay}
                      errorMessage={error?.to?.message}
                    />
                  </Col>

                  <Col style={{ marginBottom: 24 }}>
                    <Row align='middle' style={{ height: '100%' }}>
                      <Col span={6}>
                        <Checkbox
                          checked={field.value.fullDay}
                          disabled={
                            value && (value[index]?.from || value[index]?.to)
                          }
                          onChange={e =>
                            field.onChange({
                              ...field.value,
                              fullDay: e.target.checked,
                            })
                          }
                        >
                          Круглосуточно
                        </Checkbox>
                      </Col>
                    </Row>
                  </Col>

                  {!isBreakPointReached ? (
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: 0, marginBottom: 24 }}
                    />
                  ) : (
                    <Col xs={{ span: 24 }}>
                      <Button
                        danger
                        onClick={() => remove(index)}
                        style={{ width: '100%', marginBottom: 24 }}
                      >
                        Удалить сеанс
                      </Button>
                    </Col>
                  )}
                </Row>
              );
            }}
          />
        );
      })}

      <Row gutter={24}>
        <Button
          type='link'
          icon={<PlusCircleOutlined />}
          onClick={onAddSession}
        >
          Добавить сеанс
        </Button>
      </Row>
    </div>
  );
};
