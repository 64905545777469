import React from 'react';

import { LangEnumKeys } from 'constants/lang';
import { apiClient } from 'utils/http';
import { urls } from 'store/api';
import { ICatalogItem } from 'store/slices/catalog/interfaces';

import { HotelComfortGroupsComponent } from './components/HotelComfortGroupsComponent';

export const HotelComfortGroups: React.FC<{
  name: string;
  lang: LangEnumKeys;
}> = ({ name, lang }) => {
  const [options, setOptions] = React.useState<ICatalogItem[]>([]);

  React.useEffect(() => {
    const getComfortGroups = async () => {
      const { data } = await apiClient.get(urls.api.catalog.get, {
        params: {
          entityType: 'hotels',
          fieldType: 'hotelComfortGroups',
          lang,
        },
      });

      if (data?.data?.length) {
        const comforts = data.data.map(comfort => ({
          ...comfort,
          value: comfort.id,
          label: comfort.name,
        }));
        setOptions(comforts);
      }
    };

    getComfortGroups();
  }, []);

  return <HotelComfortGroupsComponent options={options} name={name} />;
};
