import React from 'react';
import { Button, Carousel, Image, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'ahooks';

import { getImageUrl } from 'utils/image';

import { ImageInfoPopover } from '../components/ImageInfoPopover';
import styles from './ViewGallery.module.less';

export const ViewGallery = ({ gallery }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const carouselRef = React.useRef(null);
  const responsive = useResponsive();

  return (
    <div className={styles.mainGalleryContainer}>
      <div className={styles.carouselContainer}>
        <Carousel ref={carouselRef} afterChange={setCurrentSlideIndex}>
          {gallery.map((image, index) => {
            return (
              <div key={index}>
                <div style={{ position: 'relative' }}>
                  <Image
                    width={'100%'}
                    height={240}
                    preview={false}
                    src={
                      image
                        ? responsive.middle
                          ? getImageUrl(image, 360, 240)
                          : getImageUrl(image, 310, 240) ||
                            '/public/images/no-image.svg'
                        : '/public/images/no-image.svg'
                    }
                    fallback='/public/images/no-image.svg'
                    className={styles.img}
                  />
                  <ImageInfoPopover image={image} />
                </div>
              </div>
            );
          })}
        </Carousel>
        <Row
          style={{
            width: '100%',
            height: '50px',
          }}
          justify='space-between'
          align='middle'
        >
          <Button onClick={() => carouselRef.current.prev()}>
            <LeftOutlined />
          </Button>
          <span>{`${currentSlideIndex + 1} / ${gallery.length}`}</span>
          <Button onClick={() => carouselRef.current.next()}>
            <RightOutlined />
          </Button>
        </Row>
      </div>
    </div>
  );
};
