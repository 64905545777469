import { isString } from 'utils/helpers';

export const urls = {
  api: {
    auth: {
      login: {
        post: '/auth/login',
      },
      register: {
        post: '/auth/register',
      },
      registerEmailRetry: {
        post: '/auth/register-email-retry',
      },
      refreshToken: {
        post: '/auth/refresh-token',
      },
      passwordResetRequest: {
        get: '/auth/password-reset-request',
      },
      passwordResetConfirm: {
        post: '/auth/password-reset-confirm',
      },
      emailConfirm: {
        get: '/auth/email-confirm',
      },
    },
    users: {
      get: '/users',
      profile: {
        get: '/users/profile',
      },
      business: {
        get: '/users/business',
        getOne: '/users/business/:id',
      },
      tourist: {
        get: '/users/tourist',
      },
      getOne: {
        admin: 'users/admin/:id',
        business: 'users/business/:id',
        tourist: 'users/tourist/:id',
      },
      patch: {
        admin: 'users/admin/:id',
        business: 'users/business/:id',
        tourist: 'users/tourist/:id',
      },
    },
    attractions: {
      get: '/attractions',
      getOne: '/attractions/:id',
      post: '/attractions',
      patch: '/attractions/:id',
      delete: '/attractions/:id',
      draft: {
        post: '/attractions/draft',
        patch: '/attractions/draft/:id',
      },
      publish: {
        patch: '/attractions/publish/:id',
      },
      unPublish: {
        patch: '/attractions/unPublish/:id',
      },
      public: {
        get: '/attractions/public',
      },
      bulkPatch: '/attractions/bulk',
    },
    hotels: {
      get: '/hotels',
      getOne: '/hotels/:id',
      post: '/hotels',
      patch: '/hotels/:id',
      delete: '/hotels/:id',
      draft: {
        post: '/hotels/draft',
        patch: '/hotels/draft/:id',
      },
      publish: {
        patch: '/hotels/publish/:id',
      },
      unPublish: {
        patch: '/hotels/unPublish/:id',
      },
      public: {
        get: '/hotels/public',
      },
      bulkPatch: '/hotels/bulk',
    },
    restaurants: {
      get: '/restaurants',
      getOne: '/restaurants/:id',
      post: '/restaurants',
      patch: '/restaurants/:id',
      delete: '/restaurants/:id',
      draft: {
        post: '/restaurants/draft',
        patch: '/restaurants/draft/:id',
      },
      publish: {
        patch: '/restaurants/publish/:id',
      },
      unPublish: {
        patch: '/restaurants/unPublish/:id',
      },
      public: {
        get: '/restaurants/public',
      },
      bulkPatch: '/restaurants/bulk',
    },
    resorts: {
      get: '/resorts',
      getOne: '/resorts/:id',
      post: '/resorts',
      patch: '/resorts/:id',
      delete: '/resorts/:id',
      draft: {
        post: '/resorts/draft',
        patch: '/resorts/draft/:id',
      },
      publish: {
        patch: '/resorts/publish/:id',
      },
      unPublish: {
        patch: '/resorts/unPublish/:id',
      },
      public: {
        get: '/resorts/public',
      },
      bulkPatch: '/resorts/bulk',
    },
    routes: {
      get: '/routes',
      getOne: '/routes/:id',
      post: '/routes',
      patch: '/routes/:id',
      delete: '/routes/:id',
      draft: {
        post: '/routes/draft',
        patch: '/routes/draft/:id',
      },
      publish: {
        patch: '/routes/publish/:id',
      },
      unPublish: {
        patch: '/routes/unPublish/:id',
      },
      public: {
        get: '/routes/public',
      },
      bulkPatch: '/routes/bulk',
    },
    catalog: {
      post: '/catalog',
      patch: '/catalog/:id',
      get: '/catalog',
      getOne: '/catalog/:id',
      delete: '/catalog/:id',
      relations: {
        get: '/catalog/relations',
      },
      regions: {
        get: '/catalog/regions',
      },
    },
    news: {
      draft: {
        post: '/news/draft',
        patch: '/news/draft/:id',
      },
      post: '/news',
      get: '/news',
      getOne: '/news/:id',
      patch: '/news/:id',
      delete: '/news/:id',
      publish: {
        patch: '/news/publish/:id',
      },
      unPublish: {
        patch: '/news/unPublish/:id',
      },
      public: {
        get: '/news/public',
      },
    },
    articles: {
      draft: {
        post: '/articles/draft',
        patch: '/articles/draft/:id',
      },
      post: '/articles',
      get: '/articles',
      getOne: '/articles/:id',
      patch: '/articles/:id',
      bulkPatch: '/articles/update-many/bulk',
      delete: '/articles/:id',
      publish: {
        patch: '/articles/publish/:id',
      },
      unPublish: {
        patch: '/articles/unPublish/:id',
      },
      public: {
        get: '/articles/public',
      },
    },
    events: {
      getMainTourism: '/events/mainTourism',
      get: '/events',
      getOne: '/events/:id',
      post: '/events',
      patch: '/events/:id',
      delete: '/events/:id',
      draft: {
        post: '/events/draft',
        patch: '/events/draft/:id',
      },
      publish: {
        patch: '/events/publish/:id',
      },
      unPublish: {
        patch: '/events/unPublish/:id',
      },
      public: {
        get: '/events/public',
      },
      bulkPatch: '/events/bulk',
    },
    infrastructures: {
      get: '/infrastructures',
      getOne: '/infrastructures/:id',
      post: '/infrastructures',
      patch: '/infrastructures/:id',
      delete: '/infrastructures/:id',
    },
    regions: {
      get: '/regions',
      getOne: '/regions/:id',
      patch: '/regions/:id',
      put: '/regions/:id',
    },
    regionRelations: {
      get: '/regionRelations',
      updateVisibility: {
        patch: '/regionRelations/update-visibility/:id',
      },
    },
    upload: {
      image: {
        post: '/uploads/image',
      },
      audio: {
        post: '/uploads/audio',
      },
      material: {
        post: '/uploads/material',
      },
    },
    faq: {
      get: '/question-answer',
      getOne: '/question-answer/:id',
      post: '/question-answer',
      patch: '/question-answer/:id',
      delete: '/question-answer/:id',
    },
    investmentProjects: {
      get: '/invest-projects',
      getOne: '/invest-projects/:id',
      post: '/invest-projects',
      patch: '/invest-projects/:id',
      draft: {
        post: '/invest-projects/draft',
        patch: '/invest-projects/draft/:id',
      },
      publish: {
        patch: '/invest-projects/publish/:id',
      },
      unPublish: {
        patch: '/invest-projects/unPublish/:id',
      },
      delete: '/invest-projects/:id',
    },
    currentResidents: {
      get: '/current-residents',
      getOne: '/current-residents/:id',
      post: '/current-residents',
      patch: '/current-residents/:id',
      draft: {
        post: '/current-residents/draft',
        patch: '/current-residents/draft/:id',
      },
      publish: {
        patch: '/current-residents/publish/:id',
      },
      unPublish: {
        patch: '/current-residents/unPublish/:id',
      },
      delete: '/current-residents/:id',
    },
    investmentSolutions: {
      get: '/ready-solutions-for-investments',
      getOne: '/ready-solutions-for-investments/:id',
      post: '/ready-solutions-for-investments',
      patch: '/ready-solutions-for-investments/:id',
      draft: {
        post: '/ready-solutions-for-investments/draft',
        patch: '/ready-solutions-for-investments/draft/:id',
      },
      publish: {
        patch: '/ready-solutions-for-investments/publish/:id',
      },
      unPublish: {
        patch: '/ready-solutions-for-investments/unPublish/:id',
      },
      delete: '/ready-solutions-for-investments/:id',
    },
    signedAgreements: {
      get: '/contract-conclusions-info',
      getOne: '/contract-conclusions-info/:id',
      post: '/contract-conclusions-info',
      patch: '/contract-conclusions-info/:id',
      draft: {
        post: '/contract-conclusions-info/draft',
        patch: '/contract-conclusions-info/draft/:id',
      },
      publish: {
        patch: '/contract-conclusions-info/publish/:id',
      },
      unPublish: {
        patch: '/contract-conclusions-info/unPublish/:id',
      },
      delete: '/contract-conclusions-info/:id',
    },
    purchasePlans: {
      get: '/purchase-plan',
      getOne: '/purchase-plan/:id',
      post: '/purchase-plan',
      patch: '/purchase-plan/:id',
      draft: {
        post: '/purchase-plan/draft',
        patch: '/purchase-plan/draft/:id',
      },
      publish: {
        patch: '/purchase-plan/publish/:id',
      },
      unPublish: {
        patch: '/purchase-plan/unPublish/:id',
      },
      delete: '/purchase-plan/:id',
    },
    regulations: {
      get: '/regulations',
      getOne: '/regulations/:id',
      post: '/regulations',
      patch: '/regulations/:id',
      draft: {
        post: '/regulations/draft',
        patch: '/regulations/draft/:id',
      },
      publish: {
        patch: '/regulations/publish/:id',
      },
      unPublish: {
        patch: '/regulations/unPublish/:id',
      },
      delete: '/regulations/:id',
    },
    supportMeasures: {
      get: '/support-measures',
      getOne: '/support-measures/:id',
      post: '/support-measures',
      patch: '/support-measures/:id',
      draft: {
        post: '/support-measures/draft',
        patch: '/support-measures/draft/:id',
      },
      publish: {
        patch: '/support-measures/publish/:id',
      },
      unPublish: {
        patch: '/support-measures/unPublish/:id',
      },
      delete: '/support-measures/:id',
    },
    purchases: {
      get: '/purchases',
      getOne: '/purchases/:id',
      post: '/purchases',
      patch: '/purchases/:id',
      draft: {
        post: '/purchases/draft',
        patch: '/purchases/draft/:id',
      },
      status: {
        put: '/purchases/status/:id',
      },
      delete: '/purchases/:id',
      remove: '/purchases/remove/:id',
    },
    sales: {
      get: '/sales',
      getOne: '/sales/:id',
      post: '/sales',
      put: '/sales/:id',
      draft: {
        post: '/sales/draft',
        put: '/sales/draft/:id',
      },
      status: {
        put: '/sales/status/:id',
      },
      delete: '/sales/:id',
      remove: {
        delete: '/sales/remove/:id',
      },
    },
    rentals: {
      get: '/rent',
      getOne: '/rent/:id',
      post: '/rent',
      put: '/rent/:id',
      draft: {
        post: '/rent/draft',
        put: '/rent/draft/:id',
      },
      status: {
        put: '/rent/status/:id',
      },
      delete: '/rent/:id',
      remove: {
        delete: '/rent/remove/:id',
      },
    },
    forms: {
      landLeaseApplications: {
        download: {
          get: '/forms/land-lease-applications/download/:formId',
        },
      },
      urbanPlanning: {
        download: {
          get: '/forms/land-plot-urban-planning-applications/download/:formId',
        },
      },
      connectITO: {
        download: {
          get: '/forms/connection-networks-ito-applications/download/:formId',
        },
      },
      realEstateNonResidents: {
        download: {
          get: '/forms/lease-real-estate-applications/download/:formId',
        },
      },
      cadastralWork: {
        download: {
          get: '/forms/cadastral-work-applications/download/:formId',
        },
      },
      projects: {
        download: {
          form: {
            get: '/forms/projects/download/form/:formId',
          },
          request: {
            get: '/forms/projects/download/request/:formId',
          },
        },
      },
    },
    formApplications: {
      get: '/forms/applications',
      getOne: '/forms/:id',
      updateApplicationStatus: {
        patch: '/forms/:id',
      },
      delete: '/forms/:id',
    },
    formProjects: {
      get: '/forms/projects',
      getOne: '/forms/:id',
      updateProject: {
        patch: '/forms/projects/:formId',
      },
      delete: '/forms/:id',
    },
    formDocuments: {
      get: '/form-documents/:formId',
      post: '/form-documents',
      delete: '/form-documents/:fromId',
      creators: {
        get: 'form-documents/:formId/creators',
      },
    },
    formComments: {
      get: '/form-comments/:formId',
      post: '/form-comments',
    },
    investmentPassports: {
      get: '/invest-passports',
      getOne: '/invest-passports/:id',
      post: '/invest-passports',
      put: '/invest-passports/:id',
      draft: {
        post: '/invest-passports/draft',
        put: '/invest-passports/draft/:id',
      },
      unPublish: {
        patch: '/invest-passports/unpublish/:id',
      },
      delete: '/invest-passports/:id',
    },
    investInfrastructures: {
      get: '/invest-infrastructures',
      getOne: '/invest-infrastructures/:id',
      post: '/invest-infrastructures',
      put: '/invest-infrastructures/:id',
      draft: {
        post: '/invest-infrastructures/draft',
        put: '/invest-infrastructures/draft/:id',
      },
      unPublish: {
        patch: '/invest-infrastructures/unpublish/:id',
      },
      delete: '/invest-infrastructures/:id',
    },
    investInfrastructureResponds: {
      get: '/invest-infrastructures/responds',
      getOne: '/invest-infrastructures/responds/:id',
      delete: '/invest-infrastructures/responds/:id',
    },
    supportResponds: {
      get: '/support',
      getOne: '/support/:id',
      delete: '/support/:id',
    },
    contactsResponds: {
      get: '/contacts',
      getOne: '/contacts/:id',
      delete: '/contacts/:id',
    },
    taxBenefitsResponds: {
      get: '/tax-benefits',
      getOne: '/tax-benefits/:id',
      delete: '/tax-benefits/:id',
    },
    taxBenefitsCommonResponds: {
      get: '/tax-benefits',
      getOne: '/tax-benefits/:id',
      delete: '/tax-benefits/:id',
    },
    materials: {
      get: '/materials',
      getOne: '/materials/:id',
      post: '/materials',
      put: '/materials/:id',
      draft: {
        post: '/materials/draft',
        put: '/materials/draft/:id',
      },
      unPublish: {
        patch: '/materials/unpublish/:id',
      },
      publish: {
        patch: '/materials/publish/:id',
      },
      delete: '/materials/:id',
    },
    organizations: {
      get: '/organizations',
      getOne: '/organizations/:id',
      getByUser: '/organizations/by-user/:id',
      getUsers: '/organizations/admin/users-list/:id',
      patchRoles: '/organizations/admin/user/changeRoles',
      patchBlock: '/organizations/admin/user/block',
      patchResident: '/organizations/set-resident/:id',
    },
    vacancies: {
      get: '/vacancies',
      getOne: '/vacancies/:id',
      post: '/vacancies',
      put: '/vacancies/:id',
      draft: {
        post: '/vacancies/draft',
        put: '/vacancies/draft/:id',
      },
      status: {
        patch: '/vacancies/status/:id',
      },
      delete: '/vacancies/:id',
    },
    vacanciesResponds: {
      get: '/vacancy-responds',
    },
    menu: {
      get: '/menu',
      put: '/menu',
    },
    priorityIndustry: {
      get: '/priority-industry/:lang',
      put: '/priority-industry',
    },
    openingInfo: {
      get: '/opening-info',
      put: '/opening-info',
      chapter: {
        get: '/opening-info/chapter',
        post: '/opening-info/chapter',
        put: '/opening-info/chapter/:id',
        delete: '/opening-info/chapter/:id',
      },
      strategy: {
        get: '/opening-info/strategy',
        post: '/opening-info/strategy',
        put: '/opening-info/strategy/:id',
        delete: '/opening-info/strategy/:id',
      },
      annual: {
        get: '/opening-info/Annual',
        post: '/opening-info/Annual',
        put: '/opening-info/Annual/:id',
        delete: '/opening-info/Annual/:id',
      },
      conditions: {
        get: '/opening-info/conditions',
        post: '/opening-info/conditions',
        put: '/opening-info/conditions/:id',
        delete: '/opening-info/conditions/:id',
      },
      landmark: {
        get: '/opening-info/landmark',
        post: '/opening-info/landmark',
        put: '/opening-info/landmark/:id',
        delete: '/opening-info/landmark/:id',
      },
      nested: {
        get: '/opening-info/nested',
        post: '/opening-info/nested',
        put: '/opening-info/nested/:id',
        delete: '/opening-info/nested/:id',
      },
    },
    reviews: {
      post: '/reviews',
      get: '/reviews/public',
      getOne: '/reviews/public/:id',
      patch: '/reviews/:id',
      delete: '/reviews/admin/:id',
      profile: {
        get: '/reviews/b2c',
      },
      admin: {
        get: '/reviews/admin',
        getOne: '/reviews/admin/:id',
      },
    },
    suggest: {
      get: '/suggest',
    },
    reports: {
      get: '/reports',
      getOne: '/reports/:id',
      patch: '/reports/status/:id',
      delete: '/reports/:id',
    },
    reportsComments: {
      get: '/reports/comments/:id',
      post: '/reports/comments',
    },
    reportsDocuments: {
      get: '/reports/documents/:id',
      post: '/reports/documents',
    },
    contractExchange: {
      get: '/contract-exchanges',
      getOne: '/contract-exchanges/:id',
      post: '/contract-exchanges',
      put: '/contract-exchanges/:id',
      draft: {
        put: '/contract-exchanges/draft/:id',
      },
      delete: '/contract-exchanges/:id',
      unPublish: {
        patch: '/contract-exchanges/unPublish/:id',
      },
      publish: {
        patch: '/contract-exchanges/publish/:id',
      },
    },
    mobileResponds: {
      get: '/mobile-responds',
      getOne: '/mobile-responds/:id',
      delete: '/mobile-responds/:id',
    },
    mainBusiness: {
      regions: {
        patch: '/main-business/region/:id',
        getOne: '/main-business/region/:id',
      },
    },
    alert: {
      get: '/main-info/public/:type/:lang',
      patch: '/main-info/:id',
      getOne: '/main-info/:id',
    },
    activities: {
      get: '/activities',
    },
    preview: {
      get: '/Preview/:id',
      post: '/Preview',
    },
  },
};

export const getUrlWithParams = (url: string, params = {}) => {
  if (!isString(url)) {
    throw new Error('url must be string');
  }
  let urlWithParams = url;
  Object.keys(params).forEach(param => {
    if (!params[param]) {
      throw new Error(`Not found param ${param} in url pattern ${url}`);
    }
    urlWithParams = urlWithParams.replace(
      `:${param}`,
      params[param].toString()
    );
  });
  return urlWithParams;
};
