import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Col, notification, Pagination, PaginationProps, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteAnnual,
  loadAllAnnual,
  loadAnnual,
  saveAnnual,
  updateAnnual,
  updateOpeningInfo,
} from 'store/slices/openingInfo/actions';
import { TAnnual } from 'store/slices/openingInfo/interfaces';
import { TContentBlock } from 'components/form/Content/contentTypes';
import { ContentField } from 'components/form/Content/ContentField';
import { mapContent } from 'utils/mappings';
import { useDeepEffect } from 'utils/useDeepEffect';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { SimpleForm } from '../EntityForms/SimpleForm';
import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import styles from './Annual.module.less';

const PAGE_SIZE = 3;

export const VALIDATION_SCHEMA = Yup.object().shape({
  content: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().oneOf(['text']).required(),
      text: Yup.string(),
    })
  ),
});

interface IFormValues {
  content: TContentBlock[];
}

interface IProps {
  initialValues: IFormValues;
}

export const Annual = ({ initialValues }: IProps) => {
  const [offset, setOffset] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);
  const swapElRef = useRef<{ annual: TAnnual; step: number }>(null);

  const {
    annual: { list, total },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const methods = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: 'onBlur',
    shouldFocusError: false,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const loadListAnnual = async (offset: number) =>
    await dispatch(
      loadAnnual({
        limit: PAGE_SIZE,
        offset: (offset - 1) * PAGE_SIZE,
      })
    ).unwrap();

  const updateAnnualOnAnotherPage = async (list: Array<TAnnual>) => {
    const { annual, step } = swapElRef.current;
    const swapAnnual = list[step === 1 ? 0 : list.length - 1];

    await dispatch(
      updateAnnual({
        id: swapAnnual.id,
        file: swapAnnual.file,
        title: swapAnnual.title,
        sortIndex: annual.sortIndex,
      })
    );

    await dispatch(
      updateAnnual({
        id: annual.id,
        file: annual.file,
        title: annual.title,
        sortIndex: swapAnnual.sortIndex,
      })
    );

    await loadListAnnual(offset);
    swapElRef.current = null;
  };

  useDeepEffect(() => {
    loadListAnnual(offset).then(({ data }) => {
      if (swapElRef.current) {
        updateAnnualOnAnotherPage(data);
      }
    });
  }, [offset]);

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  const handleChangePage: PaginationProps['onChange'] = page => setOffset(page);

  const handleAdd = useCallback(
    async (file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      await dispatch(saveAnnual({ file, title, sortIndex: total + 1 }));

      const lastPage = Math.ceil((total + 1) / PAGE_SIZE);
      if (offset === lastPage) {
        loadListAnnual(lastPage);
      } else {
        setOffset(lastPage);
      }
    },
    [total]
  );

  const handleUpdate = useCallback(
    async (file: IDocument, title: string, { id, sortIndex }: TAnnual) => {
      await dispatch(updateAnnual({ file, title, id, sortIndex }));
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleMove = useCallback(
    async (annual: TAnnual, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === annual.id);
      const swapEl = list[currentIndex + step];

      if (swapEl) {
        await dispatch(
          updateAnnual({
            id: swapEl.id,
            file: swapEl.file,
            title: swapEl.title,
            sortIndex: annual.sortIndex,
          })
        );

        await dispatch(
          updateAnnual({
            id: annual.id,
            file: annual.file,
            title: annual.title,
            sortIndex: swapEl.sortIndex,
          })
        );
        await loadListAnnual(offset);
      } else {
        swapElRef.current = { annual, step };
        setOffset(prevState => prevState + step);
      }
    },
    [offset, list]
  );

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteAnnual(id));
      handleCloseModal();
      notification.success({
        message: 'Документ удалён',
      });

      const list = await dispatch(loadAllAnnual()).unwrap();

      await Promise.all(
        list.data.map(({ id, file, title }, index) =>
          dispatch(
            updateAnnual({
              id,
              file,
              title,
              sortIndex: index + 1,
            })
          )
        )
      );

      const currentlastPage = Math.ceil(total / PAGE_SIZE);
      const updatelastPage = Math.ceil((total - 1) / PAGE_SIZE);

      if (currentlastPage !== updatelastPage && offset !== 1) {
        setOffset(prevState => prevState - 1);
      } else {
        await loadListAnnual(offset);
      }
    },
    [offset, total]
  );

  const handleSubmit = async (values: IFormValues) => {
    const prepareValues = mapContent(values.content);
    const description = prepareValues[0] ? prepareValues[0].text : null;
    await dispatch(updateOpeningInfo({ description }));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <CustomUpload onAdd={handleAdd} disabled={loading} />
        </Col>
      </Row>
      {total > 0 && (
        <Row>
          <Col span={24}>
            {list.map(item => (
              <SimpleForm
                key={item.id}
                onMove={handleMove}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                entity={item}
                total={total}
              />
            ))}
          </Col>
        </Row>
      )}
      <Row justify='end'>
        <Col>
          <Pagination
            className={styles.pagination}
            size='small'
            current={offset}
            onChange={handleChangePage}
            total={total}
            pageSize={PAGE_SIZE}
            hideOnSinglePage
          />
        </Col>
      </Row>
      <Row className={styles.description}>
        <Col span={24}>
          <FormProvider {...methods}>
            <form
              className='ant-form ant-form-vertical'
              onBlur={methods.handleSubmit(handleSubmit)}
            >
              <ContentField
                name='content'
                label='Описание'
                placeholder='Напишите'
                enabledTypes={[]}
              />
            </form>
          </FormProvider>
        </Col>
      </Row>
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          text='Вы уверены, что хотите удалить документ?'
        />
      )}
    </div>
  );
};
