import React from 'react';

import { LangEnumKeys } from 'constants/lang';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { ListResponse } from 'types/pagination';
import { InvestmentProjectsItem } from 'store/slices/investmentProjects/interfaces';
import { StatusesEnum } from 'constants/status';

import { AsyncSelect, TAsyncSelectProps } from '../base/AsyncSelect';

interface IProps
  extends Omit<TAsyncSelectProps, 'fetchOptions' | 'fetchInitialValue'> {
  requestParams: {
    lang?: LangEnumKeys;
    status?: StatusesEnum;
  };
  placeholder?: string;
}

export const InvestProjectAsyncSelect: React.FC<IProps> = ({
  label,
  mode = undefined,
  requestParams,
  ...props
}) => {
  const requestUrl = urls.api.investmentProjects.get;
  const fetchDataFn = async name => {
    const res = await apiClient.get<ListResponse<InvestmentProjectsItem>>(
      requestUrl,
      {
        params: { nameQuery: name ? name : undefined, ...requestParams },
      }
    );
    return res?.data?.data;
  };

  const fetchInitialValueFn = async id => {
    const res = await apiClient.get(
      getUrlWithParams(urls.api.investmentProjects.getOne, { id })
    );
    return res?.data;
  };

  return (
    <AsyncSelect
      label={label}
      allowClear
      {...props}
      mode={mode}
      fetchOptions={fetchDataFn}
      fetchInitialValue={fetchInitialValueFn}
    />
  );
};
