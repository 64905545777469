import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Select } from 'components/form/base/Select';
import { useQuery } from 'hooks/useQuery';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { InputField } from 'components/form/base/InputField';
import { StatusesEnum, statusesNamesHash } from 'constants/status';
import { reviewCategoriesNamesHash } from 'constants/reviews';

type TFormSelectValue = { value: string; label: string };

interface IFormValues {
  contentQuery: string;
  status: string | TFormSelectValue;
  entityTypes: string[];
}

export const Filters: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        contentQuery: values.contentQuery && values.contentQuery.trim(),
        status: mapSelect(values.status),
        entityTypes: values.entityTypes.length
          ? mapSelect(values.entityTypes)
          : null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm({
    defaultValues: {
      name: '',
      status: '',
      entityTypes: [],
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='contentQuery'
            label='Текст отзыва'
            placeholder='Введите текст'
            maxLength={255}
          />
          <Select
            name='status'
            label='Статус'
            options={[
              { value: '', label: 'Все' },
              ...Object.keys(statusesNamesHash)
                .filter(item => item !== StatusesEnum.DRAFT)
                .map(item => {
                  return {
                    value: item,
                    label: statusesNamesHash[item],
                  };
                }),
            ]}
          />
          <Select
            name='entityTypes'
            label='Раздел портала'
            placeholder='Выберите раздел'
            mode='multiple'
            allowClear
            options={[
              ...Object.keys(reviewCategoriesNamesHash).map(item => {
                return {
                  value: item,
                  label: reviewCategoriesNamesHash[item],
                };
              }),
            ]}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
