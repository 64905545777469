import React from 'react';

import { Add } from './Add';
import { AddTranslation } from './AddTranslation';
import { Edit } from './Edit';
import { List } from './List';
import { VacanciesResponds } from './VacanciesResponds';

export const vacanciesRoutes = {
  path: '/vacancies',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: 'add',
      element: <Add />,
    },
    {
      path: ':id/add-translation',
      element: <AddTranslation />,
    },
    {
      path: ':id/edit',
      element: <Edit />,
    },
    // https://jira-media.fabit.ru/browse/KZRF-903 обсуждение пункт 6.2
    {
      path: ':id',
      element: <Edit />,
    },
    {
      path: ':id/responds',
      element: <VacanciesResponds />,
    },
  ],
};
