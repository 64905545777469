// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegionsRelationsListTable-module__customHover--FCnhS .ant-table-cell-row-hover {\n  background: #E6F7FF !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/RegionRelations/components/ListTable/RegionsRelationsListTable.module.less"],"names":[],"mappings":"AAAA;EAGM,8BAAA;AADN","sourcesContent":[".customHover {\n  :global {\n    .ant-table-cell-row-hover{\n      background: #E6F7FF !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customHover": "RegionsRelationsListTable-module__customHover--FCnhS"
};
export default ___CSS_LOADER_EXPORT___;
