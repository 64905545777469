import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IInvestInfrastructuresItem,
  TInvestInfrastructuresDraftItem,
} from 'store/slices/investInfrastructures/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadInvestInfrastructuresItem,
  saveInvestInfrastructuresItem,
  saveInvestInfrastructuresItemAsDraft,
} from 'store/slices/investInfrastructures/actions';
import { LangEnum } from 'constants/lang';

import { InvestInfrastructuresForm } from './components/InvestInfrastructuresForm/InvestInfrastructuresForm';
import { mapValuesToForm } from './components/InvestInfrastructuresForm/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.investInfrastructures);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadInvestInfrastructuresItem(id));
  }, []);

  const onFinish = (values: IInvestInfrastructuresItem) => {
    setIsLoading(true);
    dispatch(saveInvestInfrastructuresItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investInfrastructures');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TInvestInfrastructuresDraftItem) => {
    setIsLoading(true);
    dispatch(saveInvestInfrastructuresItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investInfrastructures');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/investInfrastructures');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    territoryType: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item?.id && String(item?.id) === id ? (
        <InvestInfrastructuresForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
