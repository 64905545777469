import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { MainImage } from 'components/form/MainImage/MainImage';
import { Gallery } from 'components/form/Gallery/Gallery';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import {
  IRegionsItem,
  IRegionsItemResponse,
} from 'store/slices/regions/interfaces';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import { GetThere } from 'components/form/GetThere';
import { RegionsNestedArticles } from 'components/form/RegionsNestedArticles';
import { ContentField } from 'components/form/Content/ContentField';
import { SEO } from 'components/form/SEO/SEO';
import { MAIN_IMAGE_ASPECT_RATIO } from 'constants/image';

import { mapValues, validationSchema } from './formUtils';

type TProps = {
  initialValues: IRegionsItemResponse;
  onFinish: (values: IRegionsItem) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

export const RegionsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'cover',
    'content',
    'getThere',
    'gallery',
    'articles',
    'seo',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: { ...initialValues },
    resolver,
  });

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Обложка' required />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                required
                disabled
                showArrow={false}
              />
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                placeholder='Описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Вид на общей странице' key='cover'>
            <Row>
              <MainImage
                name='cover'
                label='Фото'
                required
                aspectRatio={MAIN_IMAGE_ASPECT_RATIO}
              />
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Описание региона' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Как добраться' key='getThere'>
            <GetThere
              name='getThere'
              entityType='regions'
              fieldType='getThere'
            />
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Вложенные статьи' key='articles'>
            <RegionsNestedArticles name='articles' />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
