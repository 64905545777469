import React from 'react';
import { Form, Radio } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';

import { omit } from 'utils/helpers';

import { PlaceSessions } from '../PlaceSessions/PlaceSessions';
import { PlaceSchedule } from '../PlaceSchedule';
import { checkDisabledFields, defaultScheduleDump } from '../formUtils';
import { onChangeValueType } from '../PlaceContainer/PlaceContainer';
import styles from './PlaceItemContent.module.less';

interface IPlaceItemContentProps {
  name: string;
  onChange: (value: onChangeValueType) => void;
  children: React.ReactNode;
  error: any;
}

export enum DateTableType {
  specific = 'specific',
  schedule = 'schedule',
}

export enum PlaceType {
  place = 'place',
  address = 'address',
}

export const PlaceItemContent: React.FC<IPlaceItemContentProps> = ({
  name,
  onChange,
  children,
  error,
}) => {
  const { control } = useFormContext();
  const value = useWatch({ name, control });

  const {
    isAddressDisabled,
    isPlaceDisabled,
    isSessionsDisabled,
    isClearButtonVisible,
    isScheduleDisabled,
  } = checkDisabledFields(value);

  const onChangePlaceType = (type: PlaceType) => {
    onChange({
      type,
      workTimeType: DateTableType.specific,
      ...(type === PlaceType.address && {
        address: {
          region: null,
          comment: '',
        },
      }),
      ...(type === PlaceType.place && {
        place: null,
        placeType: null,
      }),
      sessions: [],
    });
  };

  const onChangeTimeType = (type: DateTableType) => {
    onChange({
      ...(type === DateTableType.specific && {
        ...omit(value, 'schedule'),
        workTimeType: type,
        sessions: [],
      }),
      ...(type === DateTableType.schedule && {
        ...omit(value, 'sessions'),
        workTimeType: type,
        schedule: JSON.parse(JSON.stringify(defaultScheduleDump)),
      }),
    });
  };

  return (
    <>
      <Radio.Group
        defaultValue={value.type}
        onChange={e => onChangePlaceType(e.target.value)}
        style={{ marginBottom: 24 }}
      >
        <Radio.Button
          value={PlaceType.place}
          disabled={
            isPlaceDisabled || value.address?.region || value.address?.comment
          }
        >
          Место
        </Radio.Button>
        <Radio.Button
          value={PlaceType.address}
          disabled={isAddressDisabled || value.placeType}
        >
          Адрес
        </Radio.Button>
      </Radio.Group>
      {children}
      <Form.Item
        label='Сеансы'
        help={error?.sessions?.message}
        validateStatus={error?.sessions?.message ? 'error' : 'success'}
        required
      >
        <Radio.Group
          value={value.workTimeType}
          onChange={e => onChangeTimeType(e.target.value)}
          className={styles.workTimeTypeContainer}
        >
          <Radio.Button
            value={DateTableType.specific}
            disabled={isSessionsDisabled}
            className={styles.workTimeRadioButton}
          >
            В указанные дни
          </Radio.Button>
          <Radio.Button
            value={DateTableType.schedule}
            disabled={isScheduleDisabled}
            className={styles.workTimeRadioButton}
          >
            По расписанию
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {value.workTimeType === DateTableType.specific && (
        <PlaceSessions name={`${name}.sessions`} />
      )}
      {value.workTimeType === DateTableType.schedule && (
        <PlaceSchedule
          name={`${name}.schedule`}
          isClearButtonVisible={isClearButtonVisible}
          error={error}
        />
      )}
    </>
  );
};
