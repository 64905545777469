import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router';
import { notification } from 'antd';

import { useAppSelector } from 'store';
import { ROUTES_PATHS_BY_ROLES } from 'constants/routes';

import { appRoutes } from './appRoutes';

export const AppRouter: React.FC = () => {
  const { role } = useAppSelector(state => state.account);
  const routes = appRoutes(role);
  const app = useRoutes(routes);
  const location = useLocation();

  React.useEffect(() => {
    const basePathname = location.pathname.split('/').slice(0, 2).join('/');
    const routesToCheck = [
      ...ROUTES_PATHS_BY_ROLES[role],
      ...routes.map(item => item.path),
    ];

    if (!routesToCheck.includes(basePathname)) {
      notification.error({
        message: '403 Forbidden — доступ запрещен',
      });
    }
  }, [role, location?.pathname]);

  return (
    <Suspense>
      <>{app}</>
    </Suspense>
  );
};
