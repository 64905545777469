import React from 'react';

import styles from './IconTooltip.module.less';

export const IconTooltip = () => {
  return (
    <div className={styles.tooltip}>
      PNG картинка 120х120 пикс. Размер файла максимально - 100 КБ
    </div>
  );
};
