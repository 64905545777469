import React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Button, Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { TextAreaField } from 'components/form/base/TextAreaField';
import { ICatalogItem } from 'store/slices/catalog/interfaces';

import { HotelComfortGroupsSelector } from './HotelComfortGroupsSelector';

export type TFieldType = {
  id: string;
  comfort: string;
  value: number;
  children: string;
  label: string;
};

export const HotelComfortGroupsComponent: React.FC<{
  options: ICatalogItem[];
  name: string;
}> = ({ options, name }) => {
  const { control } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    name,
    control,
  });

  const comfortGroups = useWatch({ name, control });

  const handleSelectorChange = (option: TFieldType) => {
    append({
      value: option.value,
      label: option.children,
      comfort: '',
    });
  };

  const onChangeChosenSelector = (option: TFieldType, id?: number) => {
    const index = (comfortGroups as TFieldType[]).findIndex(
      item => item.value === id
    );

    update(index, {
      comfort: comfortGroups[index].comfort,
      label: option.children,
      value: option.value,
      children: option.children,
    });
  };

  const checkIsSelectorChosen = id => {
    if (fields.length) {
      const findChosenSelector = (comfortGroups as TFieldType[]).find(
        comfortGroup => comfortGroup.value === id
      );

      return !!findChosenSelector;
    }
    return false;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Row gutter={[24, 0]}>
          {fields?.map((item: TFieldType, index) => (
            <React.Fragment key={item.id}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Row gutter={24} wrap={false}>
                  <Col flex='auto'>
                    <HotelComfortGroupsSelector
                      value={item}
                      options={options}
                      handleSelectorChange={onChangeChosenSelector}
                      checkIsSelectorChosen={checkIsSelectorChosen}
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(index)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <TextAreaField
                  name={`comfortGroups.${index}.comfort`}
                  placeholder='Удобства (обязательное поле)'
                />
              </Col>
            </React.Fragment>
          ))}
          <Col span={24}>
            <Row>
              <Col md={{ span: 8 }} xs={{ span: 24 }}>
                <HotelComfortGroupsSelector
                  {...field}
                  options={options}
                  handleSelectorChange={handleSelectorChange}
                  error={fieldState.error}
                  disabled={fields.length >= 10}
                  checkIsSelectorChosen={checkIsSelectorChosen}
                  value='Добавить удобство'
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  );
};
