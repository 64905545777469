import { ParsedQuery } from 'query-string';

import { reportsOptions } from 'constants/reports';

export const mapQuery = (query: ParsedQuery<string>) => ({
  name: (query.name as string) || null,
  status:
    reportsOptions.find(({ value }) => value === query.status) ||
    reportsOptions[0],
  createdAtFrom: (query.createdAtFrom as string) || null,
  createdAtTo: (query.createdAtTo as string) || null,
});
