import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';

import { getValueByStringKeyWithArr } from 'utils/objects';
import { WeekDaysKeys, weekDaysNames } from 'constants/weekdays';
import { useCheckNodeWidth } from 'hooks/useCheckNodeWidth';

import { TextAreaField } from '../base/TextAreaField';
import { ScheduleTimePickerItem } from './components/ScheduleTimePickerItem';
import styles from './Schedule.module.less';

interface IScheduleProps {
  name: string;
  withoutComment?: boolean;
  withAllTimeButton?: boolean;
  breakpoint?: number;
}

const weekDays = [
  ...Object.keys(weekDaysNames).map(key => {
    return {
      label: weekDaysNames[key as WeekDaysKeys],
      value: key,
    };
  }),
];

export const Schedule: React.FC<IScheduleProps> = ({
  name,
  withoutComment,
  withAllTimeButton,
  breakpoint = 300,
}) => {
  const { formState, setValue, control } = useFormContext();
  const ref = React.useRef(null);
  const isBreakPointReached = useCheckNodeWidth({
    node: ref?.current,
    targetWidth: breakpoint,
  });

  return (
    <div ref={ref}>
      <div style={{ display: 'flex', flexFlow: 'column nowrap', gap: '16px' }}>
        {weekDays.map((item, index) => {
          const value = useWatch({ name: `${name}.${item.value}`, control });
          return (
            <Row
              key={index}
              align='middle'
              className={classNames(styles.scheduleContainer, {
                [styles.mobileWidth]: isBreakPointReached,
              })}
            >
              <div className={styles.label}>{item.label}</div>

              <ScheduleTimePickerItem
                name={`${name}.${item.value}.from`}
                prefixTitle='с'
                suffixIcon={
                  getValueByStringKeyWithArr(
                    formState.errors,
                    `${name}.${item.value}.from`
                  )?.message && !isBreakPointReached ? (
                    <WarningOutlined
                      style={{ color: 'var(--ant-error-color)' }}
                    />
                  ) : null
                }
                isBreakPointReached={isBreakPointReached}
                disabled={withAllTimeButton ? value.fullDay : false}
                errorMessage={
                  getValueByStringKeyWithArr(
                    formState.errors,
                    `${name}.${item.value}.from`
                  )?.message
                }
              />

              <ScheduleTimePickerItem
                name={`${name}.${item.value}.to`}
                prefixTitle='до'
                suffixIcon={
                  getValueByStringKeyWithArr(
                    formState.errors,
                    `${name}.${item.value}.to`
                  )?.message && !isBreakPointReached ? (
                    <WarningOutlined
                      style={{ color: 'var(--ant-error-color)' }}
                    />
                  ) : null
                }
                isBreakPointReached={isBreakPointReached}
                disabled={withAllTimeButton ? value.fullDay : false}
                errorMessage={
                  getValueByStringKeyWithArr(
                    formState.errors,
                    `${name}.${item.value}.to`
                  )?.message
                }
              />

              {withAllTimeButton && (
                <Row align='middle' className={styles.fullDay}>
                  <Col>
                    <Checkbox
                      checked={value.fullDay}
                      onChange={e =>
                        setValue(`${name}.${item.value}`, {
                          from: null,
                          to: null,
                          fullDay: e.target.checked,
                        })
                      }
                    >
                      Круглосуточно
                    </Checkbox>
                  </Col>
                </Row>
              )}
            </Row>
          );
        })}
      </div>
      {!withoutComment && (
        <TextAreaField
          label=''
          placeholder='Комментарий'
          name={`${name}.comment`}
          showCount
          maxLength={255}
          style={{ marginTop: '24px' }}
          rows={3}
        />
      )}
    </div>
  );
};
