import React from 'react';
import { Col, Row } from 'antd';

import { TextAreaField } from 'components/form/base/TextAreaField';

import { GetThereItemSelector } from './components/GetThereItemSelector/GetThereItemSelector';
import { TutuSelector } from './components/TutuSelector/TutuSelector';

interface IProps {
  name: string;
  entityType: string;
  fieldType: string;
}

export const GetThereItem = ({ name, entityType, fieldType }: IProps) => {
  return (
    <Row gutter={[24, 0]}>
      <GetThereItemSelector
        name={`${name}.selectedWay`}
        entityType={entityType}
        fieldType={fieldType}
      />
      <Col span={24}>
        <TextAreaField
          name={`${name}.description`}
          label='Описание'
          placeholder='Начните печатать текст'
          maxLength={1500}
        />
      </Col>
      <TutuSelector name={`${name}.selectedWayTutu`} />
    </Row>
  );
};
