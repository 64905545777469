import { createSlice } from '@reduxjs/toolkit';

import { ICommentsState } from './interfaces';
import { loadCommentsList } from './actions';

export const formCommentsSlice = createSlice({
  name: 'formComments',
  initialState: {
    list: [],
    limit: 5,
    total: 0,
    offset: 0,
  } as ICommentsState,
  reducers: {
    clearFormCommentsData: state => {
      state.list = [] as ICommentsState['list'];
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadCommentsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
  },
});

export const { clearFormCommentsData } = formCommentsSlice.actions;
