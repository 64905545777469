import { StatusesEnum } from 'constants/status';
import { ExtensionContactsTypes } from 'components/form/ExtensionContacts/types';

export const INITIAL_STATE = {
  name: '',
  purchaseMethodCatalogId: null,
  statusProcedure: null,
  contactPerson: '',
  informationProcurement: '',
  timePlaceProcedureDocumentation: '',
  paymentProcurementDocuments: '',
  securingProcurementApplication: '',
  securingContract: '',
  placeOpeningEnvelopes: '',
  dateTimeOpeningEnvelopes: '',
  placeConsiderationApplication: '',
  dateTimeConsiderationApplication: '',
  placeDebriefing: '',
  dateTimeDebriefing: '',
  datePlacementNotice: null,
  noticeNumber: '',
  materials: [],
  initialPrice: '',
  contacts: [
    {
      type: ExtensionContactsTypes.phone,
      value: '',
      additional: '',
    },
    {
      type: ExtensionContactsTypes.email,
      value: '',
    },
  ],
  status: StatusesEnum.PUBLISHED,
};

export const DEFAULT_ACCEPT =
  '.pdf, .doc, .docx, .xls, .xlsx, .csv, .odf, .ppt, .jpeg, .jpg, .png';

export const VALID_FORMATS_LIST = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.formula',
  'text/csv',
  'application/vnd.ms-powerpoint',
  'image/jpg',
  'image/jpeg',
  'image/png',
];

export const UPLOADS_UNSUPPORTED_FILE_TYPES = `У файла недопустимый формат. Загрузите файл с расширением ${DEFAULT_ACCEPT}`;
