import * as Yup from 'yup';

export const cardValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Введите от 1 до 60 символов')
    .max(60, 'Введите от 1 до 60 символов')
    .required(),
  description: Yup.string()
    .min(1, 'Введите от 1 до 255 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  sortPriority: Yup.number().min(1).required(),
});

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Введите от 1 до 60 символов')
    .max(60, 'Введите от 1 до 60 символов')
    .required(),
});
