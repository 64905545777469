import React, { useState } from 'react';
import { Button, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../store';
import {
  ISliderItem,
  ISliderResponse,
  TSliderName,
} from '../../store/slices/slider/interfaces';
import { deleteSliderItem, getSlider } from '../../store/slices/slider/actions';
import { MainLayout } from '../../components/Layout/MainLayout';
import { StickyConfirmModal } from '../../components/modals/StickyConfirmModal';
import { CardTable } from '../../components/Card/CardTable';
import { LangSelectSection } from '../../components/form/LangSelectSection/LangSelectSection';
import { validationSchema } from '../MainBusiness/components/History/validation';
import { LangEnumKeys, LangNamesHash } from '../../constants/lang';
import { useDeepEffect } from '../../utils/useDeepEffect';

interface IFormValues extends ISliderResponse {
  lang: { value: LangEnumKeys; label: string };
}

interface IProps {
  slug: string;
  name: TSliderName;
}

export const Slider: React.FC<IProps> = ({ slug, name }) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.slider);
  const params = useParams();
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState<ISliderItem | null>(null);

  const methods = useForm<IFormValues>({
    defaultValues: {
      ...data.slider,
      lang: {
        value: params.lang as LangEnumKeys,
        label: LangNamesHash[params.lang],
      },
    },
    resolver: yupResolver(validationSchema),
  });

  const lang = methods.watch('lang');

  const loadData = () => {
    dispatch(getSlider({ lang: lang.value, name: name }));
  };
  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const getLink = (id: number) => `${name}/${id}/edit`;
  const handleRemove = (id: number) =>
    dispatch(
      deleteSliderItem({
        sliderName: name,
        sliderItemId: id,
        lang: data.slider.language,
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        modalCloseHandler();
        loadData();
      });

  const getActions = item => [
    {
      element: <Typography.Text type='danger'>Удалить</Typography.Text>,
      handler: () => {
        setModalIsOpen(true);
        setCurrentItem(item);
      },
    },
    {
      element: (
        <Link to={`${name}/${item.id}/edit`}>
          <Typography.Text>Редактировать</Typography.Text>
        </Link>
      ),
    },
  ];

  useDeepEffect(() => {
    loadData();
    navigate(`/${slug}/${lang.value}/slider`);
  }, [lang]);

  return (
    <MainLayout
      pageTitle='Слайдер'
      extraButtonsList={[
        <Link key={1} to={`${name}/add`}>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
    >
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical indent-top'>
          <LangSelectSection />
        </form>
      </FormProvider>
      <CardTable
        getActions={getActions}
        data={data.slider.slides.data}
        getLink={getLink}
      />
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление слайда'
              okText='Удалить'
              text={<div>Вы хотите удалить слайд?</div>}
              onSubmit={() => handleRemove(currentItem.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
