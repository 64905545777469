import { Layout, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { FormProvider, useForm, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { DatePicker } from 'components/form/base/DatePicker';
import { InputField } from 'components/form/base/InputField';
import { defaultDateFormat } from 'components/form/base/RangePicker';
import { Select } from 'components/form/base/Select';
import { Statuses } from 'constants/status';
import { useQuery } from 'hooks/useQuery';
import { debounce, removeEmptyValues } from 'utils/helpers';
import { mapSelect } from 'utils/mappings';

import { FILTER_STATUS_OPTIONS, MINIMAL_YEAR } from './constants';

export interface FilterValues {
  nameQuery: string;
  status: Statuses | '';
  year: Dayjs | null;
  month: Dayjs | null;
}

export const Filters = () => {
  const { year, month, ...query } = useQuery();
  const navigate = useNavigate();

  const methods = useForm<FilterValues>({
    defaultValues: {
      nameQuery: '',
      status: '',
      year: year ? dayjs().year(Number(year)) : null,
      month: month ? dayjs().month(Number(month)) : null,
      ...query,
    },
  });

  const updateFilters = (filterValues: Record<string, string>) => {
    const queryString = new URLSearchParams(filterValues).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce(({ status, year, month, ...values }: FilterValues) => {
      const preparedValues = {
        ...values,
        year: dayjs(year, defaultDateFormat).get('y'),
        month: dayjs(month, defaultDateFormat).get('M'),
        status: mapSelect(status),
      };

      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(
        debounceSubmit
      ) as unknown as WatchObserver<FilterValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: 16, paddingBottom: 1 }}
    >
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form-vertical'>
          <InputField
            name='nameQuery'
            placeholder='Введите название'
            label='Название'
            maxLength={255}
          />
          <Select
            name='status'
            options={FILTER_STATUS_OPTIONS}
            label='Статус'
          />
          <DatePicker
            name='month'
            label='Месяц'
            picker='month'
            dateFormatList='MMMM'
            placeholder='Выберите месяц'
            inputReadOnly
          />
          <DatePicker
            name='year'
            label='Год'
            picker='year'
            dateFormatList='YYYY'
            minDateRestriction={dayjs().year(MINIMAL_YEAR)}
            maxDateRestriction={dayjs()}
            placeholder='Выберите год'
            inputReadOnly
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
