import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import { loadOrganizationList } from 'store/slices/organizations/actions';
import { IOrganization } from 'store/slices/organizations/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';
import { useDeepEffect } from 'utils/useDeepEffect';

import { ListTable } from './components/ListTable';
import { Filters } from './components/Filters/Filters';

export const List = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { list, total, limit } = useAppSelector(state => state.organizations);

  const loadOrganizations = () => {
    dispatch(
      loadOrganizationList({
        ...query,
      })
    );
  };

  useDeepEffect(() => {
    loadOrganizations();
  }, [query]);

  const getActions = (item: IOrganization) => [
    {
      key: 'view',
      label: <Link to={`/organizations/${item.id}`}>Просмотр</Link>,
    },
    {
      key: 'control',
      label: <Link to={`/organizations/${item.id}/control`}>Управление</Link>,
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.organizations} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
        />
      </Content>
    </MainLayout>
  );
};
