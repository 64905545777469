import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from '../../store';
import { ISliderItem } from '../../store/slices/slider/interfaces';
import {
  changeSliderItem,
  getSliderItem,
} from '../../store/slices/slider/actions';
import { mapSliderItemValues, mapToForm } from './utilts';
import { MainLayout } from '../../components/Layout/MainLayout';
import { SliderForm } from './components/SliderForm';
import CardSpin from '../../components/Card/CardSpin';

interface IProps {
  slug: string;
  withImage?: boolean;
  isFormType?: boolean;
}

export const EditSlide: React.FC<IProps> = ({
  slug,
  withImage,
  isFormType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { sliderItemId, sliderName, lang } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.slider);

  const loadSliderItem = () => {
    dispatch(getSliderItem({ sliderItemId, sliderId: sliderName, lang }));
  };
  const onSubmit = (values: ISliderItem) => {
    setIsLoading(true);
    dispatch(
      changeSliderItem({
        sliderId: sliderName,
        payload: mapSliderItemValues(values),
        lang,
      })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate(`/${slug}/${lang}/slider`);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadSliderItem();
  }, []);

  if (data.isLoading) {
    return <CardSpin />;
  }
  return (
    <MainLayout pageTitle='Редактирование'>
      {data.sliderItem && (
        <SliderForm
          isFormType={isFormType}
          initialValues={mapToForm(data.sliderItem)}
          onSubmit={onSubmit}
          isLoading={isLoading}
          withImage={withImage}
          slug={slug}
        />
      )}
    </MainLayout>
  );
};
