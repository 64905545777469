import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch } from 'store';
import {
  saveCurrentResidentsItem,
  saveCurrentResidentsItemDraft,
} from 'store/slices/currentResidents/actions';
import { CurrentResidentsItem } from 'store/slices/currentResidents/interfaces';
import { DeepPartial } from 'types/utils';

import { Form } from './components/Form';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (values: CurrentResidentsItem) => {
    setIsLoading(true);
    dispatch(saveCurrentResidentsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Действующий резидент успешно добавлен',
        });
        navigate('/currentResidents');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<CurrentResidentsItem>) => {
    setIsLoading(true);
    dispatch(saveCurrentResidentsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Действующий резидент успешно создан',
        });
        navigate('/currentResidents');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Создание'>
      <Form
        onSubmit={onSubmit}
        onSaveAsDraft={onSaveAsDraft}
        isDraft
        isLoading={isLoading}
      />
    </MainLayout>
  );
};
