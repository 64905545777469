// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 480px) {\n  .HotelsForm-module__starRatingButton--y26Fp {\n    padding: 0 10px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Hotels/components/HotelsForm/HotelsForm.module.less"],"names":[],"mappings":"AAGE;EAAA;IACE,eAAA;EADF;AACF","sourcesContent":["@xs: 480px;\n\n.starRatingButton {\n  @media (max-width: @xs) {\n    padding: 0 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"starRatingButton": "HotelsForm-module__starRatingButton--y26Fp"
};
export default ___CSS_LOADER_EXPORT___;
