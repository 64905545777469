import { PageTitles } from 'constants/pageTitles';

export const breadCrumbsHash = {
  edit: 'Редактирование',
  add: 'Создание',
  view: 'Просмотр',
  control: 'Управление',
  responds: 'Отклики',
  ...Object.keys(PageTitles).reduce((acc, key) => {
    acc[key] = PageTitles[key];
    return acc;
  }, {}),
};
