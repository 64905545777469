import { FieldValues } from 'react-hook-form';

import {
  mapAddress,
  mapSelect,
  mapValuesToAddress,
  mapValuesToSelect,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import {
  IVacanciesItem,
  TVacanciesDraftItem,
} from 'store/slices/vacancies/interfaces';
import { TTextBlock } from 'components/form/Content/contentTypes';
import {
  TVacanciesWorkExperienceType,
  TVacanciesWorkScheduleType,
  TVacanciesWorkType,
  VacanciesTempAddressFlagEnum,
} from 'types/vacancies';
import {
  VACANCIES_WORK_EXPERIENCE_OPTIONS,
  VACANCIES_WORK_SCHEDULE_OPTIONS,
  VACANCIES_WORK_TYPE_OPTIONS,
} from 'constants/vacancies';

type TMapSelectValuesToForm = { value: string; label: string };

const mapSelectValuesToForm = (
  values: TMapSelectValuesToForm[],
  searchingValue: string
) => {
  const foundItem = values.find(item => item.label === searchingValue);

  return {
    ...foundItem,
    children: foundItem.label,
  };
};

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    specialization: values.specializationData
      ? mapValuesToSelect(values.specializationData)
      : null,
    revenueFrom: values.revenueFrom || '',
    revenueTo: values.revenueTo || '',
    addressFlag:
      values.address?.city || values.address?.settlement
        ? VacanciesTempAddressFlagEnum.IncludeAddress
        : VacanciesTempAddressFlagEnum.ExcludeAddress,
    address:
      values.address?.city || values.address?.settlement
        ? mapValuesToAddress(values.address)
        : {},
    workType: values.workType
      ? mapSelectValuesToForm(VACANCIES_WORK_TYPE_OPTIONS, values.workType)
      : null,
    workSchedule: values.workSchedule
      ? mapSelectValuesToForm(
          VACANCIES_WORK_SCHEDULE_OPTIONS,
          values.workSchedule
        )
      : null,
    workExperience: values.workExperience
      ? mapSelectValuesToForm(
          VACANCIES_WORK_EXPERIENCE_OPTIONS,
          values.workExperience
        )
      : null,
    description: values.description
      ? [{ type: 'text', text: values.description }]
      : prepareContent([]),
    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const mapValues = (values: FieldValues): IVacanciesItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    specialization: mapSelect(values.specialization) as number,
    revenueType: values.revenueType,
    revenueFrom: values.revenueFrom ? Number(values.revenueFrom) : null,
    revenueTo: values.revenueTo ? Number(values.revenueTo) : null,
    organization: values.organization.trim(),
    address:
      values.address?.city || values.address?.settlement
        ? mapAddress(values.address)
        : null,
    workType: values.workType.children as TVacanciesWorkType,
    workSchedule: values.workSchedule.children as TVacanciesWorkScheduleType,
    workExperience: values.workExperience
      .children as TVacanciesWorkExperienceType,
    description: (values.description[0] as unknown as TTextBlock).text,
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    status: mapSelect(values.status) as Statuses,
  };
};

export const mapValuesToDraft = (values: FieldValues): TVacanciesDraftItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name?.trim() || null,
    specialization: values.specialization
      ? (mapSelect(values.specialization) as number)
      : null,
    revenueType: values.revenueType,
    revenueFrom: values.revenueFrom ? Number(values.revenueFrom) : null,
    revenueTo: values.revenueTo ? Number(values.revenueTo) : null,
    organization: values.organization?.trim() || null,
    address:
      values.address?.city || values.address?.settlement
        ? mapAddress(values.address)
        : null,
    workType: (values.workType?.children as TVacanciesWorkType) || null,
    workSchedule:
      (values.workSchedule?.children as TVacanciesWorkScheduleType) || null,
    workExperience:
      (values.workExperience?.children as TVacanciesWorkExperienceType) || null,
    description:
      values.description?.length &&
      (values.description[0] as unknown as TTextBlock).text
        ? (values.description[0] as unknown as TTextBlock).text
        : null,
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
  };
};
