import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Введите от 1 до 60 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  company: Yup.string()
    .min(1, 'Введите от 1 до 60 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  investVolume: Yup.number().required(),
  kavkazShare: Yup.number().required(),
  workPlaces: Yup.number().required(),
  link: Yup.string().url(),
  sortPriority: Yup.number().min(1).required(),
});
