import React, { useState } from 'react';
import { notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import {
  loadProjectsList,
  removeProjectsItem,
} from 'store/slices/projects/actions';
import { PageTitles } from 'constants/pageTitles';
import { IProjectsItem } from 'store/slices/projects/interfaces';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.projects);
  const loadProjects = () => {
    dispatch(
      loadProjectsList({
        ...query,
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<IProjectsItem | null>(null);

  useDeepEffect(() => {
    loadProjects();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const handleRemove = async id => {
    await dispatch(removeProjectsItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Проект успешно удалён',
        });
        loadProjects();
        modalCloseHandler();
      });
  };

  const getActions = (item: IProjectsItem) => [
    {
      key: 'view',
      label: <Link to={`/projects/${item.id}/view`}>Просмотр</Link>,
    },
    {
      key: 'remove',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        setModalIsOpen(true);
        setCurrentItem(item);
      },
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.projects} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление проекта'
              okText='Удалить'
              text={
                <div>
                  Вы хотите удалить проект{' '}
                  <Typography.Text strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={() => handleRemove(currentItem?.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
