import React from 'react';
import {
  Button,
  Collapse,
  Row,
  Col,
  Image,
  Space,
  Typography,
  Rate,
} from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content } from 'antd/lib/layout/layout';

import { useDeepEffect } from 'utils/useDeepEffect';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import { IReviewsItemResponse } from 'store/slices/reviews/interfaces';
import { getImageUrl } from 'utils/image';
import { getName } from 'pages/Organizations/utils';
import { Gallery } from 'pages/Reviews/components/ReviewGallery/Gallery';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { Status } from 'components/form/Status/Status';
import { useAppSelector } from 'store';
import { entityTypeForUrlHash } from 'constants/reviews';

import { mapValues, validationSchema } from './formUtils';
import styles from './ReviewForm.module.less';

type TProps = {
  initialValues: IReviewsItemResponse;
  onFinish: (values) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

export const ReviewsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'reply',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const submit = values => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Content>
          <Space
            direction='vertical'
            size='large'
            style={{ width: '100%', marginBottom: '24px' }}
          >
            <Row align='middle'>
              <Typography.Text style={{ color: '#8c8c8c' }}>
                Страница отзыва
              </Typography.Text>
            </Row>
            <Typography.Link
              className={styles.cardLink}
              href={`${b2cUrl}/${
                entityTypeForUrlHash[initialValues.entityType]
              }/${initialValues.entityId}`}
              target='_blank'
            >
              <Row style={{ flexWrap: 'nowrap' }}>
                <Col style={{ flexShrink: 0, marginRight: '16px' }}>
                  <Image
                    width={50}
                    height={50}
                    preview={false}
                    src={
                      initialValues?.entityInfo.heroImage
                        ? getImageUrl(
                            initialValues.entityInfo.heroImage,
                            50,
                            50
                          ) || '/public/images/no-image.svg'
                        : '/public/images/no-image.svg'
                    }
                    fallback='/public/images/no-image.svg'
                    style={{ flexShrink: 0 }}
                  />
                </Col>
                <Col flex={1}>
                  <Row>
                    <Typography.Link className={styles.titleLink}>
                      {initialValues.entityInfo.name}
                    </Typography.Link>
                  </Row>
                  <Row>
                    <Typography.Text style={{ color: '#8c8c8c' }}>
                      {initialValues.entityInfo.address
                        ? initialValues.entityInfo.address.region.name
                        : initialValues.entityInfo.region.name}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
            </Typography.Link>
          </Space>
          <Row gutter={[24, 0]} className={styles.viewRow}>
            <Col span={24} className={styles.viewCol}>
              <Typography.Text style={{ color: '#8c8c8c' }}>
                ФИО пользователя
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>
                {getName(
                  {
                    lastName: initialValues.userData.lastName,
                    firstName: initialValues.userData.firstName,
                    secondName: initialValues.userData.secondName,
                  },
                  false,
                  true
                )}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={[24, 0]} className={styles.viewRow}>
            <Col className={styles.viewCol}>
              <Typography.Text style={{ color: '#8c8c8c' }}>
                Оценка
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Rate disabled defaultValue={initialValues.rating} />
            </Col>
          </Row>
          <Row gutter={[24, 0]} className={styles.viewRow}>
            <Col className={styles.viewCol}>
              <Typography.Text style={{ color: '#8c8c8c' }}>
                Текст отзыва
              </Typography.Text>
            </Col>

            <Col flex={1}>
              <TextAreaField name='content' showCount maxLength={1000} />
            </Col>
          </Row>
          <Row gutter={[24, 0]} className={styles.viewRow}>
            <Col className={styles.viewCol}>
              <Typography.Text style={{ color: '#8c8c8c' }}>
                Фотогалерея
              </Typography.Text>
            </Col>
            <Col>
              <Gallery name='gallery' />
            </Col>
          </Row>
        </Content>

        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Ответ на отзыв' key='reply'>
            <Row gutter={[24, 0]}>
              <Col flex={1}>
                <TextAreaField
                  name='reply'
                  placeholder='Введите ответ'
                  showCount
                  maxLength={800}
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
