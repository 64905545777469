import { createAsyncThunk } from '@reduxjs/toolkit';

import { IHistoryItem, IHistoryResponse } from './interfaces';
import { apiClient } from '../../../utils/http';

export const getHistory = createAsyncThunk<IHistoryResponse, { lang: string }>(
  'b2bHistory/get',
  async ({ lang }) => {
    const { data } = await apiClient.get<IHistoryResponse>(`history/${lang}`);
    return data;
  }
);

export const getHistoryItem = createAsyncThunk<
  IHistoryItem,
  { historyId: string; historyItemId: string }
>('b2bHistory/getHistoryItem', async ({ historyId, historyItemId }) => {
  const { data } = await apiClient.get<IHistoryItem>(
    `historyItem/${historyId}/${historyItemId}`
  );
  return data;
});

export const deleteHistoryItem = createAsyncThunk<
  IHistoryItem,
  { historyItemId: number }
>('b2bHistory/deleteHistoryItem', async ({ historyItemId }) => {
  const { data } = await apiClient.delete<IHistoryItem>(
    `historyItem/${historyItemId}`
  );
  return data;
});

export const addHistoryItem = createAsyncThunk<
  IHistoryItem,
  { historyId: string; payload: IHistoryItem }
>('b2bHistory/addHistoryItem', async ({ historyId, payload }) => {
  const { data } = await apiClient.post<IHistoryItem>(
    `historyItem/${historyId}`,
    payload
  );
  return data;
});

export const setHistoryItem = createAsyncThunk<
  IHistoryItem,
  { historyId: string; payload: IHistoryItem }
>('b2bHistory/setHistoryItem', async ({ historyId, payload }) => {
  const { data } = await apiClient.put<IHistoryItem>(
    `historyItem/${historyId}`,
    payload
  );
  return data;
});
