import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { saveInfrastructuresItem } from 'store/slices/infrastructures/actions';
import { IAttractionItem } from 'store/slices/attractions/interfaces';
import { LangEnum } from 'constants/lang';

import { InfrastructuresForm } from './components/InfrastructuresForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveInfrastructuresItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/infrastructures');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/infrastructures');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <InfrastructuresForm
        onFinish={onFinish}
        cancelButtonHandler={cancelButtonHandler}
        isLoading={isLoading}
        initialValues={{
          lang: LangEnum.ru,
          name: '',
          category: null,
          address: {},
        }}
      />
    </MainLayout>
  );
};
