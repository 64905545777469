import React from 'react';
import { Card, Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { MainImage } from 'components/form/MainImage/MainImage';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';

import { InputField } from '../../base/InputField';
import styles from './Manual.module.less';
type TProps = {
  name: string;
  widget: any;
};

export const Manual: React.FC<TProps> = ({ name }) => {
  return (
    <Content
      className={styles.content}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <Card title='Геометка' type='inner'>
        <Row gutter={[16, 0]}>
          <Col flex={'0 0 100px'}>
            <MainImage name={`${name}.image`} label='Глав. фото' required />
          </Col>
          <Col flex={1}>
            <InputField
              name={`${name}.title`}
              label='Название геометки'
              required
              placeholder='Введите название'
              showCount
              maxLength={255}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InputField
              name={`${name}.description`}
              label='Краткое описание'
              required
              placeholder='Описание'
              showCount
              maxLength={255}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InputField
              name={`${name}.url`}
              label='Ссылка'
              required
              placeholder='Ссылка'
              showCount
              maxLength={255}
            />
          </Col>
        </Row>
        <LocationSelector name={`${name}.address`} />
      </Card>
    </Content>
  );
};
