import React from 'react';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';
import { useAppDispatch, useAppSelector } from 'store';
import { loadMenu, saveMenu } from 'store/slices/menu/actions';
import {
  IMenuRequestBody,
  IMenuRequestParams,
  MenuTypeEnum,
  TMenuType,
} from 'store/slices/menu/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { useDeepEffect } from 'utils/useDeepEffect';

import { MenuForm } from './components/MenuForm/MenuForm';
import { prepareValuesToForm } from './components/MenuForm/formUtils';
import { useQuery } from '../../hooks/useQuery';

export const Menu = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { lang = LangEnum.ru, menuType = MenuTypeEnum.headerB2b } = useQuery();

  const { menuData, loading } = useAppSelector(store => store.menu);

  useDeepEffect(() => {
    dispatch(loadMenu({ lang, menuType } as IMenuRequestParams));
  }, [lang, menuType]);

  const onFinish = (values: IMenuRequestBody) => {
    setIsLoading(true);
    dispatch(saveMenu(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Данные сохранены',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const preparedValues = prepareValuesToForm(
    menuData,
    lang as LangEnumKeys,
    menuType as TMenuType
  );

  return (
    <MainLayout pageTitle={PageTitles.menu}>
      <MenuForm
        initialValues={preparedValues}
        onFinish={onFinish}
        isLoading={isLoading}
        isDataLoading={loading}
      />
    </MainLayout>
  );
};
