import React from 'react';

import { View } from './View';
import { List } from './List';

export const applicationsRoutes = {
  path: '/applications',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: ':id/view',
      element: <View />,
    },
  ],
};
