import React, { memo, useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { UploadOutlined } from '@ant-design/icons';
import { Col, Input, InputRef, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import { ICondition } from 'store/slices/openingInfo/interfaces';
import { AntDatePicker } from 'components/form/antDateFields/AntDatePicker';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { CustomCollapse } from './components/CustomCollapse/CustomCollapse';
import styles from './Form.module.less';

interface IProps {
  onDelete: (id: string) => void;
  onMove: (item: ICondition, step?: number) => void;
  onUpdate: (
    file: IDocument,
    title: string,
    eventDate: Date,
    entity: ICondition
  ) => void;
  total: number;
  entity: ICondition;
}

export const FormWithDate = memo(
  ({ onUpdate, onDelete, onMove, entity, total }: IProps) => {
    const inputRef = useRef<InputRef>(null);
    const [title, setTitle] = useState<string>(entity.title);
    const [file, setFile] = useState<IDocument>(entity.file);
    const [eventDate, setEventDate] = useState<Date>(entity.eventDate);
    const [error, setError] = useState<string>(null);

    const handleBlur = () => !error && onUpdate(file, title, eventDate, entity);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const isValid = Yup.string().required().isValidSync(value);
      if (!isValid) {
        setError('Введите от 1 до 255 символов');
      } else {
        setError(null);
      }
      setTitle(e.target.value);
    };

    const handleChangeDate = (value: dayjs.Dayjs) =>
      setEventDate(value.utc(true).toDate());

    const handleChangeState = useCallback((file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      setTitle(title);
      setFile(file);
      inputRef.current.focus();
    }, []);

    return (
      <CustomCollapse
        onDelete={onDelete}
        onMove={step => onMove(entity, step)}
        title={title}
        file={file}
        itemId={entity.id}
        sortIndex={entity.sortIndex}
        total={total}
        isError={!!error}
      >
        <Row gutter={[24, 24]}>
          <Col flex={1}>
            <Input
              ref={inputRef}
              className={styles.input}
              placeholder='Название документа'
              type='text'
              value={title}
              onChange={handleChange}
              onBlur={handleBlur}
              minLength={1}
              maxLength={255}
              status={error ? 'error' : undefined}
              addonBefore={<span className={styles.required} />}
            />
            {error && <Typography.Text type='danger'>{error}</Typography.Text>}
          </Col>
          <Col>
            <CustomUpload
              onAdd={handleChangeState}
              icon={<UploadOutlined />}
              buttonTitle='Загрузить другой'
            />
          </Col>
        </Row>
        <Row className={styles.datePickerRow}>
          <Col span={24} md={14}>
            <div className={styles.datePicker}>
              <label htmlFor={`datepicker-${entity.id}`}>Дата проведения</label>
              <AntDatePicker
                id={`datepicker-${entity.id}`}
                value={dayjs(eventDate)}
                onChange={handleChangeDate}
                onBlur={handleBlur}
                format='DD.MM.YYYY'
                allowClear={false}
                inputReadOnly
              />
            </div>
          </Col>
        </Row>
      </CustomCollapse>
    );
  }
);

FormWithDate.displayName = 'FormWithDate';
