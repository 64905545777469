import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadInvestmentSolutionsItem,
  saveInvestmentSolutionsItem,
  saveInvestmentSolutionsItemDraft,
} from 'store/slices/investmentSolutions/actions';
import { InvestmentSolutionsItem } from 'store/slices/investmentSolutions/interfaces';
import { DeepPartial } from 'types/utils';
import { LangEnum } from 'constants/lang';

import { Form } from './components/Form';
import { mapValuesToForm } from './components/Form/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { item } = useAppSelector(state => state.investmentSolutions);
  const isCorrectItem = item?.id === Number(id);

  React.useEffect(() => {
    dispatch(loadInvestmentSolutionsItem(id));
  }, [id]);

  const onSubmit = (values: InvestmentSolutionsItem) => {
    setIsLoading(true);
    dispatch(saveInvestmentSolutionsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate('/investmentSolutions');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<InvestmentSolutionsItem>) => {
    setIsLoading(true);
    dispatch(saveInvestmentSolutionsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate('/investmentSolutions');
      })
      .catch(() => setIsLoading(false));
  };

  const preparedValues = {
    ...item,
    lang: LangEnum.en,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {isCorrectItem && (
        <Form
          onSubmit={onSubmit}
          isDraft
          isLoading={isLoading}
          defaultValues={mapValuesToForm(preparedValues)}
          onSaveAsDraft={onSaveAsDraft}
        />
      )}
    </MainLayout>
  );
};
