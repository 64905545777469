import React from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { SampleList } from 'components/Sample/SampleList';
import { PageTitles } from 'constants/pageTitles';
import { loadResortsList, saveResortItem } from 'store/slices/resorts/actions';
import { IResortItem } from 'store/slices/resorts/interfaces';

interface IProps {
  sortFieldName?: keyof Pick<
    IResortItem,
    'sortPriority' | 'sortPriorityMainPage'
  >;
  limit?: number;
  addON?: boolean;
  deleteON?: boolean;
  isSortPriority?: boolean | null;
}

export const List: React.FC<IProps> = ({
  sortFieldName = 'sortPriority',
  limit,
  addON,
  deleteON,
  isSortPriority = true,
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.resorts);

  const tableData = data.list.map(item => ({
    title: item.name,
    id: item.id,
    sortPriority: item[sortFieldName],
    image: item.image,
    address: item.address,
  }));

  const handleRemove = async (id: number): Promise<any> => {
    return dispatch(
      saveResortItem({
        id,
        [sortFieldName]: null,
      })
    ).unwrap();
  };

  const handleLoadData = (lang: string) => {
    dispatch(
      loadResortsList({
        lang: lang,
        limit,
        sort: sortFieldName,
        isSortPriority,
      })
    );
  };

  return (
    <SampleList
      data={tableData}
      handleLoadData={handleLoadData}
      handleDelete={handleRemove}
      isLoading={data.isLoading}
      title={PageTitles.resorts}
      limit={limit}
      addON={addON}
      deleteON={deleteON}
    />
  );
};
