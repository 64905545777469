export enum StatusesEnum {
  PUBLISHED = 'published',
  // TO_BE_PUBLISHED = 'toBePublished',
  DRAFT = 'draft',
  ON_MODERATION = 'onModeration',
  DECLINED = 'declined',
  NOT_PUBLISHED = 'notPublished',
}

export enum StatusesActionLogsEnum {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ON_MODERATION = 'onModeration',
  DECLINED = 'declined',
  NOT_PUBLISHED = 'notPublished',
  TO_REWORK = 'toRework',
  IN_PROGRESS = 'inProgress',
  NEW = 'new',
  FINISHED = 'finished',
}

export type Statuses = `${StatusesEnum}`;

export const oezNamesHash = {
  elbrus: 'ВТРК «Эльбрус»',
  veduchi: 'ВТРК «Ведучи»',
  armhi: 'ВТРК «Армхи»',
  mamison: 'ВТРК «Мамисон»',
  kaspiyskiy: 'ВТРК «Каспийский прибрежный кластер»',
};

export const statusesNamesHash = {
  [StatusesEnum.PUBLISHED]: 'Опубликовано',
  // [StatusesEnum.TO_BE_PUBLISHED]: 'Запланировано',
  [StatusesEnum.DRAFT]: 'Черновик',
  [StatusesEnum.ON_MODERATION]: 'На модерации',
  [StatusesEnum.DECLINED]: 'Отклонено',
  [StatusesEnum.NOT_PUBLISHED]: 'Снято с публикации',
};

export const statusesActionLogsNamesHash = {
  [StatusesActionLogsEnum.PUBLISHED]: 'Опубликовано',
  [StatusesActionLogsEnum.DRAFT]: 'Черновик',
  [StatusesActionLogsEnum.ON_MODERATION]: 'На модерации',
  [StatusesActionLogsEnum.DECLINED]: 'Отклонено',
  [StatusesActionLogsEnum.NOT_PUBLISHED]: 'Снято с публикации',
  [StatusesActionLogsEnum.TO_REWORK]: 'На доработку',
  [StatusesActionLogsEnum.IN_PROGRESS]: 'На рассмотрении',
  [StatusesActionLogsEnum.NEW]: 'Заявка получена',
  [StatusesActionLogsEnum.FINISHED]: 'Завершена',
};

export const statusesColorsHash = {
  [StatusesEnum.PUBLISHED]: 'green',
  // [StatusesEnum.TO_BE_PUBLISHED]: 'gold',
  [StatusesEnum.DRAFT]: '',
  [StatusesEnum.ON_MODERATION]: 'blue',
  [StatusesEnum.DECLINED]: 'red',
  [StatusesEnum.NOT_PUBLISHED]: 'red',
};

export enum ProjectStatusesEnum {
  IMPLEMENTED = 'implemented',
  AT_WORK = 'atWork',
}

export type ProjectStatuses = `${ProjectStatusesEnum}`;

export const projectStatusesNames = {
  [ProjectStatusesEnum.IMPLEMENTED]: 'Реализован',
  [ProjectStatusesEnum.AT_WORK]: 'Проект в работе',
};
