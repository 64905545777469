import React from 'react';
import { Form, Select } from 'antd';

import { isString, omit } from 'utils/helpers';
import { ICatalogItem } from 'store/slices/catalog/interfaces';

import { TFieldType } from './HotelComfortGroupsComponent';

type TProps = {
  value: TFieldType | string;
  options: ICatalogItem[];
  checkIsSelectorChosen: (id: number) => void;
  handleSelectorChange: (option: TFieldType, id?: number) => void;
  disabled?: boolean;
  error?: any;
};

export const HotelComfortGroupsSelector: React.FC<TProps> = React.forwardRef(
  (
    {
      value,
      options,
      handleSelectorChange,
      disabled,
      error,
      checkIsSelectorChosen,
      ...props
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <Form.Item
      validateStatus={error?.message ? 'error' : 'success'}
      help={error?.message}
    >
      <div ref={ref} tabIndex={0}>
        <Select
          {...omit(props, 'onChange')}
          value={value}
          onSelect={(_, option) => {
            handleSelectorChange(
              option,
              isString(value) ? undefined : value?.value
            );
          }}
          disabled={disabled}
        >
          {options?.map(item => (
            <Select.Option
              key={item.id}
              value={item.id}
              disabled={checkIsSelectorChosen(item.id)}
            >
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Form.Item>
  )
);

HotelComfortGroupsSelector.displayName = 'HotelComfortGroupsSelector';
