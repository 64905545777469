import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IOpeningInfoResponse,
  TOpeningInfoRequest,
  TChapterRequest,
  IChapter,
  TChaptersResponse,
  TChapterUpdateRequest,
  TStrategy,
  TStrategyUpdateRequest,
  TStrategyRequest,
  TStrategiesResponse,
  TAnnual,
  TAnnualUpdateRequest,
  TAnnualRequest,
  TAnnualResponse,
  TConditionUpdateRequest,
  ICondition,
  TConditionRequest,
  TConditionsResponse,
  ILandmark,
  TLandmarkUpdateRequest,
  TLandmarkRequest,
  TLandmarkResponse,
  INestedItem,
  TNestedItemUpdateRequest,
  TNestedItemRequest,
  TNestedItemsResponse,
} from './interfaces';

export const loadOpeningInfo = createAsyncThunk<IOpeningInfoResponse, null>(
  'openingInfo/loadInfo',
  async () => {
    const { data } = await apiClient.get<IOpeningInfoResponse>(
      urls.api.openingInfo.get
    );
    return data;
  }
);

export const updateOpeningInfo = createAsyncThunk<
  IOpeningInfoResponse,
  TOpeningInfoRequest
>('openingInfo/updateInfo', async params => {
  const { data } = await apiClient.put<IOpeningInfoResponse>(
    urls.api.openingInfo.put,
    {
      ...params,
    }
  );
  return data;
});

export const loadChapters = createAsyncThunk<
  TChaptersResponse,
  {
    limit: number;
    offset: number;
  }
>('openingInfo/loadChapters', async params => {
  const { data } = await apiClient.get<TChaptersResponse>(
    urls.api.openingInfo.chapter.get,
    {
      params: {
        ...params,
        sort: 'sortIndex',
      },
    }
  );
  return data;
});

export const loadAllChapters = createAsyncThunk<TChaptersResponse, null>(
  'openingInfo/loadAllChapters',
  async () => {
    const { data } = await apiClient.get<TChaptersResponse>(
      urls.api.openingInfo.chapter.get,
      {
        params: {
          sort: 'sortIndex',
        },
      }
    );
    return data;
  }
);

export const saveChapter = createAsyncThunk<IChapter, TChapterRequest>(
  'openingInfo/saveChapter',
  async params => {
    const { data } = await apiClient.post<IChapter>(
      urls.api.openingInfo.chapter.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateChapter = createAsyncThunk<IChapter, TChapterUpdateRequest>(
  'openingInfo/updateChapter',
  async ({ id, ...params }) => {
    const { data } = await apiClient.put<IChapter>(
      getUrlWithParams(urls.api.openingInfo.chapter.put, { id }),
      {
        ...params,
      }
    );
    return data;
  }
);

export const deleteChapter = createAsyncThunk<null, string>(
  'openingInfo/deleteChapter',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.chapter.delete, { id })
    );
    return data;
  }
);

export const loadStrategies = createAsyncThunk<
  TStrategiesResponse,
  {
    limit: number;
    offset: number;
  }
>('openingInfo/loadStrategies', async params => {
  const { data } = await apiClient.get<TStrategiesResponse>(
    urls.api.openingInfo.strategy.get,
    {
      params: {
        ...params,
        sort: 'sortIndex',
      },
    }
  );
  return data;
});

export const saveStrategy = createAsyncThunk<TStrategy, TStrategyRequest>(
  'openingInfo/saveStrategy',
  async params => {
    const { data } = await apiClient.post<TStrategy>(
      urls.api.openingInfo.strategy.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateStrategy = createAsyncThunk<
  TStrategy,
  TStrategyUpdateRequest
>('openingInfo/updateStrategy', async ({ id, ...params }) => {
  const { data } = await apiClient.put<TStrategy>(
    getUrlWithParams(urls.api.openingInfo.strategy.put, { id }),
    {
      ...params,
    }
  );
  return data;
});

export const deleteStrategy = createAsyncThunk<null, string>(
  'openingInfo/deleteStrategy',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.strategy.delete, { id })
    );
    return data;
  }
);

export const loadAnnual = createAsyncThunk<
  TAnnualResponse,
  {
    limit: number;
    offset: number;
  }
>('openingInfo/loadAnnual', async params => {
  const { data } = await apiClient.get<TAnnualResponse>(
    urls.api.openingInfo.annual.get,
    {
      params: {
        ...params,
        sort: 'sortIndex',
      },
    }
  );
  return data;
});

export const loadAllAnnual = createAsyncThunk<TAnnualResponse, null>(
  'openingInfo/loadAllAnnual',
  async () => {
    const { data } = await apiClient.get<TAnnualResponse>(
      urls.api.openingInfo.annual.get,
      {
        params: {
          sort: 'sortIndex',
        },
      }
    );
    return data;
  }
);

export const saveAnnual = createAsyncThunk<TAnnual, TAnnualRequest>(
  'openingInfo/saveAnnual',
  async params => {
    const { data } = await apiClient.post<TAnnual>(
      urls.api.openingInfo.annual.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateAnnual = createAsyncThunk<TAnnual, TAnnualUpdateRequest>(
  'openingInfo/updateAnnual',
  async ({ id, ...params }) => {
    const { data } = await apiClient.put<TAnnual>(
      getUrlWithParams(urls.api.openingInfo.annual.put, { id }),
      {
        ...params,
      }
    );
    return data;
  }
);

export const deleteAnnual = createAsyncThunk<null, string>(
  'openingInfo/deleteAnnual',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.annual.delete, { id })
    );
    return data;
  }
);

export const loadConditions = createAsyncThunk<
  TConditionsResponse,
  {
    limit: number;
    offset: number;
  }
>('openingInfo/loadConditions', async params => {
  const { data } = await apiClient.get<TConditionsResponse>(
    urls.api.openingInfo.conditions.get,
    {
      params: {
        ...params,
        sort: 'sortIndex',
      },
    }
  );
  return data;
});

export const loadAllConditions = createAsyncThunk<TConditionsResponse, null>(
  'openingInfo/loadAllConditions',
  async () => {
    const { data } = await apiClient.get<TConditionsResponse>(
      urls.api.openingInfo.conditions.get,
      {
        params: {
          sort: 'sortIndex',
        },
      }
    );
    return data;
  }
);

export const saveCondition = createAsyncThunk<ICondition, TConditionRequest>(
  'openingInfo/saveCondition',
  async params => {
    const { data } = await apiClient.post<ICondition>(
      urls.api.openingInfo.conditions.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateCondition = createAsyncThunk<
  ICondition,
  TConditionUpdateRequest
>('openingInfo/updateCondition', async ({ id, ...params }) => {
  const { data } = await apiClient.put<ICondition>(
    getUrlWithParams(urls.api.openingInfo.conditions.put, { id }),
    {
      ...params,
    }
  );
  return data;
});

export const deleteCondition = createAsyncThunk<null, string>(
  'openingInfo/deleteCondition',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.conditions.delete, { id })
    );
    return data;
  }
);

export const loadLandmark = createAsyncThunk<
  TLandmarkResponse,
  {
    limit: number;
    offset: number;
  }
>('openingInfo/loadLandmark', async params => {
  const { data } = await apiClient.get<TLandmarkResponse>(
    urls.api.openingInfo.landmark.get,
    {
      params: {
        ...params,
        sort: 'sortIndex',
      },
    }
  );
  return data;
});

export const loadAllLandmark = createAsyncThunk<TLandmarkResponse, null>(
  'openingInfo/loadAllLandmark',
  async () => {
    const { data } = await apiClient.get<TLandmarkResponse>(
      urls.api.openingInfo.landmark.get,
      {
        params: {
          sort: 'sortIndex',
        },
      }
    );
    return data;
  }
);

export const saveLandmark = createAsyncThunk<ILandmark, TLandmarkRequest>(
  'openingInfo/saveLandmark',
  async params => {
    const { data } = await apiClient.post<ILandmark>(
      urls.api.openingInfo.landmark.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateLandmark = createAsyncThunk<
  ILandmark,
  TLandmarkUpdateRequest
>('openingInfo/updateLandmark', async ({ id, ...params }) => {
  const { data } = await apiClient.put<ILandmark>(
    getUrlWithParams(urls.api.openingInfo.landmark.put, { id }),
    {
      ...params,
    }
  );
  return data;
});

export const deleteLandmark = createAsyncThunk<null, string>(
  'openingInfo/deleteLandmark',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.landmark.delete, { id })
    );
    return data;
  }
);

export const loadNestedItems = createAsyncThunk<TNestedItemsResponse, null>(
  'openingInfo/loadNestedItems',
  async () => {
    const { data } = await apiClient.get<TNestedItemsResponse>(
      urls.api.openingInfo.nested.get,
      {
        params: {
          sort: 'sortIndex',
        },
      }
    );
    return data;
  }
);

export const saveNestedItem = createAsyncThunk<INestedItem, TNestedItemRequest>(
  'openingInfo/saveNestedItem',
  async params => {
    const { data } = await apiClient.post<INestedItem>(
      urls.api.openingInfo.nested.post,
      {
        ...params,
      }
    );
    return data;
  }
);

export const updateNestedItem = createAsyncThunk<
  INestedItem,
  TNestedItemUpdateRequest
>('openingInfo/updateNestedItem', async ({ id, ...params }) => {
  const { data } = await apiClient.put<INestedItem>(
    getUrlWithParams(urls.api.openingInfo.nested.put, { id }),
    {
      ...params,
    }
  );
  return data;
});

export const deleteNestedItem = createAsyncThunk<null, string>(
  'openingInfo/deleteNestedItem',
  async id => {
    const { data } = await apiClient.delete<null>(
      getUrlWithParams(urls.api.openingInfo.nested.delete, { id })
    );
    return data;
  }
);
