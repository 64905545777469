// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nested-module__wrapper--QNISD {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OpeningInfo/components/Nested/Nested.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".wrapper {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Nested-module__wrapper--QNISD"
};
export default ___CSS_LOADER_EXPORT___;
