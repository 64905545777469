import { TImage } from 'types/image';

export enum ContentTypeEnum {
  text = 'text',
  image = 'image',
  gallery = 'gallery',
  video = 'video',
  widget = 'widget',
  geoPosition = 'geoPosition',
}

export enum WidgetTypeEnum {
  events = 'events',
  attractions = 'attractions',
  articles = 'articles',
  manual = 'manual',
  resorts = 'resorts',
  hotels = 'hotels',
  restaurants = 'restaurants',
  news = 'news',
  routes = 'routes',
}

export type WidgetType = `${WidgetTypeEnum}`;

export const widgetTypeNamesHash = {
  [WidgetTypeEnum.events]: 'Событие',
  [WidgetTypeEnum.attractions]: 'Достопримечательность',
  [WidgetTypeEnum.articles]: 'Статья',
  [WidgetTypeEnum.manual]: 'Геометка',
  [WidgetTypeEnum.resorts]: 'Курорт',
  [WidgetTypeEnum.hotels]: 'Отель',
  [WidgetTypeEnum.restaurants]: 'Кафе и ресторан',
  [WidgetTypeEnum.news]: 'Новость',
  [WidgetTypeEnum.routes]: 'Маршрут',
};

export type TTextBlock = {
  type: ContentTypeEnum.text;
  text: string;
};

type TImageBlock = {
  type: ContentTypeEnum.image;
  image: TImage;
};

type TGalleryBlock = {
  type: ContentTypeEnum.gallery;
  gallery: TImage[];
};

type TVideoBlock = {
  type: ContentTypeEnum.video;
  video: any;
};

type TWidgetBlock = {
  type: ContentTypeEnum.widget;
  widget: any;
};

type TManualBlock = {
  type: ContentTypeEnum.geoPosition;
  widget: any;
};

type THtmlCodeBlock = {
  type: 'htmlCode';
  htmlCode: any;
};

export type TContentBlock =
  | TTextBlock
  | TImageBlock
  | TGalleryBlock
  | TVideoBlock
  | TWidgetBlock
  | TManualBlock;
// | THtmlCodeBlock;

export type ContentType = `${ContentTypeEnum}`;
