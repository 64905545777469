import { createSlice } from '@reduxjs/toolkit';
import { savePreviewData } from './actions'
import { IPreviewSliceProps } from './interfaces';

export const previewSlice = createSlice({
  name: 'preview',
  initialState: {
    id: undefined,
    loading: true,
  } as IPreviewSliceProps,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(savePreviewData.fulfilled, (state, { payload }) => {
      state.id = payload.id;
      state.loading = false
    });
  },
});
