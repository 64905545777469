import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';

import {
  GetPurchasePlansParams,
  PurchasePlansItem,
  PurchasePlansItemRequest,
} from './interfaces';

export const loadPurchasePlansList = createAsyncThunk<
  ListResponse<PurchasePlansItem>,
  GetPurchasePlansParams,
  { state: RootState }
>('purchasePlans/loadList', async (params, { getState }) => {
  const { limit } = getState().purchasePlans;
  const { data } = await apiClient.get(urls.api.purchasePlans.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const savePurchasePlansItem = createAsyncThunk<
  PurchasePlansItem,
  PurchasePlansItemRequest
>('purchasePlans/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.purchasePlans.patch, { id }),
      params
    );

    return data;
  } else {
    const { data } = await apiClient.post(urls.api.purchasePlans.post, params);

    return data;
  }
});

export const savePurchasePlansItemDraft = createAsyncThunk<
  DeepPartial<PurchasePlansItem>,
  DeepPartial<PurchasePlansItemRequest>
>('purchasePlans/saveItemDraft', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.purchasePlans.draft.patch, { id }),
      params
    );
    return data;
  } else {
    const { data } = await apiClient.post(
      urls.api.purchasePlans.draft.post,
      params
    );

    return data;
  }
});

export const loadPurchasePlansItem = createAsyncThunk<
  PurchasePlansItem,
  string
>('purchasePlans/loadItem', async id => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.purchasePlans.getOne, { id })
  );

  return data;
});

export const setUnPublishPurchasePlan = createAsyncThunk<void, number>(
  'purchasePlans/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.purchasePlans.unPublish.patch, { id })
    )
);

export const deletePurchasePlan = createAsyncThunk<void, { id: number }>(
  'purchasePlans/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.purchasePlans.delete, { id })
    )
);
