import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from 'store';
import { IRestaurantsItem } from 'store/slices/restaurants/interfaces';
import {
  bulkUpdateRestaurantsItem,
  loadRestaurantsItem,
} from 'store/slices/restaurants/actions';
import { serializeSelectPriorityItem } from 'utils/mappings';
import { StatusesEnum } from 'constants/status';
import CardSpin from 'components/Card/CardSpin';
import { IFormProps, SampleEditForm } from 'components/Sample/SampleEditForm';
import { EntitySelect } from 'components/form/AsyncSelect/EntitySelect';
import { urls } from 'store/api';

interface IProps {
  sortFieldName?: keyof Pick<
    IRestaurantsItem,
    'sortPriority' | 'sortPriorityMainPage'
  >;
  slug: string;
}

export const Edit: React.FC<IProps> = ({
  sortFieldName = 'sortPriority',
  slug,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [queryLoading, setQueryLoading] = useState(!!id);
  const data = useAppSelector(state => state.restaurants);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit = (params: IFormProps<IRestaurantsItem>) => {
    const data = params.values;
    const prevItem =
      id && Number(id) != data.id
        ? { id: Number(id), [sortFieldName]: null }
        : {};
    const updateElements = [
      {
        id: data.id,
        [sortFieldName]: params.sortPriority,
      },
      prevItem,
    ].filter(el => el.id);
    dispatch(bulkUpdateRestaurantsItem(updateElements))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно изменена',
        });
        navigate(slug);
      })
      .catch(() => setIsLoading(false));
  };

  const handleCancel = () => {
    navigate(slug);
  };

  const initialValues = serializeSelectPriorityItem({
    values: data.item,
    sortFieldName,
  });

  useEffect(() => {
    if (id) {
      dispatch(loadRestaurantsItem(id))
        .unwrap()
        .finally(() => {
          setQueryLoading(false);
        });
    }
  }, [id]);

  if (queryLoading) {
    return <CardSpin />;
  }

  const Select = () => (
    <EntitySelect
      name='values'
      label='Ресторан'
      placeholder='Выберите ресторан'
      additional={{
        status: StatusesEnum.PUBLISHED,
        sort: `${sortFieldName}`,
      }}
      required
      requestUrl={urls.api.restaurants.get}
    />
  );

  return (
    <SampleEditForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleCancel={handleCancel}
      isLoading={isLoading}
      select={Select}
    />
  );
};
