export const PANNEL_NAMES = [
  'content',
  'gallery',
  'address',
  'getThere',
  'workTime',
  'contacts',
  'seo',
  'status',
  'attractions',
  'restaurants',
  'hotels',
];

interface IData {
  id: number;
  sortPriority: number;
}

interface IMapDataToTable {
  address: any;
  id: number;
  image: any;
  name: string;
  sortPriority: number;
}

export const mapDataToCompact = (data: Array<IData>): IData[] => {
  const usedSortPriorities: number[] = [];

  return data.map(item => {
    let newSortPriority = item.sortPriority;

    if (newSortPriority === null) {
      do {
        newSortPriority = Math.floor(Math.random() * 8) + 1;
      } while (usedSortPriorities.includes(newSortPriority));

      usedSortPriorities.push(newSortPriority);
    }

    return { id: item.id, sortPriority: newSortPriority };
  });
};

export const mapDataToTable = (
  data: Array<IMapDataToTable>
): IMapDataToTable[] => {
  return data.map(item => {
    return {
      address: item.address,
      id: item.id,
      image: item.image,
      name: item.name,
      sortPriority: item.sortPriority,
    };
  });
};
