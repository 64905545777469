import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography, notification } from 'antd';
import { Path, useFormContext } from 'react-hook-form';

import { IFormValues } from 'pages/Resorts/interfaces';
import { pick } from 'utils/helpers';
import { mapDataToCompact } from 'pages/Resorts/utils';
import { urls } from 'store/api';
import { EntitySelect } from 'components/form/AsyncSelect/EntitySelect';

import styles from './AttractionForm.module.less';
import { AttractionsTable } from './components/Table/AttractionsTable';

export const AttractionForm = () => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IFormValues>();

  const watchData = watch('attractions');

  const handleAdd = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data } = watchData as any;
    const preparedToTableData = pick(data, 'id', 'name', 'image', 'address');
    const sortPriority = watchData.length + 1;
    const newAttraction = { ...preparedToTableData, sortPriority };

    const attractionExists = watchData.some(
      attraction => attraction.id === preparedToTableData.id
    );
    if (!attractionExists) {
      setValue('attractions', [...watchData, newAttraction]);
    } else {
      notification.info({
        message: 'Уже добавлено!',
      });
    }
  };

  const handleRemove = (id: string | number) => {
    const updatedAttractionsData = watchData.filter(
      attraction => attraction.id !== id
    );
    setValue('attractions', updatedAttractionsData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSort = (item: Record<string, any>, direction: 'up' | 'down') => {
    const index = watchData.findIndex(col => col.id === item.id);

    if (direction === 'up' && index > 0) {
      const updatedData = [...watchData];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index - 1];
      updatedData[index - 1] = temp;

      setValue(
        'attractions',
        updatedData.map((attr, idx) => ({ ...attr, sortPriority: idx + 1 }))
      );
    } else if (direction === 'down' && index < watchData.length - 1) {
      const updatedData = [...watchData];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index + 1];
      updatedData[index + 1] = temp;

      setValue(
        'attractions',
        updatedData.map((attr, idx) => ({ ...attr, sortPriority: idx + 1 }))
      );
    }
  };

  const getActions = (item: { id: string | number }) => [
    {
      element: 'Удалить',
      handler: () => handleRemove(item.id),
    },
  ];

  const sortedAttractionsData = [...watchData].sort(
    (a, b) => a.sortPriority - b.sortPriority
  );

  useEffect(() => {
    setValue('attractions', mapDataToCompact(watchData));
  }, [setValue]);

  return (
    <>
      {errors.attractions ? (
        <Typography.Text type='danger' className={styles.errorText}>
          {errors.attractions.message}
        </Typography.Text>
      ) : null}

      <Row gutter={[16, 16]}>
        <Col xl={10} md={12}>
          <EntitySelect
            name={`attractions.data` as Path<IFormValues>}
            label=''
            placeholder=''
            additional={{
              lang: 'ru',
            }}
            required
            requestUrl={urls.api.attractions.get}
          />

          <Button
            disabled={watchData.length === 8}
            style={{ marginBottom: '15px' }}
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            Добавить
          </Button>
        </Col>
        <Col>
          <AttractionsTable
            handleSort={handleSort}
            data={sortedAttractionsData}
            getActions={getActions}
          />
        </Col>
      </Row>
    </>
  );
};
