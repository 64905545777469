import React from 'react';
import { Form, Checkbox, CheckboxProps, FormItemProps } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

export type CheckboxFieldProps = FormItemProps &
  CheckboxProps & {
    formItemStyle?: React.CSSProperties;
    withErrorMessage?: boolean;
  };

export const CheckboxField = ({
  label = '',
  required,
  name,
  noStyle = undefined,
  tooltip = undefined,
  extra = undefined,
  formItemStyle,
  withErrorMessage = true,
  ...props
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { value, ...rest } = field;

        return (
          <Form.Item
            label={label}
            help={withErrorMessage && fieldState.error?.message}
            validateStatus={fieldState.error ? 'error' : 'success'}
            required={required}
            noStyle={noStyle}
            tooltip={tooltip}
            extra={extra}
            style={formItemStyle}
            valuePropName='checked'
          >
            <Checkbox {...rest} {...props} checked={value} />
          </Form.Item>
        );
      }}
      control={control}
    />
  );
};
