import { notification } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch } from 'store';
import {
  saveSignedAgreementsItem,
  saveSignedAgreementsItemDraft,
} from 'store/slices/signedAgreements/actions';
import {
  SignedAgreementsItem,
  SignedAgreementsItemRequest,
} from 'store/slices/signedAgreements/interfaces';
import { DeepPartial } from 'types/utils';

import { SignedAgreementsForm } from './components/SignedAgreementsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (values: SignedAgreementsItemRequest) => {
    setIsLoading(true);
    dispatch(saveSignedAgreementsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно добавлено',
        });
        navigate('/signedAgreements');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<SignedAgreementsItem>) => {
    setIsLoading(true);
    dispatch(saveSignedAgreementsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно добавлено',
        });
        navigate('/signedAgreements');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Создание'>
      <SignedAgreementsForm
        onSubmit={onSubmit}
        isDraft
        onSaveAsDraft={onSaveAsDraft}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};
