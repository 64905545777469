import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';

import {
  GetSignedAgreementsParams,
  SignedAgreementsItem,
  SignedAgreementsItemRequest,
} from './interfaces';

export const loadSignedAgreementsList = createAsyncThunk<
  ListResponse<SignedAgreementsItem>,
  GetSignedAgreementsParams,
  { state: RootState }
>('signedAgreements/loadList', async (params, { getState }) => {
  const { limit } = getState().signedAgreements;
  const { data } = await apiClient.get(urls.api.signedAgreements.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const saveSignedAgreementsItem = createAsyncThunk<
  SignedAgreementsItem,
  SignedAgreementsItemRequest
>('signedAgreements/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.signedAgreements.patch, { id }),
      params
    );

    return data;
  } else {
    const { data } = await apiClient.post(
      urls.api.signedAgreements.post,
      params
    );

    return data;
  }
});

export const saveSignedAgreementsItemDraft = createAsyncThunk<
  DeepPartial<SignedAgreementsItem>,
  DeepPartial<SignedAgreementsItemRequest>
>('purchasePlans/saveItemDraft', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.signedAgreements.draft.patch, { id }),
      params
    );
    return data;
  } else {
    const { data } = await apiClient.post(
      urls.api.signedAgreements.draft.post,
      params
    );

    return data;
  }
});

export const loadSignedAgreementsItem = createAsyncThunk<
  SignedAgreementsItem,
  string
>('signedAgreements/loadItem', async id => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.signedAgreements.getOne, { id })
  );

  return data;
});

export const setUnPublishSignedAgreement = createAsyncThunk<void, number>(
  'purchasePlans/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.signedAgreements.unPublish.patch, { id })
    )
);

export const deleteSignedAgreement = createAsyncThunk<void, { id: number }>(
  'purchasePlans/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.signedAgreements.delete, { id })
    )
);
