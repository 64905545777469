// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 499px) {\n  .Widget-module__content--c3Ny5 {\n    padding: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Content/Widget/Widget.module.less"],"names":[],"mappings":"AACE;EAAA;IACE,UAAA;EACF;AACF","sourcesContent":[".content {\n  @media (max-width: 499px) {\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Widget-module__content--c3Ny5"
};
export default ___CSS_LOADER_EXPORT___;
