export enum Roles {
  Unauthorized = 'unauthorized',
  Guest = 'adminGuest',
  Admin = 'admin',
  AdminBuisness = 'adminBusiness',
  AdminContent = 'adminContent',
  AdminPress = 'adminPress',
  AdminSales = 'adminSales',
  AdminInvestments = 'adminInvestments',
  AdminOez = 'adminOez',
}

export type Role = `${Roles}`;

export const roleNamesHash = {
  [Roles.Guest]: 'Не выбрано',
  [Roles.Admin]: 'Администратор',
  [Roles.AdminBuisness]: 'Администратор-бизнес',
  [Roles.AdminContent]: 'Контент-менеджер',
  [Roles.AdminPress]: 'Пресс-служба',
  [Roles.AdminSales]: 'Управление закупками',
  [Roles.AdminInvestments]: 'Департамент инвестиций',
  [Roles.AdminOez]: 'Управление ОЭЗ',
};
