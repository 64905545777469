import {
  PaperClipOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, notification, Tooltip, Upload } from 'antd';
import React, { SyntheticEvent } from 'react';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { useFormContext, useWatch } from 'react-hook-form';
import { AxiosError } from 'axios';

import { uploadMaterial } from 'utils/upload';
import { MAX_MATERIAL_FILE_SIZE } from 'constants/upload';
import { InputField } from 'components/form/base/InputField';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import {
  DEFAULT_ACCEPT,
  ERROR_CODE_KEY,
  VALID_FORMATS_LIST,
} from './constants';
import styles from './MaterialFile.module.less';

export interface MaterialFileProps {
  name: string;
  validFormatsList?: string[];
  accept?: string;
  uploadsUnsupportedFileType?: string;
  maxMaterialFileSize?: number;
}

enum SERVER_ERROR_DICTIONARY {
  UPLOADS_UNSUPPORTED_FILE_TYPE = 'У файла недопустимый формат. Загрузите файл с расширением .pdf, .doc, .docx, .jpeg, .jpg, .png',
}

export const MaterialFile = ({
  name,
  validFormatsList = VALID_FORMATS_LIST,
  accept = DEFAULT_ACCEPT,
  uploadsUnsupportedFileType = SERVER_ERROR_DICTIONARY.UPLOADS_UNSUPPORTED_FILE_TYPE,
  maxMaterialFileSize = MAX_MATERIAL_FILE_SIZE,
}: MaterialFileProps) => {
  const { setValue } = useFormContext();
  const value = useWatch({ name: `${name}.file` });
  const [isLoading, setIsLoading] = React.useState(false);

  const fileListItem = React.useMemo(
    () => (value?.path ? [{ name: value.realName, uid: value.pathName }] : []),
    [value]
  );

  const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess?.('ok');
    }, 0);
  };

  const handleFileChange = async (info: UploadChangeParam) => {
    const isLt2M = (info.file.size as number) < maxMaterialFileSize;
    const isValidType = validFormatsList.includes(info.file.type);

    if (!isLt2M) {
      return notification.error({
        message: `Пожалуйста, выберите файл с размером не более ${
          maxMaterialFileSize / (1024 * 1024)
        } МБ`,
      });
    }
    if (!isValidType) {
      return notification.error({
        message: uploadsUnsupportedFileType,
      });
    }

    setIsLoading(true);
    try {
      const { path, fileList } = await uploadMaterial(info);
      const file = fileList[0];

      setValue(`${name}.file`, {
        baseUrl: UPLOAD_SERVICE_BASE_URL,
        path,
        displayName: file.name.split('.').slice(0, -1).join('.'),
        realName: file.name,
      });
    } catch (error) {
      if (error.code && ERROR_CODE_KEY === error.code) {
        notification.error({
          message: uploadsUnsupportedFileType,
        });
      } else {
        notification.error({
          message: (error as AxiosError)?.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTooltipClick = (e: SyntheticEvent) => e.stopPropagation();

  const tooltipText = (
    <ul className={styles.tooltipList}>
      <li>Размер файла - до {maxMaterialFileSize / (1024 * 1024)} Мб</li>
      <li>
        Форматы - текстовые, табличные, презентации, фото (pdf, doc, docx, xls,
        xlsx, csv, odf, ppt, .jpeg, .jpg, .png).
      </li>
      <li>Нельзя видео и аудио</li>
    </ul>
  );

  return (
    <Col>
      <Upload
        accept={accept}
        name={name}
        customRequest={dummyRequest}
        onChange={handleFileChange}
        className={styles.upload}
        maxCount={1}
        fileList={fileListItem}
      >
        <Button icon={<UploadOutlined />} loading={isLoading}>
          Загрузить
        </Button>
        <Tooltip title={tooltipText} placement='rightTop'>
          <Button
            icon={<QuestionCircleOutlined />}
            className={styles.tooltipButton}
            type='text'
            onClick={handleTooltipClick}
          />
        </Tooltip>
      </Upload>
      {value?.path && (
        <div className={styles.attachedFile}>
          <PaperClipOutlined />
          <div className={styles.attachedFileName}>{value?.realName}</div>
        </div>
      )}
      {value?.path && (
        <InputField
          name={`${name}.file.displayName`}
          placeholder='Название документа'
          className={styles.nameField}
          maxLength={255}
          formItemStyle={{ marginBottom: 0 }}
        />
      )}
    </Col>
  );
};
