import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IResortItem } from 'store/slices/resorts/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadResortItem,
  saveResortItem,
  saveResortItemAsDraft,
} from 'store/slices/resorts/actions';
import { LangEnum } from 'constants/lang';

import { ResortsForm } from './components/ResortsForm';
import { mapValuesToForm } from './components/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.resorts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadResortItem(id));
  }, []);

  const onFinish = (values: IResortItem) => {
    setIsLoading(true);
    dispatch(saveResortItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/resorts');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IResortItem) => {
    setIsLoading(true);
    dispatch(saveResortItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/resorts');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/resorts');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    category: null,
    categoryData: null,
    tags: [],
    tagsData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item.id && item.id === Number(id) ? (
        <ResortsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
