import React from 'react';
import { Col, Row } from 'antd';

import { LangEnumKeys } from 'constants/lang';

import { CatalogAsyncSelect } from '../selects/CatalogAsyncSelect';
import { InputField } from '../base/InputField';
import { DurationField } from '../DurationField/DurationField';

type TProps = {
  lang: LangEnumKeys;
};
export const HotelTerms: React.FC<TProps> = ({ lang }) => {
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <CatalogAsyncSelect
            name='features'
            label='Особенности размещения'
            placeholder='Выберите особенности'
            requestParams={{
              entityType: 'hotels',
              fieldType: 'hotelFeatures',
              lang,
            }}
            mode='multiple'
          />
        </Col>
        <Col>
          <DurationField
            name='arrivalTime'
            label='Время заезда'
            suffixIcon={null}
          />
        </Col>
        <Col>
          <DurationField
            name='departureTime'
            label='Время выезда'
            suffixIcon={null}
          />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <InputField
            name='price'
            label='Стоимость'
            type='price'
            placeholder='Введите стоимость'
            maxLength={6}
          />
        </Col>
      </Row>
    </>
  );
};
