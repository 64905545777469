import { IRegionRelationsItem } from 'store/slices/regions/interfaces';

export enum EntityRegionRelationsTypes {
  unknown = 'unknown',
  railway = 'railway',
  airport = 'airport',
  seaport = 'seaport',
  attractions = 'attractions',
  resorts = 'resorts',
  hotels = 'hotels',
  restaurants = 'restaurants',
}

export type TEntityRegionRelationsTypesKeys =
  keyof typeof EntityRegionRelationsTypes;

export type TGeoObject = {
  properties: {
    _data: {
      balloonContentHeader: string;
      index: number;
      point: IRegionRelationsItem;
    };
  };
};

export type TYMapOptions = {
  duration?: number;
  timingFunction?: 'ease-in-out' | string;
  checkZoomRange?: boolean;
  useMapMargin?: boolean;
};

export type TYMapRef = {
  setCenter: (
    coordinates: number[],
    zoom?: number,
    options?: TYMapOptions
  ) => void;
  getZoom: () => number;
  setBounds: (value: Array<[number, number]>) => void;
  balloon: {
    close: () => void;
  };
  events: {
    add: (type: string, event: () => void) => void;
  };
  behaviors: {
    disable: (event: string) => void;
  };
  _containerSize: number[];
  setZoom: (zoom: number) => void;
  options: any;
  converter: {
    globalToPage: (arr: number[]) => number[];
    pageToGlobal: (arr: number[]) => number[];
  };
};
