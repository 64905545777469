import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { StatusesEnum } from 'constants/status';

import { TSalesDraftItem, ISalesItem, ISalesItemResponse } from './interfaces';

type TListResponse = { data: ISalesItemResponse[]; total: number };

export const loadSalesList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('sales/loadList', async (params, { getState }) => {
  const { limit } = getState().sales;
  const { data } = await apiClient.get<TListResponse>(urls.api.sales.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadSalesItem = createAsyncThunk<ISalesItemResponse, string>(
  'sales/loadItem',
  async id => {
    const { data } = await apiClient.get<ISalesItemResponse>(
      getUrlWithParams(urls.api.sales.getOne, {
        id,
      })
    );
    return data;
  }
);

export const saveSalesItem = createAsyncThunk<ISalesItem, ISalesItem>(
  'sales/saveItem',
  async ({ id, ...params }) => {
    if (id) {
      const { data } = await apiClient.put<ISalesItem>(
        getUrlWithParams(urls.api.sales.put, { id }),
        {
          ...params,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post<ISalesItem>(urls.api.sales.post, {
        ...params,
      });
      return data;
    }
  }
);

export const saveSalesItemAsDraft = createAsyncThunk<
  TSalesDraftItem,
  TSalesDraftItem
>('sales/saveDraftItem', async ({ id, ...requestParams }) => {
  if (id) {
    const { data } = await apiClient.put<TSalesDraftItem>(
      getUrlWithParams(urls.api.sales.draft.put, { id }),
      {
        ...requestParams,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<TSalesDraftItem>(
      urls.api.sales.draft.post,
      {
        ...requestParams,
      }
    );
    return data;
  }
});

export const setUnPublishSalesItem = createAsyncThunk<void, string>(
  'sales/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.sales.status.put, { id }),
      {
        status: StatusesEnum.NOT_PUBLISHED,
      }
    );
  }
);

export const deleteSalesItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('sales/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.sales.delete, { id }));
  return data;
});
