import { MaterialFileType } from 'components/form/AddMaterial/components/MaterialItem/types';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  RegulationsItem,
  RegulationsItemRequest,
} from 'store/slices/regulations/interfaces';
import { DeepPartial } from 'types/utils';
import { omit } from 'utils/helpers';
import { mapSelect, mapValuesToSelect, SelectValue } from 'utils/mappings';

import { FormValues } from './types';

export const mapValues = ({
  name,
  material,
  status,
  category,
  ...values
}: FormValues): RegulationsItemRequest => ({
  ...values,
  name: name.trim(),
  material: material as Omit<MaterialFileType, 'displayName'>,
  status: mapSelect(status) as Statuses,
  category: mapSelect(category as SelectValue) as number,
});

export const mapValuesToForm = ({
  name,
  status,
  categoryData,
  ...item
}: RegulationsItem): FormValues => ({
  ...omit(item, [
    'author',
    'creator',
    'editor',
    'publishedAt',
    'createdAt',
    'updatedAt',
    'authorData',
    'category',
    'lang',
  ]),
  name: name || '',
  status: status === StatusesEnum.DRAFT ? StatusesEnum.PUBLISHED : status,
  category: mapValuesToSelect(categoryData),
});

export const mapValuesAsDraft = ({
  name,
  category,
  ...values
}: DeepPartial<FormValues>): DeepPartial<RegulationsItem> => ({
  ...omit(values, ['status']),
  name: name?.trim() || null,
  category: category ? mapSelect(category as SelectValue) : undefined,
});
