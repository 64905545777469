import { createSlice } from '@reduxjs/toolkit';

import { ISliderState } from './interfaces';
import { getSlider, getSliderItem } from './actions';

export const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    slider: {
      language: 'ru',
      slides: {
        data: [],
      },
    },
    sliderItem: null,
    isLoading: false,
  } as ISliderState,
  reducers: {
    setSliderItem: (state, { payload }) => {
      state.sliderItem = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSlider.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getSlider.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getSlider.fulfilled, (state, { payload }) => {
      state.slider = payload;
      state.isLoading = false;
    });
    builder.addCase(getSliderItem.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getSliderItem.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getSliderItem.fulfilled, (state, { payload }) => {
      state.sliderItem = payload;
      state.isLoading = false;
    });
  },
});

export const { setSliderItem } = sliderSlice.actions;
