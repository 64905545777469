import React, { useState } from 'react';
import { Button, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import {
  deleteInvestInfrastructuresItem,
  loadInvestInfrastructuresList,
  setUnPublishInvestInfrastructuresItem,
} from 'store/slices/investInfrastructures/actions';
import { PageTitles } from 'constants/pageTitles';
import { canBeUnPublished } from 'utils/rights';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { IInvestInfrastructuresItemResponse } from 'store/slices/investInfrastructures/interfaces';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { b2bUrl } = useAppSelector(state => state.configSlice);
  const { list, total, limit } = useAppSelector(
    state => state.investInfrastructures
  );
  const loadInvestInfrastructures = () => {
    dispatch(
      loadInvestInfrastructuresList({
        ...query,
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  useDeepEffect(() => {
    loadInvestInfrastructures();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(null);
    setCurrentItem(null);
  };

  const handleUnPublish = async id => {
    await dispatch(setUnPublishInvestInfrastructuresItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadInvestInfrastructures();
        modalCloseHandler();
      });
  };

  const handleDelete = async id => {
    await dispatch(deleteInvestInfrastructuresItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        loadInvestInfrastructures();
        modalCloseHandler();
      });
  };

  const getActions = (item: IInvestInfrastructuresItemResponse) => [
    item.status === StatusesEnum.PUBLISHED && {
      key: 'website',
      label: (
        <a
          target='_blank'
          rel='noreferrer nofollow'
          href={`${b2bUrl}/${item.lang}/invest-infrastructure/${item.id}`}
        >
          На сайте
        </a>
      ),
    },
    {
      key: 'edit',
      label: (
        <Link to={`/investInfrastructures/${item.id}/edit`}>Редактировать</Link>
      ),
    },
    item.lang === LangEnum.ru && {
      key: 'addTranslation',
      label: (
        <Link to={`/investInfrastructures/${item.id}/add-translation`}>
          Создать копию для перевода
        </Link>
      ),
    },
    canBeUnPublished(item) && {
      key: 'unPublish',
      label: <Typography.Text>Снять с публикации</Typography.Text>,
      onClick: () => handleUnPublish(item.id),
    },
    {
      key: 'remove',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        setModalIsOpen('remove');
        setCurrentItem(item);
      },
    },
  ];

  return (
    <MainLayout
      pageTitle={PageTitles.investInfrastructures}
      extraButtonsList={[
        <Link key={1} to='/investInfrastructures/add'>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
      aside={<Filters />}
    >
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen === 'remove' && (
            <StickyConfirmModal
              isOpen={modalIsOpen === 'remove'}
              onRequestClose={modalCloseHandler}
              title='Удаление инвестиционной инфраструктуры'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину
                  инвестиционную инфраструктуру{' '}
                  <Typography.Text strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={() => handleDelete(currentItem?.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
