import Yup from 'utils/yup';

/* Ссылка в формате "/" + любая буква или цифра и затем любые символы */
export const pathSchema = Yup.string().matches(
  /^\/(?:\w)(.*)/g,
  'Введите ссылку в формате /about'
);

const menuItemsValidationSchema = Yup.array().of(
  Yup.object().shape({
    itemName: Yup.string().required(),
    url: Yup.lazy((value: string) => {
      if (!value) return Yup.mixed();

      if (value.startsWith('http')) {
        return Yup.string().url();
      } else if (value === '/') {
        return Yup.mixed();
      } else {
        return pathSchema;
      }
    }),
    subItems: Yup.lazy(values => {
      if (values?.length > 0) {
        return menuItemsValidationSchema;
      } else {
        return Yup.mixed();
      }
    }),
  })
);

export const validationSchema = Yup.object().shape({
  items: menuItemsValidationSchema,
});
