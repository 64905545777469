import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IFilterReportComments,
  IReportComment,
  IRequestReportComment,
  TResponseReportComments,
} from './interfaces';

export const loadReportCommentsList = createAsyncThunk<
  TResponseReportComments,
  IFilterReportComments & { id: string },
  { state: RootState }
>('reportComments/loadList', async ({ id, ...params }, { getState }) => {
  const { limit } = getState().reportComments;
  const { data } = await apiClient.get<TResponseReportComments>(
    getUrlWithParams(urls.api.reportsComments.get, { id }),
    {
      params: {
        limit,
        sort: '-createdAt',
        ...params,
      },
    }
  );

  return {
    data: data.data.reverse(),
    total: data.total,
  };
});

export const saveReportComment = createAsyncThunk<
  IReportComment,
  IRequestReportComment
>('reportComments/saveItem', async params => {
  const { data } = await apiClient.post<IReportComment>(
    urls.api.reportsComments.post,
    {
      ...params,
    }
  );
  return data;
});
