import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from 'store';
import {
  loadReportDocumentsList,
  saveReportDocument,
} from 'store/slices/reportDocuments/actions';
import { FormDocumentUpload } from 'components/form/FormDocumentUpload/FormDocumentUpload';
import { useDeepEffect } from 'utils/useDeepEffect';
import { ReportDocumentType } from 'constants/reports';
import { IDocument } from 'types/document';

interface IFormValues {
  file: IDocument & { size: number };
}

export const UploadDocument = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const methods = useForm<IFormValues>({
    defaultValues: {
      file: null,
    },
  });

  const file = useWatch({ name: 'file', control: methods.control });

  const uploadDocument = () => {
    if (file) {
      const preparedValues = {
        reportId: id,
        type: ReportDocumentType.Scan,
        displayName: file.realName,
        document: file,
      };
      dispatch(saveReportDocument(preparedValues))
        .then(() => {
          notification.success({
            message: 'Документ успешно загружен',
          });
          navigate(`${location.pathname}`);
          dispatch(loadReportDocumentsList({ id }));
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    methods.reset({ file: null });
  };

  useDeepEffect(() => {
    uploadDocument();
  }, [file]);

  return (
    <FormProvider {...methods}>
      <FormDocumentUpload
        name='file'
        title='Загрузить документ'
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        accept='.jpg, .jpeg, .pdf, .xls, .xlsx'
        errorMessage='У файла недопустимый формат. Загрузите файл с расширением .jpg, .jpeg, .pdf, .xls, .xlsx'
        validFormatsList={[
          'image/jpg',
          'image/jpeg',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
      />
    </FormProvider>
  );
};
