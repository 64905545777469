import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';

import { MainLayout } from 'components/Layout/MainLayout';
import { InputField } from 'components/form/base/InputField';
import { useQuery } from 'hooks/useQuery';
import {
  loadRegionsItem,
  loadRegionsRelations,
  setVisibleRegionRelationsItem,
} from 'store/slices/regions/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { debounce, removeEmptyValues } from 'utils/helpers';
import { RegionRelationsTypeSelector } from 'components/form/RegionRelations/components/RegionRelationsTypeSelector';
import { RegionRelationsMap } from 'components/form/RegionRelations/RegionRealtionsMap';
import { ListTable } from 'components/form/RegionRelations/components/ListTable/RegionsRelationsListTable';
import { CATEGORY_TYPES } from 'components/form/RegionRelations/constants';
import { PageTitles } from 'constants/pageTitles';
import { useDeepEffect } from 'utils/useDeepEffect';

import styles from './RegionRelations.module.less';

const GET_LIMIT = 10;

export const RegionRelations = () => {
  const {
    item,
    relations: { data: list, total },
  } = useAppSelector(state => state.regions);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const { id } = useParams();
  const [sort, setSort] = React.useState('');
  React.useEffect(() => {
    dispatch(loadRegionsItem(id));
  }, []);

  const queryValuesToForm = {
    nameQuery: query.nameQuery || '',
    type: query.type || query.category || null,
  };

  const methods = useForm({
    defaultValues: {
      nameQuery: '',
      type: null,
      ...queryValuesToForm,
    },
  });

  const getRegionRelationsList = () => {
    const { offset, type, nameQuery, category } = query;
    const values = removeEmptyValues({
      nameQuery: nameQuery as string,
      type: type,
      category: category,
      regionFiasIds: item.fiasId,
      sort,
      offset: offset as string,
      limit: GET_LIMIT,
    });
    dispatch(loadRegionsRelations(values));
  };

  const debounceSubmit = React.useCallback(
    debounce(values => {
      const queryString = new URLSearchParams({
        nameQuery: values.nameQuery,
        ...(values.type && values.type.value !== 'unknown'
          ? CATEGORY_TYPES.includes(values.type.value)
            ? { category: values.type.value }
            : { type: values.type.value }
          : null),
      }).toString();

      navigate(`${location.pathname}?${queryString}`);
    }, 400),
    []
  );

  React.useEffect(() => {
    const subscription = methods.watch(methods.handleSubmit(debounceSubmit));
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  useDeepEffect(() => {
    if (item.fiasId) {
      getRegionRelationsList();
    }
  }, [query, item, sort]);

  const setIsItemVisible = async (id: number, isHidden: boolean) => {
    dispatch(setVisibleRegionRelationsItem({ id, isHidden }));
  };

  const filteredListByIsHiddenFlag = React.useMemo(() => {
    return list.length ? list.filter(item => !item.isHidden) : [];
  }, [list]);

  return (
    <MainLayout
      pageTitle={PageTitles.regionRelations}
      hasBackButton
      isAsideVisible={false}
    >
      <FormProvider {...methods}>
        <form>
          <Row gutter={24} className={styles.container}>
            <Col>
              <Content>
                <Row style={{ flexWrap: 'nowrap' }}>
                  <Col>
                    <RegionRelationsTypeSelector />
                  </Col>
                  <Col flex='auto' style={{ marginLeft: 12 }}>
                    <Input.Group compact>
                      <InputField
                        name='nameQuery'
                        placeholder='Поиск'
                        formItemStyle={{ width: 'calc(100% - 32px)' }}
                        maxLength={255}
                      />
                      <Button type='primary' icon={<SearchOutlined />} />
                    </Input.Group>
                  </Col>
                </Row>

                <Row>
                  <ListTable
                    data={list}
                    total={total}
                    setIsItemVisible={setIsItemVisible}
                    limit={GET_LIMIT}
                    setSort={setSort}
                  />
                </Row>
              </Content>
            </Col>
            <Col className={styles.map}>
              <RegionRelationsMap placeMarkList={filteredListByIsHiddenFlag} />
            </Col>
          </Row>
        </form>
      </FormProvider>
    </MainLayout>
  );
};
