// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegionRelations-module__container--KmECl {\n  display: grid;\n  grid-template-columns: 50% 50%;\n}\n@media (max-width: 799px) {\n  .RegionRelations-module__container--KmECl {\n    display: flex;\n    flex-direction: column;\n  }\n}\n.RegionRelations-module__map--L5lQb {\n  height: 710px;\n}\n@media (max-width: 799px) {\n  .RegionRelations-module__map--L5lQb {\n    height: 600px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Regions/RegionRelations/RegionRelations.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EAAA;IACE,aAAA;IACA,sBAAA;EAEF;AACF;AACA;EACE,aAAA;AACF;AACE;EAAA;IACE,aAAA;EAEF;AACF","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 50% 50%;\n\n  @media (max-width: 799px) {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.map {\n  height: 710px;\n\n  @media (max-width: 799px) {\n    height: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RegionRelations-module__container--KmECl",
	"map": "RegionRelations-module__map--L5lQb"
};
export default ___CSS_LOADER_EXPORT___;
