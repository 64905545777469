import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  ICommentsItem,
  TCommentsResponse,
  TGetCommentsParams,
  TPostCommentParams,
} from './interfaces';

export const loadCommentsList = createAsyncThunk<
  TCommentsResponse,
  TGetCommentsParams,
  { state: RootState }
>('formComments/loadList', async ({ formId, ...params }, { getState }) => {
  const { limit } = getState().formComments;
  const { data } = await apiClient.get<TCommentsResponse>(
    getUrlWithParams(urls.api.formComments.get, { formId }),
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const postComment = createAsyncThunk<ICommentsItem, TPostCommentParams>(
  'formComments/postItem',
  async params => {
    const { data } = await apiClient.post<ICommentsItem>(
      urls.api.formComments.post,
      {
        ...params,
      }
    );

    return data;
  }
);
