import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, Col, Collapse, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { InputField } from 'components/form/base/InputField';
import { CheckboxField } from 'components/form/base/CheckboxField';
import { TMenuItem } from 'store/slices/menu/interfaces';

import { MenuItemHeader } from '../MenuItemHeader/MenuItemHeader';
import { MenuItemActions } from '../MenuItemActions/MenuItemActions';
import { MenuItems } from '../MenuItems/MenuItems';
import { DEFAULT_NESTING_LEVEL, getPrependValue } from '../../../constants';
import styles from './MenuItem.module.less';

type TProps = {
  name: string;
  index: number;
  handleMoveItem: (index: number, step: number) => void;
  handleRemoveItem: (index: number) => void;
  fieldsLength: number;
  depthLevel: number;
};

export const MenuItem: React.FC<TProps> = ({
  name,
  index,
  handleMoveItem,
  handleRemoveItem,
  fieldsLength,
  depthLevel,
}) => {
  const { setValue, control } = useFormContext();

  const subItemsValues = useWatch({ name: `${name}.subItems`, control }) as [];

  const handleAddSubItem = () =>
    setValue(`${name}.subItems`, [
      { ...getPrependValue(depthLevel - 1), parentName: name },
      ...subItemsValues,
    ]);

  const isDisabledUrlInput =
    subItemsValues?.length > 0 &&
    !subItemsValues.every((item: TMenuItem) => !item.isActive);

  return (
    <Col span={24}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Collapse
            className={classNames(styles.collapse, {
              [styles.nestedCollapse]: depthLevel !== DEFAULT_NESTING_LEVEL,
            })}
            expandIconPosition='end'
            defaultActiveKey={[1]}
          >
            <Collapse.Panel
              key={1}
              header={
                <MenuItemHeader
                  parentName={field.value.parentName}
                  name={field.name}
                  index={index}
                  depthLevel={depthLevel}
                />
              }
              className={classNames({ [styles.error]: !!fieldState.error })}
              extra={
                <MenuItemActions
                  name={field.name}
                  parentName={field.value.parentName}
                  index={index}
                  fieldsLength={fieldsLength}
                  onMove={handleMoveItem}
                  onRemove={handleRemoveItem}
                />
              }
            >
              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 10 }}>
                  <InputField
                    name={`${field.name}.itemName`}
                    label='Название'
                    placeholder='Введите название'
                    required
                    maxLength={60}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 10 }}>
                  <InputField
                    name={`${field.name}.url`}
                    label='Ссылка'
                    placeholder='Введите ссылку'
                    tooltip={`В качестве ссылки нужно указать либо полный адрес
                          страницы включая http:// либо относительный адрес -
                          только название страницы (например /page7890.html или
                          /about)`}
                    disabled={isDisabledUrlInput}
                  />
                </Col>
                <Col flex='auto' className={styles.checkbox}>
                  <CheckboxField
                    name={`${field.name}.openNewPage`}
                    disabled={isDisabledUrlInput}
                  >
                    Открывать в новом окне
                  </CheckboxField>
                </Col>
              </Row>
              {depthLevel > 0 && (
                <Row>
                  <Col span={24}>
                    <div className={styles.subMenuTitle}>Подменю</div>
                    <Row>
                      <Button
                        type='link'
                        icon={<PlusCircleOutlined />}
                        onClick={handleAddSubItem}
                        className={styles.addButton}
                      >
                        Добавить пункт подменю
                      </Button>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <MenuItems
                      name={`${name}.subItems`}
                      depthLevel={depthLevel - 1}
                    />
                  </Col>
                </Row>
              )}
            </Collapse.Panel>
          </Collapse>
        )}
      />
    </Col>
  );
};
