import React from 'react';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { MainImage } from 'components/form/MainImage/MainImage';
import {
  CatalogRegionSelect,
  TRegionType,
} from 'components/form/selects/CatalogRegionSelect';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { Status } from 'components/form/Status/Status';
import { TImage } from 'types/image';
import {
  ContentTypeEnum,
  TContentBlock,
} from 'components/form/Content/contentTypes';
import { isEmpty, isString } from 'utils/helpers';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { INewsItem } from 'store/slices/news/interfaces';
import { Statuses } from 'constants/status';
import { LangEnumKeys } from 'constants/lang';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { Select } from 'components/form/base/Select';
import {
  NewsTopicEnum,
  newsTopicNamesHash,
  TNewsTopic,
} from 'constants/newsTopic';
import { TSEO } from 'types/seo';
import { SEO } from 'components/form/SEO/SEO';
import { sourcePreview } from 'store/slices/preview/interfaces';
import { savePreviewData } from 'store/slices/preview/actions';
import { useAppDispatch, useAppSelector } from 'store';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';

interface IFormValues extends TSEO {
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  image: TImage;
  topic: TNewsTopic | { value: TNewsTopic; label: string };
  region: TRegionType;
  tags: number[] | { value: number; label: string }[];
  shortDescription: string;
  content: TContentBlock[];
  status: Statuses;
  source: string;
  sourceLink: string;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IFormValues) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveDraft?: (values: any) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
  itemId?: number;
};

const enabledBusinessContentTypes = [
  ContentTypeEnum.image,
  ContentTypeEnum.gallery,
  ContentTypeEnum.video,
];

export const NewsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
  itemId,
}) => {
  const { b2cUrl, b2bUrl } = useAppSelector(state => state.configSlice);
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'description',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);
  const dispatch = useAppDispatch();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSaveDraft(formValues as any);
  };

  const currentLanguage = methods.watch('lang.value');
  const currentTopic = methods.watch('topic.value');

  const handlePreviewClick = async () => {
    const results = await (dispatch(
      savePreviewData({
        source: sourcePreview.news,
        ...(itemId !== undefined && { sourceId: itemId }),
        data: methods.getValues(),
      })
    ) as Promise<{ payload: { id: number; sysName: string } }>);
    const id = results.payload.id;
    const sysName = results.payload.sysName;
    const urlByTopicType = currentTopic === 'business' ? b2bUrl : b2cUrl;
    const linkToPortal =
      currentLanguage === 'en'
        ? `${urlByTopicType}/en/news/preview/${id}/${sysName}`
        : `${urlByTopicType}/news/preview/${id}/${sysName}`;

    window.open(linkToPortal, '_blank');
  };

  const submit = (values: FieldValues) => {
    const preparedValues = mapValues(values) as unknown as INewsItem;

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');
  const topic = methods.watch('topic');
  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), tags: [] });
    }
  }, [lang]);

  useDeepEffect(() => {
    if (topic && !isString(topic) && topic.value !== NewsTopicEnum.Tourist) {
      const filteredContent = methods.getValues().content.filter(item => {
        return (
          item.type === ContentTypeEnum.text ||
          enabledBusinessContentTypes.includes(item.type)
        );
      });
      methods.reset({ ...methods.getValues(), content: filteredContent });
    }
  }, [topic]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Глав. фото' required />
            </Col>
            <Col flex={1} style={{ minWidth: 300 }}>
              <InputField
                name='name'
                label='Название публикации'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <Select
                name='topic'
                label='Тема/рубрика'
                required
                placeholder='Выберите тему'
                options={[
                  ...Object.keys(newsTopicNamesHash).map(item => ({
                    value: item,
                    label: newsTopicNamesHash[item],
                  })),
                ]}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                placeholder='Выберите регион'
                isNewsPage
                required
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <CatalogAsyncSelect
                name='tags'
                label='Теги'
                placeholder='Выберите теги'
                requestParams={{
                  entityType: 'news',
                  fieldType: 'tags',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Введите описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollpaseTabs(value as string[])}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание публикации' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                  {...(!isString(topic) &&
                    topic?.value !== NewsTopicEnum.Tourist && {
                      enabledTypes: enabledBusinessContentTypes,
                    })}
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status
              status={initialValues.status}
              withSourceInput
              isStatusesLimited
            />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft ? (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          ) : null}

          <Button onClick={handlePreviewClick} disabled={isLoading}>
            Предпросмотр
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
