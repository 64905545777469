import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch } from 'store';
import {
  savePurchasePlansItem,
  savePurchasePlansItemDraft,
} from 'store/slices/purchasePlans/actions';
import {
  PurchasePlansItem,
  PurchasePlansItemRequest,
} from 'store/slices/purchasePlans/interfaces';
import { DeepPartial } from 'types/utils';

import { PurchasePlansForm } from './components/PurchasePlansForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (values: PurchasePlansItemRequest) => {
    setIsLoading(true);
    dispatch(savePurchasePlansItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'План закупок успешно добавлен',
        });
        navigate('/purchasePlans');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<PurchasePlansItem>) => {
    setIsLoading(true);
    dispatch(savePurchasePlansItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'План закупок успешно создан',
        });
        navigate('/purchasePlans');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Создание'>
      <PurchasePlansForm
        onSubmit={onSubmit}
        isDraft
        isLoading={isLoading}
        onSaveAsDraft={onSaveAsDraft}
      />
    </MainLayout>
  );
};
