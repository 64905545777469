import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Row } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate } from 'react-router';

import { IRegionsItemResponse } from 'store/slices/regions/interfaces';
import { InputField } from 'components/form/base/InputField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { UploadSingleFile } from 'components/form/UploadSingleFile/UploadSingleFile';
import { RegionsEnumSelect } from 'components/form/selects/RegionsEnumSelect';

import { validationSchema } from './validation';
import { IconTooltip } from '../../../PriorityIndustry/components/PriorityIndustryForm/components/DirectionForm/components/IconTooltip/IconTooltip';
import { mapValues } from './utils';

export interface IFormProps extends Omit<IRegionsItemResponse, 'enum'> {
  enum:
    | string
    | {
        key: string;
        value: string;
      };
}

interface IProps {
  onSubmit: (values: IFormProps) => void;
  initialValues: IRegionsItemResponse;
  isLoading: boolean;
}

export const RegionForm: React.FC<IProps> = ({
  onSubmit,
  initialValues,
  isLoading,
}) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: mapValues(initialValues) as IRegionsItemResponse,
    resolver: yupResolver(validationSchema),
  });
  const handleCancel = () => {
    navigate('/mainBusiness/regions');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Card title='Общая информация' bordered={false}>
          <Row>
            <Col span={24}>
              <UploadSingleFile
                label='Загрузить иконку'
                name='icon'
                tooltip={<IconTooltip />}
                required
                accept={'svg,png'}
                validFormatsList={['image/svg+xml', 'image/png']}
                maxFileSize={1 * 1024 * 500}
                isIgnoreResolution={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Название'
                placeholder='Введите название'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RegionsEnumSelect name='enum' required />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='capital'
                label='Столица'
                placeholder='Введите название'
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='videoUrl'
                label='Ссылка на видео'
                placeholder='Ссылка на видео'
                addonBefore={<GlobalOutlined />}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='square'
                label='Площадь'
                placeholder='Площадь'
                required
                isFloat
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='population'
                label='Население'
                placeholder='Население'
                required
                isFloat
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='vrp'
                label='ВРП'
                placeholder='ВРП'
                isFloat
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='buttonLabel'
                label='Название кнопки'
                placeholder='Название кнопки'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='buttonUrl'
                label='Ссылка по кнопке'
                placeholder='Ссылка по кнопке'
                addonBefore={<GlobalOutlined />}
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Сохранить
            </Button>
          </FormActions>
        </Card>
      </form>
    </FormProvider>
  );
};
