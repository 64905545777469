import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { PageTitles } from 'constants/pageTitles';
import { loadTouristList } from 'store/slices/tourist/actions';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { list, total, limit } = useAppSelector(state => state.tourist);

  const loadTourist = () => {
    dispatch(
      loadTouristList({
        ...query,
        sort,
      })
    );
  };

  useDeepEffect(() => {
    loadTourist();
  }, [query, sort]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [query.offset]);

  const getActions = item => [
    {
      key: 'preview',
      label: <Link to={`/tourist/${item.id}`}>Превью</Link>,
    },
    {
      key: 'edit',
      label: <Link to={`/tourist/${item.id}/edit`}>Редактировать</Link>,
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.tourist} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
    </MainLayout>
  );
};
