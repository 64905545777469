export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(7)(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  const anotherMatch = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (anotherMatch) {
    return `+7 (${anotherMatch[1]}) ${anotherMatch[2]}-${anotherMatch[3]}-${anotherMatch[4]}`;
  }
  return phoneNumber;
};
