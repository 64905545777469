import React from 'react';
import classNames from 'classnames';
import { Form, FormItemProps } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { AntUpload } from '../ImageUpload';
import { DefaultTooltip } from './components/DefaultTooltip';

type IProps = Omit<FormItemProps, 'name'> & {
  name: string;
  aspectRatio?: number;
  customTooltip?: React.ReactNode;
};

export const MainImage: React.FC<IProps> = ({
  name,
  label,
  required,
  aspectRatio,
  customTooltip,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        return (
          <div
            className={classNames('main-image image-uploader__images', {
              'image-uploader__error': fieldState.error,
            })}
            ref={field.ref}
            tabIndex={0}
          >
            <div>
              <Form.Item
                label={label}
                required={required}
                tooltip={customTooltip || <DefaultTooltip />}
              >
                <AntUpload
                  showError={Boolean(fieldState.error)}
                  onChange={imageValues => {
                    field.onChange(imageValues);
                  }}
                  onDrop={file => field.onChange({ file })}
                  value={field.value}
                  aspectRatio={aspectRatio}
                />
              </Form.Item>
            </div>
          </div>
        );
      }}
      control={control}
    />
  );
};
