import React from 'react';
import { useParams } from 'react-router';
import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { loadReportCommentsList } from 'store/slices/reportComments/actions';
import { resetComments } from 'store/slices/reportComments/reportCommentsSlice';

import { FormCommentsCreateComment } from './components/FormCommentsCreateComment/FormCommentsCreateComment';
import { FormCommentsItem } from './components/FormCommentsItem/FormCommentsItem';
import styles from './Comments.module.less';

export const Comments = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);

  const { list, limit, total } = useAppSelector(state => state.reportComments);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [comments, setComments] = React.useState(list || []);

  const loadComments = () => {
    dispatch(loadReportCommentsList({ id, offset }))
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    loadComments();

    return () => {
      dispatch(resetComments());
    };
  }, [offset]);

  React.useEffect(() => {
    if (list.length) {
      if (offset === 0) {
        setComments(list);
      }
      if (offset > 0) {
        setComments(prevState => [...list, ...prevState]);
      }
    }
  }, [list]);

  const onLoadMore = () => {
    setIsLoading(true);
    setOffset(offset + limit);
  };

  const refetchComments = () => {
    if (offset === 0) {
      loadComments();
    } else {
      setOffset(0);
    }
  };

  return (
    <React.Fragment>
      {comments.length < total && (
        <div className={styles.loadMore}>
          <Button type='link' onClick={onLoadMore} loading={isLoading}>
            Ещё комментарии
          </Button>
        </div>
      )}
      {comments.length > 0 && (
        <div className={styles.list}>
          {comments.map(comment => (
            <FormCommentsItem key={comment.id} {...comment} />
          ))}
        </div>
      )}
      <FormCommentsCreateComment refetchComments={refetchComments} />
    </React.Fragment>
  );
};
