import React from 'react';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IMaterial, TMaterialDraft } from 'store/slices/materials/interfaces';
import { InputField } from 'components/form/base/InputField';
import { Status } from 'components/form/Status/Status';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { ContentField } from 'components/form/Content/ContentField';
import {
  ContentTypeEnum,
  TContentBlock,
} from 'components/form/Content/contentTypes';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty } from 'utils/helpers';
import { Statuses } from 'constants/status';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';

interface IFormValues {
  name: string;
  content: TContentBlock[];
  status: Statuses;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IMaterial) => void;
  onSaveDraft: (values: TMaterialDraft) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const MaterialForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Card title='Общая информация' bordered={false}>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Наименование материала'
                required
                placeholder='Введите название'
                maxLength={180}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  enabledTypes={[
                    ContentTypeEnum.image,
                    ContentTypeEnum.gallery,
                    ContentTypeEnum.video,
                  ]}
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status isStatusesLimited status={initialValues.status} />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
