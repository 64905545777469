import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveRentalsItem,
  saveRentalsItemAsDraft,
} from 'store/slices/rentals/actions';
import {
  IRentalsItem,
  TRentalsDraftItem,
} from 'store/slices/rentals/interfaces';

import { RentalsForm } from './components/RentalsForm/RentalsForm';
import { INITIAL_STATE } from './components/RentalsForm/constants';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IRentalsItem) => {
    setIsLoading(true);
    dispatch(saveRentalsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/rentals');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TRentalsDraftItem) => {
    setIsLoading(true);
    dispatch(saveRentalsItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/rentals');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/rentals');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <RentalsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={INITIAL_STATE}
      />
    </MainLayout>
  );
};
