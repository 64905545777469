import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import { IBusinessItemRequest, IBusinessItemResponse } from './interfaces';

type TListResponse = {
  data: IBusinessItemResponse[];
  total: number;
};

export const loadBusinessList = createAsyncThunk<
  TListResponse,
  {
    limit?: number;
    offset?: number;
    sort?: string;
    fullNameQuery?: string;
    exactFullNameQuery?: string;
    emailQuery?: string;
    phoneQuery?: string;
    innQuery?: string;
  },
  { state: RootState }
>('business/loadList', async (params, { getState }) => {
  const { limit } = getState().business;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.users.business.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadIBusinessItem = createAsyncThunk<
  IBusinessItemResponse,
  string
>('business/loadItem', async id => {
  const { data } = await apiClient.get<IBusinessItemResponse>(
    getUrlWithParams(urls.api.users.getOne.business, { id })
  );
  return data;
});

export const saveBusinessItem = createAsyncThunk<
  IBusinessItemResponse,
  IBusinessItemRequest & { id: number }
>('business/saveItem', async ({ id, ...params }) => {
  const { data } = await apiClient.patch<IBusinessItemResponse>(
    getUrlWithParams(urls.api.users.patch.business, { id }),
    {
      ...params,
    }
  );
  return data;
});
