import { applicationsTypesNameHash } from 'types/applications';
import { projectStatusNames } from 'types/projects';

export const mapValuesToForm = values => {
  return {
    ...values,
    name: values.name || 'Не указано',
    region: values.region?.name || 'Не указано',
    status: {
      value: values.status,
      label: applicationsTypesNameHash[values.status],
    },
    projectStatus: {
      value: values.projectStatus,
      label: projectStatusNames[values.projectStatus],
    },
  };
};
