import React, { useEffect } from 'react';
import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { loadIBusinessItem } from 'store/slices/business/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { isEmpty } from 'utils/helpers';

import { Contacts } from './components/Contacts/Contacts';
import { ViewContent } from './components/ViewContent/ViewContent';

export const View = () => {
  const { item } = useAppSelector(state => state.business);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (isEmpty(item) || item.id !== Number(id)) {
      dispatch(loadIBusinessItem(id));
    }
  }, []);

  return (
    <MainLayout
      pageTitle='Просмотр бизнес пользователя'
      aside={item.id && item.id === Number(id) ? <Contacts /> : null}
      extraButtonsList={[
        <Link key={1} to={`/business/${id}/edit`}>
          <Button>Редактировать</Button>
        </Link>,
      ]}
      backButtonPath='/business'
      hasBackButton
    >
      {item.id && item.id === Number(id) ? <ViewContent /> : null}
    </MainLayout>
  );
};
