import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';

import { getPriority } from '../../store/slices/blockPriority/actions';
import { useAppDispatch, useAppSelector } from '../../store';
import { PageTitles } from '../../constants/pageTitles';
import { B2BBlockPriority } from '../../constants/blockPriority';
import { mainBusinessHeaders } from '../../constants/mainBusiness';
import { MainLayout } from '../../components/Layout/MainLayout';
import { PriorityForm } from '../../components/form/PriorityForm/PriorityForm';
import { IComponent } from '../../store/slices/blockPriority/interfaces';

const componentsData = {
  [B2BBlockPriority.HeroBlock]: {
    link: 'ru/slider',
    name: 'Слайдер',
  },
  [B2BBlockPriority.WhyBlock]: {
    name: 'Почему кавказ.рф/Карта',
    children: [
      { name: 'Почему кавказ.рф', link: 'about' },
      { name: 'Карта регионов', link: 'regions' },
    ],
  },
  [B2BBlockPriority.SuccessStoriesBlock]: {
    link: 'history',
    name: 'Истории успеха',
  },
  [B2BBlockPriority.HelpBlock]: {
    link: 'help',
    name: mainBusinessHeaders.help,
  },
  [B2BBlockPriority.SupportMeasuresBlock]: {
    link: 'supportMeasures',
    name: 'Меры поддержки',
  },
  [B2BBlockPriority.NewsBlock]: {
    name: 'Новости',
  },
  [B2BBlockPriority.InfoBlock]: {
    name: 'Партнеры',
  },
};

const serializeComponents = (data: IComponent[]) => {
  return data.filter(value => {
    return (
      value.name === B2BBlockPriority.HelpBlock ||
      value.name === B2BBlockPriority.InfoBlock ||
      value.name === B2BBlockPriority.NewsBlock ||
      value.name === B2BBlockPriority.SuccessStoriesBlock ||
      value.name === B2BBlockPriority.WhyBlock ||
      value.name === B2BBlockPriority.SupportMeasuresBlock ||
      value.name === B2BBlockPriority.HeroBlock
    );
  });
};
export const MainBusiness = () => {
  const [isLoading, setIsLoading] = useState(true);
  const state = useAppSelector(state => state.blockPriority);
  const dispatch = useAppDispatch();

  const loadData = () => {
    dispatch(getPriority('b2b'))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.mainBusiness}>
      {isLoading ? (
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        <PriorityForm
          initialValues={serializeComponents(state.components)}
          data={componentsData}
          title='Компоненты B2B'
          type='b2b'
        />
      )}
    </MainLayout>
  );
};
