import React from 'react';
import { Button, Col, Collapse, Descriptions, Row, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';
import { IProject } from 'store/slices/organizations/interfaces';
import { getName } from 'pages/Organizations/utils';
import { applicationsStatusesNamesHash } from 'constants/applicationsStatus';
import { applicationsTypesNameHash } from 'types/applications';
import { projectStatusNames } from 'types/projects';

import styles from './Project.module.less';

interface IProps {
  project: IProject;
}

export const Project: React.FC<IProps> = ({ project }) => {
  const { id, createdAt, creatorData, status, type, projectStatus } = project;
  const { firstName, lastName, secondName } = creatorData;

  const navigate = useNavigate();

  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;

  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([]);

  const genExtra = (key: number) => (
    <Row gutter={isMobile ? [0, 16] : 16} justify={isMobile ? 'end' : 'center'}>
      <Col>
        <Button
          size='small'
          onClick={event => {
            event.stopPropagation();
            navigate(`/projects/${id}/view`);
          }}
        >
          Перейти к проекту
        </Button>
      </Col>
      <Col>
        <Button size='small'>
          {activeCollapseTabs.includes(String(key)) ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )}
        </Button>
      </Col>
    </Row>
  );

  const genHeader = () => (
    <Descriptions
      labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
      contentStyle={{ fontWeight: 400 }}
      title=''
      size='small'
      column={1}
      layout='vertical'
    >
      <Descriptions.Item
        className={classNames(styles.row, styles.rowLast)}
        label='Название заявки'
      >
        {applicationsTypesNameHash[type] || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <Collapse
      bordered={false}
      expandIconPosition='end'
      onChange={(value: string[]) => setActiveCollapseTabs(value)}
      activeKey={activeCollapseTabs}
      ghost
    >
      <Collapse.Panel
        showArrow={false}
        header={genHeader()}
        key={id}
        extra={genExtra(id)}
        className={classNames(styles.panel, {
          [styles.panelActive]: activeCollapseTabs.includes(String(id)),
        })}
      >
        <Descriptions
          labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
          title=''
          size='small'
          column={1}
          layout='vertical'
        >
          <Descriptions.Item className={styles.row} label='Дата и время заявки'>
            {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
          </Descriptions.Item>
          <Descriptions.Item className={styles.row} label='ФИО пользователя'>
            {getName({ firstName, lastName, secondName })}
          </Descriptions.Item>
          <Descriptions.Item className={styles.row} label='Статус заявки'>
            {applicationsStatusesNamesHash[status]}
          </Descriptions.Item>
          <Descriptions.Item
            className={classNames(styles.row, styles.rowLast)}
            label='Статус проекта'
          >
            {projectStatusNames[projectStatus]}
          </Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  );
};
