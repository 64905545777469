import React from 'react';

import { PublishedEntityAsyncSelect } from 'components/form/selects/PublishedEntityAsyncSelect';

type TProps = {
  widget: any;
  withWidgetsDescription: any;
  name: string;
  errors: any;
};

export class Entity extends React.Component<TProps> {
  getWidgetParamsHash(type) {
    const hash = {
      attractions: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите достопримечательность',
        type: 'attractions',
      },
      resorts: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите курорт',
        type: 'resorts',
      },
      articles: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите статью',
        type: 'articles',
        nameQueryField: 'nameQuery',
      },
      news: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите новость',
        type: 'news',
      },
      events: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите событие',
        type: 'events',
        nameQueryField: 'nameQuery',
      },
      hotels: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите отель',
        type: 'hotels',
      },
      restaurants: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите кафе или ресторан',
        type: 'restaurants',
      },
      routes: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите маршрут',
        type: 'routes',
      },
    };

    return hash[type];
  }

  render() {
    const { widget } = this.props;
    const hash = this.getWidgetParamsHash(widget.type);

    const { Component } = hash;

    return (
      <React.Fragment>
        <Component
          name={`${this.props.name}.${widget.type}`}
          errors={this.props.errors}
          {...hash}
        />
      </React.Fragment>
    );
  }
}
