import { GlobalOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';

import { InputField } from 'components/form/base/InputField';

interface MaterialLinkProps {
  name: string;
}

export const MaterialLink = ({ name }: MaterialLinkProps) => (
  <Col>
    <InputField
      name={`${name}.url.url`}
      placeholder='Укажите ссылку'
      addonBefore={<GlobalOutlined />}
    />
    <InputField
      name={`${name}.url.displayName`}
      placeholder='Название'
      formItemStyle={{ marginBottom: 0 }}
    />
  </Col>
);
