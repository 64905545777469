// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PriceRange-module__freePriceContainer--naBvN {\n  height: 62px;\n}\n@media (max-width: 576px) {\n  .PriceRange-module__freePriceContainer--naBvN {\n    height: auto;\n    margin-left: 4px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/PriceRange/PriceRange.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAIA;EACE;IACE,YAAA;IACA,gBAAA;EAFF;AACF","sourcesContent":["@sm: 576px;\n\n.freePriceContainer {\n  height: 62px;\n}\n\n@media (max-width: @sm) {\n  .freePriceContainer {\n    height: auto;\n    margin-left: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"freePriceContainer": "PriceRange-module__freePriceContainer--naBvN"
};
export default ___CSS_LOADER_EXPORT___;
