import { createSlice } from '@reduxjs/toolkit';

import { IConfigState } from './interfaces';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    b2cUrl: '',
    b2bUrl: '',
    backendUrl: '',
  } as IConfigState,
  reducers: {
    setConfig: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setConfig } = configSlice.actions;
