import React from 'react';
import { Button, Dropdown, Image, Menu, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IInfrastructuresItemResponse } from 'store/slices/infrastructures/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { makeAddressString } from 'utils/entities';
import { CustomIcon } from 'components/icons/icons';
import { InfrastructureCategories } from 'constants/infrastructuresCategories';

interface IProps {
  data: IInfrastructuresItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IInfrastructuresItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (text, item) => (
        <Typography>
          <Link
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
            }}
            to={`/infrastructures/${item.id}/edit`}
          >
            <div style={{ flexShrink: 0 }}>
              {item.category ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div>
                    <CustomIcon name={item.category} />
                  </div>
                </div>
              ) : (
                <Image
                  width={50}
                  height={50}
                  preview={false}
                  src={'/public/images/no-image.svg'}
                  fallback='/public/images/no-image.svg'
                  style={{ flexShrink: 0 }}
                />
              )}
            </div>
            <div style={{ marginLeft: '16px' }}>
              <div>{(text && text.trim()) || 'Без названия'}</div>
              {item.address && (
                <div
                  style={{
                    marginTop: '4px',
                    fontSize: '12px',
                    color: '#8C8C8C',
                  }}
                >
                  {item.address?.region?.name} -{' '}
                  {makeAddressString({ city: item.address?.city }) ||
                    makeAddressString({
                      settlement: item.address?.settlement,
                    })}
                </div>
              )}
            </div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '20%',
      title: 'Категория',
      dataIndex: 'category',
      render: category => (
        <Typography>{InfrastructureCategories[category]}</Typography>
      ),
    },
    {
      key: 4,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IInfrastructuresItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
