import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import {
  IRestaurantItemResponse,
  IRestaurantsDraftItem,
  IRestaurantsItem,
} from './interfaces';

type TListResponse = { data: IRestaurantItemResponse[]; total: number };

export const loadRestaurantsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
    isSortPriority?: boolean;
  },
  { state: RootState }
>('restaurants/loadList', async (params, { getState }) => {
  const { limit } = getState().restaurants;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.restaurants.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadRestaurantsItem = createAsyncThunk<
  IRestaurantItemResponse,
  string
>('restaurants/loadItem', async id => {
  const { data } = await apiClient.get<IRestaurantItemResponse>(
    getUrlWithParams(urls.api.restaurants.getOne, {
      id,
    })
  );
  return data;
});

export const bulkUpdateRestaurantsItem = createAsyncThunk<
  IRestaurantsItem[],
  Partial<IRestaurantsItem>[]
>('restaurants/bulkUpdateItems', async params => {
  const { data } = await apiClient.patch<IRestaurantsItem[]>(
    urls.api.restaurants.bulkPatch,
    params
  );
  return data;
});

export const saveRestaurantsItem = createAsyncThunk<
  IRestaurantsItem,
  Partial<IRestaurantsItem>
>('restaurants/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = params.gallery && (await uploadImageArray(params.gallery));
  const content = params.content && (await uploadContent(params.content));

  if (id) {
    const { data } = await apiClient.patch<IRestaurantsItem>(
      getUrlWithParams(urls.api.restaurants.patch, { id }),
      {
        ...params,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IRestaurantsItem>(
      urls.api.restaurants.post,
      {
        ...params,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  }
});

export const saveRestaurantsItemAsDraft = createAsyncThunk<
  IRestaurantsDraftItem,
  IRestaurantsDraftItem
>('restaurants/saveItem', async params => {
  const { id, ...requestParams } = params;
  const image = await uploadImage(requestParams.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = await uploadImageArray(requestParams.gallery);
  const content = await uploadContent(requestParams.content);

  if (id) {
    const { data } = await apiClient.patch<IRestaurantsDraftItem>(
      getUrlWithParams(urls.api.restaurants.draft.patch, { id }),
      {
        ...requestParams,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IRestaurantsDraftItem>(
      urls.api.restaurants.draft.post,
      {
        ...requestParams,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  }
});

export const setPublishRestaurant = createAsyncThunk<void, string>(
  'restaurants/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.restaurants.publish.patch, { id })
    );
  }
);

export const setUnPublishRestaurant = createAsyncThunk<void, string>(
  'restaurants/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.restaurants.unPublish.patch, { id })
    );
  }
);

export const removeRestaurantItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: number;
  }
>('restaurants/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.restaurants.delete, { id: params.id }));
  return data;
});
