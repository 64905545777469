import * as Yup from 'yup';
import { FieldValues } from 'react-hook-form';

import { omit, pick } from 'utils/helpers';
import { mobilePhoneSchema } from 'utils/yup';
import { inputUnmaskNumber } from 'constants/masks';

export const mapValuesToForm = values => ({
  ...pick(values, 'firstName', 'lastName', 'secondName', 'email'),
  phone: values.phone || '',
});

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required()
    .min(2, 'Минимум 2 символа')
    .max(75, 'Максимум 75 символов')
    .matches(
      /^([А-ЯЁа-яё\- '])+$/,
      `Допустимы для ввода только буквы русского алфавита и специальные символы «-» (дефис), «'» (апостроф) и пробел`
    )
    .test(
      'isMatch',
      `Запрещается использование 2 одинаковых спецсимволов подряд`,
      function (value) {
        return !(
          value.includes(`''`) ||
          value.includes(`  `) ||
          value.includes(`--`)
        );
      }
    ),
  secondName: Yup.string()
    .required()
    .min(2, 'Минимум 2 символа')
    .max(75, 'Максимум 75 символов')
    .matches(
      /^([А-ЯЁа-яё\- '])+$/,
      `Допустимы для ввода только буквы русского алфавита и специальные символы «-» (дефис), «'» (апостроф) и пробел`
    )
    .test(
      'isMatch',
      `Запрещается использование 2 одинаковых спецсимволов подряд`,
      function (value) {
        return !(
          value.includes(`''`) ||
          value.includes(`  `) ||
          value.includes(`--`)
        );
      }
    ),
  lastName: Yup.string()
    .required()
    .min(2, 'Минимум 2 символа')
    .max(75, 'Максимум 75 символов')
    .matches(
      /^([А-ЯЁа-яё\- '])+$/,
      `Допустимы для ввода только буквы русского алфавита и специальные символы «-» (дефис), «'» (апостроф) и пробел`
    )
    .test(
      'isMatch',
      `Запрещается использование 2 одинаковых спецсимволов подряд`,
      function (value) {
        return !(
          value.includes(`''`) ||
          value.includes(`  `) ||
          value.includes(`--`)
        );
      }
    ),
  email: Yup.mixed(),
  phone: mobilePhoneSchema.required(),
});

export const mapValues = (values: FieldValues) => ({
  ...omit(values, ['email']),
  phone: (values.phone && inputUnmaskNumber(values.phone)) || null,
});
