import { createAsyncThunk } from '@reduxjs/toolkit';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadImage } from 'utils/upload';

import { IPreviewDataParams, IPreviewDataResponse } from './interfaces';

export const savePreviewData = createAsyncThunk<
  IPreviewDataResponse,
  IPreviewDataParams
>('preview/savePreviewData', async ({ ...params }) => {
  const image = await uploadImage(params.data?.image);
  const heroImage = await uploadImage(params.data?.heroImage);

  params.data.image = image;
  params.data.heroImage = heroImage;

  const { data } = await apiClient.post<IPreviewDataResponse>(
    urls.api.preview.post,
    {
      ...params,
    }
  );

  return data;
});
