import React from 'react';
import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';

import { InputField } from 'components/form/base/InputField';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { ContentField } from 'components/form/Content/ContentField';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { IFormValues } from 'pages/PriorityIndustry/components/types';
import { formatterFieldCount } from 'utils/formatterFieldCount';

import styles from './InfoForm.module.less';

export const InfoForm = () => {
  const {
    formState: { errors },
  } = useFormContext<IFormValues>();

  return (
    <Card className={styles.info} title='Общая информация' bordered={false}>
      <Row>
        <Col span={24}>
          <InputField
            name='hero'
            label='Заголовок hero-блока'
            placeholder='Введите название'
            minLength={1}
            maxLength={80}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextAreaField
            name='shortDesc'
            label='Краткое описание'
            placeholder='Введите описание'
            minLength={1}
            maxLength={255}
            rows={2}
            showCount={{
              formatter: formatterFieldCount,
            }}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ContentField
            name='description'
            label='Описание'
            placeholder='Введите описание'
            enabledTypes={[]}
            customErrorText={
              getValueByStringKeyWithArr(errors, 'description')?.[0]?.text
                ?.message
            }
          />
        </Col>
      </Row>
    </Card>
  );
};
