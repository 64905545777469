// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RentalsForm-module__materialSection--BCFN7 {\n  margin-top: 25px;\n}\n.RentalsForm-module__materialItem--Sqzzk {\n  margin-top: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Rentals/components/RentalsForm/RentalsForm.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".materialSection {\n  margin-top: 25px;\n}\n\n.materialItem {\n  margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"materialSection": "RentalsForm-module__materialSection--BCFN7",
	"materialItem": "RentalsForm-module__materialItem--Sqzzk"
};
export default ___CSS_LOADER_EXPORT___;
