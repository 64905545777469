import { Statuses, StatusesEnum, statusesNamesHash } from 'constants/status';
import { pick } from 'utils/helpers';

export const FILTER_STATUS_OPTIONS = [
  { value: '', label: 'Все' },
  ...Object.keys(
    pick(
      statusesNamesHash,
      StatusesEnum.PUBLISHED,
      StatusesEnum.DRAFT,
      StatusesEnum.NOT_PUBLISHED
    )
  ).map(item => ({
    value: item,
    label: statusesNamesHash[item as Statuses],
  })),
];
