import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Card } from 'antd';

import { WidgetPreview } from './WidgetPreview';
import { Entity } from './Entity';
import { widgetTypeNamesHash } from '../contentTypes';
import styles from './Widget.module.less';

type TProps = {
  widget: any;
  withWidgetsDescription: any;
  name: string;
  errors: any;
  errorRef: any;
};

export class WidgetContent extends React.Component<TProps> {
  render() {
    const { widget } = this.props;
    const entity = widget[widget.type];
    return (
      <Content
        className={styles.content}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div ref={this.props.errorRef} tabIndex={0}>
          <Card title={widgetTypeNamesHash[widget.type]} type='inner'>
            <Entity {...this.props} />
            {entity && (
              <div className='widget-form_preview widget-form_item'>
                <WidgetPreview widget={widget} />
              </div>
            )}
          </Card>
        </div>
      </Content>
    );
  }
}
