import React from 'react';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GlobalOutlined } from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { Status } from 'components/form/Status/Status';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty, isString } from 'utils/helpers';
import {
  ISupportMeasuresItem,
  TSupportMeasuresItemDraft,
} from 'store/slices/supportMeasures/interfaces';
// import { SEO } from 'components/form/SEO/SEO';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { ICatalogData } from 'types/catalogData';
import {
  CatalogRegionSelect,
  TRegionType,
} from 'components/form/selects/CatalogRegionSelect';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { LangEnumKeys } from 'constants/lang';
import {
  ContentTypeEnum,
  TContentBlock,
} from 'components/form/Content/contentTypes';
import { Statuses } from 'constants/status';
import { getValueByStringKeyWithArr } from 'utils/objects';

import {
  checkSKFOSubjectVisibility,
  mapValues,
  mapValuesToDraft,
  validationSchema,
} from './formUtils';

interface IFormValues {
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  supportLevel: number | { value: number; label: string };
  supportPeriod: number | { value: number; label: string };
  region?: TRegionType | null;
  supportFormat: number | { value: number; label: string };
  supportType: number | { value: number; label: string };
  initiator: number;
  industry: number[] | { value: number; label: string }[];
  supportProblems: number[];
  content: TContentBlock[];
  declarerRequirements: TContentBlock[];
  projectRequirements: TContentBlock[];
  documents: TContentBlock[];
  npa: TContentBlock[];
  externalUrl: string;
  contacts: string;
  status: Statuses;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: ISupportMeasuresItem) => void;
  onSaveDraft: (values: TSupportMeasuresItemDraft) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const SupportMeasuresForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'contacts',
    'externalUrl',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const supportLevel = useWatch({
    name: 'supportLevel',
    control: methods.control,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({
        ...methods.getValues(),
        initiator: null,
        supportLevel: null,
        supportPeriod: null,
        industry: [],
        supportFormat: null,
        supportType: null,
        supportProblems: null,
      });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Наименование меры поддержки'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
              <CatalogAsyncSelect
                name='initiator'
                label='Инициатор'
                placeholder='Выберите инициатора'
                required
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'initiators',
                  lang: langValue,
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
              <CatalogAsyncSelect
                name='supportLevel'
                label='Территориальный уровень'
                placeholder='Выберите уровень'
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'supportLevels',
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
              <CatalogAsyncSelect
                name='supportPeriod'
                label='Регулярность оказания'
                placeholder='Выберите регулярность'
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'supportPeriods',
                }}
              />
            </Col>
          </Row>
          {checkSKFOSubjectVisibility(
            supportLevel as unknown as ICatalogData | null
          ) && (
            <Row>
              <Col span={24}>
                <CatalogRegionSelect
                  name='region'
                  label='Субъект СКФО'
                  placeholder='Выберите субъект'
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <CatalogAsyncSelect
                name='industry'
                label='Отрасли'
                placeholder='Выберите отрасли'
                required
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'industries',
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='supportFormat'
                label='Формат'
                placeholder='Выберите формат'
                required
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'supportFormats',
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              <CatalogAsyncSelect
                name='supportType'
                label='Тип поддержки'
                placeholder='Выберите тип поддержки'
                required
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'supportTypes',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CatalogAsyncSelect
                name='supportProblems'
                label='Типы проблем'
                placeholder='Выберите типы проблем'
                requestParams={{
                  entityType: 'supportMeasures',
                  fieldType: 'supportProblems',
                }}
                mode='multiple'
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание меры поддержки' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                  enabledTypes={[
                    ContentTypeEnum.image,
                    ContentTypeEnum.gallery,
                    ContentTypeEnum.video,
                  ]}
                />
              </Col>
              <Col span={24}>
                <ContentField
                  name='declarerRequirements'
                  label='Требование к заявителю'
                  placeholder='Начните печатать текст'
                  withPreparingForModeration
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(
                      methods.formState.errors,
                      'declarerRequirements'
                    )?.[0]?.text?.message
                  }
                />
              </Col>
              <Col span={24}>
                <ContentField
                  name='projectRequirements'
                  label='Требования к проекту'
                  placeholder='Начните печатать текст'
                  withPreparingForModeration
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(
                      methods.formState.errors,
                      'projectRequirements'
                    )?.[0]?.text?.message
                  }
                />
              </Col>
              <Col span={24}>
                <ContentField
                  name='documents'
                  label='Необходимые документы'
                  placeholder='Начните печатать текст'
                  withPreparingForModeration
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(
                      methods.formState.errors,
                      'documents'
                    )?.[0]?.text?.message
                  }
                />
              </Col>
              <Col span={24}>
                <ContentField
                  name='npa'
                  label='НПА'
                  placeholder='Начните печатать текст'
                  withPreparingForModeration
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(
                      methods.formState.errors,
                      'npa'
                    )?.[0]?.text?.message
                  }
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <TextAreaField
              name='contacts'
              label='Контакты'
              placeholder='Начните печатать текст'
              showCount
              maxLength={1000}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header='Описание меры поддержки на внешнем сайте'
            key='externalUrl'
          >
            <InputField
              name='externalUrl'
              label='Ссылка на описание на внешнем сайте'
              placeholder='Ссылка на описание на внешнем сайте'
              addonBefore={<GlobalOutlined />}
              formItemStyle={{ marginBottom: 0 }}
            />
          </Collapse.Panel>
          {/* Todo: uncomment when SEO will be available */}
          {/*<Collapse.Panel header='SEO' key='seo'>*/}
          {/*  <SEO />*/}
          {/*</Collapse.Panel>*/}
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
