import React from 'react';

import { MaskedInput } from 'components/form/base/MaskedInput';
import { inputMaskHash } from 'constants/masks';
import { phonePlaceHoldersHash } from 'constants/phonePlaceholder';

type TProps = {
  name: string;
  value: string;
  mask?: string;
  addonBefore?: string;
  placeholder?: string;
  error: any;
  disabled?: boolean;
  onChange: (value: string) => void;
  ref?: any;
};

export const PhoneInput: React.FC<TProps> = React.forwardRef(
  (
    {
      placeholder = phonePlaceHoldersHash.phone,
      mask = inputMaskHash.phoneWithBracket,
      addonBefore = '+7',
      onChange,
      value,
      error,
      disabled,
      ...props
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <MaskedInput
        {...props}
        addonBefore={addonBefore}
        onChange={onChange}
        value={value}
        mask={mask}
        placeholder={placeholder}
        ref={ref}
        error={error}
        disabled={disabled}
      />
    );
  }
);

PhoneInput.displayName = 'PhoneInput';
