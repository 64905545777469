import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useFormContext } from 'react-hook-form';
import { MailOutlined, WarningOutlined } from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { useBreakpoints } from 'hooks/useBreakpoints';

import styles from './ExtensionContactBlock.module.less';
import { PhoneNumberField } from '../base/PhoneNumberField';

const BREAKPOINT = 499;

export const ExtensionContactBlock: React.FC<{ name: string }> = ({ name }) => {
  const { width } = useBreakpoints();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <InputField
            name={`${name}.position`}
            label='Должность'
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <InputField
            name={`${name}.name`}
            label='Контактное лицо'
            maxLength={60}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 10 }} className={styles.phoneInput}>
          <PhoneNumberField name={`${name}.phone`} label='Телефон' />
        </Col>
        <Col className={styles.additionalPhoneInput}>
          <InputField
            key={'inputKEY'}
            name={`${name}.additional`}
            type='number'
            label='Добавочный'
            maxLength={4}
            withErrorMessage={width <= BREAKPOINT}
            suffix={
              width >= BREAKPOINT ? (
                getValueByStringKeyWithArr(errors, `${name}.additional`)
                  ?.message ? (
                  <Tooltip
                    placement='top'
                    title={
                      getValueByStringKeyWithArr(errors, `${name}.additional`)
                        ?.message
                    }
                  >
                    <WarningOutlined style={{ color: '#ff4d4f' }} />
                  </Tooltip>
                ) : (
                  <span />
                )
              ) : (
                <span />
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <InputField
            name={`${name}.email`}
            label='Email'
            placeholder='Введите еmail'
            prefix={<MailOutlined />}
          />
        </Col>
      </Row>
    </>
  );
};
