import React, { useCallback, useRef, useState } from 'react';
import { Col, notification, Pagination, PaginationProps, Row } from 'antd';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteCondition,
  loadAllConditions,
  loadConditions,
  saveCondition,
  updateCondition,
} from 'store/slices/openingInfo/actions';
import { ICondition } from 'store/slices/openingInfo/interfaces';
import { useDeepEffect } from 'utils/useDeepEffect';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { FormWithDate } from '../EntityForms/FormWithDate';
import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import styles from './Conditions.module.less';

const PAGE_SIZE = 3;

export const Conditions = () => {
  const [offset, setOffset] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);
  const swapElRef = useRef<{ condition: ICondition; step: number }>(null);

  const {
    conditions: { list, total },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const loadListConditions = async (offset: number) =>
    await dispatch(
      loadConditions({
        limit: PAGE_SIZE,
        offset: (offset - 1) * PAGE_SIZE,
      })
    ).unwrap();

  const updateConditionOnAnotherPage = async (list: Array<ICondition>) => {
    const { condition, step } = swapElRef.current;
    const swapCondition = list[step === 1 ? 0 : list.length - 1];

    await dispatch(
      updateCondition({
        id: swapCondition.id,
        file: swapCondition.file,
        title: swapCondition.title,
        eventDate: swapCondition.eventDate,
        sortIndex: condition.sortIndex,
      })
    );

    await dispatch(
      updateCondition({
        id: condition.id,
        file: condition.file,
        title: condition.title,
        eventDate: condition.eventDate,
        sortIndex: swapCondition.sortIndex,
      })
    );

    await loadListConditions(offset);
    swapElRef.current = null;
  };

  useDeepEffect(() => {
    loadListConditions(offset).then(({ data }) => {
      if (swapElRef.current) {
        updateConditionOnAnotherPage(data);
      }
    });
  }, [offset]);

  const handleChangePage: PaginationProps['onChange'] = page => setOffset(page);

  const handleAdd = useCallback(
    async (file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      const currentDate = dayjs().toDate();
      await dispatch(
        saveCondition({
          file,
          title,
          eventDate: currentDate,
          sortIndex: total + 1,
        })
      );

      const lastPage = Math.ceil((total + 1) / PAGE_SIZE);
      if (offset === lastPage) {
        await loadListConditions(lastPage);
      } else {
        setOffset(lastPage);
      }
    },
    [total]
  );

  const handleUpdate = useCallback(
    async (
      file: IDocument,
      title: string,
      eventDate: Date,
      { id, sortIndex }: ICondition
    ) =>
      await dispatch(
        updateCondition({ file, title, id, eventDate, sortIndex })
      ),
    []
  );

  const handleMove = useCallback(
    async (condition: ICondition, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === condition.id);
      const swapEl = list[currentIndex + step];

      if (swapEl) {
        await dispatch(
          updateCondition({
            id: swapEl.id,
            file: swapEl.file,
            title: swapEl.title,
            eventDate: swapEl.eventDate,
            sortIndex: condition.sortIndex,
          })
        );

        await dispatch(
          updateCondition({
            id: condition.id,
            file: condition.file,
            title: condition.title,
            eventDate: condition.eventDate,
            sortIndex: swapEl.sortIndex,
          })
        );

        await loadListConditions(offset);
      } else {
        swapElRef.current = { condition, step };
        setOffset(prevState => prevState + step);
      }
    },
    [offset, list]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteCondition(id));
      handleCloseModal();
      notification.success({
        message: 'Документ удалён',
      });

      const list = await dispatch(loadAllConditions()).unwrap();

      await Promise.all(
        list.data.map(({ id, file, title, eventDate }, index) =>
          dispatch(
            updateCondition({
              id,
              file,
              title,
              eventDate,
              sortIndex: index + 1,
            })
          )
        )
      );

      const currentlastPage = Math.ceil(total / PAGE_SIZE);
      const updatelastPage = Math.ceil((total - 1) / PAGE_SIZE);

      if (currentlastPage !== updatelastPage && offset !== 1) {
        setOffset(prevState => prevState - 1);
      } else {
        await loadListConditions(offset);
      }
    },
    [offset, total]
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <CustomUpload onAdd={handleAdd} disabled={loading} />
        </Col>
      </Row>
      {total > 0 && (
        <Row className={styles.list}>
          <Col span={24}>
            {list.map(item => (
              <FormWithDate
                key={item.id}
                onMove={handleMove}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                entity={item}
                total={total}
              />
            ))}
          </Col>
        </Row>
      )}
      <Row justify='end'>
        <Col>
          <Pagination
            size='small'
            current={offset}
            onChange={handleChangePage}
            total={total}
            pageSize={PAGE_SIZE}
            hideOnSinglePage
          />
        </Col>
      </Row>
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          text='Вы уверены, что хотите удалить документ?'
        />
      )}
    </div>
  );
};
