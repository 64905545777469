import { SettingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, notification } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { loadFaqList, removeFaqItem } from 'store/slices/faq/actions';
import { useAppDispatch } from 'store';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { useDeepEffect } from 'utils/useDeepEffect';
import { useQuery } from 'hooks/useQuery';

interface SettingsDropdownProps {
  id: number;
}

export const SettingsDropdown = ({ id }: SettingsDropdownProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const [currentItem, setCurrentItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const loadFaq = () => {
    dispatch(
      loadFaqList({
        ...query,
      })
    );
  };

  const onEditClick = () => navigate(`${id}/edit`);

  const onDropdownRemoveClick = () => {
    setModalIsOpen(true);
    setCurrentItem(id);
  };

  const onModalClose = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const menuItems = [
    {
      label: 'Редактировать',
      onClick: onEditClick,
      key: 'edit',
    },
    {
      label: 'Удалить',
      danger: true,
      onClick: onDropdownRemoveClick,
      key: 'delete',
    },
  ];

  const onRemoveClick = async () => {
    await dispatch(removeFaqItem(currentItem))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        loadFaq();
        onModalClose();
      });
  };

  return (
    <>
      <Dropdown
        placement='bottomRight'
        trigger={['click']}
        arrow
        overlay={<Menu items={menuItems}></Menu>}
      >
        <Button
          type='text'
          icon={<SettingOutlined />}
          style={{ height: 'auto' }}
        />
      </Dropdown>
      {modalIsOpen && (
        <StickyConfirmModal
          isOpen={modalIsOpen}
          onRequestClose={onModalClose}
          title='Удаление сущности'
          okText='Удалить'
          text='Вы действительно хотите удалить эту cущность?'
          onSubmit={onRemoveClick}
          okButtonProps={{ danger: true }}
        />
      )}
    </>
  );
};

export default SettingsDropdown;
