// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormCommentsItem-module__container--JFAxe {\n  display: flex;\n  flex-wrap: nowrap;\n}\n.FormCommentsItem-module__avatar--Pmquw {\n  margin-right: 12px;\n}\n.FormCommentsItem-module__messageContainer--lHFFs {\n  width: calc(100% - 32px);\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n.FormCommentsItem-module__author--_nvXX {\n  color: #8C8C8C;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}\n.FormCommentsItem-module__message--sBqBf {\n  width: 100%;\n  color: #262626;\n  word-break: break-word;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n}\n.FormCommentsItem-module__date--oeeTV {\n  color: #8C8C8C;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormComments/components/FormCommentsItem/FormCommentsItem.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AAEA;EACE,kBAAA;AAAF;AAGA;EACE,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AADF;AAIA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAKA;EACE,WAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAHF;AAMA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAJF","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.avatar {\n  margin-right: 12px;\n}\n\n.messageContainer {\n  width: calc(100% - 32px);\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.author {\n  color: #8C8C8C;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}\n\n.message {\n  width: 100%;\n  color: #262626;\n  word-break: break-word;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n}\n\n.date {\n  color: #8C8C8C;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FormCommentsItem-module__container--JFAxe",
	"avatar": "FormCommentsItem-module__avatar--Pmquw",
	"messageContainer": "FormCommentsItem-module__messageContainer--lHFFs",
	"author": "FormCommentsItem-module__author--_nvXX",
	"message": "FormCommentsItem-module__message--sBqBf",
	"date": "FormCommentsItem-module__date--oeeTV"
};
export default ___CSS_LOADER_EXPORT___;
