import { createSlice } from '@reduxjs/toolkit';

import { IContractExchange, IContractExchangeState } from './interfaces';
import { loadContractExchangeItem, loadContractExchangeList } from './actions';

export const contractExchangeSlice = createSlice({
  name: 'contractExchange',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
  } as IContractExchangeState,
  reducers: {
    clearContractExchangeItemData: state => {
      state.item = {} as IContractExchange;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadContractExchangeList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
    builder.addCase(
      loadContractExchangeItem.fulfilled,
      (state, { payload }) => {
        state.item = payload;
      }
    );
  },
});

export const { clearContractExchangeItemData } = contractExchangeSlice.actions;
