import {
  ISliderItem,
  TSliderItemRequest,
} from '../../store/slices/slider/interfaces';
import { mapImage, mapSelect } from '../../utils/mappings';
import { applicationsStatusesFormNamesHash } from '../../constants/applicationsFormRespondsStatus';

export const mapSliderItemValues = (
  values: ISliderItem
): TSliderItemRequest => {
  return {
    id: values.id,
    title: values.title || '',
    link: values.link || '',
    subtitle: values.subtitle || '',
    sortPriority: values.sortPriority,
    buttonLabel: values.buttonLabel || '',
    image: values.image ? mapImage(values.image) : null,
    sliderId: values.sliderId,
    formType: mapSelect(values.formType) as string,
  };
};

export const mapToForm = (values: ISliderItem) => ({
  ...values,
  formType: values.formType
    ? ({
        value: values.formType,
        label: applicationsStatusesFormNamesHash[values.formType],
      } as any)
    : null,
});
