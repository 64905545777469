import { combineReducers } from 'redux';

import { configSlice } from './slices/config/configSlice';
import { accountSlice } from './slices/account/accountSlice';
import { catalogSlice } from './slices/catalog/catalogSlice';
import { catalogEntityTypesSlice } from './slices/catalogEntityTypes/catalogEntityTypesSlice';
import { attractionsSlice } from './slices/attractions/attractionsSlice';
import { newsSlice } from './slices/news/newsSlice';
import { articlesSlice } from './slices/articles/articlesSlice';
import { faqSlice } from './slices/faq/faqSlice';
import { touristRoutesSlice } from './slices/touristRoutes/touristRoutesSlice';
import { resortsSlice } from './slices/resorts/resortsSlice';
import { investmentProjectsSlice } from './slices/investmentProjects/investmentProjectsSlice';
import { eventsSlice } from './slices/events/eventsSlice';
import { purchasePlansSlice } from './slices/purchasePlans/purchasePlansSlice';
import { infrastructuresSlice } from './slices/infrastructures/infrastructuresSlice';
import { signedAgreementsSlice } from './slices/signedAgreements/signedAgreementsSlice';
import { hotelsSlice } from './slices/hotels/hotelsSlice';
import { restaurantsSlice } from './slices/restaurants/restaurantsSlice';
import { regulationsSlice } from './slices/regulations/regulationsSlice';
import { regionsSlice } from './slices/regions/regionsSlice';
import { adminsSlice } from './slices/admin/adminsSlice';
import { supportMeasuresSlice } from './slices/supportMeasures/supportMeasuresSlice';
import { purchasesSlice } from './slices/purchases/purchasesSlice';
import { applicationsSlice } from './slices/applications/applicationsSlice';
import { formDocumentsSlice } from './slices/formDocuments/formDocumentsSlice';
import { formCommentsSlice } from './slices/formComments/formCommentsSlice';
import { salesSlice } from './slices/sales/salesSlice';
import { rentalsSlice } from './slices/rentals/rentalsSlice';
import { projectsSlice } from './slices/projects/projectsSlice';
import { investmentPassportsSlice } from './slices/investmentPassports/investmentPassportsSlice';
import { investInfrastructuresSlice } from './slices/investInfrastructures/investInfrastructuresSlice';
import { respondsSlice } from './slices/responds/respondsSlice';
import { materialsSlice } from './slices/materials/materialsSlice';
import { organizationsSlice } from './slices/organizations/organizationsSlice';
import { vacanciesSlice } from './slices/vacancies/vacanciesSlice';
import { vacanciesRespondsSlice } from './slices/vacanciesResponds/vacanciesRespondsSlice';
import { businessSlice } from './slices/business/businessSlice';
import { menuSlice } from './slices/menu/menuSlice';
import { priorityIndustrySlice } from './slices/priorityIndustry/priorityIndustrySlice';
import { openingInfoSlice } from './slices/openingInfo/openingInfoSlice';
import { reviewsSlice } from './slices/reviews/reviewsSlice';
import { touristSlice } from './slices/tourist/touristSlice';
import { reportsSlice } from './slices/reports/reportsSlice';
import { reportCommentsSlice } from './slices/reportComments/reportCommentsSlice';
import { reportDocumentsSlice } from './slices/reportDocuments/reportDocumentsSlice';
import { contractExchangeSlice } from './slices/contractExchange/contractExchangeSlice';
import { alertSlice } from './slices/alert/alertSlice';
import { b2bCardSlice } from './slices/b2bCard/b2bCardSlice';
import { b2bHistorySlice } from './slices/b2bHistory/b2bHistorySlice';
import { sliderSlice } from './slices/slider/sliderSlice';
import { blockPrioritySlice } from './slices/blockPriority/blockPrioritySlice';
import { activitiesSlice } from './slices/activities/activitiesSlice';
import { previewSlice } from './slices/preview/previewSlice';
import { currentResidentsSlice } from './slices/currentResidents/currentResidentsSlice';
import { investmentSolutions } from './slices/investmentSolutions/investmentSolutions';
import { aboutRegionSlice } from './slices/aboutRegion/aboutRegionSlice';

const rootReducer = combineReducers({
  preview: previewSlice.reducer,
  activities: activitiesSlice.reducer,
  configSlice: configSlice.reducer,
  account: accountSlice.reducer,
  catalog: catalogSlice.reducer,
  attractions: attractionsSlice.reducer,
  hotels: hotelsSlice.reducer,
  restaurants: restaurantsSlice.reducer,
  catalogEntityTypes: catalogEntityTypesSlice.reducer,
  news: newsSlice.reducer,
  articles: articlesSlice.reducer,
  faq: faqSlice.reducer,
  touristRoutes: touristRoutesSlice.reducer,
  resorts: resortsSlice.reducer,
  investmentProjects: investmentProjectsSlice.reducer,
  events: eventsSlice.reducer,
  purchasePlans: purchasePlansSlice.reducer,
  infrastructures: infrastructuresSlice.reducer,
  regulations: regulationsSlice.reducer,
  signedAgreements: signedAgreementsSlice.reducer,
  regions: regionsSlice.reducer,
  admins: adminsSlice.reducer,
  supportMeasures: supportMeasuresSlice.reducer,
  purchases: purchasesSlice.reducer,
  sales: salesSlice.reducer,
  rentals: rentalsSlice.reducer,
  applications: applicationsSlice.reducer,
  projects: projectsSlice.reducer,
  formDocuments: formDocumentsSlice.reducer,
  formComments: formCommentsSlice.reducer,
  investmentPassports: investmentPassportsSlice.reducer,
  investInfrastructures: investInfrastructuresSlice.reducer,
  responds: respondsSlice.reducer,
  materials: materialsSlice.reducer,
  organizations: organizationsSlice.reducer,
  vacancies: vacanciesSlice.reducer,
  vacanciesResponds: vacanciesRespondsSlice.reducer,
  business: businessSlice.reducer,
  menu: menuSlice.reducer,
  priorityIndustry: priorityIndustrySlice.reducer,
  openingInfo: openingInfoSlice.reducer,
  reviews: reviewsSlice.reducer,
  tourist: touristSlice.reducer,
  reports: reportsSlice.reducer,
  reportComments: reportCommentsSlice.reducer,
  reportDocuments: reportDocumentsSlice.reducer,
  contractExchange: contractExchangeSlice.reducer,
  blockPriority: blockPrioritySlice.reducer,
  alert: alertSlice.reducer,
  b2bCard: b2bCardSlice.reducer,
  b2bHistory: b2bHistorySlice.reducer,
  slider: sliderSlice.reducer,
  currentResidents: currentResidentsSlice.reducer,
  investmentSolutions: investmentSolutions.reducer,
  aboutRegion: aboutRegionSlice.reducer,
});

export default rootReducer;
