// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 499px) {\n  .ExtensionContactBlock-module__phoneInput--uEGP9 {\n    flex: 1 1 100%;\n  }\n}\n.ExtensionContactBlock-module__additionalPhoneInput--_D7ML {\n  flex: 0 1 85px;\n  margin-left: 24px;\n}\n@media (max-width: 499px) {\n  .ExtensionContactBlock-module__additionalPhoneInput--_D7ML {\n    margin-left: 0;\n    flex: 1 1 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/ExtensionContactBlock/ExtensionContactBlock.module.less"],"names":[],"mappings":"AAGE;EAAA;IACE,cAAA;EADF;AACF;AAIA;EACE,cAAA;EACA,iBAAA;AAFF;AAIE;EAAA;IACE,cAAA;IACA,cAAA;EADF;AACF","sourcesContent":["@mobile: 499px;\n\n.phoneInput {\n  @media (max-width: @mobile) {\n    flex: 1 1 100%;\n  }\n}\n\n.additionalPhoneInput {\n  flex: 0 1 85px;\n  margin-left: 24px;\n\n  @media (max-width: @mobile) {\n    margin-left: 0;\n    flex: 1 1 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneInput": "ExtensionContactBlock-module__phoneInput--uEGP9",
	"additionalPhoneInput": "ExtensionContactBlock-module__additionalPhoneInput--_D7ML"
};
export default ___CSS_LOADER_EXPORT___;
