import React from 'react';
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

export const OkSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.31644 10.674C6.29577 10.5673 5.3751 10.314 4.5871 9.69335C4.4891 9.61602 4.38777 9.54135 4.2991 9.45535C3.95377 9.12202 3.9191 8.73935 4.19244 8.34602C4.42577 8.00868 4.81777 7.91868 5.2251 8.11268C5.30377 8.14935 5.37844 8.19668 5.4511 8.24668C6.91977 9.26268 8.93777 9.29068 10.4118 8.29268C10.5584 8.17935 10.7144 8.08735 10.8951 8.04068C11.2471 7.95002 11.5751 8.08002 11.7638 8.39002C11.9798 8.74335 11.9771 9.09002 11.7111 9.36402C11.3038 9.78602 10.8131 10.0907 10.2684 10.3033C9.7531 10.5047 9.18844 10.606 8.63044 10.6727C8.71444 10.7653 8.75444 10.8107 8.8071 10.864C9.5651 11.6307 10.3271 12.3953 11.0831 13.1647C11.3404 13.4274 11.3944 13.752 11.2524 14.0574C11.0971 14.3907 10.7504 14.61 10.4098 14.5867C10.1944 14.5714 10.0264 14.4634 9.87644 14.312C9.30444 13.732 8.72177 13.1627 8.1611 12.572C7.99777 12.4 7.9191 12.4327 7.7751 12.582C7.19977 13.1787 6.6151 13.766 6.02577 14.3507C5.76177 14.6127 5.4471 14.66 5.14044 14.5107C4.8151 14.3507 4.6071 14.0167 4.62377 13.68C4.63444 13.452 4.74577 13.2787 4.9011 13.1227C5.65177 12.368 6.39977 11.6113 7.14844 10.856C7.19777 10.8053 7.24444 10.752 7.31644 10.674Z'
      fill='currentColor'
    />
    <path
      d='M7.93851 8.04599C6.11717 8.03932 4.62384 6.51932 4.63384 4.68199C4.64451 2.82466 6.13917 1.32866 7.97851 1.33332C9.82117 1.33799 11.3005 2.85666 11.2918 4.73332C11.2818 6.56666 9.77851 8.05199 7.93851 8.04599ZM9.59384 4.68599C9.59507 4.4713 9.55385 4.25847 9.47257 4.05976C9.39128 3.86104 9.27151 3.68035 9.12017 3.52808C8.96882 3.3758 8.78886 3.25493 8.59065 3.17243C8.39244 3.08992 8.17987 3.04741 7.96517 3.04732C7.74845 3.04711 7.53387 3.09009 7.33394 3.17374C7.13402 3.25739 6.95276 3.38003 6.80077 3.53451C6.64877 3.68899 6.52908 3.87221 6.44868 4.07346C6.36828 4.27471 6.32879 4.48997 6.33251 4.70666C6.33276 4.92144 6.3755 5.13405 6.45826 5.33225C6.54101 5.53044 6.66216 5.71031 6.81471 5.8615C6.96726 6.01269 7.14822 6.13222 7.34715 6.21319C7.54608 6.29417 7.75906 6.33499 7.97384 6.33332C8.18861 6.33255 8.40111 6.28923 8.59905 6.20587C8.79698 6.12252 8.97645 6.00077 9.12707 5.84767C9.2777 5.69456 9.3965 5.51313 9.47661 5.31386C9.55673 5.11459 9.59657 4.90142 9.59384 4.68666V4.68599Z'
      fill='currentColor'
    />
  </svg>
);

export const VkSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.9972 4.58686C15.0987 4.24886 14.9972 4 14.5114 4H12.9073C12.4991 4 12.3109 4.21481 12.2088 4.4519C12.2088 4.4519 11.393 6.43038 10.2374 7.71552C9.86341 8.08819 9.69353 8.20643 9.48942 8.20643C9.38736 8.20643 9.23398 8.08819 9.23398 7.74957V4.58686C9.23398 4.18076 9.12154 4 8.78177 4H6.25915C6.00432 4 5.85094 4.18819 5.85094 4.3671C5.85094 4.75152 6.42904 4.84067 6.48831 5.92276V8.2739C6.48831 8.78957 6.39482 8.88305 6.19071 8.88305C5.64683 8.88305 4.3238 6.89529 3.53854 4.6209C3.38638 4.17829 3.23238 4 2.82234 4H1.21698C0.758657 4 0.666992 4.21481 0.666992 4.4519C0.666992 4.8741 1.21087 6.97143 3.19999 9.74538C4.52608 11.6397 6.39298 12.6667 8.09367 12.6667C9.11359 12.6667 9.23948 12.4389 9.23948 12.0458V10.6139C9.23948 10.1577 9.33603 10.0667 9.6593 10.0667C9.89763 10.0667 10.3052 10.1855 11.2573 11.0986C12.3451 12.1813 12.5241 12.6667 13.1365 12.6667H14.7406C15.1989 12.6667 15.4287 12.4389 15.2967 11.9882C15.1512 11.54 14.6318 10.8894 13.9431 10.1174C13.5691 9.67791 13.0081 9.20433 12.8376 8.96724C12.5999 8.66329 12.6677 8.52771 12.8376 8.25719C12.8376 8.25719 14.7938 5.51729 14.9972 4.58686Z'
      fill='currentColor'
    />
  </svg>
);

export const TelegramSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.2538 2.76086C13.2538 2.76086 14.4255 2.29914 14.3279 3.42045C14.2953 3.88217 14.0024 5.49817 13.7746 7.2461L12.9934 12.4239C12.9934 12.4239 12.9283 13.1824 12.3424 13.3144C11.7565 13.4463 10.8777 12.8526 10.7149 12.7207C10.5848 12.6218 8.27381 11.1377 7.46007 10.4121C7.23222 10.2143 6.97183 9.8185 7.49261 9.35678L10.9103 6.05884C11.3008 5.66307 11.6914 4.73965 10.064 5.86095L5.50714 8.99403C5.50714 8.99403 4.98636 9.32381 4.00991 9.027L1.89421 8.36741C1.89421 8.36741 1.11304 7.87272 2.44755 7.378C5.70244 5.82795 9.70596 4.24493 13.2538 2.76086Z'
      fill='currentColor'
    />
  </svg>
);

export const YandexZenSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.666992 8.33331C0.666992 4.46731 3.80099 1.33331 7.66699 1.33331C11.533 1.33331 14.667 4.46731 14.667 8.33331C14.667 12.1993 11.533 15.3333 7.66699 15.3333C3.80099 15.3333 0.666992 12.1993 0.666992 8.33331Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.52534 1.33331C7.49227 4.21316 7.30394 5.82486 6.23124 6.89756C5.15854 7.97026 3.54684 8.15859 0.666992 8.19166V8.60959C3.54684 8.64264 5.15854 8.83096 6.23124 9.90366C7.28712 10.9595 7.48614 12.5376 7.52367 15.3333H7.94494C7.98244 12.5376 8.18147 10.9595 9.23734 9.90366C10.2932 8.84779 11.8713 8.64876 14.667 8.61126V8.18996C11.8713 8.15246 10.2932 7.95344 9.23734 6.89756C8.16464 5.82486 7.97632 4.21316 7.94327 1.33331H7.52534Z'
      fill='white'
    />
  </svg>
);

export const SeaportSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='24' height='24' rx='3' fill='#1890FF' />
    <path
      d='M19.0396 15.9134L17.8784 11.7581L14.8601 14.8413L15.9676 15.1254C15.47 16.5917 14.3289 17.6529 12.9471 17.9897V8.94256C13.8921 8.56484 14.5617 7.64069 14.5617 6.56255C14.5618 5.14957 13.4123 4 11.9993 4C10.5863 4 9.43674 5.14957 9.43674 6.56255C9.43674 7.64057 10.1064 8.56468 11.0513 8.94256V17.9897C9.66973 17.653 8.52847 16.592 8.03081 15.1254L9.13835 14.8413L6.12021 11.7581L4.95898 15.9134L6.19066 15.5974C7.02375 18.2382 9.33591 20 11.9993 20C14.6627 20 16.975 18.2382 17.808 15.5974L19.0396 15.9134ZM11.9992 5.89574C12.3666 5.89574 12.6657 6.19473 12.6657 6.56229C12.6657 6.92972 12.3666 7.22871 11.9992 7.22871C11.6317 7.22871 11.3326 6.92985 11.3326 6.56216C11.3326 6.1946 11.6317 5.89574 11.9992 5.89574Z'
      fill='white'
    />
  </svg>
);

export const AirportSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='24' height='24' rx='3' fill='#FA541C' />
    <path
      d='M4.0001 12.1691C4.04478 12.2138 4.08932 12.236 4.11174 12.236C5.07152 12.7718 6.03145 13.3299 6.99123 13.8655C7.08045 13.9102 7.12514 13.9772 7.14756 14.0888C7.16983 14.4236 7.19225 14.7585 7.23678 15.1157C7.28147 15.8523 7.32601 16.5666 7.39312 17.281C7.39312 17.3702 7.41538 17.4373 7.4378 17.5488C7.6459 17.4207 8.30836 17.1949 8.30836 16.9014C8.39758 16.0755 8.50923 15.2495 8.5986 14.4236C8.5986 14.3566 8.66555 14.2673 8.71024 14.2227C9.15665 13.9325 9.60305 13.6424 10.0497 13.3744C10.63 12.995 11.2327 12.6378 11.813 12.2583C11.9694 12.1467 12.0809 12.1914 12.1255 12.37C12.2819 13.1513 12.438 13.9325 12.5944 14.7139C12.8622 16.0755 13.1302 17.4372 13.398 18.7765C13.4203 18.9328 13.4427 18.9328 13.5766 18.8657C13.956 18.6424 14.3579 18.4193 14.7374 18.2185C15.0722 18.0399 15.0722 18.0399 15.0946 17.6604C15.1393 16.6113 15.1838 15.5621 15.2062 14.4906C15.2732 13.0398 15.3179 11.5887 15.3848 10.1378C15.3848 10.0486 15.4295 9.9592 15.4965 9.91451C16.0992 9.53505 16.6795 9.17788 17.2823 8.7984C17.9295 8.39668 18.577 7.9948 19.202 7.57063C19.5368 7.34735 19.8047 7.05726 19.9386 6.67782C20.1172 6.16445 19.8939 5.6956 19.4253 5.56171C19.2689 5.51702 19.1128 5.51702 18.9564 5.51702C18.51 5.53929 18.1081 5.69562 17.7286 5.94118C16.5898 6.69994 15.4737 7.41433 14.3353 8.12857C14.2684 8.17325 14.179 8.17325 14.112 8.15083C12.9067 7.63746 11.7235 7.14637 10.5404 6.633C9.3573 6.11963 8.15181 5.62853 6.96873 5.11516C6.87951 5.07047 6.8124 5.0929 6.74545 5.13743C6.43294 5.36071 6.12042 5.56159 5.80791 5.78469C5.65158 5.89634 5.47314 6.03025 5.29454 6.14189C5.33923 6.18658 5.38376 6.20884 5.40618 6.25353C5.60705 6.40986 5.83035 6.56603 6.03121 6.72238C7.57148 7.86077 9.11172 8.97689 10.6296 10.1154C10.8305 10.2717 10.8305 10.361 10.6296 10.4949C9.62513 11.1421 8.62067 11.7673 7.61601 12.4146C7.4821 12.5038 7.37046 12.5038 7.23655 12.4593C6.56684 12.2137 5.87489 12.0129 5.20518 11.7449C4.82414 11.6064 4.28159 11.8876 4.0001 12.1691Z'
      fill='white'
    />
  </svg>
);

export const RailwaySvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='24' height='24' rx='3' fill='#722ED1' />
    <path
      d='M6.69302 18.75H17.3071C18.1031 18.75 18.75 18.1174 18.75 17.3357V8.78769C18.75 6.83361 17.1348 5.25 15.1428 5.25H8.85686C6.86521 5.25 5.25 6.83374 5.25 8.78769V17.3357C5.25 18.1169 5.89633 18.75 6.69302 18.75V18.75ZM15.9078 16.8049C15.3768 16.8049 14.9456 16.3823 14.9456 15.8609C14.9456 15.3407 15.3766 14.9181 15.9078 14.9181C16.4395 14.9181 16.87 15.3407 16.87 15.8609C16.87 16.3828 16.4395 16.8049 15.9078 16.8049ZM16.8693 8.90472V12.7957H12.7577V8.90472H16.8693ZM7.13003 8.90472H11.2413V12.7957H7.13003V8.90472ZM8.09211 14.9185C8.62315 14.9185 9.05364 15.3412 9.05364 15.8613C9.05364 16.3827 8.62315 16.8054 8.09211 16.8054C7.56039 16.8054 7.12993 16.3827 7.12993 15.8613C7.13006 15.3412 7.56042 14.9185 8.09211 14.9185Z'
      fill='white'
    />
  </svg>
);

export const ToThePage = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.75 14.25L2.25 14.25C1.97344 14.25 1.75 14.0266 1.75 13.75L1.75 2.25C1.75 1.97344 1.97344 1.75 2.25 1.75L7.875 1.75C7.94375 1.75 8 1.80625 8 1.875L8 2.75C8 2.81875 7.94375 2.875 7.875 2.875L2.875 2.875L2.875 13.125L13.125 13.125L13.125 8.125C13.125 8.05625 13.1813 8 13.25 8L14.125 8C14.1938 8 14.25 8.05625 14.25 8.125L14.25 13.75C14.25 14.0266 14.0266 14.25 13.75 14.25Z'
      fill='currentColor'
    />
    <path
      d='M11.2292 2.29582L12.0448 3.11144L8.04327 7.11613C7.99483 7.16457 7.99483 7.24425 8.04326 7.29269L8.70577 7.95519C8.7542 8.00363 8.83389 8.00363 8.88233 7.95519L12.8855 3.95207L13.7042 4.77082C13.7776 4.84425 13.9042 4.8005 13.9167 4.69738L14.2448 1.89425C14.2542 1.813 14.1855 1.74582 14.1058 1.75519L11.3026 2.08332C11.2794 2.08618 11.2574 2.0955 11.2392 2.11024C11.221 2.12497 11.2073 2.14452 11.1996 2.16666C11.192 2.1888 11.1907 2.21264 11.1959 2.23547C11.2012 2.2583 11.2127 2.27921 11.2292 2.29582Z'
      fill='currentColor'
    />
  </svg>
);

export const RubCircleSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z'
      fill='currentColor'
    />
    <path
      d='M8.51725 8.84342H5.26738C5.15693 8.84342 5.06738 8.75388 5.06738 8.64342V8.13652C5.06738 8.02607 5.15693 7.93652 5.26738 7.93652H8.49447C8.80132 7.93652 9.04894 7.88943 9.23731 7.79525C9.42567 7.69802 9.56391 7.56283 9.65202 7.38965C9.74013 7.21647 9.78418 7.01443 9.78418 6.78353C9.78418 6.57693 9.74013 6.38401 9.65202 6.20475C9.56391 6.0255 9.42719 5.87967 9.24186 5.76725C9.05653 5.6518 8.815 5.59408 8.51725 5.59408H7.34095C7.23049 5.59408 7.14095 5.68362 7.14095 5.79408V11.118C7.14095 11.2285 7.05141 11.318 6.94095 11.318H6.19707C6.08661 11.318 5.99707 11.2285 5.99707 11.118V4.88262C5.99707 4.77216 6.08661 4.68262 6.19707 4.68262H8.51725C9.02767 4.68262 9.46213 4.77224 9.82064 4.9515C10.1791 5.13075 10.4541 5.37836 10.6455 5.69434C10.8369 6.00727 10.9326 6.3673 10.9326 6.77441C10.9326 7.20888 10.8354 7.58105 10.641 7.89095C10.4495 8.19781 10.1731 8.43327 9.81152 8.59733C9.45302 8.76139 9.02159 8.84342 8.51725 8.84342ZM8.42663 9.33105C8.53708 9.33105 8.62663 9.4206 8.62663 9.53105V10.038C8.62663 10.1484 8.53708 10.238 8.42663 10.238H5.26738C5.15693 10.238 5.06738 10.1484 5.06738 10.038V9.53105C5.06738 9.4206 5.15693 9.33105 5.26738 9.33105H8.42663Z'
      fill='currentColor'
    />
  </svg>
);

export enum SvgIconNames {
  OK = 'ok',
  VK = 'vk',
  Telegram = 'telegram',
  YandexDzen = 'yandexDzen',
  seaport = 'seaport',
  airport = 'airport',
  railway = 'railway',
  ToThePage = 'toThePage',
  RubCircle = 'rubCircle',
}

const svgComponents = {
  [SvgIconNames.OK]: OkSvg,
  [SvgIconNames.VK]: VkSvg,
  [SvgIconNames.Telegram]: TelegramSvg,
  [SvgIconNames.YandexDzen]: YandexZenSvg,
  [SvgIconNames.seaport]: SeaportSvg,
  [SvgIconNames.airport]: AirportSvg,
  [SvgIconNames.railway]: RailwaySvg,
  [SvgIconNames.ToThePage]: ToThePage,
  [SvgIconNames.RubCircle]: RubCircleSvg,
};

type TCustomIconProps = Partial<CustomIconComponentProps> & {
  name: SvgIconNames;
};

export const CustomIcon = ({ name, ...props }: TCustomIconProps) => (
  <Icon component={svgComponents[name]} {...props} />
);
