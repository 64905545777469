import { apiClient } from './http';

export const downloadDocument = async (url: string, docName: string) => {
  return await apiClient
    .get(url, {
      responseType: 'blob',
    })
    .then(res => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName);
      document.body.appendChild(link);
      link.click();
    });
};
