// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserOrganizations-module__table--Hn161 .ant-table-cell {\n  border-bottom: none;\n  vertical-align: top !important;\n}\n.UserOrganizations-module__table--Hn161 .ant-table-cell::before {\n  content: none !important;\n}\n.UserOrganizations-module__tableTitle--F6VVE {\n  display: inline-block;\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Business/components/UserOrganizations/UserOrganizations.module.less"],"names":[],"mappings":"AAAA;EAGG,mBAAA;EACA,8BAAA;AADH;AAEG;EACC,wBAAA;AAAJ;AAMA;EACC,qBAAA;EACA,kBAAA;AAJD","sourcesContent":[".table {\n\t:global {\n\t\t.ant-table-cell {\n\t\t\tborder-bottom: none;\n\t\t\tvertical-align: top !important;\n\t\t\t&::before {\n\t\t\t\tcontent: none !important;\n\t\t\t}\n\t\t}\n\t}\n}\n\n.tableTitle {\n\tdisplay: inline-block;\n\tmargin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "UserOrganizations-module__table--Hn161",
	"tableTitle": "UserOrganizations-module__tableTitle--F6VVE"
};
export default ___CSS_LOADER_EXPORT___;
