export const getName = (
  {
    firstName,
    lastName,
    secondName = '',
  }: {
    lastName: string;
    firstName: string;
    secondName?: string;
  },
  isIp = false,
  isShort = false
): string => {
  const result = [];
  if (lastName && firstName) {
    if (isIp) result.push('ИП');
    result.push(lastName);
    if (isShort) {
      result.push(` ${firstName[0]}.`);
    } else {
      result.push(` ${firstName}`);
    }
    if (secondName) {
      result.push(` ${isShort ? `${secondName[0]}.` : secondName}`);
    }
  }

  return result.join(' ');
};
