import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GlobalOutlined } from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { MainImage } from 'components/form/MainImage/MainImage';
import { Status } from 'components/form/Status/Status';
import { Gallery } from 'components/form/Gallery/Gallery';
import { TImage } from 'types/image';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty, isString } from 'utils/helpers';
import { Statuses } from 'constants/status';
import {
  TInvestInfrastructuresDraftItem,
  IInvestInfrastructuresItem,
  TInvestInfrastructuresContacts,
  TPreferences,
} from 'store/slices/investInfrastructures/interfaces';
import { PREVIEW_ASPECT_RATIO } from 'constants/image';
import { LangEnumKeys } from 'constants/lang';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { TSEO } from 'types/seo';
import { IAttractionAddress } from 'types/address';
import { SEO } from 'components/form/SEO/SEO';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { ExtensionContactBlock } from 'components/form/ExtensionContactBlock/ExtensionContactBlock';
import { Preferences } from 'components/form/Preferences/Preferences';
import { FieldsList } from 'components/form/FieldsList';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { TContentBlock } from 'components/form/Content/contentTypes';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';
import { InvestInfrastructureLocation } from '../InvestInfrastructureLocation/InvestInfrastructureLocation';
import styles from './InvestInfrastructuresForm.module.less';

interface IFormValues extends TSEO {
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  image: TImage;
  shortDescription: string;
  territoryType: number | { value: number; label: string };
  region: string | { value: number; label: string };
  preferences: TPreferences[];
  gallery: TImage[];
  content: TContentBlock[];
  contacts: TInvestInfrastructuresContacts;
  videoUrls: { value: string }[];
  status: Statuses;
  address: IAttractionAddress;
  polylines: string[][][];
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IInvestInfrastructuresItem) => void;
  onSaveDraft: (values: TInvestInfrastructuresDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const InvestInfrastructuresForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'preferences',
    'gallery',
    'content',
    'contacts',
    'seo',
    'status',
    'address',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver,
  });

  const {
    formState: { errors },
  } = methods;

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), territoryType: null });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
            <Col flex='auto'>
              <InputField
                name='name'
                label='Название'
                required
                placeholder='Введите название'
                showCount
                maxLength={80}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Введите описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogAsyncSelect
                name='territoryType'
                label='Тип территории'
                placeholder='Выберите'
                required
                requestParams={{
                  entityType: 'investInfrastructures',
                  fieldType: 'territoryTypes',
                  lang: langValue,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Преференции резидентам' key='preferences'>
            <Preferences name='preferences' />
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
              <Col span={24} className={styles.videoGalleryContainer}>
                <div className={styles.videoGalleryTitle}>Галерея видео</div>
                <FieldsList
                  buttonLabel='Добавить видео'
                  name='videoUrls'
                  field={
                    <InputField
                      placeholder='Ссылка на видео'
                      addonBefore={<GlobalOutlined />}
                    />
                  }
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Описание паспорта' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(errors, 'content')?.[0]?.text
                      ?.message
                  }
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Адрес' key='address'>
            <InvestInfrastructureLocation name='address' />
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <ExtensionContactBlock name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
