import React from 'react';
import { GetOptionValue, GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import { notification } from 'antd';

import { apiClient } from 'utils/http';
import { ListResponse } from 'types/pagination';

import { ControlledPaginateSelect } from './ControlledPaginateSelect';

export interface IAdditional {
  offset: number;
  sort: string;
}

export type IOptions = any;

export type TLoadOptions = LoadOptions<
  IOptions,
  GroupBase<IOptions>,
  IAdditional
>;

interface IProps {
  name: string;
  label: string;
  placeholder: string;
  additional: Record<any, any>;
  required: boolean;
  requestUrl: string;
  size?: number;
}

export const EntitySelect: React.FC<IProps> = ({
  additional,
  requestUrl,
  size = 20,
  ...props
}) => {
  const loadOptions: TLoadOptions = async (q, prevOptions, additional) => {
    const nextOffset = additional.offset + size;
    return apiClient
      .get<ListResponse<any>>(requestUrl, {
        params: {
          name: q,
          ...additional,
        },
      })
      .then(async ({ data: { data, total } }) => {
        return {
          hasMore: total > nextOffset,
          additional: { ...additional, offset: nextOffset },
          options: data,
        };
      })
      .catch(err => {
        notification.error({
          message: 'Внутренняя ошибка сервера',
        });
        return err;
      });
  };

  const getOptionValue: GetOptionValue<IOptions> = option => option;
  const getOptionLabel: GetOptionValue<IOptions> = option => option.name;
  return (
    <ControlledPaginateSelect
      {...props}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      loadOptions={loadOptions}
      isSearchable
      additional={{ ...additional, offset: 0, sort: '-publishedAt' }}
    />
  );
};
