import { OrganizationsItem, OrganizationTypeEnum } from 'types/organizations';
import { omit } from 'utils/helpers';

const checkAllEmptyObj = (obj: Record<string, string>) => {
  return Object.values(obj).every(item => !item);
};

export const getNameOrganization = (
  organization: Pick<
    OrganizationsItem,
    'lastName' | 'firstName' | 'secondName' | 'shortName' | 'fullName' | 'type'
  >,
  defaultName = ''
) => {
  if (checkAllEmptyObj(omit(organization, 'type'))) return defaultName;

  if (organization.type === OrganizationTypeEnum.organization) {
    return organization.fullName || defaultName;
  }

  const { firstName, lastName, secondName } = organization;

  return `ИП ${lastName} ${firstName} ${secondName}`;
};
