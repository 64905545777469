/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  loadEventsListMainTourism,
  saveEventsItem,
} from 'store/slices/events/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { SampleList } from 'components/Sample/SampleList';
import { PageTitles } from 'constants/pageTitles';
import { IEventsItem } from 'store/slices/events/interfaces';

interface IProps {
  sortFieldName?: keyof Pick<
    IEventsItem,
    'sortPriority' | 'sortPriorityMainPage'
  >;
  limit?: number;
  addON?: boolean;
  deleteON?: boolean;
  isSortPriority?: boolean | null;
}

export const List: React.FC<IProps> = ({
  sortFieldName = 'sortPriority',
  limit,
  deleteON,
  isSortPriority = true,
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.events);

  const tableData = data.mainTourismList.map(item => ({
    title: item.name,
    id: item.id,
    sortPriority: item[sortFieldName],
    image: item.image,
  }));

  const handleRemove = async (id: number): Promise<any> => {
    return dispatch(
      saveEventsItem({
        id,
        [sortFieldName]: null,
      })
    ).unwrap();
  };

  const handleLoadData = (lang: string) => {
    dispatch(
      loadEventsListMainTourism({
        lang: lang,
        limit,
        sort: sortFieldName,
        isSortPriority,
      })
    );
  };
  return (
    <SampleList
      data={tableData}
      handleLoadData={handleLoadData}
      handleDelete={handleRemove}
      isLoading={data.isLoading}
      title={PageTitles.events}
      limit={limit}
      addON={true}
      deleteON={deleteON}
    />
  );
};
