import React, { useMemo } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useResponsive } from 'ahooks';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { useAppSelector } from 'store';

import { getFilteredSidebarListByRole, SIDEBAR_LIST } from './constants';
import styles from './Sidebar.module.less';

const { Sider } = Layout;

type TProps = {
  sidebarCollapsedStatus: boolean;
  onCollapse: () => any;
};

export const Sidebar: React.FC<TProps> = React.memo(
  ({ sidebarCollapsedStatus, onCollapse }) => {
    const [activeTab, setActiveTab] = React.useState(null);
    const [activeGroupTabs, setActiveGroupTabs] = React.useState([]);
    const { role } = useAppSelector(state => state.account);
    const responsive = useResponsive();
    const location = useLocation();

    React.useEffect(() => {
      /** это выбор активного раздела в sidebar **/
      const basePathname = location.pathname.split('/').slice(0, 2).join('/');
      SIDEBAR_LIST.forEach(item => {
        if (item.children) {
          const activeItem = item.children.find(
            nestedItem => nestedItem.key === basePathname
          );
          if (activeItem) {
            setActiveGroupTabs([...new Set([...activeGroupTabs, item.key])]);
          }
        }
      });
      setActiveTab(basePathname);
    }, [location?.pathname]);

    const items: ItemType[] = useMemo(() => {
      /** Фильтруем список в сайдбаре по ролям. Показываем те, которые соответствуют роли **/
      const sidebarListByCurrentRole = getFilteredSidebarListByRole(role);

      /** Добавляем пустой элемент, чтобы показывался последний в списке, иначе его не видно **/
      return responsive.middle
        ? [...sidebarListByCurrentRole, { key: '', label: '' }]
        : sidebarListByCurrentRole;
    }, [responsive, role]);

    return (
      <Sider
        className={classNames({
          '__ant-layout-sider-hide':
            !responsive.middle && sidebarCollapsedStatus,
          '__ant-layout-sider-show':
            !responsive.middle && !sidebarCollapsedStatus,
          '__ant-layout-sider-mobile': !responsive.middle,
        })}
        collapsed={sidebarCollapsedStatus}
        collapsible={responsive.middle}
        onCollapse={() => {
          setTimeout(() => {
            //resize yandexMap on mobile
            window.dispatchEvent(new Event('resize'));
          }, 1000);
          onCollapse();
        }}
        width={255}
      >
        {activeTab !== null && (
          <Menu
            className={styles.menu}
            theme='dark'
            mode='inline'
            selectedKeys={[activeTab]}
            openKeys={activeGroupTabs}
            onOpenChange={openKeys =>
              setActiveGroupTabs([...new Set(openKeys)])
            }
            items={items}
          />
        )}
      </Sider>
    );
  },
  () => false
);

Sidebar.displayName = 'Sidebar';
