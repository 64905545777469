import React from 'react';

import { MainBusiness } from './MainBusiness';
import { AddSlide } from '../Slider/AddSlide';
import { EditSlide } from '../Slider/EditSlide';
import { Slider } from '../Slider/Slider';
import { About } from './components/About/About';
import { History } from './components/History/History';
import { Help } from './components/Help/Help';
import { Regions } from './components/Regions/Regions';
import { RegionEdit } from './components/Regions/RegionEdit';
import { AddAbout } from './components/About/AddAbout';
import { EditAbout } from './components/About/EditAbout';
import { AddHelp } from './components/Help/AddHelp';
import { EditHelp } from './components/Help/EditHelp';
import { AddHistory } from './components/History/AddHistory';
import { EditHistory } from './components/History/EditHistory';
import { SupportMeasures } from './components/SupportMeasures/SupportMeasures';
import { AddSupportMeasures } from './components/SupportMeasures/AddSupportMeasures';
import { EditSupportMeasures } from './components/SupportMeasures/EditSupportMeasures';

export const mainBusinessRoutes = {
  path: '/mainBusiness',
  children: [
    {
      path: '',
      element: <MainBusiness />,
    },
    {
      path: ':lang/slider',
      element: <Slider slug='mainBusiness' name='b2b-slider' />,
    },
    {
      path: ':lang/slider/:sliderName/add',
      element: <AddSlide slug='mainBusiness' isFormType />,
    },
    {
      path: ':lang/slider/:sliderName/:sliderItemId/edit',
      element: <EditSlide slug='mainBusiness' isFormType />,
    },
    {
      path: 'regions',
      element: <Regions />,
    },
    {
      path: 'regions/:id/regionEdit',
      element: <RegionEdit />,
    },
    {
      path: 'about',
      element: <About />,
    },
    {
      path: 'about/:cardId/add',
      element: <AddAbout />,
    },
    {
      path: 'about/:cardId/:cardItemId/edit',
      element: <EditAbout />,
    },
    {
      path: 'help',
      element: <Help />,
    },
    {
      path: 'help/:cardId/add',
      element: <AddHelp />,
    },
    {
      path: 'help/:cardId/:cardItemId/edit',
      element: <EditHelp />,
    },
    {
      path: 'history',
      element: <History />,
    },
    {
      path: 'history/:historyId/add',
      element: <AddHistory />,
    },
    {
      path: 'history/:historyId/:historyItemId/edit',
      element: <EditHistory />,
    },
    {
      path: 'supportMeasures',
      element: <SupportMeasures />,
    },
    {
      path: 'supportMeasures/:cardId/add',
      element: <AddSupportMeasures />,
    },
    {
      path: 'supportMeasures/:cardId/:cardItemId/edit',
      element: <EditSupportMeasures />,
    },
  ],
};
