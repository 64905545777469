import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, notification, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  updateProjectsItem,
  loadProjectsItem,
  removeProjectsItem,
} from 'store/slices/projects/actions';
import { TApplicationsStatuses } from 'constants/applicationsStatus';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { TProjectStatusTypes } from 'types/projects';
import { clearProjectsItemData } from 'store/slices/projects/projectsSlice';

import { ProjectsForm } from './components/ProjectsForm/ProjectsForm';
import { mapValuesToForm } from './components/formUtils';

export const View: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.projects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadProjectsItem(id));
    return () => {
      dispatch(clearProjectsItemData());
    };
  }, []);

  const onFinish = ({
    status,
    projectStatus,
  }: {
    status: TApplicationsStatuses;
    projectStatus: TProjectStatusTypes;
  }) => {
    setIsLoading(true);
    dispatch(updateProjectsItem({ status, projectStatus, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Проект успешно изменён',
        });
        navigate('/projects');
      })
      .catch(() => setIsLoading(false));
  };

  const handleDelete = async id => {
    setIsLoading(true);
    await dispatch(removeProjectsItem(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        navigate('/projects');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/projects');
  };

  return (
    <MainLayout
      pageTitle={item?.name || 'Проект'}
      extraButtonsList={[
        <Button
          key='delete'
          danger
          onClick={() =>
            item.id && item.id === Number(id) && setIsModalOpen(true)
          }
        >
          Удалить
        </Button>,
      ]}
    >
      {item.id && item.id === Number(id) ? (
        <>
          <ProjectsForm
            onFinish={onFinish}
            cancelButtonHandler={cancelButtonHandler}
            isLoading={isLoading}
            initialValues={mapValuesToForm(item)}
          />
          {isModalOpen && (
            <StickyConfirmModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              title='Удаление проекта'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину проект{' '}
                  <Typography.Text strong>{item.name || ''}</Typography.Text>?
                </div>
              }
              onSubmit={() => handleDelete(item.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      ) : null}
    </MainLayout>
  );
};
