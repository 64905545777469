import React, { useState } from 'react';
import { Button, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import {
  loadRestaurantsList,
  removeRestaurantItem,
  setPublishRestaurant,
  setUnPublishRestaurant,
} from 'store/slices/restaurants/actions';
import { PageTitles } from 'constants/pageTitles';
import { canBePublished, canBeUnPublished } from 'utils/rights';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.restaurants);
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const loadRestaurants = () => {
    dispatch(
      loadRestaurantsList({
        ...query,
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  useDeepEffect(() => {
    loadRestaurants();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(null);
    setCurrentItem(null);
  };

  const handleRemove = async () => {
    await dispatch(removeRestaurantItem(currentItem))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        loadRestaurants();
        modalCloseHandler();
      });
  };

  const handlePublish = async id => {
    await dispatch(setPublishRestaurant(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно опубликована',
        });
        loadRestaurants();
        modalCloseHandler();
      });
  };

  const handleUnPublish = async id => {
    await dispatch(setUnPublishRestaurant(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadRestaurants();
        modalCloseHandler();
      });
  };

  const getActions = item => [
    item.status === StatusesEnum.PUBLISHED && {
      element: (
        <a
          target='_blank'
          rel='noreferrer nofollow'
          href={`${b2cUrl}/${item.lang}/bars-and-restaurants/${item.id}/${item.sysName}`}
        >
          На сайте
        </a>
      ),
    },
    {
      element: <Link to={`/restaurants/${item.id}/edit`}>Редактировать</Link>,
    },
    item.lang === LangEnum.ru && {
      element: (
        <Link to={`/restaurants/${item.id}/add-translation`}>
          Создать копию для перевода
        </Link>
      ),
    },
    canBePublished(item) && {
      element: <Typography.Text>Опубликовать</Typography.Text>,
      handler: () => handlePublish(item.id),
    },
    canBeUnPublished(item) && {
      element: <Typography.Text>Снять с публикации</Typography.Text>,
      handler: () => handleUnPublish(item.id),
    },
    {
      element: <Typography.Text type='danger'>Удалить</Typography.Text>,
      handler: () => {
        if (item.status === StatusesEnum.PUBLISHED) {
          notification.error({
            message: 'Необходимо снять сущность с публикации',
          });
        } else {
          setModalIsOpen(true);
          setCurrentItem(item);
        }
      },
    },
  ];

  return (
    <MainLayout
      pageTitle={PageTitles['restaurants']}
      extraButtonsList={[
        <Link key={1} to='/restaurants/add'>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
      aside={<Filters />}
    >
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление кафе и ресторана'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину{' '}
                  <Typography.Text strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={handleRemove}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
