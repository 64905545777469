import { EntityRegionRelationsTypes } from './types';

export const CATEGORY_TYPES = [
  EntityRegionRelationsTypes.airport,
  EntityRegionRelationsTypes.railway,
  EntityRegionRelationsTypes.seaport,
];

export const CLUSTER_LIST_ID = 'clusterList';

export const ENTITY_POINTS_URLS = {
  [EntityRegionRelationsTypes.railway]: '/infrastructures/:id/edit',
  [EntityRegionRelationsTypes.airport]: '/infrastructures/:id/edit',
  [EntityRegionRelationsTypes.seaport]: '/infrastructures/:id/edit',
  [EntityRegionRelationsTypes.attractions]: '/attractions/:id',
  [EntityRegionRelationsTypes.resorts]: '/resorts/:id',
  [EntityRegionRelationsTypes.hotels]: '/hotels/:id',
  [EntityRegionRelationsTypes.restaurants]: '/restaurants/:id/edit',
};

export const ENTITY_REGION_RELATIONS_NAMES = {
  [EntityRegionRelationsTypes.unknown]: 'Не задано',
  [EntityRegionRelationsTypes.railway]: 'Ж/д',
  [EntityRegionRelationsTypes.airport]: 'Аэропорт',
  [EntityRegionRelationsTypes.seaport]: 'Порт',
  [EntityRegionRelationsTypes.attractions]: 'Достопримечательность',
  [EntityRegionRelationsTypes.resorts]: 'Курорт',
  [EntityRegionRelationsTypes.hotels]: 'Отель',
  [EntityRegionRelationsTypes.restaurants]: 'Кафе и ресторан',
};

export const ENTITY_REGION_RELATIONS_OPTIONS = Object.entries(
  ENTITY_REGION_RELATIONS_NAMES
).map(([key, label]) => ({
  label: label,
  value: key,
}));
