// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditableGallery-module__dropzone--QCmkg {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Gallery/EditableGallery.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[".dropzone {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "EditableGallery-module__dropzone--QCmkg"
};
export default ___CSS_LOADER_EXPORT___;
