// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlaceItemContent-module__workTimeTypeContainer--SdlYn {\n  font-weight: normal;\n  margin-top: 24px;\n}\n@media (max-width: 480px) {\n  .PlaceItemContent-module__workTimeRadioButton--ue6lJ {\n    width: 100%;\n    text-align: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Places/components/PlaceItemContent/PlaceItemContent.module.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,gBAAA;AADF;AAKE;EAAA;IACE,WAAA;IACA,kBAAA;EAFF;AACF","sourcesContent":["@xs: 480px;\n\n.workTimeTypeContainer {\n  font-weight: normal;\n  margin-top: 24px;\n}\n\n.workTimeRadioButton {\n  @media (max-width: @xs) {\n    width: 100%;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workTimeTypeContainer": "PlaceItemContent-module__workTimeTypeContainer--SdlYn",
	"workTimeRadioButton": "PlaceItemContent-module__workTimeRadioButton--ue6lJ"
};
export default ___CSS_LOADER_EXPORT___;
