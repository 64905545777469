import { createSlice } from '@reduxjs/toolkit';

import { loadRespondsList, loadRespondsItem } from './actions';
import { TRespondsState } from './interfaces';

export const respondsSlice = createSlice({
  name: 'responds',
  initialState: {
    list: [],
    item: null,
    limit: 20,
    total: 0,
    offset: 0,
    createdAtFrom: '',
    createdAtTo: '',
    loading: false,
  } as TRespondsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadRespondsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
        state.loading = false;
      }
    );
    builder.addCase(loadRespondsList.rejected, state => {
      state.list = [];
      state.total = 0;
      state.loading = false;
    });
    builder.addCase(loadRespondsList.pending, state => {
      state.loading = true;
    });
    builder.addCase(loadRespondsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
      state.loading = false;
    });
    builder.addCase(loadRespondsItem.rejected, state => {
      state.loading = false;
    });
    builder.addCase(loadRespondsItem.pending, state => {
      state.loading = true;
    });
  },
});
