import { Alert, Col, Row } from 'antd';
import React from 'react';

import { TextAreaField } from '../base/TextAreaField';
import styles from './SEO.module.less';

interface SEOProps {
  isShowAlert?: boolean;
}

export const SEO = ({ isShowAlert = true }: SEOProps) => (
  <>
    {isShowAlert && (
      <Alert
        type='info'
        showIcon
        message='Если поля не заполнены, то контент заполняется по шаблону'
        className={styles.alert}
      />
    )}
    <Row gutter={[24, 0]}>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <TextAreaField
          name='metaTitle'
          label='Title'
          showCount
          maxLength={100}
        />
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <TextAreaField
          name='metaDescription'
          label='Description'
          showCount
          maxLength={150}
        />
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <TextAreaField
          name='ogTitle'
          label='OG:Title'
          showCount
          maxLength={100}
        />
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <TextAreaField
          name='ogDescription'
          label='OG:Description'
          showCount
          maxLength={150}
        />
      </Col>
      <Col span={24}>
        <TextAreaField
          name='metaKeywords'
          label='Ключевые слова'
          showCount
          maxLength={150}
        />
      </Col>
    </Row>
  </>
);
