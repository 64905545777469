import React from 'react';
import dayjs from 'dayjs';
import { Layout, Typography } from 'antd';
import {
  useForm,
  FormProvider,
  WatchObserver,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { Select } from 'components/form/base/Select';
import { DatePicker } from 'components/form/base/DatePicker';
import { mapSelect } from 'utils/mappings';
import { debounce, removeEmptyValues } from 'utils/helpers';
import { reportsOptions } from 'constants/reports';

import { defaultInitialState } from './constants';
import { mapQuery } from './formUtils';

interface IFormValues {
  name: string;
  status: { value: string; label: string };
  createdAtFrom: string;
  createdAtTo: string;
}

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce(({ name, status, createdAtFrom, createdAtTo }: IFormValues) => {
      const preparedValues = {
        name: name && name.trim(),
        status: mapSelect(status),
        createdAtFrom,
        createdAtTo,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm<IFormValues>({
    defaultValues: {
      ...defaultInitialState,
      ...mapQuery(query),
    },
  });

  const minDate =
    useWatch<IFormValues>({
      control: methods.control,
      name: 'createdAtTo',
    }) || null;

  const maxDate =
    useWatch<IFormValues>({
      control: methods.control,
      name: 'createdAtFrom',
    }) || null;

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='name'
            label='Название организации'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select
            name='status'
            label='Статус отчета'
            options={reportsOptions}
          />
          <DatePicker
            name='createdAtFrom'
            label='Дата начала'
            placeholder='Выберите дату начала'
            maxDateRestriction={
              minDate && dayjs(minDate as string, 'DD.MM.YYYY')
            }
          />
          <DatePicker
            name='createdAtTo'
            label='Дата окончания'
            placeholder='Выберите дату окончания'
            minDateRestriction={
              maxDate && dayjs(maxDate as string, 'DD.MM.YYYY')
            }
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
