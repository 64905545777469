import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IInfrastructuresItem,
  IInfrastructuresItemResponse,
} from './interfaces';

type TListResponse = { data: IInfrastructuresItemResponse[]; total: number };

export const loadInfrastructuresList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('infrastructures/loadList', async (params, { getState }) => {
  const { limit } = getState().infrastructures;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.infrastructures.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadInfrastructuresItem = createAsyncThunk<
  IInfrastructuresItemResponse,
  string
>('infrastructures/loadItem', async id => {
  const { data } = await apiClient.get<IInfrastructuresItemResponse>(
    getUrlWithParams(urls.api.infrastructures.getOne, {
      id,
    })
  );
  return data;
});

export const saveInfrastructuresItem = createAsyncThunk<
  IInfrastructuresItem,
  IInfrastructuresItem
>('infrastructures/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.patch<IInfrastructuresItem>(
      getUrlWithParams(urls.api.infrastructures.patch, { id }),
      {
        ...params,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IInfrastructuresItem>(
      urls.api.infrastructures.post,
      {
        ...params,
      }
    );
    return data;
  }
});

export const removeInfrastructuresItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  number
>('infrastructures/removeItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.infrastructures.delete, { id }));
  return data;
});
