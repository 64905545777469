import * as Yup from 'yup';

import yup, {
  additionalPhoneNumberSchema,
  contentSchema,
  imageSchema,
  shortPhoneSchema,
} from 'utils/yup';
import { LangEnum } from 'constants/lang';
import { StatusesEnum } from 'constants/status';
import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { prepareContent } from 'components/form/Content/utils';
import { TContentBlock } from 'components/form/Content/contentTypes';

export const INITIAL_VALUES = {
  image: null,
  shortDescription: '',
  content: prepareContent([]) as TContentBlock[],
  requiredInvestments: '',
  category: null,
  lang: LangEnum.ru,
  status: StatusesEnum.PUBLISHED,
  region: null,
  contacts: {
    name: '',
    position: '',
    phone: '',
    additional: '',
    email: '',
  },
  gallery: [],
  documents: [] as MaterialValue[],
};

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  region: yup.mixed().required(),
  category: yup.mixed().required(),
  shortDescription: yup.string().required(),
  contacts: Yup.object().shape({
    name: Yup.string().max(60, 'Введите не более 60 символов'),
    position: Yup.string().max(150, 'Введите не более 150 символов'),
    phone: shortPhoneSchema.notRequired(),
    additional: additionalPhoneNumberSchema,
    email: Yup.string().email(),
  }),
  image: imageSchema,
  status: yup.mixed().required(),
  gallery: yup.array().of(imageSchema),
  content: contentSchema,
  documents: yup.array().of(
    yup.object().shape({
      url: yup.object().shape({
        url: yup.string().url(),
      }),
    })
  ),
});
