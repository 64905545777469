// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuItemActions-module__wrapper--brQdM {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  gap: 16px;\n}\n.MenuItemActions-module__deleteBtn--SKcrn {\n  margin: 0 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Menu/components/MenuItems/components/MenuItemActions/MenuItemActions.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,SAAA;AACF;AAEA;EACE,cAAA;AAAF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  gap: 16px;\n}\n\n.deleteBtn {\n  margin: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MenuItemActions-module__wrapper--brQdM",
	"deleteBtn": "MenuItemActions-module__deleteBtn--SKcrn"
};
export default ___CSS_LOADER_EXPORT___;
