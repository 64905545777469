import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IAdminsItemResponse } from 'store/slices/admin/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { roleNamesHash } from 'constants/roles';

interface IProps {
  data: IAdminsItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IAdminsItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Фамилия имя',
      sorter: true,
      dataIndex: 'lastName',
      render: (
        _: unknown,
        { id, lastName, firstName }: IAdminsItemResponse
      ) => (
        <Link to={`${id}`}>
          <Typography.Text>{`${lastName} ${firstName}`}</Typography.Text>
        </Link>
      ),
    },
    {
      key: 2,
      width: '25%',
      title: 'Email',
      sorter: true,
      dataIndex: 'email',
      render: email => (
        <Typography>
          <Typography.Text>{email}</Typography.Text>
        </Typography>
      ),
    },
    {
      key: 3,
      width: '20%',
      title: 'Роль',
      dataIndex: 'role',
      render: role => <Tag>{roleNamesHash[role]}</Tag>,
    },
    {
      key: 4,
      width: '15%',
      title: 'Дата, время регистрации',
      sorter: true,
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography>
          <div>{dayjs(createdAt).format('DD.MM.YYYY HH:mm')}</div>
        </Typography>
      ),
    },
    {
      key: 5,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IAdminsItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
