import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import {
  loadOrganization,
  loadOrganizationUserList,
} from 'store/slices/organizations/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { OrganizationTypeEnum } from 'types/organizations';
import { isEmpty } from 'utils/helpers';

import { getName } from './utils';
import { InfoAboutChange } from './components/InfoAboutChange/InfoAboutChange';
import { ItemControl } from './components/ItemControl/ItemControl';

export const Control = () => {
  const dispatch = useAppDispatch();
  const { item, users } = useAppSelector(state => state.organizations);
  const { id } = useParams();

  React.useEffect(() => {
    if (isEmpty(item) || !users.length) {
      dispatch(loadOrganization(id));
      dispatch(loadOrganizationUserList(id));
    }
  }, []);

  const { type, shortName, firstName, lastName, secondName } = item;

  const title =
    type === OrganizationTypeEnum.organization
      ? shortName || ''
      : getName({ firstName, lastName, secondName }, true, true);

  if (!users.length || isEmpty(item)) {
    return null;
  }

  return (
    <MainLayout
      pageTitle={title.trim() ? title : 'Не заполнено'}
      aside={<InfoAboutChange />}
      hasBackButton
    >
      <ItemControl />
    </MainLayout>
  );
};
