import React, { useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from '../../../../store';
import { addCard } from '../../../../store/slices/b2bCard/actions';
import { ICardItem } from '../../../../store/slices/b2bCard/interfaces';
import { MainLayout } from '../../../../components/Layout/MainLayout';
import { AboutCardForm } from './AboutCardForm';

export const AddAbout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { cardId } = useParams();
  const onSubmit = (values: ICardItem) => {
    setIsLoading(true);
    dispatch(addCard({ cardId, payload: values }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate('/mainBusiness/about');
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <MainLayout pageTitle='Добавление'>
      <Content>
        <AboutCardForm
          isLoading={isLoading}
          initialValues={null}
          onSubmit={onSubmit}
        />
      </Content>
    </MainLayout>
  );
};
