import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { StatusesEnum } from 'constants/status';

import {
  TPurchasesDraftItem,
  IPurchasesItem,
  IPurchasesItemResponse,
} from './interfaces';

type TListResponse = { data: IPurchasesItemResponse[]; total: number };

export const loadPurchasesList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('purchases/loadList', async (params, { getState }) => {
  const { limit } = getState().purchases;
  const { data } = await apiClient.get<TListResponse>(urls.api.purchases.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadPurchasesItem = createAsyncThunk<
  IPurchasesItemResponse,
  string
>('purchases/loadItem', async id => {
  const { data } = await apiClient.get<IPurchasesItemResponse>(
    getUrlWithParams(urls.api.purchases.getOne, {
      id,
    })
  );
  return data;
});

export const savePurchasesItem = createAsyncThunk<
  IPurchasesItem,
  IPurchasesItem
>('purchases/saveItem', async ({ id, ...params }) => {
  if (id) {
    const { data } = await apiClient.put<IPurchasesItem>(
      getUrlWithParams(urls.api.purchases.patch, { id }),
      {
        ...params,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IPurchasesItem>(
      urls.api.purchases.post,
      {
        ...params,
      }
    );
    return data;
  }
});

export const savePurchasesItemAsDraft = createAsyncThunk<
  TPurchasesDraftItem,
  TPurchasesDraftItem
>('purchases/saveDraftItem', async ({ id, ...requestParams }) => {
  if (id) {
    const { data } = await apiClient.put<TPurchasesDraftItem>(
      getUrlWithParams(urls.api.purchases.draft.patch, { id }),
      {
        ...requestParams,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<TPurchasesDraftItem>(
      urls.api.purchases.draft.post,
      {
        ...requestParams,
      }
    );
    return data;
  }
});

export const setUnPublishPurchase = createAsyncThunk<void, string>(
  'purchases/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.purchases.status.put, { id }),
      {
        status: StatusesEnum.NOT_PUBLISHED,
      }
    );
  }
);

export const deletePurchasesItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('purchases/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.purchases.delete, { id }));
  return data;
});
