import * as Yup from 'yup';

import { stripTagsAndTrim } from 'utils/helpers';
import { locationSchema, seoValidationSchema } from 'utils/yup';
import { VacanciesTempAddressFlagEnum } from 'types/vacancies';

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 100 символов')
      .max(100, 'Введите от 1 до 100 символов')
      .required('Это поле необходимо заполнить'),
    specialization: Yup.mixed().required(),
    revenueType: Yup.mixed(),
    revenueFrom: Yup.string().max(9),
    revenueTo: Yup.string().max(9),
    organization: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 100 символов')
      .max(100, 'Введите от 1 до 100 символов')
      .required('Это поле необходимо заполнить'),
    address: Yup.lazy((_, { parent }) => {
      if (parent?.addressFlag === VacanciesTempAddressFlagEnum.IncludeAddress) {
        return locationSchema;
      } else {
        return Yup.mixed();
      }
    }),
    workType: Yup.mixed().required(),
    workSchedule: Yup.mixed().required(),
    workExperience: Yup.mixed().required(),
    description: Yup.array().of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length >= 1;
          },
          message: 'Заполните описание',
        }),
      })
    ),
    status: Yup.mixed().required(),
  })
  .concat(seoValidationSchema);
