import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { getPriority } from 'store/slices/blockPriority/actions';
import { PageTitles } from 'constants/pageTitles';
import { mainTourismHeaders } from 'constants/mainTourism';
import { B2CBlockPriority } from 'constants/blockPriority';
import { MainLayout } from 'components/Layout/MainLayout';
import { PriorityForm } from 'components/form/PriorityForm/PriorityForm';
import { IComponent } from 'store/slices/blockPriority/interfaces';

const componentsData = {
  [B2CBlockPriority.slider]: {
    link: 'ru/slider',
    name: mainTourismHeaders.slider,
  },
  [B2CBlockPriority.places]: {
    link: '',
    name: mainTourismHeaders.places,
    children: [
      { name: mainTourismHeaders.resorts, link: 'resorts' },
      { name: mainTourismHeaders.attractions, link: 'attractions' },
    ],
  },
  [B2CBlockPriority.events]: {
    link: 'events',
    name: mainTourismHeaders.events,
  },
  [B2CBlockPriority.tourists_routes]: {
    link: 'routes',
    name: mainTourismHeaders.touristRoutes,
  },
  [B2CBlockPriority.map]: {
    link: '',
    name: mainTourismHeaders.map,
  },
  [B2CBlockPriority.news]: {
    link: '',
    name: mainTourismHeaders.news,
  },
  [B2CBlockPriority.articles]: {
    link: 'articles',
    name: mainTourismHeaders.articles,
  },
};
const serializeComponents = (data: IComponent[]) => {
  return data.filter(value => {
    return (
      value.name === B2CBlockPriority.slider ||
      value.name === B2CBlockPriority.places ||
      value.name === B2CBlockPriority.events ||
      value.name === B2CBlockPriority.tourists_routes ||
      value.name === B2CBlockPriority.map ||
      value.name === B2CBlockPriority.news ||
      value.name === B2CBlockPriority.articles
    );
  });
};
export const MainTourism = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.blockPriority);

  const loadData = () => {
    dispatch(getPriority('b2c'))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.mainTourism}>
      {isLoading ? (
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        <PriorityForm
          initialValues={serializeComponents(state.components)}
          title='Компоненты B2C'
          data={componentsData}
          type='b2c'
        />
      )}
    </MainLayout>
  );
};
