import { RespondsInvestInfrastructureTypesOfPagesEnum } from 'store/slices/responds/interfaces';
import { formatPhoneNumber } from 'utils/format';

export const selectNameFromObject = itemData => {
  if (itemData.name) {
    return `${itemData.name}`;
  } else {
    if (itemData.lastName !== null) {
      return `${itemData.lastName} ${itemData.firstName}`;
    }
    return `${itemData.firstName}`;
  }
};

export const selectPageFromObject = itemData => {
  if (
    itemData.fromPage === RespondsInvestInfrastructureTypesOfPagesEnum.mainPage
  ) {
    return `С главной страницы`;
  }
  if (
    itemData.fromPage ===
    RespondsInvestInfrastructureTypesOfPagesEnum.readySolutions
  ) {
    return `Инвестиционные предложения`;
  } else {
    return `${itemData.investInfrastructure.name}`;
  }
};

export const mappedNameFromObject = itemData => {
  if ('firstName' in itemData || 'name' in itemData) {
    return selectNameFromObject(itemData);
  } else if ('userData' in itemData && itemData.userData !== null) {
    return selectNameFromObject(itemData.userData);
  } else {
    return `Пользователь не зарегистрирован`;
  }
};

export const selectPhoneFromObject = itemData => {
  if ('phone' in itemData) {
    return formatPhoneNumber(itemData.phone);
  } else if (
    'userData' in itemData &&
    itemData.userData !== null &&
    itemData.userData.phone !== null
  ) {
    return formatPhoneNumber(itemData.userData.phone);
  } else {
    return `-`;
  }
};
