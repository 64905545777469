// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FAQList-module__collapse--rl1Be .ant-collapse-header {\n  align-items: center!important;\n  padding: 12px 16px!important;\n}\n.FAQList-module__collapse--rl1Be .ant-collapse-content-box {\n  padding: 16px!important;\n}\n.FAQList-module__collapse--rl1Be .ant-collapse-header-text {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  overflow-wrap: break-word;\n}\n.FAQList-module__collapse--rl1Be .FAQList-module__category--X9PRf {\n  min-width: 120px;\n}\n@media (max-width: 768px) {\n  .FAQList-module__collapse--rl1Be .FAQList-module__category--X9PRf {\n    min-width: 80px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/FAQ/components/FAQList/FAQList.module.less"],"names":[],"mappings":"AAAA;EAEI,6BAAA;EACA,4BAAA;AAAJ;AAHA;EAMI,uBAAA;AAAJ;AANA;EASI,uBAAA;EACA,gBAAA;EACA,yBAAA;AAAJ;AAXA;EAcI,gBAAA;AAAJ;AAEE;EAAA;IAEI,eAAA;EAAJ;AACF","sourcesContent":[".collapse {\n  :global(.ant-collapse-header){\n    align-items: center!important;\n    padding: 12px 16px!important;\n  }\n  :global(.ant-collapse-content-box){\n    padding: 16px!important;\n  }\n  :global(.ant-collapse-header-text){\n    text-overflow: ellipsis;\n    overflow: hidden;\n    overflow-wrap: break-word;\n  }\n  .category{\n    min-width: 120px;\n  }\n  @media (max-width: 768px) {\n    .category{\n      min-width: 80px;\n    }\n  } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": "FAQList-module__collapse--rl1Be",
	"category": "FAQList-module__category--X9PRf"
};
export default ___CSS_LOADER_EXPORT___;
