import { createSlice } from '@reduxjs/toolkit';

import { IPriorityIndustryState } from './interfaces';
import { loadPriorityIndustry, savePriorityIndustry } from './actions';

export const priorityIndustrySlice = createSlice({
  name: 'priorityIndustry',
  initialState: {
    item: null,
  } as IPriorityIndustryState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPriorityIndustry.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
    builder.addCase(savePriorityIndustry.fulfilled, (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    });
  },
});
