import { notification } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

import { MainLayout } from 'components/Layout/MainLayout';
import { useAppDispatch } from 'store';
import {
  saveRegulationsItem,
  saveRegulationsItemDraft,
} from 'store/slices/regulations/actions';
import {
  RegulationsItem,
  RegulationsItemRequest,
} from 'store/slices/regulations/interfaces';
import { DeepPartial } from 'types/utils';

import { RegulationsForm } from './components/RegulationsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (values: RegulationsItemRequest) => {
    setIsLoading(true);

    dispatch(saveRegulationsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate('/regulations');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveAsDraft = (values: DeepPartial<RegulationsItem>) => {
    setIsLoading(true);

    dispatch(saveRegulationsItemDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно создана',
        });
        navigate('/regulations');
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <MainLayout pageTitle='Создание'>
      <RegulationsForm
        onSubmit={onSubmit}
        isDraft
        onSaveAsDraft={onSaveAsDraft}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};
