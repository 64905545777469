import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { ISupportMeasuresItemResponse } from 'store/slices/supportMeasures/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';

interface IProps {
  data: ISupportMeasuresItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: ISupportMeasuresItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (text, item) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/supportMeasures/${item.id}/edit`}
          >
            <div>{(text && text.trim()) || 'Без названия'}</div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '25%',
      title: 'Инициатор',
      dataIndex: 'initiator',
      render: (_: unknown, { initiatorData }: ISupportMeasuresItemResponse) => (
        <Typography>{initiatorData?.name || 'Не указано'}</Typography>
      ),
    },
    {
      key: 3,
      width: '20%',
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 4,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: ISupportMeasuresItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
