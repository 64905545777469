// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvestInfrastructureLocation-module__alert--G07OP {\n  width: 100%;\n  margin-top: 24px;\n}\n.InvestInfrastructureLocation-module__sectionTitle--UV2u7 {\n  color: #262626;\n  font-weight: 500;\n}\n.InvestInfrastructureLocation-module__addCircuit--Nq1OO {\n  margin-top: 24px;\n  justify-content: space-between;\n}\n.InvestInfrastructureLocation-module__addCircuitBtn--pZ1zb {\n  margin-left: -3px;\n}\n.InvestInfrastructureLocation-module__circuitMap--baci_ {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/InvestmentInfrastructures/components/InvestInfrastructureLocation/InvestInfrastructureLocation.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AAEA;EACE,cAAA;EACA,gBAAA;AAAF;AAGA;EACE,gBAAA;EACA,8BAAA;AADF;AAIA;EACE,iBAAA;AAFF;AAKA;EACE,gBAAA;AAHF","sourcesContent":[".alert {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.sectionTitle {\n  color: #262626;\n  font-weight: 500;\n}\n\n.addCircuit {\n  margin-top: 24px;\n  justify-content: space-between;\n}\n\n.addCircuitBtn {\n  margin-left: -3px;\n}\n\n.circuitMap {\n  margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "InvestInfrastructureLocation-module__alert--G07OP",
	"sectionTitle": "InvestInfrastructureLocation-module__sectionTitle--UV2u7",
	"addCircuit": "InvestInfrastructureLocation-module__addCircuit--Nq1OO",
	"addCircuitBtn": "InvestInfrastructureLocation-module__addCircuitBtn--pZ1zb",
	"circuitMap": "InvestInfrastructureLocation-module__circuitMap--baci_"
};
export default ___CSS_LOADER_EXPORT___;
