import React, { useState } from 'react';
import { notification, Skeleton, Typography } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { PageTitles } from 'constants/pageTitles';
import { omit } from 'utils/helpers';
import {
  loadRespondsList,
  deleteRespondsItem,
} from 'store/slices/responds/actions';
import {
  RespondsQueryForTaxBenefitsEnum,
  RespondsTypesEnum,
  TRespondsItemData,
  TRespondsTypes,
} from 'store/slices/responds/interfaces';
import { mappedNameFromObject } from 'components/utils';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const queryValues = omit(query, 'type');
  const { list, total, limit, loading } = useAppSelector(
    state => state.responds
  );
  const type = query.type as TRespondsTypes;
  const mappedList = [
    ...list.map(item => {
      if ('name' in item) {
        const firstName = item.name;
        return {
          ...item,
          type,
          firstName,
        };
      }
      return {
        ...item,
        type,
      };
    }),
  ];

  const loadResponds = () => {
    if (type === RespondsTypesEnum.taxBenefitsResponds) {
      dispatch(
        loadRespondsList({
          type,
          fromPage: RespondsQueryForTaxBenefitsEnum.cabinet,
          ...queryValues,
          sort,
        })
      );
    } else if (type === RespondsTypesEnum.taxBenefitsCommonResponds) {
      dispatch(
        loadRespondsList({
          type,
          fromPage: RespondsQueryForTaxBenefitsEnum.portal,
          ...queryValues,
          sort,
        })
      );
    } else if (type === undefined) {
      dispatch(
        loadRespondsList({
          type: RespondsTypesEnum.investInfrastructureResponds,
          ...queryValues,
          sort,
        })
      );
    } else {
      dispatch(
        loadRespondsList({
          type,
          ...queryValues,
          sort,
        })
      );
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<TRespondsItemData | null>(
    null
  );
  const [isLoading, setIsLoading] = React.useState(false);

  useDeepEffect(() => {
    loadResponds();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const handleRemove = async id => {
    setIsLoading(true);
    await dispatch(deleteRespondsItem({ id, type }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Заявка успешно удалена',
        });
        loadResponds();
        setIsLoading(false);
        modalCloseHandler();
      });
  };

  const getActions = item => [
    {
      key: 'view',
      label: (
        <Link to={`/applicationsForm/${item.id}?type=${item.type}`}>
          Перейти в карточку
        </Link>
      ),
    },
    {
      key: 'delete',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        setModalIsOpen(true);
        setCurrentItem(item);
      },
    },
  ];

  React.useEffect(() => {
    if (!search) {
      const newUrl = `${pathname}?type=investInfrastructureResponds`;
      navigate(newUrl, { replace: true });
    }
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.applicationsForm} aside={<Filters />}>
      <Content>
        {!loading ? (
          <ListTable
            data={mappedList}
            total={total}
            getActions={getActions}
            limit={limit}
            setSort={setSort}
          />
        ) : (
          <Skeleton paragraph={{ rows: 4 }} active title loading={loading} />
        )}
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление заявки'
              okText='Удалить'
              text={
                <div>
                  Вы хотите удалить заявку{' '}
                  <Typography.Text strong>
                    {type === RespondsTypesEnum.mobileResponds &&
                    'email' in currentItem
                      ? currentItem?.email
                      : mappedNameFromObject(currentItem)}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={() => handleRemove(currentItem?.id)}
              okButtonProps={{ danger: true, loading: isLoading }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
