import { applicationsTypesNameHash } from 'types/applications';

export const mapValuesToForm = values => {
  return {
    ...values,
    applicationName: applicationsTypesNameHash[values.type],
    name: values.name || 'Не указано',
    region: values.region?.name || 'Не указано',
    territory: values.territory || 'Не указано',
    status: {
      value: values.status,
      label: applicationsTypesNameHash[values.status],
    },
  };
};
