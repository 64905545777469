import React from 'react';
import { Layout, Row, Typography } from 'antd';

import { useAppSelector } from 'store';
import { formatPhoneNumber } from 'utils/format';
import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import { contactIconsHash } from 'constants/contacts';
import { contactsFormat } from 'utils/contacts';

import styles from './Contacts.module.less';

export const Contacts = () => {
  const {
    item: { phone, email },
  } = useAppSelector(state => state.business);

  return (
    <Layout.Content style={{ background: '#fff', padding: '24px' }}>
      <Typography.Title level={5} style={{ marginBottom: '16px' }}>
        Контакты
      </Typography.Title>
      {phone ? (
        <Row style={{ marginBottom: '8px' }}>
          <Typography.Link
            href={contactsFormat({
              value: phone,
              type: DefaultContactOptionsTypes.Phone,
            })}
          >
            <span className={styles.contactIconPhone}>
              {contactIconsHash[DefaultContactOptionsTypes.Phone]}
            </span>
            <span className={styles.contact}>{formatPhoneNumber(phone)}</span>
          </Typography.Link>
        </Row>
      ) : null}
      {email ? (
        <Row>
          <Typography.Link href={`mailto:${email}`} target='_blank'>
            <span className={styles.contactIcon}>
              {contactIconsHash[DefaultContactOptionsTypes.Email]}
            </span>
            <span className={styles.contact}>{email}</span>
          </Typography.Link>
        </Row>
      ) : null}
    </Layout.Content>
  );
};
