import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Col, Row } from 'antd';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { sortPrioritySchema } from '../../schemas/sortPrioritySchema';
import { InputField } from '../form/base/InputField';
import { FormActions } from '../Layout/FormActions/FormActions';
import { MainLayout } from '../Layout/MainLayout';

export interface IFormProps<T> {
  values: T;
  sortPriority: number | null;
}

type IProps<T extends FieldValues = FieldValues> = {
  initialValues: IFormProps<T> | null;
  onSubmit: (values: DefaultValues<T> | IFormProps<T>) => void;
  handleCancel: () => void;
  isLoading: boolean;
  select: () => JSX.Element;
};

export const SampleEditForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
  select: Select,
  handleCancel,
}) => {
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(sortPrioritySchema),
  });

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  const title = initialValues ? 'Редактирование' : 'Создание';

  return (
    <MainLayout pageTitle={title}>
      <FormProvider {...methods}>
        <form
          className='ant-form ant-form-vertical'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Content>
            <Row>
              <Col span={24}>
                <Select />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <InputField
                  type='number'
                  name='sortPriority'
                  label='Сортировка'
                  placeholder='Введите число'
                  required
                />
              </Col>
            </Row>
            <FormActions>
              <Button onClick={handleCancel} disabled={isLoading}>
                Отмена
              </Button>
              <Button
                disabled={isLoading}
                loading={isLoading}
                type='primary'
                htmlType='submit'
              >
                Сохранить
              </Button>
            </FormActions>
          </Content>
        </form>
      </FormProvider>
    </MainLayout>
  );
};
