import React, { useEffect, useState } from 'react';
import { Collapse, Typography } from 'antd';

import { loadOpeningInfo } from 'store/slices/openingInfo/actions';
import { MainLayout } from 'components/Layout/MainLayout';
import { PageTitles } from 'constants/pageTitles';
import { openingInfoHeaders, OpeningInfoKind } from 'constants/openingInfo';
import { useAppDispatch, useAppSelector } from 'store';
import { prepareContent } from 'components/form/Content/utils';

import { Chapters } from './components/Chapters/Chapters';
import { Annual } from './components/Annual/Annual';
import { Strategies } from './components/Strategies/Strategies';
import { Conditions } from './components/Conditions/Conditions';
import { Landmark } from './components/Landmark/Landmark';

export const OpeningInfo = () => {
  const [activeCollapseTabs, setActiveCollapseTabs] = useState(
    Object.values(OpeningInfoKind)
  );

  const { description } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadOpeningInfo());
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.openingInfo} aside={<div />}>
      <Collapse
        bordered={false}
        expandIconPosition='end'
        onChange={(value: string[]) =>
          setActiveCollapseTabs(value as Array<OpeningInfoKind>)
        }
        activeKey={activeCollapseTabs}
      >
        <Collapse.Panel
          key={OpeningInfoKind.Chapter}
          header={
            <Typography.Text style={{ fontSize: 16 }}>
              {openingInfoHeaders.chapter}
            </Typography.Text>
          }
        >
          <Chapters />
        </Collapse.Panel>
        <Collapse.Panel
          key={OpeningInfoKind.Strategy}
          header={
            <Typography.Text style={{ fontSize: 16 }}>
              {openingInfoHeaders.strategy}
            </Typography.Text>
          }
        >
          <Strategies />
        </Collapse.Panel>
        <Collapse.Panel
          key={OpeningInfoKind.Annual}
          header={
            <Typography.Text style={{ fontSize: 16 }}>
              {openingInfoHeaders.annual}
            </Typography.Text>
          }
        >
          <Annual
            initialValues={{
              content: prepareContent(
                description ? [{ type: 'text', text: description }] : []
              ),
            }}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={OpeningInfoKind.Landmark}
          header={
            <Typography.Text style={{ fontSize: 16 }}>
              {openingInfoHeaders.landmark}
            </Typography.Text>
          }
        >
          <Landmark />
        </Collapse.Panel>
        <Collapse.Panel
          key={OpeningInfoKind.Conditions}
          header={
            <Typography.Text style={{ fontSize: 16 }}>
              {openingInfoHeaders.conditions}
            </Typography.Text>
          }
        >
          <Conditions />
        </Collapse.Panel>
      </Collapse>
    </MainLayout>
  );
};
