import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { TApplicationsStatuses } from 'constants/applicationsStatus';

import { IApplicationsItem, TApplicationsListParams } from './interfaces';

type TListResponse = {
  data: IApplicationsItem[];
  total: number;
};

export const loadApplicationsList = createAsyncThunk<
  TListResponse,
  TApplicationsListParams,
  { state: RootState }
>('applications/loadList', async (params, { getState }) => {
  const { limit } = getState().applications;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.formApplications.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadApplicationsItem = createAsyncThunk<IApplicationsItem, string>(
  'applications/loadItem',
  async id => {
    const { data } = await apiClient.get<IApplicationsItem>(
      getUrlWithParams(urls.api.formApplications.getOne, {
        id,
      })
    );
    return data;
  }
);

export const updateApplicationsItemStatus = createAsyncThunk<
  unknown,
  { id: number; status: TApplicationsStatuses }
>('applications/updateItemStatus', async ({ id, status }) => {
  const { data } = await apiClient.patch(
    getUrlWithParams(urls.api.formApplications.updateApplicationStatus.patch, {
      id,
    }),
    {
      status,
    }
  );
  return data;
});

export const removeApplicationsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: string;
  }
>('applications/deleteItem', async ({ id }) => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.formApplications.delete, { id }));
  return data;
});
