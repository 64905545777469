import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICardItem } from '../../../../store/slices/b2bCard/interfaces';
import { cardValidationSchema } from './validation';
import { formatterFieldCount } from '../../../../utils/formatterFieldCount';
import { InputField } from '../../../../components/form/base/InputField';
import { TextAreaField } from '../../../../components/form/base/TextAreaField';
import { FormActions } from '../../../../components/Layout/FormActions/FormActions';

interface IProps {
  isLoading: boolean;
  initialValues: ICardItem | null;
  onSubmit: (values: ICardItem) => void;
}

export const HelpCardForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
}) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(cardValidationSchema),
  });
  const handleCancel = () => {
    navigate('/mainBusiness/help');
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Card title='Общая информация'>
          <Row>
            <Col span={24}>
              <InputField
                name='title'
                label='Заголовок'
                placeholder='Введите заголовок'
                minLength={1}
                maxLength={60}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaField
                name='description'
                label='Описание'
                placeholder='Введите описание'
                minLength={1}
                maxLength={255}
                rows={2}
                showCount={{
                  formatter: formatterFieldCount,
                }}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                type='number'
                name='sortPriority'
                label='Сортировка'
                placeholder='Введите число'
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Сохранить
            </Button>
          </FormActions>
        </Card>
      </form>
    </FormProvider>
  );
};
