import React, { useState } from 'react';
import { Col, Collapse, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { MainTourismKind } from 'constants/mainTourism';
import { ExtraArrowMenu } from 'components/ExtraArrowMenu';

import styles from '../styles/index.module.less';

interface IProps {
  handleMove: (index: number, step: number) => void;
  index: number;
  count: number;
  list: { name: string; link: string }[];
  name: string;
}

export const CollapsePriority: React.FC<IProps> = ({
  name,
  handleMove,
  index,
  count,
  list,
}) => {
  const [activeCollapseTabs, setActiveCollapseTabs] = useState([]);

  return (
    <Collapse
      bordered={false}
      expandIconPosition='end'
      onChange={(value: string[]) =>
        setActiveCollapseTabs(value as Array<MainTourismKind>)
      }
      activeKey={activeCollapseTabs}
      className={styles.collapse}
    >
      <Collapse.Panel
        key='panel'
        header={
          <Typography.Text style={{ fontWeight: 600 }}>{name}</Typography.Text>
        }
        extra={
          <ExtraArrowMenu index={index} count={count} onMove={handleMove} />
        }
      >
        {list.map((item, index) => {
          return (
            <Row key={index} style={{ padding: 12 }}>
              <Col flex={1}>
                <Link to={item.link}>
                  <Typography className={styles.collapse__text}>
                    {item.name}
                  </Typography>
                </Link>
              </Col>
            </Row>
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};
