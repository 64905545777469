import * as Yup from 'yup';

import { locationSchema } from 'utils/yup';
import { mapAddress, mapSelect, mapValuesToAddress } from 'utils/mappings';
import { IInfrastructuresItem } from 'store/slices/infrastructures/interfaces';
import { InfrastructureCategories } from 'constants/infrastructuresCategories';
import { LangEnum, LangEnumKeys } from 'constants/lang';

const mapCategoryToForm = (category: string) => {
  return {
    label: InfrastructureCategories[category],
    value: category,
  };
};

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    address: values.address ? mapValuesToAddress(values.address) : {},
    category: values.category ? mapCategoryToForm(values.category) : null,
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 255 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  category: Yup.mixed().required(),
  address: locationSchema,
});

export const mapValues = (values): IInfrastructuresItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    category: mapSelect(values.category) as number,
    address: mapAddress(values.address),
  };
};
