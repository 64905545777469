// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlaceSessions-module__sessionContainer--lLNlm {\n  gap: 14px;\n}\n.PlaceSessions-module__sessionContainer--lLNlm.PlaceSessions-module__mobileWidth--HwaWX {\n  margin: 0 0 16px 0;\n  border-bottom: 1px solid #D9D9D9;\n  gap: 0;\n}\n.PlaceSessions-module__datePicker--IpiXm.PlaceSessions-module__mobileWidth--HwaWX {\n  width: 100%;\n}\n.PlaceSessions-module__timePickerFieldContainer--gH4tB.PlaceSessions-module__mobileWidth--HwaWX {\n  width: 100%;\n  margin-bottom: 24px;\n}\n.PlaceSessions-module__timePickerFieldContainer--gH4tB.PlaceSessions-module__error--OhVU_ {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Places/components/PlaceSessions/PlaceSessions.module.less"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AACE;EACE,kBAAA;EACA,gCAAA;EACA,MAAA;AACJ;AAIE;EACE,WAAA;AAFJ;AAOE;EACE,WAAA;EACA,mBAAA;AALJ;AAQE;EACE,gBAAA;AANJ","sourcesContent":[".sessionContainer {\n  gap: 14px;\n\n  &.mobileWidth {\n    margin: 0 0 16px 0;\n    border-bottom: 1px solid #D9D9D9;\n    gap: 0;\n  }\n}\n\n.datePicker {\n  &.mobileWidth {\n    width: 100%;\n  }\n}\n\n.timePickerFieldContainer {\n  &.mobileWidth {\n    width: 100%;\n    margin-bottom: 24px;\n  }\n\n  &.error {\n    margin-bottom: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessionContainer": "PlaceSessions-module__sessionContainer--lLNlm",
	"mobileWidth": "PlaceSessions-module__mobileWidth--HwaWX",
	"datePicker": "PlaceSessions-module__datePicker--IpiXm",
	"timePickerFieldContainer": "PlaceSessions-module__timePickerFieldContainer--gH4tB",
	"error": "PlaceSessions-module__error--OhVU_"
};
export default ___CSS_LOADER_EXPORT___;
