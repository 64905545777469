// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadSingleFile-module__item--Y6jgx .ant-form-item-tooltip {\n  color: #262626 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/UploadSingleFile/UploadSingleFile.module.less"],"names":[],"mappings":"AAAA;EAGG,yBAAA;AADH","sourcesContent":[".item {\n\t:global {\n\t\t.ant-form-item-tooltip {\n\t\t\tcolor: #262626 !important;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "UploadSingleFile-module__item--Y6jgx"
};
export default ___CSS_LOADER_EXPORT___;
