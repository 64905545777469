import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiClient } from '../../../utils/http';
import { getUrlWithParams, urls } from '../../api';
import { IAlert, IAlertResponse, TAlertRequest } from './interfaces';

export const getAlerts = createAsyncThunk<
  IAlertResponse,
  { lang: string; type: string }
>('alert/getAlerts', async params => {
  const { data } = await apiClient.get<IAlertResponse>(
    getUrlWithParams(urls.api.alert.get, params)
  );
  return data;
});

export const getAlert = createAsyncThunk<IAlert, string>(
  'alert/getAlert',
  async id => {
    const { data } = await apiClient.get<IAlert>(
      getUrlWithParams(urls.api.alert.getOne, { id })
    );
    return data;
  }
);

export const setAlert = createAsyncThunk<IAlert, TAlertRequest>(
  'alert/setAlert',
  async ({ id, ...params }) => {
    const { data } = await apiClient.patch<IAlert>(
      getUrlWithParams(urls.api.alert.patch, { id }),
      params
    );
    return data;
  }
);
