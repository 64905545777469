export enum MobileRespondsThemeEnum {
  Refunds = 'refunds',
  Claim = 'claim',
  Partnership = 'partnership',
  Other = 'other',
}

export const MobileRespondsThemeNameHash = {
  [MobileRespondsThemeEnum.Refunds]: 'Возвраты',
  [MobileRespondsThemeEnum.Claim]: 'Жалоба / Претензия',
  [MobileRespondsThemeEnum.Partnership]: 'Партнерство',
  [MobileRespondsThemeEnum.Other]: 'Другое',
};

export enum MobileRespondsResortEnum {
  Arhyz = 'arhyz',
  Armhi = 'armhi',
  Veduchi = 'veduchi',
  KaspiyskiyKlaster = 'kaspiyskiyKlaster',
  Mamison = 'mamison',
  Tsori = 'tsori',
  Elbrus = 'elbrus',
  AoKavkaz = 'aoKavkaz',
}

export const MobileRespondsResortNameHash = {
  [MobileRespondsResortEnum.Arhyz]: 'Архыз',
  [MobileRespondsResortEnum.Armhi]: 'Армхи',
  [MobileRespondsResortEnum.Veduchi]: 'Ведучи',
  [MobileRespondsResortEnum.KaspiyskiyKlaster]: 'Каспийский прибрежный кластер',
  [MobileRespondsResortEnum.Mamison]: 'Мамисон',
  [MobileRespondsResortEnum.Tsori]: 'Цори',
  [MobileRespondsResortEnum.Elbrus]: 'Эльбрус',
  [MobileRespondsResortEnum.AoKavkaz]: 'АО Кавказ.РФ',
};
