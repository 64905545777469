import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IFilterReports,
  TResponseReports,
  TResponseReport,
  IRequestUpdateReport,
} from './interfaces';

export const loadReportsList = createAsyncThunk<
  TResponseReports,
  IFilterReports,
  { state: RootState }
>('reports/loadList', async ({ sort, ...params }, { getState }) => {
  const { limit } = getState().reports;
  const { data } = await apiClient.get<TResponseReports>(urls.api.reports.get, {
    params: {
      limit,
      sort: sort || '-createdAt',
      ...params,
    },
  });
  return data;
});

export const loadReportItem = createAsyncThunk<TResponseReport, string>(
  'reports/loadItem',
  async id => {
    const { data } = await apiClient.get<TResponseReport>(
      getUrlWithParams(urls.api.reports.getOne, {
        id,
      })
    );
    return data;
  }
);

export const removeReportItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  string
>('reports/removeItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.reports.delete, { id }));
  return data;
});

export const updateReportItem = createAsyncThunk<
  null,
  IRequestUpdateReport,
  { state: RootState }
>('reports/updateItem', async (params, { getState }) => {
  const { item } = getState().reports;
  const { data } = await apiClient.patch<null>(
    getUrlWithParams(urls.api.reports.patch, {
      id: item.id,
    }),
    {
      ...params,
    }
  );
  return data;
});
