import { createSlice } from '@reduxjs/toolkit';

import { IAboutRegionState } from './interfaces';
import { getAboutRegion } from './actions';

export const aboutRegionSlice = createSlice({
  name: 'aboutRegion',
  initialState: {
    aboutRegion: {},
    isLoading: false,
  } as IAboutRegionState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAboutRegion.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAboutRegion.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getAboutRegion.fulfilled, (state, { payload }) => {
      state.aboutRegion = payload;
      state.isLoading = false;
    });
  },
});
