// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadIcon-module__tooltip--w_e4a .ant-form-item-tooltip {\n  color: var(--ant-error-color) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Preferences/components/UploadImage/UploadIcon.module.less"],"names":[],"mappings":"AAAA;EAGM,wCAAA;AADN","sourcesContent":[".tooltip {\n  :global {\n    .ant-form-item-tooltip {\n      color: var(--ant-error-color) !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "UploadIcon-module__tooltip--w_e4a"
};
export default ___CSS_LOADER_EXPORT___;
