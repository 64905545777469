// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoForm-module__info--Nt6hf .ant-card-head {\n  border-bottom: none;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PriorityIndustry/components/PriorityIndustryForm/components/InfoForm/InfoForm.module.less"],"names":[],"mappings":"AAAA;EAGG,mBAAA;AADH","sourcesContent":[".info {\n\t:global {\n\t\t.ant-card-head {\n\t\t\tborder-bottom: none;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "InfoForm-module__info--Nt6hf"
};
export default ___CSS_LOADER_EXPORT___;
