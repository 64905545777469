import * as Yup from 'yup';

import { MAX_NUMBER, MIN_NUMBER } from 'constants/fields';

export const validationSchema = Yup.object().shape({
  enum: Yup.mixed().required(),
  icon: Yup.mixed().required(),
  name: Yup.string()
    .required()
    .trim()
    .min(1, 'Введите от 1 до 255 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  videoUrl: Yup.string().url().required(),
  square: Yup.number().min(MIN_NUMBER).max(MAX_NUMBER).required(),
  population: Yup.number().min(MIN_NUMBER).max(MAX_NUMBER).required(),
  vrp: Yup.number().min(MIN_NUMBER).max(MAX_NUMBER).required(),
  buttonLabel: Yup.string().trim().required(),
  buttonUrl: Yup.string().url().required(),
});
