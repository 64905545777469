import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import {
  IResortDraftItem,
  IResortItem,
  IResortItemResponse,
} from './interfaces';

type TListResponse = { data: IResortItemResponse[]; total: number };

export const loadResortsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
    isSortPriority?: boolean;
  },
  { state: RootState }
>('resorts/loadList', async (params, { getState }) => {
  params.limit = params.limit || getState().resorts.limit;
  const { data } = await apiClient.get<TListResponse>(urls.api.resorts.get, {
    params: params,
  });
  return data;
});

export const loadResortItem = createAsyncThunk<IResortItemResponse, string>(
  'resort/loadItem',
  async id => {
    const { data } = await apiClient.get<IResortItemResponse>(
      getUrlWithParams(urls.api.resorts.getOne, {
        id,
      })
    );
    return data;
  }
);

export const bulkUpdateResortsItem = createAsyncThunk<
  IResortItem[],
  Partial<IResortItem>[]
>('resort/bulkUpdateItems', async params => {
  const { data } = await apiClient.patch<IResortItem[]>(
    urls.api.resorts.bulkPatch,
    params
  );
  return data;
});
export const saveResortItem = createAsyncThunk<
  IResortItem,
  Partial<IResortItem>
>('resort/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = params.gallery && (await uploadImageArray(params.gallery));
  const content = params.content && (await uploadContent(params.content));
  const attractionsWithNullPriority = params.attractions?.some(
    item => item.sortPriority === null
  );
  const hotelsWithNullPriority = params.hotels?.some(
    item => item.sortPriority === null
  );
  const restaurantsWithNullPriority = params.restaurants?.some(
    item => item.sortPriority === null
  );

  if (id) {
    const { data } = await apiClient.patch<IResortItem>(
      getUrlWithParams(urls.api.resorts.patch, { id }),
      {
        ...params,
        attractions: attractionsWithNullPriority
          ? undefined
          : params.attractions,
        hotels: hotelsWithNullPriority ? undefined : params.hotels,
        restaurants: restaurantsWithNullPriority
          ? undefined
          : params.restaurants,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IResortItem>(urls.api.resorts.post, {
      ...params,
      image,
      heroImage,
      gallery,
      content,
    });
    return data;
  }
});

export const saveResortItemAsDraft = createAsyncThunk<
  IResortDraftItem,
  IResortDraftItem
>('resort/saveItem', async params => {
  const { id, ...requestParams } = params;
  const image = await uploadImage(requestParams.image);
  const heroImage = await uploadImage(requestParams.heroImage);
  const gallery = await uploadImageArray(requestParams.gallery);
  const content = await uploadContent(requestParams.content);
  const attractionsWithNullPriority = params.attractions?.some(
    item => item.sortPriority === null
  );
  const hotelsWithNullPriority = params.hotels?.some(
    item => item.sortPriority === null
  );
  const restaurantsWithNullPriority = params.restaurants?.some(
    item => item.sortPriority === null
  );

  if (id) {
    const { data } = await apiClient.patch<IResortDraftItem>(
      getUrlWithParams(urls.api.resorts.draft.patch, { id }),
      {
        ...requestParams,
        attractions: attractionsWithNullPriority
          ? undefined
          : params.attractions,
        hotels: hotelsWithNullPriority ? undefined : params.hotels,
        restaurants: restaurantsWithNullPriority
          ? undefined
          : params.restaurants,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IResortDraftItem>(
      urls.api.resorts.draft.post,
      {
        ...requestParams,
        attractions: attractionsWithNullPriority
          ? undefined
          : params.attractions,
        hotels: hotelsWithNullPriority ? undefined : params.hotels,
        restaurants: restaurantsWithNullPriority
          ? undefined
          : params.restaurants,
        image,
        heroImage,
        gallery,
        content,
      }
    );
    return data;
  }
});

export const setPublishResort = createAsyncThunk<void, string>(
  'resorts/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.resorts.publish.patch, { id })
    );
  }
);

export const setUnPublishResort = createAsyncThunk<void, string>(
  'resorts/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.resorts.unPublish.patch, { id })
    );
  }
);

export const removeResortItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: number;
  }
>('resort/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.resorts.delete, { id: params.id }));
  return data;
});
