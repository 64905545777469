import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router';
import { GlobalOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppSelector } from '../../../../store';
import { IHistoryItem } from '../../../../store/slices/b2bHistory/interfaces';
import { InvestmentProjectsItem } from '../../../../store/slices/investmentProjects/interfaces';
import { validationSchema } from './validation';
import { useDeepEffect } from '../../../../utils/useDeepEffect';
import { LangEnumKeys } from '../../../../constants/lang';
import { InputField } from '../../../../components/form/base/InputField';
import { FormActions } from '../../../../components/Layout/FormActions/FormActions';
import { InvestProjectAsyncSelect } from '../../../../components/form/selects/InvestProjectAsyncSelect';

interface IProps {
  isLoading: boolean;
  initialValues: IHistoryItem | null;
  onSubmit: (values: IHistoryItem) => void;
}

export const HistoryItemForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  isLoading,
}) => {
  const navigate = useNavigate();
  const data = useAppSelector(state => state.b2bHistory.history);
  const methods = useForm({
    defaultValues: { ...initialValues, project: null },
    resolver: yupResolver(validationSchema),
  });
  const handleCancel = () => {
    navigate('/mainBusiness/history');
  };

  const project = methods.watch('project') as InvestmentProjectsItem;

  useDeepEffect(() => {
    if (project) {
      methods.reset({
        ...methods.getValues(),
        investVolume: project.investments,
        workPlaces: project.workPlaces,
        title: project.name,
        company: project.initiator,
        link: `https://investkavkaz.ru/invest-projects/${project.id}`,
        region: project?.region?.name ?? '',
      });
    }
  }, [project]);
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className='ant-form ant-form-vertical'
      >
        <Card title='Общая информация' bordered={false}>
          <Row>
            <Col span={24}>
              <InvestProjectAsyncSelect
                requestParams={{ lang: data.language as LangEnumKeys }}
                name='project'
                label='Инвест. проекты'
                placeholder='Выберите проект'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='title'
                label='Заголовок'
                placeholder='Введите заголовок'
                minLength={1}
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='company'
                label='Компания'
                placeholder='Введите компанию'
                minLength={1}
                maxLength={255}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='investVolume'
                label='Объем инвестиций'
                placeholder='Объем инвестиций'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='kavkazShare'
                label='Участие Кавказ.рф'
                placeholder='Участие Кавказ.рф'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='workPlaces'
                label='Рабочих мест'
                placeholder='Рабочих мест'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='region'
                label='Регион'
                placeholder='Регион'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='link'
                label='Ссылка'
                placeholder='Укажите ссылку'
                addonBefore={<GlobalOutlined />}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                type='number'
                name='sortPriority'
                label='Сортировка'
                placeholder='Введите число'
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Сохранить
            </Button>
          </FormActions>
        </Card>
      </form>
    </FormProvider>
  );
};
