import { contractExchangeStatusesNames } from './contractsStatus';

enum AdsTypesEnum {
  OFFER = 'offer',
  REQUEST = 'request',
}

export const adsTypes = {
  [AdsTypesEnum.OFFER]: 'Предложение',
  [AdsTypesEnum.REQUEST]: 'Запрос',
};

export type AdsTypes = `${AdsTypesEnum}`;

export const ADS_TYPES_OPTIONS = Object.keys(adsTypes).map(item => ({
  value: item,
  label: adsTypes[item as AdsTypes],
}));

export const ADS_TYPES_OPTIONS_FILTER = [
  { value: '', label: 'Все' },
  ...ADS_TYPES_OPTIONS,
];

export const CONTRACTS_STATUSES_OPTIONS = Object.keys(
  contractExchangeStatusesNames
).map(item => ({
  value: item,
  label: contractExchangeStatusesNames[item as AdsTypes],
}));

export const CONTRACTS_STATUSES_OPTIONS_FILTER = [
  { value: '', label: 'Все' },
  ...CONTRACTS_STATUSES_OPTIONS,
];
