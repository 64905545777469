import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUrlWithParams, urls } from 'store/api';
import { LangEnumKeys } from 'constants/lang';
import { apiClient } from 'utils/http';

import {
  IPriorityIndustryResponse,
  TPriorityIndustryRequest,
} from './interfaces';

export const loadPriorityIndustry = createAsyncThunk<
  IPriorityIndustryResponse,
  LangEnumKeys
>('priorityIndustry/loadItem', async lang => {
  const { data } = await apiClient.get<IPriorityIndustryResponse>(
    getUrlWithParams(urls.api.priorityIndustry.get, {
      lang,
    })
  );
  return data;
});

export const savePriorityIndustry = createAsyncThunk<
  TPriorityIndustryRequest,
  TPriorityIndustryRequest
>('priorityIndustry/saveItem', async params => {
  const { data } = await apiClient.put<TPriorityIndustryRequest>(
    urls.api.priorityIndustry.put,
    {
      ...params,
    }
  );
  return data;
});
