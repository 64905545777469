import React from 'react';
import { Button, Col, Collapse, Descriptions, Row, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

import { IOrganization, IUser } from 'store/slices/organizations/interfaces';
import { getUrlWithParams, urls } from 'store/api';
import { getName } from 'pages/Organizations/utils';
import { RolesNames } from 'constants/organizations';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { OrganizationTypeEnum } from 'types/organizations';
import { apiClient } from 'utils/http';
import { ListResponse } from 'types/pagination';
import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';
import { formatPhoneNumber } from 'utils/format';

import styles from './User.module.less';

interface IProps {
  user: IUser;
}

export const User: React.FC<IProps> = ({ user }) => {
  const { userData, createdAt, role } = user;
  const { firstName, lastName, secondName, id, email, phone } = userData;
  const navigate = useNavigate();
  const [activeCollapaseTabs, setActiveCollapaseTabs] = React.useState([]);
  const [userOrganizations, setUserOrganizations] = React.useState<
    ListResponse<IOrganization>
  >({ data: [], total: 0 });

  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;

  const getUserOrganizations = async () => {
    const { data } = await apiClient.get<ListResponse<IOrganization>>(
      getUrlWithParams(urls.api.organizations.getByUser, { id })
    );

    setUserOrganizations(data);
  };

  React.useEffect(() => {
    activeCollapaseTabs.length && getUserOrganizations();
  }, [activeCollapaseTabs.length]);

  const genExtra = (key: number) => (
    <Row gutter={isMobile ? [0, 16] : 16} justify={isMobile ? 'end' : 'center'}>
      <Col>
        <Button
          size='small'
          onClick={event => {
            event.stopPropagation();
            navigate(`/business/${id}`);
          }}
        >
          Подробнее
        </Button>
      </Col>
      <Col>
        <Button size='small'>
          {activeCollapaseTabs.includes(String(key)) ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )}
        </Button>
      </Col>
    </Row>
  );

  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: '',
      render: (
        _: unknown,
        { id, type, firstName, lastName, secondName, shortName }: IOrganization
      ) => (
        <Typography.Link href={`/organizations/${id}`}>
          {type === OrganizationTypeEnum.organization
            ? shortName || 'Не указано'
            : getName({ lastName, firstName, secondName }, true, true) ||
              'Не указано'}
        </Typography.Link>
      ),
    },
    {
      key: 2,
      title: 'ИНН',
      dataIndex: 'inn',
      render: inn => <Typography.Text>{inn || 'Не указано'}</Typography.Text>,
    },
    {
      key: 3,
      title: 'ОГРН/ОГРНИП',
      dataIndex: 'ogrn',
      render: ogrn => <Typography.Text>{ogrn || 'Не указано'}</Typography.Text>,
    },
    {
      key: 4,
      title: 'Роль',
      dataIndex: 'role',
      render: role => (
        <Typography.Text>{RolesNames[role] || 'Не указано'}</Typography.Text>
      ),
    },
  ];

  return (
    <Collapse
      bordered={false}
      expandIconPosition='end'
      onChange={(value: string[]) => setActiveCollapaseTabs(value)}
      activeKey={activeCollapaseTabs}
    >
      <Collapse.Panel
        showArrow={false}
        header={getName({ firstName, lastName, secondName })}
        key={id}
        extra={genExtra(id)}
        className={classNames(styles.panel, {
          [styles.panelActive]: activeCollapaseTabs.includes(String(id)),
        })}
      >
        <Descriptions
          labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
          title=''
          size='small'
          column={1}
          layout='vertical'
        >
          <Descriptions.Item
            className={styles.row}
            label='Дата и время регистрации'
          >
            {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
          </Descriptions.Item>
          <Descriptions.Item className={styles.row} label='Роль'>
            {RolesNames[role]}
          </Descriptions.Item>
          <Descriptions.Item className={styles.row} label='Телефон'>
            {formatPhoneNumber(phone) || (
              <Typography.Text type='secondary'>Не заполнено</Typography.Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            className={classNames(styles.row, styles.rowLast)}
            label='Email'
          >
            {email || (
              <Typography.Text type='secondary'>Не заполнено</Typography.Text>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Typography.Text className={styles.tableTitle} strong>
          Другие организации пользователя
        </Typography.Text>
        <CommonTable
          className={styles.table}
          bordered={false}
          columns={columns}
          scroll={{ x: 800 }}
          dataSource={userOrganizations.data}
          total={userOrganizations.total}
          limit={5}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
