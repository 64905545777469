import React from 'react';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider, FieldValues, useWatch } from 'react-hook-form';
import { PaperClipOutlined } from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty } from 'utils/helpers';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { IApplicationsItem } from 'store/slices/applications/interfaces';
import { DeepPartial } from 'types/utils';
import { Select } from 'components/form/base/Select';
import {
  applicationsStatusesNamesHash,
  TApplicationsStatuses,
} from 'constants/applicationsStatus';
import { applicationsTypesNameHash } from 'types/applications';
import { getUrlWithParams } from 'store/api';
import { FormDocuments } from 'components/FormDocuments/FormDocuments';
import { FormComments } from 'components/FormComments/FormComments';
import { downloadDocument } from 'utils/downloadFile';

import styles from './ApplicationsForm.module.less';
import { APPLICATION_URLS_BY_TYPE } from './constants';

type TProps = {
  initialValues: DeepPartial<IApplicationsItem>;
  onFinish: (status: TApplicationsStatuses) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

export const ApplicationsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'documents',
    'comments',
  ]);

  const methods = useForm({
    defaultValues: initialValues,
  });

  const [type, id] = useWatch({
    name: ['type', 'id'],
    control: methods.control,
  });

  const submit = (values: FieldValues) => onFinish(values.status.value);

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const downloadTemplate = async () => {
    const url = getUrlWithParams(APPLICATION_URLS_BY_TYPE[type] || '', {
      formId: id,
    });
    const docName = `${applicationsTypesNameHash[type]}.docx`;
    await downloadDocument(url, docName);
  };

  return (
    <FormProvider {...methods}>
      <form className='ant-form ant-form-vertical indent-top'>
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='applicationName'
                label='Название заявки'
                disabled
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField name='name' label='Название проекта' disabled />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className={styles.applicationNumber}>
              <InputField name='id' label='Номер заявки' disabled />
            </Col>
            <Col className={styles.applicationStatus}>
              <Select
                name='status'
                label='Статус заявки'
                options={[
                  ...Object.keys(applicationsStatusesNamesHash).map(item => ({
                    value: item,
                    label: applicationsStatusesNamesHash[item],
                  })),
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <InputField name='region' label='Регион' disabled />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <InputField name='territory' label='ОЭЗ' disabled />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <div className={styles.document} onClick={downloadTemplate}>
                <span>
                  <PaperClipOutlined />
                  <a>{`${applicationsTypesNameHash[type]}.docx`}</a>
                </span>
              </div>
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollpaseTabs(value as string[])}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Документы' key='documents'>
            <FormDocuments />
          </Collapse.Panel>
          <Collapse.Panel header='Комментарии' key='comments'>
            <FormComments />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            onClick={() => methods.handleSubmit(submit)()}
          >
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
