import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Typography } from 'antd';
import React from 'react';

import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';
import { TImage } from 'types/image';

import styles from './ImageInfoPopover.module.less';

type TProps = {
  image: TImage;
};

const getPopoverContent = image => {
  return (
    <div className={styles.popoverWrapper}>
      <Typography.Text strong>Автор</Typography.Text>
      <div className={styles.popoverText}>{image.author}</div>
      <Typography.Text strong>Источник</Typography.Text>
      <div className={styles.popoverText}>
        {image.source?.includes('https://') ||
        image.source?.includes('http://') ? (
          <Typography.Link href={image.source} target='_blank'>
            {image.source}
          </Typography.Link>
        ) : (
          <Typography.Text>{image.source}</Typography.Text>
        )}
      </div>
      {image.description && (
        <>
          <Typography.Text strong>Описание</Typography.Text>
          <div className={styles.popoverText}>{image.description}</div>
        </>
      )}
    </div>
  );
};

export const ImageInfoPopover: React.FC<TProps> = ({ image }) => {
  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;
  return (
    <div style={{ position: 'absolute', top: '12px', left: '12px' }}>
      <Popover
        content={getPopoverContent(image)}
        title='Информация о изображении'
        placement={isMobile ? 'topRight' : 'top'}
        overlayStyle={{
          zIndex: 1,
        }}
      >
        <Button icon={<InfoCircleOutlined />} />
      </Popover>
    </div>
  );
};
