import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';
import { uploadImage, uploadImageArray } from 'utils/upload';

import {
  CurrentResidentsItem,
  GetCurrentResidentsParams,
  ICurrentResidentsResponseItem,
} from './interfaces';

export const loadCurrentResidentsList = createAsyncThunk<
  ListResponse<ICurrentResidentsResponseItem>,
  GetCurrentResidentsParams,
  { state: RootState }
>('current-residents/loadList', async (params, { getState }) => {
  const { limit } = getState().currentResidents;
  const { data } = await apiClient.get(urls.api.currentResidents.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const saveCurrentResidentsItem = createAsyncThunk<
  CurrentResidentsItem,
  CurrentResidentsItem
>('current-residents/saveItem', async ({ id, image, gallery, ...params }) => {
  const uploadedImage = await uploadImage(image);
  const uploadedGallery = await uploadImageArray(gallery);

  if (id) {
    const { data } = await apiClient.patch(
      getUrlWithParams(urls.api.currentResidents.patch, { id }),
      {
        ...params,
        image: uploadedImage,
        gallery: uploadedGallery,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post(urls.api.currentResidents.post, {
      ...params,
      image: uploadedImage,
      gallery: uploadedGallery,
    });
    return data;
  }
});
export const saveCurrentResidentsItemDraft = createAsyncThunk<
  DeepPartial<CurrentResidentsItem>,
  DeepPartial<CurrentResidentsItem>
>(
  'current-residents/saveItemAsDraft',
  async ({ id, image, gallery, ...params }) => {
    const uploadedImage = await uploadImage(image);
    const uploadedGallery = await uploadImageArray(gallery);

    if (id) {
      const { data } = await apiClient.patch(
        getUrlWithParams(urls.api.currentResidents.draft.patch, { id }),
        {
          ...params,
          image: uploadedImage,
          gallery: uploadedGallery,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post(
        urls.api.currentResidents.draft.post,
        {
          ...params,
          image: uploadedImage,
          gallery: uploadedGallery,
        }
      );
      return data;
    }
  }
);

export const loadCurrentResidentsItem = createAsyncThunk<
  ICurrentResidentsResponseItem,
  string
>('current-residents/loadItem', async id => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.currentResidents.getOne, { id })
  );
  return data;
});

export const setPublishCurrentResident = createAsyncThunk<void, number>(
  'current-residents/publish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.currentResidents.publish.patch, { id })
    )
);

export const setUnPublishCurrentResident = createAsyncThunk<void, number>(
  'current-residents/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.currentResidents.unPublish.patch, { id })
    )
);

export const deleteCurrentResident = createAsyncThunk<void, { id: number }>(
  'current-residents/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.currentResidents.delete, { id })
    )
);
