import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash } from 'constants/status';
import { contractExchangeStatusesNames } from 'constants/contractsStatus';
import { AdsTypes, adsTypes } from 'constants/contracts';
import { IContractExchange } from 'store/slices/contractExchange/interfaces';
import { TAuthorData } from 'types/authorData';
import { OrganizationsItem } from 'types/organizations';
import { IAddress } from 'types/address';

import { getOrganizationName } from '../utils';

interface IProps {
  data: IContractExchange[];
  total: number;
  limit?: number;
  getActions: (item: IContractExchange) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      width: 120,
      title: 'Тип объявления',
      dataIndex: 'type',
      render: (_, { type, id }: { type: AdsTypes; id: string }) => (
        <Link to={`/contractExchange/${id}`}>
          <Tag style={{ marginRight: 0 }} color='blue'>
            {adsTypes[type]}
          </Tag>
        </Link>
      ),
    },
    {
      key: 2,
      title: 'Название',
      sorter: true,
      dataIndex: 'name',
      render: (_, { name, id }) => (
        <Link to={`/contractExchange/${id}`}>
          <Typography>{name || 'Без названия'}</Typography>
        </Link>
      ),
    },
    {
      key: 3,
      width: 110,
      title: 'Автор',
      sorter: true,
      dataIndex: 'creator',
      render: (
        _,
        { creatorData, id }: { creatorData: TAuthorData; id: string }
      ) => (
        <Link to={`/contractExchange/${id}`}>
          <Typography>
            {`${creatorData?.lastName} ${creatorData?.firstName} ${
              creatorData?.secondName ? creatorData.secondName : ''
            }`}
          </Typography>
        </Link>
      ),
    },
    {
      key: 4,
      width: 138,
      title: 'Организация',
      sorter: true,
      dataIndex: 'organization',
      render: (
        _,
        { organization, id }: { organization: OrganizationsItem; id: string }
      ) => (
        <Link to={`/contractExchange/${id}`}>
          <Typography>{getOrganizationName(organization)}</Typography>
        </Link>
      ),
    },
    {
      key: 5,
      width: 110,
      title: 'Субъект СКФО',
      dataIndex: 'address',
      render: (_, { address, id }: { address: IAddress; id: string }) => (
        <Link to={`/contractExchange/${id}`}>
          <Typography>
            {address?.region?.name || 'Без указания региона'}
          </Typography>
        </Link>
      ),
    },
    {
      key: 6,
      width: 130,
      title: 'Статус публикации',
      dataIndex: 'status',
      render: (_, { status, id }: { status: string; id: string }) => (
        <Link
          to={`/contractExchange/${id}`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'inherit',
          }}
        >
          <Tag style={{ marginRight: 0 }} color={statusesColorsHash[status]}>
            {contractExchangeStatusesNames[status]}
          </Tag>
        </Link>
      ),
    },
    {
      key: 7,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IContractExchange) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
