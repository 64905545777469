import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TContentBlock } from 'components/form/Content/contentTypes';
import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { MainImage } from 'components/form/MainImage/MainImage';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { Schedule } from 'components/form/Schedule/Schedule';
import { Status } from 'components/form/Status/Status';
import { Gallery } from 'components/form/Gallery/Gallery';
import { IAttractionAddress } from 'types/address';
import { TImage } from 'types/image';
import { Contacts } from 'components/form/Contacts';
import { TContact } from 'types/contact';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { TSchedule } from 'types/schedule';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty, isString } from 'utils/helpers';
import { Statuses } from 'constants/status';
import {
  IAttractionDraftItem,
  IAttractionItem,
} from 'store/slices/attractions/interfaces';
import { MAIN_IMAGE_ASPECT_RATIO, PREVIEW_ASPECT_RATIO } from 'constants/image';
import { LangEnumKeys } from 'constants/lang';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { TSEO } from 'types/seo';
import { SEO } from 'components/form/SEO/SEO';
import { useAppDispatch, useAppSelector } from 'store';
import { savePreviewData } from 'store/slices/preview/actions';
import { sourcePreview } from 'store/slices/preview/interfaces';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';

interface IFormValues extends TSEO {
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  category: number | { value: number; label: string };
  tags: number[] | { value: number; label: string }[];
  image: TImage;
  heroImage: TImage;
  content: TContentBlock[];
  gallery: TImage[];
  address: IAttractionAddress;
  workTime: TSchedule;
  contacts: TContact[];
  status: Statuses;
  isSelection: boolean;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IAttractionItem) => void;
  onSaveDraft: (values: IAttractionDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
  itemId?: number;
};

export const AttractionsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
  itemId,
}) => {
  const dispatch = useAppDispatch();
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'gallery',
    'address',
    'workTime',
    'contacts',
    'seo',
    'status',
  ]);

  const resolver = yupResolver(validationSchema);

  const methods = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const currentLanguage = methods.watch('lang.value');

  const handlePreviewClick = async () => {
    const results = await (dispatch(
      savePreviewData({
        source: sourcePreview.attractions,
        ...(itemId !== undefined && { sourceId: itemId }),
        data: methods.getValues(),
      })
    ) as Promise<{ payload: { id: number; sysName: string } }>);
    const id = results.payload.id;
    const sysName = results.payload.sysName;

    const linkToB2C =
      currentLanguage === 'en'
        ? `${b2cUrl}/en/attractions/preview/${id}/${sysName}`
        : `${b2cUrl}/attractions/preview/${id}/${sysName}`;
    window.open(linkToB2C, '_blank');
  };

  const submit = values => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), category: null, tags: [] });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='heroImage'
                label='Глав. фото'
                required
                aspectRatio={MAIN_IMAGE_ASPECT_RATIO}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Название места'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='category'
                label='Категория'
                placeholder='Выберите категорию'
                required
                requestParams={{
                  entityType: 'attractions',
                  fieldType: 'categories',
                  lang: langValue,
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='tags'
                label='Tеги'
                placeholder='Выберите теги'
                requestParams={{
                  entityType: 'attractions',
                  fieldType: 'tags',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание места' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Адрес' key='address'>
            <LocationSelector name='address' />
          </Collapse.Panel>
          <Collapse.Panel header='Режим работы' key='workTime'>
            <Schedule name='workTime' />
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <Contacts name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft ? (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          ) : null}
          <Button onClick={handlePreviewClick} disabled={isLoading}>
            Предпросмотр
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
