import { omit } from 'utils/helpers';

import { StatusesEnum, statusesNamesHash } from './status';

export const getLimitedStatuses = () => {
  const filteredStatuses = omit(statusesNamesHash, [
    StatusesEnum.ON_MODERATION,
    StatusesEnum.DECLINED,
  ]);
  return [
    { value: '', label: 'Все' },
    ...Object.keys(filteredStatuses).map(key => ({
      value: key,
      label: filteredStatuses[key],
    })),
  ];
};
