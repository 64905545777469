// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialFile-module__upload--b_Ky0 .ant-upload-list-item {\n  margin-top: 25px;\n}\n.MaterialFile-module__upload--b_Ky0 .anticon-paper-clip {\n  font-size: 20px!important;\n}\n.MaterialFile-module__upload--b_Ky0 .ant-upload-list-text-container {\n  display: none;\n}\n.MaterialFile-module__nameField--vsQRU {\n  margin-top: 24px;\n}\n.MaterialFile-module__attachedFile--mIMJr {\n  display: flex;\n  align-items: end;\n  height: 47px;\n}\n.MaterialFile-module__attachedFile--mIMJr svg {\n  width: 20px;\n  height: 20px;\n  color: #8C8C8C;\n}\n.MaterialFile-module__attachedFileName--LSyIz {\n  padding: 0 8px;\n  width: calc(100% - 20px);\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/AddMaterial/components/MaterialFile/MaterialFile.module.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAKI,yBAAA;AAAJ;AALA;EAQI,aAAA;AAAJ;AAGA;EACE,gBAAA;AADF;AAIA;EACE,aAAA;EACA,gBAAA;EACA,YAAA;AAFF;AADA;EAMI,WAAA;EACA,YAAA;EACA,cAAA;AAFJ;AAMA;EACE,cAAA;EACA,wBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAJF","sourcesContent":[".upload {\n  :global(.ant-upload-list-item){\n    margin-top: 25px;\n  }\n  :global(.anticon-paper-clip) {\n    font-size: 20px!important;\n  }\n  :global(.ant-upload-list-text-container) {\n    display: none;\n  }\n}\n.nameField {\n  margin-top: 24px;\n}\n\n.attachedFile {\n  display: flex;\n  align-items: end;\n  height: 47px;\n\n  svg {\n    width: 20px;\n    height: 20px;\n    color: #8C8C8C;\n  }\n}\n\n.attachedFileName {\n  padding: 0 8px;\n  width: calc(100% - 20px);\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": "MaterialFile-module__upload--b_Ky0",
	"nameField": "MaterialFile-module__nameField--vsQRU",
	"attachedFile": "MaterialFile-module__attachedFile--mIMJr",
	"attachedFileName": "MaterialFile-module__attachedFileName--LSyIz"
};
export default ___CSS_LOADER_EXPORT___;
