import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, notification, Tooltip } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import React, { SyntheticEvent } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { AxiosError } from 'axios';

import { uploadMaterial } from 'utils/upload';
import { MAX_MATERIAL_FILE_SIZE } from 'constants/upload';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import { InputField } from '../base/InputField';
import styles from './UploadDoc.module.less';
import { VALID_FORMATS_LIST } from './constants';

export interface UploadDocProps {
  name: string;
  textFieldName?: string;
}

enum SERVER_ERROR_DICTIONARY {
  UPLOADS_UNSUPPORTED_FILE_TYPE = 'У файла недопустимый формат. Загрузите файл с расширением .pdf, .doc, .docx, .xls, .xlsx, .csv, .odf, .ppt, .jpeg, .jpg, .png',
}

const tooltipText = (
  <ul className={styles.tooltipList}>
    <li>Размер файла - до {MAX_MATERIAL_FILE_SIZE / (1024 * 1024)} Мб</li>
    <li>
      Форматы - текстовые, табличные, презентации, фото (pdf, doc, docx, xls,
      xlsx, csv, odf, ppt, jpeg, jpg, png)
    </li>
    <li>Форматы, которые нельзя загружать - видео, аудио </li>
  </ul>
);

export const UploadDoc = ({ name, textFieldName = 'name' }: UploadDocProps) => {
  const { setValue, trigger, setFocus } = useFormContext();
  const value = useWatch({ name });
  const [isLoading, setIsLoading] = React.useState(false);

  const fileListItem = React.useMemo(
    () => (value ? [{ name: value.realName, uid: value.pathName }] : []),
    [value?.path]
  );

  const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess?.('ok');
    }, 0);
  };

  const handleFileChange = async (info: UploadChangeParam) => {
    const isLt2M = (info.file.size as number) < MAX_MATERIAL_FILE_SIZE;
    const isValidType = VALID_FORMATS_LIST.includes(info.file.type);

    if (!isLt2M) {
      return notification.error({
        message: `Пожалуйста, выберите файл с размером не более ${
          MAX_MATERIAL_FILE_SIZE / (1024 * 1024)
        } МБ`,
      });
    }
    if (!isValidType) {
      return notification.error({
        message: SERVER_ERROR_DICTIONARY.UPLOADS_UNSUPPORTED_FILE_TYPE,
      });
    }

    setIsLoading(true);

    try {
      const { path, fileList } = await uploadMaterial(info);
      const file = fileList[0];

      setValue(name, {
        baseUrl: UPLOAD_SERVICE_BASE_URL,
        path,
        realName: file.name,
      });
      setValue(textFieldName, file.name.split('.').slice(0, -1).join('.'));
      trigger();
      setFocus(textFieldName);
    } catch (error) {
      if (error.code && SERVER_ERROR_DICTIONARY[error.code]) {
        notification.error({
          message: SERVER_ERROR_DICTIONARY[error.code],
        });
      } else {
        notification.error({
          message: (error as AxiosError)?.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTooltipClick = (e: SyntheticEvent) => e.stopPropagation();

  return (
    <Col>
      <Controller
        name={name}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <Upload
            accept='.pdf, .doc, .docx, .xls, .xlsx, .csv, .odf, .ppt, .png, .jpeg, .jpg'
            customRequest={dummyRequest}
            maxCount={1}
            className={styles.uploadField}
            fileList={fileListItem}
            {...field}
            {...fieldState}
            onChange={handleFileChange}
          >
            <Tooltip title={fieldState.error?.message}>
              <Button
                icon={<UploadOutlined />}
                loading={isLoading}
                danger={!!fieldState.error?.message}
              >
                Загрузить файл
              </Button>
            </Tooltip>

            <Tooltip title={tooltipText} placement='rightTop'>
              <Button
                icon={<QuestionCircleOutlined />}
                className={styles.tooltipButton}
                type='text'
                onClick={handleTooltipClick}
              />
            </Tooltip>
          </Upload>
        )}
      />

      <InputField
        name={textFieldName}
        placeholder='Введите название'
        required
        label='Название'
        maxLength={255}
      />
    </Col>
  );
};
