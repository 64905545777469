import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  saveSupportMeasuresItem,
  saveSupportMeasuresItemAsDraft,
} from 'store/slices/supportMeasures/actions';
import {
  ISupportMeasuresItem,
  TSupportMeasuresItemDraft,
} from 'store/slices/supportMeasures/interfaces';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';

import { SupportMeasuresForm } from './components/SupportMeasuresForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: ISupportMeasuresItem) => {
    setIsLoading(true);
    dispatch(saveSupportMeasuresItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/supportMeasures');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TSupportMeasuresItemDraft) => {
    setIsLoading(true);
    dispatch(saveSupportMeasuresItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/supportMeasures');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/supportMeasures');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <SupportMeasuresForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          lang: LangEnum.ru,
          name: '',
          initiator: null,
          supportLevel: null,
          supportPeriod: null,
          region: null,
          industry: [],
          supportFormat: null,
          supportType: null,
          supportProblems: [],
          content: prepareContent([]),
          declarerRequirements: prepareContent([]),
          projectRequirements: prepareContent([]),
          documents: prepareContent([]),
          npa: prepareContent([]),
          contacts: '',
          externalUrl: '',
          status: StatusesEnum.PUBLISHED,
          /* Todo: uncomment when SEO will be available
          metaTitle: '',
          metaDescription: '',
          ogTitle: '',
          ogDescription: '',
          metaKeywords: '',
          */
        }}
      />
    </MainLayout>
  );
};
