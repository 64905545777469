import { createSlice } from '@reduxjs/toolkit';

import { SKFO_REGION_ID } from 'constants/region';

import { IRegionsState } from './interfaces';
import {
  loadRegionsItem,
  loadRegionsList,
  loadRegionsRelations,
  setVisibleRegionRelationsItem,
} from './actions';

export const regionsSlice = createSlice({
  name: 'regions',
  initialState: {
    list: [],
    item: {},
    relations: {
      data: [],
      total: 0,
    },
    limit: 20,
    total: 0,
    offset: 0,
    isLoading: false,
  } as IRegionsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadRegionsList.fulfilled,
      (state, { payload: { data, total } }) => {
        /* exclude SKFO from region list */
        state.list =
          data?.filter(region => region.fiasId !== SKFO_REGION_ID) || [];
        state.total = total;
        state.isLoading = false;
      }
    );
    builder.addCase(loadRegionsList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loadRegionsList.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(loadRegionsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
    builder.addCase(loadRegionsRelations.fulfilled, (state, { payload }) => {
      state.relations = payload;
    });
    builder.addCase(
      setVisibleRegionRelationsItem.fulfilled,
      (state, { payload }) => {
        const item = state.relations.data.find(
          relation => relation.id === payload.id
        );
        if (item) {
          item.isHidden = payload.isHidden;
        }
        return state;
      }
    );
  },
});
