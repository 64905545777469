import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GlobalOutlined, MailOutlined } from '@ant-design/icons/lib/icons';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { IAddress } from 'types/address';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import { getValueByStringKeyWithArr } from 'utils/objects';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { RubCircleSvg } from 'components/icons/icons';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { Select } from 'components/form/base/Select';
import { TContractExchangeResponse } from 'store/slices/contractExchange/interfaces';
import { PhoneNumberField } from 'components/form/base/PhoneNumberField';
import { ShortAddressSelector } from 'components/form/ShortAddressSelector/ShortAddressSelector';
import { ContentField } from 'components/form/Content/ContentField';
import { ICatalogData } from 'types/catalogData';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  ADS_TYPES_OPTIONS,
  CONTRACTS_STATUSES_OPTIONS,
} from 'constants/contracts';

import { mapValues, validationSchema } from './formUtils';

type TFormSelectValue = { value: Statuses; label: string };

interface IFormValues {
  name: string;
  type: string;
  productType: ICatalogData;
  price: string;
  organization: string;
  contactPhone: string;
  contactEmail: string;
  contactWebsite: string;
  address: IAddress;
  status: Statuses | TFormSelectValue;
  declineReason?: string;
  description: string;
}

type TProps = {
  initialValues: IFormValues;
  isLoading: boolean;
  onFinish: (values: TContractExchangeResponse) => void;
  cancelButtonHandler: () => void;
};

export const ContractExchangeForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'contacts',
    'publication',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });
  const status = methods.watch('status');

  const submit = values => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={1} style={{ minWidth: 200 }}>
              <TextAreaField
                name='name'
                label='Название'
                required
                placeholder='Введите название'
                maxLength={80}
                rows={2}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Select
                name='type'
                label='Тип объявления'
                placeholder='Выберите тип'
                options={ADS_TYPES_OPTIONS}
                required
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col flex={1} style={{ minWidth: 200 }}>
              <CatalogAsyncSelect
                required
                name='productType'
                label='Направление/вид продукции'
                placeholder='Выберите направление/вид продукции'
                requestParams={{
                  entityType: 'contractExchanges',
                  fieldType: 'productTypes',
                }}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <InputField
                addonAfter={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RubCircleSvg />
                  </div>
                }
                name='price'
                label='Стоимость'
                type='price'
                placeholder='Введите стоимость'
                maxLength={10}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col flex={1}>
              <ShortAddressSelector name='address' />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextAreaField
                name='description'
                label='Описание'
                placeholder='Описание'
                required
                maxLength={2000}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollpaseTabs(value as string[])}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Контакты' key='contacts'>
            <InputField
              label='Организация'
              name='organization'
              required
              disabled
            />
            {initialValues.contactPhone && (
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <PhoneNumberField
                    name='contactPhone'
                    label='Телефон'
                    required
                    disabled
                  />
                </Col>
              </Row>
            )}
            {initialValues.contactEmail && (
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <InputField
                    name='contactEmail'
                    label='Email'
                    required
                    disabled
                    prefix={<MailOutlined />}
                  />
                </Col>
              </Row>
            )}
            {initialValues.contactWebsite && (
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <InputField
                    name='contactWebsite'
                    label='Cайт'
                    placeholder='Укажите ссылку'
                    required
                    disabled
                    prefix={<GlobalOutlined />}
                  />
                </Col>
              </Row>
            )}
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='publication'>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Select
                  name='status'
                  label='Статус'
                  options={CONTRACTS_STATUSES_OPTIONS}
                />
              </Col>
            </Row>
            {(status === StatusesEnum.DECLINED ||
              (status as TFormSelectValue).value === StatusesEnum.DECLINED) && (
              <Row>
                <Col flex={1}>
                  <TextAreaField
                    name='declineReason'
                    label='Причина отклонения'
                    placeholder='Введите причину'
                    maxLength={800}
                    rows={2}
                  />
                </Col>
              </Row>
            )}
          </Collapse.Panel>
        </Collapse>

        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
