// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contacts-module__contact--0aPAQ {\n  color: #262626 !important;\n}\n.Contacts-module__contact--0aPAQ:hover {\n  color: #1890ff !important;\n}\n.Contacts-module__contactIcon--_z2na {\n  color: #262626;\n  margin-right: 9px;\n}\n.Contacts-module__contactIconPhone--D4uV0 {\n  color: #262626;\n  margin-right: 9px;\n}\n.Contacts-module__contactIconPhone--D4uV0 svg {\n  transform: rotate(90deg);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Business/components/Contacts/Contacts.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAEA;EACE,cAAA;EACA,iBAAA;AAAF;AAGA;EACE,cAAA;EACA,iBAAA;AADF;AADA;EAII,wBAAA;AAAJ","sourcesContent":[".contact {\n  color: #262626 !important;\n  &:hover {\n    color: #1890ff !important;\n  }\n}\n\n.contactIcon {\n  color: #262626;\n  margin-right: 9px;\n}\n\n.contactIconPhone {\n  color: #262626;\n  margin-right: 9px;\n  svg {\n    transform: rotate(90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "Contacts-module__contact--0aPAQ",
	"contactIcon": "Contacts-module__contactIcon--_z2na",
	"contactIconPhone": "Contacts-module__contactIconPhone--D4uV0"
};
export default ___CSS_LOADER_EXPORT___;
