import React from 'react';
import { Form, Input, InputProps, FormItemProps } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

export type InputFieldProps = FormItemProps &
  InputProps & {
    formItemStyle?: React.CSSProperties;
    withErrorMessage?: boolean;
    isFloat?: boolean;
    numberAfterDot?: number;
  };

export const InputField = ({
  label = '',
  required,
  name,
  type = 'text',
  addonAfter,
  noStyle = undefined,
  tooltip = undefined,
  extra = undefined,
  formItemStyle,
  maxLength = 255,
  withErrorMessage = true,
  isFloat = false,
  numberAfterDot = 2,
  ...props
}: InputFieldProps) => {
  const { control } = useFormContext();
  const handleChange = e => {
    if (type === 'days') {
      let value = e.target.value.replace(/[^0-9]/g, '');
      if (!value) {
        return value;
      }
      value = Number(value);
      if (value > 999) {
        return 999;
      }
      if (value < 0) {
        return 0;
      }
      return value;
    }
    if (type === 'number') {
      const value = e.target.value as string;

      if (isFloat) {
        if (/^\d*([.,]\d+)?$/.test(value) || /^\d*(\d+[.,])?$/.test(value)) {
          return value;
        }

        return value.slice(0, value.length - 1);
      }

      return value.replace(/[^0-9]/g, '');
    }
    if (type === 'price') {
      return e.target.value
        ? Number(e.target.value.replace(/[^0-9]/g, '')) * 100
        : null;
    }

    return e.target.value;
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(',', '.');
    if (Number(value)) {
      const number = parseFloat(value);
      if (String(number).includes('.')) {
        return number.toFixed(numberAfterDot);
      }

      return String(number);
    }

    return value;
  };

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={withErrorMessage && fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          required={required}
          noStyle={noStyle}
          tooltip={tooltip}
          extra={extra}
          style={formItemStyle}
        >
          <Input
            {...field}
            {...props}
            maxLength={maxLength}
            type='text'
            addonAfter={addonAfter}
            value={
              type === 'price' && field.value
                ? Number(field.value) / 100
                : field.value
            }
            onChange={e => field.onChange(handleChange(e))}
            onBlur={e =>
              isFloat ? field.onChange(handleBlur(e)) : field.onBlur()
            }
            autoComplete={'chrome-off'}
            style={{
              ...(noStyle &&
                fieldState.error && {
                  borderColor: '#ff4d4f',
                  boxShadow: '0 0 0 2px rgb(255 77 79 / 20%)',
                }),
            }}
          />
        </Form.Item>
      )}
      control={control}
    />
  );
};
