import dayjs, { Dayjs } from 'dayjs';

import { Statuses, StatusesEnum } from 'constants/status';
import {
  SignedAgreementsItem,
  SignedAgreementsItemRequest,
} from 'store/slices/signedAgreements/interfaces';
import { mapSelect } from 'utils/mappings';
import { defaultDateFormat } from 'components/form/base/RangePicker';
import { DeepPartial } from 'types/utils';
import { omit } from 'utils/helpers';
import { MaterialFileType } from 'components/form/AddMaterial/components/MaterialItem/types';

import { FormValues } from './types';

export const mapValues = ({
  name,
  year,
  status,
  month,
  material,
  ...values
}: FormValues): SignedAgreementsItemRequest => ({
  ...values,
  name: name.trim() || '',
  year: dayjs(year, defaultDateFormat).get('y'),
  month: dayjs(month, defaultDateFormat).get('M'),
  status: mapSelect(status) as Statuses,
  material: material as Required<MaterialFileType>,
});

export const mapValuesToForm = ({
  year,
  status,
  month,
  name,
  ...item
}: SignedAgreementsItem): FormValues => ({
  ...omit(item, [
    'author',
    'creator',
    'editor',
    'publishedAt',
    'createdAt',
    'updatedAt',
    'lang',
    'authorData',
  ]),
  name: name || '',
  year: dayjs().year(Number(year)),
  month: dayjs().month(Number(month)),
  status: status === StatusesEnum.DRAFT ? StatusesEnum.PUBLISHED : status,
});

export const mapValuesAsDraft = ({
  name,
  year,
  month,
  ...values
}: DeepPartial<FormValues>): DeepPartial<SignedAgreementsItemRequest> => ({
  ...omit(values, ['status']),
  name: name?.trim() || null,
  year: year ? dayjs(year as Dayjs, defaultDateFormat).get('y') : null,
  month: month ? dayjs(month as Dayjs, defaultDateFormat).get('M') : null,
});
