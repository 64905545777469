import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { loadReviewsItem, saveReviewsItem } from 'store/slices/reviews/actions';

import { ReviewsForm } from './components/ReviewsForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.reviews);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadReviewsItem({ id }));
  }, []);

  const onFinish = values => {
    setIsLoading(true);

    dispatch(saveReviewsItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/reviews');
      })
      .catch(() => setIsLoading(false))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelButtonHandler = () => {
    navigate('/reviews');
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && id ? (
        <ReviewsForm
          onFinish={onFinish}
          cancelButtonHandler={cancelButtonHandler}
          initialValues={mapValuesToForm(item)}
          isLoading={isLoading}
        />
      ) : null}
    </MainLayout>
  );
};
