import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { YMaps, Map as YMap, Polygon } from 'react-yandex-maps';

import { mapDefaultState, mapQuery, mapOptions } from './constants';

interface IFormMapProps {
  height: number;
  isOpenModal: boolean;
  polygonPosition?: number[][][];
  setIsOpenModal: (v: boolean) => void;
  setPolygonPosition: (v: number[][][]) => void;
}

type TYMapOptions = {
  duration: number;
  timingFunction: 'ease-in-out' | string;
};

type TYMapRef = {
  setCenter: (
    coordinates: number[],
    zoom: number,
    options: TYMapOptions
  ) => void;
  getZoom: () => number;
};

const ymapOptions = { duration: 500, timingFunction: 'ease-in-out' };

export const PolylinesMap: React.FC<IFormMapProps> = ({
  polygonPosition,
  height,
  isOpenModal,
  setPolygonPosition,
  setIsOpenModal,
}) => {
  const [mapRef, setMapRef] = React.useState(null);
  const [polylineRef, setPolRef] = React.useState(null);
  const [ymaps, setYmaps] = React.useState(null);

  const { control } = useFormContext();
  const mapPosition = useWatch({ name: `address.mapPosition`, control });

  const instanceRef = React.useCallback(ref => {
    if (ref) {
      setPolRef(ref);
      ref.editor.startDrawing();
      ref.geometry.events.add('change', e => {
        const coordinates = e.get('newCoordinates');
        setPolygonPosition(coordinates);
      });
    }
  }, []);

  React.useEffect(() => {
    if (mapRef) {
      if (polygonPosition?.[0]?.length) {
        mapRef.setBounds(ymaps.util.bounds.fromPoints(polygonPosition[0]));
      } else {
        mapRef.setCenter(mapPosition, 18, ymapOptions);
      }
    }
  }, [mapRef, polygonPosition, mapPosition]);

  React.useEffect(() => {
    if (polylineRef && !isOpenModal) {
      polylineRef.editor.stopEditing();
      polylineRef.editor.stopDrawing();
    }
  }, [polylineRef, isOpenModal]);

  React.useEffect(() => {
    if (polylineRef && !isOpenModal) {
      const startEditBtn = new ymaps.control.Button({
        data: {
          content: 'Редактировать контур',
        },
        options: {
          selectOnClick: false,
          visible: true,
          maxWidth: 250,
        },
      });
      startEditBtn.events.add('click', () => {
        startEditBtn.options.set('visible', false);
        setIsOpenModal(true);
        polylineRef.editor.startEditing();
        polylineRef.editor.startDrawing();
      });
      if (!isOpenModal) {
        mapRef.controls.add(startEditBtn, { float: 'right' });
      }
    }
  }, [polylineRef, isOpenModal]);

  return (
    <YMaps query={mapQuery}>
      <YMap
        height={height}
        width='100%'
        instanceRef={ref => setMapRef(ref as unknown as TYMapRef)}
        onLoad={ymaps => setYmaps(ymaps)}
        defaultState={mapDefaultState}
        modules={['geoObject.addon.editor']}
      >
        <Polygon
          instanceRef={ref => instanceRef(ref)}
          geometry={polygonPosition}
          options={mapOptions}
        />
      </YMap>
    </YMaps>
  );
};
