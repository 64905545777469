import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IVacanciesRespondsItemResponse,
  IVacanciesRespondsListParams,
} from './interfaces';

type TListResponse = {
  data: IVacanciesRespondsItemResponse[];
  total: number;
};

export const loadVacanciesRespondsList = createAsyncThunk<
  TListResponse,
  IVacanciesRespondsListParams,
  { state: RootState }
>('vacanciesResponds/loadList', async (params, { getState }) => {
  const { limit } = getState().vacanciesResponds;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.vacanciesResponds.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});
