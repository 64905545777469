import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { ICatalogItem } from 'store/slices/catalog/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { saveCatalogItem } from 'store/slices/catalog/actions';
import { LangEnum } from 'constants/lang';

import { CatalogForm } from './components/CatalogForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: ICatalogItem) => {
    setIsLoading(true);
    dispatch(saveCatalogItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/catalog');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/catalog');
  };

  return (
    <MainLayout pageTitle='Создание данных полей'>
      <CatalogForm
        onFinish={onFinish}
        cancelButtonHandler={cancelButtonHandler}
        isLoading={isLoading}
        initialValues={{
          name: '',
          entityType: null,
          fieldType: null,
          lang: LangEnum.ru,
        }}
      />
    </MainLayout>
  );
};
