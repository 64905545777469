import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IHotelsDraftItem, IHotelsItem } from 'store/slices/hotels/interfaces';
import { resetHotelItem } from 'store/slices/hotels/hotelsSlice';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadHotelsItem,
  saveHotelsItem,
  saveHotelsItemAsDraft,
} from 'store/slices/hotels/actions';
import { LangEnum } from 'constants/lang';

import { HotelsForm } from './components/HotelsForm/HotelsForm';
import { mapValuesToForm } from './components/formUtils';

export const AddTranslation = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.hotels);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadHotelsItem(id));
    return () => {
      dispatch(resetHotelItem());
    };
  }, []);

  const onFinish = (values: IHotelsItem) => {
    setIsLoading(true);
    dispatch(saveHotelsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/hotels');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IHotelsDraftItem) => {
    setIsLoading(true);
    dispatch(saveHotelsItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/hotels');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/hotels');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    category: null,
    categoryData: null,
    hotelComforts: [],
    hotelComfortsData: null,
    features: [],
    featuresData: null,
    comfortGroups: [],
    comfortGroupsData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item?.id && item.id === Number(id) ? (
        <HotelsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
