import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  saveRestaurantsItem,
  saveRestaurantsItemAsDraft,
} from 'store/slices/restaurants/actions';
import {
  IRestaurantsDraftItem,
  IRestaurantsItem,
} from 'store/slices/restaurants/interfaces';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { DEFAULT_SEO_STATE } from 'constants/seo';

import { RestaurantsForm } from './components/RestaurantsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IRestaurantsItem) => {
    setIsLoading(true);
    dispatch(saveRestaurantsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/restaurants');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IRestaurantsDraftItem) => {
    setIsLoading(true);
    dispatch(saveRestaurantsItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/restaurants');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/restaurants');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <RestaurantsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          lang: LangEnum.ru,
          name: '',
          image: null,
          heroImage: null,
          restaurantTypes: [],
          foods: [],
          features: [],
          shortDescription: '',
          content: prepareContent([]),
          averagePrice: null,
          gallery: [],
          address: {},
          workTime: {
            fri: { from: null, to: null },
            mon: { from: null, to: null },
            sat: { from: null, to: null },
            sun: { from: null, to: null },
            thu: { from: null, to: null },
            tue: { from: null, to: null },
            wed: { from: null, to: null },
          },
          contacts: [],
          ...DEFAULT_SEO_STATE,
          status: StatusesEnum.PUBLISHED,
        }}
      />
    </MainLayout>
  );
};
