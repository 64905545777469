import React, { cloneElement, Fragment, ReactElement } from 'react';
import { Button, Col, Input, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { InputField } from '../base/InputField';
import styles from './FieldsList.module.less';
export interface FieldsListProps {
  buttonLabel: string;
  name: string;
  field?: ReactElement;
  className?: string;
}

export const FieldsList = ({
  buttonLabel,
  name,
  field = <InputField />,
  ...props
}: FieldsListProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const onAddFieldClicked = () => append({ value: '' });

  const onRemove = (index: number) => remove(index);

  return (
    <Col {...props}>
      {fields.map(({ id }, index) => (
        <Fragment key={id}>
          {cloneElement(field, {
            name: `${name}.${index}.value`,
            className: styles.field,
            addonAfter: (
              <Button
                danger
                onClick={() => onRemove(index)}
                type='text'
                className={styles.removeButton}
              >
                <DeleteOutlined />
              </Button>
            ),
          })}
        </Fragment>
      ))}
      <Button icon={<PlusOutlined />} onClick={onAddFieldClicked}>
        {buttonLabel}
      </Button>
    </Col>
  );
};
