import * as Yup from 'yup';

import {
  contactsSchema,
  contentSchema,
  imageSchema,
  locationSchema,
  seoValidationSchema,
} from 'utils/yup';
import {
  mapAddress,
  mapContacts,
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage,
  mapSelect,
  mapValuesToAddress,
  mapValuesToSelect,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import { IHotelsItem, IHotelsDraftItem } from 'store/slices/hotels/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';

export const mapComfortGroups = values => {
  return values.map(group => ({
    id: group.value,
    comfort: group.comfort ? group.comfort : null,
  }));
};

export const mapComfortGroupsToForm = (comfortGroups, comfortGroupsData) => {
  return comfortGroups.map(group => {
    const name =
      comfortGroupsData.find(groupData => groupData.id === group.id).name ||
      null;
    return {
      comfort: group.comfort || '',
      value: group.id,
      children: name,
      label: name,
    };
  });
};

function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    starRating: values.starRating ? values.starRating : 'Не задано',
    rating: values.rating ? Number(values.rating) : null,
    shortDescription: stripHtml(values.shortDescription) || '',
    address: values.address ? mapValuesToAddress(values.address) : {},
    category: values.categoryData
      ? mapValuesToSelect(values.categoryData)
      : null,
    hotelComforts: values.hotelComfortsData
      ? mapValuesToSelect(values.hotelComfortsData)
      : [],
    content: prepareContent(mapContentToForm(values.content || [])),
    features: values.featuresData ? mapValuesToSelect(values.featuresData) : [],
    arrivalTime: values.arrivalTime,
    departureTime: values.departureTime,
    comfortGroups:
      values.comfortGroups && values.comfortGroupsData
        ? mapComfortGroupsToForm(values.comfortGroups, values.comfortGroupsData)
        : [],
    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    image: imageSchema,
    heroImage: imageSchema,
    category: Yup.mixed().required(),
    hotelComforts: Yup.array().max(
      10,
      'Выберите не более ${max} удобств в отеле'
    ),
    starRating: Yup.mixed(),
    rating: Yup.mixed(),
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 150 символов')
      .max(150, 'Введите от 1 до 150 символов')
      .required(),
    content: contentSchema,
    gallery: Yup.array().of(imageSchema),
    address: locationSchema,
    features: Yup.array().max(10, 'Выберите не более ${max} особенностей'),
    arrivalTime: Yup.mixed(),
    departureTime: Yup.mixed(),
    price: Yup.mixed(),
    comfortGroups: Yup.array()
      .max(10, 'Выберите не более ${max} удобств')
      .of(
        Yup.object().shape({
          comfort: Yup.string()
            .trim()
            .min(1, 'Введите от 1 до 800 символов')
            .max(800, 'Введите от 1 до 800 символов')
            .required(),
        })
      ),
    externalUrl: Yup.string().url(),
    contacts: contactsSchema,
    status: Yup.mixed().required(),
  })
  .concat(seoValidationSchema);

export const mapValues = (values): IHotelsItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    image: mapImage(values.image),
    heroImage: mapImage(values.heroImage),
    category: mapSelect(values.category) as number,
    hotelComforts: values.hotelComforts
      ? (mapSelect(values.hotelComforts) as number[])
      : [],
    starRating: values.starRating !== 'Не задано' ? values.starRating : null,
    rating: values.rating ? Number(values.rating) : null,
    shortDescription: values.shortDescription.trim(),
    content: mapContent(values.content),
    gallery: mapGallery(values.gallery),
    address: mapAddress(values.address),
    features: values.features.length
      ? (mapSelect(values.features) as number[])
      : [],
    arrivalTime: values.arrivalTime ? values.arrivalTime : null,
    departureTime: values.departureTime ? values.departureTime : null,
    price: values.price,
    comfortGroups: values.comfortGroups.length
      ? mapComfortGroups(values.comfortGroups)
      : [],
    contacts: mapContacts(values.contacts),
    externalUrl: values.externalUrl || '',
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    status: mapSelect(values.status) as Statuses,
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriorityMainPage || null,
  };
};

export const mapValuesToDraft = (values): IHotelsDraftItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name?.trim() || null,
    image: values.image ? mapImage(values.image) : null,
    heroImage: values.heroImage ? mapImage(values.heroImage) : null,
    category: values.category ? (mapSelect(values.category) as number) : null,
    hotelComforts: values.hotelComforts?.length
      ? (mapSelect(values.hotelComforts) as number[])
      : null,
    starRating: values.starRating !== 'Не задано' ? values.starRating : null,
    rating: values.rating ? Number(values.rating) : null,
    shortDescription: values.shortDescription?.trim() || null,
    content: values.content?.length ? mapContent(values.content) : [],
    gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
    address:
      values.address?.city || values.address?.region
        ? mapAddress(values.address)
        : null,
    features: values.features?.length
      ? (mapSelect(values.features) as number[])
      : null,
    arrivalTime: values.arrivalTime ? values.arrivalTime : null,
    departureTime: values.departureTime ? values.departureTime : null,
    price: values.price || null,
    comfortGroups: values.comfortGroups?.length
      ? mapComfortGroups(values.comfortGroups)
      : [],
    contacts:
      values.contacts?.length > 0 &&
      values.contacts.every(contact => contact.value !== '')
        ? values.contacts
        : [],
    externalUrl: values.externalUrl || '',
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
  };
};
