import {
  MaterialValue,
  MaterialValueRequest,
} from 'components/form/AddMaterial/components/MaterialItem/types';
import { TImage } from 'types/image';
import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import { inputUnmaskNumber } from 'constants/masks';

import {
  flatten,
  isNumber,
  isObject,
  isString,
  omit,
  reject,
  stripTagsAndTrim,
} from './helpers';

type Value = string | number;
export type SelectValue =
  | Value
  | {
    value: Value;
    label?: string;
    extraData?: {
      [key: string]: any;
    };
  };

export const mapSelect = (
  value: SelectValue | SelectValue[]
): Value | Value[] => {
  if (isNumber(value) || isString(value)) {
    return value as Value;
  }
  const mapped = flatten([value]).map(item => item && item.value);

  return Array.isArray(value) ? mapped : mapped[0];
};

export const mapImage = (image: TImage) => {
  return omit(image, ['cropCanvas', 'averageColor', 'size', 'realName']);
};

export const mapGallery = (gallery: TImage[]) => gallery.map(mapImage);

export const mapRegion = region => {
  return {
    name: region.children,
    fiasId: region.value,
    sysName: region.sysName,
  };
};

export const mapAddress = address => {
  return {
    ...address,
    region: {
      name: address.region.name,
      fiasId: address.region.fiasId,
    },
  };
};

export const mapShortAddress = address => {
  return {
    ...omit(address, 'comment', 'mapPosition'),
    region: {
      name: address.region.name,
      fiasId: address.region.fiasId,
    },
  };
};

export const mapContent = content => {
  const contentValue = reject(
    content,
    ({ type, text }) => type === 'text' && !stripTagsAndTrim(text)
  ).map(item => omit(item, 'originalIndex'));

  return contentValue.map(contentItem => {
    switch (contentItem.type) {
      case 'image':
        return {
          ...contentItem,
          image: mapImage(contentItem.image),
        };
      case 'gallery':
        return {
          ...contentItem,
          gallery: mapGallery(contentItem.gallery),
        };
      case 'geoPosition':
        return {
          type: 'geoPosition',
          geoPosition: {
            ...contentItem.geoPosition,
            address: mapAddress(contentItem.geoPosition.address),
            image: mapImage(contentItem.geoPosition.image),
          },
        };
      case 'widget': {
        const widgetType = contentItem.widget.type;

        return {
          type: 'widget',
          widget: {
            type: widgetType,
            id: contentItem.widget[widgetType].id,
            widgetDescription: contentItem.widget.widgetDescription,
          },
        };
      }
      default:
        return contentItem;
    }
  });
};

export const mapValuesToSelect = data => {
  if (isObject(data)) {
    return {
      ...data,
      key: data.id,
      value: data.id,
      label: data.name,
    };
  }
  if (Array.isArray(data)) {
    return data.map(item => {
      return {
        ...item,
        key: item.id,
        value: item.id,
        label: item.name,
      };
    });
  }
};

export const mapValuesToAddress = address => {
  return {
    ...address,
    region: {
      ...address.region,
      value: address.region.fiasId,
      children: address.region.name,
      key: address.region.fiasId,
    },
  };
};

export const mapValuesToRegion = region => ({
  value: region.fiasId,
  children: region.name,
  key: region.fiasId,
});

export const mapValueStringToSelect = (value: string | number) => {
  if (!value) return null;

  return {
    key: value.toString(),
    value,
  };
};

export const mapValueStringToFieldArray = (values: string[] = []) => {
  if (!values) return [];

  return values.map(item => ({ value: item }));
};

export const mapRegionForFilters = value => {
  if (Array.isArray(value)) {
    if (isString(value[0])) {
      return value;
    }
    if (isObject(value[0])) {
      return value.map(item => item.value);
    }
    return [];
  }
  if (isString(value)) {
    return value;
  }
  if (isObject(value)) {
    return value.value;
  }
  return null;
};

export const mapMaterial = (values: MaterialValue[]): MaterialValueRequest[] =>
  values.reduce<MaterialValueRequest[]>((acc, item) => {
    const { type } = item;

    if (!item[type]) return acc;

    const itemValue = item[type] || {};

    acc.push({
      type,
      [type]: itemValue,
    });

    return acc;
  }, []);

export const mapVideo = (values: { value: string }[]): string[] =>
  values.reduce<string[]>((acc, { value }) => {
    if (!value) return acc;

    acc.push(value);

    return acc;
  }, []);

export const mapContentToForm = content => {
  return content.map(item => {
    if (item.type === 'widget') {
      return {
        type: 'widget',
        widget: {
          type: item.widget.type,
          [item.widget.type]: mapValuesToSelect(item.widget.widgetData),
          widgetDescription: item.widget.widgetDescription,
        },
      };
    }

    if (item.type === 'image') {
      if (item.image?.crop) {
        return {
          ...item
        };
      } else {
        return {
          // убираем объект image если в нем нет crop (ломается драфт без этого)
          ...omit(item, 'image')
        };
      }
    }
    return item;
  });
};

export const mapContacts = contacts => {
  return contacts.map(item => {
    if (item.type === DefaultContactOptionsTypes.Phone) {
      return {
        ...item,
        value: (item.value && inputUnmaskNumber(item.value)) || null,
      };
    }
    return item;
  });
};

export const serializeSelectPriorityItem = <
  T extends {
    id?: number;
    sortPriority: number | null;
    sortPriorityMainPage: number | null;
    name: string;
  }
>({
  values,
  sortFieldName,
}: {
  values: T;
  sortFieldName: 'sortPriority' | 'sortPriorityMainPage';
}): {
  sortPriority: number | null;
  values: T;
} | null => {
  return 'id' in values
    ? {
      sortPriority: values[sortFieldName],
      values: values,
    }
    : null;
};
