import { createAsyncThunk } from '@reduxjs/toolkit';

import { Statuses } from 'constants/status';
import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadImageArray } from 'utils/upload';

import { IReviewsItemResponse } from './interfaces';

type TListReviews = {
  data: IReviewsItemResponse[];
  total: number;
};

export const loadReviewsList = createAsyncThunk<
  TListReviews,
  {
    name?: string;
    limit?: number;
    status?: string;
    offset?: number;
    sort?: string;
  },
  { state: RootState }
>('reviews/loadList', async (params, { getState }) => {
  const { limit } = getState().reviews;
  const { data } = await apiClient.get<TListReviews>(
    urls.api.reviews.admin.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );

  return data;
});

export const loadReviewsItem = createAsyncThunk<
  IReviewsItemResponse,
  {
    id?: string;
  }
>('reviews/loadItem', async ({ id }) => {
  const { data } = await apiClient.get<IReviewsItemResponse>(
    getUrlWithParams(urls.api.reviews.admin.getOne, {
      id,
    })
  );
  return data;
});

export const updateStatusItem = createAsyncThunk<
  unknown,
  { id: string; status: Omit<Statuses, 'draft'> }
>('reviews/updateStatusItem', async ({ id, status }) => {
  return await apiClient.patch(
    getUrlWithParams(urls.api.reviews.patch, { id }),
    {
      status,
    }
  );
});

export const saveReviewsItem = createAsyncThunk<
  IReviewsItemResponse,
  IReviewsItemResponse
>('reviews/saveItem', async ({ id, ...params }) => {
  const gallery = await uploadImageArray(params.gallery);

  const { data } = await apiClient.patch<IReviewsItemResponse>(
    getUrlWithParams(urls.api.reviews.patch, { id }),
    {
      ...params,
      gallery,
    }
  );
  return data;
});

export const deleteReviewsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: string;
  }
>('reviews/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.reviews.delete, { id }));
  return data;
});
