import React, { useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from '../../../../store';
import { addHistoryItem } from '../../../../store/slices/b2bHistory/actions';
import { IHistoryItem } from '../../../../store/slices/b2bHistory/interfaces';
import { MainLayout } from '../../../../components/Layout/MainLayout';
import { HistoryItemForm } from './HistoryItemForm';
import { mapHistoryValues } from '../../utils';

export const AddHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { historyId } = useParams();
  const onSubmit = (values: IHistoryItem) => {
    setIsLoading(true);
    dispatch(addHistoryItem({ historyId, payload: mapHistoryValues(values) }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно добавлена',
        });
        navigate('/mainBusiness/history');
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <MainLayout pageTitle='Добавление'>
      <Content>
        <HistoryItemForm
          isLoading={isLoading}
          initialValues={null}
          onSubmit={onSubmit}
        />
      </Content>
    </MainLayout>
  );
};
