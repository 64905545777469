import { createSlice } from '@reduxjs/toolkit';

import { loadReviewsItem, loadReviewsList } from './actions';
import { IReviewsState } from './interfaces';

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
    entityType: null,
    entityId: null,
  } as IReviewsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadReviewsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
    builder.addCase(loadReviewsItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});
