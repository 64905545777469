import { PlusOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { MaterialItem } from './components/MaterialItem';
import styles from './AddMaterial.module.less';
import { MaterialEnum } from './components/MaterialItem/types';

export interface AddMaterialProps {
  name: string;
  title?: string;
  className?: string;
  validFormatsList?: string[];
  accept?: string;
  uploadsUnsupportedFileType?: string;
}

export const AddMaterial = ({
  name,
  title = 'Создать материалы',
  validFormatsList,
  accept,
  uploadsUnsupportedFileType,
  ...props
}: AddMaterialProps) => {
  const { control } = useFormContext();
  const fieldMethods = useFieldArray({
    control,
    name,
  });

  const onAddClick = () =>
    fieldMethods.append({
      type: MaterialEnum.FILE,
      url: {
        url: '',
        displayName: '',
      },
      file: {
        baseUrl: '',
        path: '',
        realName: '',
        displayName: '',
      },
    });

  return (
    <Col {...props}>
      <Button
        icon={<PlusOutlined />}
        onClick={onAddClick}
        className={styles.addButton}
      >
        {title}
      </Button>
      <Col className={styles.list}>
        {fieldMethods.fields.map(({ id }, index) => (
          <MaterialItem
            key={id}
            name={name}
            index={index}
            methods={fieldMethods}
            validFormatsList={validFormatsList}
            accept={accept}
            uploadsUnsupportedFileType={uploadsUnsupportedFileType}
          />
        ))}
      </Col>
    </Col>
  );
};
