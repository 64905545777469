import React from 'react';
import classNames from 'classnames';

import { IRegionRelationsItem } from 'store/slices/regions/interfaces';

import { TGeoObject } from '../../types';
import { CLUSTER_LIST_ID } from '../../constants';
import { BalloonLayout } from '../BalloonLayout/BalloonLayout';
import styles from './ClusterInnerLayout.module.less';

type TProps = {
  points: IRegionRelationsItem[];
  geoObjects: TGeoObject[];
  activeClusterItem: number;
  setActiveClusterItem: (index: number) => void;
  setScrollPosition: (scrollPosition: number) => void;
};

export const ClusterInnerLayout: React.FC<TProps> = ({
  points,
  geoObjects,
  activeClusterItem,
  setActiveClusterItem,
  setScrollPosition,
}) => {
  return (
    <div className={styles.cluster}>
      <ul className={styles.cluster__list} id={CLUSTER_LIST_ID}>
        {geoObjects &&
          geoObjects.map(geoObject => (
            <li
              key={geoObject.properties._data.balloonContentHeader}
              className={classNames(styles.cluster__item, {
                [styles.active]:
                  String(geoObject.properties._data.index) ===
                  String(activeClusterItem),
              })}
              data-placemarkid={geoObject.properties._data.index}
              title={geoObject.properties._data.balloonContentHeader}
              onClick={() => {
                const clusterListEl = document.getElementById(CLUSTER_LIST_ID);
                if (clusterListEl) {
                  setScrollPosition(clusterListEl.scrollTop);
                  setActiveClusterItem(geoObject.properties._data.index);
                }
              }}
            >
              {geoObject.properties._data.balloonContentHeader}
            </li>
          ))}
      </ul>
      <div className={styles.cluster__content}>
        <BalloonLayout
          point={activeClusterItem !== null ? points[activeClusterItem] : null}
        />
      </div>
    </div>
  );
};
