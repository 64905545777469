import React, { useState, PropsWithChildren, useCallback, memo } from 'react';
import classNames from 'classnames';
import { Collapse } from 'antd';

import { IDocument } from 'types/document';

import { Header } from '../Header/Header';
import { Extra } from '../Extra/Extra';
import styles from './CustomCollapse.module.less';

interface IProps extends PropsWithChildren {
  onDelete: (id: string) => void;
  onMove: (step?: number) => void;
  title: string;
  file: IDocument;
  itemId: string;
  sortIndex: number;
  total: number;
  isError: boolean;
}

export const CustomCollapse = memo(
  ({
    onDelete,
    onMove,
    itemId,
    file,
    title,
    children,
    sortIndex,
    total,
    isError,
  }: IProps) => {
    const [isOpen, setIsOpen] = useState<string[]>([]);

    const handleChange = useCallback((value: string[]) => setIsOpen(value), []);
    const handleChangeStatus = useCallback(
      () => setIsOpen(prevState => (prevState.length ? [] : ['1'])),
      []
    );

    const handleDelete = useCallback(() => onDelete(itemId), []);

    return (
      <Collapse
        className={classNames(styles.collapse, { [styles.error]: isError })}
        onChange={handleChange}
        activeKey={isOpen}
        collapsible='disabled'
        expandIconPosition='end'
      >
        <Collapse.Panel
          key={1}
          header={<Header file={file} title={title} />}
          extra={
            <Extra
              onDelete={handleDelete}
              onMove={onMove}
              onChangeStatus={handleChangeStatus}
              isOpen={!!isOpen.length}
              sort={sortIndex}
              total={total}
            />
          }
          showArrow={false}
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    );
  }
);

CustomCollapse.displayName = 'CustomCollapse';
