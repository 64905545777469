import React from 'react';
import { Layout, Typography } from 'antd';
import { useForm, FormProvider, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { InputField } from 'components/form/base/InputField';
import { Select } from 'components/form/base/Select';
import { DatePicker } from 'components/form/base/DatePicker';
import { CheckboxField } from 'components/form/base/CheckboxField';
import { mapSelect } from 'utils/mappings';
import { debounce, omit, removeEmptyValues } from 'utils/helpers';
import { useAppSelector } from 'store';
import {
  canViewOrganizationApplications,
  canViewOrganizationProjects,
} from 'utils/rights';

import { IFormValues } from './interfaces';
import { roleOptions } from './constants';

export const Filters = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { role } = useAppSelector(state => state.account);
  const updateFilters = filters => {
    const queryString = new URLSearchParams(filters).toString();
    navigate(`${location.pathname}?${queryString}`);
  };

  const debounceSubmit = React.useCallback(
    debounce((values: IFormValues) => {
      const preparedValues = {
        organizationName:
          values.organizationName && values.organizationName.trim(),
        isResident: mapSelect(values.isResident),
        ownerName: values.ownerName && values.ownerName.trim(),
        createdAtFrom: values.createdAtFrom || null,
        createdAtTo: values.createdAtTo || null,
        hasProjects: values.hasProjects || null,
        hasApplications: values.hasApplications || null,
      };
      updateFilters(removeEmptyValues(preparedValues));
    }, 500),
    []
  );

  const methods = useForm<IFormValues>({
    defaultValues: {
      organizationName: '',
      isResident: '',
      ownerName: '',
      createdAtFrom: null,
      createdAtTo: null,
      hasProjects: false,
      hasApplications: false,
      ...omit(query, 'limit', 'offset'),
    },
  });

  React.useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(debounceSubmit) as WatchObserver<IFormValues>
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const applicationsCheckboxIsVisible = canViewOrganizationApplications(role);
  const projectsCheckboxIsVisible = canViewOrganizationProjects(role);
  return (
    <Layout.Content
      style={{ background: '#fff', paddingTop: '16px', paddingBottom: '1px' }}
    >
      <Typography.Title level={5} style={{ marginBottom: '24px' }}>
        Фильтры
      </Typography.Title>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <InputField
            name='organizationName'
            label='Поиск по названию'
            placeholder='Введите название'
            maxLength={255}
          />
          <Select
            name='isResident'
            label='Роль организации'
            options={roleOptions}
          />
          <InputField
            name='ownerName'
            label='Владелец организации'
            placeholder='Введите ФИО'
            maxLength={255}
          />
          <DatePicker
            name='createdAtFrom'
            label='Период регистрации с'
            placeholder='Выберите дату начала'
          />
          <DatePicker
            name='createdAtTo'
            label='Период регистрации по'
            placeholder='Выберите дату окончания'
          />
          {applicationsCheckboxIsVisible && (
            <CheckboxField
              label='Наличие созданной заявки'
              formItemStyle={projectsCheckboxIsVisible ? { margin: 0 } : {}}
              name='hasApplications'
            >
              Есть заявка
            </CheckboxField>
          )}
          {projectsCheckboxIsVisible && (
            <CheckboxField name='hasProjects'>Есть проект</CheckboxField>
          )}
        </form>
      </FormProvider>
    </Layout.Content>
  );
};
