import React, { useState } from 'react';
import { Button, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import {
  loadSupportMeasuresList,
  removeSupportMeasuresItem,
  setPublishSupportMeasure,
  setUnPublishSupportMeasure,
} from 'store/slices/supportMeasures/actions';
import { PageTitles } from 'constants/pageTitles';
import { canBePublished, canBeUnPublished } from 'utils/rights';
import { StatusesEnum } from 'constants/status';
import { ISupportMeasuresItemResponse } from 'store/slices/supportMeasures/interfaces';
import { LangEnum } from 'constants/lang';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.supportMeasures);
  const { b2bUrl } = useAppSelector(state => state.configSlice);

  const loadSupportMeasures = () => {
    dispatch(
      loadSupportMeasuresList({
        ...query,
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  useDeepEffect(() => {
    loadSupportMeasures();
  }, [query, sort]);

  const modalCloseHandler = () => {
    setModalIsOpen(null);
    setCurrentItem(null);
  };

  const handleRemove = async () => {
    await dispatch(removeSupportMeasuresItem({ id: currentItem.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        loadSupportMeasures();
        modalCloseHandler();
      });
  };

  const handlePublish = async id => {
    await dispatch(setPublishSupportMeasure(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно опубликована',
        });
        loadSupportMeasures();
        modalCloseHandler();
      });
  };

  const handleUnPublish = async id => {
    await dispatch(setUnPublishSupportMeasure(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно снята с публикации',
        });
        loadSupportMeasures();
        modalCloseHandler();
      });
  };

  const getActions = (item: ISupportMeasuresItemResponse) => [
    item.status === StatusesEnum.PUBLISHED && {
      key: 'website',
      label: (
        <a
          target='_blank'
          rel='noreferrer nofollow'
          href={`${b2bUrl}/${item.lang}/support-measures/${item.id}`}
        >
          На сайте
        </a>
      ),
    },
    {
      key: 'edit',
      label: <Link to={`/supportMeasures/${item.id}/edit`}>Редактировать</Link>,
    },
    item.lang === LangEnum.ru && {
      key: 'add-translation',
      label: (
        <Link to={`/supportMeasures/${item.id}/add-translation`}>
          Создать копию для перевода
        </Link>
      ),
    },
    canBePublished(item) && {
      key: 'publish',
      label: <Typography.Text>Опубликовать</Typography.Text>,
      onClick: () => handlePublish(item.id),
    },
    canBeUnPublished(item) && {
      key: 'unPublish',
      label: <Typography.Text>Снять с публикации</Typography.Text>,
      onClick: () => handleUnPublish(item.id),
    },
    {
      key: 'delete',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        if (item.status === StatusesEnum.PUBLISHED) {
          notification.error({
            message: 'Необходимо снять сущность с публикации',
          });
        } else {
          setModalIsOpen('remove');
          setCurrentItem(item);
        }
      },
    },
  ];

  return (
    <MainLayout
      pageTitle={PageTitles.supportMeasures}
      extraButtonsList={[
        <Link key={1} to='/supportMeasures/add'>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
      aside={<Filters />}
    >
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen === 'remove' && (
            <StickyConfirmModal
              isOpen={modalIsOpen === 'remove'}
              onRequestClose={modalCloseHandler}
              title='Удаление меры поддержки'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала и переместить в Корзину меру
                  поддержки{' '}
                  <Typography.Text strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={handleRemove}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
