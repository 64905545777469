import React, { useCallback, useRef, useState } from 'react';
import { Col, notification, Pagination, PaginationProps, Row } from 'antd';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteLandmark,
  loadAllLandmark,
  loadLandmark,
  saveLandmark,
  updateLandmark,
} from 'store/slices/openingInfo/actions';
import { ILandmark } from 'store/slices/openingInfo/interfaces';
import { useDeepEffect } from 'utils/useDeepEffect';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { FormWithSelect } from '../EntityForms/FormWithSelect';
import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import styles from './Landmark.module.less';

dayjs.extend(quarterOfYear);

const PAGE_SIZE = 3;

export const Landmark = () => {
  const [offset, setOffset] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);
  const swapElRef = useRef<{ landmark: ILandmark; step: number }>(null);

  const {
    landmark: { list, total },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const loadListLandmark = async (offset: number) =>
    await dispatch(
      loadLandmark({
        limit: PAGE_SIZE,
        offset: (offset - 1) * PAGE_SIZE,
      })
    ).unwrap();

  const updateLandmarkOnAnotherPage = async (list: Array<ILandmark>) => {
    const { landmark, step } = swapElRef.current;
    const swapLandmark = list[step === 1 ? 0 : list.length - 1];

    await dispatch(
      updateLandmark({
        id: swapLandmark.id,
        file: swapLandmark.file,
        title: swapLandmark.title,
        quart: swapLandmark.quart,
        year: swapLandmark.year,
        sortIndex: landmark.sortIndex,
      })
    );

    await dispatch(
      updateLandmark({
        id: landmark.id,
        file: landmark.file,
        title: landmark.title,
        quart: landmark.quart,
        year: landmark.year,
        sortIndex: swapLandmark.sortIndex,
      })
    );

    await loadListLandmark(offset);
    swapElRef.current = null;
  };

  useDeepEffect(() => {
    loadListLandmark(offset).then(({ data }) => {
      if (swapElRef.current) {
        updateLandmarkOnAnotherPage(data);
      }
    });
  }, [offset]);

  const handleChangePage: PaginationProps['onChange'] = page => setOffset(page);

  const handleAdd = useCallback(
    async (file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      const currentDay = dayjs();
      const quart = currentDay.quarter();
      const year = currentDay.year();
      await dispatch(
        saveLandmark({ file, title, quart, year, sortIndex: total + 1 })
      );

      const lastPage = Math.ceil((total + 1) / PAGE_SIZE);
      if (offset === lastPage) {
        loadListLandmark(lastPage);
      } else {
        setOffset(lastPage);
      }
    },
    [total]
  );

  const handleUpdate = useCallback(
    async (
      file: IDocument,
      title: string,
      quart: number,
      year: number,
      { id, sortIndex }: ILandmark
    ) =>
      await dispatch(
        updateLandmark({ file, title, id, quart, year, sortIndex })
      ),
    []
  );

  const handleMove = useCallback(
    async (landmark: ILandmark, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === landmark.id);
      const swapEl = list[currentIndex + step];

      if (swapEl) {
        await dispatch(
          updateLandmark({
            id: swapEl.id,
            file: swapEl.file,
            title: swapEl.title,
            quart: swapEl.quart,
            year: swapEl.year,
            sortIndex: landmark.sortIndex,
          })
        );

        await dispatch(
          updateLandmark({
            id: landmark.id,
            file: landmark.file,
            title: landmark.title,
            quart: landmark.quart,
            year: landmark.year,
            sortIndex: swapEl.sortIndex,
          })
        );

        await loadListLandmark(offset);
      } else {
        swapElRef.current = { landmark, step };
        setOffset(prevState => prevState + step);
      }
    },
    [offset, list]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteLandmark(id));
      handleCloseModal();
      notification.success({
        message: 'Документ удалён',
      });

      const list = await dispatch(loadAllLandmark()).unwrap();

      await Promise.all(
        list.data.map(({ id, file, title, quart, year }, index) =>
          dispatch(
            updateLandmark({
              id,
              file,
              title,
              quart,
              year,
              sortIndex: index + 1,
            })
          )
        )
      );

      const currentlastPage = Math.ceil(total / PAGE_SIZE);
      const updatelastPage = Math.ceil((total - 1) / PAGE_SIZE);

      if (currentlastPage !== updatelastPage && offset !== 1) {
        setOffset(prevState => prevState - 1);
      } else {
        await loadListLandmark(offset);
      }
    },
    [offset, total]
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <CustomUpload onAdd={handleAdd} disabled={loading} />
        </Col>
      </Row>
      {total > 0 && (
        <Row className={styles.list}>
          <Col span={24}>
            {list.map(item => (
              <FormWithSelect
                key={item.id}
                onMove={handleMove}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                total={total}
                entity={item}
              />
            ))}
          </Col>
        </Row>
      )}
      <Row justify='end'>
        <Col>
          <Pagination
            size='small'
            current={offset}
            onChange={handleChangePage}
            total={total}
            pageSize={PAGE_SIZE}
            hideOnSinglePage
          />
        </Col>
      </Row>
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          text='Вы уверены, что хотите удалить документ?'
        />
      )}
    </div>
  );
};
