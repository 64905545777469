import React from 'react';
import { Button, Dropdown, Image, Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';

import { makeAddressString } from '../../utils/entities';
import { getImageUrl } from '../../utils/image';
import { TImage } from '../../types/image';
import { CommonTable } from '../CommonTable/CommonTable';
import { IAddress } from '../../types/address';

interface ICol {
  id: number;
  title: string;
  sortPriority: number;
  image: TImage;
  address?: IAddress;
}

interface IProps {
  data: ICol[];
  limit?: number;
  getActions: (item: ICol) => Array<
    | {
        element: JSX.Element;
        handler?: undefined;
      }
    | {
        element: React.ReactNode;
        handler: () => void;
      }
  >;
}

export const SampleTable: React.FC<IProps> = ({
  data,
  getActions,
  limit = 20,
}) => {
  const columns = [
    {
      key: 1,
      title: 'Название',
      dataIndex: 'title',
      render: (title, item) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`${item.id}/edit`}
          >
            <div style={{ flexShrink: 0 }}>
              <Image
                width={50}
                height={50}
                preview={false}
                src={
                  item?.image
                    ? getImageUrl(item.image, 50, 50) ||
                      '/public/images/no-image.svg'
                    : '/public/images/no-image.svg'
                }
                fallback='/public/images/no-image.svg'
                style={{ flexShrink: 0 }}
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <div>{title || 'Без названия'}</div>
              {item.address && (
                <div
                  style={{
                    marginTop: '4px',
                    fontSize: '12px',
                    color: '#8C8C8C',
                  }}
                >
                  {item.address?.region?.name} -{' '}
                  {makeAddressString({ city: item.address?.city }) ||
                    makeAddressString({
                      settlement: item.address?.settlement,
                    })}
                </div>
              )}
            </div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: 150,
      title: 'Сортировка',
      dataIndex: 'sortPriority',
      render: sortPriority => <Typography>{sortPriority || '-'}</Typography>,
    },
    {
      key: 3,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (actions, item) => {
        const dropMenu = (
          <Menu>
            {getActions &&
              getActions(item).map((action, index) =>
                action ? (
                  <Menu.Item key={index} onClick={action.handler}>
                    {action.element}
                  </Menu.Item>
                ) : null
              )}
          </Menu>
        );
        return (
          <>
            <Dropdown
              placement='bottomRight'
              overlay={dropMenu}
              arrow
              trigger={['click']}
            >
              <Button type='text' icon={<SettingOutlined />} />
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 600 }}
      dataSource={data}
      limit={limit}
      total={data.length}
    />
  );
};
