import React, { memo, useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { UploadOutlined } from '@ant-design/icons';
import { Col, Input, InputRef, Row, Typography } from 'antd';

import {
  IChapter,
  TAnnual,
  TStrategy,
} from 'store/slices/openingInfo/interfaces';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { CustomCollapse } from './components/CustomCollapse/CustomCollapse';
import { Nested } from '../Nested/Nested';
import styles from './Form.module.less';
interface IProps {
  onDelete: (id: string) => void;
  onMove: (item: IChapter | TStrategy | TAnnual, step?: number) => void;
  onUpdate: (
    file: IDocument,
    title: string,
    entity: IChapter | TStrategy | TAnnual
  ) => void;
  entity: IChapter | TStrategy | TAnnual;
  total: number;
  withAdditional?: boolean;
}

export const SimpleForm = memo(
  ({
    onUpdate,
    onDelete,
    onMove,
    entity,
    total,
    withAdditional = false,
  }: IProps) => {
    const inputRef = useRef<InputRef>(null);
    const [title, setTitle] = useState<string>(entity.title);
    const [file, setFile] = useState<IDocument>(entity.file);
    const [error, setError] = useState<string>(null);

    const handleBlur = () => !error && onUpdate(file, title, entity);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const isValid = Yup.string().required().isValidSync(value);
      if (!isValid) {
        setError('Введите от 1 до 255 символов');
      } else {
        setError(null);
      }
      setTitle(e.target.value);
    };

    const handleChangeState = useCallback((file: IDocument) => {
      const title = file.realName.split('.').slice(0, -1).join('.');
      setTitle(title);
      setFile(file);
      inputRef.current.focus();
    }, []);

    return (
      <CustomCollapse
        onDelete={onDelete}
        onMove={step => onMove(entity, step)}
        title={title}
        file={file}
        itemId={entity.id}
        sortIndex={entity.sortIndex}
        total={total}
        isError={!!error}
      >
        <Row gutter={[24, 24]}>
          <Col flex={1}>
            <Input
              ref={inputRef}
              className={styles.input}
              placeholder='Название документа'
              type='text'
              value={title}
              onChange={handleChange}
              onBlur={handleBlur}
              minLength={1}
              maxLength={255}
              status={error ? 'error' : undefined}
              addonBefore={<span className={styles.required} />}
            />
            {error && <Typography.Text type='danger'>{error}</Typography.Text>}
          </Col>
          <Col>
            <CustomUpload
              onAdd={handleChangeState}
              icon={<UploadOutlined />}
              buttonTitle='Загрузить другой'
            />
          </Col>
        </Row>
        {withAdditional && <Nested parentId={entity.id} />}
      </CustomCollapse>
    );
  }
);

SimpleForm.displayName = 'SimpleForm';
