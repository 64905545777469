import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { Statuses, StatusesEnum } from 'constants/status';
import { TImage } from 'types/image';
import { DeepPartial } from 'types/utils';
import {
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage,
  mapMaterial,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
  SelectValue,
} from 'utils/mappings';
import { InvestmentSolutionsItem } from 'store/slices/investmentSolutions/interfaces';
import { investmentSolutionNamesHash } from 'constants/investmentSolutionsCategories';
import { prepareContent } from 'components/form/Content/utils';
import { inputUnmaskNumber } from 'constants/masks';

import { FormValues } from './types';

const mapContactsToForm = (contacts: InvestmentSolutionsItem['contacts']) => ({
  name: contacts.name || '',
  position: contacts.position || '',
  phone: contacts.phone || '',
  additional: contacts.additional || '',
  email: contacts.email || '',
});

const mapContacts = (contacts: InvestmentSolutionsItem['contacts']) => ({
  name: contacts.name || null,
  position: contacts.position || null,
  phone: (contacts.phone && inputUnmaskNumber(contacts.phone)) || null,
  additional: contacts.additional || null,
  email: contacts.email || null,
});

export const mapCategoryToForm = category => ({
  value: category,
  label: investmentSolutionNamesHash[category],
});

export const mapValues = (values: FormValues): InvestmentSolutionsItem => ({
  name: values.name.trim(),
  shortDescription: values.shortDescription.trim(),
  requiredInvestments: values.requiredInvestments,
  category: mapSelect(values.category) as string,
  content: mapContent(values.content),
  region: values.region ? mapRegion(values.region) : null,
  lang: mapSelect(values.lang) as LangEnumKeys,
  image: mapImage(values.image as TImage),
  status: mapSelect(values.status as SelectValue) as Statuses,
  gallery: mapGallery(values.gallery),
  documents: mapMaterial(values.documents as MaterialValue[]),
  contacts: mapContacts(values.contacts),
});

export const mapValuesAsDraft = (
  values: FormValues
): DeepPartial<InvestmentSolutionsItem> => ({
  contacts: mapContacts(values.contacts),
  image: values.image ? mapImage(values.image as TImage) : undefined,
  name: values.name || null,
  category: values.category ? (mapSelect(values.category) as string) : null,
  region: values.region ? mapRegion(values.region) : null,
  requiredInvestments: values.requiredInvestments || null,
  content: values.content?.length ? mapContent(values.content) : [],
  shortDescription: values.shortDescription || null,
  gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
  documents: values.documents?.length
    ? mapMaterial(values.documents as MaterialValue[])
    : [],
  lang: mapSelect(values.lang) as LangEnumKeys,
});

export const mapValuesToForm = values => ({
  ...values,
  contacts: mapContactsToForm(values.contacts),
  category: values.category ? mapCategoryToForm(values.category) : null,
  region: values.region ? mapValuesToRegion(values.region) : null,
  content: prepareContent(mapContentToForm(values.content || [])),
  lang: values.lang || LangEnum.ru,
  status:
    values.status === StatusesEnum.DRAFT
      ? StatusesEnum.PUBLISHED
      : values.status,
  documents: values.documents || [],
  gallery: values.gallery || [],
});
