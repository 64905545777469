import React from 'react';
import { Descriptions, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { useAppSelector } from 'store';
import { authTypesValueTextHash } from 'constants/authTypes';

import styles from './UserInfo.module.less';

export const UserInfo = () => {
  const {
    item: { createdAt, authType },
  } = useAppSelector(state => state.business);

  return (
    <Descriptions
      labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
      title=''
      size='small'
      column={1}
      layout='vertical'
    >
      <Descriptions.Item className={styles.row} label='Дата регистрации'>
        <Typography.Text>
          {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
        </Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item
        className={classNames(styles.row, styles.rowLast)}
        label='Тип регистрации'
      >
        <Row>
          <Typography.Text>{authTypesValueTextHash[authType]}</Typography.Text>
        </Row>
      </Descriptions.Item>
    </Descriptions>
  );
};
