import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import {
  IB2BRegionItem,
  IRegionRelationsItem,
  IRegionRelationsResponse,
  IRegionsItem,
  IRegionsItemResponse,
} from './interfaces';

type TListResponse = { data: IRegionsItemResponse[]; total: number };

export const loadRegionsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
  },
  { state: RootState }
>('regions/loadList', async (params, { getState }) => {
  const { limit } = getState().regions;
  const { data } = await apiClient.get<TListResponse>(urls.api.regions.get, {
    params: {
      limit,
      ...params,
    },
  });
  return data;
});

export const loadRegionsItem = createAsyncThunk<IRegionsItemResponse, string>(
  'regions/loadItem',
  async id => {
    const { data } = await apiClient.get<IRegionsItemResponse>(
      getUrlWithParams(urls.api.regions.getOne, {
        id,
      })
    );

    return data;
  }
);

export const saveRegionsItem = createAsyncThunk<
  IRegionsItemResponse,
  IRegionsItem
>('regions/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const cover = await uploadImage(params.cover);
  const gallery = await uploadImageArray(params.gallery);
  const content = await uploadContent(params.content);

  const { data } = await apiClient.patch<IRegionsItemResponse>(
    getUrlWithParams(urls.api.regions.patch, { id }),
    {
      ...params,
      image,
      cover,
      gallery,
      content,
    }
  );
  return data;
});

export const setRegionsItem = createAsyncThunk<
  IRegionsItemResponse,
  IB2BRegionItem
>('regions/setItem', async ({ id, ...params }) => {
  const { data } = await apiClient.put<IRegionsItemResponse>(
    getUrlWithParams(urls.api.regions.put, { id }),
    params
  );
  return data;
});

export const loadRegionsRelations = createAsyncThunk<
  IRegionRelationsResponse,
  {
    nameQuery?: string;
    regionFiasIds: string;
    type?: string;
    sort?: string;
    offset?: string;
    limit?: number;
  }
>('regions/loadRelations', async params => {
  const { data } = await apiClient.get<IRegionRelationsResponse>(
    urls.api.regionRelations.get,
    { params }
  );

  return data;
});

export const setVisibleRegionRelationsItem = createAsyncThunk<
  IRegionRelationsItem,
  { id: number; isHidden: boolean }
>('regions/setVisible', async ({ id, isHidden }) => {
  const { data } = await apiClient.patch<IRegionRelationsItem>(
    getUrlWithParams(urls.api.regionRelations.updateVisibility.patch, { id }),
    { isHidden }
  );

  return data;
});
