import React from 'react';
import { Row, Col, Typography } from 'antd';

import { useAppSelector } from 'store';

import styles from './Projects.module.less';
import { Project } from './components/Project';

export const Projects = () => {
  const { projects } = useAppSelector(state => state.organizations);

  if (!projects.length) {
    return <Typography.Text>Проектов нет</Typography.Text>;
  }

  return (
    <Row gutter={[0, 16]}>
      {projects.map((project, index) => (
        <Col key={index} span={24} className={styles.wrapper}>
          <Project project={project} />
        </Col>
      ))}
    </Row>
  );
};
