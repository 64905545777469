import React from 'react';
import SpinFC from 'antd/lib/spin';
import { Form, notification, Row, Col, Button, Alert } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AnyObject, YMapsApi } from 'react-yandex-maps';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { makeAddressString } from 'utils/entities';
import { InputField } from 'components/form/base/InputField';
import { AddressSelector } from 'components/form/AddressSelector/AddressSelector';
import { FormMap } from 'components/form/FormMap/FormMap';

import { PolylineModal } from '../PolylineModal/PolylineModal';
import { PolylinesMap } from '../PolylineModal/components/PolylinesMap/PolylinesMap';
import styles from './InvestInfrastructureLocation.module.less';

type LocationSelectorProps = {
  name: string;
  mapPositionName?: string;
};

export const InvestInfrastructureLocation: React.FC<LocationSelectorProps> = ({
  name,
  mapPositionName = 'mapPosition',
}: LocationSelectorProps) => {
  const [isSearching, setIsSearching] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [ymaps, setYmaps] = React.useState<YMapsApi | null>(null);
  const [polygonPosition, setPolygonPosition] = React.useState<number[][][]>(
    []
  );
  const polygonValuesLength = polygonPosition?.[0]?.length || 0;

  const { control, setValue, getValues } = useFormContext();
  const [polylines, mapPosition] = useWatch({
    name: ['polylines', `${name}.mapPosition`],
    control,
  });

  React.useEffect(() => {
    if (polylines) {
      setPolygonPosition([polylines]);
    }
  }, [polylines]);

  const onMapPositionChange = (coordinates: number[]) => {
    const coordinatesToString = coordinates.map(String);
    setValue(`${name}.${mapPositionName}`, [...coordinatesToString], {
      shouldValidate: true,
    });
  };

  const onMapPolygonChange = (coordinates: number[][][]) => {
    let coordinatesToString = [];

    if (coordinates?.length) {
      coordinatesToString = coordinates.map(item =>
        item.map(item => [String(item[0]), String(item[1])])
      )[0];
    }

    setValue('polylines', [...coordinatesToString], {
      shouldValidate: true,
    });
  };

  const onMapLoad = (map: YMapsApi) => {
    setYmaps(map);
  };

  const search = () => {
    const address = getValues(`${name}`);

    if (address && ymaps) {
      setIsSearching(true);
      ymaps
        .geocode(['Россия', makeAddressString(address)].join(', '), {
          results: 1,
          json: true,
        })
        .then((result: AnyObject) => {
          const coordinates =
            result?.GeoObjectCollection?.metaDataProperty
              ?.GeocoderResponseMetaData?.Point?.coordinates;
          setIsSearching(false);
          if (coordinates) {
            onMapPositionChange(coordinates.reverse());
          } else {
            notification.error({
              message: 'Адрес не найден',
            });
          }
        })
        .catch((err: unknown) => {
          notification.error({
            message: 'Ошибка при поиске адреса',
          });
        });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <Row>
            <Col lg={{ span: 10 }} xs={{ span: 24 }} flex={1}>
              <AddressSelector
                errors={fieldState.error}
                search={search}
                isSearching={isSearching}
                {...field}
              />
            </Col>

            <Col
              lg={{ span: 14 }}
              xs={{ span: 24 }}
              style={{
                position: 'relative',
                minHeight: '200px',
                display: 'block',
              }}
            >
              {isSearching && (
                <div
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 40,
                    zIndex: 1,
                  }}
                >
                  <SpinFC />
                </div>
              )}

              <Form.Item
                help={fieldState.error?.[mapPositionName]?.message}
                validateStatus={
                  fieldState.error?.[mapPositionName] ? 'error' : 'success'
                }
              >
                <FormMap
                  {...field}
                  onChange={onMapPositionChange}
                  onMapLoad={onMapLoad}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} flex={1}>
              <InputField
                name={`${name}.comment`}
                label='Комментарий к адресу'
                showCount
                maxLength={255}
                placeholder='Комментарий к адресу'
              />
            </Col>
          </Row>

          <Col span={24}>
            <Row>
              <div className={styles.sectionTitle}>Контур</div>
            </Row>
            <Row gutter={[24, 0]} className={styles.addCircuit}>
              <Button
                type='link'
                disabled={Boolean(polygonValuesLength) || !mapPosition}
                icon={<PlusCircleOutlined />}
                onClick={() => setIsOpenModal(true)}
                className={styles.addCircuitBtn}
              >
                Добавить контур
              </Button>
              {polygonValuesLength > 0 && (
                <Button
                  type='text'
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setPolygonPosition([]);
                    onMapPolygonChange([]);
                  }}
                >
                  Удалить контур
                </Button>
              )}
            </Row>
          </Col>

          {!polygonValuesLength &&
            (mapPosition ? (
              <Alert
                className={styles.alert}
                message='Можно добавить контур'
                type='success'
                showIcon
              />
            ) : (
              <Alert
                className={styles.alert}
                message='Чтобы добавить контур, нужно указать адрес'
                type='warning'
                showIcon
              />
            ))}

          {polygonValuesLength > 0 && (
            <Col span={24} className={styles.circuitMap}>
              <Row>
                <PolylinesMap
                  height={354}
                  polygonPosition={polygonPosition}
                  isOpenModal={isOpenModal}
                  setPolygonPosition={setPolygonPosition}
                  setIsOpenModal={setIsOpenModal}
                />
              </Row>
            </Col>
          )}
          {isOpenModal && (
            <Form.Item>
              <PolylineModal
                isOpenModal={isOpenModal}
                polygonPosition={polygonPosition}
                onMapPolygonChange={onMapPolygonChange}
                setIsOpenModal={setIsOpenModal}
                setPolygonPosition={setPolygonPosition}
              />
            </Form.Item>
          )}
        </>
      )}
    />
  );
};
