import React from 'react';
import { Col, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { MainLayout } from '../Layout/MainLayout';

export interface IProps {
  pageTitle?: string;
}

const CardSpin: React.FC<IProps> = ({ pageTitle = 'Редактирование' }) => {
  return (
    <MainLayout pageTitle={pageTitle}>
      <Content>
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      </Content>
    </MainLayout>
  );
};

export default CardSpin;
