import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';

import { IProjectsItem } from 'store/slices/projects/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import {
  applicationsStatusesColorsHash,
  applicationsStatusesNamesHash,
} from 'constants/applicationsStatus';
import { OrganizationTypeEnum } from 'types/organizations';
import { projectStatusNames } from 'types/projects';

interface IProps {
  data: IProjectsItem[];
  total: number;
  limit?: number;
  getActions: (item: IProjectsItem) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: '№ заявки',
      width: 80,
      dataIndex: 'id',
      render: id => (
        <Link
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'inherit',
          }}
          to={`/projects/${id}/view`}
        >
          <Typography style={{ color: '#1890FF' }}>{id}</Typography>
        </Link>
      ),
    },
    {
      key: 2,
      title: 'Дата и время заявки',
      width: 110,
      dataIndex: 'createdAt',
      sorter: true,
      render: createdAt => (
        <Typography>{dayjs(createdAt).format('DD.MM.YYYY HH:mm')}</Typography>
      ),
    },
    {
      key: 3,
      title: 'Название',
      dataIndex: 'name',
      sorter: true,
      render: (text, item) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/projects/${item.id}/view`}
          >
            <div>{(text && text.trim()) || 'Без названия'}</div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 4,
      title: 'Инициатор',
      dataIndex: 'organization',
      width: 145,
      sorter: true,
      render: ({
        type,
        fullName,
        firstName,
        lastName,
        secondName,
      }: IProjectsItem['organization']) => (
        <Typography>
          {type === OrganizationTypeEnum.ip ? (
            <div>
              ИП {lastName} {firstName.charAt(0)}. {secondName.charAt(0)}.
            </div>
          ) : (
            fullName
          )}
        </Typography>
      ),
    },
    {
      key: 5,
      width: 140,
      title: 'Статус проекта',
      dataIndex: 'projectStatus',
      render: projectStatus => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{projectStatusNames[projectStatus]}</Typography>
        </div>
      ),
    },
    {
      key: 6,
      width: 140,
      title: 'Статус заявки',
      dataIndex: 'status',
      render: status => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tag
            color={applicationsStatusesColorsHash[status]}
            style={{ marginRight: 0 }}
          >
            {applicationsStatusesNamesHash[status]}
          </Tag>
        </div>
      ),
    },
    {
      key: 7,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IProjectsItem) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
    />
  );
};
