import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, notification, Row, UploadFile } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import deepEqual from 'fast-deep-equal/react';

import Yup from 'utils/yup';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { FormDocumentUpload } from 'components/form/FormDocumentUpload/FormDocumentUpload';
import { useAppDispatch, useAppSelector } from 'store';
import { saveReportComment } from 'store/slices/reportComments/actions';
import { loadReportDocumentsList } from 'store/slices/reportDocuments/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { Avatar } from 'components/Avatar/Avatar';
import { resetFilters } from 'store/slices/formDocuments/formDocumentsSlice';

import { FormCommentsFiles } from '../FormCommentsFiles/FormCommentsFiles';
import styles from './FormCommentsCreateComment.module.less';

type TProps = {
  refetchComments: () => void;
};

const validationSchema = Yup.object().shape({
  text: Yup.string().trim().max(1000).required(),
});

const DEFAULT_STATE = {
  text: '',
  documents: [],
  document: null,
};

export const FormCommentsCreateComment: React.FC<TProps> = ({
  refetchComments,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { firstName, lastName } = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: DEFAULT_STATE,
    resolver,
  });

  const comment = methods.watch();

  const handleAddDocumentToArray = () => {
    if (comment.document) {
      const newValue = [...comment.documents, { document: comment.document }];
      methods.setValue('documents', newValue);
      methods.setValue('document', null);
      notification.success({
        message: 'Файл успешно загружен',
      });
      setIsLoading(false);
    }
  };

  useDeepEffect(() => {
    handleAddDocumentToArray();
  }, [comment.document]);

  const onSubmit = async () => {
    if (!deepEqual(DEFAULT_STATE, comment)) {
      setIsLoading(true);
      const preparedValues = {
        reportId: id,
        text: comment.text,
        ...(comment.documents.length && {
          documents: comment.documents.map(({ document }) => document),
        }),
      };

      dispatch(saveReportComment(preparedValues))
        .unwrap()
        .then(() => {
          dispatch(resetFilters(true));
          methods.reset(DEFAULT_STATE);
          refetchComments();
          setIsLoading(false);
          dispatch(loadReportDocumentsList({ id }));
        })
        .catch(() => setIsLoading(false));
    }
  };

  const handleDeleteFilesItem = (file: UploadFile) => {
    const filteredDocuments = comment.documents.filter(
      ({ document }) => document.path !== file.uid
    );
    methods.setValue('documents', filteredDocuments);
  };

  return (
    <FormProvider {...methods}>
      <Row className={styles.container}>
        <Col className={styles.avatar}>
          <Avatar firstName={firstName} lastName={lastName} />
        </Col>
        <Col flex='auto'>
          <Row className={styles.bottomContainer}>
            <Col span={24}>
              <TextAreaField
                name='text'
                maxLength={1000}
                onBlur={() => methods.clearErrors()}
                placeholder='Введите текст комментария'
              />
            </Col>

            {!!comment.documents.length && (
              <Col span={24} className={styles.filesContainer}>
                <FormCommentsFiles
                  data={comment.documents}
                  onRemove={handleDeleteFilesItem}
                />
              </Col>
            )}

            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col>
                  <Button
                    type='primary'
                    onClick={() => methods.handleSubmit(onSubmit)()}
                    loading={isLoading}
                  >
                    Добавить комментарий
                  </Button>
                </Col>
                <Col className={styles.uploadButton}>
                  <FormDocumentUpload
                    name='document'
                    title='Загрузить файл'
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    accept='.jpg, .jpeg, .pdf, .xls, .xlsx'
                    errorMessage='У файла недопустимый формат. Загрузите файл с расширением .jpg, .jpeg, .pdf, .xls, .xlsx'
                    validFormatsList={[
                      'image/jpg',
                      'image/jpeg',
                      'application/pdf',
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormProvider>
  );
};
