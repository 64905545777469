import React from 'react';
import { Button } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

interface IProps {
  onMove: (index: number, step: number) => void;
  index: number;
  count: number;
}

export const ExtraArrowMenu = ({ onMove, index, count }: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '16px',
      }}
      onClick={e => e.stopPropagation()}
    >
      <div>
        <Button
          size='small'
          onClick={() => onMove(index, index - 1)}
          icon={<ArrowUpOutlined />}
          disabled={index === 0}
        />
        <Button
          size='small'
          onClick={() => onMove(index, index + 1)}
          icon={<ArrowDownOutlined />}
          disabled={index + 1 >= count}
          style={{ marginLeft: 4, marginRight: 16 }}
        />
      </div>
    </div>
  );
};
