import React from 'react';
import { Descriptions, Typography } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { useAppSelector } from 'store';
import { OrganizationTypeEnum } from 'types/organizations';
import { TaxSystemNames } from 'constants/organizations';
import { getName } from 'pages/Organizations/utils';
import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';
import { formatPhoneNumber } from 'utils/format';

import styles from './Requisites.module.less';

export const Requisites = () => {
  const { item } = useAppSelector(state => state.organizations);

  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;

  const {
    type,
    fullName,
    firstName,
    secondName,
    lastName,
    createdAt,
    okpo,
    ogrn,
    inn,
    taxSystemIp,
    taxSystemOrganization,
    okved,
    okato,
    oktmo,
    registerAddress,
    actualAddress,
    phone,
    email,
    siteUrl,
    bik,
    bankName,
    corrAccount,
    account,
    kpp,
    shortName,
    ownerData,
  } = item;

  return (
    <Descriptions
      labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
      title=''
      size='small'
      column={4}
      layout='vertical'
    >
      <Descriptions.Item
        className={styles.row}
        label='Полное название'
        span={4}
      >
        {(type === OrganizationTypeEnum.organization
          ? fullName
          : getName({ firstName, lastName, secondName }, true)) || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Краткое название'
        span={4}
      >
        {(type === OrganizationTypeEnum.organization
          ? shortName
          : getName({ firstName, lastName, secondName }, true, true)) || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ФИО руководителя'
        span={4}
      >
        {getName(ownerData) || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Дата и время регистрации'
        span={4}
      >
        {createdAt ? (
          dayjs(createdAt).format('DD.MM.YYYY')
        ) : (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ИНН'
        span={isMobile ? 4 : 1}
      >
        {inn || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Система налогообложения'
        span={isMobile ? 4 : 1}
      >
        {
          TaxSystemNames[
            type === OrganizationTypeEnum.organization
              ? taxSystemOrganization
              : taxSystemIp
          ]
        }
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label={type === OrganizationTypeEnum.organization ? 'ОГРН' : 'ОГРНИП'}
        span={isMobile ? 4 : 1}
      >
        {ogrn || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ОКПО'
        span={isMobile ? 4 : 1}
      >
        {okpo || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ОКВЭД'
        span={isMobile ? 4 : 1}
      >
        {okved || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ОКАТО'
        span={isMobile ? 4 : 1}
      >
        {okato || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='ОКТМО'
        span={isMobile ? 4 : 1}
      >
        {oktmo || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='КПП'
        span={isMobile ? 4 : 1}
      >
        {kpp || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Адрес регистрации'
        span={4}
      >
        {registerAddress || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Фактический адрес'
        span={4}
      >
        {actualAddress || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Телефон'
        span={isMobile ? 4 : 1}
      >
        {formatPhoneNumber(phone) || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Email'
        span={isMobile ? 4 : 1}
      >
        {email || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label='Адрес сайта'
        span={isMobile ? 4 : 1}
      >
        {siteUrl || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={styles.row}
        label=''
        span={isMobile ? 4 : 1}
      >
        {''}
      </Descriptions.Item>
      <Descriptions.Item
        className={classNames(styles.row, { [styles.rowLast]: !isMobile })}
        label='Расчетный счёт'
        span={isMobile ? 4 : 1}
      >
        {account || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={classNames(styles.row, { [styles.rowLast]: !isMobile })}
        label='БИК банка'
        span={isMobile ? 4 : 1}
      >
        {bik || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={classNames(styles.row, { [styles.rowLast]: !isMobile })}
        label='Название банка'
        span={isMobile ? 4 : 1}
      >
        {bankName || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        className={classNames(styles.row, styles.rowLast)}
        label='Корреспондентский счёт'
        span={isMobile ? 4 : 1}
      >
        {corrAccount || (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
