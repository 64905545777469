import { LangEnumKeys } from 'constants/lang';

export enum MenuTypeEnum {
  headerB2b = 'headerB2b',
  headerB2c = 'headerB2c',
  footerB2b = 'footerB2b',
  footerB2c = 'footer',
}

export type TMenuType = `${MenuTypeEnum}`;

type TMenuItemData = {
  name: string;
  itemName: string;
  isActive: true;
  sort: number;
  openNewPage: true;
  url: string;
};

export type TMenuItem = TMenuItemData & {
  subItems?: TMenuItemData[];
};

export interface IMenuRequestBody {
  menuType: TMenuType;
  lang: LangEnumKeys;
  items: Omit<TMenuItem, 'itemName'>[];
}

export interface IMenuRequestParams {
  menuType: TMenuType;
  lang: LangEnumKeys;
}

export interface IMenuResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  parent: string; // ID
  menuType: TMenuType;
  lang: LangEnumKeys;
  isActive: true;
  sort: number;
  openNewPage: true;
  url: string;
  creator: number;
  editor: number;
  children: [
    {
      id: string;
      createdAt: string;
      updatedAt: string;
      name: string;
      parent: string; // ID
      menuType: LangEnumKeys;
      lang: LangEnumKeys;
      isActive: true;
      sort: number;
      openNewPage: true;
      url: string;
      creator: number;
      editor: number;
    }
  ];
}
