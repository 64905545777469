// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormCommentsCreateComment-module__container--br0Up {\n  flex-wrap: nowrap;\n}\n.FormCommentsCreateComment-module__avatar--uAorv {\n  flex: 0 0 38px;\n  padding-right: 16px;\n}\n@media (max-width: 575px) {\n  .FormCommentsCreateComment-module__avatar--uAorv {\n    display: none;\n  }\n}\n.FormCommentsCreateComment-module__filesContainer--plIQI {\n  margin-bottom: 24px;\n}\n@media (max-width: 466px) {\n  .FormCommentsCreateComment-module__uploadButton--jNA3G {\n    margin-top: 24px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormComments/components/FormCommentsCreateComment/FormCommentsCreateComment.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,cAAA;EACA,mBAAA;AAAF;AAEE;EAAA;IACE,aAAA;EACF;AACF;AAEA;EACE,mBAAA;AAAF;AAIE;EAAA;IACE,gBAAA;EADF;AACF","sourcesContent":[".container {\n  flex-wrap: nowrap;\n}\n\n.avatar {\n  flex: 0 0 38px;\n  padding-right: 16px;\n\n  @media (max-width: 575px) {\n    display: none;\n  }\n}\n\n.filesContainer {\n  margin-bottom: 24px;\n}\n\n.uploadButton {\n  @media (max-width: 466px) {\n    margin-top: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FormCommentsCreateComment-module__container--br0Up",
	"avatar": "FormCommentsCreateComment-module__avatar--uAorv",
	"filesContainer": "FormCommentsCreateComment-module__filesContainer--plIQI",
	"uploadButton": "FormCommentsCreateComment-module__uploadButton--jNA3G"
};
export default ___CSS_LOADER_EXPORT___;
