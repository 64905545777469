import { LangEnum } from 'constants/lang';
import { prepareContent } from 'components/form/Content/utils';
import { DEFAULT_SEO_STATE } from 'constants/seo';
import { StatusesEnum } from 'constants/status';
import {
  VacanciesRevenueTypeEnum,
  VacanciesTempAddressFlagEnum,
} from 'types/vacancies';

export const INITIAL_VALUES = {
  lang: LangEnum.ru,
  name: '',
  specialization: null,
  revenueType: VacanciesRevenueTypeEnum.BeforeDeduction,
  revenueFrom: '',
  revenueTo: '',
  organization: '',
  addressFlag: VacanciesTempAddressFlagEnum.ExcludeAddress,
  address: {},
  workType: null,
  workSchedule: null,
  workExperience: null,
  description: prepareContent([]),
  ...DEFAULT_SEO_STATE,
  status: StatusesEnum.PUBLISHED,
};
