// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconTooltip-module__tooltip--vP1xP {\n  width: 184px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PriorityIndustry/components/PriorityIndustryForm/components/DirectionForm/components/IconTooltip/IconTooltip.module.less"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD","sourcesContent":[".tooltip {\n\twidth: 184px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "IconTooltip-module__tooltip--vP1xP"
};
export default ___CSS_LOADER_EXPORT___;
