import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import classNames from 'classnames';

import styles from './MenuItemHeader.module.less';

interface IProps {
  index: number;
  name: string;
  parentName?: string;
  depthLevel: number;
}

const headerDefaultNestedNames = ['первого', 'второго'].reverse();

export const MenuItemHeader = ({
  name,
  index,
  depthLevel,
  parentName,
}: IProps) => {
  const { control } = useFormContext();

  const [text = '', isActive] = useWatch({
    name: [`${name}.name`, `${name}.isActive`],
    control,
  });

  const parentIsActive = useWatch({
    name: `${parentName}.isActive`,
    control,
  });

  const headerDefaultName = `Пункт меню ${headerDefaultNestedNames[depthLevel]} уровня`;

  const disabledByParent = parentIsActive !== undefined && !parentIsActive;

  return (
    <div className={styles.wrapper}>
      <Typography.Text
        type={isActive && !disabledByParent ? undefined : 'secondary'}
        className={styles.title}
      >
        {`${index + 1}. `}
        {isActive && !disabledByParent ? (
          <EyeOutlined className={classNames(styles.svg, styles.activeEye)} />
        ) : (
          <EyeInvisibleOutlined className={styles.svg} />
        )}{' '}
        {`${text || headerDefaultName}`}
      </Typography.Text>
    </div>
  );
};
