// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VacanciesForm-module__radioContainer--CxNWu {\n  margin-top: 16px;\n  display: flex;\n  gap: 16px;\n}\n.VacanciesForm-module__priceInputs--ecPjv {\n  margin-top: 16px;\n}\n@media (max-width: 576px) {\n  .VacanciesForm-module__priceInputsItem--CNwIA {\n    width: 100%;\n  }\n}\n.VacanciesForm-module__priceWithIcon--E55Vg {\n  vertical-align: text-top;\n}\n.VacanciesForm-module__locationContainer--JHVj1 {\n  margin-top: 16px;\n}\n.VacanciesForm-module__bottomSelectors--_lfwq {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Vacancies/components/VacanciesForm/VacanciesForm.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,SAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAIE;EAAA;IACE,WAAA;EADF;AACF;AAIA;EACE,wBAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,gBAAA;AAJF","sourcesContent":[".radioContainer {\n  margin-top: 16px;\n  display: flex;\n  gap: 16px;\n}\n\n.priceInputs {\n  margin-top: 16px;\n}\n\n.priceInputsItem {\n  @media (max-width: 576px) {\n    width: 100%;\n  }\n}\n\n.priceWithIcon {\n  vertical-align: text-top;\n}\n\n.locationContainer {\n  margin-top: 16px;\n}\n\n.bottomSelectors {\n  margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioContainer": "VacanciesForm-module__radioContainer--CxNWu",
	"priceInputs": "VacanciesForm-module__priceInputs--ecPjv",
	"priceInputsItem": "VacanciesForm-module__priceInputsItem--CNwIA",
	"priceWithIcon": "VacanciesForm-module__priceWithIcon--E55Vg",
	"locationContainer": "VacanciesForm-module__locationContainer--JHVj1",
	"bottomSelectors": "VacanciesForm-module__bottomSelectors--_lfwq"
};
export default ___CSS_LOADER_EXPORT___;
