import React from 'react';
import { notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import dayjs from 'dayjs';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import {
  loadReportsList,
  removeReportItem,
} from 'store/slices/reports/actions';
import { IReport } from 'store/slices/reports/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { useDeepEffect } from 'utils/useDeepEffect';
import { pick, removeEmptyValues } from 'utils/helpers';
import { PageTitles } from 'constants/pageTitles';
import { TReportStatus } from 'constants/reports';

import { Filters } from './components/Filters/Filters';
import { ListTable } from './components/ListTable/ListTable';
import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<IReport>(null);

  const query = useQuery();

  const dispatch = useAppDispatch();
  const { list, total, limit } = useAppSelector(state => state.reports);

  const loadReports = () =>
    dispatch(
      loadReportsList({
        ...removeEmptyValues(pick(query, 'limit', 'offset')),
        sort,
        organizationName: (query.name as string) || null,
        statuses: (query.status as TReportStatus) || null,
        createdAtFrom:
          query.createdAtFrom &&
          dayjs(query.createdAtFrom as string, 'DD.MM.YYYY')
            .startOf('day')
            .toDate(),
        createdAtTo:
          query.createdAtTo &&
          dayjs(query.createdAtTo as string, 'DD.MM.YYYY')
            .endOf('day')
            .toDate(),
      })
    );

  useDeepEffect(() => {
    loadReports();
  }, [query, sort]);

  const handleCloseModalAction = React.useCallback(() => {
    setModalIsOpen(null);
    setCurrentItem(null);
  }, []);

  const handleRemove = React.useCallback(
    () =>
      dispatch(removeReportItem(currentItem.id))
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Отчет успешно удален',
          });
          loadReports();
          handleCloseModalAction();
        }),
    [currentItem]
  );

  const getActions = React.useCallback(
    (item: IReport) => [
      {
        key: 'view',
        label: <Link to={`/reports/${item.id}/view`}>Просмотр</Link>,
      },
      {
        key: 'delete',
        label: <Typography.Text type='danger'>Удалить</Typography.Text>,
        onClick: () => {
          setModalIsOpen(true);
          setCurrentItem(item);
        },
      },
    ],
    []
  );

  return (
    <MainLayout pageTitle={PageTitles.reports} aside={<Filters />}>
      <Content>
        <ListTable
          setSort={setSort}
          getActions={getActions}
          data={list}
          total={total}
          limit={limit}
        />
      </Content>
      {currentItem?.id && modalIsOpen && (
        <ConfirmModal
          onRemove={handleRemove}
          onCloseModalAction={handleCloseModalAction}
          modalIsOpen={modalIsOpen}
          currentItem={currentItem}
        />
      )}
    </MainLayout>
  );
};
