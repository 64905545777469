import {
  IMenuRequestBody,
  IMenuResponse,
  TMenuItem,
  TMenuType,
} from 'store/slices/menu/interfaces';
import { LangEnumKeys } from 'constants/lang';
import { omit } from 'utils/helpers';

import { IFormValues } from './MenuForm';
import { MENU_OPTIONS } from '../constants';

const mapItemsToForm = (values, parentName?: string) =>
  values.map(({ name, isActive, openNewPage, url, ...restItems }, index) => ({
    itemName: name,
    isActive,
    openNewPage,
    parentName,
    url: url || '',
    ...(restItems.children?.length && {
      subItems: mapItemsToForm(restItems.children, `${parentName}.${index}`),
    }),
  }));

export const prepareValuesToForm = (
  values: IMenuResponse[],
  lang: LangEnumKeys,
  menuType: TMenuType
): IFormValues => ({
  lang,
  menuType,
  type: menuType,
  menuOptions:
    MENU_OPTIONS.find(option => option.value === menuType) || MENU_OPTIONS[0],
  items: mapItemsToForm(values, 'items'),
});

const mapItems = (values: TMenuItem[]) =>
  values.map((item, index) => {
    return {
      ...omit(item, 'parentName', 'itemName'),
      name: item.itemName,
      sort: index,
      url: item.url || null,
      ...(item.subItems?.length && { subItems: mapItems(item.subItems) }),
    };
  });

export const mapValues = (values: IFormValues): IMenuRequestBody => ({
  menuType: values.type,
  lang: values.lang,
  items: mapItems(values.items as TMenuItem[]),
});

export const changeSortParentNames = (values, parentName?: string) =>
  values.map((item, index) => ({
    ...item,
    parentName,
    ...(item.subItems?.length && {
      subItems: changeSortParentNames(
        item.subItems,
        `${parentName}.${index + 1}`
      ),
    }),
  }));
