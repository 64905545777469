export enum OpeningInfoKind {
  Chapter = 'chapter',
  Strategy = 'strategy',
  Annual = 'annual',
  Landmark = 'landmark',
  Conditions = 'conditions',
}

export const openingInfoHeaders = {
  [OpeningInfoKind.Chapter]: 'Устав АО Кавказ.РФ',
  [OpeningInfoKind.Strategy]: 'Стратегия компании',
  [OpeningInfoKind.Annual]: 'Годовой отчет',
  [OpeningInfoKind.Landmark]:
    'Сведения о свободных земельных участках, расположенных в границах ОЭЗ',
  [OpeningInfoKind.Conditions]: 'Условия труда сотрудников АО «КАВКАЗ.РФ»',
};
