// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slider-module__slider--gwyRV {\n  position: absolute;\n\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n.Slider-module__items--tPuIC {\n  position: relative;\n\n  display: flex;\n  height: 100%;\n}\n\n.Slider-module__allItems--kOWIg {\n  display: none;\n}\n\n.Slider-module__item--ZSDpD {\n  z-index: 0;\n\n  flex: 0 0 100%;\n\n  user-select: none;\n  -webkit-user-drag: none;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/form/Slider/Slider.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,kBAAkB;;EAElB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;;EAEV,cAAc;;EAEd,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".slider {\n  position: absolute;\n\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n.items {\n  position: relative;\n\n  display: flex;\n  height: 100%;\n}\n\n.allItems {\n  display: none;\n}\n\n.item {\n  z-index: 0;\n\n  flex: 0 0 100%;\n\n  user-select: none;\n  -webkit-user-drag: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "Slider-module__slider--gwyRV",
	"items": "Slider-module__items--tPuIC",
	"allItems": "Slider-module__allItems--kOWIg",
	"item": "Slider-module__item--ZSDpD"
};
export default ___CSS_LOADER_EXPORT___;
