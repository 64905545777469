import { GlobalOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Col, Collapse, Row, Tooltip } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormActions } from 'components/Layout/FormActions/FormActions';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { Contacts } from 'components/form/Contacts';
import { ContentField } from 'components/form/Content/ContentField';
import { Gallery } from 'components/form/Gallery/Gallery';
import { GetThere } from 'components/form/GetThere';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { MainImage } from 'components/form/MainImage/MainImage';
import { SEO } from 'components/form/SEO/SEO';
import { Schedule } from 'components/form/Schedule/Schedule';
import { Status } from 'components/form/Status/Status';
import { InputField } from 'components/form/base/InputField';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { MAIN_IMAGE_ASPECT_RATIO, PREVIEW_ASPECT_RATIO } from 'constants/image';
import { useAppDispatch, useAppSelector } from 'store';
import { savePreviewData } from 'store/slices/preview/actions';
import { sourcePreview } from 'store/slices/preview/interfaces';
import { IResortDraftItem, IResortItem } from 'store/slices/resorts/interfaces';
import { isEmpty, isString } from 'utils/helpers';
import { useDeepEffect } from 'utils/useDeepEffect';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';
import { PANNEL_NAMES } from '../utils';
import { IFormValues } from '../interfaces';
import { AttractionForm } from './AttractionForm/AttractionForm';
import { HotelForm } from './HotelForm/HotelForm';
import { RestaurantForm } from './RestaurantForm/RestaurantForm';

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IResortItem) => void;
  onSaveDraft: (values: IResortDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
  itemId?: number;
};

export const ResortsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
  itemId,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] =
    React.useState(PANNEL_NAMES);
  const dispatch = useAppDispatch();
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), category: null, tags: [] });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  const currentLanguage = methods.watch('lang.value');

  const handlePreviewClick = async () => {
    const results = await (dispatch(
      savePreviewData({
        source: sourcePreview.resorts,
        ...(itemId !== undefined && { sourceId: itemId }),
        data: methods.getValues(),
      })
    ) as Promise<{ payload: { id: number; sysName: string } }>);
    const id = results.payload.id;
    const sysName = results.payload.sysName;

    const linkToB2C =
      currentLanguage === 'en'
        ? `${b2cUrl}/en/resorts/preview/${id}/${sysName}`
        : `${b2cUrl}/resorts/preview/${id}/${sysName}`;
    window.open(linkToB2C, '_blank');
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='heroImage'
                label='Глав. фото'
                required
                aspectRatio={MAIN_IMAGE_ASPECT_RATIO}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Название места'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='category'
                label='Категория'
                placeholder='Выберите категорию'
                required
                requestParams={{
                  entityType: 'resorts',
                  fieldType: 'categories',
                  lang: langValue,
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='tags'
                label='Tеги'
                placeholder='Выберите теги'
                requestParams={{
                  entityType: 'resorts',
                  fieldType: 'tags',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='externalUrl'
                label='Ссылка на сайт с покупкой ски-пасса'
                addonBefore={<GlobalOutlined />}
                placeholder='Укажите ссылку'
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание места' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Адрес' key='address'>
            <LocationSelector name='address' />
          </Collapse.Panel>
          <Collapse.Panel header='Как добраться' key='getThere'>
            <GetThere
              name='getThere'
              entityType='resorts'
              fieldType='getThere'
            />
          </Collapse.Panel>
          <Collapse.Panel header='Режим работы' key='workTime'>
            <Schedule name='workTime' />
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <Contacts name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>Достопримечательности</div>
                <Tooltip
                  placement='top'
                  title={`Необходимое количество: не меньше 4 и не больше 8`}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            key='attractions'
          >
            <AttractionForm />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>Отели</div>
                <Tooltip
                  placement='top'
                  title={`Необходимое количество: не меньше 4 и не больше 8`}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            key='hotels'
          >
            <HotelForm />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>Кафе и рестораны</div>
                <Tooltip
                  placement='top'
                  title={`Необходимое количество: не меньше 4 и не больше 8`}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            key='restaurants'
          >
            <RestaurantForm />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft ? (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          ) : null}
          <Button onClick={handlePreviewClick} disabled={isLoading}>
            Предпросмотр
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
