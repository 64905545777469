import React from 'react';

import { Slider } from '../Slider/Slider';
import { AddSlide } from '../Slider/AddSlide';
import { EditSlide } from '../Slider/EditSlide';
import { Info } from './components/Info/Info';
import { AddInfo } from './components/Info/AddInfo';
import { EditInfo } from './components/Info/EditInfo';
import { AboutRegion } from './AboutRegion';
import { Description } from './components/Description/Description';

export const aboutRegionRoutes = {
  path: '/aboutRegion',
  children: [
    {
      path: '',
      element: <AboutRegion />,
    },
    {
      path: ':lang/slider',
      element: <Slider slug='aboutRegion' name='about-region' />,
    },
    {
      path: ':lang/slider/:sliderName/add',
      element: <AddSlide slug='aboutRegion' withImage />,
    },
    {
      path: ':lang/slider/:sliderName/:sliderItemId/edit',
      element: <EditSlide slug='aboutRegion' withImage />,
    },
    {
      path: 'aboutRegionInfo',
      element: <Info />,
    },
    {
      path: 'aboutRegionInfo/:cardId/add',
      element: <AddInfo />,
    },
    {
      path: 'aboutRegionInfo/:cardId/:cardItemId/edit',
      element: <EditInfo />,
    },
    {
      path: 'description',
      element: <Description />,
    },
  ],
};
