import React from 'react';
import ReactMaskedInput from 'react-maskedinput';
import classNames from 'classnames';

interface IMaskedInputProps {
  value: string;
  name: string;
  mask: string;
  placeholder: string;
  addonBefore?: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  ref: React.ForwardedRef<HTMLInputElement>;
  error: any;
}

export const MaskedInput: React.FC<IMaskedInputProps> = React.forwardRef(
  (
    { value, name, mask, placeholder, onChange, addonBefore, disabled, error },
    ref: any
  ) => {
    const preparedValue = value.startsWith('+')
      ? value.slice(addonBefore.length)
      : value;
    return (
      <div className='ant-col ant-form-item-control'>
        <div className='ant-form-item-control-input'>
          <div className='ant-input-wrapper ant-input-group'>
            {addonBefore && (
              <div
                className='ant-input-group-addon'
                style={{
                  ...(error && {
                    borderColor: 'var(--ant-error-color)',
                    borderStyle: 'solid',
                    borderWidth: '1px 0 1px 1px',
                    color: 'var(--ant-error-color)',
                  }),
                }}
              >
                +7
              </div>
            )}
            <ReactMaskedInput
              ref={ref}
              name={name}
              value={preparedValue}
              className={classNames(
                'ant-input',
                error ? 'ant-input-status-error' : null
              )}
              mask={mask}
              placeholder={placeholder}
              onChange={onChange}
              autoComplete='off'
              type='text'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
);

MaskedInput.displayName = 'MaskedInput';
