import { Button, Card, Col, Collapse, Row } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty } from 'utils/helpers';
import { MainImage } from 'components/form/MainImage/MainImage';
import { InputField } from 'components/form/base/InputField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { Gallery } from 'components/form/Gallery/Gallery';
import { Status } from 'components/form/Status/Status';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { Select } from 'components/form/base/Select';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import { investmentSolutionNamesHash } from 'constants/investmentSolutionsCategories';
import { AddMaterial } from 'components/form/AddMaterial';

import { INITIAL_VALUES, VALIDATION_SCHEMA } from './constants';
import { FormProps, FormValues } from './types';
import { mapValues, mapValuesAsDraft } from './formUtils';
import { ContentField } from '../../../../components/form/Content/ContentField';
import { ExtensionContactBlock } from '../../../../components/form/ExtensionContactBlock/ExtensionContactBlock';

export const Form = ({
  onSubmit,
  onSaveAsDraft,
  defaultValues = {},
  isDraft = false,
  isLoading,
}: FormProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | string[]>([
    'content',
    'gallery',
    'status',
    'contacts',
    'documents',
  ]);
  const formInitialValues = { ...INITIAL_VALUES, ...defaultValues };
  const methods = useForm<FormValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmitHandler = (values: FormValues) => onSubmit(mapValues(values));

  const cancelButtonHandler = () => navigate('/investmentSolutions');

  const handleSaveAsDraft = () => {
    const formValues = mapValuesAsDraft(methods.getValues());

    onSaveAsDraft(formValues);
  };

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    methods.reset({ ...INITIAL_VALUES, ...defaultValues });
  }, [defaultValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveTab([
        ...Array.from(
          new Set([...activeTab, ...Object.keys(methods.formState.errors)])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues() });
    }
  }, [lang]);

  return (
    <FormProvider {...methods}>
      <form
        className='ant-form ant-form-vertical'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Глав. фото' required />
            </Col>
            <Col flex={1} style={{ minWidth: 300 }}>
              <InputField
                name='name'
                label='Название'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <Select
                name='category'
                label='Категория'
                required
                placeholder='Выберите категорию'
                options={[
                  ...Object.keys(investmentSolutionNamesHash).map(item => ({
                    value: investmentSolutionNamesHash[item],
                    label: investmentSolutionNamesHash[item],
                  })),
                ]}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                placeholder='Выберите регион'
                isNewsPage
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='requiredInvestments'
                label='Объем требуемых инвестиций'
                placeholder='Введите объем требуемых инвестиций'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Введите описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          activeKey={activeTab}
          onChange={(value: string | string[]) => setActiveTab(value)}
        >
          <Collapse.Panel header='Описание' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <ExtensionContactBlock name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Дополнительные материалы' key='documents'>
            <AddMaterial name='documents' />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={formInitialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
