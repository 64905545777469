export enum PageTitles {
  attractions = 'Достопримечательности',
  resorts = 'Курорты',
  hotels = 'Отели',
  restaurants = 'Кафе и рестораны',
  infrastructures = 'Инфраструктура',
  catalog = 'Данные полей',
  news = 'Новости',
  articles = 'Статьи',
  faq = 'Вопрос-ответ',
  touristRoutes = 'Маршруты',
  investmentProjects = 'Инвестиционные проекты',
  investmentPassports = 'Инвестиционные паспорта',
  investInfrastructures = 'Инвестиционные инфраструктуры',
  events = 'События',
  purchasePlans = 'План закупок',
  regulations = 'Нормативные документы',
  purchases = 'Закупки',
  sales = 'Продажи',
  rentals = 'Аренда',
  vacancies = 'Вакансии',
  signedAgreements = 'Сведения о заключенных договорах',
  regions = 'Регионы',
  reviews = 'Отзывы',
  regionRelations = 'Отображение мест на карте',
  admin = 'Администраторы',
  supportMeasures = 'Меры поддержки',
  applications = 'Заявки',
  projects = 'Проекты',
  applicationsForm = 'Заявки из форм',
  materials = 'Материалы',
  organizations = 'Организации',
  business = 'Бизнесмены',
  menu = 'Управление меню',
  priorityIndustry = 'Приоритетные отрасли',
  openingInfo = 'Раскрытие информации',
  tourist = 'Туристы',
  reports = 'Отчетность',
  contractExchange = 'Биржа контрактов',
  mainBusiness = 'Главная бизнес',
  mainRegionEdit = 'Редактирование страницы "О регионе"',
  mainTourism = 'Главная туризм',
  attractionsSample = 'Выборка достопримечательности',
  restaurantsSample = 'Выборка Кафе и рестораны',
  resortsSample = 'Выборка куроты',
  eventsSample = 'Выборка события',
  routesSample = 'Выборка туристические маршруты',
  hotelsSample = 'Выборка отели',
  regionEdit = 'Редактирование региона',
  addSlide = 'Добавление слайда',
  editSlide = 'Редактирование слайда',
  slider = 'Слайдер',
  about = 'Почему Кавказ',
  history = 'Истории успеха',
  help = 'Помощь',
  alert = 'Оповещения',
  alertB2C = 'Оповещения B2C',
  alertB2B = 'Оповещения B2B',
  routes = 'Маршруты',
  activities = 'Активности',
  currentResidents = 'Действующие резиденты',
  investmentSolutions = 'Инвестиционные предложения',
  aboutRegion = 'О регионе',
  aboutRegionInfo = 'Данные об СКФО в цифрах',
  description = 'Описание',
}
