import { createSlice } from '@reduxjs/toolkit';

import {
  loadBusinessList,
  loadIBusinessItem,
  saveBusinessItem,
} from './actions';
import { IBusinessState } from './interfaces';

export const businessSlice = createSlice({
  name: 'business',
  initialState: {
    list: [],
    item: {},
    limit: 20,
    total: 0,
    offset: 0,
  } as IBusinessState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadBusinessList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );

    builder.addCase(loadIBusinessItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });

    builder.addCase(saveBusinessItem.fulfilled, (state, { payload }) => {
      state.item = payload;
    });
  },
});
