import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import { TDocumentsListResponse, TDocumentsFilterParams } from './interfaces';

export const loadDocumentsList = createAsyncThunk<
  TDocumentsListResponse,
  TDocumentsFilterParams,
  { state: RootState }
>('formDocuments/loadList', async ({ formId, ...params }, { getState }) => {
  const { limit } = getState().formDocuments;
  const { data } = await apiClient.get<TDocumentsListResponse>(
    getUrlWithParams(urls.api.formDocuments.get, { formId }),
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});
