import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { ListResponse } from 'types/pagination';
import { IDocument } from 'types/document';

import { IApplication, IOrganization, IProject, IUser } from './interfaces';

export const loadOrganizationList = createAsyncThunk<
  ListResponse<IOrganization>,
  {
    organizationName?: string;
    ownerName?: string;
    isResident?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    hasProjects?: boolean;
    hasApplications?: boolean;
    limit?: number;
    offset?: number;
  },
  { state: RootState }
>('organizations/loadList', async (params, { getState }) => {
  const { limit } = getState().organizations;
  const date: Record<string, string> = {};
  if (params.createdAtFrom) {
    date.createdAtFrom = dayjs(
      params.createdAtFrom,
      'DD.MM.YYYY'
    ).toISOString();
  }
  if (params.createdAtTo) {
    date.createdAtTo = dayjs(params.createdAtTo, 'DD.MM.YYYY').toISOString();
  }
  const { data } = await apiClient.get<ListResponse<IOrganization>>(
    urls.api.organizations.get,
    {
      params: {
        limit,
        sort: '-createdAt',
        ...params,
        ...date,
      },
    }
  );
  return data;
});

export const loadOrganization = createAsyncThunk<IOrganization, string>(
  'organizations/loadItem',
  async id => {
    const { data } = await apiClient.get<IOrganization>(
      getUrlWithParams(urls.api.organizations.getOne, { id })
    );
    return data;
  }
);

export const loadOrganizationUserList = createAsyncThunk<Array<IUser>, string>(
  'organizations/loadUserList',
  async id => {
    const { data } = await apiClient.get<Array<IUser>>(
      getUrlWithParams(urls.api.organizations.getUsers, { id })
    );
    return data;
  }
);

export const loadOrganizationApplicationList = createAsyncThunk<
  ListResponse<IApplication>,
  string
>('organizations/loadApplicationList', async id => {
  const { data } = await apiClient.get<ListResponse<IApplication>>(
    urls.api.formApplications.get,
    { params: { organizationId: id } }
  );
  return data;
});

export const loadOrganizationProjectList = createAsyncThunk<
  ListResponse<IProject>,
  string
>('organizations/loadProjectList', async id => {
  const { data } = await apiClient.get<ListResponse<IProject>>(
    urls.api.formProjects.get,
    { params: { organizationId: id } }
  );
  return data;
});

export const setRoles = createAsyncThunk<
  null,
  {
    users: Array<{ id: number; role: string }>;
    organization: number;
  }
>('organizations/setRoles', async body => {
  const { data } = await apiClient.patch(urls.api.organizations.patchRoles, {
    ...body,
  });
  return data;
});

export const setBlockUser = createAsyncThunk<
  null,
  {
    user: number;
    organization: number;
    isBlocked: boolean;
  }
>('organizations/setBlockUser', async body => {
  const { data } = await apiClient.patch(urls.api.organizations.patchBlock, {
    ...body,
  });
  return data;
});

export const setResident = createAsyncThunk<
  null,
  {
    id: number;
    isResident: boolean;
    documents: Array<IDocument>;
  }
>('organizations/setResident', async params => {
  const { id, ...rest } = params;
  const { data } = await apiClient.patch(
    getUrlWithParams(urls.api.organizations.patchResident, { id }),
    { ...rest }
  );
  return data;
});
