import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IRegionsItem } from 'store/slices/regions/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { loadRegionsItem, saveRegionsItem } from 'store/slices/regions/actions';

import { RegionsForm } from './components/RegionsForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.regions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadRegionsItem(id));
  }, []);

  const onFinish = (values: IRegionsItem) => {
    setIsLoading(true);
    dispatch(saveRegionsItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/regions');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/regions');
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && item.id === Number(id) ? (
        <RegionsForm
          onFinish={onFinish}
          cancelButtonHandler={cancelButtonHandler}
          initialValues={mapValuesToForm(item)}
          isLoading={isLoading}
        />
      ) : null}
    </MainLayout>
  );
};
