import React from 'react';
import { Row, Col, Typography } from 'antd';

import { useAppSelector } from 'store';

import styles from './Users.module.less';
import { User } from './components/User';

export const Users = () => {
  const { users } = useAppSelector(state => state.organizations);

  if (!users.length) {
    return <Typography.Text>Пользователей нет</Typography.Text>;
  }

  return (
    <Row gutter={[0, 24]}>
      {users.map((user, index) => (
        <Col span={24} className={styles.wrapper} key={index}>
          <User user={user} />
        </Col>
      ))}
    </Row>
  );
};
