import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  saveBusinessItem,
  loadIBusinessItem,
} from 'store/slices/business/actions';
import { IBusinessItemRequest } from 'store/slices/business/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import { isEmpty } from 'utils/helpers';

import { EditContent } from './components/EditContent/EditContent';
import { mapValuesToForm } from './components/EditContent/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { item } = useAppSelector(state => state.business);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(item) || item.id !== Number(id)) {
      dispatch(loadIBusinessItem(id));
    }
  }, []);

  const handleSave = (values: IBusinessItemRequest) => {
    setIsLoading(true);
    dispatch(saveBusinessItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Данные успешно сохранены',
        });
        setIsLoading(false);
        navigate(`/business/${id}`);
      })
      .catch(() => {
        notification.error({
          message: 'Cущность не удалось изменить',
        });
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    navigate(`/business/${id}`);
  };

  return (
    <MainLayout aside={<div />} pageTitle='Редактирование'>
      {item.id && (
        <EditContent
          onSave={handleSave}
          onCancel={handleCancel}
          initialValues={mapValuesToForm(item)}
          isLoading={isLoading}
          authType={item.authType}
        />
      )}
    </MainLayout>
  );
};
