/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, notification, Row, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { TImage } from 'types/image';
import { IAddress } from 'types/address';
import { useDeepEffect } from 'utils/useDeepEffect';
import { LangEnumKeys } from 'constants/lang';

import { SampleTable } from './SampleTable';
import { LangSelectSection } from '../form/LangSelectSection/LangSelectSection';
import { MainLayout } from '../Layout/MainLayout';
import { StickyConfirmModal } from '../modals/StickyConfirmModal';

export interface ISampleList {
  data: {
    id: number;
    title: string;
    sortPriority: number;
    image: TImage;
    address?: IAddress;
  }[];
  handleLoadData: (lang?: string) => void;
  handleDelete: (id: number) => Promise<any>;
  isLoading: boolean;
  title?: string;
  limit?: number;
  addON?: boolean;
  deleteON?: boolean;
}

interface IFormValues {
  lang: { value: LangEnumKeys; label: string };
}

export const SampleList: React.FC<ISampleList> = ({
  data,
  handleLoadData,
  handleDelete,
  isLoading,
  title = '',
  limit,
  addON = true,
  deleteON = true,
}) => {
  const methods = useForm<IFormValues>({
    defaultValues: { lang: { value: 'ru', label: 'Русский' } },
  });
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState<Record<'id', number> | null>(
    null
  );

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };
  const handleRemove = (id: number) => {
    handleDelete(id).then(() => {
      notification.success({
        message: 'Cущность успешно удалена',
      });
      modalCloseHandler();
      handleLoadData(lang.value);
    });
  };

  const getActions = useCallback(
    item => {
      const actions: any = [
        {
          key: 'view',
          element: (
            <Link to={`${item.id}/edit`}>
              <Typography.Text>Редактировать</Typography.Text>
            </Link>
          ),
        },
      ];
      if (deleteON) {
        actions.push({
          key: 'remove',
          element: <Typography.Text type='danger'>Удалить</Typography.Text>,
          handler: () => {
            setModalIsOpen(true);
            setCurrentItem(item);
          },
        });
      }

      return actions;
    },
    [deleteON]
  );

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    handleLoadData(lang.value);
  }, [lang]);
  return (
    <MainLayout
      pageTitle={title}
      extraButtonsList={
        addON
          ? [
              <Link key={1} to='add'>
                <Button type='primary'>Создать</Button>
              </Link>,
            ]
          : []
      }
    >
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <LangSelectSection />
        </form>
      </FormProvider>
      {isLoading ? (
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        <Content>
          <SampleTable data={data} getActions={getActions} limit={limit} />
        </Content>
      )}
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление сортировки'
              okText='Удалить'
              text={<div>Вы уверены что хотите, удалить из сортировки?</div>}
              onSubmit={() => handleRemove(currentItem.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
