// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View-module__contact--JK7ey {\n  color: #262626 !important;\n}\n.View-module__contact--JK7ey:hover {\n  color: #1890ff !important;\n}\n.View-module__contactIcon--fa6ZH {\n  color: #262626;\n  margin-right: 9px;\n}\n.View-module__contactIconPhone--t5xvh {\n  color: #262626;\n  margin-right: 9px;\n}\n.View-module__contactIconPhone--t5xvh svg {\n  transform: rotate(90deg);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Admin/View/View.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAEA;EACE,cAAA;EACA,iBAAA;AAAF;AAGA;EACE,cAAA;EACA,iBAAA;AADF;AADA;EAII,wBAAA;AAAJ","sourcesContent":[".contact {\n  color: #262626 !important;\n  &:hover {\n    color: #1890ff !important;\n  }\n}\n\n.contactIcon {\n  color: #262626;\n  margin-right: 9px;\n}\n\n.contactIconPhone {\n  color: #262626;\n  margin-right: 9px;\n  svg {\n    transform: rotate(90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "View-module__contact--JK7ey",
	"contactIcon": "View-module__contactIcon--fa6ZH",
	"contactIconPhone": "View-module__contactIconPhone--t5xvh"
};
export default ___CSS_LOADER_EXPORT___;
