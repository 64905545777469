import React from 'react';
import classNames from 'classnames';
import { Col, Row, Tooltip, Typography } from 'antd';

import { TimePickerField } from '../../base/TimePickerField';
import styles from './ScheduleTimePickerItem.module.less';

interface IScheduleTimePickerItem {
  name: string;
  prefixTitle: string;
  isBreakPointReached: boolean;
  suffixIcon: React.ReactElement | null;
  disabled?: boolean;
  errorMessage?: string;
}

export const ScheduleTimePickerItem: React.FC<IScheduleTimePickerItem> = ({
  name,
  prefixTitle,
  isBreakPointReached,
  suffixIcon,
  disabled,
  errorMessage,
}) => {
  return (
    <Row
      className={classNames(styles.rowTimePicker, {
        [styles.mobileWidth]: isBreakPointReached,
        [styles.error]: isBreakPointReached && !!errorMessage,
      })}
    >
      <Col
        className={classNames(styles.titleTimePicker, {
          [styles.mobileWidth]: isBreakPointReached,
        })}
      >
        {prefixTitle}
      </Col>
      <Col
        className={classNames(styles.timePicker, {
          [styles.mobileWidth]: isBreakPointReached,
        })}
      >
        <Tooltip title={!isBreakPointReached && errorMessage}>
          <TimePickerField
            name={name}
            suffixIcon={suffixIcon}
            minuteStep={5}
            disabled={disabled}
          />
          {isBreakPointReached && (
            <Typography.Text
              type='danger'
              className={classNames(styles.errorMessage, {
                [styles.mobileWidth]: isBreakPointReached,
              })}
            >
              {errorMessage}
            </Typography.Text>
          )}
        </Tooltip>
      </Col>
    </Row>
  );
};
