import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IVacanciesItem,
  TVacanciesDraftItem,
} from 'store/slices/vacancies/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveVacanciesItem,
  loadVacanciesItem,
  saveVacanciesItemAsDraft,
} from 'store/slices/vacancies/actions';
import { LangEnum } from 'constants/lang';

import { VacanciesForm } from './components/VacanciesForm/VacanciesForm';
import { mapValuesToForm } from './components/VacanciesForm/formUtils';

export const AddTranslation: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.vacancies);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadVacanciesItem(id));
  }, []);

  const onFinish = (values: IVacanciesItem) => {
    setIsLoading(true);
    dispatch(saveVacanciesItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/vacancies');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TVacanciesDraftItem) => {
    setIsLoading(true);
    dispatch(saveVacanciesItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/vacancies');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/vacancies');
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item.id && item.id === Number(id) ? (
        <VacanciesForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={{
            ...mapValuesToForm({ ...item, lang: LangEnum.en }),
            specialization: null,
          }}
        />
      ) : null}
    </MainLayout>
  );
};
