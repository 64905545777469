import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Col, Collapse, Row } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Status } from 'components/form/Status/Status';
import { UploadDoc } from 'components/form/UploadDoc/UploadDoc';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';

import { INITIAL_VALUES, VALIDATION_SCHEMA } from './constants';
import { FormValues, RegulationsFormProps } from './types';
import { mapValues, mapValuesAsDraft } from './formUtils';

export const RegulationsForm = ({
  onSubmit,
  defaultValues = {},
  isDraft = false,
  onSaveAsDraft,
  isLoading = false,
}: RegulationsFormProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | string[]>([
    'publication',
  ]);
  const formInitialValues = { ...INITIAL_VALUES, ...defaultValues };
  const methods = useForm<FormValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmitHandler = (values: FormValues) => onSubmit(mapValues(values));

  const cancelButtonHandler = () => navigate('/regulations');

  const handleSaveAsDraft = () => {
    const formValues = mapValuesAsDraft(methods.getValues());

    onSaveAsDraft(formValues);
  };

  useDeepEffect(() => {
    methods.reset(formInitialValues);
  }, [formInitialValues]);

  return (
    <FormProvider {...methods}>
      <form
        className='ant-form ant-form-vertical'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <Card title='Общая информация' bordered={false}>
          <UploadDoc name='material' />
          <Row gutter={[24, 0]}>
            <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
              <CatalogAsyncSelect
                name='category'
                requestParams={{
                  entityType: 'regulations',
                  fieldType: 'categories',
                }}
                label='Категория'
                placeholder='Выберите категорию'
                required
                showSearch={false}
                onSearch={undefined}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          activeKey={activeTab}
          onChange={(value: string | string[]) => setActiveTab(value)}
        >
          <Collapse.Panel header='Публикация' key='publication'>
            <Status status={formInitialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
