import { FileFilled } from '@ant-design/icons';
import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';
import React from 'react';

import styles from './FileIcon.module.less';

interface FileIconPros {
  color?: string;
  icon?: IconComponentProps['component'];
}

export const FileIcon = ({
  color = '#52C41A',
  icon: IconComponent = FileFilled,
}: FileIconPros) => (
  <div
    className={styles.wrapper}
    style={{
      backgroundColor: color,
    }}
  >
    <Icon style={{ color: 'white' }} component={IconComponent} />
  </div>
);
