import React from 'react';
import { Collapse } from 'antd';

import { Users } from './components/Users/Users';
import { Role } from './components/Role/Role';

export const ItemControl = () => {
  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([
    'role',
    'users',
  ]);

  return (
    <Collapse
      bordered={false}
      expandIconPosition='end'
      onChange={(value: string[]) => setActiveCollapseTabs(value)}
      activeKey={activeCollapseTabs}
    >
      <Collapse.Panel header='Роль организации' key='role'>
        <Role />
      </Collapse.Panel>
      <Collapse.Panel header='Пользователи' key='users'>
        <Users />
      </Collapse.Panel>
    </Collapse>
  );
};
