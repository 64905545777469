import { createSlice } from '@reduxjs/toolkit';

import { loadReportCommentsList } from './actions';
import { IReportCommentsState } from './interfaces';

export const reportCommentsSlice = createSlice({
  name: 'reportComments',
  initialState: {
    list: [],
    limit: 5,
    total: 0,
    offset: 0,
  } as IReportCommentsState,
  reducers: {
    resetComments: state => {
      state.list = [];
      state.total = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadReportCommentsList.fulfilled,
      (state, { payload: { data, total } }) => {
        state.list = data;
        state.total = total;
      }
    );
  },
});

export const { resetComments } = reportCommentsSlice.actions;
