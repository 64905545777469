import React from 'react';
import { Button, Typography } from 'antd';
import { EyeFilled, EyeInvisibleOutlined } from '@ant-design/icons';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { IRegionRelationsItem } from 'store/slices/regions/interfaces';

import { PlaceholderIcon } from '../RegionRelationsTypeSelector';
import styles from './RegionsRelationsListTable.module.less';

interface IProps {
  data: IRegionRelationsItem[];
  total: number;
  limit?: number;
  setSort: (sort?: string) => void;
  setIsItemVisible: (id: number, isHidden: boolean) => void;
}

export const ListTable = ({
  data,
  total,
  limit,
  setSort,
  setIsItemVisible,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: <div style={{ fontWeight: 'normal' }}>По новизне</div>,
      dataIndex: 'createdAt',
      sorter: true,
      render: (
        _: unknown,
        { name, type, data, isHidden }: IRegionRelationsItem
      ) => (
        <Typography
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          <div>
            <PlaceholderIcon
              selectedName={'category' in data ? data.category || null : type}
            />
          </div>
          <Typography.Text
            style={{
              fontSize: 14,
              marginBottom: 0,
              marginLeft: 24,
              color: isHidden && '#8C8C8C',
            }}
            ellipsis
          >
            {name}
          </Typography.Text>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '30%',
      title: ({ sortOrder }) => (
        <div style={{ fontWeight: 'normal' }}>
          {sortOrder === 'descend' ? 'Сначала закрытые' : 'Сначала открытые'}
        </div>
      ),
      sorter: true,
      dataIndex: 'isHidden',
      render: (isHidden, { id }) => (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={() => setIsItemVisible(id, !isHidden)}
            icon={
              isHidden ? (
                <EyeInvisibleOutlined style={{ color: '#8C8C8C' }} />
              ) : (
                <EyeFilled />
              )
            }
            type='text'
          />
        </div>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
      bordered={false}
      style={{ width: '100%' }}
      size='small'
      className={styles.customHover}
    />
  );
};
