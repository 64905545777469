import { Button, Card, Col, Collapse, Row, Typography } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainImage } from 'components/form/MainImage/MainImage';
import { InputField } from 'components/form/base/InputField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { Gallery } from 'components/form/Gallery/Gallery';
import { AddMaterial } from 'components/form/AddMaterial';
import { useDeepEffect } from 'utils/useDeepEffect';
import { Status } from 'components/form/Status/Status';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { DatePicker } from 'components/form/base/DatePicker';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { ContentField } from 'components/form/Content/ContentField';

import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
  VALID_FORMATS_LIST,
  DEFAULT_ACCEPT,
  UPLOADS_UNSUPPORTED_FILE_TYPES,
} from './constants';
import { FormProps, FormValues } from './types';
import { mapValues, mapValuesAsDraft } from './formUtils';
import styles from './Form.module.less';
import { LangEnumKeys } from '../../../../constants/lang';
import { CatalogRegionSelect } from '../../../../components/form/selects/CatalogRegionSelect';

export const Form = ({
  onSubmit,
  onSaveAsDraft,
  defaultValues = {},
  isDraft = false,
  isLoading,
}: FormProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | string[]>([
    'about',
    'content',
    'gallery',
    'documents',
    'publication',
  ]);
  const formInitialValues = { ...INITIAL_VALUES, ...defaultValues };
  const methods = useForm<FormValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmitHandler = (values: FormValues) => onSubmit(mapValues(values));

  useDeepEffect(() => {
    methods.reset({ ...INITIAL_VALUES, ...defaultValues });
  }, [defaultValues]);

  const cancelButtonHandler = () => navigate('/currentResidents');

  const handleSaveAsDraft = () => {
    const formValues = mapValuesAsDraft(methods.getValues());

    onSaveAsDraft(formValues);
  };

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues() });
    }
  }, [lang]);

  return (
    <FormProvider {...methods}>
      <form
        className='ant-form ant-form-vertical'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Глав. фото' required />
            </Col>
            <Col flex={1} style={{ minWidth: 200 }}>
              <InputField
                name='name'
                label='Название организации'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 14 }}>
              <CatalogAsyncSelect
                name='category'
                label='Территория ОЭЗ'
                requestParams={{
                  entityType: 'oezTerritory',
                  fieldType: 'oezname',
                  lang: lang as LangEnumKeys,
                }}
                placeholder='Выберите ОЭЗ'
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                placeholder='Выберите регион'
                isNewsPage
                required
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <DatePicker
                label='Дата получения статуса резидента'
                name='residentStatusDate'
                placeholder='Выберите дату'
                required
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='projectName'
                label='Название проекта'
                placeholder='Название проекта'
                required
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                placeholder='Описание'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          activeKey={activeTab}
          onChange={(value: string | string[]) => setActiveTab(value)}
        >
          <Collapse.Panel header='Описание места' key='content'>
            <ContentField
              name='content'
              label='Описание'
              placeholder='Начните печатать текст'
              required
            />
          </Collapse.Panel>
          <Collapse.Panel header='О проекте' key='about'>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <InputField
                  name='fullUsername'
                  label='ФИО Руководителя'
                  placeholder='ФИО Руководителя'
                  maxLength={255}
                />
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <InputField
                  name='investments'
                  label='Капитальные вложения'
                  placeholder='Капитальные вложения'
                  maxLength={255}
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Галерея' key='gallery'>
            <Gallery name='gallery' />
          </Collapse.Panel>
          <Collapse.Panel header='Документы' key='documents'>
            <AddMaterial
              name='documents'
              title='Загрузить документ'
              className={styles.materialItem}
              validFormatsList={VALID_FORMATS_LIST}
              accept={DEFAULT_ACCEPT}
              uploadsUnsupportedFileType={UPLOADS_UNSUPPORTED_FILE_TYPES}
            />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='publication'>
            <Status status={formInitialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
