import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';

import { FormDocumentUpload } from 'components/form/FormDocumentUpload/FormDocumentUpload';
import { useDeepEffect } from 'utils/useDeepEffect';
import { apiClient } from 'utils/http';
import { urls } from 'store/api';
import { useAppDispatch } from 'store';
import { loadDocumentsList } from 'store/slices/formDocuments/actions';

export const UploadDocument = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      file: null,
    },
  });

  const file = useWatch({ name: 'file', control: methods.control });

  const uploadDocument = () => {
    if (file) {
      const preparedValues = {
        formId: Number(id),
        type: 'custom',
        displayName: file.realName,
        document: file,
      };
      apiClient
        .post(urls.api.formDocuments.post, {
          ...preparedValues,
        })
        .then(() => {
          notification.success({
            message: 'Документ успешно загружен',
          });
          navigate(`${location.pathname}`);
          dispatch(loadDocumentsList({ formId: id }));
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    methods.reset({ file: null });
  };

  useDeepEffect(() => {
    uploadDocument();
  }, [file]);

  return (
    <FormProvider {...methods}>
      <FormDocumentUpload
        name='file'
        title='Загрузить документ'
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </FormProvider>
  );
};
