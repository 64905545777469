import React from 'react';
import { Row, Col, Typography } from 'antd';

import { useAppSelector } from 'store';

import styles from './Applications.module.less';
import { Application } from './components/Application';

export const Applications = () => {
  const { applications } = useAppSelector(state => state.organizations);

  if (!applications.length) {
    return <Typography.Text>Заявок нет</Typography.Text>;
  }

  return (
    <Row gutter={[0, 16]}>
      {applications.map((application, index) => (
        <Col key={index} span={24} className={styles.wrapper}>
          <Application application={application} />
        </Col>
      ))}
    </Row>
  );
};
