import React from 'react';
import { Button, Dropdown, Menu, Tag, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { statusesColorsHash, statusesNamesHash } from 'constants/status';
import { entityTypeNames } from 'constants/catalog';
import { IReviewsItemResponse } from 'store/slices/reviews/interfaces';
import { getName } from 'pages/Organizations/utils';

import { SYMBOL_LIMIT } from '../List';

interface IProps {
  data: IReviewsItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IReviewsItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const columns = [
    {
      key: 1,
      title: 'Отзыв',
      width: '34%',
      dataIndex: 'content',
      render: (content, item) => (
        <Link
          style={{
            color: 'inherit',
            wordBreak: 'break-word',
          }}
          to={`/reviews/${item.id}`}
        >
          {content.length > SYMBOL_LIMIT
            ? `${content.substring(0, SYMBOL_LIMIT)}...`
            : content}
        </Link>
      ),
    },
    {
      key: 2,
      title: 'Автор отзыва',
      width: '23%',
      dataIndex: 'author',
      render: (_: unknown, { userData }) => (
        <Typography.Text>
          {getName(
            {
              lastName: userData.lastName,
              firstName: userData.firstName,
              secondName: userData.secondName,
            },
            false,
            true
          )}
        </Typography.Text>
      ),
    },
    {
      key: 3,
      title: 'Раздел',
      width: '17%',
      dataIndex: 'entityType',
      render: entityType => (
        <Typography>
          <Typography.Text>{entityTypeNames[entityType]}</Typography.Text>
        </Typography>
      ),
    },
    {
      key: 4,
      title: 'Статус',
      width: '15%',
      dataIndex: 'status',
      render: status => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 6,
      title: '',
      width: '6%',
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <CommonTable
      columns={columns}
      scroll={{ x: 800 }}
      dataSource={data}
      total={total}
      limit={limit}
      setSort={setSort}
      bordered={false}
    />
  );
};
