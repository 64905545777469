import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ICardItem,
  ICardRequest,
  ICardResponse,
  TCardType,
} from './interfaces';
import { apiClient } from '../../../utils/http';

export const getCard = createAsyncThunk<
  ICardResponse,
  { lang: string; type: TCardType }
>('b2bCard/get', async ({ lang, type }) => {
  const { data } = await apiClient.get<ICardResponse>(`card/${type}/${lang}`);
  return data;
});

export const getCardItem = createAsyncThunk<
  ICardItem,
  { cardId: string; cardItemId: string }
>('b2bCard/getCardItem', async ({ cardId, cardItemId }) => {
  const { data } = await apiClient.get<ICardItem>(
    `cardItem/${cardId}/${cardItemId}`
  );
  return data;
});

export const deleteCardItem = createAsyncThunk<
  ICardItem,
  { cardItemId: number }
>('b2b/deleteCardItem', async ({ cardItemId }) => {
  const { data } = await apiClient.delete<ICardItem>(`cardItem/${cardItemId}`);
  return data;
});

export const addCard = createAsyncThunk<
  ICardItem,
  { cardId: string; payload: ICardItem }
>('b2b/addCard', async ({ cardId, payload }) => {
  const { data } = await apiClient.post<ICardItem>(
    `cardItem/${cardId}`,
    payload
  );
  return data;
});

export const setCardItem = createAsyncThunk<
  ICardItem,
  { cardId: string; payload: ICardItem }
>('b2b/setCardItem', async ({ cardId, payload }) => {
  const { data } = await apiClient.put<ICardItem>(
    `cardItem/${cardId}`,
    payload
  );
  return data;
});

export const setCard = createAsyncThunk<ICardRequest, ICardRequest>(
  'b2b/setCard',
  async payload => {
    const { data } = await apiClient.put<ICardRequest>(`card`, payload);
    return data;
  }
);
