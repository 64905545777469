import { WidgetTypeEnum } from 'components/form/Content/contentTypes';

export const entityTypeForUrlHash = {
  [WidgetTypeEnum.resorts]: 'resorts',
  [WidgetTypeEnum.attractions]: 'attractions',
  [WidgetTypeEnum.hotels]: 'hotels',
  [WidgetTypeEnum.restaurants]: 'bars-and-restaurants',
  [WidgetTypeEnum.routes]: 'touristRoutes',
  [WidgetTypeEnum.events]: 'events',
};
