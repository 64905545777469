import { Roles } from 'constants/roles';
import { StatusesEnum } from 'constants/status';

import { JWTService } from './jwt';

export const canBePublished = entity => {
  const { role } = JWTService.decode(JWTService.getAccessToken());

  if (role !== Roles.Admin) {
    return false;
  }

  if (
    entity.status !== StatusesEnum.PUBLISHED &&
    entity.status !== StatusesEnum.DRAFT
  ) {
    return true;
  }
};

export const canBeUnPublished = entity => {
  const { role } = JWTService.decode(JWTService.getAccessToken());

  if (role !== Roles.Admin) {
    return false;
  }

  if (entity.status === StatusesEnum.PUBLISHED) {
    return true;
  }
};

export const canViewOrganizationProjects = role => {
  if (role === Roles.AdminOez) {
    return false;
  }
  return true;
};

export const canViewOrganizationApplications = role => {
  if (role === Roles.AdminInvestments) {
    return false;
  }
  return true;
};
