import React from 'react';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider, FieldValues, useWatch } from 'react-hook-form';
import { PaperClipOutlined } from '@ant-design/icons';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty } from 'utils/helpers';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { IProjectsItem } from 'store/slices/projects/interfaces';
import { DeepPartial } from 'types/utils';
import { Select } from 'components/form/base/Select';
import {
  applicationsStatusesNamesHash,
  TApplicationsStatuses,
} from 'constants/applicationsStatus';
import { getUrlWithParams, urls } from 'store/api';
import { FormDocuments } from 'components/FormDocuments/FormDocuments';
import { FormComments } from 'components/FormComments/FormComments';
import { downloadDocument } from 'utils/downloadFile';
import { projectStatusNames, TProjectStatusTypes } from 'types/projects';

import styles from './Projects.module.less';

type TProps = {
  initialValues: DeepPartial<IProjectsItem>;
  onFinish: (values: {
    status: TApplicationsStatuses;
    projectStatus: TProjectStatusTypes;
  }) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

const docNameForm = 'Анкета юридического лица.docx';
const docNameRequest = 'Заявление.docx';

export const ProjectsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'documents',
    'comments',
  ]);

  const methods = useForm({
    defaultValues: initialValues,
  });

  const id = useWatch({
    name: 'id',
    control: methods.control,
  });

  const submit = (values: FieldValues) =>
    onFinish({
      status: values.status.value,
      projectStatus: values.projectStatus.value,
    });

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const downloadTemplate = async (type: string) => {
    let url: string;
    let docName: string;

    if (type === 'form') {
      url = getUrlWithParams(urls.api.forms.projects.download.form.get, {
        formId: id,
      });
      docName = docNameForm;
    } else {
      url = getUrlWithParams(urls.api.forms.projects.download.request.get, {
        formId: id,
      });
      docName = docNameRequest;
    }

    await downloadDocument(url, docName);
  };

  return (
    <FormProvider {...methods}>
      <form className='ant-form ant-form-vertical indent-top'>
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField name='name' label='Название проекта' disabled />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col className={styles.projectStatus}>
              <Select
                name='projectStatus'
                label='Статус проекта'
                options={[
                  ...Object.keys(projectStatusNames).map(item => ({
                    value: item,
                    label: projectStatusNames[item],
                  })),
                ]}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className={styles.applicationNumber}>
              <InputField name='id' label='Номер заявки' disabled />
            </Col>
            <Col className={styles.applicationStatus}>
              <Select
                name='status'
                label='Статус заявки'
                options={[
                  ...Object.keys(applicationsStatusesNamesHash).map(item => ({
                    value: item,
                    label: applicationsStatusesNamesHash[item],
                  })),
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <InputField name='region' label='Регион' disabled />
            </Col>
          </Row>
          <Row className={styles.links}>
            <div
              className={styles.document}
              onClick={() => downloadTemplate('form')}
            >
              <span>
                <PaperClipOutlined />
                <a>{docNameForm}</a>
              </span>
            </div>
            <div
              className={styles.document}
              onClick={() => downloadTemplate('request')}
            >
              <span>
                <PaperClipOutlined />
                <a>{docNameRequest}</a>
              </span>
            </div>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollpaseTabs(value as string[])}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Документы' key='documents'>
            <FormDocuments />
          </Collapse.Panel>
          <Collapse.Panel header='Комментарии' key='comments'>
            <FormComments />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            onClick={() => methods.handleSubmit(submit)()}
          >
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
