import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { AxiosError } from 'axios';

import { MAX_MATERIAL_FILE_SIZE } from 'constants/upload';
import { uploadMaterial } from 'utils/upload';
import {
  DEFAULT_ACCEPT,
  ERROR_CODE_KEY,
  ERROR_MESSAGE,
  VALID_FORMATS_LIST,
} from 'constants/formDocument';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

type TProps = {
  name: string;
  title: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  validFormatsList?: string[];
  accept?: string;
  errorMessage?: string;
};

export const FormDocumentUpload: React.FC<TProps> = ({
  name,
  title,
  isLoading,
  setIsLoading,
  validFormatsList = VALID_FORMATS_LIST,
  accept = DEFAULT_ACCEPT,
  errorMessage = ERROR_MESSAGE,
}) => {
  const { control, setValue } = useFormContext();

  const file = useWatch({ name, control });

  const fileListItem = React.useMemo(
    () => (file?.path ? [{ name: file.realName, uid: file.pathName }] : []),
    [file]
  );

  const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess?.('ok');
    }, 0);
  };

  const handleFileChange = async (info: UploadChangeParam) => {
    const isLt2M = (info.file.size as number) < MAX_MATERIAL_FILE_SIZE;
    const isValidType = validFormatsList.includes(info.file.type);

    if (!isLt2M) {
      return notification.error({
        message: `Пожалуйста, выберите файл с размером не более ${
          MAX_MATERIAL_FILE_SIZE / (1024 * 1024)
        } МБ`,
      });
    }
    if (!isValidType) {
      return notification.error({
        message: errorMessage,
      });
    }

    setIsLoading(true);
    try {
      const { path, fileList, size } = await uploadMaterial(info);
      const file = fileList[0];

      setValue(name, {
        baseUrl: UPLOAD_SERVICE_BASE_URL,
        path,
        realName: file.name,
        size,
      });
    } catch (error) {
      if (error.code && ERROR_CODE_KEY === error.code) {
        notification.error({
          message: errorMessage,
        });
      } else {
        notification.error({
          message: (error as AxiosError)?.message,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Upload
      accept={accept}
      name={name}
      customRequest={dummyRequest}
      onChange={handleFileChange}
      maxCount={1}
      fileList={fileListItem}
    >
      <Button icon={<UploadOutlined />} loading={isLoading}>
        {title}
      </Button>
    </Upload>
  );
};
