import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent } from 'utils/upload';

import {
  ISupportMeasuresItem,
  TSupportMeasuresItemDraft,
  ISupportMeasuresResponse,
} from './interfaces';

type TListResponse = { data: ISupportMeasuresResponse[]; total: number };

export const loadSupportMeasuresList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('supportMeasures/loadList', async (params, { getState }) => {
  const { limit } = getState().supportMeasures;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.supportMeasures.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadSupportMeasuresItem = createAsyncThunk<
  ISupportMeasuresResponse,
  string
>('supportMeasures/loadItem', async id => {
  const { data } = await apiClient.get<ISupportMeasuresResponse>(
    getUrlWithParams(urls.api.supportMeasures.getOne, {
      id,
    })
  );
  return data;
});

export const saveSupportMeasuresItem = createAsyncThunk<
  unknown,
  ISupportMeasuresItem
>('supportMeasures/saveItem', async ({ id, ...params }) => {
  const content = await uploadContent(params.content);

  if (id) {
    const { data } = await apiClient.patch<ISupportMeasuresItem>(
      getUrlWithParams(urls.api.supportMeasures.patch, { id }),
      {
        ...params,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<ISupportMeasuresItem>(
      urls.api.supportMeasures.post,
      {
        ...params,
        content,
      }
    );
    return data;
  }
});

export const saveSupportMeasuresItemAsDraft = createAsyncThunk<
  unknown,
  TSupportMeasuresItemDraft
>('supportMeasures/saveItem', async ({ id, ...requestParams }) => {
  const content = await uploadContent(requestParams.content);

  if (id) {
    const { data } = await apiClient.patch<TSupportMeasuresItemDraft>(
      getUrlWithParams(urls.api.supportMeasures.draft.patch, { id }),
      {
        ...requestParams,
        content,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<TSupportMeasuresItemDraft>(
      urls.api.supportMeasures.draft.post,
      {
        ...requestParams,
        content,
      }
    );
    return data;
  }
});

export const setPublishSupportMeasure = createAsyncThunk<void, string>(
  'supportMeasures/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.supportMeasures.publish.patch, { id })
    );
  }
);

export const setUnPublishSupportMeasure = createAsyncThunk<void, string>(
  'supportMeasures/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.supportMeasures.unPublish.patch, { id })
    );
  }
);

export const removeSupportMeasuresItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('supportMeasures/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.supportMeasures.delete, { id: params.id }));
  return data;
});
