import React from 'react';
import { Row, Col } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AddressSelector,
  TDefaultPartsType,
} from 'components/form/AddressSelector/AddressSelector';
import { AddressType } from 'types/address';

type LocationSelectorProps = {
  name: string;
  mapPositionName?: string;
};

const defaultParts: TDefaultPartsType = {
  area: {
    display: true,
    label: 'Район',
    key: AddressType.Region,
  },
  'city/settlement': {
    display: true,
    label: 'Город/населённый пункт',
    key: AddressType.Area,
  },
};

export const ShortAddressSelector: React.FC<LocationSelectorProps> = ({
  name,
}: LocationSelectorProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <Row>
            <Col lg={{ span: 10 }} xs={{ span: 24 }} flex={1}>
              <AddressSelector
                isSearching={false}
                isSearchable={false}
                errors={fieldState.error}
                addressLevels={defaultParts}
                {...field}
              />
            </Col>
          </Row>
        </>
      )}
    />
  );
};
