import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiClient } from 'utils/http';

import { IAboutRegion } from './interfaces';

export const getAboutRegion = createAsyncThunk<IAboutRegion, { lang: string }>(
  'aboutRegion/get',
  async ({ lang }) => {
    const { data } = await apiClient.get<IAboutRegion>(
      `about-the-regions/${lang}`
    );
    return data;
  }
);

export const updateAboutRegion = createAsyncThunk<IAboutRegion, IAboutRegion>(
  'aboutRegion/patch',
  async payload => {
    const { data } = await apiClient.patch<IAboutRegion>(
      `about-the-regions`,
      payload
    );
    return data;
  }
);
