import React from 'react';

import { Edit } from './Edit';
import { List } from './List';
import { RegionRelations } from './RegionRelations/RegionRelations';

export const regionsRoutes = {
  path: '/regions',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: ':id/edit',
      element: <Edit />,
    },
    {
      path: ':id/regionRelations',
      element: <RegionRelations />,
    },
  ],
};
