export enum InvestmentSolutionsEnum {
  Box = 'box',
  Special = 'special',
}

export type TInvestmentSolutions = `${InvestmentSolutionsEnum}`;

export const investmentSolutionNamesHash = {
  [InvestmentSolutionsEnum.Box]: 'Коробочное решение',
  [InvestmentSolutionsEnum.Special]: 'Специальное предложение',
};
