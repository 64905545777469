import dayjs from 'dayjs';

import { StatusesEnum } from 'constants/status';
import yup from 'utils/yup';

import { FormValues } from './types';

export const INITIAL_VALUES: FormValues = {
  name: '',
  material: null,
  year: dayjs(),
  status: StatusesEnum.PUBLISHED,
  month: dayjs(),
};

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  year: yup.mixed().required(),
  month: yup.mixed().required(),
  material: yup.mixed().required(),
});
