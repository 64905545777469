// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewGallery-module__mainGalleryContainer--ZaEjo {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 50px;\n  margin-bottom: 50px;\n}\n.ViewGallery-module__carouselContainer--v25tp {\n  width: 360px;\n}\n@media (max-width: 480px) {\n  .ViewGallery-module__carouselContainer--v25tp {\n    max-width: 100%;\n  }\n}\n.ViewGallery-module__img--T7VxX {\n  flex-shrink: 0;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/view/ViewGallery/ViewGallery.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AACF;AACA;EACE,YAAA;AACF;AAAE;EAAA;IACE,eAAA;EAGF;AACF;AADA;EACE,cAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAGF","sourcesContent":[".mainGalleryContainer {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 50px;\n  margin-bottom: 50px;\n}\n.carouselContainer {\n  width: 360px;\n  @media (max-width: 480px) {\n    max-width: 100%;\n  }\n}\n.img {\n  flex-shrink: 0;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainGalleryContainer": "ViewGallery-module__mainGalleryContainer--ZaEjo",
	"carouselContainer": "ViewGallery-module__carouselContainer--v25tp",
	"img": "ViewGallery-module__img--T7VxX"
};
export default ___CSS_LOADER_EXPORT___;
