// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Projects-module__wrapper--xMX8K {\n  border-top: 1px solid #d9d9d9;\n  border-bottom: 1px solid #d9d9d9;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Organizations/components/ItemInfo/components/Projects/Projects.module.less"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACC,gCAAA;AACF","sourcesContent":[".wrapper {\n\tborder-top: 1px solid #d9d9d9;\n  border-bottom: 1px solid #d9d9d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Projects-module__wrapper--xMX8K"
};
export default ___CSS_LOADER_EXPORT___;
