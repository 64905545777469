// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventsForm-module__ageRestrictionRadioButton--yU3VE {\n  margin-right: 24px;\n}\n@media (max-width: 480px) {\n  .EventsForm-module__ageRestrictionRadioButton--yU3VE {\n    margin-right: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Events/components/EventsForm/EventsForm.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAIA;EACE;IACE,eAAA;EAFF;AACF","sourcesContent":["@xs: 480px;\n\n.ageRestrictionRadioButton {\n  margin-right: 24px;\n}\n\n@media (max-width: @xs) {\n  .ageRestrictionRadioButton {\n    margin-right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ageRestrictionRadioButton": "EventsForm-module__ageRestrictionRadioButton--yU3VE"
};
export default ___CSS_LOADER_EXPORT___;
