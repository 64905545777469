import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadImage, uploadImageArray } from 'utils/upload';

import {
  TInvestmentPassportsDraftItem,
  IInvestmentPassportsItem,
  IInvestmentPassportsItemResponse,
} from './interfaces';

type TListResponse = {
  data: IInvestmentPassportsItemResponse[];
  total: number;
};

export const loadInvestmentPassportsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
  },
  { state: RootState }
>('investmentPassports/loadList', async (params, { getState }) => {
  const { limit } = getState().investmentPassports;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.investmentPassports.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadInvestmentPassportsItem = createAsyncThunk<
  IInvestmentPassportsItemResponse,
  string
>('investmentPassports/loadItem', async id => {
  const { data } = await apiClient.get<IInvestmentPassportsItemResponse>(
    getUrlWithParams(urls.api.investmentPassports.getOne, {
      id,
    })
  );
  return data;
});

export const saveInvestmentPassportsItem = createAsyncThunk<
  IInvestmentPassportsItem,
  IInvestmentPassportsItem
>('investmentPassports/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const gallery = await uploadImageArray(params.gallery);

  if (id) {
    const { data } = await apiClient.put<IInvestmentPassportsItem>(
      getUrlWithParams(urls.api.investmentPassports.put, { id }),
      {
        ...params,
        image,
        gallery,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IInvestmentPassportsItem>(
      urls.api.investmentPassports.post,
      {
        ...params,
        image,
        gallery,
      }
    );
    return data;
  }
});

export const saveInvestmentPassportsItemAsDraft = createAsyncThunk<
  TInvestmentPassportsDraftItem,
  TInvestmentPassportsDraftItem
>('investmentPassports/saveDraftItem', async ({ id, ...requestParams }) => {
  const image = await uploadImage(requestParams.image);
  const gallery = await uploadImageArray(requestParams.gallery);

  if (id) {
    const { data } = await apiClient.put<TInvestmentPassportsDraftItem>(
      getUrlWithParams(urls.api.investmentPassports.draft.put, { id }),
      {
        ...requestParams,
        image,
        gallery,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<TInvestmentPassportsDraftItem>(
      urls.api.investmentPassports.draft.post,
      {
        ...requestParams,
        image,
        gallery,
      }
    );
    return data;
  }
});

export const setUnPublishInvestmentPassportsItem = createAsyncThunk<
  void,
  string
>('investmentPassports/unPublish', async id => {
  return await apiClient.patch(
    getUrlWithParams(urls.api.investmentPassports.unPublish.patch, { id })
  );
});

export const deleteInvestmentPassportsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('investmentPassports/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.investmentPassports.delete, { id }));
  return data;
});
