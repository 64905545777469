import { StatusesEnum } from 'constants/status';
import yup from 'utils/yup';

import { FormValues } from './types';

export const INITIAL_VALUES: FormValues = {
  name: '',
  material: null,
  category: null,
  status: StatusesEnum.PUBLISHED,
};

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  category: yup.mixed().required(),
  material: yup.mixed().required(),
});
