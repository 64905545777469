// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar-module__icon--nA65S {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  border-radius: 50%;\n  color: #fff;\n}\n.Avatar-module__icon--nA65S:hover {\n  color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  border-radius: 50%;\n  color: #fff;\n\n  &:hover {\n    color: #fff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Avatar-module__icon--nA65S"
};
export default ___CSS_LOADER_EXPORT___;
