import { LangEnum } from 'constants/lang';
import { prepareContent } from 'components/form/Content/utils';
import { DEFAULT_SEO_STATE } from 'constants/seo';
import { StatusesEnum } from 'constants/status';

export const DEFAULT_STATE = {
  lang: LangEnum.ru,
  name: '',
  image: null,
  shortDescription: '',
  territoryType: null,
  region: null,
  preferences: [],
  gallery: [],
  videoUrls: [{ value: '' }, { value: '' }],
  address: {},
  polylines: [],
  content: prepareContent([]),
  contacts: {
    name: '',
    position: '',
    phone: '',
    additional: '',
    email: '',
  },
  ...DEFAULT_SEO_STATE,
  status: StatusesEnum.PUBLISHED,
};
