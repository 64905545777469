import React from 'react';

import { List } from './List';
import { Edit } from './Edit';

export const resortsSampleRoutes = {
  path: '/resortsSample',
  children: [
    {
      path: '',
      element: <List />,
    },
    {
      path: ':id/edit',
      element: <Edit slug='/resortsSample' />,
    },
    {
      path: 'add',
      element: <Edit slug='/resortsSample' />,
    },
  ],
};
