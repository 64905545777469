export enum Reports {
  ResidentsActivity = 'residentsActivity',
  ResidentPayments = 'residentPayments',
  BusinessPlan = 'businessPlan',
}

export const reportName = {
  [Reports.ResidentsActivity]: 'Отчет о деятельности резидентов',
  [Reports.ResidentPayments]:
    'Отчет об объемах налоговых и таможенных платежей резидентов',
  [Reports.BusinessPlan]:
    'Форма отчета о достижении показателей реализации бизнес-плана',
};

export type TReports = `${Reports}`;

export enum ReportStatus {
  Sent = 'sent',
  Accepted = 'accepted',
  Declined = 'declined',
}

export const reportStatusName = {
  [ReportStatus.Sent]: 'Отправлен',
  [ReportStatus.Accepted]: 'Принят',
  [ReportStatus.Declined]: 'Отклонен',
};

export const reportStatusColor = {
  [ReportStatus.Sent]: 'blue',
  [ReportStatus.Accepted]: 'green',
  [ReportStatus.Declined]: 'red',
};

export type TReportStatus = `${ReportStatus}`;

export const reportsOptions = [
  {
    value: '',
    label: 'Все',
  },
  ...Object.values(ReportStatus).map(status => ({
    value: status,
    label: reportStatusName[status],
  })),
];

export enum ReportDocumentType {
  Comment = 'comment',
  Scan = 'scan',
}

export type TReportDocumentType = `${ReportDocumentType}`;
