import * as Yup from 'yup';
import dayjs from 'dayjs';

import { mapMaterial, mapSelect, mapValuesToSelect } from 'utils/mappings';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  IRentalsItem,
  TRentalsDraftItem,
} from 'store/slices/rentals/interfaces';
import { defaultDateFormat } from 'components/form/base/RangePicker';
import { ExtensionContactsTypes } from 'components/form/ExtensionContacts/types';
import { procedureStatusesNamesHash } from 'constants/procedureStatus';
import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import {
  applicationsContactsSchema,
  applicationsMaterialsSchema,
} from 'utils/yup';
import { inputUnmaskNumber } from 'constants/masks';

export const mapContacts = contacts => {
  return contacts.map(contact => {
    if (contact.type === ExtensionContactsTypes.phone) {
      return {
        type: contact.type,
        value: (contact.value && inputUnmaskNumber(contact.value)) || null,
        additional: contact.additional || null,
      };
    }
    return {
      type: contact.type,
      value: contact.value || null,
    };
  });
};

export const mapContactsToForm = contacts => {
  return contacts.map(contact => ({
    type: contact.type,
    value: contact.value || '',
    ...(contact.type === ExtensionContactsTypes.phone && {
      additional: contact.additional || '',
    }),
  }));
};

export const mapRentalsStatusToForm = statusProcedure => ({
  label: procedureStatusesNamesHash[statusProcedure],
  value: statusProcedure,
});

export const mapValuesToForm = values => {
  return {
    ...values,
    name: values.name || '',
    rentMethodCatalogId: values.rentMethodCatalogIdData?.name
      ? mapValuesToSelect(values.rentMethodCatalogIdData)
      : null,
    statusProcedure: values.statusProcedure
      ? mapRentalsStatusToForm(values.statusProcedure)
      : null,
    timePlaceProcedureDocumentation:
      values.timePlaceProcedureDocumentation || '',
    paymentProcurementDocuments: values.paymentProcurementDocuments || '',
    securingProcurementApplication: values.securingProcurementApplication || '',
    securingContract: values.securingContract || '',
    placeOpeningEnvelopes: values.placeOpeningEnvelopes || '',
    dateTimeOpeningEnvelopes: values.dateTimeOpeningEnvelopes || '',
    placeConsiderationApplication: values.placeConsiderationApplication || '',
    dateTimeConsiderationApplication:
      values.dateTimeConsiderationApplication || '',
    placeDebriefing: values.placeDebriefing || '',
    dateTimeDebriefing: values.dateTimeDebriefing || '',
    datePlacementNotice: values.datePlacementNotice
      ? dayjs(values.datePlacementNotice)
      : null,
    noticeNumber: values.noticeNumber || '',
    initialPrice: values.initialPrice || '',
    contactPerson: values.contactPerson || '',
    contacts: mapContactsToForm(values.contacts),
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

const validationTextAreaSchema = Yup.string()
  .trim()
  .min(1, 'Введите от 1 до 500 символов')
  .max(500, 'Введите от 1 до 500 символов')
  .required();

const validationInputFieldSchema = Yup.string()
  .trim()
  .min(1, 'Введите от 1 до 255 символов')
  .max(255, 'Введите от 1 до 255 символов')
  .required();

export const validationSchema = Yup.object().shape({
  name: validationTextAreaSchema,
  rentMethodCatalogId: Yup.mixed().required(),
  contactPerson: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 100 символов')
    .max(100, 'Введите от 1 до 100 символов')
    .required(),
  statusProcedure: Yup.mixed().required(),
  timePlaceProcedureDocumentation: validationInputFieldSchema,
  paymentProcurementDocuments: validationInputFieldSchema,
  securingProcurementApplication: validationTextAreaSchema,
  securingContract: validationTextAreaSchema,
  placeOpeningEnvelopes: validationInputFieldSchema,
  dateTimeOpeningEnvelopes: validationInputFieldSchema,
  placeConsiderationApplication: validationInputFieldSchema,
  dateTimeConsiderationApplication: validationInputFieldSchema,
  placeDebriefing: validationInputFieldSchema,
  dateTimeDebriefing: validationInputFieldSchema,
  datePlacementNotice: Yup.mixed().required(),
  noticeNumber: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 60 символов')
    .max(60, 'Введите от 1 до 60 символов')
    .required(),
  initialPrice: validationTextAreaSchema,
  materials: applicationsMaterialsSchema,
  contacts: applicationsContactsSchema,
  status: Yup.mixed().required(),
});

export const mapValues = (values): IRentalsItem => {
  return {
    name: values.name.trim(),
    rentMethodCatalogId: mapSelect(values.rentMethodCatalogId) as number,
    statusProcedure: values.statusProcedure.value,
    timePlaceProcedureDocumentation: values.timePlaceProcedureDocumentation,
    paymentProcurementDocuments: values.paymentProcurementDocuments,
    securingProcurementApplication: values.securingProcurementApplication,
    securingContract: values.securingContract,
    placeOpeningEnvelopes: values.placeOpeningEnvelopes,
    dateTimeOpeningEnvelopes: values.dateTimeOpeningEnvelopes,
    placeConsiderationApplication: values.placeConsiderationApplication,
    dateTimeConsiderationApplication: values.dateTimeConsiderationApplication,
    placeDebriefing: values.placeDebriefing,
    dateTimeDebriefing: values.dateTimeDebriefing,
    datePlacementNotice: dayjs(
      values.datePlacementNotice,
      defaultDateFormat
    ).toISOString(),
    noticeNumber: values.noticeNumber,
    initialPrice: values.initialPrice,
    materials: mapMaterial(values.materials) as unknown as MaterialValue[],
    contactPerson: values.contactPerson,
    contacts: mapContacts(values.contacts),
    status: mapSelect(values.status) as Statuses,
  };
};

export const mapValuesToDraft = (values): TRentalsDraftItem => {
  return {
    name: values.name.trim() || null,
    rentMethodCatalogId: values.rentMethodCatalogId
      ? (mapSelect(values.rentMethodCatalogId) as number)
      : null,
    statusProcedure: values.statusProcedure
      ? values.statusProcedure.value
      : null,
    timePlaceProcedureDocumentation:
      values.timePlaceProcedureDocumentation || null,
    paymentProcurementDocuments: values.paymentProcurementDocuments || null,
    securingProcurementApplication:
      values.securingProcurementApplication || null,
    securingContract: values.securingContract || null,
    placeOpeningEnvelopes: values.placeOpeningEnvelopes || null,
    dateTimeOpeningEnvelopes: values.dateTimeOpeningEnvelopes || null,
    placeConsiderationApplication: values.placeConsiderationApplication || null,
    dateTimeConsiderationApplication:
      values.dateTimeConsiderationApplication || null,
    placeDebriefing: values.placeDebriefing || null,
    dateTimeDebriefing: values.dateTimeDebriefing || null,
    datePlacementNotice: values.datePlacementNotice
      ? dayjs(values.datePlacementNotice, defaultDateFormat).toISOString()
      : null,
    noticeNumber: values.noticeNumber || null,
    initialPrice: values.initialPrice || null,
    materials: values.materials.length
      ? mapMaterial(values.materials as MaterialValue[])
      : [],
    contactPerson: values.contactPerson || null,
    contacts: mapContacts(values.contacts),
  };
};
