// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedule-module__label--RUxvU {\n  width: 130px;\n  flex-shrink: 0;\n}\n@media (max-width: 800px) {\n  .Schedule-module__label--RUxvU {\n    width: 100%;\n    margin-bottom: -10px;\n  }\n}\n.Schedule-module__scheduleContainer--Woviy {\n  gap: 14px;\n}\n.Schedule-module__scheduleContainer--Woviy.Schedule-module__mobileWidth--nvOXu {\n  padding-bottom: 16px;\n  border-bottom: 1px solid #D9D9D9;\n}\n.Schedule-module__fullDay--nHvFt {\n  margin-left: 10px;\n}\n@media (max-width: 800px) {\n  .Schedule-module__fullDay--nHvFt {\n    margin-left: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Schedule/Schedule.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;AACF;AAAE;EAAA;IACE,WAAA;IACA,oBAAA;EAGF;AACF;AAAA;EACE,SAAA;AAEF;AADE;EACE,oBAAA;EACA,gCAAA;AAGJ;AACA;EACE,iBAAA;AACF;AAAE;EAAA;IACE,cAAA;EAGF;AACF","sourcesContent":[".label {\n  width: 130px;\n  flex-shrink: 0;\n  @media (max-width: 800px) {\n    width: 100%;\n    margin-bottom: -10px;\n  }\n}\n\n.scheduleContainer {\n  gap: 14px;\n  &.mobileWidth {\n    padding-bottom: 16px;\n    border-bottom: 1px solid #D9D9D9;\n  }\n}\n\n.fullDay {\n  margin-left: 10px;\n  @media (max-width: 800px) {\n    margin-left: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Schedule-module__label--RUxvU",
	"scheduleContainer": "Schedule-module__scheduleContainer--Woviy",
	"mobileWidth": "Schedule-module__mobileWidth--nvOXu",
	"fullDay": "Schedule-module__fullDay--nHvFt"
};
export default ___CSS_LOADER_EXPORT___;
