import React, { memo, useCallback, useState } from 'react';
import { Col, notification, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteNestedItem,
  loadNestedItems,
  saveNestedItem,
  updateNestedItem,
} from 'store/slices/openingInfo/actions';
import { INestedItem } from 'store/slices/openingInfo/interfaces';
import { IDocument } from 'types/document';

import { CustomUpload } from '../CustomUpload/CustomUpload';
import { ModalConfirmDelete } from '../ModalConfirmDelete/ModalConfirmDelete';
import { AdditionalForm } from '../EntityForms/AdditionalForm';
import styles from './Nested.module.less';

interface IProps {
  parentId: string;
}

export const Nested = memo(({ parentId }: IProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState<string>(null);

  const {
    chapters: {
      nestedItems: { list, total },
    },
    loading,
  } = useAppSelector(state => state.openingInfo);
  const dispatch = useAppDispatch();

  const loadListNestedItems = async () => await dispatch(loadNestedItems());

  const handleNestedItemAdd = useCallback(
    async (file: IDocument, parentId: string) => {
      const title = file.realName.split('.').slice(0, -1).join('.');

      await dispatch(
        saveNestedItem({ file, title, parentId, sortIndex: total + 1 })
      );
      await loadListNestedItems();
    },
    [total]
  );

  const handleNestedItemUpdate = useCallback(
    async (
      file: IDocument,
      title: string,
      { id, parentId, sortIndex }: INestedItem
    ) =>
      await dispatch(
        updateNestedItem({ file, title, id, parentId, sortIndex })
      ),
    []
  );

  const handleMove = useCallback(
    async (item: INestedItem, step = 1) => {
      const currentIndex = list.findIndex(({ id }) => id === item.id);
      const swapEl = list[currentIndex + step];

      await dispatch(
        updateNestedItem({
          id: swapEl.id,
          file: swapEl.file,
          title: swapEl.title,
          parentId: swapEl.parentId,
          sortIndex: item.sortIndex,
        })
      );

      await dispatch(
        updateNestedItem({
          id: item.id,
          file: item.file,
          title: item.title,
          parentId: item.parentId,
          sortIndex: swapEl.sortIndex,
        })
      );

      await loadListNestedItems();
    },
    [list]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(prevState => !prevState);
    setCurrentItemId(null);
  }, []);

  const handleDelete = useCallback((id: string) => {
    setCurrentItemId(id);
    setModalIsOpen(prevState => !prevState);
  }, []);

  const handleNestedItemConfirmDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteNestedItem(id));
      handleCloseModal();
      notification.success({
        message: 'Приложение удалено',
      });

      await Promise.all(
        list
          .filter(item => item.id !== id)
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map(({ id, file, title, parentId }, index) =>
            dispatch(
              updateNestedItem({
                id,
                file,
                title,
                parentId,
                sortIndex: index + 1,
              })
            )
          )
      );

      await loadListNestedItems();
    },
    [list]
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <CustomUpload
            onAdd={file => handleNestedItemAdd(file, parentId)}
            disabled={loading}
            isAdditional
          />
        </Col>
      </Row>
      {list.filter(item => item.parentId === parentId).length > 0 && (
        <Row>
          <Col span={24}>
            {list
              .filter(item => item.parentId === parentId)
              .sort((a, b) => a.sortIndex - b.sortIndex)
              .map(item => (
                <AdditionalForm
                  key={item.id}
                  onMove={handleMove}
                  onUpdate={handleNestedItemUpdate}
                  onDelete={handleDelete}
                  entity={item}
                  total={total}
                />
              ))}
          </Col>
        </Row>
      )}
      {currentItemId && modalIsOpen && (
        <ModalConfirmDelete
          onClose={handleCloseModal}
          onDelete={handleNestedItemConfirmDelete}
          isOpen={modalIsOpen}
          itemId={currentItemId}
          entityName='приложения'
          text='Вы уверены, что хотите удалить приложение?'
        />
      )}
    </div>
  );
});

Nested.displayName = 'Nested';
