import React from 'react';

import { Menu } from './Menu';

export const menuRoutes = {
  path: '/menu',
  children: [
    {
      path: '',
      element: <Menu />,
    },
  ],
};
