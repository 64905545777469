export enum StateStatus {
  Current = 'current',
  Completed = 'completed',
}

export type TStateStatusesTypes = `${StateStatus}`;

export const stateStatusesNamesHash = {
  [StateStatus.Current]: 'Текущие',
  [StateStatus.Completed]: 'Завершенные',
} as const;
