import React from 'react';
import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { useAppSelector } from 'store';
import { getName } from 'pages/Organizations/utils';

import styles from './InfoAboutChange.module.less';

export const InfoAboutChange = () => {
  const { item } = useAppSelector(state => state.organizations);

  const { isResidentUpdatedAt, isResidentEditor } = item;

  if (!isResidentEditor && !isResidentUpdatedAt) {
    return null;
  }

  return (
    <div style={{ padding: '16px 24px', backgroundColor: 'white' }}>
      <Descriptions
        labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
        title=''
        size='small'
        column={1}
        layout='vertical'
      >
        <Descriptions.Item className={styles.row} label='Последние изменения'>
          {getName({
            firstName: item.residentEditorData.firstName,
            secondName: item.residentEditorData.secondName,
            lastName: item.residentEditorData.lastName,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          className={classNames(styles.row, styles.rowLast)}
          label='Дата и время последних изменений'
        >
          {dayjs(isResidentUpdatedAt).format('DD.MM.YYYY HH:mm')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
