import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IArticlesItem } from 'store/slices/articles/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveArticlesItem,
  loadArticlesItem,
  saveArticlesItemAsDraft,
} from 'store/slices/articles/actions';
import { LangEnum } from 'constants/lang';

import { ArticlesForm } from './components/ArticlesForm';
import { mapValuesToForm } from './components/formUtils';

export const AddTranslation: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.articles);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(loadArticlesItem(id));
  }, []);

  const onFinish = (values: IArticlesItem) => {
    setIsLoading(true);
    dispatch(saveArticlesItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/articles');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IArticlesItem) => {
    setIsLoading(true);
    dispatch(saveArticlesItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/articles');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/articles');
  };

  // catalog values depends on lang
  const preparedValues = {
    ...item,
    lang: LangEnum.en,
    category: null,
    categoryData: null,
    tags: [],
    tagsData: null,
  };

  return (
    <MainLayout pageTitle='Создание'>
      {item.id && item.id === Number(id) ? (
        <ArticlesForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft
          isLoading={isLoading}
          initialValues={mapValuesToForm(preparedValues)}
        />
      ) : null}
    </MainLayout>
  );
};
