import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { TApplicationsStatuses } from 'constants/applicationsStatus';
import { TProjectStatusTypes } from 'types/projects';

import { IProjectsItem, TProjectsListParams } from './interfaces';

type TListResponse = {
  data: IProjectsItem[];
  total: number;
};

export const loadProjectsList = createAsyncThunk<
  TListResponse,
  TProjectsListParams,
  { state: RootState }
>('projects/loadList', async (params, { getState }) => {
  const { limit } = getState().projects;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.formProjects.get,
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const loadProjectsItem = createAsyncThunk<IProjectsItem, string>(
  'projects/loadItem',
  async id => {
    const { data } = await apiClient.get<IProjectsItem>(
      getUrlWithParams(urls.api.formProjects.getOne, {
        id,
      })
    );
    return data;
  }
);

export const updateProjectsItem = createAsyncThunk<
  unknown,
  {
    id: number;
    status: TApplicationsStatuses;
    projectStatus: TProjectStatusTypes;
  }
>('projects/updateItem', async ({ id, status, projectStatus }) => {
  const { data } = await apiClient.patch(
    getUrlWithParams(urls.api.formProjects.updateProject.patch, {
      formId: id,
    }),
    {
      status,
      projectStatus,
    }
  );
  return data;
});

export const removeProjectsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id: string;
  }
>('projects/deleteItem', async id => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.formProjects.delete, { id }));
  return data;
});
