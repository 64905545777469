// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__container--Lqrqt {\n  padding: 13px 0;\n  margin-right: 16px;\n}\n@media (max-width: 800px) {\n  .Header-module__container--Lqrqt {\n    margin-right: 0;\n    margin-bottom: 16px;\n    padding: 0;\n  }\n}\n.Header-module__title--owM6E {\n  font-size: 16px;\n}\n.Header-module__subTitle--NGUPn {\n  font-weight: 400;\n}\n.Header-module__icon--QCLby {\n  font-size: 16px;\n  color: #1890ff;\n  rotate: 180deg;\n  -webkit-transform: scaleX(-1);\n  transform: scaleX(-1);\n  margin-left: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OpeningInfo/components/EntityForms/components/Header/Header.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;AACE;EAAA;IACE,eAAA;IACA,mBAAA;IACA,UAAA;EAEF;AACF;AACA;EACE,eAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,eAAA;EACA,cAAA;EACA,cAAA;EACA,6BAAA;EACA,qBAAA;EACA,gBAAA;AADF","sourcesContent":[".container {\n  padding: 13px 0;\n  margin-right: 16px;\n\n  @media (max-width: 800px) {\n    margin-right: 0;\n    margin-bottom: 16px;\n    padding: 0;\n  }\n}\n\n.title {\n  font-size: 16px;\n}\n\n.subTitle {\n  font-weight: 400;\n}\n\n.icon {\n  font-size: 16px;\n  color: #1890ff;\n  rotate: 180deg;\n  -webkit-transform: scaleX(-1);\n  transform: scaleX(-1);\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Header-module__container--Lqrqt",
	"title": "Header-module__title--owM6E",
	"subTitle": "Header-module__subTitle--NGUPn",
	"icon": "Header-module__icon--QCLby"
};
export default ___CSS_LOADER_EXPORT___;
