// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreferencesItem-module__wrapper--xMCPv {\n  background: #fafafa;\n  border-bottom: 1px solid #d9d9d9;\n  padding: 24px;\n}\n.PreferencesItem-module__header--AtHmf {\n  width: 100%;\n  display: flex;\n  justify-content: end;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Preferences/components/PreferencesItem/PreferencesItem.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gCAAA;EACA,aAAA;AACF;AAEA;EACE,WAAA;EACA,aAAA;EACA,oBAAA;AAAF","sourcesContent":[".wrapper {\n  background: #fafafa;\n  border-bottom: 1px solid #d9d9d9;\n  padding: 24px;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: end;\n}\n\n.actions {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PreferencesItem-module__wrapper--xMCPv",
	"header": "PreferencesItem-module__header--AtHmf"
};
export default ___CSS_LOADER_EXPORT___;
