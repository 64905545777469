import dayjs from 'dayjs';

export const getYears = (startYear = 2014) => {
  const options: Array<{ value: number; label: string }> = [];
  const currentYear = dayjs().year();
  const countYears = currentYear - startYear;
  for (let i = 0; i <= countYears; i++) {
    const label = String(currentYear - i);
    const value = currentYear - i;
    options.push({ label, value });
  }
  return options;
};
