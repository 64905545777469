import React, { useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  loadRegionsItem,
  setRegionsItem,
} from '../../../../store/slices/regions/actions';
import { MainLayout } from '../../../../components/Layout/MainLayout';
import { IFormProps, RegionForm } from './RegionForm';
import CardSpin from '../../../../components/Card/CardSpin';
import { mapValues } from './utils';

export const RegionEdit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(true);
  const state = useAppSelector(state => state.regions);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const onSubmit = (values: IFormProps) => {
    const slug =
      typeof values.enum === 'string' ? values.enum : values.enum.value;
    setIsLoading(true);
    dispatch(setRegionsItem(mapValues({ ...values, enum: slug })))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/mainBusiness/regions');
      })
      .finally(() => setIsLoading(false));
  };
  React.useEffect(() => {
    dispatch(loadRegionsItem(id))
      .unwrap()
      .finally(() => {
        setQueryLoading(false);
      });
  }, []);

  if (queryLoading) {
    return <CardSpin />;
  }

  return (
    <MainLayout pageTitle='Редактирование'>
      <Content>
        {state.item.id && state.item.id === Number(id) && (
          <RegionForm
            initialValues={state.item}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        )}
      </Content>
    </MainLayout>
  );
};
