import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { ListResponse } from 'types/pagination';
import { DeepPartial } from 'types/utils';
import { apiClient } from 'utils/http';
import { uploadImage, uploadImageArray } from 'utils/upload';

import {
  InvestmentSolutionsItem,
  GetInvestmentSolutionsParams,
} from './interfaces';

export const loadInvestmentSolutionsList = createAsyncThunk<
  ListResponse<InvestmentSolutionsItem>,
  GetInvestmentSolutionsParams,
  { state: RootState }
>('investment-solutions/loadList', async (params, { getState }) => {
  const { limit } = getState().investmentSolutions;
  const { data } = await apiClient.get(urls.api.investmentSolutions.get, {
    params: {
      limit,
      ...params,
    },
  });

  return data;
});

export const saveInvestmentSolutionsItem = createAsyncThunk<
  InvestmentSolutionsItem,
  InvestmentSolutionsItem
>(
  'investment-solutions/saveItem',
  async ({ id, image, gallery, ...params }) => {
    const uploadedImage = await uploadImage(image);
    const uploadedGallery = await uploadImageArray(gallery);

    if (id) {
      const { data } = await apiClient.patch(
        getUrlWithParams(urls.api.investmentSolutions.patch, { id }),
        {
          ...params,
          image: uploadedImage,
          gallery: uploadedGallery,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post(urls.api.investmentSolutions.post, {
        ...params,
        image: uploadedImage,
        gallery: uploadedGallery,
      });
      return data;
    }
  }
);
export const saveInvestmentSolutionsItemDraft = createAsyncThunk<
  DeepPartial<InvestmentSolutionsItem>,
  DeepPartial<InvestmentSolutionsItem>
>(
  'investment-solutions/saveItemAsDraft',
  async ({ id, image, gallery, ...params }) => {
    const uploadedImage = await uploadImage(image);
    const uploadedGallery = await uploadImageArray(gallery);

    if (id) {
      const { data } = await apiClient.patch(
        getUrlWithParams(urls.api.investmentSolutions.draft.patch, { id }),
        {
          ...params,
          image: uploadedImage,
          gallery: uploadedGallery,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post(
        urls.api.investmentSolutions.draft.post,
        {
          ...params,
          image: uploadedImage,
          gallery: uploadedGallery,
        }
      );
      return data;
    }
  }
);

export const loadInvestmentSolutionsItem = createAsyncThunk<
  InvestmentSolutionsItem,
  string
>('investment-solutions/loadItem', async id => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.investmentSolutions.getOne, { id })
  );
  return data;
});

export const setPublishInvestmentSolution = createAsyncThunk<void, number>(
  'investment-solutions/publish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.investmentSolutions.publish.patch, { id })
    )
);

export const setUnPublishInvestmentSolution = createAsyncThunk<void, number>(
  'investment-solutions/unPublish',
  async (id: number) =>
    await apiClient.patch(
      getUrlWithParams(urls.api.investmentSolutions.unPublish.patch, { id })
    )
);

export const deleteInvestmentSolution = createAsyncThunk<void, { id: number }>(
  'investment-solutions/delete',
  async ({ id }) =>
    await apiClient.delete(
      getUrlWithParams(urls.api.investmentSolutions.delete, { id })
    )
);
