import React from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { Status } from 'components/form/Status/Status';
import { isEmpty, isString } from 'utils/helpers';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { Select } from 'components/form/base/Select';
import { SEO } from 'components/form/SEO/SEO';
import { CustomIcon, SvgIconNames } from 'components/icons/icons';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import {
  VACANCIES_WORK_EXPERIENCE_OPTIONS,
  VACANCIES_WORK_SCHEDULE_OPTIONS,
  VACANCIES_WORK_TYPE_OPTIONS,
} from 'constants/vacancies';
import { getValueByStringKeyWithArr } from 'utils/objects';
import {
  TVacanciesTempAddressFlag,
  VacanciesRevenueTypeEnum,
  VacanciesTempAddressFlagEnum,
} from 'types/vacancies';

import { TProps } from './types';
import { mapValues, mapValuesToDraft } from './formUtils';
import { validationSchema } from './validation';
import styles from './VacanciesForm.module.less';

export const VacanciesForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollapseTabs, setActiveCollapseTabs] = React.useState([
    'content',
    'description',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const {
    setValue,
    control,
    formState: { errors },
  } = methods;

  const [addressFlag, address] = useWatch({
    name: ['addressFlag', 'address'],
    control,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = (values: FieldValues) => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollapseTabs([
        ...Array.from(
          new Set([
            ...activeCollapseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), specialization: null });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  const onChangeRevenue = e => setValue('revenueType', e.target.value);

  const onAddressFlagChange = e => {
    const value = e.target.value as TVacanciesTempAddressFlag;
    setValue('addressFlag', value);
    methods.reset({
      ...methods.getValues(),
      address: { region: null, comment: null },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='name'
                label='Название вакансии'
                required
                placeholder='Введите название'
                showCount
                maxLength={100}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='specialization'
                label='Специализация'
                placeholder='Выберите специализацию'
                requestParams={{
                  entityType: 'vacancies',
                  fieldType: 'specialization',
                  lang: langValue,
                }}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Typography.Text>
                Предполагаемый уровень дохода в месяц или за объем работ
              </Typography.Text>
            </Col>
            <Col span={24} className={styles.radioContainer}>
              <Radio.Group
                defaultValue={VacanciesRevenueTypeEnum.BeforeDeduction}
                onChange={onChangeRevenue}
              >
                <Radio value={VacanciesRevenueTypeEnum.BeforeDeduction}>
                  До вычета налогов
                </Radio>
                <Radio value={VacanciesRevenueTypeEnum.AfterDeduction}>
                  На руки
                </Radio>
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Row className={styles.priceInputs}>
                <Input.Group compact>
                  <Col className={styles.priceInputsItem}>
                    <InputField
                      name='revenueFrom'
                      placeholder='От'
                      type='number'
                      maxLength={9}
                    />
                  </Col>
                  <Col className={styles.priceInputsItem}>
                    <InputField
                      name='revenueTo'
                      placeholder='До'
                      type='number'
                      maxLength={9}
                      addonAfter={
                        <CustomIcon
                          name={SvgIconNames.RubCircle}
                          className={styles.priceWithIcon}
                        />
                      }
                    />
                  </Col>
                </Input.Group>
              </Row>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='organization'
                label='Организация'
                placeholder='Введите название организации'
                showCount
                maxLength={100}
                required
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Typography.Text>
                Адрес, где будет работать будущий сотрудник, или адрес основного
                офиса, если предполагается разъездная работа
              </Typography.Text>
            </Col>
            <Col span={24} className={styles.radioContainer}>
              <Radio.Group
                defaultValue={addressFlag}
                onChange={onAddressFlagChange}
              >
                <Radio value={VacanciesTempAddressFlagEnum.ExcludeAddress}>
                  Не указывать адрес
                </Radio>
                <Radio value={VacanciesTempAddressFlagEnum.IncludeAddress}>
                  Указывать адрес
                </Radio>
              </Radio.Group>
            </Col>
            <Col span={24} className={styles.locationContainer}>
              <LocationSelector
                name='address'
                disabledForm={
                  !address ||
                  addressFlag === VacanciesTempAddressFlagEnum.ExcludeAddress
                }
              />
            </Col>
            <Col span={24} className={styles.bottomSelectors}>
              <Row gutter={24}>
                <Col xs={24} md={24} lg={8}>
                  <Select
                    name='workType'
                    label='Тип занятости'
                    placeholder='Выберите тип занятости'
                    options={VACANCIES_WORK_TYPE_OPTIONS}
                    required
                  />
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Select
                    name='workSchedule'
                    label='График работы'
                    placeholder='Выберите график работы'
                    options={VACANCIES_WORK_SCHEDULE_OPTIONS}
                    required
                  />
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Select
                    name='workExperience'
                    label='Опыт работы'
                    placeholder='Выберите опыт работы'
                    options={VACANCIES_WORK_EXPERIENCE_OPTIONS}
                    required
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollapseTabs(value as string[])}
          activeKey={activeCollapseTabs}
        >
          <Collapse.Panel header='Описание вакансии' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='description'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(errors, 'description')?.[0]?.text
                      ?.message
                  }
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
