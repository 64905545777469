// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfo-module__row--E6pj1 {\n  padding-bottom: 0 !important;\n}\n.UserInfo-module__row--E6pj1 .ant-descriptions-item-container .ant-descriptions-item-content {\n  margin-bottom: 24px !important;\n}\n.UserInfo-module__row--E6pj1 .ant-descriptions-item-container .ant-descriptions-item-label {\n  margin-bottom: 4px !important;\n}\n.UserInfo-module__row--E6pj1 .ant-descriptions-item-container .ant-descriptions-item-label::after {\n  content: '';\n}\n.UserInfo-module__rowLast--MlugI .ant-descriptions-item-container .ant-descriptions-item-content {\n  margin-bottom: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Business/components/ViewContent/components/UserInfo/UserInfo.module.less"],"names":[],"mappings":"AAAA;EAEE,4BAAA;AAAF;AAFA;EAMI,8BAAA;AADJ;AALA;EAUI,6BAAA;AAFJ;AAII;EACC,WAAA;AAFL;AASA;EAII,2BAAA;AAVJ","sourcesContent":[".row {\n\t:global {\n\t\tpadding-bottom: 0 !important;\n\t\t\n\t\t.ant-descriptions-item-container {\n\t\t\t.ant-descriptions-item-content {\n\t\t\t\tmargin-bottom: 24px !important;\n\t\t\t}\n\n\t\t\t.ant-descriptions-item-label {\n\t\t\t\tmargin-bottom: 4px !important;\n\n\t\t\t\t&::after {\n\t\t\t\t\tcontent: '';\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n\n.rowLast {\n\t:global {\n\t\t.ant-descriptions-item-container {\n\t\t\t.ant-descriptions-item-content {\n\t\t\t\tmargin-bottom: 0 !important;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "UserInfo-module__row--E6pj1",
	"rowLast": "UserInfo-module__rowLast--MlugI"
};
export default ___CSS_LOADER_EXPORT___;
