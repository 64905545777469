// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SalesForm-module__dateNumberContainer--zlSGc {\n  gap: 24px;\n}\n@media (max-width: 1199px) {\n  .SalesForm-module__dateNumberContainer--zlSGc {\n    gap: 0;\n  }\n}\n.SalesForm-module__date--O70FT {\n  flex: 0 1 346px;\n}\n@media (max-width: 1199px) {\n  .SalesForm-module__date--O70FT {\n    flex: 1 1 100%;\n  }\n}\n.SalesForm-module__number--DyXEq {\n  flex: 0 1 271px;\n}\n@media (max-width: 1199px) {\n  .SalesForm-module__number--DyXEq {\n    flex: 1 1 100%;\n  }\n}\n.SalesForm-module__materialSection--IbwTr {\n  margin-top: 25px;\n}\n.SalesForm-module__materialItem--yv8vS {\n  margin-top: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Sales/components/SalesForm/SalesForm.module.less"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AACE;EAAA;IACE,MAAA;EAEF;AACF;AACA;EACE,eAAA;AACF;AACE;EAAA;IACE,cAAA;EAEF;AACF;AACA;EACE,eAAA;AACF;AACE;EAAA;IACE,cAAA;EAEF;AACF;AACA;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".dateNumberContainer {\n  gap: 24px;\n\n  @media (max-width: 1199px) {\n    gap: 0;\n  }\n}\n\n.date {\n  flex: 0 1 346px;\n\n  @media(max-width: 1199px) {\n    flex: 1 1 100%;\n  }\n}\n\n.number {\n  flex: 0 1 271px;\n\n  @media(max-width: 1199px) {\n    flex: 1 1 100%;\n  }\n}\n\n.materialSection {\n  margin-top: 25px;\n}\n\n.materialItem {\n  margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateNumberContainer": "SalesForm-module__dateNumberContainer--zlSGc",
	"date": "SalesForm-module__date--O70FT",
	"number": "SalesForm-module__number--DyXEq",
	"materialSection": "SalesForm-module__materialSection--IbwTr",
	"materialItem": "SalesForm-module__materialItem--yv8vS"
};
export default ___CSS_LOADER_EXPORT___;
