import React from 'react';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';

import { AsyncSelect, TAsyncSelectProps } from '../base/AsyncSelect';

type IProps = Omit<TAsyncSelectProps, 'fetchOptions' | 'fetchInitialValue'> & {
  type:
    | 'attractions'
    | 'resorts'
    | 'hotels'
    | 'restaurants'
    | 'events'
    | 'articles'
    | 'news';
  nameQueryField?: string;
};

export const PublishedEntityAsyncSelect: React.FC<IProps> = ({
  label,
  type,
  nameQueryField = 'name',
  ...props
}) => {
  const requestUrl = urls.api[type].public.get;
  const fetchDataFn = async nameQuery => {
    const res = await apiClient.get(requestUrl, {
      params: { [nameQueryField]: nameQuery },
    });
    return res.data.data?.map(item => {
      return {
        id: item.id,
        name: item.name,
        image: item.image,
        address: item.address,
        shortDescription: item.shortDescription,
      };
    });
  };

  const fetchInitialValueFn = async ids => {
    const res = await apiClient.get(requestUrl, { params: { ids } });
    const data = res?.data?.data;
    if (!props.mode) {
      return {
        id: data[0].id,
        name: data[0].name,
        image: data[0].image,
        address: data[0].address,
        shortDescription: data[0].shortDescription,
      };
    }
    return data.map(item => {
      return {
        id: item.id,
        name: item.name,
        image: item.image,
        address: item.address,
        shortDescription: item.shortDescription,
      };
    });
  };

  return (
    <>
      <AsyncSelect
        label={label}
        allowClear
        {...props}
        fetchOptions={fetchDataFn}
        fetchInitialValue={fetchInitialValueFn}
      />
    </>
  );
};
