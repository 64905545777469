import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';

import { getPriority } from 'store/slices/blockPriority/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { AboutRegionBlockPriority } from 'constants/blockPriority';
import { MainLayout } from 'components/Layout/MainLayout';
import { PriorityForm } from 'components/form/PriorityForm/PriorityForm';
import { IComponent } from 'store/slices/blockPriority/interfaces';

import { PageTitles } from '../../constants/pageTitles';

const componentsData = {
  [AboutRegionBlockPriority.hero]: {
    link: 'ru/slider',
    name: 'Слайдер',
  },
  [AboutRegionBlockPriority.info]: {
    name: 'Данные об СКФО в цифрах',
    link: 'aboutRegionInfo',
  },
  [AboutRegionBlockPriority.description]: {
    name: 'Описание региона',
    link: 'description',
  },
  [AboutRegionBlockPriority.regions]: {
    name: 'Список субъектов',
  },
  [AboutRegionBlockPriority.map]: {
    name: 'Карта округа',
  },
};

const serializeComponents = (data: IComponent[]) => {
  return data.filter(value => {
    return (
      value.name === AboutRegionBlockPriority.hero ||
      value.name === AboutRegionBlockPriority.info ||
      value.name === AboutRegionBlockPriority.description ||
      value.name === AboutRegionBlockPriority.regions ||
      value.name === AboutRegionBlockPriority.map
    );
  });
};
export const AboutRegion = () => {
  const [isLoading, setIsLoading] = useState(true);
  const state = useAppSelector(state => state.blockPriority);
  const dispatch = useAppDispatch();

  const loadData = () => {
    dispatch(getPriority('aboutRegion'))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MainLayout pageTitle={PageTitles.mainRegionEdit}>
      {isLoading ? (
        <Row justify='center'>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        <PriorityForm
          initialValues={serializeComponents(state.components)}
          data={componentsData}
          title='Компоненты "О регионе"'
          type='aboutRegion'
        />
      )}
    </MainLayout>
  );
};
