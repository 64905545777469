import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveInvestInfrastructuresItem,
  saveInvestInfrastructuresItemAsDraft,
} from 'store/slices/investInfrastructures/actions';
import {
  IInvestInfrastructuresItem,
  TInvestInfrastructuresDraftItem,
} from 'store/slices/investInfrastructures/interfaces';

import { InvestInfrastructuresForm } from './components/InvestInfrastructuresForm/InvestInfrastructuresForm';
import { DEFAULT_STATE } from './components/InvestInfrastructuresForm/constants';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IInvestInfrastructuresItem) => {
    setIsLoading(true);
    dispatch(saveInvestInfrastructuresItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investInfrastructures');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TInvestInfrastructuresDraftItem) => {
    setIsLoading(true);
    dispatch(saveInvestInfrastructuresItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/investInfrastructures');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/investInfrastructures');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <InvestInfrastructuresForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={DEFAULT_STATE}
      />
    </MainLayout>
  );
};
