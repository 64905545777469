// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form-module__formItem--WyxN4 {\n  margin-top: 8px;\n}\n.Form-module__gallerySection--rTCjh {\n  margin-top: 22px;\n}\n.Form-module__materialSection--G_QEy {\n  margin-top: 49px;\n}\n.Form-module__materialItem--JrN2H {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/CurrentResidents/components/Form/Form.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,gBAAA;AACF","sourcesContent":[".formItem {\n  margin-top: 8px;\n}\n.gallerySection{\n  margin-top: 22px;\n}\n.materialSection {\n  margin-top: 49px;\n}\n.materialItem{\n  margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "Form-module__formItem--WyxN4",
	"gallerySection": "Form-module__gallerySection--rTCjh",
	"materialSection": "Form-module__materialSection--G_QEy",
	"materialItem": "Form-module__materialItem--JrN2H"
};
export default ___CSS_LOADER_EXPORT___;
