import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Col, notification, Row, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { ICardItem, ICardResponse } from 'store/slices/b2bCard/interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteCardItem, getCard } from 'store/slices/b2bCard/actions';
import { LangEnumKeys } from 'constants/lang';
import { PageTitles } from 'constants/pageTitles';
import { useDeepEffect } from 'utils/useDeepEffect';
import { CardTable } from 'components/Card/CardTable';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { MainLayout } from 'components/Layout/MainLayout';

/**
 * Форма для редактиования блока B2С "О Регионе"
 * @component
 */

interface IFormValues extends ICardResponse {
  lang: { value: LangEnumKeys; label: string };
}

export const Info = () => {
  const dispatch = useAppDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [currentItem, setCurrentItem] = useState<ICardItem | null>(null);

  const data = useAppSelector(state => state.b2bCard);

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };
  const handleRemove = (id: number) =>
    dispatch(deleteCardItem({ cardItemId: id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно удалена',
        });
        modalCloseHandler();
        loadData();
      });

  const methods = useForm<IFormValues>({
    defaultValues: { lang: { value: 'ru', label: 'Русский' } },
  });

  const loadData = () => {
    dispatch(getCard({ lang: lang.value, type: 'about-region' }))
      .unwrap()
      .then(data => {
        methods.reset({ ...methods.getValues(), ...data });
      });
  };

  const getActions = item => [
    {
      element: <Typography.Text type='danger'>Удалить</Typography.Text>,
      handler: () => {
        setModalIsOpen(true);
        setCurrentItem(item);
      },
    },
    {
      element: (
        <Link to={`${data.card.id}/${item.id}/edit`}>
          <Typography.Text>Редактировать</Typography.Text>
        </Link>
      ),
    },
  ];

  const getLink = (id: number) => `${data.card.id}/${id}/edit`;

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    loadData();
  }, [lang]);

  return (
    <MainLayout
      pageTitle={PageTitles.aboutRegionInfo}
      extraButtonsList={[
        <Link key={1} to={`${data.card.id}/add`}>
          <Button type='primary'>Создать</Button>
        </Link>,
      ]}
    >
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical indent-top'>
          <LangSelectSection />
          {data.isLoading ? (
            <Row justify='center'>
              <Col>
                <Spin size='large' />
              </Col>
            </Row>
          ) : (
            <Card style={{ marginTop: 10 }}>
              <CardTable
                data={data.card.cardItems.data}
                getActions={getActions}
                getLink={getLink}
                showSubtitle
              />
            </Card>
          )}
        </form>
      </FormProvider>

      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление слайда'
              okText='Удалить'
              text={<div>Вы хотите удалить слайд?</div>}
              onSubmit={() => handleRemove(currentItem.id)}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
