// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormCommentsFiles-module__modal--vy4lb .ant-modal-title {\n  margin-right: 40px;\n}\n.FormCommentsFiles-module__fileUploader--qhhZu .ant-upload-list {\n  margin-top: -10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reports/components/ViewInfo/components/Comments/components/FormCommentsFiles/FormCommentsFiles.module.less"],"names":[],"mappings":"AAAA;EAGM,kBAAA;AADN;AAMA;EAGM,iBAAA;AANN","sourcesContent":[".modal {\n  :global {\n    .ant-modal-title {\n      margin-right: 40px;\n    }\n  }\n}\n\n.fileUploader {\n  :global {\n    .ant-upload-list {\n      margin-top: -10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "FormCommentsFiles-module__modal--vy4lb",
	"fileUploader": "FormCommentsFiles-module__fileUploader--qhhZu"
};
export default ___CSS_LOADER_EXPORT___;
