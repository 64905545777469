import React from 'react';
import { Col, Form, Select } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { apiClient } from 'utils/http';
import { urls } from 'store/api';
import { isObject } from 'utils/helpers';

type TProps = {
  name: string;
  entityType: string;
  fieldType: string;
};

export const GetThereItemSelector: React.FC<TProps> = ({
  name,
  entityType,
  fieldType,
}) => {
  const [options, setOptions] = React.useState([]);
  const { control } = useFormContext();
  const getThereValues = useWatch({ name: 'getThere', control });

  const selectedGetThereWays = React.useMemo(() => {
    return getThereValues.map(item => item.selectedWay);
  }, [getThereValues]);

  const getComfortGroups = async () => {
    const { data } = await apiClient.get(urls.api.catalog.get, {
      params: {
        entityType,
        fieldType,
      },
    });

    if (data?.data?.length) {
      const getThereWays = data.data.map(getThereWay => ({
        ...getThereWay,
        value: getThereWay.id,
        label: getThereWay.name,
      }));
      setOptions(getThereWays);
    }
  };

  React.useEffect(() => {
    getComfortGroups();
  }, []);

  const checkIsSelectorChosen = id => {
    if (selectedGetThereWays.length) {
      const findChosenSelector = selectedGetThereWays.find(
        getThereWay => isObject(getThereWay) && getThereWay.value === id
      );

      return !!findChosenSelector;
    }
    return false;
  };

  const onSelectWay = (option, onChange) => {
    onChange(option);
  };

  return (
    <Col xs={{ span: 24 }} md={{ span: 12 }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Form.Item
            label='Способ как добраться'
            help={fieldState.error?.message}
            validateStatus={fieldState.error ? 'error' : 'success'}
            required
          >
            <Select
              defaultValue='Не указан'
              value={field.value ? field.value : 'Не указан'}
              onSelect={(_, option) => onSelectWay(option, field.onChange)}
            >
              {options?.map(item => (
                <Select.Option
                  key={item.id}
                  value={item.value}
                  disabled={checkIsSelectorChosen(item.id)}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      />
    </Col>
  );
};
