import React from 'react';
import { notification, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';
import { StickyConfirmModal } from 'components/modals/StickyConfirmModal';
import { PageTitles } from 'constants/pageTitles';
import {
  loadContractExchangeList,
  removeContractExchangeItem,
  setPublishContractExchange,
  setUnPublishContractExchange,
} from 'store/slices/contractExchange/actions';
import { IContractExchange } from 'store/slices/contractExchange/interfaces';
import { StatusesEnum } from 'constants/status';
import { ContractExchangeStatusesEnum } from 'constants/contractsStatus';
import { canBePublished, canBeUnPublished } from 'utils/rights';

import { Filters } from './components/Filters';
import { ListTable } from './components/ListTable';

export const List = () => {
  const [sort, setSort] = React.useState(null);
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { b2bUrl } = useAppSelector(state => state.configSlice);
  const { list, total, limit } = useAppSelector(
    state => state.contractExchange
  );
  const loadContractExchange = () => {
    dispatch(
      loadContractExchangeList({
        ...query,
        statuses: Object.values(ContractExchangeStatusesEnum),
        sort,
      })
    );
  };

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  useDeepEffect(() => {
    loadContractExchange();
  }, [query, sort]);

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [query.offset]);

  const modalCloseHandler = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const handlePublish = async id => {
    await dispatch(setPublishContractExchange(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно опубликован',
        });
        loadContractExchange();
      });
  };

  const handleUnPublish = async id => {
    await dispatch(setUnPublishContractExchange(id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно снят с публикации',
        });
        loadContractExchange();
      });
  };

  const handleRemove = async () => {
    await dispatch(removeContractExchangeItem(currentItem.id))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Контракт успешно удален',
        });
        loadContractExchange();
        modalCloseHandler();
      });
  };

  const getActions = (item: IContractExchange) => [
    item.status === StatusesEnum.PUBLISHED && {
      key: 'onWebsite',
      label: (
        <a
          href={`${b2bUrl}/contract-exchange/${item.id}`}
          target='_blank'
          rel='noreferrer nofollow'
        >
          На сайте
        </a>
      ),
      handler: () => console.log('link to portal'),
    },
    {
      key: 'preview',
      label: <Link to={`/contractExchange/${item.id}`}>Превью</Link>,
    },
    {
      key: 'edit',
      label: (
        <Link to={`/contractExchange/${item.id}/edit`}>Редактировать</Link>
      ),
    },
    canBeUnPublished(item) && {
      key: 'unPublish',
      label: <Typography.Text>Снять с публикации</Typography.Text>,
      onClick: () => handleUnPublish(item.id),
    },
    canBePublished(item) && {
      key: 'publish',
      label: <Typography.Text>Опубликовать</Typography.Text>,
      onClick: () => handlePublish(item.id),
    },
    {
      key: 'delete',
      label: <Typography.Text type='danger'>Удалить</Typography.Text>,
      onClick: () => {
        if (item.status === StatusesEnum.PUBLISHED) {
          notification.error({
            message: 'Необходимо снять сущность с публикации',
          });
        } else {
          setModalIsOpen(true);
          setCurrentItem(item);
        }
      },
    },
  ];

  return (
    <MainLayout pageTitle={PageTitles.contractExchange} aside={<Filters />}>
      <Content>
        <ListTable
          data={list}
          total={total}
          getActions={getActions}
          limit={limit}
          setSort={setSort}
        />
      </Content>
      {currentItem?.id && (
        <>
          {modalIsOpen && (
            <StickyConfirmModal
              isOpen={modalIsOpen}
              onRequestClose={modalCloseHandler}
              title='Удаление контракта'
              okText='Удалить'
              text={
                <div>
                  Вы хотите убрать с портала{' '}
                  <Typography.Text strong>
                    {currentItem.name || ''}
                  </Typography.Text>
                  ?
                </div>
              }
              onSubmit={handleRemove}
              okButtonProps={{ danger: true }}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};
