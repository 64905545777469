import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';

import { AppRouter } from 'components/AppRouter';
import { store } from 'store';
import './styles/index.less';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import { setConfig } from 'store/slices/config/configSlice';
import { FirefoxFocusScrollFix } from 'components/FirefoxFocusScrollFix/FirefoxFocusScrollFix';

type TProps = {
  config: {
    portal_b2c_url: string;
    portal_b2b_url: string;
    api_url: string;
  };
};

const App: React.FC<TProps> = ({ config }) => {
  store.dispatch(
    setConfig({
      b2cUrl: config.portal_b2c_url,
      b2bUrl: config.portal_b2b_url,
      backendUrl: config.api_url,
    })
  );

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <AppRouter />
          <ScrollToTop />
          <FirefoxFocusScrollFix />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
