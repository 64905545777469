import React from 'react';
import { useParams } from 'react-router';
import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { loadCommentsList } from 'store/slices/formComments/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { clearFormCommentsData } from 'store/slices/formComments/formCommentsSlice';

import { FormCommentsCreateComment } from './components/FormCommentsCreateComment/FormCommentsCreateComment';
import { FormCommentsItem } from './components/FormCommentsItem/FormCommentsItem';
import styles from './FormComments.module.less';

export const FormComments = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [offsetBottom, setOffsetBottom] = React.useState<number | null>(null);
  const [isDocumentsSectionLoaded, setIsDocumentsSectionLoaded] =
    React.useState(false);

  const { list, limit, total } = useAppSelector(state => state.formComments);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [comments, setComments] = React.useState(list || []);

  const loadComments = () => {
    dispatch(loadCommentsList({ formId: id, offset }))
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    loadComments();
    return () => {
      dispatch(clearFormCommentsData());
    };
  }, [offset]);

  React.useEffect(() => {
    if (list.length) {
      if (offset === 0) {
        setComments(list);
      }
      if (offset > 0) {
        setComments([...list, ...comments]);
      }
    }
  }, [list]);

  const preparedList = React.useMemo(() => {
    return comments.length ? [...comments].sort((a, b) => a.id - b.id) : [];
  }, [comments]);

  useDeepEffect(() => {
    /* Здесь настраивается скроллинг по нажатию на кнопку показать ещё */

    if (isDocumentsSectionLoaded && offsetBottom) {
      window.scrollTo({
        top: window.document.body.scrollHeight - offsetBottom,
        left: 0,
        behavior: 'instant' as any,
      });
    }
  }, [comments]);

  const onLoadMore = () => {
    setIsLoading(true);
    setIsDocumentsSectionLoaded(true);
    setOffsetBottom(
      window.document.body.scrollHeight -
        window.document.documentElement.scrollTop
    );
    setOffset(offset + limit);
  };

  const refetchComments = () => {
    setIsDocumentsSectionLoaded(false);
    if (offset === 0) {
      loadComments();
    } else {
      setOffset(0);
    }
  };

  return (
    <React.Fragment>
      {preparedList.length < total && (
        <div className={styles.loadMore}>
          <Button type='link' onClick={onLoadMore} loading={isLoading}>
            Ещё комментарии
          </Button>
        </div>
      )}
      {preparedList.length > 0 && (
        <div className={styles.list}>
          {preparedList.map(comment => (
            <FormCommentsItem key={String(comment.id)} {...comment} />
          ))}
        </div>
      )}
      <FormCommentsCreateComment refetchComments={refetchComments} />
    </React.Fragment>
  );
};
