import React from 'react';
import { Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { isString } from 'utils/helpers';

import { ENTITY_REGION_RELATIONS_OPTIONS } from '../constants';

export const PlaceholderIcon = ({ selectedName }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    <img src={`/public/images/place/${selectedName || 'unknown'}.svg`} alt='' />
  </div>
);

export const RegionRelationsTypeSelector = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name='type'
      control={control}
      render={({ field }) => {
        if (isString(field.value)) {
          const option = ENTITY_REGION_RELATIONS_OPTIONS.find(
            item => item.value === field.value
          );
          if (option) {
            field.onChange({
              key: option.value,
              value: option.value,
              children: option.label,
            });
          }
        }
        return (
          <Select
            placeholder={
              <PlaceholderIcon
                selectedName={field.value ? field.value.value : null}
              />
            }
            value={null}
            dropdownMatchSelectWidth={false}
            onSelect={(_, option) => {
              field.onChange(option);
            }}
            allowClear
            onClear={() => field.onChange(null)}
          >
            {ENTITY_REGION_RELATIONS_OPTIONS.map(entityName => (
              <Select.Option
                key={entityName.value}
                value={entityName.value}
                style={{
                  background:
                    field.value?.value === entityName.value && '#E6F7FF',
                }}
              >
                {entityName.label}
              </Select.Option>
            ))}
          </Select>
        );
      }}
    />
  );
};
