import React from 'react';
import { Button, Col, Row, Card } from 'antd';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';

import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { PhoneNumberField } from 'components/form/base/PhoneNumberField';
import { IAdminsItem } from 'store/slices/admin/interfaces';
import { Select } from 'components/form/base/Select';
import { roleNamesHash, Roles } from 'constants/roles';
import { useAppSelector } from 'store';
import { phonePlaceHoldersHash } from 'constants/phonePlaceholder';

import { mapValues, validationSchema } from './formUtils';

type TProps = {
  initialValues: IAdminsItem & { email: string };
  onFinish: (values: IAdminsItem) => void;
  cancelButtonHandler: () => void;
  isLoading: boolean;
};

export const AdminForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  cancelButtonHandler,
  isLoading,
}) => {
  const { role, id: userId } = useAppSelector(state => state.account);
  const { id } = useParams();

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const submit = (values: FieldValues) => {
    const preparedValues = mapValues(values);
    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputField
                name='firstName'
                label='Имя'
                required
                placeholder='Введите имя'
                maxLength={75}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputField
                name='lastName'
                label='Фамилия'
                required
                placeholder='Введите фамилию'
                maxLength={75}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputField name='email' label='Email' required disabled />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <PhoneNumberField
                name='phone'
                label='Телефон'
                placeholder={phonePlaceHoldersHash.mobilePhone}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputField
                name='position'
                label='Должность'
                placeholder='Введите должность'
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Select
                name='role'
                label='Роль'
                required
                options={[
                  ...Object.keys(roleNamesHash).map(item => ({
                    value: item,
                    label: roleNamesHash[item],
                  })),
                ]}
                disabled={role !== Roles.Admin || Number(id) === userId}
              />
            </Col>
          </Row>
        </Card>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
