import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import { uploadContent, uploadImage, uploadImageArray } from 'utils/upload';

import { IEventsItem } from './interfaces';

type TListResponse = { data: IEventsItem[]; total: number };

export const loadEventsList = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
    isSortPriority?: boolean;
  },
  { state: RootState }
>('events/loadList', async (params, { getState }) => {
  params.limit = params.limit || getState().events.limit;
  const { data } = await apiClient.get<TListResponse>(urls.api.events.get, {
    params: params,
  });
  return data;
});

export const loadEventsListMainTourism = createAsyncThunk<
  TListResponse,
  {
    name?: string;
    limit?: number;
    offset?: number;
    entityType?: string;
    type?: string;
    sort?: string;
    lang?: string;
    isSortPriority?: boolean;
  },
  { state: RootState }
>('events/loadEventsListMainTourism', async (params, { getState }) => {
  params.limit = params.limit || getState().events.limit;
  const { data } = await apiClient.get<TListResponse>(
    urls.api.events.getMainTourism,
    {
      params: params,
    }
  );
  return data;
});

export const loadEventsItem = createAsyncThunk<IEventsItem, string>(
  'events/loadItem',
  async id => {
    const { data } = await apiClient.get<IEventsItem>(
      getUrlWithParams(urls.api.events.getOne, {
        id,
      })
    );
    return data;
  }
);

export const bulkUpdateEventsItem = createAsyncThunk<
  IEventsItem[],
  Partial<IEventsItem>[]
>('events/bulkUpdateItems', async params => {
  const { data } = await apiClient.patch<IEventsItem[]>(
    urls.api.events.bulkPatch,
    params
  );
  return data;
});

export const saveEventsItem = createAsyncThunk<
  IEventsItem,
  Partial<IEventsItem>
>('events/saveItem', async ({ id, ...params }) => {
  const image = await uploadImage(params.image);
  const heroImage = await uploadImage(params.heroImage);
  const gallery = params.gallery && (await uploadImageArray(params.gallery));
  const content = params.content && (await uploadContent(params.content));
  const sortPriorityMainPage = params.sortPriorityMainPage ?? undefined;

  if (id) {
    const { data } = await apiClient.patch<IEventsItem>(
      getUrlWithParams(urls.api.events.patch, { id }),
      {
        ...params,
        image,
        heroImage,
        gallery,
        content,
        sortPriorityMainPage: sortPriorityMainPage,
      }
    );
    return data;
  } else {
    const { data } = await apiClient.post<IEventsItem>(urls.api.events.post, {
      ...params,
      image,
      heroImage,
      gallery,
      content,
      sortPriorityMainPage: sortPriorityMainPage,
    });
    return data;
  }
});

export const saveEventsItemAsDraft = createAsyncThunk<IEventsItem, IEventsItem>(
  'events/saveItem',
  async ({ id, ...params }) => {
    const image = await uploadImage(params.image);
    const heroImage = await uploadImage(params.heroImage);
    const gallery = await uploadImageArray(params.gallery);
    const content = await uploadContent(params.content);
    if (id) {
      const { data } = await apiClient.patch<IEventsItem>(
        getUrlWithParams(urls.api.events.draft.patch, { id }),
        {
          ...params,
          image,
          heroImage,
          gallery,
          content,
        }
      );
      return data;
    } else {
      const { data } = await apiClient.post<IEventsItem>(
        urls.api.events.draft.post,
        {
          ...params,
          image,
          heroImage,
          gallery,
          content,
        }
      );
      return data;
    }
  }
);

export const setPublishEvent = createAsyncThunk<void, string>(
  'events/publish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.events.publish.patch, { id })
    );
  }
);

export const setUnPublishEvent = createAsyncThunk<void, string>(
  'events/unPublish',
  async id => {
    return await apiClient.patch(
      getUrlWithParams(urls.api.events.unPublish.patch, { id })
    );
  }
);

export const mainTouristEvents = createAsyncThunk<void, string>(
  'events/mainTouristEvents',
  async id => {
    return await apiClient.get(
      getUrlWithParams(urls.api.events.unPublish.patch, { id })
    );
  }
);

export const removeEventsItem = createAsyncThunk<
  {
    accessToken: string;
    refreshToken: string;
  },
  {
    id;
  }
>('events/removeItem', async params => {
  const { data } = await apiClient.delete<{
    accessToken: string;
    refreshToken: string;
  }>(getUrlWithParams(urls.api.events.delete, { id: params.id }));
  return data;
});
