export enum LangEnum {
  ru = 'ru',
  en = 'en',
}

export const LangNamesHash = {
  [LangEnum.ru]: 'Русский',
  [LangEnum.en]: 'Английский',
};

export type LangEnumKeys = `${LangEnum}`;
