import React from 'react';
import dayjs from 'dayjs';
import { Button, Dropdown, Menu, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import { IBusinessItemResponse } from 'store/slices/business/interfaces';
import { CommonTable } from 'components/CommonTable/CommonTable';
import { formatPhoneNumber } from 'utils/format';
import { useQuery } from 'hooks/useQuery';
import { useDeepEffect } from 'utils/useDeepEffect';

import { getNameOrganization } from '../utils';

interface IProps {
  data: IBusinessItemResponse[];
  total: number;
  limit?: number;
  getActions: (item: IBusinessItemResponse) => ItemType[];
  setSort: (sort?: string) => void;
}

export const ListTable = ({
  data,
  total,
  limit = 20,
  getActions,
  setSort,
}: IProps) => {
  const query = useQuery();

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [query.offset]);

  const columns = [
    {
      key: 1,
      title: 'ФИО',
      width: 148,
      sorter: true,
      dataIndex: 'lastName',
      render: (
        _: unknown,
        { firstName, secondName, lastName, id }: IBusinessItemResponse
      ) => (
        <Link to={`${id}`}>
          <Typography.Text>
            {`${lastName} ${firstName} ${secondName}`}
          </Typography.Text>
        </Link>
      ),
    },
    {
      key: 2,
      title: 'Email',
      sorter: true,
      dataIndex: 'email',
      render: email => <Typography.Text>{email}</Typography.Text>,
    },
    {
      key: 3,
      title: 'Телефон',
      sorter: true,
      dataIndex: 'phone',
      render: phone => (
        <Typography.Text>{formatPhoneNumber(phone)}</Typography.Text>
      ),
    },
    {
      key: 4,
      title: 'Организация',
      render: (
        _: unknown,
        { organizationCount, organizationData }: IBusinessItemResponse
      ) =>
        organizationData.isDraft ? (
          <Typography.Text type='secondary'>Не заполнено</Typography.Text>
        ) : (
          <Typography.Text>
            {organizationCount > 1
              ? `${organizationCount} орг.`
              : getNameOrganization(organizationData)}
          </Typography.Text>
        ),
    },
    {
      key: 5,
      width: 134,
      title: 'Дата, время регистрации',
      sorter: true,
      dataIndex: 'createdAt',
      render: createdAt => (
        <Typography.Text>
          {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
        </Typography.Text>
      ),
    },
    {
      key: 6,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right' as const,
      render: (_: unknown, item: IBusinessItemResponse) => (
        <Dropdown
          placement='bottomRight'
          overlay={<Menu items={getActions(item)} />}
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data}
        total={total}
        limit={limit}
        setSort={setSort}
      />
    </>
  );
};
