// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View-module__contact--B8ZuB {\n  color: #262626 !important;\n}\n.View-module__contact--B8ZuB:hover {\n  color: #1890ff !important;\n}\n.View-module__contactIcon--CnLTv {\n  color: #262626;\n  margin-right: 9px;\n}\n.View-module__contactIconPhone--QVFz9 {\n  color: #262626;\n  margin-right: 9px;\n}\n.View-module__contactIconPhone--QVFz9 svg {\n  transform: rotate(90deg);\n}\n.View-module__contactItem--tWvWa {\n  word-break: break-all;\n}\n.View-module__extraButtons--c8svQ {\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n}\n.View-module__activityData--Zh2l6 {\n  display: flex;\n  gap: 24px;\n  margin-top: 24px;\n}\n@media (max-width: 800px) {\n  .View-module__activityData--Zh2l6 {\n    flex-direction: column;\n  }\n}\n.View-module__declineFormArea--RSsNY {\n  margin-bottom: -24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ContractExchange/View/View.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAEA;EACE,cAAA;EACA,iBAAA;AAAF;AAGA;EACE,cAAA;EACA,iBAAA;AADF;AADA;EAII,wBAAA;AAAJ;AAIA;EACE,qBAAA;AAFF;AAKA;EACE,aAAA;EACA,QAAA;EACA,eAAA;AAHF;AAMA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AAJF;AAME;EAAA;IACE,sBAAA;EAHF;AACF;AAMA;EACE,oBAAA;AAJF","sourcesContent":[".contact {\n  color: #262626 !important;\n  &:hover {\n    color: #1890ff !important;\n  }\n}\n\n.contactIcon {\n  color: #262626;\n  margin-right: 9px;\n}\n\n.contactIconPhone {\n  color: #262626;\n  margin-right: 9px;\n  svg {\n    transform: rotate(90deg);\n  }\n}\n\n.contactItem {\n  word-break: break-all;\n}\n\n.extraButtons {\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n}\n\n.activityData {\n  display: flex;\n  gap: 24px;\n  margin-top: 24px;\n\n  @media (max-width: 800px) {\n    flex-direction: column;\n  }\n}\n\n.declineFormArea {\n  margin-bottom: -24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "View-module__contact--B8ZuB",
	"contactIcon": "View-module__contactIcon--CnLTv",
	"contactIconPhone": "View-module__contactIconPhone--QVFz9",
	"contactItem": "View-module__contactItem--tWvWa",
	"extraButtons": "View-module__extraButtons--c8svQ",
	"activityData": "View-module__activityData--Zh2l6",
	"declineFormArea": "View-module__declineFormArea--RSsNY"
};
export default ___CSS_LOADER_EXPORT___;
