import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  saveSalesItem,
  saveSalesItemAsDraft,
} from 'store/slices/sales/actions';
import { ISalesItem, TSalesDraftItem } from 'store/slices/sales/interfaces';

import { SalesForm } from './components/SalesForm/SalesForm';
import { INITIAL_STATE } from './components/SalesForm/constants';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: ISalesItem) => {
    setIsLoading(true);
    dispatch(saveSalesItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/sales');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: TSalesDraftItem) => {
    setIsLoading(true);
    dispatch(saveSalesItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/sales');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/sales');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <SalesForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={INITIAL_STATE}
      />
    </MainLayout>
  );
};
