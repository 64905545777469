import { Button, Dropdown, Image, Menu, Tag, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { CommonTable } from 'components/CommonTable/CommonTable';
import { CurrentResidentsItem } from 'store/slices/currentResidents/interfaces';
import { getImageUrl } from 'utils/image';
import {
  Statuses,
  statusesColorsHash,
  statusesNamesHash,
} from 'constants/status';

import { NO_IMAGE } from './constants';
import styles from './Table.module.less';

interface TableProps {
  total: number;
  getActions: (item: CurrentResidentsItem) => ItemType[];
  setSort: (sort?: string) => void;
  limit?: number;
  items?: CurrentResidentsItem[];
}

export const Table = ({
  items = [],
  total,
  limit = 20,
  getActions,
  setSort,
}: TableProps) => {
  const columns: ColumnsType<TODO_ANY> = [
    {
      key: 'projectName',
      title: 'Название',
      sorter: true,
      dataIndex: 'projectName',
      render: (text: string, item: CurrentResidentsItem) => (
        <Typography>
          <Link
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            to={`/currentResidents/${item.id}/edit`}
          >
            <div style={{ flexShrink: 0 }}>
              <Image
                width={50}
                height={50}
                preview={false}
                src={
                  item?.image
                    ? getImageUrl(item.image, 50, 50) || NO_IMAGE
                    : NO_IMAGE
                }
                fallback={NO_IMAGE}
                style={{ flexShrink: 0 }}
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <div>{(text && text.trim()) || 'Без названия'}</div>
            </div>
          </Link>
        </Typography>
      ),
    },
    {
      key: 2,
      width: '20%',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: Statuses) => (
        <Tag color={statusesColorsHash[status]}>
          {statusesNamesHash[status]}
        </Tag>
      ),
    },
    {
      key: 3,
      title: '',
      width: 65,
      dataIndex: 'actions',
      fixed: 'right',
      render: (_: unknown, item: CurrentResidentsItem) => (
        <Dropdown
          placement='bottomRight'
          overlay={
            <Menu className={styles.actionDropdown} items={getActions(item)} />
          }
          arrow
          trigger={['click']}
        >
          <Button type='text' icon={<SettingOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Content>
      <CommonTable
        columns={columns}
        scroll={{ x: 800 }}
        total={total}
        limit={limit}
        dataSource={items}
        setSort={setSort}
      />
    </Content>
  );
};
