import React from 'react';

import { Slider } from '../Slider/Slider';
import { AddSlide } from '../Slider/AddSlide';
import { EditSlide } from '../Slider/EditSlide';
import { MainTourism } from './MainTourism';
import { List as AttractionsList } from '../AttractionsSample/List';
import { List as RoutesList } from '../RoutesSample/List';
import { List as ResortsList } from '../ResortsSample/List';
import { List as EventsList } from '../EventsSample/List';
import { List as ArticlesList } from '../ArticlesSample/List';
import { Edit as EditAttractions } from '../AttractionsSample/Edit';
import { Edit as EditRoutes } from '../RoutesSample/Edit';
import { Edit as EditResorts } from '../ResortsSample/Edit';
import { Edit as EditEvent } from '../EventsSample/Edit';
import { Edit as EditArticles } from '../ArticlesSample/Edit';

export const mainTourismRoutes = {
  path: '/mainTourism',
  children: [
    {
      path: '',
      element: <MainTourism />,
    },
    {
      path: ':lang/slider',
      element: <Slider slug='mainTourism' name='b2c-slider' />,
    },
    {
      path: ':lang/slider/:sliderName/add',
      element: <AddSlide slug='mainTourism' withImage />,
    },
    {
      path: ':lang/slider/:sliderName/:sliderItemId/edit',
      element: <EditSlide slug='mainTourism' withImage />,
    },
    {
      path: 'attractions',
      element: (
        <AttractionsList
          key='b2c-attractions'
          limit={4}
          sortFieldName='sortPriorityMainPage'
          addON={false}
          deleteON={false}
          isSortPriority={null}
        />
      ),
    },
    {
      path: 'attractions/:id/edit',
      element: (
        <EditAttractions
          slug='/mainTourism/attractions'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
    {
      path: 'resorts',
      element: (
        <ResortsList
          key='b2c-resorts'
          limit={4}
          sortFieldName='sortPriorityMainPage'
          addON={false}
          deleteON={false}
          isSortPriority={null}
        />
      ),
    },
    {
      path: 'resorts/:id/edit',
      element: (
        <EditResorts
          slug='/mainTourism/resorts'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
    {
      path: 'events',
      element: (
        <EventsList
          key='b2c-events'
          limit={3}
          sortFieldName='sortPriorityMainPage'
          addON={false}
          deleteON={false}
          isSortPriority={null}
        />
      ),
    },
    {
      path: 'events/:id/edit',
      element: (
        <EditEvent
          slug='/mainTourism/events'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
    {
      path: 'events/add',
      element: (
        <EditEvent
          slug='/mainTourism/events'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
    {
      path: 'routes',
      element: (
        <RoutesList
          key='b2c-routes'
          sortFieldName='sortPriorityMainPage'
          limit={3}
          addON={false}
          deleteON={false}
          isSortPriority={null}
        />
      ),
    },
    {
      path: 'routes/:id/edit',
      element: (
        <EditRoutes
          slug='/mainTourism/routes'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
    {
      path: 'articles',
      element: (
        <ArticlesList
          key='b2c-articles'
          sortFieldName='sortPriorityMainPage'
          limit={4}
          addON={false}
          deleteON={false}
          isSortPriority={null}
        />
      ),
    },
    {
      path: 'articles/:id/edit',
      element: (
        <EditArticles
          slug='/mainTourism/articles'
          sortFieldName='sortPriorityMainPage'
        />
      ),
    },
  ],
};
