import * as Yup from 'yup';

import {
  additionalPhoneNumberSchema,
  imageSchema,
  locationSchema,
  preferencesValidationSchema,
  seoValidationSchema,
  shortPhoneSchema,
} from 'utils/yup';
import {
  mapAddress,
  mapGallery,
  mapImage,
  mapSelect,
  mapValuesToAddress,
  mapValuesToSelect,
  mapValueStringToFieldArray,
  mapVideo,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  TInvestInfrastructuresDraftItem,
  IInvestInfrastructuresItem,
} from 'store/slices/investInfrastructures/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { stripTagsAndTrim } from 'utils/helpers';
import { inputUnmaskNumber } from 'constants/masks';

const mapContactsToForm = (
  contacts: IInvestInfrastructuresItem['contacts']
) => ({
  name: contacts.name || '',
  position: contacts.position || '',
  phone: contacts.phone || '',
  additional: contacts.additional || '',
  email: contacts.email || '',
});

const mapContacts = (contacts: IInvestInfrastructuresItem['contacts']) => ({
  name: contacts.name || null,
  position: contacts.position || null,
  phone: (contacts.phone && inputUnmaskNumber(contacts.phone)) || null,
  additional: contacts.additional || null,
  email: contacts.email || null,
});

const mapPreferencesToForm = (
  preferences: IInvestInfrastructuresItem['preferences']
) => {
  return preferences.map(preference => ({
    icon: preference.icon || null,
    title: preference.title || '',
    description: preference.description || '',
    alt: preference.alt || '',
  }));
};

const mapPreferences = (
  preferences: IInvestInfrastructuresItem['preferences']
) => {
  return preferences.map(preference => ({
    icon: preference.icon || null,
    title: preference.title || null,
    description: preference.description || null,
    alt: preference.alt || null,
  }));
};

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    shortDescription: values.shortDescription || '',
    territoryType: values.territoryData
      ? mapValuesToSelect(values.territoryData)
      : null,
    preferences: mapPreferencesToForm(values.preferences),
    videoUrls: mapValueStringToFieldArray(values.videoUrls),
    content: values.content
      ? [{ type: 'text', text: values.content }]
      : prepareContent([]),
    contacts: mapContactsToForm(values.contacts),
    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    address: values.address ? mapValuesToAddress(values.address) : {},
    polylines: values.polylines ? values.polylines : [],
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 80 символов')
      .max(80, 'Введите от 1 до 80 символов')
      .required(),
    image: imageSchema,
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    territoryType: Yup.mixed().required(),
    preferences: preferencesValidationSchema,
    gallery: Yup.array().of(imageSchema),
    videoUrls: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().url(),
      })
    ),
    content: Yup.array().of(
      Yup.object().shape({
        type: Yup.mixed(),
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length >= 100 && filteredText.length <= 5000;
          },
          message: 'Введите от 100 до 5000 символов',
        }),
      })
    ),
    contacts: Yup.object().shape({
      name: Yup.string().max(60, 'Введите не более 60 символов'),
      position: Yup.string().max(150, 'Введите не более 150 символов'),
      phone: shortPhoneSchema.notRequired(),
      additional: additionalPhoneNumberSchema,
      email: Yup.string().email(),
    }),
    status: Yup.mixed().required(),
    address: locationSchema,
  })
  .concat(seoValidationSchema);

export const mapValues = (values): IInvestInfrastructuresItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    image: mapImage(values.image),
    shortDescription: values.shortDescription,
    territoryType: mapSelect(values.territoryType) as number,
    preferences: mapPreferences(values.preferences),
    gallery: mapGallery(values.gallery),
    videoUrls: mapVideo(values.videoUrls) as string[],
    content: values.content[0].text || null,
    contacts: mapContacts(values.contacts),
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    status: mapSelect(values.status) as Statuses,
    address: mapAddress(values.address),
    polylines: values.polylines || [],
  };
};

export const mapValuesToDraft = (values): TInvestInfrastructuresDraftItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name?.trim() || null,
    image: values.image ? mapImage(values.image) : null,
    shortDescription: values.shortDescription ? values.shortDescription : null,
    territoryType: values.territoryType
      ? (mapSelect(values.territoryType) as number)
      : null,
    preferences: mapPreferences(values.preferences),
    videoUrls: values.videoUrls?.length ? mapVideo(values.videoUrls) : [],
    gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
    content:
      values.content?.length && values.content[0].text
        ? values.content[0].text
        : null,
    contacts: mapContacts(values.contacts),
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    address:
      values.address?.city || values.address?.settlement
        ? mapAddress(values.address)
        : null,
    polylines: values.polylines || [],
  };
};
