import React from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';

import { useDeepEffect } from 'utils/useDeepEffect';
import { useAppDispatch, useAppSelector } from 'store';
import { loadDocumentsList } from 'store/slices/formDocuments/actions';
import { useQuery } from 'hooks/useQuery';
import { clearFormDocumentsData } from 'store/slices/formDocuments/formDocumentsSlice';

import { Filters } from './components/Filters/Filters';
import { ListTable } from './components/ListTable';
import { UploadDocument } from './components/UploadDocument/UploadDocument';

export const FormDocuments = () => {
  const { list, total, limit } = useAppSelector(state => state.formDocuments);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const query = useQuery();

  const loadDocuments = () => {
    dispatch(
      loadDocumentsList({
        formId: id,
        ...query,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearFormDocumentsData());
    };
  }, []);

  useDeepEffect(() => {
    loadDocuments();
  }, [query]);

  return (
    <Row gutter={[24, 0]}>
      <Filters />
      <Col span={24}>
        <ListTable data={list} total={total} limit={limit} />
      </Col>
      <Col span={24} style={{ marginTop: total > limit ? 0 : 24 }}>
        <UploadDocument />
      </Col>
    </Row>
  );
};
