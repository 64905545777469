import * as Yup from 'yup';

import { addressContractSchema } from 'utils/yup';
import { mapSelect, mapShortAddress, mapValuesToAddress } from 'utils/mappings';
import { isNumber } from 'utils/helpers';
import { OrganizationTypeEnum } from 'types/organizations';
import {
  IContractExchange,
  TContractExchangeResponse,
} from 'store/slices/contractExchange/interfaces';
import { Statuses, StatusesEnum } from 'constants/status';
import { ContractExchangeStatusesEnum } from 'constants/contractsStatus';

import { getOrganizationName } from '../utils';

export const mapValuesToForm = values => {
  return {
    ...values,
    price: values.price ? values.price : null,
    address: values.address
      ? mapValuesToAddress(values.address)
      : { region: null },
    organization:
      values.organization.type === OrganizationTypeEnum.organization
        ? values.organization.fullName
        : getOrganizationName(values.organization),
    description: values.description,
    declineReason: values.declineReason ? values.declineReason : '',
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Введите от 3 до 80 символов')
    .max(80, 'Введите от 3 до 80 символов')
    .required(),
  address: addressContractSchema,
  type: Yup.mixed().required(),
  productType: Yup.mixed().required(),
  price: Yup.number()
    .nullable()
    .test(
      'is-price-valid',
      'Цена должна быть числом и не превышать 10 цифр',
      value => {
        if (value === null) return true;
        return isNumber(value) && value / 100 < 10000000000;
      }
    ),
  description: Yup.string().max(2000).required(),
  declineReason: Yup.string().when('status.value', {
    is: StatusesEnum.DECLINED,
    then: Yup.string()
      .min(1)
      .max(800)
      .required('Поле обязательно для заполнения'),
    otherwise: Yup.string().nullable(),
  }),
});

export const mapValues = (
  values: IContractExchange
): TContractExchangeResponse => {
  return {
    name: values.name.trim(),
    type: mapSelect(values.type) as string,
    price: values.price ? values.price : null,
    address: mapShortAddress(values.address),
    productType: mapSelect(values.productType) as number,
    description: values.description,
    status: mapSelect(values.status) as Statuses,
    declineReason:
      values.declineReason &&
      mapSelect(values.status) === ContractExchangeStatusesEnum.DECLINED
        ? values.declineReason
        : null,
  };
};
