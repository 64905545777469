import React from 'react';
import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import PaginateSelect from './PaginateSelect';
import { TAsyncPaginate } from './interfaces';

export const ControlledPaginateSelect: TAsyncPaginate = ({
  name,
  defaultValue,
  required,
  label,
  ...props
}) => {
  const { control, setValue } = useFormContext();
  const handleChange = option => {
    setValue(name, option);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #d9d9d9',
      boxShadow: 'none',
      height: '30px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Form.Item
          label={label}
          help={fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          required={required}
          noStyle={false}
        >
          <PaginateSelect
            {...props}
            name={field.name}
            value={field.value}
            onChange={handleChange}
            styles={customStyles}
          />
        </Form.Item>
      )}
      control={control}
    />
  );
};
