// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthLayout-module__wrapper--SG9G6 {\n  /* 64px header height */\n  padding-top: 64px;\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-flow: column nowrap;\n}\n.AuthLayout-module__content--jGuHW {\n  max-width: 624px;\n  margin: 24px auto 0;\n  padding: 0 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: column nowrap;\n  flex: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/auth/components/AuthLayout/AuthLayout.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;EACxB,OAAO;AACT","sourcesContent":[".wrapper {\n  /* 64px header height */\n  padding-top: 64px;\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-flow: column nowrap;\n}\n.content {\n  max-width: 624px;\n  margin: 24px auto 0;\n  padding: 0 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: column nowrap;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AuthLayout-module__wrapper--SG9G6",
	"content": "AuthLayout-module__content--jGuHW"
};
export default ___CSS_LOADER_EXPORT___;
