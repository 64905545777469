export const ERROR_CODE_KEY = 'UPLOADS_UNSUPPORTED_FILE_TYPE';

export const DEFAULT_ACCEPT =
  '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx';

export const VALID_FORMATS_LIST = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const ERROR_MESSAGE =
  'У файла недопустимый формат. Загрузите файл с расширением ' + DEFAULT_ACCEPT;
