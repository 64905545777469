import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ISliderItem,
  TSliderItemRequest,
  ISliderResponse,
  TSliderName,
} from './interfaces';
import { apiClient } from '../../../utils/http';
import { uploadImage } from '../../../utils/upload';

export const getSlider = createAsyncThunk<
  ISliderResponse,
  { lang: string; name: TSliderName }
>('slider/get', async ({ lang, name }) => {
  const { data } = await apiClient.get<ISliderResponse>(
    `slider/${name}/slider-item/${lang}`
  );
  return data;
});

export const getSliderItem = createAsyncThunk<
  ISliderItem,
  { sliderId: string; sliderItemId: string; lang: string }
>('slider/getSliderItem', async ({ sliderId, sliderItemId, lang }) => {
  const { data } = await apiClient.get<ISliderItem>(
    `slider/${sliderId}/slider-item/${lang}/${sliderItemId}`
  );
  return data;
});

export const deleteSliderItem = createAsyncThunk<
  ISliderItem,
  { sliderName: string; sliderItemId: number; lang: string }
>('slider/deleteSliderItem', async ({ sliderName, sliderItemId, lang }) => {
  const { data } = await apiClient.delete<ISliderItem>(
    `slider/${sliderName}/slider-item/${lang}/${sliderItemId}`
  );
  return data;
});

export const addSliderItem = createAsyncThunk<
  ISliderItem,
  { sliderId: string; payload: TSliderItemRequest; lang: string }
>('slider/addSliderItem', async ({ sliderId, payload, lang }) => {
  const image = await uploadImage(payload.image);
  const { data } = await apiClient.post<ISliderItem>(
    `slider/${sliderId}/slider-item/${lang}`,
    {
      slides: [{ ...payload, image }],
    }
  );
  return data;
});

export const changeSliderItem = createAsyncThunk<
  ISliderItem,
  { sliderId: string; payload: TSliderItemRequest; lang: string }
>('slider/changeSliderItem', async ({ sliderId, payload, lang }) => {
  const { id, ...params } = payload;
  const image = await uploadImage(payload.image);
  const { data } = await apiClient.patch<ISliderItem>(
    `slider/${sliderId}/slider-item/${lang}/${id}`,
    {
      ...params,
      image,
    }
  );
  return data;
});
