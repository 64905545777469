import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';

import {
  IFilterReportDocuments,
  IReportDocument,
  IRequestReportDocument,
  TResponseReportDocuments,
} from './interfaces';

export const loadReportDocumentsList = createAsyncThunk<
  TResponseReportDocuments,
  IFilterReportDocuments & { id: string },
  { state: RootState }
>('reportDocuments/loadList', async ({ id, ...params }, { getState }) => {
  const { limit } = getState().reportDocuments;
  const { data } = await apiClient.get<TResponseReportDocuments>(
    getUrlWithParams(urls.api.reportsDocuments.get, { id }),
    {
      params: {
        limit,
        ...params,
      },
    }
  );
  return data;
});

export const saveReportDocument = createAsyncThunk<
  IReportDocument,
  IRequestReportDocument
>('reportDocuments/saveItem', async params => {
  const { data } = await apiClient.post<IReportDocument>(
    urls.api.reportsDocuments.post,
    {
      ...params,
    }
  );
  return data;
});
