import React, { useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Col, notification, Row, Spin } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';

import { mapValuesToForm } from './utils';
import { getAlerts, setAlert } from '../../store/slices/alert/actions';
import { IAlert, TAlertType } from '../../store/slices/alert/interfaces';
import { useAppDispatch, useAppSelector } from '../../store';
import { MainLayout } from '../../components/Layout/MainLayout';
import { AlertForm } from './components/AlertForm';
import { LangSelectSection } from '../../components/form/LangSelectSection/LangSelectSection';
import { LangEnumKeys } from '../../constants/lang';
import { useDeepEffect } from '../../utils/useDeepEffect';

interface IAlertProps {
  pageTitle: string;
  type: TAlertType;
}

interface IFormValues {
  lang: { value: LangEnumKeys; label: string };
}

export const Alert: React.FC<IAlertProps> = ({ pageTitle, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const state = useAppSelector(state => state.alert);
  const methods = useForm<IFormValues>({
    defaultValues: { lang: { value: 'ru', label: 'Русский' } },
  });

  const initialValues = state.alerts.find(alert => alert.type === type);
  const dispatch = useAppDispatch();
  const onSubmit = (values: IAlert) => {
    setIsLoading(true);
    const mappedValues = mapValuesToForm(values);
    dispatch(setAlert(mappedValues))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    dispatch(getAlerts({ lang: lang.value, type }));
  }, [lang, type]);

  return (
    <MainLayout pageTitle={pageTitle}>
      <FormProvider {...methods}>
        <form className='ant-form ant-form-vertical'>
          <LangSelectSection />
        </form>
      </FormProvider>
      <Content>
        {state.isLoading ? (
          <Row justify='center'>
            <Col>
              <Spin size='large' />
            </Col>
          </Row>
        ) : (
          <AlertForm
            key={type}
            isLoading={isLoading}
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        )}
      </Content>
    </MainLayout>
  );
};
