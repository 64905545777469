import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import { resetDocuments } from 'store/slices/reportDocuments/reportDocumentsSlice';
import { loadReportDocumentsList } from 'store/slices/reportDocuments/actions';
import { useDeepEffect } from 'utils/useDeepEffect';

import { ListTable } from './components/ListTable/ListTable';
import { Filters } from './components/Filters/Filters';
import { UploadDocument } from './components/UploadDocument/UploadDocument';

export const Documents = () => {
  const { id } = useParams();
  const query = useQuery();

  const { list, total, limit } = useAppSelector(state => state.reportDocuments);
  const dispatch = useAppDispatch();

  const loadDocuments = () => {
    dispatch(
      loadReportDocumentsList({
        id,
        ...query,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(resetDocuments());
    };
  }, []);

  useDeepEffect(() => {
    loadDocuments();
  }, [query]);

  return (
    <Row gutter={[24, 0]}>
      <Filters />
      <Col span={24}>
        <ListTable data={list} total={total} limit={limit} />
      </Col>
      <Col span={24} style={{ marginTop: total > limit ? 0 : 24 }}>
        <UploadDocument />
      </Col>
    </Row>
  );
};
