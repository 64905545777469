import { LangEnum } from 'constants/lang';
import { StatusesEnum } from 'constants/status';
import yup, { contentSchema, imageSchema } from 'utils/yup';
import { MaterialValue } from 'components/form/AddMaterial/components/MaterialItem/types';
import { prepareContent } from 'components/form/Content/utils';

export const INITIAL_VALUES = {
  image: null,
  shortDescription: '',
  name: '',
  category: null,
  fullUsername: '',
  residentStatusDate: null,
  projectName: '',
  investments: '',
  content: prepareContent([]),
  lang: LangEnum.ru,
  status: StatusesEnum.PUBLISHED,
  gallery: [],
  documents: [] as MaterialValue[],
  region: null,
};

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  projectName: yup.string().required(),
  category: yup.mixed().required(),
  residentStatusDate: yup.mixed().required(),
  image: imageSchema,
  status: yup.mixed().required(),
  gallery: yup.array().of(imageSchema),
  content: contentSchema,
  region: yup.mixed().required(),
  documents: yup.array().of(
    yup.object().shape({
      url: yup.object().shape({
        url: yup.string().url(),
      }),
    })
  ),
});

export const DEFAULT_ACCEPT =
  '.pdf, .doc, .docx, .xls, .xlsx, .csv, .odf, .ppt, .jpeg, .jpg, .png';

export const VALID_FORMATS_LIST = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.formula',
  'text/csv',
  'application/vnd.ms-powerpoint',
  'image/jpg',
  'image/jpeg',
  'image/png',
];

export const UPLOADS_UNSUPPORTED_FILE_TYPES = `У файла недопустимый формат. Загрузите файл с расширением ${DEFAULT_ACCEPT}`;

export const COLLAPSE_FIELDS_ERRORS = {
  organizationName: 'about',
};
