import React from 'react';
import { Button, Row, Col, Typography, notification } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import {
  loadOrganizationUserList,
  setBlockUser,
  setRoles,
} from 'store/slices/organizations/actions';
import { IPerson } from 'store/slices/organizations/interfaces';
import { Select } from 'components/form/base/Select';
import { getName } from 'pages/Organizations/utils';
import { isString } from 'utils/helpers';
import { RolesNames } from 'constants/organizations';
import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';

import styles from './Users.module.less';

interface IFormValues {
  [key: number]: {
    id: string;
    role: string;
    userData: IPerson;
  };
}

export const Users = () => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.organizations);

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const isMobile = useBreakpoints().breakpoint === BreakpointsEnum.mobile;

  const methods = useForm<IFormValues>({
    defaultValues: {
      ...users.map(({ role, userData, user }) => ({
        user,
        role,
        name: getName(userData),
      })),
    },
  });

  const cancelButtonHandler = () => {
    navigate(`/organizations/${id}`);
  };

  const submit = (values: IFormValues) => {
    const preparedValue: {
      organization: number;
      users: { role: string; id: number }[];
    } = {
      organization: Number(id),
      users: Object.values(values).map(({ role, user }) => {
        let newRole = '';
        if (isString(role)) {
          newRole = role;
        } else {
          newRole = role.value;
        }
        return { id: user, role: newRole };
      }),
    };
    setIsLoading(true);
    dispatch(setRoles(preparedValue))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Роли успешно изменены',
        });
        dispatch(loadOrganizationUserList(id));
      })
      .finally(() => setIsLoading(false));
  };

  const handleControlUser = (user: number, isBlocked: boolean) => {
    dispatch(
      setBlockUser({ user, organization: Number(id), isBlocked: !isBlocked })
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: `Пользователь ${
            isBlocked ? 'разблокирован' : 'заблокирован'
          }`,
        });
        dispatch(loadOrganizationUserList(id));
      })
      .catch(() => {
        notification.error({
          message: 'Пользователь не изменен',
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        {users.map(({ isBlocked, userData, user }, index) => (
          <Row
            gutter={[0, 16]}
            className={styles.row}
            style={{ padding: '16px 76px 16px 0' }}
            justify='space-between'
            align='middle'
            key={index}
          >
            <Typography.Text style={{ width: '250px' }}>
              {getName(userData)}
            </Typography.Text>
            <Select
              style={{ width: isMobile ? '100%' : '282px' }}
              name={`${String(index)}.role`}
              options={Object.entries(RolesNames).map(([value, label]) => ({
                label,
                value,
              }))}
            />
            <Button onClick={() => handleControlUser(user, isBlocked)}>
              {isBlocked ? 'Разблокировать' : 'Заблокировать'}
            </Button>
          </Row>
        ))}
        <Row
          style={{ paddingTop: '16px', marginTop: '32px' }}
          gutter={[18, 0]}
          justify='end'
          align='middle'
        >
          <Col>
            <Button onClick={cancelButtonHandler} disabled={isLoading}>
              Отмена
            </Button>
          </Col>
          <Col>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Сохранить
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};
