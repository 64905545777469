import React from 'react';
import { useLocation } from 'react-router';

const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, bottom } = el.getBoundingClientRect();
  const { innerHeight } = window;
  const HEADER_HEIGHT = 64;
  return partiallyVisible
    ? top > HEADER_HEIGHT && top < innerHeight
    : top >= HEADER_HEIGHT && bottom <= innerHeight;
};

const focusEl = event => {
  if (
    event.target.nodeName !== '#document' &&
    !elementIsVisibleInViewport(event.target)
  ) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    (event.target as HTMLInputElement).scrollIntoView({ block: 'center' });
  }
};

export const FirefoxFocusScrollFix = () => {
  const location = useLocation();

  React.useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/firefox|fxios/i)) {
      document.addEventListener('focus', focusEl, true);
      return () => {
        document.removeEventListener('focus', focusEl);
      };
    }
  }, [location]);
  return null;
};
