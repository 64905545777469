import React from 'react';
import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { inputMaskHash } from 'constants/masks';
import { phonePlaceHoldersHash } from 'constants/phonePlaceholder';

import { PhoneInput } from './PhoneInput';

type TProps = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  formItemStyle?: React.CSSProperties;
  mask?: string;
  placeholder?: string;
};

export const PhoneNumberField: React.FC<TProps> = ({
  name,
  label,
  required,
  disabled,
  formItemStyle,
  mask = inputMaskHash.phoneWithBracket,
  placeholder = phonePlaceHoldersHash.phone,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Form.Item
          help={fieldState.error?.message}
          validateStatus={fieldState.error ? 'error' : 'success'}
          label={label}
          style={formItemStyle}
          required={required}
        >
          <PhoneInput
            {...field}
            error={fieldState.error}
            mask={mask}
            placeholder={placeholder}
            disabled={disabled}
          />
        </Form.Item>
      )}
    />
  );
};
