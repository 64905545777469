import React from 'react';
import { Button, Col, Row } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { MenuItem } from '../MenuItem/MenuItem';
import { DEFAULT_NESTING_LEVEL, getPrependValue } from '../../../constants';
import { changeSortParentNames } from '../../../MenuForm/formUtils';

type TProps = {
  name: string;
  depthLevel: number;
};

type TMenuItem = {
  id?: string;
  name: string;
};

export const MenuItems: React.FC<TProps> = ({ name, depthLevel }) => {
  const { control, setValue, getValues } = useFormContext();

  const { fields, move, remove } = useFieldArray({
    name,
    control,
  });

  const handleAddMenuItem = () => {
    const sortedValues = changeSortParentNames(getValues().items, 'items');
    setValue(name, [getPrependValue(depthLevel), ...sortedValues]);
  };

  const handleMoveItem = (index: number, step: number) => move(index, step);

  const handleRemoveItem = (index: number) => remove(index);

  return (
    <>
      {depthLevel === DEFAULT_NESTING_LEVEL && (
        <Row>
          <Col>
            <Button onClick={handleAddMenuItem}>+ Добавить пункт меню</Button>
          </Col>
        </Row>
      )}
      <Row>
        {(fields as TMenuItem[]).map((fieldItem, index) => (
          <MenuItem
            key={fieldItem.id}
            name={`${name}.${index}`}
            index={index}
            handleMoveItem={handleMoveItem}
            handleRemoveItem={handleRemoveItem}
            fieldsLength={fields.length}
            depthLevel={depthLevel}
          />
        ))}
      </Row>
    </>
  );
};
