// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainLayout-module__wrapper--amees {\n  display: flex;\n  gap: 24px;\n  padding-bottom: 50px;\n}\n.MainLayout-module__wrapper--amees.MainLayout-module__isMobile--XWpaI {\n  flex-flow: column;\n}\n.MainLayout-module__main--F6jrs {\n  flex: 1 1 200px;\n  order: 1;\n  min-width: 0;\n}\n.MainLayout-module__aside--bzI78 {\n  width: 266px;\n  order: 1;\n}\n.MainLayout-module__isMobile--XWpaI .MainLayout-module__main--F6jrs {\n  width: 100%;\n  flex: auto;\n  order: 2;\n}\n.MainLayout-module__isMobile--XWpaI .MainLayout-module__aside--bzI78 {\n  width: 100%;\n  flex: auto;\n  order: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,oBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AACA;EACE,eAAA;EACA,QAAA;EACA,YAAA;AACF;AACA;EACE,YAAA;EACA,QAAA;AACF;AAEA;EAEI,WAAA;EACA,UAAA;EACA,QAAA;AADJ;AAHA;EAOI,WAAA;EACA,UAAA;EACA,QAAA;AADJ","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 24px;\n  padding-bottom: 50px;\n  &.isMobile {\n    flex-flow: column;\n  }\n}\n.main {\n  flex: 1 1 200px;\n  order: 1;\n  min-width: 0;\n}\n.aside {\n  width: 266px;\n  order: 1;\n}\n\n.isMobile {\n  .main {\n    width: 100%;\n    flex: auto;\n    order: 2;\n  }\n  .aside {\n    width: 100%;\n    flex: auto;\n    order: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MainLayout-module__wrapper--amees",
	"isMobile": "MainLayout-module__isMobile--XWpaI",
	"main": "MainLayout-module__main--F6jrs",
	"aside": "MainLayout-module__aside--bzI78"
};
export default ___CSS_LOADER_EXPORT___;
