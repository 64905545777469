import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  saveResortItem,
  saveResortItemAsDraft,
} from 'store/slices/resorts/actions';
import { IResortItem } from 'store/slices/resorts/interfaces';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { DEFAULT_SEO_STATE } from 'constants/seo';

import { ResortsForm } from './components/ResortsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IResortItem) => {
    setIsLoading(true);
    dispatch(saveResortItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/resorts');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IResortItem) => {
    setIsLoading(true);
    dispatch(saveResortItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/resorts');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/resorts');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <ResortsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          lang: LangEnum.ru,
          name: '',
          image: null,
          heroImage: null,
          category: null,
          tags: [],
          content: prepareContent([]),
          gallery: [],
          address: {},
          getThere: [],
          workTime: {
            fri: { from: null, to: null },
            mon: { from: null, to: null },
            sat: { from: null, to: null },
            sun: { from: null, to: null },
            thu: { from: null, to: null },
            tue: { from: null, to: null },
            wed: { from: null, to: null },
          },
          contacts: [],
          ...DEFAULT_SEO_STATE,
          status: StatusesEnum.PUBLISHED,
          selection: false,
          attractions: [],
          hotels: [],
          restaurants: [],
        }}
      />
    </MainLayout>
  );
};
