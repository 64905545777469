import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { TContentBlock } from 'components/form/Content/contentTypes';
import { InputField } from 'components/form/base/InputField';
import { useDeepEffect } from 'utils/useDeepEffect';
import { MainImage } from 'components/form/MainImage/MainImage';
import { Status } from 'components/form/Status/Status';
import { Gallery } from 'components/form/Gallery/Gallery';
import { TImage } from 'types/image';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { isEmpty } from 'utils/helpers';
import { Statuses } from 'constants/status';
import {
  TInvestmentPassportsDraftItem,
  IInvestmentPassportsItem,
} from 'store/slices/investmentPassports/interfaces';
import { PREVIEW_ASPECT_RATIO } from 'constants/image';
import { LangEnumKeys } from 'constants/lang';
import { ContentField } from 'components/form/Content/ContentField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { TSEO } from 'types/seo';
import { CatalogRegionSelect } from 'components/form/selects/CatalogRegionSelect';
import { DatePicker } from 'components/form/base/DatePicker';
import { SEO } from 'components/form/SEO/SEO';
import { getValueByStringKeyWithArr } from 'utils/objects';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';
import { MAXIMAL_YEAR, MINIMAL_YEAR } from './constants';

interface IFormValues extends TSEO {
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  image: TImage;
  region: string | { value: number; label: string };
  year: number;
  month: number;
  gallery: TImage[];
  content: TContentBlock[];
  status: Statuses;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IInvestmentPassportsItem) => void;
  onSaveDraft: (values: TInvestmentPassportsDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const InvestmentPassportsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'gallery',
    'content',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver,
  });

  const {
    formState: { errors },
  } = methods;

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
            <Col flex='auto'>
              <InputField
                name='name'
                label='Название инвестиционного паспорта '
                required
                placeholder='Введите название'
                showCount
                maxLength={80}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                placeholder='Выберите регион'
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 7 }}>
              <DatePicker
                name='year'
                label='Год'
                picker='year'
                required
                dateFormatList='YYYY'
                minDateRestriction={dayjs().year(MINIMAL_YEAR)}
                maxDateRestriction={dayjs().year(MAXIMAL_YEAR)}
                allowClear={false}
                inputReadOnly
                placeholder='Выберите год'
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 7 }}>
              <DatePicker
                name='month'
                label='Месяц'
                picker='month'
                required
                dateFormatList='MMMM'
                allowClear={false}
                inputReadOnly
                placeholder='Выберите месяц'
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Галерея' key='gallery'>
            <Row>
              <Col span={24}>
                <Gallery name='gallery' />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Описание паспорта' key='content'>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  enabledTypes={[]}
                  customErrorText={
                    getValueByStringKeyWithArr(errors, 'content')?.[0]?.text
                      ?.message
                  }
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
