import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  documents: Yup.array()
    .test({
      message: 'Необходимо загрузить документы',
      test: arr => arr.length >= 1,
    })
    .required(),
});
